import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.less";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/alert/alert.action";
import moment from "moment";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import { Card, Select, Spin, Row, Col } from "antd";
import { ReactComponent as AppointmentIcon } from "../../../Images/dashboardIcon.svg";
import { ReactComponent as AppointmentIcon2 } from "../../../Images/dashboardIcon2.svg";
import { ReactComponent as WalletIcon } from "../../../Images/wallet.svg";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ZoomableContainer,
} from "recharts";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import MySchedule from "../../../components/mySchedule";
import Responsiveheader from "../../../components/Responsiveheader";
import Footer from "../../../components/Footer/Footer";
const { Title } = Typography;
const currentYear = moment().year();

function CareGiverDashboard() {
  const dispatch = useDispatch();
  const [earningReport, setEarningReport] = useState();
  const [yearValue, setYearValue] = useState(currentYear);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");

  const fetchEarningReport = async () => {
    if (accessToken !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/web-dashboard-report`,
          {
            year: yearValue,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((res) => {
          setEarningReport(res?.data?.output);
          setIsError(false);
        })
        .catch((err) => {
          setIsError(true);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchEarningReport();
  }, [yearValue, isError]);

  const cardDetails = [
    {
      icon: <AppointmentIcon />,
      count: earningReport?.pendingAppointments || "0",
      text: "Pending Appointment",
    },
    {
      icon: <AppointmentIcon2 stroke="green" />,
      count: earningReport?.upcomingAppointments || "0",
      text: "Upcoming Appointment",
    },
    {
      icon: <WalletIcon />,
      count: earningReport?.totalEarnings
        ? `$${earningReport.totalEarnings}`
        : "$0.00",
      text: "Total Earnings",
    },
  ];

  const handleYearChange = (year) => {
    setYearValue(year);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = earningReport?.data || [];
  const minY = Math.min(...data.map((d) => d.total));
  const maxY = Math.max(...data.map((d) => d.total)) * 1.1; // Increase upper bound by 10%
  const domain = [minY < 0 ? minY : 0, maxY];

  const ticks = [];
  for (let i = 0; i < 4; i++) {
    ticks.push(domain[0] + (i / 2) * (domain[1] - domain[0]));
  }
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{*/}
      {/*  isMobile ? (*/}
      {/*    <div className="container-fluid">*/}
      {/*      <div className="pt-3 d-flex">*/}
      {/*        <div className="parent-root-mobile">*/}
      {/*          */}
      {/*          <Row>*/}
      {/*          <Col xs={22} md={22}>*/}
      {/*          <Responsiveheader title="Appointments"/>*/}
      {/*          </Col> */}
      {/*            <div className="mobile-root">*/}
      {/*              <div className="dashboard-body">*/}
      {/*              <Col xs={24} md={24}>*/}
      {/*                <div className="mobile-small-cards" style={{ display: "flex" }}>*/}
      {/*                  {cardDetails.map((item, index) => {*/}
      {/*                    return (*/}
      {/*                      <Card*/}
      {/*                        className="ms-3 responsive-card"*/}
      {/*                        style={{*/}
      {/*                          width: 270,*/}
      {/*                          height: "auto",*/}
      {/*                          borderRadius: 20,*/}
      {/*                        }}*/}
      {/*                        key={index}*/}
      {/*                      >*/}
      {/*                        <div*/}
      {/*                          className="card-body"*/}
      {/*                          style={{ display: "flex", flexDirection: "column" }}*/}
      {/*                        >*/}
      {/*                          {item.icon}*/}
      {/*                          <Title*/}
      {/*                            level={1}*/}
      {/*                            style={{ fontWeight: "bold", margin: "0.5rem 0" }}*/}
      {/*                          >*/}
      {/*                            {item?.count}*/}
      {/*                          </Title>*/}
      {/*                          <div className="d-flex">*/}
      {/*                            <Title*/}
      {/*                              className="d-flex"*/}
      {/*                              level={4}*/}
      {/*                              style={{*/}
      {/*                                color: "grey",*/}
      {/*                                margin: "0.5rem 0",*/}
      {/*                                fontWeight: "200",*/}
      {/*                                display:"flex"*/}
      {/*                              }}*/}
      {/*                            >*/}
      {/*                              {item?.text}*/}
      {/*                            </Title>*/}
      {/*                          </div>*/}

      {/*                        </div>*/}
      {/*                      </Card>*/}
      {/*                    );*/}
      {/*                  })}*/}
      {/*                </div>*/}
      {/*                </Col>*/}
      {/*                <Col xs={24} md={24}>*/}
      {/*                <Card*/}
      {/*                className="mt-3"*/}
      {/*                style={{ maxWidth: '100%', borderRadius: 10, overflowX:"scroll", padding:"10px!important" }}*/}
      {/*              >*/}
      {/*                <div className="chart-content pb-2">*/}
      {/*                  <Title*/}
      {/*                    className="chart-title"*/}
      {/*                    level={4}*/}
      {/*                    style={{*/}
      {/*                      fontWeight: "semi-bold",*/}
      {/*                      margin: "0.5rem 0",*/}
      {/*                      paddingBottom: "10px",*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    Earning Overview*/}
      {/*                  </Title>*/}
      {/*                  <Select*/}
      {/*                    defaultValue="2023"*/}
      {/*                    style={{*/}
      {/*                      width: 80,*/}
      {/*                      alignItems: "center",*/}
      {/*                      display: "flex",*/}
      {/*                      color: "blue",*/}
      {/*                      fontSize: "14px",*/}
      {/*                      fontWeight: "500",*/}
      {/*                      fontFamily: "font-family: 'SF Pro Display'",*/}
      {/*                    }}*/}
      {/*                    onChange={handleYearChange}*/}
      {/*                    options={[*/}
      {/*                      {*/}
      {/*                        value: "2022",*/}
      {/*                        label: "2022",*/}
      {/*                      },*/}
      {/*                      {*/}
      {/*                        value: "2023",*/}
      {/*                        label: "2023",*/}
      {/*                      }*/}
      {/*                    ]}*/}
      {/*                  />*/}
      {/*                </div>*/}
      {/*                <div className="chart-container">*/}
      {/*                <Spin spinning={isLoading} tip="loading earnings and your appointments..." style={{width:"100%"}}>*/}
      {/*                  <LineChart*/}
      {/*                    width={500}*/}
      {/*                    height={370}*/}
      {/*                    data={earningReport?.data}*/}
      {/*                    margin={{ top: 5, right: 20, left: 2, bottom: 5 }}*/}
      {/*                    style={{maxWidth:"500", height:"auto", overflow:"scroll"}}*/}
      {/*                  >*/}
      {/*                    <CartesianGrid strokeDasharray="" vertical={false} />*/}
      {/*                    <XAxis*/}
      {/*                      dataKey="month"*/}
      {/*                      strokeDasharray=""*/}
      {/*                      axisLine={{ display: "none" }}*/}
      {/*                      interval={0} // Ensure that all labels are displayed*/}
      {/*                      tick={{ fontSize: 12 }}*/}
      {/*                      tickFormatter={(label) => label?.substring(0, 3)}*/}
      {/*                      style={{*/}
      {/*                        fontSize: "12",*/}
      {/*                        fontWeight: "400",*/}
      {/*                        color: "#64748B",*/}
      {/*                        marginTop: "1rem",*/}
      {/*                      }}*/}
      {/*                      width={120}*/}
      {/*                    />*/}
      {/*                    <YAxis*/}
      {/*                      tickCount={4}*/}
      {/*                      domain={[earningReport?.totalEarnings < 0 ? earningReport?.totalEarnings : 0, Math.max(earningReport?.totalEarnings + 100, 200)]}*/}
      {/*                      ticks={ticks}*/}
      {/*                      tickFormatter={(value) => `$${Math.round(value)}`}*/}
      {/*                      dataKey="Total"*/}
      {/*                      strokeDasharray=""*/}
      {/*                      style={{*/}
      {/*                        fontSize: "16",*/}
      {/*                        fontWeight: "400",*/}
      {/*                        color: "#64748B",*/}
      {/*                      }}*/}
      {/*                      scale="linear"*/}
      {/*                    />*/}
      {/*                    <Tooltip*/}
      {/*                      formatter={(value, name, props) => {*/}
      {/*                        const capitalizedDataKey = name.charAt(0).toUpperCase() + name.slice(1);*/}
      {/*                        return [`${capitalizedDataKey}: $${value}`, ''];*/}
      {/*                      }}*/}
      {/*                    />*/}

      {/*                    {isError === false && (*/}
      {/*                      <Line*/}
      {/*                        type="monotone"*/}
      {/*                        dataKey="total"*/}
      {/*                        stroke="green"*/}
      {/*                        strokeWidth={3.5}*/}
      {/*                        activeDot={{*/}
      {/*                          r: 7,*/}
      {/*                          fill: "#0072c6",*/}
      {/*                          stroke: "#fff",*/}
      {/*                          strokeWidth: 4,*/}
      {/*                        }}*/}
      {/*                        dot={(dotProps) => {*/}
      {/*                          const { dataKey, cx, cy, payload } = dotProps;*/}
      {/*                          let max = null;*/}
      {/*                          let maxCount = 0;*/}
      {/*                          for (const point of earningReport?.data || []) {*/}
      {/*                            if (point[dataKey] > max) {*/}
      {/*                              max = point[dataKey];*/}
      {/*                              maxCount = 1;*/}
      {/*                            } else if (point[dataKey] === max) {*/}
      {/*                              maxCount++;*/}
      {/*                            }*/}
      {/*                          }*/}
      {/*                          return payload[dataKey] === max &&*/}
      {/*                            max > 0 &&*/}
      {/*                            maxCount === 1 ? (*/}
      {/*                            <circle*/}
      {/*                              cx={cx}*/}
      {/*                              cy={cy}*/}
      {/*                              r={6}*/}
      {/*                              fill="#0072c6"*/}
      {/*                              stroke="none"*/}
      {/*                            />*/}
      {/*                          ) : null;*/}
      {/*                        }}*/}
      {/*                        */}
      {/*                      />*/}
      {/*                    )}*/}
      {/*                  </LineChart>*/}
      {/*                  </Spin>*/}
      {/*                </div>*/}
      {/*              </Card>*/}
      {/*                </Col>*/}
      {/*              */}
      {/*              </div>*/}
      {/*            */}
      {/*            </div>*/}
      {/*            </Row>*/}
      {/*     */}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="parent-root parent-root-custom">
            <div
              className="appointment-navbar-custom"
              style={{ width: "100%", paddingLeft: "2rem" }}
            >
              <AppointmentNavBar dashboardTitile={true} />
            </div>
            <div className="root">
              <div className="dashboard-body ms-3">
                <div className="dashboard-row-card" style={{ display: "flex" }}>
                  {cardDetails.map((item, index) => {
                    return (
                      <Card
                        className="ms-3 custom-dashboard-card"
                        style={{
                          width: "32%",
                          height: 150,
                          borderRadius: 20,
                        }}
                        key={index}
                      >
                        <div
                          className="card-body"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {item.icon}
                          <Title
                            style={{
                              fontWeight: "bold",
                              fontSize: "30px",
                              marginTop: "0.2rem",
                              marginLeft: "0.1rem",
                            }}
                          >
                            {item?.count}
                          </Title>
                          <Title
                            style={{
                              color: "grey",
                              margin: "-0.5rem 0",
                              fontWeight: "200",
                              display: "flex",
                              fontSize: "18px",
                            }}
                          >
                            {item?.text}
                          </Title>
                        </div>
                      </Card>
                    );
                  })}
                </div>
                <Spin
                  spinning={isLoading}
                  // tip="loading earnings and your appointments..."
                >
                  <Card
                    className="ms-3 mt-3 chart-card-main"
                    style={{ borderRadius: 20, }}
                  >
                    <div className="chart-content mb-2">
                      <Title
                        style={{
                          fontWeight: "bold",
                          margin: "0.5rem 0",
                          paddingBottom: "10px",
                          fontSize: "20px",
                          marginLeft: "1rem",
                        }}
                      >
                        Earning Overview
                      </Title>
                      <Select
                        defaultValue={currentYear}
                        style={{
                          width: 110,
                          alignItems: "center",
                          display: "flex",
                          color: "blue",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "19px",
                          fontFamily: "font-family: 'SF Pro Display'",
                        }}
                        onChange={handleYearChange}
                        options={[
                          {
                            value: "2022",
                            label: "2022",
                          },
                          {
                            value: "2023",
                            label: "2023",
                          },
                        ]}
                      />
                    </div>
                    <div className="chart pb-2">
                      <LineChart
                        width={1050}
                        height={400}
                        data={earningReport?.data}
                        margin={{ top: 5, right: 20, left: 2, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="" vertical={false} />
                        <XAxis
                          dataKey="month"
                          strokeDasharray=""
                          axisLine={{ display: "none" }}
                          interval={0} // Ensure that all labels are displayed
                          tick={{ fontSize: 12 }}
                          tickFormatter={(label) => label?.substring(0, 3)}
                          style={{
                            fontSize: "16",
                            fontWeight: "400",
                            color: "#64748B",
                            marginTop: "1rem",
                          }}
                          width={120}
                        />
                        <YAxis
                          tickCount={4}
                          domain={[
                            earningReport?.totalEarnings < 0
                              ? earningReport?.totalEarnings
                              : 0,
                            Math.max(earningReport?.totalEarnings + 100, 200),
                          ]}
                          ticks={ticks}
                          tickFormatter={(value) => `$${Math.round(value)}`}
                          dataKey="Total"
                          strokeDasharray=""
                          style={{
                            fontSize: "16",
                            fontWeight: "400",
                            color: "#64748B",
                          }}
                          scale="linear"
                        />
                        <Tooltip
                          formatter={(value, name, props) => {
                            const capitalizedDataKey =
                              name.charAt(0).toUpperCase() + name.slice(1);
                            const res = `${capitalizedDataKey}: $${value}`;
                            return [res];
                          }}
                        />

                        {isError === false && (
                          <Line
                            type="monotone"
                            dataKey="total"
                            stroke="green"
                            strokeWidth={3.5}
                            activeDot={{
                              r: 7,
                              fill: "#0072c6",
                              stroke: "#fff",
                              strokeWidth: 4,
                            }}
                            dot={(dotProps) => {
                              const { dataKey, cx, cy, payload } = dotProps;
                              let max = null;
                              let maxCount = 0;
                              for (const point of earningReport?.data || []) {
                                if (point[dataKey] > max) {
                                  max = point[dataKey];
                                  maxCount = 1;
                                } else if (point[dataKey] === max) {
                                  maxCount++;
                                }
                              }
                              return payload[dataKey] === max &&
                                max > 0 &&
                                maxCount === 1 ? (
                                <circle
                                  cx={cx}
                                  cy={cy}
                                  r={6}
                                  fill="#0072c6"
                                  stroke="none"
                                />
                              ) : null;
                            }}
                            filter="drop-shadow(0px 8px 3px rgba(0,0,0,0.5))"
                          />
                        )}
                      </LineChart>
                    </div>
                  </Card>
                </Spin>
              </div>
              <div className="right-panel">
                <MySchedule />
              </div>
            </div>
            <div className="" style={{ bottom: "0!important" }}>
              {" "}
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {/*) }*/}
    </div>
  );
}

export default CareGiverDashboard;

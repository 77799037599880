import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Slider } from "antd";
import { Circle, GoogleMap, useLoadScript } from "@react-google-maps/api";
import { connect } from "react-redux";
import "../../../../pages/caregiver/details/style.less";

const mapContainerStyle = {
  height: "520px",
  width: "100%",
};

function ProfileGeolocation({ location, save, userDetails }) {
  console.log(userDetails, "userDetails");
  const [form] = Form.useForm();
  const [haveCar, setHaveCar] = useState();
  const [center, setCenter] = useState();
  const [dim, setDim] = useState();

  useEffect(() => {
    if (userDetails) {
      setHaveCar(userDetails?.haveCar);
      setDim(
        !userDetails?.location?.radius
          ? 1500
          : userDetails?.location?.radius * 100
      );
      form.setFieldValue("haveCar", userDetails?.haveCar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, location]);

  useEffect(() => {
    debugger;
    if (location?.lat || userDetails?.location?.lat) {
      setCenter({
        lat: location?.lat || +userDetails?.location?.lat,
        lng: location?.lon || +userDetails?.location?.lon,
      });
    }
  }, [location, userDetails]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP, // ,
    // ...otherOptions
  });

  const onFinish = (values) => {
    console.log(location, userDetails?.location, "values", values);
    const data = {
      location: {
        lat: (location?.lat || userDetails?.location?.lat).toString(),
        lon: (location?.lon || userDetails?.location?.lon).toString(),
        radius: (dim / 100).toString(),
      },
      haveCar: haveCar,
    };

    save(data);
  };

  const onChange = (newValue) => {
    setDim(newValue);
  };

  const options = {
    strokeColor: "#364BC6",
    strokeOpacity: 0.5,
    strokeWeight: 4,
    fillColor: "rgba(255, 255, 255, 0.1)",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    // radius: dim,
    zIndex: 1,
  };

  const onLoad = (circle) => {
    console.log("Circle onLoad circle: ", circle);
  };

  const onUnmount = (circle) => {
    console.log("Circle onUnmount circle: ", circle);
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  console.log(userDetails, center, location, "userDetails");
  if (center) {
    return (
      <div
        className="px-3 mx-auto map-content profile-geo-location-div"
        style={{ maxWidth: "850px" }}
      >
        <div className="text-end color-secondary fs-5 pe-4 pt-2 fw-bolder" />
        <h2 className="color-primary fw-bolder mt-4">
          How far are you willing to travel for appointments?
        </h2>
        <div className="text-start geo-location-div-inner">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            // validateTrigger='onSubmit'
            // onFieldsChange={handleFormChange}
          >
            <Form.Item
              name="haveCar"
              initialValue={haveCar}
              style={{ marginBottom: "0px" }}
            >
              <Checkbox
                className="checkboxclass"
                checked={haveCar}
                value={haveCar}
                onChange={(e) => {
                  form.setFieldValue("haveCar", e.target.checked);
                  setHaveCar(e.target.checked);
                }}
              >
                <p style={{ color: "black" }}>
                  Do you have a car? Would you like to offer driving services
                  for your clients’ appointments and errands?
                </p>
              </Checkbox>
            </Form.Item>
            <GoogleMap
              id="circle-example"
              mapContainerStyle={mapContainerStyle}
              defaultOptions={{
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                zoomControl: true,
                draggable: true,
              }}
              zoom={10}
              center={center}
            >
              <Circle
                onLoad={onLoad}
                onUnmount={onUnmount}
                center={center}
                options={options}
                radius={dim * 10}
              ></Circle>

              <div className="map-km">{dim / 100} Km</div>
              <div className="map-actions">
                <Slider
                  className="simple-slider"
                  min={500}
                  max={2000}
                  step={100}
                  value={dim}
                  tooltip={{
                    open: false,
                  }}
                  style={{ top: "15px" }}
                  onChange={onChange}
                />
                <Button
                  className="btn-black auth-action-btn mt-4 mb-5 mx-auto google-next-button"
                  shape="round"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  style={{ top: "25px" }}
                >
                  Next{" "}
                  <img
                    className="ms-2"
                    src="/images/icons/right.svg"
                    alt="right icon"
                  />
                </Button>
              </div>
            </GoogleMap>
            <p className="mb-5"></p>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGeolocation);

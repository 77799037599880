const initState = [{ title: "", route: "" }];
const breadcumbsReducer = (state = initState, action) => {
  // console.log(action, "action")
  switch (action.type) {
    case "PUSH_BREADCRUMB":
      return action.payload;
    case "POP_BREADCRUMB":
      return state.slice(0, state.length - 1);
    default:
      return state;
  }
};

export default breadcumbsReducer;

import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Form, Input, Modal, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import PasswordStrengthMeter from "../../common/password-meter";
import { setAlert } from "../../redux/alert/alert.action";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import { handleRouting } from "../../common/helper/handleRoute";
import { setUser, setUserMode } from "../../redux/auth/auth.action";
import TermsAndConditions from "../../components/termsandconditions";
import PrivacyPolicy from "../../components/privacypolicy";
import { ArrowLeftOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "../styles/privacypolicy.less";

function SignUP({ user }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [disabledSave, setDisabledSave] = useState(true);
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const { Text } = Typography;

  useEffect(() => {
    handleRouting(user, navigate);
  }, [user, navigate]);

  const onFinish = (values) => {
    console.log("Success:", values);
    const body = {
      email: values.email?.toLowerCase(),
      password: values.password,
    };
    doSignUP(body);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const doSignUP = async (values) => {
    setDisabledSave(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/client-sign-up`, values, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((response) => {
        // console.log("response", response)
        if (response.data.status === 200) {
          setDisabledSave(false);
          localStorage.setItem("token", response?.data?.output?.accessToken);
          dispatch(setUser(response?.data?.output));
          dispatch(setUserMode("client"));
          navigate("/client/verify", { state: response?.data?.output });
        } else if (response.data.status >= 400) {
          setDisabledSave(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        } else {
          setDisabledSave(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (err?.response.data.status >= 400) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
          if (err.response.data.status === 401) {
            localStorage.clear();
            navigate("/");
            window.location.reload();
          }
        }
      });
  };

  const handleFormChange = (changedFields) => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
    if (changedFields[0]?.name.includes("email")) {
      form.setFieldValue("email", changedFields[0]?.value.toLowerCase());
    }
  };

  return (
    <>
      <div className="center-container main-div-top min-vh-100">
        <div className="welcome-container justify-content-center">
          {/* <div className="left-side-poster client-login-poster" /> */}
          <div className="right-side-form">
            <div className="center-container justify-content-start h-100 text-center">
              {/* <img
              className="logo-img pt-0 mb-4 logo-mobile"
              src="/images/icons/logo_with_name.svg"
              alt="logo"
            /> */}
              <div className="w-100">
                <div
                  className="welcome-wrapper full-width text-left-mobile"
                  // style={{ maxWidth: "370px" }}
                >
                  <h1 id="name" className="welcome-header mb-2 welcome-wrapper">
                    Sign Up
                  </h1>
                  <p className="welcome-subheader mt-2 mb-4">
                    Register and create a profile to book appointments <br /> on
                    demand and view available caregivers in your area
                  </p>
                  <Form
                    name="signup-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    // validateTrigger='onSubmit'
                    onFieldsChange={handleFormChange}
                    form={form}
                  >
                    <Form.Item
                      name="email"
                      type="email"
                      className="mb-3"
                      rules={[
                        // {
                        //   type: "email",
                        //   message: "Please enter a valid E-mail.",
                        // },
                        {
                          required: true,
                          message: "Please enter your email",
                        },
                        {
                          pattern:
                            /^[a-zA-Z0-9-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                          message: "Please enter a valid email.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Email Address"
                        prefix={
                          <img
                            src="/images/icons/Message.svg"
                            style={{ marginRight: "10px" }}
                            alt="message"
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                            const isContainsLowercase = /^(?=.*[a-z]).*$/;
                            const isContainsNumber = /^(?=.*[0-9]).*$/;
                            const isContainsSymbol =
                              /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                            if (value?.length < 8) {
                              return Promise.reject(
                                new Error(
                                  "Must contain minimum of 8 characters."
                                )
                              );
                            }
                            if (
                              !isContainsNumber.test(value) ||
                              !isContainsLowercase.test(value) ||
                              !isContainsSymbol.test(value) ||
                              !isContainsUppercase.test(value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  "Atleast one uppercase, lowercase, number(0-9), special character."
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src="/images/icons/password-show.svg"
                              alt="show"
                            />
                          ) : (
                            <img
                              src="/images/icons/password-hide.svg"
                              alt="hide"
                            />
                          )
                        }
                        onChange={(e) => setPassword(e.target.value)}
                        size="large"
                        prefix={
                          <img
                            src="/images/icons/Lock.svg"
                            alt="lock icon is here"
                            style={{ marginRight: "10px" }}
                          />
                        }
                        placeholder="Enter Password"
                      />
                    </Form.Item>
                    <div className="mb-3 mt-3 progress-bar">
                      <PasswordStrengthMeter password={password} />
                    </div>
                    <Form.Item
                      name="confirmpassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your Password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "Password should match with confirm password"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        iconRender={(visible) =>
                          visible ? (
                            <img
                              src="/images/icons/password-show.svg"
                              alt="show"
                            />
                          ) : (
                            <img
                              src="/images/icons/password-hide.svg"
                              alt="hide"
                            />
                          )
                        }
                        size="large"
                        prefix={
                          <img
                            src="/images/icons/Lock.svg"
                            alt="lock icon"
                            style={{ marginRight: "10px" }}
                          />
                        }
                        placeholder="Retype Password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Should accept agreement")
                                ),
                        },
                      ]}
                    >
                      <Checkbox>
                        By tapping here you agree to our{" "}
                        <a
                          className="text-dark fw-bold"
                          onClick={() => {
                            setTermsModal(true);
                          }}
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          className="text-dark fw-bold"
                          onClick={() => {
                            setPrivacyModal(true);
                          }}
                        >
                          Privacy Policy.
                        </a>
                      </Checkbox>
                    </Form.Item>
                    <Form.Item className="mb-0 d-flex justify-content-center flex-direction-column">
                      <Button
                        htmlType="submit"
                        className="btn-black mx-auto-mobile auth-action-btn mb-2 mx-auto ml-left-0"
                        shape="round"
                        type="primary"
                        size="large"
                        disabled={disabledSave}
                      >
                        Sign Up{" "}
                        <img
                          className="ms-2"
                          src="/images/icons/right.svg"
                          alt="right icon"
                        />
                      </Button>
                      <p className="welcome-subheader text-center-ipad text-center-mob pointer fs-6 account-confirmation">
                        Already have an account?{" "}
                        <Button
                          type="link"
                          className="fw-bold p-0"
                          onClick={() => navigate("/client/signin")}
                        >
                          Sign In
                        </Button>
                      </p>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={termsModal}
        width="100%"
        header={null}
        footer={null}
        centered
        className="termsandcon custom-modal"
        onCancel={() => setTermsModal(false)}
        closable
        closeIcon={
          <img src="/images/icons/close-icon-outline-black.svg" alt={""} />
        }
      >
        <Card
          className="common-card-modal"
          style={{ padding: "15px", margin: "20px" }}
        >
          <ArrowLeftOutlined
            style={{ fontSize: "20px" }}
            onClick={() => {
              setTermsModal(false);
            }}
          />
          <div
            className="termsheadings"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h1
              style={{
                textAlign: "center",
                color: "#364BC6",
                fontWeight: "700",
                fontSize: "28px",
              }}
            >
              {" "}
              Terms & Conditions
            </h1>
            <p
              style={{
                textAlign: "center",
                color: "#64748B",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              <span style={{ color: "#0F172A", fontWeight: 700 }}>
                Last updated:
              </span>{" "}
              April, 2023
            </p>
          </div>
          <div style={{ height: "350px", overflowY: "auto" }}>
            <Text
              className="TextLevelSmallLabel"
              style={{
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <TermsAndConditions />
            </Text>
          </div>
        </Card>
      </Modal>
      <Modal
        open={privacyModal}
        width="100%"
        header={null}
        footer={null}
        centered
        className="termsandcon custom-modal"
        onCancel={() => setPrivacyModal(false)}
        closable
        closeIcon={
          <img src="/images/icons/close-icon-outline-black.svg" alt={""} />
        }
      >
        <Card
          className="common-card-modal"
          style={{ padding: "15px", margin: "20px" }}
        >
          <div
            className="termsheadings"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <ArrowLeftOutlined
              style={{ fontSize: "20px" }}
              onClick={() => {
                setPrivacyModal(false);
              }}
            />
            <h1
              style={{
                textAlign: "center",
                color: "#364BC6",
                fontWeight: "700",
                fontSize: "28px",
              }}
            >
              {" "}
              Privacy <span style={{ color: "#364BC6" }}>Policy</span>
            </h1>
            <p
              style={{
                textAlign: "center",
                color: "#64748B",
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              <span style={{ color: "#0F172A", fontWeight: 700 }}>
                Last updated:
              </span>{" "}
              April, 2023
            </p>
          </div>
          <div style={{ height: "350px", overflowY: "auto" }}>
            <Text
              className="TextLevelSmallLabel"
              style={{
                fontSize: "16px",
                textAlign: "justify",
              }}
            >
              <PrivacyPolicy />
            </Text>
          </div>
        </Card>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUP);

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from "antd";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import "../../client/appointment/service-grid.less";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
// import { RatingModal } from "../../client/appointment/careGiverDetails";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import TextArea from "antd/lib/input/TextArea";
import "./appointment.less";
import Webcam from "react-webcam";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../components/Footer/Footer";
import ExpensesItems from "./ClientExpenses/ExpensesItems";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getDistanceBetweenTwoPoints } from "../../../utils";
// import { RatingModal } from "./appointmentDetails";
const { Text } = Typography;
// let ExpensesArray = []
export default function CaregiverAppointmentDetails(props) {
  const [loading, setLoading] = useState(false);
  const [caregiverDetails, setCaregiverDetails] = useState(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 990);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [declineModalVisible, setDeclineModalVisible] = useState(false);
  const [startModalVisible, setStartModalVisible] = useState(false);
  const [completeModalVisible, setCompleteModalVisible] = useState(false);
  const [age, setAge] = useState(0);
  const [appointmentID, setAppointmentID] = useState(null);
  const [addExpenseScreen, setAddExpenseScreen] = useState(false);
  const [isAppointmentForm, setIsAppointmentForm] = useState(false);
  const [isAppointentInput, setIsAppointentInput] = useState(true);
  const [isAppointmentDetails, setIsAppointmentDetails] = useState(false);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [fileList, setFileList] = useState([]);
  const [expensesArr, setExpensesArr] = useState([]);
  const [currentExpenseUrl, setCurrentExpenseUrl] = useState("");
  const [calculatedDistance, setCalculatedDistance] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentData = useSelector((state) => state.appointment);
  const { paymentDetails } = appointmentData;
  const { caregiverLoc } = props;
  let startDuration = moment.duration(
    moment(paymentDetails?.timing[0]?.startTime).diff(moment().utc(true))
  );
  const endTime = moment.utc(paymentDetails?.timing[0]?.endTime);
  let endDuration = moment.duration(endTime.diff(moment().utc(true)));
  let startTimeMinutes = startDuration.asMinutes();
  let endTimeMinutes = endDuration.asMinutes();
  console.log(endTimeMinutes, "endTimeMinutes");
  const userMode = useSelector((state) => state.loginData?.userMode);

  useEffect(() => {
    if (caregiverLoc && paymentDetails?.location) {
      const coord1 = { ...caregiverLoc, lon: caregiverLoc?.lng };
      const coord2 = { ...paymentDetails.location };
      const calcDistance = getDistanceBetweenTwoPoints(coord1, coord2);
      setCalculatedDistance(calcDistance);
    }
  }, [caregiverLoc?.lat, caregiverLoc?.lng]);

  console.log(props.caregiverLoc, "caregiverLoc");

  const appointment = useParams();
  const token = localStorage.getItem("token");
  console.log(
    // paymentDetails,
    // startTimeMinutes,
    endTimeMinutes,
    "paymentDetails"
  );
  const onChange = ({ fileList: newFileList, file: newFile }) => {
    setFileList(newFileList);
    const formData = new FormData();
    formData.append("image", newFile.originFileObj);
    formData.append("path", "expense-image");
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/upload-image`, formData, {
        headers: {
          accessToken: token,
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          // console.log(response.data.output.imageUrl1.location                        , "expensesimage");
          setCurrentExpenseUrl(response.data.output.imageUrl1.location);
          // setUploadedImg(response.data.output.imageUrl1.location);
          // handleWebcamClose();
        }
      });
  };
  console.log(fileList, "fileListfileList");
  // const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const image = new Image();
  //   image.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(image.outerHTML);
  // };
  // console.log(additionalNotes, "additionalNotes")
  const isAddExpenseScreen = () => {
    setAddExpenseScreen(false);
    setIsAppointmentForm(false);
    // setIsAppointmentDetails(false);
    // setCompleteModalVisible(true);
    // setIsAppointentInput(true);
  };

  const isExpensesAdded = () => {
    setIsAppointentInput(true);
    setIsAppointmentForm(false);
    setAddExpenseScreen(false);
    // setIsAppointmentDetails(true);
  };
  const isAddExpenses = () => {
    // console.log("id data ..../");
    setIsAppointentInput(false);
    // setCompleteModalVisible(false)
    setIsAppointmentForm(true);
    setAddExpenseScreen(true);
  };
  const handleReviewModalVisible = () => {
    setReviewModalVisible(true);
  };

  const handleRatingModalClose = () => {
    setReviewModalVisible(false);
  };

  const handleCancelModalVisible = (appId) => {
    setAppointmentID(appId);
    setCancelModalVisible(true);
  };

  const handleCancelModalClose = () => {
    setAppointmentID(null);
    setCancelModalVisible(false);
  };

  const handleDeclineModalVisible = (appId) => {
    setAppointmentID(appId);
    setDeclineModalVisible(true);
  };

  const handleDeclineModalClose = () => {
    setAppointmentID(null);
    setDeclineModalVisible(false);
    // setCompleteModalVisible(true);
  };

  const handleStartModalVisible = (appId) => {
    setAppointmentID(appId);
    setStartModalVisible(true);
  };

  const handleStartModalClose = () => {
    setAppointmentID(null);
    setStartModalVisible(false);
  };

  const handleCompleteModalVisible = (appId) => {
    setAppointmentID(appId);
    setCompleteModalVisible(true);
  };

  const handleCompleteModalClose = () => {
    setAppointmentID(null);
    setCompleteModalVisible(false);
  };

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.Appointments.concat({
          title: paymentDetails?.relativeName,
          route: `appointment-details`,
        })
      )
    );
  }, []);
  const handleStartAppointment = (uploadedImg) => {
    console.log(uploadedImg, "uploadedImg");
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/start-appointment`;
    console.log(appointmentID, token, "dsfsdf");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            appointmentId: appointmentID,
            image: uploadedImg,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data?.status === 200) {
            //  setPaySuccessVisible(true);
            //  dispatch(paymentDetails(response.data.output.result))
            // setCareGiverDetails(response.data.output)
            // dispatch(bookAppointment(response.data.output));
            // dispatch(selectedCC(caregiverDetails));
            navigate("/caregiver/appointments-dashboard");
            //  setAppointmentListing(response.data.output.list)
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleCancelAppointment = (cancelType, cancelReason) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/caregiver-cancel-appointment`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            cancelType,
            appointmentId: appointmentID,
            cancelReason,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            //  setPaySuccessVisible(true);
            //  dispatch(paymentDetails(response.data.output.result))
            // setCareGiverDetails(response.data.output)
            // dispatch(bookAppointment(response.data.output));
            // dispatch(selectedCC(caregiverDetails));
            navigate("/caregiver/appointments-dashboard");
            //  setAppointmentListing(response.data.output.list)
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  // const ishandleCompleteAppointment=(values)=>{
  //     console.log(values, "values")
  // }
  const handleCompleteAppointment = (notes) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/complete-appointment`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            appointmentId: appointmentID,
            expenses: expensesArr,
            notes: notes?.notes || additionalNotes,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            //  setPaySuccessVisible(true);
            //  dispatch(paymentDetails(response.data.output.result))
            // setCareGiverDetails(response.data.output)
            // dispatch(bookAppointment(response.data.output));
            // dispatch(selectedCC(caregiverDetails));
            setAddExpenseScreen(false);
            navigate("/caregiver/appointments-dashboard");
            //  setAppointmentListing(response.data.output.list)
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            setAddExpenseScreen(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            setAddExpenseScreen(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleCaregiverActions = (IS_DECLINE, appID) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/approve-or-decline`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: IS_DECLINE ? "decline" : "approve",
            appointmentId: appointmentID ?? appID,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            navigate("/caregiver/appointments-dashboard");
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            // setPayUnsuccessVisible(true);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    if (paymentDetails?.relativeDob) {
      const DOB = moment(paymentDetails?.relativeDob, "DD/MM/YYYY");
      const years = moment().diff(DOB, "years", false);
      setAge(years);
    }
  }, [paymentDetails?.relativeDob]);
  const handleAddressClick = () => {
    const address = paymentDetails;

    if (address) {
      console.log(address.province, "address.city");
      const city = address.city ? `${address.city}, ` : "";
      const province = address.province ? address.province : "";
      const postalcode = address.postalCode ? address.postalCode : "";

      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${city}${province}${postalcode}`;
      window.open(mapsUrl, "_blank");
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const getHighlightedID = () => {
    const foundOngoing = paymentDetails?.timing.find(
      (dateSlot) => dateSlot.statusType == 5
    );
    console.log(foundOngoing, "foundOngoing");
    if (foundOngoing) {
      return foundOngoing._id;
    } else {
      const foundFirstUpcoming = paymentDetails?.timing.find(
        (dateSlot) => dateSlot.statusType == 2
      );
      return foundFirstUpcoming?._id;
    }
  };

  console.log(
    calculatedDistance,
    startTimeMinutes,
    // startTimeMinutes > 10,
    // calculatedDistance < 500,
    // startTimeMinutes > -30,
    "calculatedDistancenew"
  );
  return (
    <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>
      {/*{*/}
      {/*  isMobile ? (*/}
      {/*    <div className="container-fluid appointment-dashboard">*/}
      {/*    <div className="pt-3 d-flex profile-details-wrapper">*/}
      {/*      <div className="w-100">*/}
      {/*        <Responsiveheader title={"Appointments > ".concat(paymentDetails?.relativeName)} style={{fontSize:"18px!important"}} />*/}
      {/*        <div*/}
      {/*          className="border-less rounded"*/}
      {/*          style={ { background: "#fff", padding: "15px 15px", height:"100vh" }}*/}
      {/*        >*/}
      {/*          <div>*/}
      {/*            /!* <div className="d-flex justify-content-between">*/}
      {/*                                <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>*/}
      {/*                                /!* <p className="mt-2 mb-3 profile-detail-title" style={{fontSize: '16px',}}>{moment(bookingDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p> *!/*/}
      {/*            /!* </div> *!/*/}
      {/*            <Spin spinning={loading} tip="loading...">*/}
      {/*              <div*/}
      {/*                className={`payment-wrapper appointment-wrapper`}*/}
      {/*                style={{ width: "100%" }}*/}
      {/*              >*/}
      {/*                <Row>*/}
      {/*                  <Col xl={24} lg={24} md={24} xs={24}>*/}
      {/*                    <Row>*/}
      {/*                      <Col xl={24} lg={12} xs={24}>*/}
      {/*                        <Row>*/}
      {/*                          <Col lg={12} md={12} xs={12}>*/}
      {/*                            <div className="d-flex profile-wrapper">*/}
      {/*                            {*/}
      {/*                              paymentDetails?.relativeImage ? */}
      {/*                              <img*/}
      {/*                                src={paymentDetails?.relativeImage}*/}
      {/*                                width={40}*/}
      {/*                                height={40}*/}
      {/*                                className="br-25 caregiver-img"*/}
      {/*                              />*/}
      {/*                              :*/}
      {/*                              <img*/}
      {/*                                src='/images/icons/Profile.png'*/}
      {/*                                width={40}*/}
      {/*                                height={40}*/}
      {/*                                className="br-25 caregiver-img"*/}
      {/*                              />*/}
      {/*                            }*/}
      {/*                              */}
      {/*                              <div>*/}
      {/*                                <p className="caregiver-name d-flex">*/}
      {/*                                  <span className='d-flex'>{paymentDetails?.relativeName}</span>{" "}*/}
      {/*                                  <span className="color-secondary d-flex" style={{fontSize:"10px!important"}}>{` • ${age} Years`}</span>*/}
      {/*                                </p>*/}
      {/*                                <div className="d-flex justify-content-between relative-wrapper">*/}
      {/*                                  <span className="book-btn">*/}
      {/*                                    Booked by*/}
      {/*                                  </span>*/}
      {/*                                  /!* <img src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"*/}
      {/*                                                                            width={20} className='br-25 mx-2' /> *!/*/}
      {/*                                  <span className="relative-name mx-2 d-flex">*/}
      {/*                                    {paymentDetails?.clientName}*/}
      {/*                                  </span>*/}
      {/*                                </div>*/}
      {/*                              </div>*/}
      {/*                            </div>*/}
      {/*                          </Col>*/}
      {/*                          <Col*/}
      {/*                            lg={12}*/}
      {/*                            md={12}*/}
      {/*                            xs={12}*/}
      {/*                            style={{ textAlign: "right" }}*/}
      {/*                          >*/}
      {/*                            {paymentDetails?.statusType == 1 ? (*/}
      {/*                              <Button className="pending">Pending</Button>*/}
      {/*                            ) : paymentDetails?.statusType == 5 ? (*/}
      {/*                              <Button className="ongoing">Ongoing</Button>*/}
      {/*                            ) : paymentDetails?.statusType == 6 ? (*/}
      {/*                              <Button className="completed">*/}
      {/*                                Completed*/}
      {/*                              </Button>*/}
      {/*                            ) : paymentDetails?.statusType == 4 ? (*/}
      {/*                              <Button className="cancelled">*/}
      {/*                                Cancelled*/}
      {/*                              </Button>*/}
      {/*                            ) : paymentDetails?.statusType == 3 ? (*/}
      {/*                              <Button className="declined">Declined</Button>*/}
      {/*                            ) : paymentDetails?.statusType == 2 ? (*/}
      {/*                              <Button className="upcoming">Upcoming</Button>*/}
      {/*                            ) : (*/}
      {/*                              ""*/}
      {/*                            )}*/}
      {/*                          </Col>*/}
      {/*                        </Row>*/}
      {/*                        <Row className="mt-4">*/}
      {/*                          {paymentDetails?.statusType == 1 ? (*/}
      {/*                            <>*/}
      {/*                            <Col lg={2} xl={4} md={6}>*/}
      {/*                              <div className="d-flex"  onClick={handleAddressClick}>*/}
      {/*                                <img*/}
      {/*                                  src="/images/icons/location.svg"*/}
      {/*                                  width={15}*/}
      {/*                                />*/}
      {/*                                <span*/}
      {/*                                  style={{*/}
      {/*                                    marginLeft: "11px",*/}
      {/*                                    fontWeight: 400,*/}
      {/*                                    fontSize: "16px",*/}
      {/*                                    lineHeight: "22px",*/}
      {/*                                    position: "relative",*/}
      {/*                                    right: "2px",*/}
      {/*                                    cursor:"pointer"*/}
      {/*                                  }}*/}
      {/*                                >*/}
      {/*                                  {`${paymentDetails?.city}, ${paymentDetails?.province}`}*/}
      {/*                                </span>*/}
      {/*                              </div>*/}
      {/*                            </Col>*/}
      {/*                             <Divider type="vertical" style={{height: '40px'}} /></>*/}
      {/*                          ) : (*/}
      {/*                            <>*/}
      {/*                            <Col lg={7} xl={6} md={8}>*/}
      {/*                              <div className="d-flex"  onClick={handleAddressClick} style={{cursor:"pointer",  paddingBottom:"2rem", }} >*/}
      {/*                                <img*/}
      {/*                                  src="/images/icons/location.svg"*/}
      {/*                                  width={15}*/}
      {/*                                />*/}
      {/*                                <span*/}
      {/*                                  style={{*/}
      {/*                                    marginLeft: "11px",                                    */}
      {/*                                    fontWeight: 400,*/}
      {/*                                    fontSize: "16px",*/}
      {/*                                    lineHeight: "22px",*/}
      {/*                                    position: "relative",*/}
      {/*                                    right: "2px",*/}
      {/*                                  }}*/}
      {/*                                >*/}
      {/*                                  {`${paymentDetails?.address}, ${paymentDetails?.city}, ${paymentDetails?.province}, ${paymentDetails?.postalCode}`}*/}
      {/*                                </span>*/}
      {/*                              </div>*/}
      {/*                            </Col>*/}
      {/*                             <Divider type="vertical" style={{height: '40px'}} /></>*/}
      {/*                          )}*/}
      {/*                          <Col lg={4} xl={4} md={4}>*/}
      {/*                            <div*/}
      {/*                              className="d-flex"*/}
      {/*                              style={{*/}
      {/*                              */}
      {/*                                position: "relative",*/}
      {/*                                justifyContent: "center",*/}
      {/*                              }}*/}
      {/*                            >*/}
      {/*                              <img*/}
      {/*                                src="/images/icons/time.svg"*/}
      {/*                                width={20}*/}
      {/*                              />*/}
      {/*                              <span*/}
      {/*                                style={{*/}
      {/*                                  fontWeight: 400,*/}
      {/*                                  fontSize: "16px",*/}
      {/*                                  lineHeight: "22px",*/}
      {/*                                  marginLeft: "11px",*/}
      {/*                                }}*/}
      {/*                              >*/}
      {/*                                {paymentDetails?.totalHour} Hours*/}
      {/*                              </span>*/}
      {/*                            </div>*/}
      {/*                          </Col>*/}
      {/*                          <Divider type="vertical" style={{height: '40px'}} />*/}
      {/*                          <Col lg={3} xl={4} md={3}>*/}
      {/*                            <span className="profile-detail-title">*/}
      {/*                              Hourly Rate*/}
      {/*                            </span>*/}
      {/*                            <p className="profile-detail-val">*/}
      {/*                              ${paymentDetails?.caregiverBasePrice}*/}
      {/*                            </p>*/}
      {/*                          </Col>*/}
      {/*                          <Divider type="vertical" style={{height: '40px'}} />*/}
      {/*                          <Col lg={3} xl={3} md={3}>*/}
      {/*                            <span className="profile-detail-title">*/}
      {/*                              Total*/}
      {/*                            </span>*/}
      {/*                            <p className="profile-detail-val">*/}
      {/*                              ${paymentDetails?.totalAmount}*/}
      {/*                            </p>*/}
      {/*                          </Col>*/}
      {/*                        </Row>*/}
      {/*                        <Divider*/}
      {/*                          type="horizontal"*/}
      {/*                          style={{ marginTop: "10px" }}*/}
      {/*                        />*/}
      {/*                      </Col>*/}
      {/*                      <div style={{*/}
      {/*                        maxHeight: paymentDetails?.statusType == 1 || paymentDetails?.statusType == 2 || paymentDetails?.statusType == 3  ? "32vh" : "32vh",*/}
      {/*                        overflow: "scroll",*/}
      {/*                        width: "100%"*/}
      {/*                      }}*/}
      {/*                      >  */}
      {/*                      <Col xl={24} lg={12} xs={18} md={18} className="mb-3">*/}
      {/*                      <span className="profile-detail-title">*/}
      {/*                          Services*/}
      {/*                        </span>*/}
      {/*                        <Row className="mt-3">*/}
      {/*                          {paymentDetails?.serviceList.map((i) => {*/}
      {/*                            return (*/}
      {/*                              <Col*/}
      {/*                                lg={6}*/}
      {/*                                md={8}*/}
      {/*                                sm={12}*/}
      {/*                                className="service-offer-card d-flex mx-1 mt-2"*/}
      {/*                              >*/}
      {/*                                <img src={i.image} width={50} height={50} />*/}
      {/*                                <span className="service-offer-text">*/}
      {/*                                  {i?.name}*/}
      {/*                                </span>*/}
      {/*                              </Col>*/}
      {/*                            );*/}
      {/*                          })}*/}
      {/*                        </Row>*/}
      {/*                    */}
      {/*                      </Col>*/}
      {/*                      <Col xl={24} lg={12} xs={24} md={24}>*/}
      {/*                        <span className="profile-detail-title">*/}
      {/*                          Date and Time*/}
      {/*                        </span>*/}
      {/*                        <Row*/}
      {/*                          className={`mt-3 ${*/}
      {/*                            paymentDetails?.statusType == 6*/}
      {/*                              ? "completed-appointment"*/}
      {/*                              : ""*/}
      {/*                          }`}*/}
      {/*                        >*/}
      {/*                          {paymentDetails?.timing?.map((i) => {*/}
      {/*                            return (*/}
      {/*                              <Col*/}
      {/*                                lg={3}*/}
      {/*                                md={6}*/}
      {/*                                sm={8}*/}
      {/*                                className={`service-offer-card d-flex mx-1 mt-2 ${*/}
      {/*                                  i.statusType == 4*/}
      {/*                                    ? "cancelled-appointment"*/}
      {/*                                    : ""*/}
      {/*                                } ${*/}
      {/*                                  i.statusType == 5*/}
      {/*                                    ? "ongoing-appointment"*/}
      {/*                                    : ""*/}
      {/*                                }`}*/}
      {/*                              >*/}
      {/*                                <div className="date-card">*/}
      {/*                                  {moment(i.date).format("MMM")}{" "}*/}
      {/*                                  <span*/}
      {/*                                    style={{*/}
      {/*                                      fontWeight: 700,*/}
      {/*                                      marginTop: "-5px",*/}
      {/*                                    }}*/}
      {/*                                  >*/}
      {/*                                    {moment(i.date).format("DD")}*/}
      {/*                                  </span>*/}
      {/*                                </div>*/}
      {/*                                <span className="time-text">*/}
      {/*                                  {moment(i.startTime)*/}
      {/*                                    .zone(0)*/}
      {/*                                    .format("hh:mm a")}{" "}*/}
      {/*                                  to{" "}*/}
      {/*                                  {moment(i.endTime)*/}
      {/*                                    .zone(0)*/}
      {/*                                    .format("hh:mm a")}*/}
      {/*                                </span>*/}
      {/*                                /!* <span className="service-offer-text">{i?.name}</span> *!/*/}
      {/*                              </Col>*/}
      {/*                            );*/}
      {/*                          })}*/}
      {/*                        </Row>*/}
      {/*                      </Col>*/}
      {/*                      {paymentDetails?.statusType != 1 &&*/}
      {/*                        <Col xl={24} lg={12} xs={24} md={24} style={{marginTop: '20px'}}>*/}
      {/*                          <span className="profile-detail-title">*/}
      {/*                            Map*/}
      {/*                          </span>*/}
      {/*                          <Row className="mt-3 mb-3">*/}
      {/*                          <Col xl={24} lg={12} xs={24} md={24}>*/}
      {/*                            {isLoaded && */}
      {/*                            <CaregiverGoogleMap paymentDetails={paymentDetails} isLoaded={isLoaded}/>*/}
      {/*                            }*/}
      {/*                          </Col>*/}
      {/*                          </Row>*/}
      {/*                        </Col>*/}
      {/*                      }*/}
      {/*                      <Col xl={24} lg={12} xs={24} md={24} className="mt-3">*/}
      {/*                        <span className="profile-detail-title">*/}
      {/*                          Additional Comments*/}
      {/*                        </span>*/}
      {/*                        <Row className="mt-3 mb-3">*/}
      {/*                          <span className="profile-detail-title">*/}
      {/*                            {paymentDetails?.additionalNote*/}
      {/*                              ?.additionComments ?? "N/A"}*/}
      {/*                          </span>*/}
      {/*                        </Row>*/}
      {/*                      </Col>*/}

      {/*                      <Col xl={24} lg={12} xs={12} className="mt-3">*/}
      {/*                       <ExpensesItems*/}
      {/*                        Items={paymentDetails}*/}

      {/*                       />*/}
      {/*                      </Col>*/}
      {/*                      </div>*/}
      {/*                    </Row>*/}
      {/*                    /!* <Row>*/}
      {/*                                            <Col xl={12} lg={12} md={12}>*/}
      {/*                                                    <div className="d-flex justify-content-between">*/}
      {/*                                                        <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>*/}
      {/*                                                        <p className="mt-2 profile-detail-title d-flex align-items-center" style={{fontSize: '16px', marginRight: '80px'}}>{moment(paymentDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p>*/}
      {/*                                                    </div>   */}
      {/*                                                </Col>*/}
      {/*                                            </Row>*/}
      {/*                                            <Row>*/}
      {/*                                                <Col xl={12} lg={12} md={12}>*/}
      {/*                                                    <div className="amount-wrapper booking-confirm">*/}
      {/*                                                        <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                            <p className="price-title">Transaction ID</p>*/}
      {/*                                                            <p className="price-value">{paymentDetails?.transactionId}</p>*/}
      {/*                                                        </div>*/}
      {/*                                                        <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                            <p className="price-title">Payment Type</p>*/}
      {/*                                                            <p className="price-value">Credit Card</p>*/}
      {/*                                                        </div>*/}
      {/*                                                        <Divider />*/}
      {/*                                                        <div className="d-flex justify-content-between align-items-center">*/}
      {/*                                                            <p className="price-title">Caregiver Price</p>*/}
      {/*                                                            <p className="price-value">${paymentDetails?.caregiverBasePrice}</p>*/}
      {/*                                                        </div>*/}
      {/*                                                        <div className="d-flex justify-content-between align-items-center total">*/}
      {/*                                                            <p className="price-title">Total Amount</p>*/}
      {/*                                                            <p className="price-value">${paymentDetails?.totalAmount}</p>*/}
      {/*                                                        </div>*/}
      {/*                                                    </div>*/}
      {/*                                                </Col>*/}
      {/*                                            </Row> *!/*/}
      {/*                  </Col>*/}
      {/*                </Row>*/}
      {/*              </div>*/}
      {/*              {paymentDetails?.statusType == 1 ? (*/}
      {/*                <div*/}
      {/*                  className="d-flex text-center px-3 "*/}
      {/*                  style={{ justifyContent: "right" }}*/}
      {/*                >*/}
      {/*                  <Button*/}
      {/*                    // htmlType="submit"*/}
      {/*                    //   onClick={() => navigate("/client/appointment/create-appointment/caregivers")}*/}
      {/*                    onClick={() =>*/}
      {/*                      handleDeclineModalVisible(paymentDetails?._id)*/}
      {/*                    }*/}
      {/*                    className="btn-back auth-action-btn mt-3 mb-3 mx-3"*/}
      {/*                    shape="round"*/}
      {/*                    size="small"*/}
      {/*                  >*/}
      {/*                    Decline*/}
      {/*                  </Button>*/}
      {/*                  <Button*/}
      {/*                    // htmlType="submit"*/}
      {/*                    // onClick={() => navigate("/client/appointment")}*/}
      {/*                    // disabled={!selectedCard}*/}
      {/*                    onClick={() =>*/}
      {/*                      handleCaregiverActions(false, paymentDetails?._id)*/}
      {/*                    }*/}
      {/*                    className="btn-black auth-action-btn mt-3 mb-3"*/}
      {/*                    shape="round"*/}
      {/*                    type="primary"*/}
      {/*                    size="small"*/}
      {/*                  >*/}
      {/*                    Accept*/}
      {/*                  </Button>*/}
      {/*                </div>*/}
      {/*              ) : paymentDetails?.statusType != 4 &&*/}
      {/*                paymentDetails?.statusType != 3 &&*/}
      {/*                paymentDetails?.statusType != 6 ? (*/}
      {/*                <div className="d-flex  align-items-center" style={{flexDirection:"row", alignItems:"center", maxHeight:"17vh", overflowY:"scroll"}}>*/}
      {/*                  {paymentDetails?.statusType != 5 ? (*/}
      {/*                    <Button*/}
      {/*                      onClick={*/}
      {/*                        () => handleCancelModalVisible(paymentDetails?._id)*/}
      {/*                        // handleCancelAppointment(paymentDetails?._id)*/}
      {/*                      }*/}
      {/*                      className="cancel-btn btn-back mt-4"*/}
      {/*                      shape="round"*/}
      {/*                      type="primary"*/}
      {/*                      size="small"*/}
      {/*                      style={{width:"30%", fontSize:"8px"}}*/}
      {/*                    >*/}
      {/*                      Cancel*/}
      {/*                    </Button>*/}
      {/*                  ) : (*/}
      {/*                    <div></div>*/}
      {/*                  )}*/}
      {/*                  <div*/}
      {/*                    className="d-flex text-right mt-4"*/}
      {/*                    style={{ justifyContent: "right", flexDirection:"row", alignItems:"center",  }}*/}
      {/*                  >*/}
      {/*                    {paymentDetails?.statusType == 5 ? (*/}
      {/*                      <Button*/}
      {/*                        // htmlType="submit"*/}
      {/*                        onClick={() =>*/}
      {/*                          handleCompleteModalVisible(paymentDetails?._id)*/}
      {/*                        }*/}
      {/*                        // style={!(endTimeMinutes < 30) ? {opacity: 0.6} : {}}*/}
      {/*                        className="btn-back mt-3 mb-3 mx-3"*/}
      {/*                        shape="round"*/}
      {/*                        size="small"*/}
      {/*                        // disabled={!(endTimeMinutes < 30)}*/}
      {/*                        style={{width:"8rem"}}*/}
      {/*                      >*/}
      {/*                        Complete*/}
      {/*                      </Button>*/}
      {/*                    ) : (*/}
      {/*                      <Button*/}
      {/*                        onClick={() =>*/}
      {/*                          handleStartModalVisible(paymentDetails?._id)*/}
      {/*                        }*/}
      {/*                        className="btn-back mt-3 mb-3 mx-3"*/}
      {/*                        shape="round"*/}
      {/*                        type="primary"*/}
      {/*                        size="small"*/}
      {/*                        style={{width:"60%"}}*/}
      {/*                      >*/}
      {/*                        Start*/}
      {/*                      </Button>*/}
      {/*                    )}*/}
      {/*                    <Button*/}
      {/*                      // htmlType="submit"*/}
      {/*                      onClick={() =>*/}
      {/*                        navigate("/caregiver/appointment/messages")*/}
      {/*                      }*/}
      {/*                      // disabled={!selectedCard}*/}
      {/*                      className="btn-black mt-3 mb-3"*/}
      {/*                      shape="round"*/}
      {/*                      type="primary"*/}
      {/*                      size="medium"*/}
      {/*                      style={{width:"8rem"}}*/}
      {/*                    >*/}
      {/*                      Message*/}
      {/*                    </Button>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              ) : (*/}
      {/*                ""*/}
      {/*              )}*/}
      {/*              /!* <RatingModal*/}
      {/*                                    reviewModalVisible={reviewModalVisible}*/}
      {/*                                    handleRatingModalClose={handleRatingModalClose}*/}
      {/*                                /> *!/*/}
      {/*              <CancelModel*/}
      {/*                cancelModalVisible={cancelModalVisible}*/}
      {/*                handleCancelModalClose={handleCancelModalClose}*/}
      {/*                handleCancelAppointment={handleCancelAppointment}*/}
      {/*              />*/}
      {/*              <CompleteModal*/}
      {/*                isAddExpenses={isAddExpenses}*/}
      {/*                completeModalVisible={completeModalVisible}*/}
      {/*                handleCompleteModalClose={handleCompleteModalClose}*/}
      {/*                handleCompleteAppointment={handleCompleteAppointment}*/}
      {/*              />*/}
      {/*              <StartModal*/}
      {/*                startModalVisible={startModalVisible}*/}
      {/*                handleStartModalClose={handleStartModalClose}*/}
      {/*                handleStartAppointment={handleStartAppointment}*/}
      {/*              />*/}
      {/*              <DeclineModel*/}
      {/*                declineModalVisible={declineModalVisible}*/}
      {/*                handleDeclineModalClose={handleDeclineModalClose}*/}
      {/*                handleCaregiverActions={handleCaregiverActions}*/}
      {/*              />*/}
      {/*              <AddExpense*/}
      {/*                isAddExpenseScreen={isAddExpenseScreen}*/}
      {/*                addExpenseScreen={addExpenseScreen}*/}
      {/*                isAppointmentForm={isAppointmentForm}*/}
      {/*                isAppointentInput={isAppointentInput}*/}
      {/*                isAddExpenses={isAddExpenses}*/}
      {/*                isExpensesAdded={isExpensesAdded}*/}
      {/*                isAppointmentDetails={isAppointmentDetails}*/}
      {/*                handleCompleteAppointment={handleCompleteAppointment}*/}
      {/*                onChange={onChange}*/}
      {/*                // onPreview={onPreview}*/}
      {/*                setFileList={setFileList}*/}
      {/*                fileList={fileList}*/}
      {/*                setAdditionalNotes={setAdditionalNotes}*/}
      {/*                additionalNotes={additionalNotes}*/}
      {/*              />*/}
      {/*            </Spin>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  ) :*/}
      {/*  (*/}
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex profile-details-wrapper">
          <Sidebar />
          <div className="mx-4 main-content w-100">
            <AppointmentNavBar />
            <div
              className="border-less rounded h-mob-auto c-app-card caregiver-appointment-row-main"
              style={{
                background: "#fff",
                padding: "15px 25px",
                height: "79vh",
              }}
            >
              <div>
                {/* <div className="d-flex justify-content-between">
                                    <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>
                                    {/* <p className="mt-2 mb-3 profile-detail-title" style={{fontSize: '16px',}}>{moment(bookingDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p> */}
                {/* </div> */}
                <Spin
                  spinning={loading}
                  // tip="loading..."
                >
                  <div
                    className={`payment-wrapper appointment-wrapper`}
                    style={{ width: "100%" }}
                  >
                    <Row>
                      <Col xl={24} lg={24}>
                        <Row>
                          <Col xl={24} lg={24} xs={24}>
                            <Row>
                              <Col lg={12} xl={12} md={16} xs={24}>
                                <div className="d-flex profile-wrapper">
                                  {paymentDetails?.relativeImage ? (
                                    <img
                                      alt={""}
                                      src={paymentDetails?.relativeImage}
                                      width={40}
                                      height={40}
                                      className="br-25 caregiver-img"
                                    />
                                  ) : (
                                    <img
                                      alt={""}
                                      src="/images/icons/Profile.png"
                                      width={40}
                                      height={40}
                                      className="br-25 caregiver-img"
                                    />
                                  )}

                                  <div className="w-80-mob">
                                    <p className="caregiver-name">
                                      {paymentDetails?.relativeName}{" "}
                                      <span className="color-secondary">{` • ${Number(
                                        age
                                      )} Years`}</span>
                                    </p>
                                    <div className="d-flex justify-content-between align-center-mob flex-wrap relative-wrapper">
                                      <span className="book-btn">
                                        Booked by
                                      </span>
                                      {/* <img src="https://mf-homecare-dev.s3.ca-central-1.amazonaws.com/CaregiverProfileChanged/1672992730809_image-20913844-db35-49e2-af3a-2295944b635c-1672992730807.png"
                                                                                width={20} className='br-25 mx-2' /> */}
                                      <span className="relative-name mx-mob-0 mx-2">
                                        {paymentDetails?.clientName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col
                                className="app-confirm"
                                lg={12}
                                md={8}
                                xs={24}
                                style={{ textAlign: "right" }}
                              >
                                {paymentDetails?.statusType == 1 ? (
                                  <Button className="pending">Pending</Button>
                                ) : paymentDetails?.statusType == 5 ? (
                                  <Button className="ongoing">Ongoing</Button>
                                ) : paymentDetails?.statusType == 6 ? (
                                  <Button className="completed">
                                    Completed
                                  </Button>
                                ) : paymentDetails?.statusType == 4 ? (
                                  <Button className="cancelled">
                                    Cancelled
                                  </Button>
                                ) : paymentDetails?.statusType == 3 ? (
                                  <Button className="declined">Declined</Button>
                                ) : paymentDetails?.statusType == 2 ? (
                                  <Button className="upcoming">Upcoming</Button>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-4 mt-mob-0">
                              {paymentDetails?.statusType == 1 ? (
                                <>
                                  <Col xs={24} lg={6} md={24} xl={8}>
                                    <div
                                      className="d-flex address-details-row"
                                      onClick={handleAddressClick}
                                    >
                                      <img
                                        alt=""
                                        src="/images/icons/location.svg"
                                        width={15}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "11px",
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          lineHeight: "22px",
                                          position: "relative",
                                          right: "2px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {`${paymentDetails?.city}, ${paymentDetails?.province}`}
                                      </span>
                                    </div>
                                  </Col>
                                  <Divider
                                    className="d-md-none"
                                    type="vertical"
                                    style={{ height: "40px" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Col xs={24} lg={24} xl={8} md={24}>
                                    <div
                                      className="d-flex address-details-row text-left-mob"
                                      onClick={handleAddressClick}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <img
                                        alt=""
                                        src="/images/icons/location.svg"
                                        width={15}
                                      />
                                      <span
                                        style={{
                                          marginLeft: "11px",
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          lineHeight: "22px",
                                          position: "relative",
                                          right: "2px",
                                        }}
                                      >
                                        {`${paymentDetails?.address}, ${paymentDetails?.city}, ${paymentDetails?.province}, ${paymentDetails?.postalCode}`}
                                      </span>
                                    </div>
                                  </Col>
                                  <Divider
                                    className="d-md-none"
                                    type="vertical"
                                    style={{ height: "40px" }}
                                  />
                                </>
                              )}
                              <Col xs={24} lg={24} xl={15} md={24}>
                                <Row className="time-details-row">
                                  <Col xs={8} lg={7} md={7} xl={8}>
                                    <div
                                      className="d-flex right-mob-0 justify-flex-start-mob"
                                      style={{
                                        right: "20px",
                                        position: "relative",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src="/images/icons/time.svg"
                                        width={20}
                                      />
                                      <span
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "16px",
                                          lineHeight: "22px",
                                          marginLeft: "11px",
                                        }}
                                      >
                                        {paymentDetails?.totalHour} Hours
                                      </span>
                                    </div>
                                  </Col>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "40px" }}
                                  />
                                  <Col xs={8} lg={7} md={7} xl={7}>
                                    <span className="profile-detail-title">
                                      Hourly Rate
                                    </span>
                                    <p className="profile-detail-val">
                                      ${paymentDetails?.caregiverBasePrice}
                                    </p>
                                  </Col>
                                  <Divider
                                    type="vertical"
                                    style={{ height: "40px" }}
                                  />
                                  <Col xs={8} lg={7} md={7} xl={7}>
                                    <span className="profile-detail-title">
                                      Total
                                    </span>
                                    <p className="profile-detail-val">
                                      ${paymentDetails?.totalAmount}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Divider
                              type="horizontal"
                              style={{ marginTop: "10px" }}
                            />
                          </Col>
                          <div
                            className="max-height-auto caregiver-appoinment-details-div height-adjust-screen-size"
                            style={{
                              maxHeight:
                                paymentDetails?.statusType == 1 ||
                                paymentDetails?.statusType == 2 ||
                                paymentDetails?.statusType == 3
                                  ? "47vh"
                                  : "47vh",
                              overflow: "auto",
                              width: "100%",
                            }}
                          >
                            <Col xl={24} lg={24} xs={24} className="mb-3">
                              <span className="profile-detail-title">
                                Services
                              </span>
                              <Row className="mt-3 mt-mob-0 service-appointment-row">
                                {paymentDetails?.serviceList.map((i) => {
                                  return (
                                    <Col
                                      xs={12}
                                      lg={8}
                                      md={12}
                                      sm={12}
                                      xl={6}
                                      className="mx-mob-0 service-appointment-col mt-2"
                                    >
                                      <div className="d-flex align-items-center service-offer-card">
                                        <img
                                          alt=""
                                          src={i.image}
                                          width={50}
                                          height={50}
                                        />
                                        <span className="service-offer-text">
                                          {i?.name}
                                        </span>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                            <Col xl={24} lg={24} xs={24}>
                              <span className="profile-detail-title">
                                Date and Time
                              </span>
                              <Row
                                className={`mt-3 mt-mob-0 service-appointment-row ${
                                  paymentDetails?.statusType == 6
                                    ? "completed-appointment"
                                    : ""
                                }`}
                              >
                                {paymentDetails?.timing?.map((i) => {
                                  return (
                                    <Col
                                      xs={12}
                                      lg={8}
                                      md={8}
                                      sm={8}
                                      xl={4}
                                      className={`mx-mob-0 service-appointment-col mt-2`}
                                    >
                                      <div
                                        className={`service-offer-card d-flex align-items-center w-100 ${
                                          i.statusType == 4
                                            ? "cancelled-appointment"
                                            : ""
                                        } ${
                                          i?._id == getHighlightedID()
                                            ? "ongoing-appointment"
                                            : ""
                                        } ${
                                          i?.statusType == 6
                                            ? "completed-appointment"
                                            : ""
                                        }`}
                                      >
                                        <div className="date-card">
                                          {moment(i.date).format("MMM")}{" "}
                                          <span
                                            style={{
                                              fontWeight: 700,
                                              marginTop: "-5px",
                                            }}
                                          >
                                            {moment(i.date).utc().format("DD")}
                                          </span>
                                        </div>
                                        <span className="time-text">
                                          {moment(i.startTime)
                                            .zone(0)
                                            .format("hh:mm a")}{" "}
                                          to{" "}
                                          {moment(i.endTime)
                                            .zone(0)
                                            .format("hh:mm a")}
                                        </span>
                                        {/* <span className="service-offer-text">{i?.name}</span> */}
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                            {paymentDetails?.statusType != 1 && (
                              <Col
                                xl={24}
                                lg={24}
                                xs={24}
                                style={{ marginTop: "20px" }}
                              >
                                <span className="profile-detail-title">
                                  Map
                                </span>
                                <Row className="mt-3 mb-3">
                                  <Col xl={24} lg={24} xs={24}>
                                    {isLoaded && (
                                      <CaregiverGoogleMap
                                        paymentDetails={paymentDetails}
                                        isLoaded={isLoaded}
                                      />
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            <Col xl={24} lg={24} xs={24} className="mt-3">
                              <span className="profile-detail-title">
                                Additional Comments
                              </span>
                              <Row className="mt-3 mb-3">
                                <span className="profile-detail-title">
                                  {paymentDetails?.additionalNote
                                    ?.additionComments ?? "N/A"}
                                </span>
                              </Row>
                            </Col>

                            <Col xl={24} lg={12} xs={24} className="mt-3">
                              <ExpensesItems Items={paymentDetails} />
                            </Col>
                          </div>
                        </Row>
                        {/* <Row>
                                                <Col xl={12} lg={12} md={12}>
                                                        <div className="d-flex justify-content-between">
                                                            <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>
                                                            <p className="mt-2 profile-detail-title d-flex align-items-center" style={{fontSize: '16px', marginRight: '80px'}}>{moment(paymentDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p>
                                                        </div>   
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12}>
                                                        <div className="amount-wrapper booking-confirm">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="price-title">Transaction ID</p>
                                                                <p className="price-value">{paymentDetails?.transactionId}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="price-title">Payment Type</p>
                                                                <p className="price-value">Credit Card</p>
                                                            </div>
                                                            <Divider />
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <p className="price-title">Caregiver Price</p>
                                                                <p className="price-value">${paymentDetails?.caregiverBasePrice}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center total">
                                                                <p className="price-title">Total Amount</p>
                                                                <p className="price-value">${paymentDetails?.totalAmount}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                      </Col>
                    </Row>
                  </div>
                  {/* <RatingModal
                                        reviewModalVisible={reviewModalVisible}
                                        handleRatingModalClose={handleRatingModalClose}
                                    /> */}
                  <CancelModel
                    cancelModalVisible={cancelModalVisible}
                    handleCancelModalClose={handleCancelModalClose}
                    handleCancelAppointment={handleCancelAppointment}
                    loading={loading}
                    paymentDetails={paymentDetails}
                    userMode={userMode}
                  />
                  <CompleteModal
                    isAddExpenses={isAddExpenses}
                    completeModalVisible={completeModalVisible}
                    handleCompleteModalClose={handleCompleteModalClose}
                    handleCompleteAppointment={handleCompleteAppointment}
                    expensesArr={expensesArr}
                  />
                  <StartModal
                    startModalVisible={startModalVisible}
                    handleStartModalClose={handleStartModalClose}
                    handleStartAppointment={handleStartAppointment}
                  />
                  <DeclineModel
                    declineModalVisible={declineModalVisible}
                    handleDeclineModalClose={handleDeclineModalClose}
                    handleCaregiverActions={handleCaregiverActions}
                  />
                  <AddExpense
                    isAddExpenseScreen={isAddExpenseScreen}
                    addExpenseScreen={addExpenseScreen}
                    isAppointmentForm={isAppointmentForm}
                    isAppointentInput={isAppointentInput}
                    isAddExpenses={isAddExpenses}
                    isExpensesAdded={isExpensesAdded}
                    isAppointmentDetails={isAppointmentDetails}
                    handleCompleteAppointment={handleCompleteAppointment}
                    onChange={onChange}
                    // onPreview={onPreview}
                    setFileList={setFileList}
                    fileList={fileList}
                    setAdditionalNotes={setAdditionalNotes}
                    additionalNotes={additionalNotes}
                    currentExpenseUrl={currentExpenseUrl}
                    setCurrentExpenseUrl={setCurrentExpenseUrl}
                    expensesArr={expensesArr}
                    setExpensesArr={setExpensesArr}
                  />
                </Spin>
              </div>
              {paymentDetails?.statusType == 1 ? (
                <div
                  className="d-flex text-right px-3 accept-decline-btn-col px-mob-0 "
                  style={{ justifyContent: "right" }}
                >
                  <Button
                    // htmlType="submit"
                    //   onClick={() => navigate("/client/appointment/create-appointment/caregivers")}
                    onClick={() =>
                      handleDeclineModalVisible(paymentDetails?._id)
                    }
                    className="btn-back appointment-cancel-btn auth-action-btn mt-3 mb-3 mx-3"
                    shape="round"
                    size="large"
                  >
                    Decline
                  </Button>
                  <Button
                    // htmlType="submit"
                    // onClick={() => navigate("/client/appointment")}
                    // disabled={!selectedCard}
                    onClick={() =>
                      handleCaregiverActions(false, paymentDetails?._id)
                    }
                    className="btn-black appointment-schedule-again-btn auth-action-btn mt-3 mb-3"
                    shape="round"
                    type="primary"
                    size="large"
                  >
                    Accept
                  </Button>
                </div>
              ) : paymentDetails?.statusType != 4 &&
                paymentDetails?.statusType != 3 &&
                paymentDetails?.statusType != 6 ? (
                <div className="d-flex justify-content-between details-footer-bottom details-footer-bottom-upcoming align-items-center">
                  {paymentDetails?.statusType != 5 ? (
                    <Button
                      onClick={
                        () => handleCancelModalVisible(paymentDetails?._id)
                        // handleCancelAppointment(paymentDetails?._id)
                      }
                      className="cancel-btn cancel-btn-custom btn-back auth-action-btn mt-4"
                      shape="round"
                      size="small"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  <div
                    className="d-flex text-right complete-start-btn-div px-3 px-mob-0 mt-4 mt-mob-0"
                    style={{ justifyContent: "right" }}
                  >
                    {paymentDetails?.statusType == 5 ? (
                      <Button
                        // htmlType="submit"
                        onClick={() =>
                          handleCompleteModalVisible(paymentDetails?._id)
                        }
                        style={
                          endTimeMinutes < -30 || endTimeMinutes > 30
                            ? { opacity: 0.6 }
                            : {}
                        }
                        className="btn-back complete-start-btn auth-action-btn my-mob-0 mt-3 mb-3 mx-3"
                        shape="round"
                        size="large"
                        disabled={endTimeMinutes < -30 || endTimeMinutes > 30}
                      >
                        Complete
                      </Button>
                    ) : (
                      <Button
                        // htmlType="submit"
                        // onClick={() => navigate("/client/appointment/create-appointment/caregivers")}
                        onClick={() =>
                          handleStartModalVisible(paymentDetails?._id)
                        }
                        style={
                          startTimeMinutes > 10 ||
                          startTimeMinutes < -30 ||
                          calculatedDistance > 500
                            ? { opacity: 0.6 }
                            : {
                                background: "#364bc6",
                                color: "#fff",
                                fontWeight: 700,
                                height: "58px",
                              }
                        }
                        className={`${
                          startTimeMinutes > 10 ||
                          calculatedDistance > 500 ||
                          startTimeMinutes < -30
                            ? "btn-back"
                            : ""
                        } complete-start-btn auth-action-btn start-btn mt-3 mb-3 mx-3`}
                        shape="round"
                        size="large"
                        disabled={
                          startTimeMinutes > 10 ||
                          calculatedDistance > 500 ||
                          startTimeMinutes < -30
                        }
                      >
                        Start
                      </Button>
                    )}
                    <Button
                      // htmlType="submit"
                      onClick={() =>
                        navigate("/caregiver/appointment/messages")
                      }
                      // disabled={!selectedCard}
                      className="btn-black message-btn auth-action-btn start-btn mt-3 mb-3"
                      shape="round"
                      type="primary"
                      size="large"
                    >
                      Message
                    </Button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*  )*/}
      {/*}*/}
    </div>
  );
}

export const CancelModel = (props) => {
  const [checkedVal, setCheckedVal] = useState(null);
  const [cancelReason, setCancelReason] = useState("");

  const {
    cancelModalVisible,
    handleCancelModalClose,
    handleCancelAppointment,
    loading,
    paymentDetails,
    userMode,
  } = props;

  useEffect(() => {
    if (userMode == "caregiver" && paymentDetails?.statusType == 2) {
      // setCheckedVal("next");
    } else if (paymentDetails?.statusType == 1) {
      setCheckedVal("next");
    }
  }, [paymentDetails?.statusType]);

  return (
    <Modal
      className="cancel-modal-container cancel-modal-container-custom"
      centered
      width={390}
      header={null}
      footer={null}
      open={cancelModalVisible}
      onOk={handleCancelModalClose}
      onCancel={handleCancelModalClose}
      closable={false}
    >
      <div className="text-center">
        <img src="/images/icons/cancel.svg" alt="clear" />
      </div>
      <div>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "30px",
            fontWeight: "bold",
          }}
          className="text-center cancel-text"
        >
          Are you sure you want to cancel this appointment(s)?
        </p>
      </div>
      {(userMode == "caregiver" ||
        (userMode == "client" && paymentDetails?.statusType != 1)) && (
        <div>
          <div className="select-checkbox">
            <Checkbox
              checked={checkedVal == "next"}
              onChange={(e) => setCheckedVal("next")}
            >
              Next Appointment
            </Checkbox>
          </div>
          <div className="select-checkbox mt-1">
            <Checkbox
              checked={checkedVal == "all"}
              onChange={(e) => setCheckedVal("all")}
            >
              All Appointments
            </Checkbox>
          </div>
        </div>
      )}
      <div className="text-center mt-3">
        <TextArea
          className="notes"
          placeholder="Tell us the reason"
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </div>
      {userMode == "client" && paymentDetails?.statusType != 1 && (
        <div className="d-flex justify-content-between mt-2">
          <img src="/images/icons/info-circle1.svg" />
          <div style={{ marginLeft: "10px" }}>
            <span
              style={{
                fontSize: "12px",
                color: "#64748B",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              After 24 Hours, cancellation charges will be 50% of the total
              booking amount.
            </span>
          </div>
        </div>
      )}
      <Row>
        <Col
          xs={24}
          style={{
            marginTop: "15px",
          }}
        >
          <div className="confirmButtons common-button-yes-no">
            <Button
              disabled={!checkedVal}
              onClick={() => handleCancelAppointment(checkedVal, cancelReason)}
              className="yes-button"
              loading={loading}
            >
              Yes
            </Button>
            <Button onClick={handleCancelModalClose} className="no-button">
              No
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const CompleteModal = (props) => {
  const [additionalNote, setAdditionalNote] = useState("");
  const {
    completeModalVisible,
    handleCompleteModalClose,
    handleCompleteAppointment,
    isAddExpenses,
    expensesArr,
  } = props;

  return (
    <Modal
      className="complete-modal-container"
      centered
      width={350}
      header={null}
      footer={null}
      open={completeModalVisible}
      onOk={handleCompleteModalClose}
      onCancel={handleCompleteModalClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div>
        <h2 className="text-center">Complete Appointment</h2>
      </div>
      <div className="text-center mt-3">
        <TextArea
          className="notes"
          placeholder="Additional Notes"
          value={additionalNote}
          onChange={(e) => setAdditionalNote(e.target.value)}
        />
      </div>
      {/* {isAppointmentDetails && ( */}
      <div className="Add_Expenses_Form">
        <div className="expense_detailsCard">
          {expensesArr &&
            expensesArr.map((item, key) => {
              console.log(item, "expenseitem");
              return (
                <Row className="Addexpense_details">
                  <div>
                    <Space>
                      <img
                        src={item.image}
                        alt="reciept"
                        className="expensesReceipt_Img"
                      />
                      <div>
                        <Text className="expensesReceipt_Title">
                          {item.title}
                        </Text>
                      </div>
                    </Space>
                  </div>
                  <div style={{ paddingTop: "15px" }}>
                    <Text className="expensesReceipt_Amount">
                      $ {item.amount}
                    </Text>
                  </div>
                </Row>
              );
            })}
        </div>
      </div>

      {/* )} */}
      <div className="d-flex justify-content-between details-footer-bottom-appointment align-items-center">
        <Button
          // htmlType="submit"
          // onClick={() => handleCompleteAppointment(paymentDetails?._id)}
          // onClick={handleCancelAppointment}
          // onClick={handleCompleteAppointment}
          onClick={isAddExpenses}
          className="expense-btn"
          shape="round"
          size="large"
          type="primary"
        >
          Add Expenses
        </Button>
        <Button
          // htmlType="submit"
          // onClick={() => navigate("/client/appointment")}
          // disabled={!selectedCard}
          onClick={() => handleCompleteAppointment(additionalNote)}
          className="complete-btn btn-black auth-action-btn mt-3 mb-3 mx-2"
          shape="round"
          type="primary"
          size="large"
        >
          Complete
        </Button>
      </div>
    </Modal>
  );
};

export const StartModal = (props) => {
  const [webCamShow, setWebcamShow] = useState(false);
  const [uploadedImg, setUploadedImg] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { startModalVisible, handleStartModalClose, handleStartAppointment } =
    props;

  const handleWebcamClose = () => {
    setWebcamShow(false);
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Modal
      className="cancel-modal-container"
      centered
      width={400}
      header={null}
      footer={null}
      open={startModalVisible}
      onOk={handleStartModalClose}
      onCancel={handleStartModalClose}
      closable={false}
      // closeIcon={<CloseCircleOutlined />}
    >
      {uploadedImg ? (
        <>
          <img
            src={uploadedImg}
            width={isMobile ? "100%" : 350}
            height={300}
            alt="door image"
          />
          <div className="d-flex justify-content-center align-items-center">
            <Button
              // htmlType="submit"
              // onClick={() => handleCompleteAppointment(paymentDetails?._id)}
              onClick={handleStartModalClose}
              className="btn-back auth-action-btn mt-3 mb-3 mx-2"
              shape="round"
              size="large"
            >
              Cancel
            </Button>
            <Button
              // htmlType="submit"
              // onClick={() => navigate("/client/appointment")}
              // disabled={!selectedCard}
              onClick={() => handleStartAppointment(uploadedImg)}
              className="appointment-start-btn btn-black auth-action-btn mt-3 mb-3"
              shape="round"
              type="primary"
              size="large"
            >
              Start Appointment
            </Button>
          </div>
        </>
      ) : webCamShow ? (
        <WebcamCapture
          handleWebcamClose={handleWebcamClose}
          setUploadedImg={setUploadedImg}
        />
      ) : (
        <>
          <div
            className="text-center d-flex justify-content-center align-items-center upload-img-wrapper"
            style={{ flexDirection: "column" }}
            onClick={() => setWebcamShow(true)}
          >
            <img src="/images/icons/upload-img.svg" alt="clear" width="120" />
            <Button
              className="upload-img-btn"
              style={{
                height: "50px",
                color: "#fff",
                background: "#364BC6",
                width: "40%",
                marginTop: "20px",
                borderRadius: "23px",
              }}
            >
              Upload Image
            </Button>
          </div>
          <div>
            <p className="text-center upload-text mt-3">
              Upload a picture of the front door and start the appointment
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <Button
              // htmlType="submit"
              // onClick={() => handleCompleteAppointment(paymentDetails?._id)}
              onClick={handleStartModalClose}
              className="btn-back auth-action-btn mt-3 mb-3 mx-2"
              shape="round"
              size="large"
            >
              Cancel
            </Button>
            <Button
              // htmlType="submit"
              // onClick={() => navigate("/client/appointment")}
              disabled={!uploadedImg}
              onClick={() => handleStartAppointment(uploadedImg)}
              className="appointment-start-btn btn-black auth-action-btn mt-3 mb-3"
              shape="round"
              type="primary"
              size="large"
            >
              Start Appointment
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

const WebcamCapture = (props) => {
  const token = localStorage.getItem("token");
  const { handleWebcamClose, setUploadedImg } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Webcam
      audio={false}
      height={300}
      screenshotFormat="image/jpeg"
      width={isMobile ? "100%" : 350}
      //   videoConstraints={videoConstraints}
    >
      {({ getScreenshot }) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="upload-btn btn-black auth-action-btn mt-3 mb-3 mx-2 px-3"
            onClick={() => {
              const imageSrc = getScreenshot();
              const updatedImg = dataURLtoFile(imageSrc);
              let formData = new FormData();
              formData.append("image", updatedImg);
              formData.append("path", "frontdoorimage");
              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/user/upload-image`,
                  formData,
                  {
                    headers: {
                      accessToken: token,
                      Authorization:
                        "Basic " +
                        btoa(
                          process.env.REACT_APP_USERNAME +
                            ":" +
                            process.env.REACT_APP_PASSWORD
                        ),
                    },
                  }
                )
                .then((response) => {
                  if (response.data.status === 200) {
                    setUploadedImg(response.data.output.imageUrl1.location);
                    handleWebcamClose();
                  }
                });
            }}
          >
            Capture photo
          </Button>
          <Button
            className="upload-cancel-btn btn-back auth-action-btn mt-3 mb-3 mx-2"
            onClick={handleWebcamClose}
          >
            Cancel
          </Button>
        </div>
      )}
    </Webcam>
  );
};

export const DeclineModel = (props) => {
  const {
    declineModalVisible,
    handleDeclineModalClose,
    handleCaregiverActions,
  } = props;
  return (
    <Modal
      className="cancel-modal-container"
      centered
      width={350}
      header={null}
      footer={null}
      open={declineModalVisible}
      onOk={handleDeclineModalClose}
      onCancel={handleDeclineModalClose}
      closable={false}
    >
      <div className="text-center">
        <img src="/images/icons/cancel.svg" alt="clear" />
      </div>
      <div>
        <p className="text-center cancel-text">
          Are you sure you want to decline this appointment?
        </p>
      </div>
      <Row>
        <Col
          xs={24}
        >
          <div className="d-flex justify-content-between align-items-center">
            <Button
              // htmlType="submit"
              // onClick={() => handleCompleteAppointment(paymentDetails?._id)}
              onClick={() => handleCaregiverActions(true)}
              className="btn-back auth-action-btn yes-btn-hover mt-3 mb-3 btn-48"
              shape="round"
              size="large"
            >
              Yes
            </Button>
            <Button
              // htmlType="submit"
              // onClick={() => navigate("/client/appointment")}
              // disabled={!selectedCard}
              onClick={handleDeclineModalClose}
              className="btn-black auth-action-btn mt-3 mb-3 btn-48"
              shape="round"
              type="primary"
              size="large"
            >
              No
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
const AddExpense = (props) => {
  const [expenses, setExpenses] = useState("");
  const [expensesAmount, setExpensesAmount] = useState("$");
  const expenseForm = useRef();
  // const [additionalNotes, setAdditionalNotes] = useState('')
  const {
    addExpenseScreen,
    isAddExpenseScreen,
    isAppointmentForm,
    isAppointentInput,
    isAddExpenses,
    isExpensesAdded,
    isAppointmentDetails,
    handleCompleteAppointment,
    //   onPreview,
    onChange,
    fileList,
    setFileList,
    setAdditionalNotes,
    additionalNotes,
    currentExpenseUrl,
    setCurrentExpenseUrl,
    expensesArr,
    setExpensesArr,
  } = props;

  console.log(expensesAmount, expenses, "expensesAmount", expensesArr);
  const handleFinished = (values) => {
    console.log(currentExpenseUrl, "currentExpenseUrl");
    console.log(values, "values", fileList, fileList[0]?.name);
    let obj = {
      amount: values.amount.toFixed(2),
      title: values.title,
      image: currentExpenseUrl,
    };
    setExpensesArr((prev) => [...prev, obj]);
    // ExpensesArray.push(obj);
    isExpensesAdded();
    setFileList([]);
    expenseForm.current.resetFields();
    // setExpensesAmount(null)
    // setCurrentExpenseUrl("");
  };
  // console.log("ExpensesArray", ExpensesArray, fileList, expensesAmount, "expensesAmount");
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={addExpenseScreen}
      onCancel={isAddExpenseScreen}
      closable
      closeIcon={<CloseCircleOutlined />}
      // destroyOnClose
    >
      <div
        layout="vertical"
        className="Add_Expenses_Form"
        //  onFinish={handleCompleteAppointment}
      >
        {/* {isAppointentInput && (
                    <>
                        <div className="image-container">
                            <h2 className="text-center">Complete Appointment</h2>
                        </div>
                        <Form.Item name="notes">
                            <TextArea
                                rows={6}
                                placeholder="Additional Notes"
                                onChange={(e) => setAdditionalNotes(e.target.value)}
                            />
                        </Form.Item>
                        {/* <>
                            
                        </> */}
        {/* <Row gutter={24}>
                            <Col span={10}>
                                <Button
                                    // htmlType="submit"
                                    onClick={isAddExpenses}
                                    className="btn-primary mx-auto expense_popup_AddExpense"
                                    shape="round"
                                    type="primary"
                                    size="large"
                                >
                                    Add Expenses
                                </Button>
                            </Col>
                            <Col span={14}>
                                <Button
                                    // htmlType="submit"
                                    onClick={handleCompleteAppointment}
                                    className=" btn-black mx-auto expense_popup_complete"
                                    shape="round"
                                    type="primary"
                                    size="large"
                                >
                                    Complete
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
                {isAppointmentForm && ( */}
        <Form onFinish={handleFinished} ref={expenseForm}>
          <div className="image-container">
            <h2 className="text-center">Add Expenses</h2>
          </div>
          <Form.Item valuePropName="fileList">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
            >
              {fileList.length < 1 && (
                <div className="image-container">
                  <img
                    alt=""
                    src="/images/icons/upload-img.svg"
                    className="text-center UploadImage_Form"
                  />
                  <div className="upload_Image_content">Upload Image</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Row>
            <Col xs={24}>
              <div className="Expense_FormInputs">
                <Form.Item name="title">
                  <Input placeholder=" Expense Title" />
                </Form.Item>
                <Form.Item name="amount">
                  <InputNumber
                    placeholder=" $ Amount"
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={`$ ${expensesAmount} `}
                    onChange={(e) => setExpensesAmount(`$ ${e.target.value}`)}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Button
                // htmlType="submit"
                onClick={isAddExpenseScreen}
                className=" mx-auto mx-mob-0 expense_popup_cancel"
                shape="round"
                type="primary"
                size="large"
              >
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="submit"
                // onClick={isExpensesAdded}
                className="btn-black mx-auto expense_popup_okay"
                shape="round"
                type="primary"
                size="large"
              >
                Add Expense
              </Button>
            </Col>
          </Row>
        </Form>
        {/* )} */}
      </div>
    </Modal>
  );
};

const CaregiverGoogleMap = (props) => {
  const { isLoaded, paymentDetails } = props;
  const updatedLocDetails = {
    lat: parseFloat(paymentDetails?.location?.lat),
    lng: parseFloat(paymentDetails?.location?.lon),
  };

  const style = {
    maxWidth: "100%",
    height: "300px",
    overflowX: "hidden",
    overflowY: "hidden",
  };
  const containerStyle = {
    maxWidth: "100%",
    height: "300px",
    opacity: 0.6,
  };

  return isLoaded ? (
    <GoogleMap
      style={style}
      center={updatedLocDetails}
      zoom={15}
      mapContainerStyle={containerStyle}
      mapContainerClassName="gmap-wrapper"
      options={{
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        minZoom: 15,
        maxZoom: 22,
      }}
    >
      {/* <Marker position={updatedLocDetails} icon={"/images/icons/bird-icon.svg"} width={20} /> */}
      <Marker
        position={{
          lat: parseFloat(paymentDetails?.location?.lat),
          lng: parseFloat(paymentDetails?.location?.lon),
        }}
      />
    </GoogleMap>
  ) : (
    <div>
      <Spin spinning={isLoaded} />
    </div>
  );
};

import React, {useState} from 'react';
import {
  DatePicker,
  Typography,
  Space,
  Divider,
} from 'antd';
import InputBox from '../components/forms/input-box';
import CheckBoxComponent from '../components/forms/check-box';
import '../common/styles/forms.less'

const { Title } = Typography;

const App = () => {
  
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const [checkValue, setCheckValue] = useState([])


  const genders = [
    {
      icon:<img className='me-3' width={25} src='/images/icons/male.svg' alt='male' />,
      activeIcon:<img className='me-3' width={25} src='/images/icons/female.svg' alt='male' />,
      name:"Male",
      value:"male"
    },
    {
      icon:<img className='me-3' width={25} src='/images/icons/female.svg' alt='female' />,
      name:"Female",
      value:"female"
    },
    {
      icon:<img className='me-3' width={25} src='/images/icons/male.svg' alt='others' />,
      name:"Others",
      value:"other"
    }
  ]

  const handleCheckboxs = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setCheckValue(checkedValues)
  };

  return (<>
    <section style={{ textAlign: 'center', marginTop: 48, marginBottom: 40 }}>
      <Space align="start">
        <img
          style={{width: 40, height: 40 }}
          src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
          alt="Ant Design"
        />
        <Title level={2} style={{ marginBottom: 0 }}>
          Ant Design
        </Title>
      </Space>
    </section>
    <Divider style={{ marginBottom: 60 }}>Form</Divider>
    <div style={{maxWidth:"400px", padding: "20px",margin:"0 auto"}}>

    </div>
    <InputBox />
    <DatePicker style={{ width: '100%' }}
    clearIcon={null}
      suffixIcon={<img src="/images/icons/calendar.svg" alt="calendar" />}
       size='large' onChange={onChange} />
<br>
</br>
<br></br>
<CheckBoxComponent
values={checkValue}
items={genders}
onChange={handleCheckboxs} />
  </>
)}

export default App;
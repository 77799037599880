import {
  Row,
  Typography,
  Col,
  Select,
  Space,
  Modal,
  Button,
  Empty,
  Table,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar";
import { connect, useDispatch, useSelector } from "react-redux";
import "./Earnings.less";
import moment from "moment";
import { setAlert } from "../../../redux/alert/alert.action";
import axios from "axios";
import receipt from "../../../Images/receipt.svg";
import { useNavigate } from "react-router-dom";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Breadcrumbs from "../../../common/Breadcrumbs";
import Footer from "../../../components/Footer/Footer";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import Responsiveheader from "../../../components/Responsiveheader";

const { Text } = Typography;
const { Option } = Select;
const Earnings = ({ accessToken }) => {
  const dispatch = useDispatch();
  const [relativeList, setRelativeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentYear = moment().format("YYYY");
  const [year, setYear] = useState(currentYear);
  const [ispageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [receiptpopup, setReceiptpopup] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    fetchRelatives(accessToken, year);
  }, [accessToken, ispageLimit, page]);
  const handleReceiptModalClose = () => {
    setReceiptpopup(false);
  };
  const ReceiptPopUp = () => {
    setReceiptpopup(true);
  };
  const fetchRelatives = async (token, year) => {
    setLoading(true);
    if (token !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/earning-report`,
          {
            page: page,
            pageLimit: ispageLimit,
            year: year,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          // console.log("response", response);
          if (response.data.status === 200) {
            setRelativeList(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setLoading(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        });
    }
  };
  const columns = [
    {
      dataIndex: "transactionName",
      key: "transactionName",
      // width: "15%",
      render: (transactionName) => (
        <Space>
          {/* <img src={receipt} alt="Img" className="" onClick={ReceiptPopUp} />{" "} */}
          <Text className="expenses_client_title">{transactionName}</Text>
        </Space>
      ),
    },
    {
      dataIndex: "transactionDate",
      key: "transactionDate",
      // width: "15%",
      render: (transactionDate) => (
        <Text className="Transaction_Date">
          {" "}
          {moment(transactionDate).format("MMMM DD, YYYY")}
        </Text>
      ),
    },
    {
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      align: "right",
      render: (transactionAmount) => (
        <>
          <Text className="Transaction_Amount">
            $ {transactionAmount || ""}
          </Text>
        </>
      ),
    },
  ];
  const handleChange = (value) => {
    console.log("value", value);
    setYear(value);
    fetchRelatives(accessToken, value);
  };
  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.earnings)
    );
  }, []);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {/*{ isMobile ? (<div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
      {/*            <div className="p-3 pt-4 d-flex w-100">*/}
      {/*              <Responsiveheader   title="Total Earnings" />*/}
      {/*            </div>*/}
      {/*            <div className="w-100 careGiver_TotalEarnings">*/}

      {/*            <div className="Earnings_cards">*/}
      {/*                <Row gutter={[8,24]}>*/}
      {/*                  <Col span={24}>*/}
      {/*                    <div className="Earnings_cardbody">*/}
      {/*                      <Text className="Earnings_amount">*/}
      {/*                        $ {relativeList?.totalAmount || ""}*/}
      {/*                      </Text>*/}
      {/*                      <br />*/}
      {/*                      <Text className="Earnings_amountType">Total Earning</Text>*/}
      {/*                    </div>*/}
      {/*                  </Col>*/}
      {/*                  <Space/>*/}
      {/*                    <Col  span={24} >*/}
      {/*                      <div className="Earnings_cardbody">*/}
      {/*                        <Text*/}
      {/*                          className="Earnings_amount"*/}
      {/*                          style={{ color: "#F68B29" }}*/}
      {/*                        >*/}
      {/*                          $ {relativeList?.pendingAmount || ""}*/}
      {/*                        </Text>*/}
      {/*                        <br />*/}
      {/*                        <Text className="Earnings_amountType">Pending</Text>*/}
      {/*                      </div>*/}
      {/*                    </Col>*/}
      {/*                    <Space/>*/}
      {/*                    <Col  span={24}>*/}
      {/*                      <div className="Earnings_cardbody">*/}
      {/*                        <Text*/}
      {/*                          className="Earnings_amount"*/}
      {/*                          style={{ color: "#364BC6" }}*/}
      {/*                        >*/}
      {/*                          $ {relativeList?.depositAmount || ""}*/}
      {/*                        </Text>*/}
      {/*                        <br />*/}
      {/*                        <Text className="Earnings_amountType">Deposit</Text>*/}
      {/*                      </div>*/}
      {/*                    </Col>*/}
      {/*                </Row>*/}
      {/*              </div>*/}
      {/*              <div className="Transactions_mobile_Container">*/}
      {/*                <Row className="Transactions_Header">*/}
      {/*                  <Text className="Earnings_Transaction_Header">*/}
      {/*                    All Transactions*/}
      {/*                  </Text>*/}
      {/*                  <div>*/}
      {/*                    <Space>*/}
      {/*                      {" "}*/}
      {/*                      <Text className="Earnings_select_year">*/}
      {/*                        Select Year*/}
      {/*                      </Text>{" "}*/}
      {/*                      <Select*/}
      {/*                        className="Select"*/}
      {/*                        defaultValue="2023"*/}
      {/*                        style={{*/}
      {/*                          width: 88,*/}
      {/*                        }}*/}
      {/*                        onChange={handleChange}*/}
      {/*                      >*/}
      {/*                        <Option value="2023">2023</Option>*/}
      {/*                        <Option value="2022">2022</Option>*/}
      {/*                      </Select>*/}
      {/*                    </Space>*/}
      {/*                  </div>*/}
      {/*                </Row>*/}

      {/*                <div*/}
      {/*                  className="*/}
      {/*                "*/}
      {/*                >*/}
      {/*                  {relativeList.list?.length > 0 ? (*/}
      {/*                    <>*/}
      {/*                      {" "}*/}
      {/*                      <Table*/}
      {/*                        dataSource={relativeList?.list}*/}
      {/*                        columns={columns}*/}
      {/*                        pagination={false}*/}
      {/*                        // style={{ height: "50vh", overflowY:'auto' }}*/}
      {/*                        scroll={{ x:350 ,}}*/}
      {/*                        // pagination={{*/}
      {/*                        //   // pageSizeOptions: ["10", "20"],*/}
      {/*                        //   pageSize: ispageLimit,*/}
      {/*                        //   total: relativeList?.list?.length,*/}
      {/*                        //   showSizeChanger: true,*/}
      {/*                        //   onChange: (page, ispageLimit) => {*/}
      {/*                        //     setPage(page);*/}
      {/*                        //     setPageLimit(ispageLimit);*/}
      {/*                        //   },*/}
      {/*                        // }}*/}
      {/*                      />*/}
      {/*                    </>*/}
      {/*                  ):loading?(*/}
      {/*<>*/}
      {/*<div className="no-data-wrapper">*/}
      {/*                    <Spin    className="no-data-text"/>*/}
      {/*                    */}
      {/*                    <h4>Loading  ...</h4>*/}
      {/*                    </div>*/}
      {/*</>*/}
      {/*                  ):!loading?(*/}
      {/*<>*/}
      {/*                    <div className="no-data-wrapper">*/}
      {/*                    <img*/}
      {/*                     src="/images/icons/no-transactions.png"*/}
      {/*                      alt="No data"*/}
      {/*                    />*/}
      {/*                    <span className="no-data-text">*/}
      {/*                    No Transactions*/}
      {/*                    </span>*/}
      {/*                  </div>*/}
      {/*                    */}
      {/*                    </>*/}
      {/*                  ):''}*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*           */}
      {/*          </div>*/}
      {/*          <div*/}
      {/*          >*/}
      {/* <Space size={20}>*/}

      {/* <div></div>*/}
      {/* </Space>*/}
      {/*          </div>*/}
      {/*         */}
      {/*          */}
      {/*</div>      ) : (*/}

      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div className="container-fluid appointment-dashboard">
          <div className="pt-5 d-flex client_Expenses">
            <Sidebar />
            <div className="mx-4 message-inner-row w-100 careGiver_TotalEarnings">
              <div className="breadCrumbs_item">
                <AppointmentNavBar BreadcrumbsView={false} />
              </div>

              <div className="Earnings_cards">
                <Row gutter={24}>
                  <Col span={8} md={8} lg={8} xl={8} xs={24}>
                    <div className="Earnings_cardbody">
                      <Text className="Earnings_amount">
                        $ {relativeList?.totalAmount || ""}
                      </Text>
                      <br />
                      <Text className="Earnings_amountType">
                        Total Earnings
                      </Text>
                    </div>
                  </Col>
                  <Col span={8} md={8} lg={8} xl={8} xs={24}>
                    <div className="Earnings_cardbody">
                      <Text
                        className="Earnings_amount"
                        style={{ color: "#F68B29" }}
                      >
                        $ {relativeList?.pendingAmount || ""}
                      </Text>
                      <br />
                      <Text className="Earnings_amountType">Pending</Text>
                    </div>
                  </Col>
                  <Col span={8} md={8} lg={8} xl={8} xs={24}>
                    <div className="Earnings_cardbody">
                      <Text
                        className="Earnings_amount"
                        style={{ color: "#364BC6" }}
                      >
                        $ {relativeList?.depositAmount || ""}
                      </Text>
                      <br />
                      <Text className="Earnings_amountType">Deposit</Text>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="Transactions_Container">
                <Row className="Transactions_Header">
                  <Text className="Earnings_Transaction_Header">
                    All Transactions
                  </Text>
                  <div>
                    <Space>
                      {" "}
                      <Text className="Earnings_select_year">
                        Select Year
                      </Text>{" "}
                      <Select
                        className="Select"
                        defaultValue={currentYear}
                        style={{
                          width: 88,
                        }}
                        onChange={handleChange}
                      >
                        {[currentYear, currentYear - 1, currentYear - 2].map(
                          (year) => {
                            return <Option value={year}>{year}</Option>;
                          }
                        )}
                      </Select>
                    </Space>
                  </div>
                </Row>
                <div>
                  {relativeList.list?.length > 0 ? (
                    <>
                      {" "}
                      <Table
                        dataSource={relativeList?.list}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: 350 }}
                        // pagination={{
                        //   // pageSizeOptions: ["10", "20"],
                        //   pageSize: ispageLimit,
                        //   total: relativeList?.list?.length,
                        //   showSizeChanger: true,
                        //   onChange: (page, ispageLimit) => {
                        //     setPage(page);
                        //     setPageLimit(ispageLimit);
                        //   },
                        // }}
                      />
                    </>
                  ) : loading ? (
                    <>
                      <div className="no-data-wrapper">
                        <Spin className="no-data-text" />
                        <h4>Loading ...</h4>
                      </div>
                    </>
                  ) : !loading ? (
                    <>
                      <div className="no-data-wrapper">
                        <img src="/images/icons/bird-icon.svg" alt="No data" />
                        <span className="no-data-text">No Transactions</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {/*)}*/}
      <ReceiptModal
        receiptpopup={receiptpopup}
        handleReceiptModalClose={handleReceiptModalClose}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Earnings);

const ReceiptModal = (props) => {
  const { receiptpopup, handleReceiptModalClose } = props;
  return (
    <Modal
      className="info-modal-container "
      centered
      width={390}
      header={null}
      footer={null}
      open={receiptpopup}
      onOk={handleReceiptModalClose}
      onCancel={handleReceiptModalClose}
    >
      <div className="Expense_receipt">
        <div className="text-center ">
          <Text className="expenseText">Expense</Text>
          <div className="ExpenseImg">
            <img src="/images/icons/receiptImg.svg" alt="image" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./style.less";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const SetupForm = ({ name, save }) => {
  const saveBack = (tc) => {
    save(tc);
  };
  return (
    <div className="w-82">
      <Elements name={name} stripe={stripePromise}>
        <CheckoutForm name={name} save={saveBack} />
      </Elements>
    </div>
  );
};

export default SetupForm;

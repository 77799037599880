import { Modal } from "antd";
import React from "react";
import PrivacyPolicy from "../privacypolicy";
import TermsAndConditions from "../termsandconditions";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "./footerview.less";
export default function Footer() {
  const [terms, setTerms] = React.useState(false);
  const [Privacy, setPrivacy] = React.useState(false);

  const handleOpen = () => {
    setTerms(true);
    setPrivacy(false);
  };

  const handleClose = () => {
    setTerms(false);
    setPrivacy(false);
  };

  return (
    <>
      <div className="footer">
        <span onClick={handleOpen}>Terms & Conditions</span> and{" "}
        <span
          onClick={() => {
            setPrivacy(true);
            setTerms(false);
          }}
        >
          Privacy Policy
        </span>
      </div>
      <TermAndCondition
        handleClose={handleClose}
        open={terms}
        setTerms={setTerms}
        Privacy={Privacy}
        setPrivacy={setPrivacy}
      />
    </>
  );
}

export const TermAndCondition = (props) => {
  const {
    declineModalVisible,
    handleDeclineModalClose,
    handleCaregiverActions,
  } = props;
  return (
    //   <Modal
    //     className="cancel-modal-container"
    //     centered
    //     width={350}
    //     header={null}
    //     footer={null}
    //     open={props.open}
    //     onOk={props.handleClose}
    //     onCancel={props.handleClose}
    //   >
    //     {/* <div className="text-center">
    //       <img src="/images/icons/cancel.svg" alt="clear" />
    //     </div>
    //     <div>
    //       <p className="text-center cancel-text">
    //         Are you sure you want to decline this appointment?
    //       </p>
    //     </div>

    //     <div className="d-flex justify-content-center align-items-center">
    //       <Button
    //         // htmlType="submit"
    //         // onClick={() => handleCompleteAppointment(paymentDetails?._id)}
    //         onClick={() => handleCaregiverActions(true)}
    //         className="btn-back auth-action-btn mt-3 mb-3 mx-2"
    //         shape="round"
    //         size="large"
    //       >
    //         Yes
    //       </Button>
    //       <Button
    //         // htmlType="submit"
    //         // onClick={() => navigate("/client/appointment")}
    //         // disabled={!selectedCard}
    //         onClick={handleDeclineModalClose}
    //         className="btn-black auth-action-btn mt-3 mb-3"
    //         shape="round"
    //         type="primary"
    //         size="large"
    //       >
    //         No
    //       </Button>
    //     </div> */}
    //   </Modal>

    <>
      <Modal
        open={props.Privacy}
        width={925}
        style={{ overflow: "hidden" }}
        header={null}
        footer={null}
        closable
        centered
        closeIcon={<img src="/images/icons/close-icon-outline-black.svg" alt={""}/>}
        onCancel={() => props.setPrivacy(false)}
        className="termsmodal"
      >
        <div
          className="termsheadings"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <h1
            style={{ textAlign: "center", color: "#364BC6", fontWeight: "700",fontSize:'28px' }}
          >
            {" "}
            Privacy Policy
          </h1>
          <p style={{ textAlign: "center",color:'#64748B',fontSize:'12px',fontWeight:400 }}>
            <span style={{ color: "#0F172A", fontWeight: 700 }}>
              Last updated:
            </span>{" "}
            April, 2023
          </p>
        </div>
        <div style={{ height: "460px", overflowY: "auto" }}>
          <PrivacyPolicy termView={true} setPrivacy={props.setPrivacy} />
        </div>
      </Modal>

      <Modal
        centered
        open={props.open}
        width={925}
        style={{}}
        header={null}
        footer={null}
        className="termsmodal"
        // closable
        closeIcon={<img src="/images/icons/close-icon-outline-black.svg" alt={""}/>}
        onCancel={() => props.setTerms(false)}
      >
        <div
          className="termsheadings"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <h1
            style={{ textAlign: "center", color: "#364BC6", fontWeight: "700",fontSize:'28px' }}
          >
            {" "}
            Terms & Conditions
          </h1>
          <p style={{ textAlign: "center",color:'#64748B',fontSize:'12px',fontWeight:400 }}>
            <span style={{ color: "#0F172A", fontWeight: 700 }}>
              Last updated:
            </span>{" "}
            May, 2023
          </p>
        </div>
        <div style={{ height: "460px", overflowY: "auto" }}>
          <TermsAndConditions setTermsModal={props.setTerms} termView={true} />
        </div>
      </Modal>
    </>
  );
};

import React, { useState } from "react";
import { Button, Input, Form, Row, Col, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { connect, useDispatch } from "react-redux";
import SetupForm from "./card";
import { Logout } from "../../../redux/auth/auth.action";

function CardDetails({ user, next }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [disabledSave, setDisabledSave] = useState(false);
  const [name, setName] = useState("");

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (e) => {
    setName(e.target.value);
  };

  const updateCardData = async (values) => {
    setDisabledSave(true);
    console.log("values", values);
    const data = {
      //"name":name,
      token: values?.id,
    };
    if (user?.accessToken !== "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/user/add-card`, data, {
          headers: {
            accessToken: user?.accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
        .then((response) => {
          console.log("response after adding the card", response);
          if (response.data.status === 200) {
            setDisabledSave(false);
            next();
          } else if (response.data.status >= 400) {
            setDisabledSave(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setDisabledSave(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setDisabledSave(false);
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(Logout());
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  return (
    <Spin spinning={disabledSave}>
      <div className="px-3 px-md-0">
        {/* <div className="text-end color-secondary fs-5 pe-4 pt-4 fw-bolder">
          <span className="pointer" onClick={() => next()}>
            Skip
          </span>
        </div> */}
        {/* <h1 className="color-primary fw-bolder mt-2">
          Credit Card Information
        </h1> */}
        <h1 className="welcome-header fw-bolder mt-4" id="name">
          Credit Card Information
        </h1>
        <p className="welcome-subheader mb-5">
          Enter a credit card to verify and complete your registration. You can
          add additional credit cards at any time in your account settings
        </p>
        <div className="px-md-5 text-start">
          <Form
            form={form}
            name="signup-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            // validateTrigger='onSubmit'

            requiredMark={false}
            className="mb-0 text-start"
          >
            <Form.Item
              name="cardholderName"
              type="text"
              className="mb-1"
              rules={[
                {
                  required: true,
                  message: "Please enter cardholder name",
                },
                {
                  pattern: new RegExp(/^[A-Za-z ]+$/),
                  message:
                    "Field does not accept numbers or special characters.",
                },
              ]}
            >
              <Input
                value={name}
                onChange={handleChange}
                size="large"
                placeholder="Cardholder's Name"
              />
            </Form.Item>
          </Form>
          <SetupForm name={name} save={updateCardData} />
          <div className="d-flex">
            <span
              className="primary-color"
              style={{
                fontSize: "18px",
                fontWeight: 600,
                lineHeight: "24.51px",
                color: "#0F172A",
              }}
            >
              Note:{" "}
            </span>
            <div>
              <p
                className="welcome-subheader mb-2"
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  marginLeft: "10px",
                  textAlign: "justify",
                }}
              >
                Your credit card will not be charged until you book an
                appointment with a caregiver. Your payment details are encrypted
                and processed securely via Stripe. MF Homecare does not see or
                store any credit card information on its servers.
              </p>
              <p
                className="primary-color"
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "30px",
                  marginLeft: "10px",
                  // textAlign: "center",
                }}
              >
                Need help signing up or have questions about our services?{" "}
                <span id="name">Call 1-416-262-0591</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  user: state.loginData.user,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);

import { Card, Modal, Button } from "antd";
import { useState } from "react";
import { CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import "./card.less";
import closeicon from "../../../Images/crossicon.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
export default function CardItem(props) {
  const [handleConfirmDelete, setHandleConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleDeleteClose = () => {
    setHandleConfirmDelete(false);
  };
  const cardImage = {
    Discover: "/images/icons/discover.png",
    "American Express": "/images/icons/amex.png",
    MasterCard: "/images/icons/mastercard.png",
    UnionPay: "/images/icons/unioncard.png",
    Visa: "/images/icons/visa.png",
    "Diners Club": "/images/icons/diners.png",
    Jcb: "/images/icons/jcb.png",
  };
  // console.log(cardImage[props.item.brand],"item image brand")
  const handleDeleteOpen = () => {
    setHandleConfirmDelete(true);
  };
  const handleYesF = () => {
    setHandleConfirmDelete(false);
    if (token !== "") {
      props
        .deleteSingleCardDetails(token, props.item.id)
        .then((res) => {
          if (res.data.status === 200) {
            props
              .fetchCardDetails(token)
              .then((res) => props.setCardDetails(res));
            dispatch(
              setAlert({
                alertType: "success",
                alertMsg: res.data.message,
                alertOpen: true,
              })
            );
          } else if (res.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: res?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  return (
    <>
      <div className="container-fluid">
        <Card>
          <div className="cardinfo">
            <div className="nameicon">
              <span className="fullname">{props.item.name}</span>
              <span
                className="deleteicon"
                onClick={handleDeleteOpen}
                style={{ fontSize: "17px" }}
              >
                <img src="/images/icons/Delete.png" />
              </span>
            </div>
            <div className="cardnumber">
              <span className="item">....</span>
              <span className="item">....</span>
              <span className="item">....</span>
              <span className="item">{props.item.last4}</span>
            </div>
            <div className="carddetails">
              <div className="expirycvv">
                <div className="expirycvvheading">Expiry Date</div>
                <div className="expirycvvvalue">
                  {`${props.item.exp_month}/${props.item.exp_year}`}
                </div>
              </div>
              <div className="expirycvv">
                <div className="expirycvvheading">CVV</div>
                <div className="expirycvvvalue">...</div>
              </div>
              <div className="visaimage">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={cardImage[props.item.brand]}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>

      <DeleteSingleCard
        handleConfirmDelete={handleConfirmDelete}
        handleDeleteClose={handleDeleteClose}
        handleYesF={handleYesF}
      />
    </>
  );
}
const DeleteSingleCard = ({
  handleConfirmDelete,
  handleDeleteClose,
  handleYesF,
}) => {
  return (
    <>
      <Modal
        centered
        width={350}
        header={null}
        footer={null}
        open={handleConfirmDelete}
        closable={false}
        onCancel={() => handleDeleteClose()}
      >
        <div className="crossImage">
          <img alt=""
            src={closeicon}
            className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          />
        </div>

        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "20px",
              textAlign:'center',
              color:'#0F172A',
              fontSize:'18px',
              fontWeight:400
          }}
        >
          Are you sure you want to delete this card?
        </h2>
        <div className="confirmButtons common-button-yes-no">
          <Button className="yes-button" onClick={handleYesF}>Yes</Button>
          <Button className="no-button" onClick={handleDeleteClose}>No</Button>
        </div>
      </Modal>
    </>
  );
};

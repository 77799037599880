import React from "react";
import { Radio, Space } from "antd";

function RadioBox({
  onChange,
  dir,
  defaultValue,
  value,
  options,
  btnClass,
  iconClass,
  sufixStyle,
  prefixStyle,
  isSupport,
}) {
  return (
    <Radio.Group
      size="large"
      className={`w-100`}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      <Space direction={dir} className="custom-radio-space">
        {options?.map((op, index) => {
          return (
            <Radio.Button
              key={index}
              className={`w-100 custom-radio text-dark align-items-center ${btnClass} ${
                op?.value === value ? "checked" : ""
              }`}
              value={op?.value}
            >
              <span className={iconClass} style={prefixStyle}>
                {value === op?.value
                  ? op?.activeIcon === undefined
                    ? op?.icon
                    : op?.activeIcon
                  : op?.icon}
                {isSupport ? (
                  <div style={{ lineHeight: "25px" }}>
                    <span
                      style={{
                        color: value === op?.value ? "#364BC6" : "#0F172A",
                      }}
                    >
                      {op?.name}
                    </span>
                    {isSupport && (
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: value === op?.value ? "#246C9B" : "#64748B",
                        }}
                      >
                        {op.helperText}
                      </span>
                    )}
                  </div>
                ) : (
                  op?.name
                )}
              </span>
              <span className="ms-2">
                {value === op?.value ? (
                  <img
                    width={16}
                    style={sufixStyle}
                    src="/images/icons/checked.svg"
                    alt="checked"
                  />
                ) : (
                  <img
                    width={16}
                    style={sufixStyle}
                    src="/images/icons/unchecked.svg"
                    alt="unchecked"
                  />
                )}
              </span>
            </Radio.Button>
          );
        })}
      </Space>
    </Radio.Group>
  );
}

export default RadioBox;

import React, { useRef, useEffect } from "react";
import "./styles/navbar.less"
function useOutsideAlerter(ref, actionOnClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        actionOnClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.handleOutSideClick);
  return <div ref={wrapperRef} className={props.className}>{props.children}</div>;
}
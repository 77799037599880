import React, { useState, useEffect } from "react";
import { Button } from "antd";
import RadioBox from "../components/forms/v-radio-box";
import "./styles/Welcome.less";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setUserMode } from "../redux/auth/auth.action";
import { handleRouting } from "./helper/handleRoute";
import { setAlert } from "../redux/alert/alert.action";

function Welcome({ accessToken, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleRouting(user, navigate);
  }, [user, accessToken]);

  const [customer, setCustomer] = useState("");

  const handleRadioChange = (e) => {
    setCustomer(e.target.value);
    dispatch(setUserMode(e.target.value));
  };

  const handleNext = () => {
    if (customer !== "") {
      customer === "client"
        ? navigate(`client/signup`)
        : navigate(`caregiver/signup`);
    } else {
      dispatch(
        setAlert({
          alertType: "error",
          alertMsg: "Please select any from Client or Caregiver/Companion",
          alertOpen: true,
        })
      );
    }
  };

  const typeOfCustomers = [
    {
      icon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/client.svg"
          alt="client icon"
        />
      ),
      activeIcon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/client_active.svg"
          alt="client icon"
        />
      ),
      name: "Client",
      helperText: "I'm looking for care",
      value: "client",
    },
    {
      icon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/caregiver.svg"
          alt="caregiver icon"
        />
      ),
      activeIcon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/caregiver_bold.svg"
          alt="caregiver icon"
        />
      ),
      name: "Caregiver/Companion",
      helperText: "I'm looking for jobs",
      value: "caregiver",
    },
  ];
  return (
    <div className="center-container main-div-top min-vh-100">
      <div className="welcome-container justify-content-center d-flex text-center">
        {/* <div className="left-side-poster welcome-poster" /> */}
        <div className="right-side-form">
          <div className="center-container justify-content-start h-100">
            <div className="w-100">
              <img
                className="logo-img pt-0 mb-4 main-logo"
                src="./images/icons/logo_with_name.svg"
                alt="logo"
              />
              <h1 id="name" className="welcome-header mb-2 text-center">
                Welcome to MF Homecare
              </h1>
              <div className="full-width">
                <p className="welcome-subheader mb-3">
                  Our platform connects families in need of home care services
                  with independent caregiving professionals, without the
                  restrictions of traditional agencies
                  {/* {customer === '' ? ' ...' : customer} */}
                </p>
                <div className="mb-3 mt-5 radio-box-div">
                  <RadioBox
                    btnClass="p-3 mb-3 h-100 fs-5 fw-bold"
                    sufixStyle={{ width: 22 }}
                    options={typeOfCustomers}
                    value={customer}
                    defaultValue={"client"}
                    dir="vertical"
                    onChange={handleRadioChange}
                    isSupport
                  />
                </div>
                <Button
                  className="btn-black ps-5 pe-5 home-button common-theme-btn"
                  onClick={() => handleNext()}
                  shape="round"
                  type="primary"
                  size="large"
                  disabled={customer === "" ? true : false}
                  style={{ width: "40%", margin: "auto" }}
                >
                  Sign Up{" "}
                  <img
                    className="ms-2"
                    src="/images/icons/right.svg"
                    alt="right icon"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData?.user?.userType,
    screen: state.loginData?.user?.screen,
    accessToken: state.loginData?.user?.accessToken,
    user: state.loginData?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);

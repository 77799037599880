import React from 'react'
import Responsiveheader from '../../components/Responsiveheader'
import Title from 'antd/lib/skeleton/Title'
import { Navigate } from 'react-router-dom'
import { Button } from 'antd'

function DeleteAccountScreen() {
    return (
        <div className="container-fluid pt-3" style={{ background: "#E4E4E4", minHeight: "100vH" }}>
            <Responsiveheader/>
            <div className='ms-2 me-2' style={{marginTop:"2rem"}}>
                <h2 style={{fontWeight:"700"}}>Are You Sure You want to delete your Account ?</h2>
                <div className='d-flex'>
                    <Button
                        className=" mt-3 mb-3 me-2"
                        style={{
                            width: "57%",
                            height: "80%",
                            marginLeft: "5px",
                            backgroundColor: "#D3D3D3",
                            color: "black",
                            padding: "17px",
                            borderRadius: "35px",
                            fontSize:"17px",fontWeight:"600"
                          }}
                          onClick={()=>{}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-black mt-3 mb-3"
  
                        style={{
                            width: "57%",
                            height: "80%",
                            marginLeft: "5px",
                            color: "black",
                            padding: "20px",
                            borderRadius: "35px",
                            fontSize:"20px",fontWeight:"600"
                          }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DeleteAccountScreen
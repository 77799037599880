import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
//import "../../caregiver/settings.less"
import PasswordStrengthMeter from "../../../common/password-meter/index";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
import AppointmentNavBar from "../../../pages/client/appointment/appointmentsNavBar";

const ChangePassword = ({ tabChange }) => {
  const [password, setPassword] = useState("");
  const [disabledSave, setDisabledSave] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    console.log("in useEffect change password");
    form.setFieldValue("oldPassword", "");
    form.setFieldValue("newPassword", "");
    form.setFieldValue("confirmPassword", "");
  }, [tabChange]);
  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    const body = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    doChangePassword(body);
  };
  const onFinishFailed = (error) => {
    console.log("Failed:", error);
  };

  const token = localStorage.getItem("token");

  const doChangePassword = async (values) => {
    console.log(values);
    if (token !== "") {
      try {
        setDisabledSave(true);
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/change-password`,
            values,
            {
              headers: {
                accessToken: token,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then((response) => {
            console.log("change password", response);
            if (response.data.status === 200) {
              setDisabledSave(false);
              message.success(response.data.message);
              localStorage.clear();
              window.location.replace("/");
            } else if (response.data.status >= 400) {
              setDisabledSave(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: response?.data?.error?.message,
                  alertOpen: true,
                })
              );
            } else {
              setDisabledSave(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          });
      } catch (err) {
        if (
          err?.response.data.status >= 400 &&
          err?.response.data.status != 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      }
    }
  };
  return (
    <>
      <div className="container appointment-dashboard-settings-p settings-editprofile changepwd appointment-dashboard-cP">
        {/* {isMobile ? <AppointmentNavBar/> : ""} */}
        {/*{isMobile?<Responsiveheader title={"Change Password"}/>:""}*/}
        <div className="caregiver-profile-settings-card">
          <Form
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            onFinishFailed={onFinishFailed}
            onFieldsChange={handleFormChange}
            form={form}
          >
            <Form.Item
              name={"oldPassword"}
              rules={[
                { required: true, message: "Please enter the Old Password" },
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Old Password"
                style={{ width: "58%", height: "45px" }}
                className="passwordinput"
              />
            </Form.Item>
            <Form.Item
              name={"newPassword"}
              dependencies={["oldPassword"]}
              rules={[
                { required: true, message: "Please enter the New Password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    console.log(value);
                    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                    const isContainsLowercase = /^(?=.*[a-z]).*$/;
                    const isContainsNumber = /^(?=.*[0-9]).*$/;
                    const isContainsSymbol =
                      /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                    if (value?.length < 8) {
                      return Promise.reject(
                        new Error("Must contain minimum of 8 characters.")
                      );
                    }
                    if (
                      !isContainsNumber.test(value) ||
                      !isContainsLowercase.test(value) ||
                      !isContainsSymbol.test(value) ||
                      !isContainsUppercase.test(value)
                    ) {
                      return Promise.reject(
                        new Error(
                          "Atleast one uppercase, lowercase, number(0-9), special character."
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="New Password"
                className="passwordinput"
                style={{ width: "58%" }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <div
              className="mb-3 mt-3 strengthpass"
              style={{ width: "48%", fontSize: "9px" }}
            >
              <PasswordStrengthMeter password={password} />
            </div>
            <Form.Item
              name={"confirmPassword"}
              dependencies={["newPassword"]}
              rules={[
                { required: true, message: "Please confirm the Password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Password should match with confirm password")
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Confirm Password"
                style={{ width: "58%" }}
                className="passwordinput"
              />
            </Form.Item>
            <div className="client-bottom-btn client-bottom-btn-update change-password-caregiver">
              <Button
                className="btn-black  auth-action-btn float-end changepwd-btn"
                disabled={disabledSave}
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
});

export default connect(mapStateToProps)(ChangePassword);

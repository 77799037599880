import React, { useEffect } from "react";
import Sidebar from "../../components/sidebar";
import AppointmentNavBar from "./appointment/appointmentsNavBar";
import axios from "axios";
import { useState } from "react";
import VideoTutorial from "../../components/videotutorial";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../redux/alert/alert.action";
import { Pagination } from "antd";
import Responsiveheader from "../../components/Responsiveheader";
import ActionCreator from "../../redux/Breadcrumb/ActionCreator";
import InfiniteScroll from "react-infinite-scroll-component";
import {Row} from 'antd'
import { useRef } from "react";
import Footer from "../../components/Footer/Footer";
import Breadcrumbs from "../../common/Breadcrumbs";
import { Spin } from "antd";


function ViewTutorial() {
    const token = localStorage.getItem('token')
   const [tutorialList,setTutorialList]=useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [hasMore,setHasMore]=useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [resultCount,setResultCount]=useState(0);
    const [loading,setLoading]=useState(false);
const listInnerRef=useRef();
    const navigate=useNavigate();
    const dispatch=useDispatch();
    useEffect(() => {
        fetchTutorails()
    }, [page])
    const onScroll = () => {
        console.log("called", "");
        if (listInnerRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
          console.log(
            scrollTop.toFixed(0),
            scrollHeight,
            clientHeight,
            "sfsfsdfds"
          );
          const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
          if (
            addedScrollHeight == scrollHeight ||
            addedScrollHeight == scrollHeight + 1 ||
            addedScrollHeight == scrollHeight - 1
          ) {
            if (tutorialList.length <= resultCount) {
              console.log("adasd");
              setPage((prev) => prev + 1);
            }
            // This will be triggered after hitting the last element.
            // API call should be made here while implementing pagination.
          }
        }
      };
    const fetchTutorails = async () => {
     
        const pageLen=(tutorialList.length/pageSize)+1;
        console.log(pageLen,"pageLenght is")
        if(resultCount>tutorialList.length || pageLen===1)
        {
     try {
      setLoading(true);
            await axios.post(`${process.env.REACT_APP_BASE_URL}/tutorial/list-tutorials`, {

                userType: 2,
                page: pageLen,
                pageLimit: pageSize,

            },
                {
                    headers: {
                        accessToken: token,
                        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD),
                    }
                }
            ).then((response) => {
              setLoading(false)
                if(response.data.status===200)
                {
                  setLoading(false)
                    if(pageLen===1)
                    {
                    setResultCount(response.data.output.resultCount);
                    }
                    const resData=response.data.output.list;
                    const mergeData=[...tutorialList,...resData]
                    setTutorialList(mergeData)
                    setHasMore(response.data.status.hasResult)
                }
                else if (response.data.status >= 400) {
                    dispatch(
                      setAlert({
                        alertType: "error",
                        alertMsg: response?.data?.error?.message,
                        alertOpen: true,
                      })
                    );
                  } else {
                    dispatch(
                      setAlert({
                        alertType: "error",
                        alertMsg: "something went wrong! please try again.",
                        alertOpen: true,
                      })
                    );
                  }
            })
        } catch (err) {
          setLoading(false)
          if(err?.response?.data?.status >= 400&&err?.response?.data?.status !== 401) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
}
              }
    }

    }
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
    
      useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
      
   useEffect(()=>{
    dispatch(
   ActionCreator.pushBreadcrumb([
    { title: "View Tutorials",
     route: `appointment-details`}
   ])
 );

  }, [])
    return (
        <>
            <div className="message-main" style={{ background: "#F7F9FA", minHeight: "100vH" }}>
{/*         {*/}
{/*            isMobile ? (*/}
{/*              <div style={{marginLeft:"1rem", marginRight:"1rem"}}>*/}
{/*                <div className='pt-3 d-flex'>*/}
{/*                    <div className='w-100'>*/}
{/*                    <Responsiveheader title="View Tutorials" />*/}
{/*                        <div className="clientsettings">*/}
{/*                            <div className="videodetailsinfo-mobile" style={{width:"100%",height:"100%"}}>*/}
{/*                                <div className="videos">*/}
{/*                                              {tutorialList?.length > 0 ?*/}
{/*                                    */}
{/*                                    <div onScroll={onScroll} ref={listInnerRef} style={{ overflowY: 'scroll', height: '90vh' }}>*/}

{/*<Row gutter={16} style={{margin:"10px"}}>*/}
{/*                                       {  tutorialList.map((item) => {*/}
{/*                                             return (*/}
{/*                                                 <div className="video" key={item.id}>*/}
{/*                                                     <VideoTutorial item={item} key={item.id} />*/}
{/*                                                 </div>*/}
{/*                                             )*/}
{/*                                         })}*/}
{/*                                         </Row>*/}
{/*                                    </div>*/}
{/*                                   */}
{/*                                    :""}    */}
{/*                                         */}
{/*                                    </div>*/}

{/*                            </div></div></div></div></div>*/}

{/*            ) : (*/}
              
                <div className='container-fluid appointment-dashboard'>
                <div className='pt-5 d-flex client_Expenses'>
                    <Sidebar />
                    <div className='mx-4 w-100 message-inner-row'>
                        <AppointmentNavBar />
                        <Spin spinning={loading} style={{width:"100%",height:"100%"}}>
                        <div className="clientsettings" >
                            <div className="videodetailsinfo" style={{width:"100%",height:"100%"}}  >

                                <div className="videos" style={{width:"100%",height:"100%"}}>
                                {/* <InfiniteScroll
                                               dataLength={tutorialList.length}
                                               next={fetchTutorails}
                                               hasMore={hasMore}
                                               loader={<h4>Loading...</h4>}
                                              // scrollableTarget="videos"
                                             >
                                                   <Row gutter={16} style={{margin:"10px"}}>
                                          {  tutorialList.map((item) => {
                                                return (
                                                    <div className="video" key={item.id}>
                                                        <VideoTutorial item={item} key={item.id} />
                                                    </div>
                                                )
                                            })}
                                            </Row>
                                            </InfiniteScroll> */}
                                             
                                    {tutorialList?.length > 0 ?
                                       <div className="view-tutorial-inner" onScroll={onScroll} ref={listInnerRef} style={{ overflowY: 'scroll', height: '75vh' }}>
                                          <Row gutter={16} style={{margin:"10px"}}>
                                          {tutorialList.map((item) => {
                                                return (
                                                    <div className="video" key={item.id} >
                                                        <VideoTutorial item={item} key={item.id} />
                                                    </div>
                                                )
                                            })}
                                            </Row>
                                       </div>
                                      
                                       :""}    
                                </div>
                                

                        </div></div></Spin>
                            
                        <Footer/>   
                            </div>
                            
                            </div></div>
                        
         {/*   )*/}
         {/*}*/}

            </div>
        </>
    )
}
export default ViewTutorial;
import {
  LOGIN_SUCCESS,
  SET_USER,
  SET_USER_MODE,
  LOGOUT,
  SET_NAVIGATION,
} from "./auth.type";
export const login = (payload) => async (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: payload,
  });
};

export const setUserMode = (payload) => async (dispatch) => {
  dispatch({
    type: SET_USER_MODE,
    payload: payload,
  });
};

export const setUser = (payload) => async (dispatch) => {
  console.log("set user payload", payload);
  dispatch({
    type: SET_USER,
    payload: payload,
  });
};
export const setTimeToTravel = (payload) => async (dispatch) => {
  console.log("set user payload", payload);
  dispatch({
    type: "SET_TRAVEL_TIME",
    payload: payload,
  });
};
export const Logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const setScreenNavigation = (payload) => async (dispatch) => {
  dispatch({
    type: SET_NAVIGATION,
    payload: payload,
  });
};

export const setUnreadMsgCount = (payload) => async (dispatch) => {
  dispatch({
    type: "SET_UNREAD_MSG",
    payload: payload,
  });
};

/*global google*/
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Circle } from "@react-google-maps/api";
import { Slider, Button } from "antd";
import { useDispatch } from "react-redux";
import CaregiverProfileAction from "../../../../redux/profileDetails/profile.action";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CaregiverSettingGmap = (props) => {
  const [updatedLocDetails, setLocationDetails] = useState(null);
  const { dim, setDim, setRadius, lat, lon } = props;

  useEffect(() => {
    setLocationDetails({ lat: parseFloat(lat), lng: parseFloat(lon) });
  }, [lat, lon, window.google]);

  const caregiverProfileDetails = useSelector(
    (state) => state?.profileDetails?.getCaregiverDetails?.output
  );
  const onChange = (newValue) => {
    setDim(newValue);
    console.log(dim, "setting in dim");
  };
  const style = {
    maxWidth: "100%",
    height: "300px",
    overflowX: "hidden",
    overflowY: "hidden",
  };
  const containerStyle = {
    maxWidth: "100%",
    height: "350px",
  };
  const options = {
    strokeColor: "#364BC6",
    strokeOpacity: 0.5,
    strokeWeight: 4,
    fillColor: "rgba(255, 255, 255, 0.1)",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: dim * 10,
    zIndex: 1,
  };
  return (
    <>
      {window.google != undefined && (
        <GoogleMap
          id="circle-example"
          style={style}
          center={updatedLocDetails}
          zoom={9}
          mapContainerStyle={containerStyle}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            draggable: true,
          }}
        >
          <Circle center={updatedLocDetails} options={options}></Circle>
          <div className="map-km" style={{ top: "calc(90% - 20px)" }}>
            {dim / 100} Km
          </div>
          <div
            className="map-actions"
            style={{ position: "relative", maxWidth: "300px", top: "294px" }}
          >
            {console.log(dim, "dim")}
            <Slider
              className="simple-slider"
              min={500}
              max={2000}
              step={100}
              value={dim}
              tooltip={{
                open: false,
              }}
              onChange={(e) => onChange(e)}
            />
          </div>
        </GoogleMap>
      )}
    </>
  );
};
export default CaregiverSettingGmap;

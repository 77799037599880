import React from "react";
import {
  CardElement,
  AuBankAccountElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  disableLink: true,
  style: {
    base: {
      color: "#303238",
      fontSize: "18px",
      fontFamily: "Open Sans, sans-serif",
      fontSmoothing: "antialiased",
      borderRadius: "11px",
      border: "1px solid #d9d9d9",
      fontWeight: 400,
      "::placeholder": {
        color: "#bfbfbf",
        fontWeight: 400,
        fontSize: "18px",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

function CardSection({ onChange }) {
  return (
    <label>
      {/* <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
      <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      <CardCvcElement options={CARD_ELEMENT_OPTIONS} /> */}
      <div className="row-custom">
        <div className="col-12 col-sm-12 col-md-12">
          <CardNumberElement
            onChange={onChange}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <CardCvcElement onChange={onChange} options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <CardExpiryElement
            onChange={onChange}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
      </div>
      {/* <AuBankAccountElement /> */}
    </label>
  );
}

export default CardSection;

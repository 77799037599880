import {HttpService} from '../axios'
 const _expenseReject= async(payload)=>{
     const res= await HttpService.post('/appointment/approve-reject-expenses', payload)

      return res.data
 }

  const _fetchCard=async()=>{
    const res= await HttpService.post('/user/list-card', {})

    return res.data
  }


   const _Pay=async(payload)=>{
    const res= await HttpService.post('/appointment/approve-reject-expenses', payload)

    return res.data
   }

    const _fetchAppointmentsDetails=async(payload, userMode)=>{
      const res= await HttpService.post(`/appointment/view-${userMode}-appointments`, payload)

    return res.data

    }

  export default {_expenseReject, _fetchCard, _Pay, _fetchAppointmentsDetails}

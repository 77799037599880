import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Spin, Checkbox, Button } from "antd";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { setAlert } from "../../../redux/alert/alert.action";
import RadioBox from "../../../components/forms/v-radio-box";
import axios from "axios";
import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";
import NoAppointment from "./no-appointment";
import "./service-grid.less";
import AppointmentNavBar from "./appointmentsNavBar";
import { services } from "../../../redux/appointment/appointment.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../components/Footer/Footer";
import ScrollWrapper from "../../../components/ScrollWrapper/ScrollWrapper";
import Responsiveheader from "../../../components/Responsiveheader";

function ChooseServices(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken, isBookmarked } = props;
  const [servceType, setServiceType] = useState("1");
  const [caregiver, setCaregiver] = useState([]);
  const [companion, setCompanion] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);

  const serviceData = useSelector((state) => state.appointment.servicesResp);
  const selectedCCId = useSelector((state) => state.appointment.selectedCC);
  console.log(selectedCCId, "selectedCCId");
  const [checkedCaregiver, setCheckedCaregiver] = useState(
    serviceData?.checkedCaregiver
  );
  const [checkedCompanions, setCheckedCompanions] = useState(
    serviceData?.checkedCompanions
  );
  const [caregiverAllChecked, setAllCareGiverChecked] = useState(
    serviceData?.caregiverAllChecked
  );
  const [companionAllChecked, setAllCompanionChecked] = useState(
    serviceData?.companionAllChecked
  );
  const navigationData = useSelector((state) => state.loginData?.homeData);
  // const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // console.log(storeData, "storeData")
  const handleOk = () => {
    dispatch(toggleChooseRelativeModal(false));
  };

  const handleCancel = () => {
    dispatch(toggleChooseRelativeModal(false));
  };

  const handleRadioChange = (e) => {
    setValue(e.target.value);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    if (servceType == 1) {
      setCheckedCompanions([]);
    } else {
      setCheckedCaregiver([]);
    }
  }, [servceType]);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (isBookmarked && selectedCCId?.firstName && selectedCCId?.lastName) {
      const breadCrumbArr = [...ActionCreator.BreadcrumbItems.caregivers];
      breadCrumbArr.push({
        title: `${selectedCCId?.firstName} ${selectedCCId?.lastName}`,
        route: `my-caregivers/view-caregiver/${selectedCCId?._id}`,
        isCustom: true,
      });
      breadCrumbArr.push({
        title: "New Appointment",
        route: "",
      });
      dispatch(ActionCreator.pushBreadcrumb(breadCrumbArr));
    } else {
      dispatch(
        ActionCreator.pushBreadcrumb(
          ActionCreator.BreadcrumbItems.Appointments.concat({
            title: "New Appointment",
            route: `appointment-details`,
          })
        )
      );
    }
  }, [selectedCCId?.firstName]);
  useEffect(() => {
    if (isBookmarked) {
      fetchGiverServices();
    } else {
      fetchServices(accessToken, "1", setCaregiver);
      fetchServices(accessToken, "2", setCompanion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookmarked]);

  const fetchGiverServices = () => {
    if (accessToken !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/view-caregiver`,
          {
            selectedServices: [],
            caregiverId: selectedCCId?.caregiverId
              ? selectedCCId?.caregiverId
              : selectedCCId?._id,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            const services = response.data.output.data?.serviceList;
            setCaregiver(services);
            setCompanion(services);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const saveServices = () => {
    // localStorage.setItem('services', value)
    const newObj = {
      checkedCaregiver,
      checkedCompanions,
      caregiverAllChecked,
      companionAllChecked,
    };
    dispatch(services(newObj));
    if (window.location.href.includes("my-caregivers")) {
      navigate("/my-caregivers/select-time-slots");
    } else {
      navigate("/client/appointment/create-appointment/dates");
    }
  };

  const fetchServices = async (token, type, fn) => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/service/list-services`,
          {
            type: type,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            fn(response.data.output.result);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleOnChange = (e) => {
    debugger;
    if (servceType === "1") {
      setCheckedCaregiver(e);
      if (caregiver.length == e.length) {
        setAllCareGiverChecked(true);
      } else {
        setAllCareGiverChecked(false);
      }
    } else {
      setCheckedCompanions(e);
      if (companion.length == e.length) {
        setAllCompanionChecked(true);
      } else {
        setAllCompanionChecked(false);
      }
    }
  };

  const handleSelectAll = (e) => {
    if (servceType == 1) {
      if (e.target.checked) {
        const careGiverIDs =
          caregiver.length > 0 &&
          caregiver?.map((s) => {
            return s?._id;
          });
        setCheckedCaregiver(careGiverIDs);
        setAllCareGiverChecked(true);
      } else {
        setCheckedCaregiver([]);
        setAllCareGiverChecked(false);
      }
    } else {
      if (e.target.checked) {
        const companionIDs =
          companion.length > 0 &&
          companion?.map((s) => {
            return s?._id;
          });
        setCheckedCompanions(companionIDs);
        setAllCompanionChecked(true);
      } else {
        setCheckedCompanions([]);
        setAllCompanionChecked(false);
      }
    }
  };

  console.log(navigationData, "navigationData");

  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{isMobile?<>*/}

      {/* <div className="p-3 pt-4 d-flex w-100">*/}
      {/*           <Responsiveheader   title="New Appointment " />*/}
      {/*         </div>*/}
      {/*     <div className='p-3 border-less mx-3 rounded' style={{background:"#fff"}}>*/}
      {/*    <div >*/}
      {/*    <p className='px-3 mt-2 fw-semibold fs-4'>What Services Do You Require?</p>*/}
      {/*    <div className=''>*/}
      {/*     <div className='px-3 mb-2 d-flex  justify-content-between '>*/}
      {/*     <Button */}
      {/*      className={`me-2 btn-large ${servceType !== '1' && 'color-secondary fw-normal'}`} */}
      {/*     shape="round" size="large" */}
      {/*     onClick={() => {*/}
      {/*       setServiceType('1')*/}
      {/*     }} type={servceType === '1' && 'primary'}*/}
      {/*     >Caregiver</Button>*/}
      {/*     {!isBookmarked &&*/}
      {/*     <Button */}
      {/*     className={`btn-large pt-2 ${servceType !== '2' && 'color-secondary fw-normal'}`} */}
      {/*     shape="round" size="large"*/}
      {/*     //  style={{marginTop:'1rem'}} */}
      {/*     onClick={() => {*/}
      {/*       setServiceType('2');*/}
      {/*     }} type={servceType === '2' && 'primary'}*/}
      {/*     >Companion</Button>*/}
      {/*     }*/}
      {/*     </div>*/}
      {/*     <div className='pt-2 m-2' >*/}
      {/*     <Checkbox style={{marginLeft:'1rem'}}  className="select-all-check" checked={servceType === '1' ? caregiverAllChecked : companionAllChecked} onChange={handleSelectAll}>Select All</Checkbox>*/}
      {/*     </div>*/}
      {/*     </div>*/}
      {/*     <Spin spinning={loading} tip="loading Services...">*/}
      {/*     <ScrollWrapper >*/}
      {/*     {servceType === '1' ? <Checkbox.Group value={checkedCaregiver} */}
      {/*     className="service-container" onChange={handleOnChange}>*/}
      {/*     {caregiver?.map((op, index) => {*/}
      {/*     return <Checkbox  style={{width:'135px', height:'250px'}} className={`service-btn service-card ms-3 mt-3 flex-row-reverse ${checkedCaregiver?.includes(op?._id) && 'service-btn-active'}`} value={op?._id} checked={checkedCaregiver?.includes(op?._id)}>*/}
      {/*         <div className=''>*/}
      {/*     <img className='service-img mt-4' src={op?.image} alt={op?.name} />*/}
      {/*     <p className='mt-4 mb-4 fw-semibold'>{op?.name}</p>*/}
      {/*     </div> */}
      {/*     </Checkbox>*/}
      {/*     })}*/}
      {/*     </Checkbox.Group> : <Checkbox.Group value={checkedCompanions} */}
      {/*     className="service-container" onChange={handleOnChange}>*/}
      {/*     {companion?.map((op, index) => {*/}
      {/*     return <Checkbox  style={{ width:'130px', height:'250px'}}className={`service-btn service-card ms-3 mt-3 flex-row-reverse ${checkedCompanions?.includes(op?._id) && 'service-btn-active'}`} value={op?._id} checked={checkedCompanions?.includes(op?._id)}>*/}
      {/*         <div className=''>*/}
      {/*     <img className='service-img mt-4' src={op?.image} alt={op?.name} />*/}
      {/*     <p className='mt-4 mb-4 fw-semibold'>{op?.name}</p>*/}
      {/*     </div> */}
      {/*     </Checkbox>*/}
      {/*     })}*/}
      {/*     </Checkbox.Group>}*/}
      {/*  */}

      {/*     <div className='d-flex justify-content-between px-1 mt-2'>*/}
      {/*     <Button */}
      {/*     // htmlType="submit"*/}
      {/*     onClick={() => {*/}
      {/*       if(isBookmarked) {*/}
      {/*         navigate("/dashboard/my-caregivers")*/}
      {/*       }*/}
      {/*       else {*/}
      {/*       navigate("/client/appointment")*/}
      {/*       }*/}
      {/*     }}*/}
      {/*     className="btn-back auth-action-btn mt-3 mb-3" shape="round" size="large" disabled={value === ''}>*/}
      {/*     Back*/}
      {/*     </Button>*/}
      {/*     <Button */}
      {/*     htmlType="submit"*/}
      {/*     onClick={saveServices}*/}
      {/*     className="btn-black auth-action-btn mt-3 mb-3" shape="round" type="primary" size="large" disabled={value === ''}  style={{marginLeft:'2rem',width:'100%'}}>*/}
      {/*     Next*/}
      {/*     </Button>*/}
      {/*     </div>*/}
      {/*     </ScrollWrapper>*/}
      {/*       </Spin>*/}
      {/* </div>*/}
      {/*    </div>*/}
      {/*</>: */}

      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 w-100 message-inner-row">
            <AppointmentNavBar />
            <div
              className="p-3 border-less rounded"
              style={{ background: "#fff" }}
            >
              <div className="choose-service-row-main">
                <p className="px-3 mt-2 fw-semibold px-mob-0 text-left-mobile fs-4">
                  {" "}
                  Which services do you require?
                </p>
                <div className="px-3 mb-2 d-flex justify-content-between choose-service-btn-row px-mob-0 align-items-center">
                  <div className="mobile-flex-custom">
                    <Button
                      className={`me-2 btn-large ${
                        servceType !== "1" && "color-secondary fw-normal"
                      }`}
                      shape="round"
                      size="large"
                      onClick={() => {
                        setServiceType("1");
                        setAllCompanionChecked(false);
                      }}
                      type={servceType === "1" && "primary"}
                    >
                      Caregiver
                    </Button>
                    {!isBookmarked && (
                      <Button
                        className={`btn-large ${
                          servceType !== "2" && "color-secondary fw-normal"
                        }`}
                        shape="round"
                        size="large"
                        onClick={() => {
                          setServiceType("2");
                          setAllCareGiverChecked(false);
                        }}
                        type={servceType === "2" && "primary"}
                      >
                        Companion
                      </Button>
                    )}
                  </div>
                  <div className="text-left-mobile">
                    <Checkbox
                      className="select-all-check"
                      checked={
                        servceType === "1"
                          ? caregiverAllChecked
                          : companionAllChecked
                      }
                      onChange={handleSelectAll}
                    >
                      Select All
                    </Checkbox>
                  </div>
                </div>
                <Spin spinning={loading}>
                  <ScrollWrapper height="calc(84vh - 320px)">
                    {servceType === "1" ? (
                      <Checkbox.Group
                        value={checkedCaregiver}
                        className="service-container"
                        onChange={handleOnChange}
                      >
                        {caregiver?.map((op, index) => {
                          return (
                            <Checkbox
                              className={`service-btn service-card ms-3 mt-3 flex-row-reverse ${
                                checkedCaregiver?.includes(op?._id) &&
                                "service-btn-active"
                              }`}
                              value={op?._id}
                              checked={checkedCaregiver?.includes(op?._id)}
                            >
                              <div className="">
                                <img
                                  className="service-img mt-4"
                                  src={op?.image}
                                  alt={op?.name}
                                />
                                <p className="mt-4 mb-4 fw-semibold">
                                  {op?.name}
                                </p>
                              </div>
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    ) : (
                      <Checkbox.Group
                        value={checkedCompanions}
                        className="service-container"
                        onChange={handleOnChange}
                      >
                        {companion?.map((op, index) => {
                          return (
                            <Checkbox
                              className={`service-btn service-card ms-3 mt-3 flex-row-reverse ${
                                checkedCompanions?.includes(op?._id) &&
                                "service-btn-active"
                              }`}
                              value={op?._id}
                              checked={checkedCompanions?.includes(op?._id)}
                            >
                              <img
                                className="service-img mt-4"
                                src={op?.image}
                                alt={op?.name}
                              />
                              <p className="mt-4 mb-4 fw-semibold">
                                {op?.name}
                              </p>
                            </Checkbox>
                          );
                        })}
                      </Checkbox.Group>
                    )}
                  </ScrollWrapper>
                </Spin>
              </div>
              <div className="d-flex justify-content-between common-btn-row px-mob-0 px-3 mt-4">
                <Button
                  // htmlType="submit"
                  onClick={() => {
                    if (isBookmarked) {
                      navigate("/dashboard/my-caregivers");
                    } else if (navigationData?.isFirstAppointmentCreated) {
                      navigate("/client/appointments-dashboard");
                    } else {
                      navigate("/client/appointment");
                    }
                  }}
                  className="btn-back auth-action-btn mt-3 mb-3"
                  shape="round"
                  size="large"
                  disabled={value === ""}
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  onClick={saveServices}
                  className="btn-black auth-action-btn mt-3 mb-3"
                  shape="round"
                  type="primary"
                  size="large"
                  disabled={
                    servceType == 1
                      ? checkedCaregiver?.length == 0
                      : checkedCompanions?.length == 0
                  }
                >
                  Next
                </Button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*}*/}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseServices);

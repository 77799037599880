import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import "./MySchedule.less";
import moment from "moment";
import { Calendar } from "react-calendar";
import axios from "axios";
import AppoinmentCard from "./AppoinmentCard";
import MonthlyCalneder from "./MonthlyCalneder";
import { useNavigate } from "react-router";
import DatePicker from "../../common/DatePicker";
import Appointments from "../../pages/client/Schedule/Appointments";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { paymentDetails } from "../../redux/appointment/appointment.action";
import { setAlert } from "../../redux/alert/alert.action";

const orientation = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

const MySchedule = () => {
  const [active, setActive] = useState(orientation.WEEKLY);
  const [appointmentList, setAppointmentList] = useState([]);
  const [selectedDayAppo, setSelectedDayAppo] = useState([]);
  const [datesData, setDatesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Availability, setAvailability] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const clientMode = userMode === " client";
  const token = localStorage.getItem("token");
  // clientMode
  // ? localStorage.getItem("token")
  // : JSON.parse(JSON.parse(localStorage.getItem("persist:root")).loginData)
  //     .user.accessToken;
  const handleAppointment = (appointmentId) => {
    // navigate(`/client/appointment/appointment-details/${appointmentId}`);
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: "redirect",
            appointmentId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointment/appointment-details/${appointmentId}`
            );
            dispatch(paymentDetails(response.data.output.result));
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const getAppointments = (payload, isVerify) => {
    if (!token) {
      return;
    }
    const formatedURL = clientMode
      ? `${process.env.REACT_APP_BASE_URL}/appointment/list-scheduled-appointments`
      : `${process.env.REACT_APP_BASE_URL}/appointment/list-scheduled-appointments`;
    const headers = {
      accessToken: token,
      Authorization:
        "Basic " +
        btoa(
          process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
        ),
    };

    return axios
      .post(formatedURL, payload, { headers })
      .then((res) => {
        //  if(!isVerify){

        const temp = [];
        setAppointmentList(res?.data?.output);
        const dayByfilter = appointmentList?.list?.find((listing) => {
          // console.log(listing, "listinglistinglisting");
          const splittedDate = listing.date.split("T");
          return (
            moment().format("DD/MM/YYYY") ===
            moment(splittedDate[0]).format("DD/MM/YYYY")
          );
          // if (moment().utc().format("DD") === new Date(listing.date).getDay()) {
          //   return (
          //     new Date().getDay() === new Date(listing.date).getDay() &&
          //     new Date().getMonth() === new Date(listing.date).getMonth() &&
          //     new Date().getDate() === new Date(listing.date).getDate()
          //   );
          // }
        });
        // temp.push(dayByfilter?.appointments)
        //  console.log(temp, "wdewrfer")
        setSelectedDayAppo(dayByfilter);

        //  }

        return res?.data?.output;
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  //   useEffect(() => {
  //     let payload;
  //     if (active === orientation.MONTHLY) {
  //       payload = {
  //         type: userMode,
  //         startDate:
  //           moment().startOf("month").format("yyyy-MM-DDT").toString() +
  //           "00:00:00.000+0000",
  //         endDate:
  //           moment().endOf("month").format("yyyy-MM-DDT").toString() +
  //           "00:00:00.000+0000",
  //       };
  //     } else if (active === orientation.WEEKLY && datesData) {
  //       payload = {
  //         type: userMode,
  //         startDate:
  //           moment(datesData?.listingDates[0]?._d)
  //             .format("yyyy-MM-DDT")
  //             .toString() + "00:00:00.000+0000",
  //         endDate:
  //           moment(datesData?.listingDates[datesData.listingDates.length - 1]?._d)
  //             .format("yyyy-MM-DDT")
  //             .toString() + "00:00:00.000+0000",
  //       };
  //     }

  //     getAppointments(payload);
  //   }, [datesData]);
  //  console.log(datesData, "date....")

  useEffect(() => {
    const payload = {
      type: userMode,
      startDate:
        moment().startOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",

      endDate:
        moment()
          .add(3, "months")
          .endOf("month")
          .format("yyyy-MM-DDT")
          .toString() + "00:00:00.000+0000",
    };
    getAppointments(payload);
    getavailability(payload);
  }, []);
  const onSelectDatePicker = async (e) => {
    const dayAppointment = appointmentList?.list?.find((listing) => {
      const splittedDate = listing.date?.split("T");
      // console.log(listing, "listinglistinglisting");
      return (
        moment(e).format("DD/MM/YYYY") ===
        moment(splittedDate[0]).format("DD/MM/YYYY")
      );
      // if (new Date(e).getDay() === new Date(listing.date).getDay()) {
      //   return (
      //     new Date(e).getDay() === new Date(listing.date).getDay() &&
      //     new Date(e).getMonth() === new Date(listing.date).getMonth() &&
      //     new Date(e).getDate() === new Date(listing.date).getDate()
      //   );
      // } else {
      // }
    });
    setSelectedDayAppo(
      _.isEmpty(dayAppointment?.appointments) ? [] : dayAppointment.appointments
    );
  };
  const getavailability = (payload) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/list-availability`,
        {
          ...payload,
        },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setAvailability(response.data.output.list);
        } else if (response.data.status >= 400) {
          // setLoading(false)
        } else {
          // setLoading(false)
        }
      });
  };
  const handleArrow = (value) => {
    const payload = {
      type: userMode,
      startDate:
        moment(value).startOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",

      endDate:
        moment(value).endOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
    };
    getAppointments(payload);
    getavailability(payload);
  };

  console.log(selectedDayAppo, "selectedDayAppo");

  return (
    <div
      className={active === orientation.MONTHLY ? "monthly_cal" : "mySchedule"}
    >
      <div className="mySchedule_heading">My Schedule</div>
      <div className="mySchedule_tab">
        <div
          className={active === orientation.WEEKLY ? "active" : "unused"}
          onClick={() => setActive(orientation.WEEKLY)}
        >
          {orientation.WEEKLY}
        </div>
        <div
          className={active === orientation.MONTHLY ? "active" : "unused"}
          onClick={() => setActive(orientation.MONTHLY)}
        >
          {orientation.MONTHLY}
        </div>
      </div>

      {active === orientation.MONTHLY ? (
        <>
          <MonthlyCalneder
            active={active}
            handleArrow={handleArrow}
            appointmentList={appointmentList}
            onSelectDatePicker={onSelectDatePicker}
            setSelectedDayAppo={setSelectedDayAppo}
            selectedDayAppo={selectedDayAppo}
            Availability={Availability}
            getavailability={getavailability}
            getAppointments={getAppointments}
            userMode={userMode}
          />
          {selectedDayAppo?.length === 0 || _.isEmpty(selectedDayAppo) ? (
            <div className="client_no_appointments_text ">
              <img
                src="/images/icons/bird-icon.svg"
                alt="green-circle"
                className="monthly_Empty"
              />
              <h4 style={{ margin: "1px" }}>No Appointments</h4>
            </div>
          ) : (
            <div
              className={
                selectedDayAppo?.length === 0 || _.isEmpty(selectedDayAppo)
                  ? ""
                  : "appoinment_details"
              }
            >
              {selectedDayAppo?.length > 0 &&
                selectedDayAppo?.map((appoint) => (
                  <AppoinmentCard
                    {...appoint}
                    handleAppontment={handleAppointment}
                  />
                ))}
            </div>
          )}
        </>
      ) : (
        <div className="monthly_calender">
          <DatePicker
            setDatesData={setDatesData}
            weekly={true}
            getAppointments={getAppointments}
            userMode={userMode}
            isVisibleWeek={true}
            onSelectDatePicker={onSelectDatePicker}
          />

          {/* <Appointments appointments={appointmentList?.list} /> */}

          {selectedDayAppo?.length === 0 || _.isEmpty(selectedDayAppo) ? (
            <div className="no_appointments_text">
              <img src="/images/icons/bird-icon.svg" alt="green-circle" />
              <h4 style={{ marginTop: 0 }}>No Appointments</h4>
            </div>
          ) : (
            <>
              {selectedDayAppo?.length > 0 &&
                selectedDayAppo?.map((appoint) => (
                  <AppoinmentCard
                    {...appoint}
                    handleAppontment={handleAppointment}
                  />
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MySchedule;

import {SET_ALERT, REMOVE_ALERT} from './alert.type';
import { message } from 'antd';
export const setAlert = (payload) => async(dispatch) => {
        payload?.alertType !== '' && message?.[payload?.alertType](`${payload?.alertMsg}`);
        dispatch({
                type: SET_ALERT,
                payload: payload
        })

}

export const removeAlert = () => async(dispatch) => {
    dispatch({
            type: REMOVE_ALERT,
    })
}



export const stripeIdentityChange = (payload) => async (dispatch) => {
  dispatch({
    type: "IDENTITY",
    payload,
  });
};

export const stripeAddressChange = (payload) => async (dispatch) => {
  dispatch({
    type: "ADDRESS",
    payload,
  });
};

export const updateStripeStatus = (payload) => async (dispatch) => {
  dispatch({
    type: "STATUS",
    payload,
  });
};

import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk" 
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './rootReducer';
import { sessionService } from 'redux-react-session';



const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
);


const persistor = persistStore(store);
sessionService.initSessionService(store)

export { store , persistor };

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Spin,
  Button,
  message,
  Col,
  Row,
  TimePicker,
  Divider,
  Typography,
  Modal,
  Select,
} from "antd";
import {
  toggleChooseRelativeModal,
  toggleConfirmRelativeModal,
} from "../../../redux/modals/toggleModal.actions";
import Sidebar from "../../../components/sidebar";
import { DateRangePicker } from "react-date-range";

import "./service-grid.less";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
// import Modal from
import AppointmentNavBar from "./appointmentsNavBar";
import {
  dateTime,
  services,
} from "../../../redux/appointment/appointment.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import { setAlert } from "../../../redux/alert/alert.action";
import Footer from "../../../components/Footer/Footer";
import { Calendar, DateObject } from "react-multi-date-picker";
import Responsiveheader from "../../../components/Responsiveheader";
import { CloseCircleOutlined } from "@ant-design/icons";
import { convertToTwoDigits, getTimeSlots } from "./utils";

// import InfoModal from "../../../components/Modal/ExpensesModal/InfoModel";

function ChooseServices({ chooseRelativeModal, accessToken }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hourSlots = getTimeSlots();
  const dateTimeData = useSelector((state) => state.appointment.dateTimeResp);
  const currentDate = moment().toDate();
  const [update, setUpdate] = useState(true);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [days, setDays] = useState(0);
  const [slot, setSlot] = useState(dateTimeData?.slot);
  const [nextPage, setNextpage] = useState(false);
  const defaultRange =
    dateTimeData?.range?.length > 0
      ? dateTimeData?.range
      : // .map((i) => {
        //     return {
        //       ...i,
        //       startDate: moment(i.startDate).toDate(),
        //       endDate: moment(i.endDate).toDate(),
        //     };
        //   })
        [];
  // const [range, setRange] = useState([{
  //   startDate:new Date(),
  //   endDate:new Date(),
  //   key:"selection"
  // }])

  // console.log(range, "test")
  // const dateRange = dateTimeData?.range?.length > 0 ? dateTimeData?.range : [{startDate:new Date(),
  //     endDate:new Date(),
  //     key:"selection"}]
  const [range, setRange] = useState(defaultRange);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const storeData = useSelector((state) => state);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleSaveTiming = () => {
    // localStorage.setItem('slot', JSON.stringify(slot));
    const rangeSlotObj = {
      range,
      slot,
    };
    dispatch(dateTime(rangeSlotObj));
    dispatch(toggleConfirmRelativeModal(true));
  };

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.Appointments.concat({
          title: "New Appointments",
          route: `appointment-details`,
        })
      )
    );
  }, []);

  const handleSelect = (ranges) => {
    // const dateDiff = moment(ranges?.selection?.endDate).diff(moment(ranges?.selection?.startDate), 'days');
    // if(dateDiff > 3){
    //   message.error('end date should be within 4 days only')
    // }else{
    // console.log("ran  ges", ranges)

    // setDays(dateDiff)
    // setRange(prev => {
    //   return [...prev, {
    //   startDate:ranges?.selection?.startDate,
    //   endDate:ranges?.selection?.endDate,
    //   key:"selection"
    // }]})
    const rangeArr = [];
    ranges.forEach((range) => {
      const dateObj = {};
      // range.forEach((i, index) => {
      // console.log(i, index, "obj");
      const date = new Date(range);
      // console.log(date, moment(new Date(i)).toDate(),"updateddate")
      // if (range.length == 1) {
      dateObj["startDate"] = moment(date).toDate();
      dateObj["endDate"] = moment(date).toDate();
      // }
      // if (index == 0) {
      //   dateObj["startDate"] = moment(date).toDate();
      // }
      // if (index == 1) {
      //   dateObj["endDate"] = moment(date).toDate();
      // }
      dateObj["key"] = "selection";
      console.log(date, "startenddate");
      // });
      rangeArr.push(dateObj);
    });
    setRange(ranges);
    // setRange([
    //   {
    //     startDate: ranges?.selection?.startDate,
    //     endDate: ranges?.selection?.endDate,
    //     key: "selection",
    //   },
    // ]);
    // if(ranges?.selection?.endDate > ranges?.selection?.startDate){
    console.log(rangeArr, "rangeArr");
    itreteSlots(rangeArr);
    // }

    // }
  };

  const itreteSlots = (ranges) => {
    let slotArr = [];
    ranges.forEach((dateObj) => {
      const dateDiff = moment(dateObj?.endDate).diff(
        moment(dateObj?.startDate),
        "days"
      );
      const beforeSevenDays = moment(dateObj?.endDate).subtract(
        dateDiff,
        "days"
      );
      const today = moment(dateObj?.startDate).startOf("day");
      let i = 0;
      for (
        let m = moment(beforeSevenDays);
        m.diff(today, "days") <= dateDiff;
        m.add(1, "days")
      ) {
        const foundSlot = dateTimeData?.slot?.find(
          (obj) =>
            moment(obj.date).format("DD/MM/YYYY") === m.format("DD/MM/YYYY")
        );
        const foundCurrentSlot = slot.find((item) => {
          return (
            moment(item.date).format("DD/MM/YYYY") === m.format("DD/MM/YYYY")
          );
        });
        slotArr.push({
          date: new Date(m._d),
          time: {
            startTime: foundSlot
              ? foundSlot.time.startTime
              : foundCurrentSlot
              ? foundCurrentSlot.time.startTime
              : moment(new Date(m._d)).set({
                  hour: 6,
                  minutes: 0,
                  seconds: 0,
                }),
            endTime: foundSlot
              ? foundSlot.time.endTime
              : foundCurrentSlot
              ? foundCurrentSlot.time.endTime
              : null,
          },
        });
        i += 1;
      }
    });
    setSlot(slotArr);
  };

  const handleStartTime = (e, date, i) => {
    let foundItem;
    let foundStartItem = hourSlots.find((item) => {
      return item.start.format("HH:mm") === e;
    });
    const foundEndItem = hourSlots.find((item) => {
      return item.end.format("HH:mm") === e;
    });
    if (foundStartItem) {
      foundItem = foundStartItem.start;
    } else {
      foundItem = foundEndItem?.end;
    }
    // debugger;
    let tempSlot = slot;
    const selectedDay = moment(date).format("DD");
    const selectedMonth = moment(date).format("MM");
    const selectedYear = moment(date).format("YYYY");
    const startTime = foundItem
      .set({
        date: selectedDay,
        month: selectedMonth - 1,
        year: selectedYear,
      })
      .utc(true);
    let endTime;
    if (tempSlot[i]?.time?.endTime && tempSlot[i]?.isOverNight) {
      endTime = moment(tempSlot[i].time.endTime).set({
        date: Number(selectedDay) + 1,
        month: selectedMonth - 1,
        year: selectedYear,
      });
    } else if (tempSlot[i]?.time?.endTime) {
      endTime = moment(tempSlot[i].time.endTime)
        .set({
          date: selectedDay,
          month: selectedMonth - 1,
          year: selectedYear,
        })
        .utc(true);
    }
    let hrs = moment.duration(endTime?.diff(startTime)).asHours();
    const formattedStartTime = moment(startTime).format("HH:mm");
    const formattedEndTime = moment(endTime).format("HH:mm");
    if (!formattedStartTime.includes("30") && formattedEndTime.includes("30")) {
      hrs = hrs + 0.5;
    }
    let time = moment(startTime).format("HH:mm a");
    const ct = time.split(" ");
    if (tempSlot[i]?.time?.endTime && hrs < 3) {
      message.error("Appointments must be a minimum duration of 3 hours.");
    } else if (
      !tempSlot[i]?.isOverNight &&
      tempSlot[i]?.time?.endTime &&
      hrs > 12
    ) {
      message.error("Appointments must be a maximum duration of 12 hours.");
    } else {
      tempSlot[i].time.startTime = startTime;
      setSlot(tempSlot);
      setUpdate(!update);
    }
  };

  const handleEndTime = (e, date, i) => {
    let tempSlot = slot;
    let foundItem;
    let foundStartItem = hourSlots.find((item) => {
      return item.start.format("HH:mm") === e;
    });
    const foundEndItem = hourSlots.find((item) => {
      return item.end.format("HH:mm") === e;
    });
    if (foundStartItem) {
      foundItem = foundStartItem.start;
    } else {
      foundItem = foundEndItem?.end;
    }
    debugger;
    // let hrs = moment.utc(e.diff(tempSlot[i].time.startTime)).format("HH");
    const selectedDay = moment(date).format("DD");
    const selectedMonth = moment(date).format("MM");
    const selectedYear = moment(date).format("YYYY");
    let endDateTime;
    if (tempSlot[i].isOverNight) {
      endDateTime = moment(foundItem)
        // .utc()
        .set({
          date: Number(selectedDay) + 1,
          month: selectedMonth - 1,
          year: selectedYear,
        })
        .utc(true);
    } else {
      endDateTime = moment(foundItem)
        .set({
          date: selectedDay,
          month: selectedMonth - 1,
          year: selectedYear,
        })
        .utc(true);
    }
    let startTime;
    if (tempSlot[i]?.time?.startTime) {
      startTime = moment(tempSlot[i]?.time?.startTime)
        .set({
          date: selectedDay,
          month: selectedMonth - 1,
          year: selectedYear,
        })
        .utc(true);
    }
    let hrs = Math.round(
      moment.duration(endDateTime.diff(startTime)).asHours()
    );
    const formattedStartTime = moment(startTime).format("HH:mm");
    const formattedEndTime = moment(endDateTime).format("HH:mm");
    if (!formattedStartTime.includes("30") && formattedEndTime.includes("30")) {
      hrs = hrs + 0.5;
    }
    console.log(hrs, "fsdfs");
    let time = moment(endDateTime).format("HH:mm a");
    const ct = time.split(" ");
    console.log("End time", ct);
    if (startTime && hrs < 3) {
      message.error("Appointments must be a minimum duration of 3 hours.");
    } else if (!tempSlot[i]?.isOverNight && startTime && hrs > 12) {
      message.error("Appointments must be a maximum duration of 12 hours.");
    } else {
      console.log("DIFF", hrs);
      tempSlot[i].time.endTime = endDateTime;
      console.log("tempSlot", tempSlot);
      setSlot(tempSlot);
      setUpdate(!update);
    }
  };

  const handleClearStartEndTime = (idx) => {
    const timeSlot = [...slot];
    timeSlot[idx].time.startTime = "";
    timeSlot[idx].time.endTime = "";
    setSlot(timeSlot);
  };

  const handleOverNight = (idx) => {
    const timeSlot = [...slot];
    timeSlot[idx].isOverNight = !timeSlot[idx].isOverNight;
    const formatted = timeSlot[idx].time?.startTime
      ? timeSlot[idx].time?.startTime
      : timeSlot[idx]?.date;
    if (timeSlot[idx].isOverNight) {
      const defaultOvernightVal = moment(formatted).set({
        hour: 18,
        minutes: 0,
        seconds: 0,
      });
      timeSlot[idx].time = {
        ...timeSlot[idx].time,
        startTime: defaultOvernightVal,
        endTime: null,
      };
    } else {
      const defaultVal = moment(formatted).set({
        hour: 6,
        minutes: 0,
        seconds: 0,
      });
      const endDefaultVal = moment(formatted).set({
        hour: 9,
        minutes: 0,
        seconds: 0,
      });
      timeSlot[idx].time = {
        ...timeSlot[idx].time,
        startTime: defaultVal,
        endTime: "",
      };
    }
    setSlot(timeSlot);
  };

  const handleInfoModal = () => {
    setInfoModalVisible(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalVisible(false);
  };

  const calculateHours = () => {
    let hours = 0;
    slot.forEach((i) => {
      const date = moment(i.date, "YYYY/MM/DD");
      console.log(date, "selected");
      const startTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(i.time?.startTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      let endTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(i.time?.endTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      if (i?.isOverNight) {
        const formattedDate = moment(date).add(1, "days").format("YYYY-MM-DD");
        endTimeObj =
          formattedDate +
          "T" +
          moment(i.time?.endTime).utc(true).format("HH:mm") +
          `:00.000Z`;
      }
      const end = moment(endTimeObj);
      const start = moment(startTimeObj);
      let duration = moment.duration(end.diff(start));
      hours = hours + duration.asHours();
    });
    return Math.abs(hours);
  };
  const maxDate = moment().add(3, "months").endOf("month").toDate();

  // const disabledTimedropDown = (current) => {
  //   if (current && current < moment().startOf("hour")) {
  //     return true;
  //   }
  //   return false;
  // };

  const isNextDisabled =
    slot.length > 0
      ? slot.some((i) => !i.time.startTime || !i.time.endTime)
      : true;
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/*{isMobile ? <>*/}
      {/*  <div style={{ background: "#E4E4E4", height: "100vH" }}>*/}
      {/*    <InfoModal*/}
      {/*      infoModalVisible={infoModalVisible}*/}
      {/*      isMobile={isMobile}*/}
      {/*      handleInfoModalClose={handleInfoModalClose}*/}
      {/*    />*/}
      {/*    <div className="p-3 pt-4 d-flex w-100">*/}
      {/*      <Responsiveheader title="New Appointment " />*/}
      {/*    </div>*/}
      {/*    /!* <div className='p-3 border-less rounded' style={{background:"#fff"}}> *!/*/}

      {/*    <div*/}
      {/*      className="p-3 border-less mx-2 rounded date-range-picker-wrapper"*/}
      {/*      style={{ background: "#fff", height: "85vh" }}*/}
      {/*    >*/}
      {/*      <div>*/}
      {/*        <p className="px-3  fw-semibold fs-4">*/}
      {/*          What Dates Do You Require These Services For?*/}
      {/*        </p>*/}
      {/*        <Spin spinning={loading} tip="loading...">*/}
      {/*          <Row className="p-3 date_item" >*/}
      {/*            {!nextPage ? <>*/}
      {/*              <Col lg={8} xl={8}>*/}
      {/*                /!* <DateRangePicker*/}
      {/*                  ranges={range}*/}
      {/*                  style={{ width: "80vw" }}*/}
      {/*                  //  className={"date_item"}*/}
      {/*                  onChange={handleSelect}*/}
      {/*                  minDate={moment().toDate()}*/}
      {/*                  maxDate={maxDate}*/}
      {/*                  editableDateInputs={false}*/}
      {/*                  showDateDisplay={false}*/}
      {/*                  showMonthAndYearPickers={false}*/}
      {/*                  showSelectionPreview={false}*/}
      {/*                  renderStaticRangeLabel={false}*/}
      {/*                  staticRanges={[]}*/}
      {/*                  inputRanges={[]}*/}
      {/*                /> *!/*/}
      {/*                */}
      {/*                  <Calendar range multiple value={range} onChange={dateObject => handleSelect(dateObject)} */}
      {/*                  minDate={moment().toDate()}*/}
      {/*                  maxDate={maxDate}*/}
      {/*                  />*/}
      {/*              </Col>*/}

      {/*              <div className="d-flex justify-content-between">*/}
      {/*                <Button*/}
      {/*                  // htmlType="submit"*/}
      {/*                  onClick={() =>*/}
      {/*                    setNextpage(false)*/}
      {/*                  }*/}
      {/*                  className="ant-btn-round ant-btn-default btn-back auth-action-btn mt-3 mb-3 text-center"*/}
      {/*                  shape="round"*/}
      {/*                  size="large"*/}
      {/*                  disabled={value === ""}*/}
      {/*                >*/}
      {/*                  Back*/}
      {/*                </Button>*/}
      {/*                <Button*/}
      {/*                  htmlType="submit"*/}
      {/*                  // disabled={isNextDisabled}*/}
      {/*                  onClick={() => setNextpage(true)}*/}
      {/*                  className="btn-black auth-action-btn mt-3 mb-3"*/}
      {/*                  shape="round"*/}
      {/*                  style={{ marginLeft: '5rem', width: '100%' }}*/}
      {/*                  type="primary"*/}
      {/*                  size="large"*/}
      {/*                >*/}
      {/*                  Next*/}
      {/*                </Button>*/}
      {/*              </div></> : ''}*/}
      {/*            /!* {nextPage?<> *!/*/}
      {/*            {nextPage && !range[0]?.notEdited && (*/}
      {/*              <Col*/}
      {/*                lg={12}*/}
      {/*                xl={14}*/}
      {/*                className=" text-start timing-container"*/}
      {/*              >*/}
      {/*                <p className="mb-0 fw-semibold fs-4 ">Select Time</p>*/}

      {/*                <div style={{ justifyContent: 'space-between', display: 'flex' }}>*/}

      {/*                  <p className="fw-normal fs-7 color-secondary  pt-2">*/}
      {/*                    Minimum 3 hr duration is mandatory*/}
      {/*                  </p>*/}

      {/*                  <img*/}
      {/*                    src="/images/icons/info-circle.svg"*/}
      {/*                    width={20}*/}

      {/*                    onClick={handleInfoModal}*/}
      {/*                  />*/}
      {/*                </div>*/}

      {/*                <Row className="timing-list" gutter={24}>*/}
      {/*                  <Col xs={4}>*/}
      {/*                    <p className="color-secondary">Date</p>*/}
      {/*                  </Col>*/}
      {/*                  <Col xs={10}>*/}
      {/*                    <p className="color-secondary">Start Time</p>*/}
      {/*                  </Col>*/}
      {/*                  <Col xs={10}>*/}
      {/*                    <p className="color-secondary">End Time</p>*/}
      {/*                  </Col>*/}

      {/*                  {slot?.length > 0 &&*/}
      {/*                    slot.map((s, i) => {*/}
      {/*                      let disabledHours = [2, 3, 4, 5];*/}

      {/*                      let disabledStartingHours = [];*/}
      {/*                      let disabledEndingHours = [];*/}

      {/*                      if (s?.isOverNight) {*/}
      {/*                        disabledStartingHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,];*/}
      {/*                        disabledEndingHours = [2, 3, 4, 5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];*/}
      {/*                      }*/}
      {/*                      if (i != 0 && slot[i - 1]?.isOverNight) {*/}
      {/*                        const choosenTime = moment(slot[i - 1]?.time?.endTime).hours();*/}
      {/*                        for (let n = 0; n <= choosenTime; n++) {*/}
      {/*                          disabledStartingHours.push(n);*/}
      {/*                          disabledEndingHours.push(n);*/}
      {/*                        }*/}
      {/*                      }*/}
      {/*                      return (*/}
      {/*                        <>*/}
      {/*                          <Col*/}

      {/*                            xs={3}*/}
      {/*                            style={*/}
      {/*                              s?.isOverNight*/}
      {/*                                ? { marginTop: "20px" }*/}
      {/*                                : {}*/}
      {/*                            }*/}
      {/*                          >*/}
      {/*                            <p className="fw-bold ">*/}
      {/*                              {moment(s?.date, "YYYY/MM/DD").format(*/}
      {/*                                "MMM DD"*/}
      {/*                              )}*/}
      {/*                            </p>*/}
      {/*                          </Col>*/}
      {/*                          <Col xs={9} >*/}
      {/*                            {s?.isOverNight && (*/}
      {/*                              <p>*/}
      {/*                                {moment(s?.date, "YYYY/MM/DD").format(*/}
      {/*                                  "MMM DD"*/}
      {/*                                )}*/}
      {/*                              </p>*/}
      {/*                            )}*/}
      {/*                            <TimePicker*/}
      {/*                              use12Hours*/}
      {/*                              // disabledDate={disabledTimedropDown}*/}
      {/*                              format={"h a"}*/}
      {/*                              size="large"*/}
      {/*                              disabledHours={() => {*/}
      {/*                                if (*/}
      {/*                                  disabledStartingHours.length > 0 ||*/}
      {/*                                  s?.isOverNight*/}
      {/*                                ) {*/}
      {/*                                  return disabledStartingHours;*/}
      {/*                                }*/}
      {/*                                return disabledHours;*/}
      {/*                              }}*/}
      {/*                              style={{*/}
      {/*                                // width: "127px",*/}
      {/*                                height: "50px",*/}
      {/*                                marginBottom: "10px",*/}
      {/*                              }}*/}
      {/*                              placeholder="Start Time"*/}
      {/*                              showNow={false}*/}
      {/*                              allowClear={false}*/}
      {/*                              suffixIcon={*/}
      {/*                                <img*/}
      {/*                                  src="/images/icons/down-arrow.svg"*/}
      {/*                                  width={20}*/}
      {/*                                />*/}
      {/*                              }*/}
      {/*                              onChange={(e, date) =>*/}
      {/*                                handleStartTime(e, s?.date, i)*/}
      {/*                              }*/}
      {/*                              value={*/}
      {/*                                slot[i]?.time?.startTime*/}
      {/*                                  ? moment(slot[i]?.time?.startTime)*/}
      {/*                                  : ""*/}
      {/*                              }*/}
      {/*                            />*/}
      {/*                          </Col>*/}
      {/*                          <Col xs={9} >*/}
      {/*                            {s?.isOverNight && (*/}
      {/*                              <p>*/}
      {/*                                {moment(s?.date, "YYYY/MM/DD")*/}
      {/*                                  .add(1, "days")*/}
      {/*                                  .format("MMM DD")}*/}
      {/*                              </p>*/}
      {/*                            )}*/}
      {/*                            <TimePicker*/}
      {/*                              use12Hours*/}
      {/*                              // disabledDate={disabledTimedropDown}*/}
      {/*                              format={"h a"}*/}
      {/*                              size="large"*/}
      {/*                              style={{*/}
      {/*                                // width: "100px",*/}
      {/*                                height: "50px",*/}
      {/*                                marginBottom: "10px",*/}
      {/*                              }}*/}
      {/*                              placeholder="End Time"*/}
      {/*                              showNow={false}*/}
      {/*                              disabledHours={() => {*/}
      {/*                                if (*/}
      {/*                                  disabledStartingHours.length > 0 ||*/}
      {/*                                  s?.isOverNight*/}
      {/*                                ) {*/}
      {/*                                  return disabledEndingHours;*/}
      {/*                                }*/}
      {/*                                return disabledHours;*/}
      {/*                              }}*/}
      {/*                              disabledMinutes={(selectedHour) => {*/}
      {/*                                const start = moment(slot[i]?.time?.startTime);*/}
      {/*                                const end = moment()*/}
      {/*                                  .hour(selectedHour)*/}
      {/*                                  .startOf("hour");*/}
      {/*                                const diff = end.diff(start, "hours", true);*/}
      {/*                                if (diff < 3) {*/}
      {/*                                  return Array.from(Array(60).keys()).slice(*/}
      {/*                                    start.minute() + 1*/}
      {/*                                  );*/}
      {/*                                }*/}
      {/*                                return [];*/}
      {/*                              }}*/}
      {/*                              // disabled={allDay}*/}
      {/*                              allowClear={false}*/}
      {/*                              suffixIcon={*/}
      {/*                                <img*/}
      {/*                                  src="/images/icons/down-arrow.svg"*/}
      {/*                                  width={20}*/}
      {/*                                />*/}
      {/*                              }*/}
      {/*                              onChange={(e) =>*/}
      {/*                                handleEndTime(e, s?.date, i)*/}
      {/*                              }*/}
      {/*                              // value={*/}
      {/*                              //   editAvailabilityData*/}
      {/*                              //     ? moment(editAvailabilityData.startTime).utc()*/}
      {/*                              //     : startTime*/}
      {/*                              // }*/}
      {/*                              value={*/}
      {/*                                slot[i]?.time?.endTime*/}
      {/*                                  ? moment(slot[i]?.time?.endTime)*/}
      {/*                                  : ""*/}
      {/*                              }*/}
      {/*                            />*/}
      {/*                          </Col>*/}
      {/*                          <Col*/}
      {/*                            xs={2}*/}

      {/*                            */}
      {/*                            style={*/}
      {/*                              s?.isOverNight*/}
      {/*                                ? { marginTop: "33px", display:'flex' }*/}
      {/*                                : {}*/}
      {/*                            }*/}
      {/*                          >*/}
      {/*                            <div className="d-flex " style={{display:'flex', justifyContent:"space-around", marginTop:'12px' }}>*/}
      {/*                             {s?.isOverNight ? (*/}
      {/*                              <img*/}
      {/*                                src="/images/icons/bed-time.svg"*/}
      {/*                                width={20}*/}
      {/*                                // className="mt-3"*/}
      {/*                                onClick={() => handleOverNight(i)}*/}
      {/*                              />*/}
      {/*                            ) : (*/}
      {/*                              <img*/}
      {/*                                src="/images/bedtime.png"*/}
      {/*                                width={20}*/}
      {/*                                */}
      {/*                                // className="mt-3"*/}
      {/*                                onClick={() => handleOverNight(i)}*/}
      {/*                              />*/}
      {/*                            )}*/}
      {/*                            <img*/}
      {/*                              src="/images/icons/clear-icon.svg"*/}
      {/*                              width={20}*/}
      {/*                              style={{marginLeft:'5px'}}*/}
      {/*                              // className="mt-3 mx-2"*/}
      {/*                              onClick={() => handleClearStartEndTime(i)}*/}
      {/*                            /> */}
      {/*                            </div>*/}
      {/*                          </Col>*/}
      {/*                          <Col  xs={24}>*/}
      {/*                            /!* <p className='color-secondary'>Date</p> *!/*/}
      {/*                          </Col>*/}
      {/*                        </>*/}
      {/*                      );*/}
      {/*                    })}*/}
      {/*                </Row>*/}
      {/*                {slot?.length > 0 && (*/}
      {/*                  <Col lg={24} xs={17} xl={17}>*/}
      {/*                    <Divider />*/}
      {/*                    <Typography>*/}
      {/*                      Total Hours:{" "}*/}
      {/*                      <strong>{calculateHours() || 0}</strong>*/}
      {/*                    </Typography>*/}
      {/*                  </Col>*/}
      {/*                )}*/}
      {/*              </Col>*/}
      {/*            )}*/}
      {/*             {nextPage &&*/}
      {/*            <div className="d-flex justify-content-between">*/}
      {/*              <Button*/}
      {/*                // htmlType="submit"*/}
      {/*                onClick={() =>*/}
      {/*                  navigate(*/}
      {/*                    "/client/appointment/create-appointment/services"*/}
      {/*                  )*/}
      {/*                }*/}
      {/*                className="ant-btn-round ant-btn-default btn-back auth-action-btn mt-3 mb-3 text-center"*/}
      {/*                shape="round"*/}
      {/*                size="large"*/}
      {/*                disabled={value === ""}*/}
      {/*              >*/}
      {/*                Back*/}
      {/*              </Button>*/}
      {/*              <Button*/}
      {/*                htmlType="submit"*/}
      {/*                disabled={isNextDisabled}*/}
      {/*                onClick={handleSaveTiming}*/}
      {/*                className="btn-black auth-action-btn mt-3 mb-3"*/}
      {/*                shape="round"*/}
      {/*                style={{ marginLeft: '4rem', width: '100%' }}*/}
      {/*                type="primary"*/}
      {/*                size="large"*/}
      {/*              >*/}
      {/*                Next*/}
      {/*              </Button>*/}
      {/*            </div>}*/}

      {/*          </Row>*/}
      {/*         */}
      {/*        </Spin>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</>*/}

      {/*  :*/}
      <>
        <div
          className="message-main"
          style={{ background: "#E4E4E4", height: "100vH" }}
        >
          <div className="container-fluid appointment-dashboard">
            <div className="pt-5 d-flex client_Expenses">
              <Sidebar />
              <div className="mx-4 message-inner-row w-100">
                <AppointmentNavBar />
                <div
                  className="p-3 border-less rounded date-range-picker-wrapper position-relative date-range-picker-wrapper-custom"
                  style={{ background: "#fff" }}
                >
                  <div className="choose-date-div">
                    <p className="px-3 px-mob-0 text-left-mobile fw-semibold fs-4 mb-2">
                      Which date(s) do you require these services on?
                    </p>
                    <Spin spinning={loading}>
                      <Row className="p-3 px-mob-0 date_item">
                        <Col xs={24} md={24} lg={24} xl={10}>
                          {/* <DateRangePicker
                              ranges={range}
                              //  className={"date_item"}
                              onChange={handleSelect}
                              minDate={moment().toDate()}
                              maxDate={maxDate}
                              editableDateInputs={false}
                              showDateDisplay={false}
                              showMonthAndYearPickers={false}
                              showSelectionPreview={false}
                              renderStaticRangeLabel={false}
                              staticRanges={[]}
                              inputRanges={[]}
                            /> */}
                          <Calendar
                            // range
                            multiple
                            value={range}
                            onChange={(dateObject) => handleSelect(dateObject)}
                            minDate={moment().toDate()}
                            maxDate={maxDate}
                            weekStartDayIndex={0}
                          />
                        </Col>
                        {!range[0]?.notEdited && (
                          <Col
                            xs={24}
                            md={24}
                            lg={24}
                            xl={14}
                            className="ps-4 text-start timing-container-custom timing-container"
                          >
                            <p className="mb-0 fw-semibold fs-4 position-relative">
                              Select Time
                              <img
                                alt={""}
                                src="/images/icons/info-circle.svg"
                                width={20}
                                className="info-icon-tip"
                                onClick={handleInfoModal}
                              />
                            </p>
                            <p className="fw-normal fs-7 color-secondary">
                              Minimum 3 hr duration is mandatory
                            </p>
                            <Row className="p-3 timing-list">
                              <Col xs={6} lg={4} md={4} xl={4}>
                                <p className="color-secondary">Date</p>
                              </Col>
                              <Col xs={8} lg={8} xl={8}>
                                <p className="color-secondary">Start Time</p>
                              </Col>
                              <Col xs={8} lg={8} xl={8}>
                                <p className="color-secondary">End Time</p>
                              </Col>
                              {/*<Col xs={24} lg={4} xl={4}>*/}
                              {/*    /!* <p className='color-secondary'>Date</p> *!/*/}
                              {/*</Col>*/}
                              <Col xs={24} lg={4} xl={4}>
                                {/* <p className='color-secondary'>Date</p> */}
                              </Col>
                              {slot?.length > 0 &&
                                slot.map((s, i) => {
                                  let disabledHours = [2, 3, 4, 5];
                                  const currentTime = moment().hours();
                                  let disabledStartingHours = [2, 3, 4, 5];
                                  let disabledEndingHours = [2, 3, 4, 5];
                                  if (
                                    moment(s?.date).format("DD/MM/YYYY") ===
                                    moment().format("DD/MM/YYYY")
                                  ) {
                                    for (let n = 0; n <= currentTime; n++) {
                                      disabledStartingHours.push(n);
                                      disabledEndingHours.push(n);
                                    }
                                  }
                                  if (s?.isOverNight) {
                                    disabledStartingHours = [
                                      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                                    ];
                                    disabledEndingHours = [
                                      2, 3, 4, 5, 12, 13, 14, 15, 16, 17, 18,
                                      19, 20, 21, 22, 23,
                                    ];
                                  }
                                  if (
                                    i != 0 &&
                                    slot[i - 1]?.isOverNight &&
                                    !s?.isOverNight
                                  ) {
                                    const choosenTime = moment(
                                      slot[i - 1]?.time?.endTime
                                    ).hours();
                                    for (let n = 0; n <= choosenTime; n++) {
                                      disabledStartingHours.push(n);
                                      disabledEndingHours.push(n);
                                    }
                                  }
                                  disabledStartingHours =
                                    disabledStartingHours.map((i) =>
                                      convertToTwoDigits(i)
                                    );
                                  disabledEndingHours = disabledEndingHours.map(
                                    (i) => convertToTwoDigits(i)
                                  );
                                  disabledHours = disabledHours.map((i) =>
                                    convertToTwoDigits(i)
                                  );
                                  return (
                                    <Row
                                      style={{
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                      className="position-relative width-full mb-2"
                                    >
                                      <Col
                                        xs={6}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                        style={
                                          s?.isOverNight
                                            ? { marginTop: "33px" }
                                            : {}
                                        }
                                      >
                                        <p className="fw-bold m-0">
                                          {moment(s?.date, "YYYY/MM/DD").format(
                                            "MMM DD"
                                          )}
                                        </p>
                                      </Col>
                                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                        {s?.isOverNight && (
                                          <p>
                                            {moment(
                                              s?.date,
                                              "YYYY/MM/DD"
                                            ).format("MMM DD")}
                                          </p>
                                        )}
                                        {/* <TimePicker
                                          use12Hours
                                          // disabledDate={disabledTimedropDown}
                                          format={"hh:mm a"}
                                          minuteStep={30}
                                          hourStep={1}
                                          size="large"
                                          disabledHours={() => {
                                            if (
                                              disabledStartingHours.length >
                                                0 ||
                                              s?.isOverNight
                                            ) {
                                              return disabledStartingHours;
                                            }
                                            return disabledHours;
                                          }}
                                          style={{
                                            width: "95%",
                                            height: "50px",
                                            marginBottom: "10px",
                                          }}
                                          placeholder={
                                            isMobile ? "" : "Start Time"
                                          }
                                          inputReadOnly
                                          showNow={false}
                                          allowClear={false}
                                          suffixIcon={
                                            <img
                                              alt={""}
                                              src="/images/icons/down-arrow.svg"
                                              width={20}
                                            />
                                          }
                                          onChange={(e, date) =>
                                            handleStartTime(e, s?.date, i)
                                          }
                                          value={
                                            slot[i]?.time?.startTime
                                              ? moment(slot[i]?.time?.startTime)
                                              : ""
                                          }
                                        /> */}
                                        <Select
                                          placeholder="Start Time"
                                          onChange={(e) =>
                                            handleStartTime(e, s?.date, i)
                                          }
                                          value={
                                            slot[i]?.time?.startTime
                                              ? moment(
                                                  slot[i]?.time?.startTime
                                                ).format("HH:mm")
                                              : ""
                                          }
                                        >
                                          {hourSlots?.map((item) => {
                                            return (
                                              <>
                                                <Select.Option
                                                  value={moment(
                                                    item.start
                                                  ).format("HH:mm")}
                                                  disabled={
                                                    disabledStartingHours.length >
                                                      0 || s?.isOverNight
                                                      ? disabledStartingHours.includes(
                                                          moment(
                                                            item.start
                                                          ).format("HH")
                                                        )
                                                      : disabledHours.includes(
                                                          moment(
                                                            item.start
                                                          ).format("HH")
                                                        )
                                                  }
                                                >
                                                  {moment(item.start).format(
                                                    "hh:mm a"
                                                  )}
                                                </Select.Option>
                                                <Select.Option
                                                  value={moment(
                                                    item.end
                                                  ).format("HH:mm")}
                                                  disabled={
                                                    disabledStartingHours.length >
                                                      0 || s?.isOverNight
                                                      ? disabledStartingHours.includes(
                                                          moment(
                                                            item.end
                                                          ).format("HH")
                                                        )
                                                      : disabledHours.includes(
                                                          moment(
                                                            item.end
                                                          ).format("HH")
                                                        )
                                                  }
                                                >
                                                  {moment(item.end).format(
                                                    "hh:mm a"
                                                  )}
                                                </Select.Option>
                                              </>
                                            );
                                          })}
                                        </Select>
                                      </Col>
                                      <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                        {s?.isOverNight && (
                                          <p>
                                            {moment(s?.date, "YYYY/MM/DD")
                                              .add(1, "days")
                                              .format("MMM DD")}
                                          </p>
                                        )}
                                        {/* <TimePicker
                                          use12Hours
                                          // disabledDate={disabledTimedropDown}
                                          format={"hh:mm a"}
                                          size="large"
                                          hourStep={1}
                                          minuteStep={30}
                                          style={{
                                            width: "95%",
                                            height: "50px",
                                            marginBottom: "10px",
                                          }}
                                          placeholder={
                                            isMobile ? "" : "End Time"
                                          }
                                          inputReadOnly
                                          showNow={false}
                                          disabledHours={() => {
                                            if (
                                              disabledStartingHours.length >
                                                0 ||
                                              s?.isOverNight
                                            ) {
                                              return disabledEndingHours;
                                            }
                                            return disabledHours;
                                          }}
                                          // disabled={allDay}
                                          allowClear={false}
                                          suffixIcon={
                                            <img
                                              alt=""
                                              src="/images/icons/down-arrow.svg"
                                              width={20}
                                            />
                                          }
                                          onChange={(e) => {
                                            console.log(
                                              e,
                                              s?.date,
                                              i,
                                              "unidate"
                                            );
                                            handleEndTime(e, s?.date, i);
                                          }}
                                          // value={
                                          //   editAvailabilityData
                                          //     ? moment(editAvailabilityData.startTime).utc()
                                          //     : startTime
                                          // }
                                          value={
                                            slot[i]?.time?.endTime
                                              ? moment(slot[i]?.time?.endTime)
                                              : ""
                                          }
                                        /> */}
                                        <Select
                                          placeholder="End Time"
                                          onChange={(e) =>
                                            handleEndTime(e, s?.date, i)
                                          }
                                          value={
                                            slot[i]?.time?.endTime
                                              ? moment(
                                                  slot[i]?.time?.endTime
                                                ).format("HH:mm")
                                              : ""
                                          }
                                        >
                                          {hourSlots?.map((item) => {
                                            return (
                                              <>
                                                <Select.Option
                                                  value={moment(
                                                    item.start
                                                  ).format("HH:mm")}
                                                  disabled={
                                                    disabledEndingHours.length >
                                                      0 || s?.isOverNight
                                                      ? disabledEndingHours.includes(
                                                          moment(
                                                            item.start
                                                          ).format("HH")
                                                        )
                                                      : disabledHours.includes(
                                                          moment(
                                                            item.start
                                                          ).format("HH")
                                                        )
                                                  }
                                                >
                                                  {moment(item.start).format(
                                                    "hh:mm a"
                                                  )}
                                                </Select.Option>
                                                <Select.Option
                                                  value={moment(
                                                    item.end
                                                  ).format("HH:mm")}
                                                  disabled={
                                                    disabledEndingHours.length >
                                                      0 || s?.isOverNight
                                                      ? disabledEndingHours.includes(
                                                          moment(
                                                            item.end
                                                          ).format("HH")
                                                        )
                                                      : disabledHours.includes(
                                                          moment(
                                                            item.end
                                                          ).format("HH")
                                                        )
                                                  }
                                                >
                                                  {moment(item.end).format(
                                                    "hh:mm a"
                                                  )}
                                                </Select.Option>
                                              </>
                                            );
                                          })}
                                        </Select>
                                      </Col>
                                      <Col
                                        className={`mode-icon-div ${
                                          s?.isOverNight
                                            ? "mobile-time-margin"
                                            : ""
                                        }`}
                                        xs={24}
                                        lg={4}
                                        xl={4}
                                        style={
                                          s?.isOverNight
                                            ? { marginTop: "33px" }
                                            : {}
                                        }
                                      >
                                        {s?.isOverNight ? (
                                          <img
                                            alt=""
                                            src="/images/icons/bed-time.svg"
                                            width={20}
                                            className="mt-mob-0"
                                            onClick={() => handleOverNight(i)}
                                          />
                                        ) : (
                                          <img
                                            alt=""
                                            src="/images/bedtime.png"
                                            width={20}
                                            className="mt-mob-0"
                                            onClick={() => handleOverNight(i)}
                                          />
                                        )}
                                        <img
                                          alt=""
                                          src="/images/icons/clear-icon.svg"
                                          width={20}
                                          className="mt-mob-0 mx-2"
                                          onClick={() =>
                                            handleClearStartEndTime(i)
                                          }
                                        />
                                      </Col>
                                      <Col
                                        className="d-none-small-devices"
                                        xs={24}
                                        sm={24}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        {/* <p className='color-secondary'>Date</p> */}
                                      </Col>
                                    </Row>
                                  );
                                })}
                            </Row>
                            {slot?.length > 0 && (
                              <Col lg={24} xs={24} xl={17}>
                                <Divider />
                                <Typography>
                                  Total Hours:{" "}
                                  <strong>{calculateHours() || 0}</strong>
                                </Typography>
                              </Col>
                            )}
                          </Col>
                        )}
                      </Row>
                    </Spin>
                  </div>
                  <div className="d-flex justify-content-between common-btn-row common-btn-row-choose-date choose-date-butt-n px-mob-0 px-3">
                    <Button
                      // htmlType="submit"
                      onClick={() =>
                        navigate(
                          "/client/appointment/create-appointment/services"
                        )
                      }
                      className="ant-btn-round ant-btn-default btn-back auth-action-btn mt-3 mb-3 text-center"
                      shape="round"
                      size="large"
                      disabled={value === ""}
                    >
                      Back
                    </Button>
                    <Button
                      htmlType="submit"
                      disabled={isNextDisabled}
                      onClick={handleSaveTiming}
                      className="btn-black auth-action-btn mt-3 mb-3"
                      shape="round"
                      type="primary"
                      size="large"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </>
      {/*}*/}

      <InfoModal
        infoModalVisible={infoModalVisible}
        isMobile={isMobile}
        handleInfoModalClose={handleInfoModalClose}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const InfoModal = (props) => {
  const { infoModalVisible, handleInfoModalClose, isMobile } = props;
  return (
    <div>
      <Modal
        className="infoview-modal-container"
        centered
        width={500}
        style={{ maxWidth: "75vw !important" }}
        header={null}
        footer={null}
        open={infoModalVisible}
        onOk={handleInfoModalClose}
        onCancel={handleInfoModalClose}
        closable
        closeIcon={<CloseCircleOutlined />}
      >
        <div
          className="image-container"
          style={
            isMobile
              ? { position: "relative", left: "30%", right: "30%" }
              : {
                  position: "relative",
                  left: "40%",
                  right: "30%",
                }
          }
        >
          <img
            alt=""
            src="/images/icons/info-group.svg"
            className="text-center"
          />
        </div>
        <p className="text-center mt-3">
          For the safety of our caregivers and companions we do not allow the
          users to start or end any appointment between 2 AM - 5 AM. Thank you
          for understanding.
        </p>
        <Button
          // htmlType="submit"
          onClick={handleInfoModalClose}
          className="btn-black mx-auto auth-action-btn mt-3 mb-3"
          shape="round"
          type="primary"
          size="large"
        >
          I understand
        </Button>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseServices);

import {Button, Checkbox, Form, Input, Spin} from "antd";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {setAlert} from "../../../../redux/alert/alert.action";
import CheckBoxComponent from "../../../../components/forms/check-box";
import RadioBox from "../../../../components/forms/v-radio-box";
import {useNavigate} from "react-router-dom";
import {Logout} from "../../../../redux/auth/auth.action";

function AddRelativeAdditionalDetails({accessToken, save, editRelData}) {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [loadDiesese, setLoadDiesese] = useState(false);
    const {TextArea} = Input;
    const navigate = useNavigate();

    const [disabledSave, setDisabledSave] = useState(true);
    const [isOther, setIsOther] = useState(false);
    const [genderState, setGenderState] = useState();
    const [diseaseData, setDiesaseData] = useState([]);
    const [hasDisease, setHasDisease] = useState([]);
    const [hasPets, setHasPets] = useState([]);
    const [initialData, setInitialData] = useState({
        disease: editRelData?.disease,
        otherDisease: editRelData?.otherDisease,
        gender: editRelData?.gender,
        pets: editRelData?.pets,
        address: editRelData?.address,
        additionalInfo: editRelData?.additionalInfo,
    });
    useEffect(() => {
        if (editRelData !== undefined) {
            setInitData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editRelData, diseaseData]);

    const setInitData = () => {
        console.log("editRelData", editRelData);
        setInitialData({
            otherDisease: editRelData?.otherDisease,
            gender: editRelData?.gender,
            pets: editRelData?.pets,
            address: editRelData?.address,
            additionalInfo: editRelData?.additionalInfo,
        });
        const checkOtherDisease = editRelData?.otherDisease !== "" ? [...editRelData?.disease, "0"] : editRelData?.disease;
        setHasDisease(checkOtherDisease);
        setHasPets(editRelData?.pets);
        setIsOther(checkOtherDisease.includes("0"));
        form.setFieldValue("disease", [...editRelData?.disease, "0"]);
        handleFormChange();
    };
    console.log("isOther", isOther);
    const onFinish = (values) => {
        console.log("Success:", values);
        if (!isOther) {
            values.otherDisease = "";
        }
        const index = values.disease.indexOf("0");
        if (index > -1) {
            // only splice array when item is found
            values.disease.splice(index, 1); // 2nd parameter means remove one item only
            console.log("values >> ", values);
            save(values);
        } else {
            save(values);
        }
        // doLogin(values)
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleFormChange = () => {
        // const areTruthy = Object.values(form.getFieldsValue()).every(
        //   value => value
        // );
        // !areTruthy ||
        const hasErrors = form.getFieldsError().filter(({errors}) => errors.length).length > 0;
        setDisabledSave(hasErrors);
    };

    useEffect(() => {
        fetchDieseseData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDieseseData = () => {
        if (accessToken !== "") {
            setLoadDiesese(true);
            axios
                .post(`${process.env.REACT_APP_BASE_URL}/disease/list`, {}, {
                    headers: {
                        accessToken: accessToken,
                        Authorization: "Basic " + btoa(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD),
                    },
                })
                .then((response) => {
                    console.log("response", response);
                    if (response.data.status === 200) {
                        const data = [...response.data.output, {name: "Other", _id: "0"}];
                        setDiesaseData(data);
                        setLoadDiesese(false);
                    } else if (response.data.status >= 400) {
                        console.log(response);
                        setLoadDiesese(false);
                    } else {
                        setLoadDiesese(false);
                    }
                })
                .catch((err) => {
                    setLoadDiesese(false);
                    if (err?.response.data.status >= 400) {
                        dispatch(setAlert({
                            alertType: "error", alertMsg: err?.response?.data?.error?.message, alertOpen: true,
                        }));
                        if (err.response.data.status === 401) {
                            dispatch(Logout());
                            localStorage.clear();
                            navigate("/");
                            window.location.reload();
                        }
                    }
                });
        }
    };

    const genders = [{
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/male.svg"
            alt="male icon"
        />), name: "Male", value: 1,
    }, {
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/female.svg"
            alt="female icon"
        />), name: "Female", value: 2,
    }, {
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/others.svg"
            alt="others icon"
        />), name: "No preference", value: 3,
    },];

    const pets = [{
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/pets/cat.svg"
            alt="male icon"
        />), name: "Cat", _id: "Cat",
    }, {
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/pets/dog.svg"
            alt="female icon"
        />), name: "Dogs", _id: "Dogs",
    }, {
        icon: (<img
            className="me-3"
            width={25}
            src="/images/icons/pets/pet-free.svg"
            alt="others icon"
        />), name: "Pet Free", _id: "Pet Free",
    },];

    return (<div className="px-3 px-md-0">
        <h1 className="welcome-header fw-bolder mt-4" id="name">
            Additional Information
        </h1>
        <p className="welcome-subheader mb-5">
            Enter the recipient's health information and preferences so that
            caregivers can provide the adequate level of care
        </p>
        <div className="col-12 text-start form-row form-row-div">
            <Form
                form={form}
                name="signup-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
                // validateTrigger='onSubmit'
                onFieldsChange={handleFormChange}
                initialValues={initialData}
                requiredMark={false}
                className="mb-0 p-md-4 text-start"
            >
                <Form.Item
                    name="disease"
                    type="text"
                    className="mb-3"
                    label={<p className="color-secondary mb-0 fs-6">
                        1. Does the care recipient have any of these conditions?
                    </p>}
                    rules={[{
                        required: true, message: "Select at least one option!",
                    },]}
                >
                    {loadDiesese ? (<div className="text-center p-5">
                        <Spin size="large"/>
                    </div>) : (<CheckBoxComponent
                        values={hasDisease}
                        onChange={(val) => {
                            const foundNone = diseaseData.find((i) => i.name == "None");
                            if (val.includes(foundNone?._id)) {
                                setHasDisease([foundNone?._id]);
                            } else {
                                setHasDisease(val);
                                form.setFieldValue("disease", val);
                                setIsOther(val.includes("0"));
                                console.log("00 >", val);
                            }
                        }}
                        items={diseaseData}
                    />)}
                </Form.Item>
                {isOther && (<Form.Item
                    name="otherDisease"
                    type="text"
                    style={{maxWidth: "320px"}}
                    className="mb-3"
                    rules={[{
                        required: true, message: "Please enter your other disease!",
                    },]}
                >
                    <Input size="large" placeholder="Enter health condition(s)"/>
                </Form.Item>)}
                <Form.Item
                    name="gender"
                    className="mb-3"
                    label={<p className="color-secondary mb-0 fs-6">
                        2. Does the care recipient have a preference regarding the
                        caregiver's sex?{" "}
                    </p>}
                    rules={[{
                        required: true, message: "Please select preferred gender!",
                    },]}
                >
                    <RadioBox
                        btnClass={"border-less"}
                        onChange={(e) => {
                            form.setFieldValue("gender", e.target.value);
                            setGenderState(e.target.value);
                        }}
                        options={genders}
                        value={genderState}
                    />
                </Form.Item>
                <Form.Item
                    name="pets"
                    className="mb-3"
                    label={<p className="color-secondary mb-0 fs-6">
                        3. Does the care recipient have any pets?{" "}
                    </p>}
                    rules={[{
                        required: true, message: "Select at least one option!",
                    },]}
                >
                    <Checkbox.Group
                        values={hasPets}
                        style={{width: "100%"}}
                        onChange={(values) => {
                            if (values.includes("Pet Free")) {
                                setHasPets(["Pet Free"]);
                                form.setFieldValue("pets", ["Pet Free"]);
                            } else {
                                setHasPets(values);
                                form.setFieldValue("pets", values);
                            }
                            console.log("val", values);
                        }}
                    >
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <Checkbox
                                    style={{flexDirection: "row-reverse"}}
                                    className="checkbox-custom-additional"
                                    value="Cat"
                                    checked={hasPets?.includes("Cat")}
                                    disabled={hasPets?.includes("Pet Free")}>
                                    <div
                                        className={`text-body pets-section-button ${hasPets?.includes("Cat") ? "checked" : ""}`}>
                                        <img className="me-3" width={25} src="/images/icons/pets/cat.svg"
                                             alt="cat icon"/>{" "}
                                        Cat
                                    </div>
                                </Checkbox>
                            </li>
                            <li className="list-inline-item">
                                <Checkbox
                                    style={{flexDirection: "row-reverse"}}
                                    className="checkbox-custom-additional"
                                    value="Dogs"
                                    checked={hasPets?.includes("Dogs")}
                                    disabled={hasPets?.includes("Pet Free")}
                                >
                                    <div
                                        className={`text-body pets-section-button ${hasPets?.includes("Dogs") ? "checked" : ""}`}>
                                        <img className="me-3" width={25} src="/images/icons/pets/dog.svg"
                                             alt="dog icon"/>{" "}
                                        Dogs
                                    </div>
                                </Checkbox>
                            </li>
                            <li className="list-inline-item">
                                <Checkbox
                                    style={{flexDirection: "row-reverse"}}
                                    className="checkbox-custom-additional"
                                    value="Pet Free"
                                    checked={hasPets?.includes("Pet Free")}
                                    disabled={hasPets?.includes("Cat") || hasPets?.includes("Dogs")}
                                >
                                    <div
                                        className={`text-body pets-section-button ${hasPets?.includes("Pet Free") ? "checked" : ""}`}>
                                        <img className="me-3" width={25} src="/images/icons/pets/pet-free.svg"
                                             alt="Pet Free icon"/>{" "}
                                        Pet Free
                                    </div>
                                </Checkbox>
                            </li>
                        </ul>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item
                    name="additionalInfo"
                    type="text"
                    label={<p className="color-secondary mb-0 fs-6">
                        4. Any additional information or instructions not covered yet
                        and deemed important for the caregivers to be aware of before
                        the appointment?
                    </p>}
                    className="mb-3"
                    // rules={[
                    // {
                    // required: true,
                    // message: 'Please enter additional information!',
                    // }]}
                >
                    <TextArea rows={4} placeholder="Type here"/>
                </Form.Item>
                <Form.Item className="text-center mb-2">
                    <Button
                        className="btn-black auth-action-btn mt-3 mx-auto"
                        shape="round"
                        type="primary"
                        size="large"
                        htmlType="submit"
                        disabled={!Object.values(form.getFieldsValue()).some((value) => value) || form.getFieldsError().filter(({errors}) => errors.length).length > 0}
                    >
                        Finish{" "}
                        <img
                            className="ms-2"
                            src="/images/icons/right.svg"
                            alt="right icon"
                        />
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </div>);
}

const mapStateToProps = (state) => ({
    accessToken: state.loginData.user.accessToken,
});

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRelativeAdditionalDetails);

import React from "react";
import {
  ElementsConsumer,
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { Button } from "antd";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enableSubmit: false, elements: {} };
  }
  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements, name, save } = this.props;

    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const cvc = elements.getElement(CardCvcElement);
    const expiry = elements.getElement(CardExpiryElement);
    console.log("card", expiry, cvc, card);
    const result = await stripe.createToken(card, { name: name });
    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
      save(result.token);
    }
  };

  handleChange = async (event) => {
    const e = await event;
    console.log("com", e);
    this.setState((prev) => ({
      elements: { ...prev.elements, [e.elementType]: e },
    }));
    // if (e?.complete === true) {
    //   // enable payment button
    //   this.setState({
    //     enableSubmit: true,
    //   });
    //   console.log("com", e);
    // } else if (e?.error) {
    //   // show validation to customer
    //   console.log("err");
    //   this.setState({
    //     enableSubmit: false,
    //   });
    // } else if (e?.empty === false && e?.error === undefined) {
    //   this.setState({
    //     enableSubmit: false,
    //   });
    // }
  };

  checkDisabled = () => {
    const { elements } = this.state;
    if (
      !elements?.cardCvc?.complete ||
      !elements?.cardExpiry?.complete ||
      !elements?.cardNumber?.complete ||
      elements?.cardCvc?.error?.code ||
      elements?.cardExpiry?.error?.code ||
      elements?.cardNumber?.error?.code ||
      !this.props.name
    ) {
      return true;
    }
    return false;
  };
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <CardSection onChange={this.handleChange} />
          <button
            disabled={this.checkDisabled()}
            className="btn-black auth-action-btn mb-5 mt-5 mx-auto"
          >
            Next{" "}
            <img
              className="ms-2"
              src="/images/icons/right.svg"
              alt="right icon"
            />
          </button>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutForm({ name, save }) {
  {
    console.log("na > ", name);
  }
  const saveBack = (tc) => {
    save(tc);
  };
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          name={name}
          save={saveBack}
        />
      )}
    </ElementsConsumer>
  );
}

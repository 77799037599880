import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Image,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

import { ArrowRightOutlined, CloseCircleOutlined } from "@ant-design/icons";
// import DatePicker from "../../common/DatePicker";
import DatePicker from "../caregiver/appointment/Availability-datepicker";
import moment from "moment/moment";
import axios from "axios";
import { ModalBody } from "./ModalBody";
import "./Availability.less";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../components/ScrollWrapper/ScrollWrapper";
import { LOGOUT } from "../../redux/auth/auth.type";
import { setAlert } from "../../redux/alert/alert.action";
import { setTimeToTravel, setUser } from "../../redux/auth/auth.action";

const HOURS_24 = new Array(24).fill().map((item, index) => index);
const UNSAFE_HOURS_DISABLED_INDEXES = [2, 3, 4, 5];

function Availability(props) {
  const { Title, Text } = Typography;
  const { Option, OptGroup } = Select;
  const { accessToken, isEdit } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  // const [api, contextHolder] = notification.useNotification();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [allDay, setAllDay] = useState(false);
  const [overnightAvailability, setOvernightAvailability] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [startTimeString, setStartTimeString] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [endTimeString, setEndTimeString] = useState(null);
  const [datesData, setDatesData] = useState(null);
  const [selectedDateData, setSelectedDateData] = useState([]);
  const [daysActive, setdaysActive] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const [editAvailabilityData, setEditAvailabilityData] = useState(null);
  const [minDuration, setMinDuration] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const vidRef = useRef();
  const dispatch = useDispatch();
  const [startTimeDisabledHrs, setStartTimeDisabledHrs] = useState(
    UNSAFE_HOURS_DISABLED_INDEXES
  );
  const [endTimeDisabledHrs, setEndTimeDisabledHrs] = useState(
    UNSAFE_HOURS_DISABLED_INDEXES
  );
  const timeToTravel = useSelector((state) => state.loginData.timeToTravel);
  const [viewTutorial, setViewTutorial] = useState({});
  const fetchSlots = async () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/list-availability`,
        {
          startDate:
            moment().format("yyyy-MM-DDT").toString() + "00:00:00.000+0000",
          endDate:
            moment().add(90, "days").format("yyyy-MM-DDT").toString() +
            "00:00:00.000+0000",
        },
        {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
          },
        }
      )
      .then((res) => {
        let dates = [];
        res?.data?.output?.list.forEach((item) => {
          item.time?.forEach((time) => {
            let eachDateTimeSlot = {
              date: moment(item.date),
              day: moment.utc(item.date).format("DD MMMM"),
              userId: item.userId,
              isAvailableAllDay: item.isAvailableAllDay,
              isOverNight: false,
            };
            if (time.startTime && time.endTime) {
              let startTime = moment(time.startTime);
              let endTime = moment(time.endTime);
              eachDateTimeSlot["startTime"] = startTime;
              eachDateTimeSlot["endTime"] = endTime;
              if (
                startTime.utc().format("hh:mm A").includes("PM") &&
                endTime.utc().format("hh:mm A").includes("AM")
              ) {
                eachDateTimeSlot["isOverNight"] = true;
              }
              eachDateTimeSlot["slotId"] = time._id;
              // console.log("slot time", time);
              // console.log("slot eachDateTimeSlot", eachDateTimeSlot);
              dates.push(eachDateTimeSlot);
            }
          });
        });
        console.log("slotddddddddddddd dates", dates);
        setSelectedDateData(dates);
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          showMessage(err?.response?.data?.error?.message);
        } else {
          if (err?.response?.data?.status !== 401) showMessage(err?.message);
        }
      });
  };

  useEffect(() => {
    (async () => await fetchSlots())();
    const currentDate = moment();
    const currentTime = currentDate.hour();
    if (editAvailabilityData) {
      // Disable the start,end time disabled hours till the current time
      if (editAvailabilityData.date?.isAfter(currentDate)) {
        setStartTimeDisabledHrs(UNSAFE_HOURS_DISABLED_INDEXES);
        setEndTimeDisabledHrs(UNSAFE_HOURS_DISABLED_INDEXES);
        return;
      }
    }
    // Disable the start,end time disabled hours till the current time
    setStartTimeDisabledHrs([
      ...HOURS_24.filter((item) => item <= currentTime),
      ...UNSAFE_HOURS_DISABLED_INDEXES,
    ]);
    setEndTimeDisabledHrs([
      ...HOURS_24.filter((item) => item <= currentTime),
      ...UNSAFE_HOURS_DISABLED_INDEXES,
    ]);
  }, [editAvailabilityData]);

  // useEffect(() => {
  //   console.log("use startTime", startTime, "endTime", endTime);
  //   let newStartTime = moment(startTime);
  //   let newEndTime = moment(endTime);
  //   if (startTime && endTime) {
  //     var duration = endTime.diff(startTime, "minutes");
  //     console.log("duration", Math.round(duration / 60));
  //     const roundedHours = Math.round(duration / 60);
  //     setMinDuration(roundedHours);
  //     message.destroy();
  //     if (roundedHours < 3) {
  //       showMessage("Minimum 3 hours gap required", "warning");
  //     }
  //   }
  // }, [startTime, endTime]);

  useEffect(() => {
    fetchTutorial();
  }, []);
  const Logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };
  const handleClick = () => {
    setOpenTutorial(true);
  };
  const handleVideoClose = () => {
    vidRef.current.pause();
    setOpenTutorial(false);
  };
  const showModal = (date, index) => {
    const newSelectedDate = moment(date);
    const today = moment();
    console.log("date", date);
    console.log("newSelectedDate", newSelectedDate);
    let weekDays = [];
    const sunday = moment().startOf("week").add(0, "days");
    for (let i = 1; i < 7; i++) {
      weekDays.push(moment().startOf("week").add(i, "days"));
    }
    weekDays.push(sunday);
    // setStartTime(newSelectedDate);
    // setEndTime(newSelectedDate);
    const runningHour = newSelectedDate.hours();
    const defaultVal = moment(date).set({
      hour: 6,
      minutes: 0,
      seconds: 0,
    });
    console.log("runningHour hours::", runningHour);
    if (newSelectedDate.isAfter(today, "day")) {
      setStartTimeDisabledHrs([...UNSAFE_HOURS_DISABLED_INDEXES]);
      setEndTimeDisabledHrs([...UNSAFE_HOURS_DISABLED_INDEXES]);
    } else {
      // Disable the start,end time disabled hours till the current time
      setStartTimeDisabledHrs([
        ...HOURS_24.filter((item) => item <= runningHour),
        ...UNSAFE_HOURS_DISABLED_INDEXES,
      ]);
      setEndTimeDisabledHrs([
        ...HOURS_24.filter((item) => item <= runningHour),
        ...UNSAFE_HOURS_DISABLED_INDEXES,
      ]);
    }
    console.log("showModaldate", date, index);
    setdaysActive([]);
    // handleDayClick(date);
    setWeekDays(weekDays);
    setOvernightAvailability(false);
    setStartTime(defaultVal);
    setSelectedDate({ date: moment(date), index });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setdaysActive([]);
    setIsModalOpen(false);
    setOvernightAvailability(false);
    setIsModalOpen(false);
    setStartTime(null);
    setEndTime(null);
    setStartTimeString(null);
    setEndTimeString(null);
    setEditAvailabilityData(null);
  };

  const handleAllDay = (e) => {
    setAllDay(e.target.checked);
  };
  const handleOvernightAvailability = (e) => {
    console.log(editAvailabilityData, "editAvailabilityData");
    setOvernightAvailability(e.target.checked);
    const defaultOvernightVal = moment(selectedDate?.date).set({
      hour: 18,
      minutes: 0,
      seconds: 0,
    });
    const defaultVal = moment(selectedDate?.date).set({
      hour: 6,
      minutes: 0,
      seconds: 0,
    });
    if (e.target.checked) {
      const newSelctedDate = moment(selectedDate?.date);
      // setEndTime(newSelctedDate.add(1, "day"));
      const emptyArr = new Array(12).fill("");
      const currentTime = newSelctedDate.hours();
      const today = moment();
      console.log("currentTime", currentTime);
      if (newSelctedDate.isAfter(today, "day")) {
        setStartTimeDisabledHrs([
          ...emptyArr.map((item, index) => index),
          ...UNSAFE_HOURS_DISABLED_INDEXES,
        ]);
      } else {
        setStartTimeDisabledHrs([
          ...emptyArr.map((item, index) => index),
          ...HOURS_24.slice(12).filter((item) => item <= currentTime),
          ...UNSAFE_HOURS_DISABLED_INDEXES,
        ]);
      }
      if (editAvailabilityData) {
        const editData = { ...editAvailabilityData };
        const defaultEditStartOvernightVal = moment(editData?.startTime).set({
          hour: 18,
          minutes: 0,
          seconds: 0,
        });
        const defaultEditEndOvernightVal = moment(editData?.startTime)
          .add("day", 1)
          .set({
            hour: 0,
            minutes: 0,
            seconds: 0,
          });
        editData.startTime = moment(defaultEditStartOvernightVal);
        editData.endTime = moment(defaultEditEndOvernightVal);
        editData.edited = true;
        setStartTime(moment(defaultEditStartOvernightVal));
        setEndTime(defaultEditEndOvernightVal);
        setEditAvailabilityData(editData);
      } else {
        setStartTime(defaultOvernightVal);
      }
      setEndTime(null);
      setEndTimeDisabledHrs([
        2,
        3,
        4,
        5,
        ...emptyArr.map((item, index) => 12 + index),
      ]);
    } else {
      console.log(editAvailabilityData, "editAvailabilityData");
      if (editAvailabilityData) {
        debugger;
        const editData = { ...editAvailabilityData };
        const selectedDay = moment(editData?.date)?.date();
        editData.startTime = moment(editData?.startTime).set({
          date: selectedDay,
          hour: 6,
        });
        // .utc();
        editData.endTime = moment(editData?.endTime).set({
          date: selectedDay,
          hour: 9,
        });
        // .utc()
        // .format("YYYY-MM-DDTHH:mm:ssZ");
        editData.edited = true;
        // setEditAvailabilityData(editData);
        console.log(editData.endTime, selectedDay, "endTimeendTime");
        setEditAvailabilityData(editData);
        const newSelectedDate = moment(selectedDate?.date);
        setEndTime(newSelectedDate);
      } else {
        const endTimeDefault = moment(selectedDate?.date).startOf("day");
        setEndTime(null);
      }
      setStartTime(defaultVal);
      const unsafeHrs = new Array(4).fill("").map((item, index) => 2 + index);
      setStartTimeDisabledHrs(unsafeHrs);
      setEndTimeDisabledHrs([unsafeHrs]);
    }
  };
  const handleStartTime = (time, timeString) => {
    // console.log("handleStartTime time", hrs);
    let overnightHrs = Math.round(
      moment.duration(editAvailabilityData?.endTime.diff(time)).asHours()
    );
    if (editAvailabilityData) {
      const hrs =
        moment(editAvailabilityData?.endTime).zone(0).hours() - time.hours();
      console.log(hrs, "hrshrs");
      if (hrs < 3 && !overnightAvailability) {
        message.error(
          "An availability could be of a minimum of 3 hours duration. "
        );
        setMinDuration(hrs);
      } else if (overnightAvailability && overnightHrs < 3) {
        message.error(
          "An availability could be of a minimum of 3 hours duration. "
        );
      } else {
        setEditAvailabilityData({
          ...editAvailabilityData,
          startTime: time.set({
            hour: time.hours(),
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          edited: true,
        });
      }
    } else {
      const hrs = moment(endTime).hours() - time.hours();
      if (hrs < 3 && !overnightAvailability) {
        message.error(
          "An availability could be of a minimum of 3 hours duration."
        );
        setMinDuration(hrs);
      } else {
        setStartTime(
          time.set({
            hour: time.hours(),
            minute: 0,
            second: 0,
            millisecond: 0,
            date: selectedDate?.date.date(),
            month: selectedDate?.date.month(),
            year: selectedDate?.date.year(),
          })
        );
        setStartTimeString(timeString);
      }
    }

    // setSelectedDateData([[moment(selectedDate).format("DD MMMM")]: { ...selectedDateData, startTime: time }]);
    // if (endTime) {
    //   const startTimeArr = startTime.split(" ");
    //   const EndTimeArr = timeString.split(" ");
    //   if (EndTimeArr[1] === "pm") {
    //     EndTimeArr[0] = parseInt(EndTimeArr[0]) + 12;
    //   }
    //   if (parseInt(EndTimeArr[0]) - parseInt(startTimeArr[0]) < 3) {
    //     setTimeError(true);
    //   } else {
    //     setEndTime(timeString);
    //   }
    // }
  };

  const handleEndTime = (time, timeString) => {
    console.log("handleEndTime time", time.hours());
    let overnightHrs = Math.round(
      moment.duration(time.diff(editAvailabilityData?.startTime)).asHours()
    );
    // let hrs = Math.round(moment.duration(time.diff(editAvailabilityData?.startTime)).asHours());
    // console.log(hrs, "hourshours")
    if (editAvailabilityData) {
      const hrs = time.hours() - editAvailabilityData?.startTime.hours();
      if (hrs < 3 && !overnightAvailability) {
        message.error(
          "An availability could be of a minimum of 3 hours duration."
        );
        setMinDuration(hrs);
      } else if (overnightAvailability && overnightHrs < 3) {
        message.error(
          "An availability could be of a minimum of 3 hours duration. "
        );
      } else {
        setEditAvailabilityData({
          ...editAvailabilityData,
          endTime: time.set({
            hour: time.hours(),
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
          edited: true,
        });
      }
    } else {
      if (overnightAvailability) {
        const newDate = time
          .set({
            hour: time.hours(),
            minute: 0,
            second: 0,
            millisecond: 0,
            date: selectedDate?.date.date(),
            month: selectedDate?.date.month(),
            year: selectedDate?.date.year(),
          })
          .add(1, "day");
        const hrs = moment.duration(newDate.diff(startTime)).asHours();
        if (hrs < 3) {
          message.error(
            "An availability could be of a minimum of 3 hours duration."
          );
        } else {
          setEndTime(
            time
              .set({
                hour: time.hours(),
                minute: 0,
                second: 0,
                millisecond: 0,
                date: selectedDate?.date.date(),
                month: selectedDate?.date.month(),
                year: selectedDate?.date.year(),
              })
              .add(1, "day")
          );
        }
      } else {
        const hrs = time.hours() - startTime.hours();
        if (hrs < 3) {
          message.error(
            "An availability could be of a minimum of 3 hours duration."
          );
          setMinDuration(hrs);
        } else {
          setEndTime(
            time.set({
              hour: time.hours(),
              minute: 0,
              second: 0,
              millisecond: 0,
              date: selectedDate?.date.date(),
              month: selectedDate?.date.month(),
              year: selectedDate?.date.year(),
            })
          );
        }
      }

      setEndTimeString(timeString);
    }

    // if (startTime) {
    // conFt startTimeArr = startTimeString.split(" ");
    // const EndTimeArr = timeString.split(" ");
    // if (EndTimeArr[1] === "pm") {
    //   EndTimeArr[0] = parseInt(EndTimeArr[0]) + 12;
    // }
    // if (parseInt(EndTimeArr[0]) - parseInt(startTimeArr[0]) < 3) {
    //   errorTime();
    //   setEndTime(null);R
    // } else {
    //   setEndTimeString(timeString);
    //   setEndTime(time);
    // }
    // }
  };

  const showMessage = (error, type = "error") => {
    message.destroy();
    messageApi.open({
      type: type,
      content: error,
      duration: 5,
    });
  };

  const handleDayClick = (day) => {
    console.log("handleDayClick", day);
    if (!day) return;
    if (daysActive.includes(day)) {
      const dayRemoved = daysActive.filter((item) => item !== day);
      setdaysActive(dayRemoved);
      console.log(dayRemoved, "dayRemoved");
    } else {
      setdaysActive([...daysActive, day]);
    }
  };

  const handleSaveOfTime = () => {
    console.log(editAvailabilityData, "editAvailabilityData");
    setIsLoading(true);
    if (editAvailabilityData?.edited) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/edit-availability`,
          {
            id: editAvailabilityData.slotId,
            time: {
              startTime: editAvailabilityData.startTime,
              endTime: editAvailabilityData.endTime,
            },
            isAvailableAllDay: editAvailabilityData.isAvailableAllDay,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
              // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
            },
          }
        )
        .then((res) => {
          if (res?.data?.status === 200) {
            setEditAvailabilityData(null);
            handleCancel();
            let allSlots = selectedDateData;
            let updatedSlot = allSlots.findIndex(
              (item) => item.slotId === editAvailabilityData.slotId
            );
            allSlots[updatedSlot].startTime = editAvailabilityData.startTime;
            allSlots[updatedSlot].endTime = editAvailabilityData.endTime;
            // setSelectedDateData(allSlots);
            fetchSlots();
          }
          console.log("response", res);
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            showMessage(err?.response?.data?.error?.message);
          } else {
            if (err?.response?.data?.status !== 401) showMessage(err?.message);
          }
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (allDay) {
      let date = moment(selectedDate?.date);
      let day = moment(selectedDate?.date).format("DD MMMM");

      let startTime =
        moment(date).format("yyyy-MM-DDT").toString() + "00:00:00.000+0000";
      let endTime =
        moment(date).format("yyyy-MM-DDT").toString() + "23:59:59.000+0000";

      let slotsCreated = [...selectedDateData];

      daysActive.map((item) => {
        day = moment(item).format("DD MMMM");
        slotsCreated.push({
          day,
          date: item,
          startTime: startTime,
          endTime: endTime,
        });
      });

      let slot = slotsCreated.map((item) => {
        return {
          date:
            moment(item.date).format("yyyy-MM-DDT").toString() +
            "00:00:00.000+0000",
          time: {
            startTime,
            endTime,
          },
          isAvailableAllDay: true,
        };
      });
      if (!editAvailabilityData) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/add-availability`,
            {
              slot,
            },
            {
              headers: {
                accessToken: accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
                // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
              },
            }
          )
          .then((res) => {
            if (res) {
              // setSelectedDateData([...slotsCreated]);
              setOvernightAvailability(false);
              setIsModalOpen(false);
              setStartTime(null);
              setEndTime(null);
              setStartTimeString(null);
              setEndTimeString(null);
              fetchSlots();
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              err?.response?.data?.status >= 400 &&
              err?.response?.data?.status !== 401
            ) {
              showMessage(err?.response?.data?.error?.message);
            } else {
              if (err?.response?.data?.status != 401) showMessage(err?.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
    const slotsArr = [];
    if (daysActive.length > 0 && overnightAvailability) {
      let StartTime = moment(startTime);
      let Selected = moment(selectedDate?.date).format("DD MMMM yyyy");
      let EndTime = moment(endTime);
      let dates = [];
      for (let i = 0; i < 90; i++) {
        dates.push(moment().add(i, "days"));
      }
      dates.forEach((i) => {
        daysActive.forEach((item) => {
          const activeDay = moment(item).day();
          const dateDay = moment(i).day();
          if (moment(i).isAfter(Selected) && activeDay == dateDay) {
            const startTimeObj =
              moment(i).format("YYYY-MM-DD") +
              "T" +
              moment(StartTime).format("HH") +
              `:00:00.000Z`;
            const slot = {
              date:
                moment(i).format("yyyy-MM-DDT").toString() +
                "00:00:00.000+0000",
              time: {
                startTime: startTimeObj,
              },
              isAvailableAllDay: false,
            };
            if (overnightAvailability) {
              slot.time["endTime"] =
                i.format("YYYY-MM") +
                "-" +
                moment(i).add(1, "days").format("DD") +
                "T" +
                moment(EndTime).format("HH") +
                `:00:00.000Z`;
            } else {
              slot.time["endTime"] =
                i.format("YYYY-MM") +
                "-" +
                moment(i).format("DD") +
                "T" +
                moment(EndTime).format("HH") +
                `:00:00.000Z`;
            }
            slotsArr.push(slot);
          }
        });
      });
    }
    if (startTime && endTime) {
      let day = moment(selectedDate?.date).format("DD MMMM yyyy");
      console.log("handleSaveOfTime day", day);
      console.log("handleSaveOfTime selectedDate", selectedDate);
      console.log("handleSaveOfTime daysActive", daysActive);
      let targetDay = moment(selectedDate?.date).add(90, "days");
      let newStartTime = moment(startTime);
      // .set("day", date.date())
      // .set("month", date.month() + 1, "year", date.year());
      let newEndTime = moment(endTime);
      console.log("newStartTime", newStartTime);
      console.log("newEndTime", newEndTime);
      let slotsCreated = [
        {
          day,
          date: day,
          startTime: newStartTime,
          endTime: newEndTime,
        },
      ];
      if (daysActive.length > 0) {
        daysActive.forEach((item) => {
          let date = moment(item);
          while (date.isBefore(day)) {
            date.add(7, "days");
          }
          console.log("date after adjust", date);
          while (date.isBefore(targetDay)) {
            day = date.format("DD MMMM yyyy");

            let start = moment(date).set({
              hour: newStartTime.hours(),
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            let end = moment(date).set({
              hour: newEndTime.hours(),
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            if (overnightAvailability) {
              endTime.add(1, "day");
            }
            let foundSlot = slotsCreated.find((slot) => {
              return moment(slot.date).isSame(moment(date));
            });
            if (!foundSlot) {
              slotsCreated.push({
                day,
                date: day,
                startTime: start,
                endTime: end,
              });
            }
            date.add(7, "days");
          }
        });
      }
      console.log("handleSaveOfTime slotsCreated", slotsCreated);
      let slot = slotsCreated.map((item) => {
        return {
          date:
            moment(item.date).format("yyyy-MM-DDT").toString() +
            "00:00:00.000+0000",
          time: {
            startTime:
              moment(item.startTime).format("yyyy-MM-DDTHH:mm:ss").toString() +
              ".000+0000",
            endTime:
              moment(item.endTime).format("yyyy-MM-DDTHH:mm:ss").toString() +
              ".000+0000",
          },
          isAvailableAllDay: false,
        };
      });
      console.log("handleSaveOfTime slots", slot);
      if (slot.length > 1) {
        slot.shift();
      }
      // if (1 == 1) return;
      if (!editAvailabilityData) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/add-availability`,
            {
              slot:
                daysActive.length > 0 && overnightAvailability
                  ? slotsArr
                  : slot,
            },
            {
              headers: {
                accessToken: accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
                // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
              },
            }
          )
          .then((res) => {
            if (res) {
              // setSelectedDateData([...selectedDateData, ...slotsCreated]);
              fetchSlots();
              setOvernightAvailability(false);
              setIsModalOpen(false);
              setStartTime(null);
              setEndTime(null);
              setStartTimeString(null);
              setEndTimeString(null);
            }
          })
          .catch((err) => {
            console.log(err);
            if (
              err?.response?.data?.status >= 400 &&
              err?.response?.data?.status !== 401
            ) {
              showMessage(err?.response?.data?.error?.message);
            } else {
              if (err?.response?.data?.status != 401) showMessage(err?.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleEditAvailablity = (availability) => {
    setIsModalOpen(true);
    setOvernightAvailability(availability?.isOverNight);
    setEditAvailabilityData(availability);
  };

  const handleDeleteAvailability = (availability) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/delete-availability`,
        {
          id: availability.slotId,
        },
        {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          // let slotRemoved = selectedDateData.filter(
          //   (item) => item.slotId !== availability.slotId
          // );
          fetchSlots();
          // setSelectedDateData(slotRemoved);
        }
      })
      .catch((err) => {
        console.log(err);
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          showMessage(err?.response?.data?.error?.message);
        } else {
          if (err?.response?.data?.status != 401) showMessage(err?.message);
        }
      });
  };

  const handleDashboardNavigate = () => {
    navigate("/caregiver/appointments-dashboard");
  };
  const fetchTutorial = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/tutorial/view-tutorial-byCategory`,
        {
          userType: 3,
          name: "Availability",
        },

        {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((res) => {
        setViewTutorial(res.data.output);
        console.log(Object.keys(viewTutorial).length === 0, "empty");
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          showMessage(err?.response?.data?.error?.message);
        }
      });
    //  console.log(response,"response in fectch tutorial");
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChange = (value) => {
    const data = {
      timeToTravel: value,
    };
    if (accessToken != "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/user/update-profile`, data, {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            dispatch(setTimeToTravel(value));
            message.success("Travel time updated successfully");
          }
        })
        .catch((err) => {
          if (err?.response?.data?.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch({ type: LOGOUT });
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          }
        });
    }
  };
  return (
    <>
      {/* <div className="text-end">
        <Button type="primary" onClick={Logout}>
          Logout
        </Button>
      </div> */}
      {contextHolder}
      <Row style={{ minWidth: "100%" }}>
        <Col xs={24} sm={24} lg={24} md={24}>
          <div
            className={`${
              !isEdit
                ? "center-container min-vh-100 p-4 parent-avalability parent-avalability-custom"
                : "parent-avalability parent-avalability-update"
            }`}
            style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          >
            <div
              className={`${!isEdit ? "welcome-container" : ""}`}
              style={{ display: "Grid" }}
            >
              <div
                style={{ padding: isEdit ? "0px" : "30px" }}
                className="availability-contents"
              >
                <div className="mt-4 justify-content-center text-center heading-text">
                  <Title
                    className="mb-mob-0"
                    level={3}
                    style={{ fontWeight: "700" }}
                  >
                    {isEdit ? "Edit" : "Add"} your availability
                  </Title>
                  <Text style={{ color: "#64748B" }}>
                    You can add upto 3 months
                  </Text>
                </div>
                {isEdit ? (
                  ""
                ) : (
                  <div className="text-center-ipad">
                    Need help?{" "}
                    <a onClick={handleClick}>Click here to view tutorial</a>
                  </div>
                )}
                <div className="align-middle d-flex justify-content-between time-text">
                  <div className="mt-4 d-flex flex-column text-left-mob">
                    <Text style={{ fontSize: "20px", lineHeight: "40px" }}>
                      Time to Travel
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        color: "#64748B",
                        marginBottom: "1rem",
                      }}
                    >
                      Select the amount of time you need between appointments
                      (i.e. travel time, break)
                    </Text>
                  </div>

                  <div className="mt-4 time-text-dropdown">
                    <Select
                      placeholder="time"
                      size="large"
                      style={{
                        width: isMobile ? "100%" : 300,
                      }}
                      defaultValue={timeToTravel.toString() || "60"}
                      className="profileselectrole"
                      onChange={handleChange}
                    >
                      <OptGroup label="Select travel time">
                        {/* <Option value="30">30 minutes</Option> */}
                        <Option value="60">60 minutes</Option>
                        <Option value="120">120 minutes</Option>
                      </OptGroup>
                    </Select>
                  </div>
                </div>
                <DatePicker
                  setDatesData={setDatesData}
                  isAvailablity={true}
                  style={{ padding: "5px!important" }}
                  isMobile={isMobile}
                />
                <ScrollWrapper
                  className="list-dates"
                  height="calc(58vh - 200px)"
                  minWidth="100%!important"
                >
                  <div className="m-4 common-row">
                    {datesData?.listingDates
                      ?.filter((dateItem) =>
                        moment(dateItem).add(1, "day").isSameOrAfter(moment())
                      )
                      .map((item, index) => {
                        const isAddEditDisabled =
                          moment(item).format("DD/MM/YYYY") ===
                          moment().format("DD/MM/YYYY");
                        const disabledStyles = {
                          opacity: "0.6",
                          cursor: "not-allowed",
                        };
                        return (
                          <div key={index}>
                            <Title level={5} style={{ display: "flex" }}>
                              {moment(item).format("dddd MMMM DD")}
                            </Title>
                            <hr style={{ opacity: 0.2 }} />
                            {selectedDateData.map((days, index) => {
                              if (moment(item).format("DD MMMM") === days.day) {
                                return (
                                  <div
                                    className="d-flex justify-content-between"
                                    key={
                                      JSON.stringify(moment(days.startTime)) +
                                      index
                                    }
                                    style={{ marginBottom: "8px" }}
                                  >
                                    <div className="date-time-div-avail">
                                      <Text
                                        style={{
                                          color: days.isOverNight
                                            ? "#364BC6"
                                            : "#00B383",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {moment(days.startTime)
                                          .utc()
                                          .format("hh:mm A")}
                                      </Text>{" "}
                                      -{" "}
                                      <Text
                                        style={{
                                          color: days.isOverNight
                                            ? "#364BC6"
                                            : "#00B383",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {moment(days.endTime)
                                          .utc()
                                          .format("hh:mm A")}
                                      </Text>
                                      {days.isOverNight && (
                                        <img
                                          className="me-2 ms-2"
                                          style={{ marginTop: "-5px" }}
                                          width={12}
                                          src="/images/icons/moon-over-night.png"
                                          alt="moon-over-night"
                                        />
                                      )}
                                    </div>
                                    <div className="date-time-div-avail-action">
                                      <Image
                                        preview={false}
                                        width={20}
                                        height={20}
                                        src={
                                          "/images/icons/close-circle-icon.png"
                                        }
                                        alt="plus-icon"
                                        onClick={() =>
                                          handleDeleteAvailability(days)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                      {/* <CloseCircleFilled
                            onClick={() => handleDeleteAvailability(days)}
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              // background: "#130F26",
                              color: "#130F26",
                            }}
                            size="large"
                          /> */}
                                      <Image
                                        preview={false}
                                        width={20}
                                        height={20}
                                        src={"/images/icons/edit-icon.png"}
                                        alt="plus-icon"
                                        onClick={() => {
                                          if (!isAddEditDisabled) {
                                            handleEditAvailablity(days);
                                          }
                                        }}
                                        style={{
                                          ...(isAddEditDisabled
                                            ? disabledStyles
                                            : { cursor: "pointer" }),
                                          marginLeft: "10px",
                                        }}
                                      />
                                      {/* <FormOutlined
                            onClick={() => handleEditAvailablity(days)}
                            style={{marginLeft: "10px",   cursor: "pointer" }}
                            size="large"
                          /> */}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                            <div className="d-flex justify-content-between mt-1">
                              <Text
                                color="#64748B"
                                style={{
                                  opacity: 0.7,
                                }}
                              >
                                Add Availability
                              </Text>
                              {/* <PlusSquareOutlined
                    onClick={() => showModal(item, index)}
                    style={{ fontSize: '20px', backgroundColor: '#364BC6',color:'#fff' }}
                  /> */}
                              <Image
                                className="plus-add-icon"
                                preview={false}
                                width={20}
                                height={20}
                                src={"/images/icons/add-icon.png"}
                                alt="plus-icon"
                                style={{
                                  ...(isAddEditDisabled
                                    ? disabledStyles
                                    : { cursor: "pointer" }),
                                  marginLeft: "10px",
                                }}
                                onClick={() => {
                                  if (!isAddEditDisabled) {
                                    showModal(item, index);
                                  }
                                }}
                              />
                            </div>
                            <hr
                              style={{ opacity: 0.2, marginBottom: "20px" }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </ScrollWrapper>
                {!isEdit && (
                  <div className="mt-3 mb-3 text-center">
                    <Button
                      className="get-started-btn"
                      // type="primary"
                      onClick={handleDashboardNavigate}
                      shape="round"
                      disabled={selectedDateData.length == 0}
                      style={{
                        width: "260px",
                        height: "70px",
                        fontStyle: "bold",
                        fontSize: "20px",
                        backgroundColor:
                          selectedDateData.length == 0 ? "#E7E8EA" : "#0F172A",
                        borderRadius: "45px",
                        color: "#fff",
                      }}
                    >
                      Get Started
                      <ArrowRightOutlined
                        type="Right"
                        style={{ color: "#fff" }}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <Modal
              className="availability-modal"
              open={isModalOpen}
              onCancel={handleCancel}
              closeIcon={<CloseCircleOutlined color="#000" />}
              footer={null}
              destroyOnClose
              // width={"25%!important"}
              // height={"50%"}
            >
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <Title
                  className="mt-2 text-center add-avail-h4 ModalTitle"
                  level={4}
                >
                  Add your Availibility
                </Title>
                {/* <CloseCircleOutlined onClick={handleCancel}
                                                     style={{fontSize: "24px", cursor: "pointer"}}/> */}
              </div>

              <ModalBody
                editAvailabilityData={editAvailabilityData}
                selectedDate={selectedDate}
                handleDayClick={handleDayClick}
                overnightAvailability={overnightAvailability}
                allDay={allDay}
                startTimeDisabledHrs={startTimeDisabledHrs}
                handleStartTime={handleStartTime}
                startTime={startTime}
                endTimeDisabledHrs={endTimeDisabledHrs}
                handleEndTime={handleEndTime}
                endTime={endTime}
                handleOvernightAvailability={handleOvernightAvailability}
                minDuration={minDuration}
                handleSaveOfTime={handleSaveOfTime}
                daysActive={daysActive}
                weekDays={weekDays}
                isLoading={isLoading}
              />
            </Modal>
          </div>
        </Col>
      </Row>
      <Modal
        className="playmodal addavailvideo"
        centered
        header={null}
        footer={null}
        open={openTutorial}
        onCancel={handleVideoClose}
        width={919}
        height={410}
        style={{ top: "5%", padding: "0" }}
        closeIcon={<CloseCircleOutlined />}
        closable
      >
        {/* <img className="circlecross" src="/images/icons/crossiconimage.png" alt="tutorial-image"
                     style={{cursor: "pointer", color: "#fff", opacity: "none"}} onClick={handleVideoClose}/> */}
        {Object.keys(viewTutorial).length !== 0 ? (
          <video
            style={{ borderRadius: "10px" }}
            width="100%"
            height="100%"
            ref={vidRef}
            src={viewTutorial.youtubeUrl}
            autoPlay={true}
            controls
          />
        ) : (
          "No Video Available"
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Availability);

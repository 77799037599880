import React, { useEffect, useState } from "react";
import { Steps, Modal, Button, message, Spin } from "antd";

import { connect, useDispatch, useSelector } from "react-redux";
import ProfileDetails from "./profile-details";
import WorkDetails from "./work-details";
import WorkDetailsCompanion from "./work-details/companion";
import AdditionalDetails from "./additional-details";
import BankDetails from "./bank-details";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "./style.less";
import moment from "moment";
import logo1 from "../../../Images/43.jpg";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../redux/alert/alert.action";
import { LOGOUT } from "../../../redux/auth/auth.type";
import { setUser } from "../../../redux/auth/auth.action";
import { GET_CAREGIVER_PROFILE } from "../../../redux/profileDetails/profile.type";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const Index = ({ accessToken }) => {
  const [current, setCurrent] = useState(0);
  const [role] = useState(localStorage.getItem("role"));
  const [, setAdditionalInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [firstModal, setFirstModal] = useState(true);
  const [userType, setUserType] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const token = localStorage.getItem("token");
  const loginData = useSelector((state) => state.loginData?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginData?.accessToken) {
      getUserData();
    }
  }, [loginData?.accessToken]);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const saveProfileData = (values, func) => {
    updateProfile(values, func());
  };

  const saveWorkData = (values) => {
    const newValues = {
      ...values,
      qualificationImage: values.qualificationImage || "",
    };
    updateProfile(newValues, next);
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/get-caregiver-profile`,
        {},
        {
          headers: {
            accessToken: loginData?.accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      );
      //console.log('caregiver profile',response.data)
      if (response.data.status === 200) {
        console.log(response, "caregiverresponse");
        dispatch(
          setUser({
            userDetails: { ...response.data.output },
            accessToken: loginData?.accessToken,
          })
        );
        dispatch({ type: GET_CAREGIVER_PROFILE, payload: response.data });
      }
      return response;
    } catch (error) {
      console.log("caregiver profile", error);
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = (data, func) => {
    setLoading(true);
    console.log("SHRAVAN::: data:::", data);
    console.log("SHRAVAN::: dob:::", data.dob);
    if (data.dob) {
      data.dob = moment(data.dob).format("DD/MM/YYYY");
    }
    console.log("SHRAVAN::: dob:::", data);
    if (accessToken != "") {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/user/update-profile`, data, {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setLoading(false);
            func();
            getUserData();
            // dispatch(setUser({...userDetails, ...data}))
            message.success("Details updated successfully");
            //  func()
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch({ type: LOGOUT });
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          }
        });
    }
  };

  const saveAdditionalData = (values) => {
    // const newObj = {
    //   ...values, referredFrom: values.referredFrom == "Others" ? values.other : values.referredFrom
    // }
    // console.log("Addi D", values, newObj)
    setAdditionalInfo(values);
    updateProfile(values, next());
  };
  console.log("role > ", role, localStorage.getItem("role"));

  const steps = [
    {
      title: "Profile Details",
      content: (
        <ProfileDetails
          next={next}
          prev={prev}
          save={saveProfileData}
          userType={userType}
          setUserType={setUserType}
        />
      ),
    },
    {
      title: "Work Details",
      content:
        localStorage.getItem("role") !== null &&
        localStorage.getItem("role") === "4" ? (
          <WorkDetailsCompanion next={next} prev={prev} save={saveWorkData} />
        ) : (
          <WorkDetails next={next} prev={prev} save={saveWorkData} />
        ),
    },
    {
      title: "Additional",
      content: (
        <AdditionalDetails
          load={loading}
          next={next}
          prev={prev}
          save={saveAdditionalData}
          userType={userType}
        />
      ),
    },
    {
      title: "Bank Details",
      content: (
        <Elements stripe={stripePromise}>
          <BankDetails next={next} prev={prev} />
        </Elements>
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="container p-md-5 profilecont"
      style={{ maxWidth: "1078px", overflow: "hidden", position: "fixed" }}
    >
      <div
        className="mx-auto"
        style={{ maxWidth: "800px", overflow: "hidden" }}
      >
        {isMobile ? (
          ""
        ) : (
          <Steps
            className="mb-0 pb-4"
            current={current}
            labelPlacement="vertical"
            items={items}
          />
        )}
      </div>
      <Spin spinning={loading}>
        <div className="steps-content profile-details">
          {steps[current].content}
        </div>
      </Spin>
      {/* <Modal
        open={firstModal}
        onCancel={() => setFirstModal(false)}
        // closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"355px"}
        height={"200px"}
        centered
       // style={{ top: 150 }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={logo1} width={70} height={70} alt=""></img><br /><br />
          <p>Please enter you legal first and last name in the boxes. Once you input your name it cannot be undone.</p><br />
          <Button htmlType="submit" className="btn-black" style={{ marginLeft:"20%" ,padding:"10%"}}
            shape="round" size="large" onClick={()=>setFirstModal(false)}
          >I understand</Button>
        </div>
      </Modal> */}
      {/* <div className="steps-action">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { blankStarArr } from "../../pages/caregiver/appointment/appointmentDetails";
import AppointmentNavBar from "../../pages/client/appointment/appointmentsNavBar";
import { setAlert } from "../../redux/alert/alert.action";
import { blankStarArr } from "../../utils";
import Sidebar from "../sidebar";
import { useNavigate } from "react-router-dom";
import ActionCreator from "../../redux/Breadcrumb/ActionCreator";
import Footer from "../Footer/Footer";
import { Spin } from "antd";

export default function MyReviews(props) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [ratingsDetails, setRatingsDetails] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [starRating, setStarRating] = useState(blankStarArr);
  const [loading, setLoading] = useState(false);
  const caregiverData = useSelector((state) => state.loginData);
  const navigate = useNavigate();
  const fetchAllReviews = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-review`,
          {
            page: 1,
            pageLimit: 5,
            caregiverId: caregiverData?.userId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            const checkFilledArr = starRating.map((star) => {
              if (star.id <= response.data.output?.averageRating) {
                return { ...star, type: "filled" };
              }
              return { ...star, type: "blank" };
            });
            setRatingsDetails(response.data?.output);
            setStarRating(checkFilledArr);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (err?.response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAllReviews();
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.dashboard.concat({
          title: "Ratings",
          route: `appointment-details`,
        })
      )
    );
  }, []);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="message-main contact-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*            {isMobile ? (<>*/}
      {/*                    <Row>*/}
      {/*                    <Col xs={22} md={22} className="pt-3 mx-3">*/}
      {/*                    <Responsiveheader title="Reviews"/>*/}
      {/*                    </Col> */}
      {/*                    </Row>*/}
      {/*                    <Row>*/}
      {/*                    <Col xs={24} md={24}>*/}
      {/*                    <Spin*/}
      {/*                    spinning={loading}*/}
      {/*                    tip="loading...">*/}
      {/*<div className='review-modal-container caregiver-review-container'>*/}
      {/*<div className='no-rating-container text-center'>*/}
      {/*// <h2 className="mb-2">My Reviews</h2>*/}
      {/*// <h1 className="rating-num mb-3" style={{color: "#364BC6"}}>{ratingsDetails?.averageRating}</h1>*/}
      {/*// <div className="d-flex justify-content-center align-items-center star-wrapper">*/}
      {/*{starRating.map(i => {*/}
      {/*return (*/}
      {/*i.type == "blank" ? <img src="/images/icons/blank-star.svg" className='text-center mx-2' width={25} />*/}
      {/*:*/}
      {/*<img src="/images/icons/filled-star.svg" className='text-center mx-2' width={25} />*/}
      {/*)*/}
      {/*})}*/}
      {/*</div>*/}

      {/*<span className="review-count-msg">Based on {ratingsDetails?.ratingCount} reviews</span>*/}
      {/*{ratingsDetails?.list?.length > 0 ?*/}
      {/*<div className="reviewsListing ms-2" style={{marginRight:"0.5rem"}}>*/}
      {/*{ratingsDetails.list.map(rating => {*/}
      {/*return (*/}
      {/*       <div className="client-wrapper">*/}
      {/*         <div className="client-details d-flex justify-content-between">*/}
      {/*            <p className="client-name">*/}
      {/*//               {rating?.clientName} */}
      {/*//             </p>*/}
      {/*//             <p className="rating-date">{moment(rating?.reviewedAt).format("MMMM DD, YYYY")}</p>*/}
      {/*         </div>*/}
      {/*         <div className="d-flex ratings-star-wrapper"> */}
      {/*                {*/}
      {/*                   [1,2,3,4,5].map(i => {*/}
      {/*                    if(i <= rating?.rating) {*/}
      {/*                      return <img src="/images/icons/filled-star.svg" className='text-center mx-1' width={15} />*/}
      {/*                    }*/}
      {/*                    return <img src="/images/icons/blank-star.svg" className='text-center mx-1' width={15} />*/}
      {/*                   }) */}
      {/*                }*/}
      {/*        </div>*/}
      {/*//         <p className="client-feedback">{rating?.text}</p>*/}
      {/*//        </div>*/}
      {/*// )*/}
      {/*})}*/}
      {/*// </div>*/}
      {/*// :*/}
      {/*// <>*/}
      {/*// <div className="no-reviews-wrapper">*/}
      {/*// <img src="/images/icons/no-rating.svg" className='text-center' />*/}
      {/*// </div>*/}
      {/*// <span className="no-review-txt">No Reviews</span>*/}
      {/*// </>}*/}
      {/*// </div>*/}
      {/*</div>*/}
      {/*</Spin>*/}
      {/*                    </Col>*/}
      {/*                    </Row>*/}
      {/*                    </>) : (*/}
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex profile-details-wrapper">
          <Sidebar />
          <div className="ms-4 w-100 message-inner-row">
            <AppointmentNavBar />
            <div className="px-mob-0" style={{ padding: "15px 25px" }}>
              {/* <div className="d-flex justify-content-between">
                                    <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>
                                    {/* <p className="mt-2 mb-3 profile-detail-title" style={{fontSize: '16px',}}>{moment(bookingDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p> */}
              {/* </div> */}
              <Spin
                spinning={loading}
                // tip="loading..."
              >
                <div className="review-modal-container caregiver-review-container">
                  <div className="no-rating-container text-center">
                    <h2 className="mb-2">My Reviews</h2>
                    <h1
                      className="rating-num mb-3"
                      style={{ color: "#364BC6" }}
                    >
                      {ratingsDetails?.averageRating}
                    </h1>
                    <div className="d-flex justify-content-center align-items-center star-wrapper">
                      {starRating.map((i) => {
                        return i.type == "blank" ? (
                          <img
                            alt={""}
                            src="/images/icons/blank-star.svg"
                            className="text-center mx-2"
                            width={25}
                          />
                        ) : (
                          <img
                            alt={""}
                            src="/images/icons/filled-star.svg"
                            className="text-center mx-2"
                            width={25}
                          />
                        );
                      })}
                    </div>
                    {ratingsDetails?.ratingCount > 1 && (
                      <span className="review-count-msg">
                        Based on {ratingsDetails?.ratingCount} reviews
                      </span>
                    )}
                    {ratingsDetails?.list?.length > 0 ? (
                      <div className="reviewsListing">
                        {ratingsDetails.list.map((rating) => {
                          return (
                            <div className="client-wrapper">
                              <div className="client-details d-flex justify-content-between">
                                <p className="client-name">
                                  {rating?.clientName}
                                </p>
                                <p className="rating-date">
                                  {moment(rating?.reviewedAt).format(
                                    "MMMM DD, YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="d-flex ratings-star-wrapper">
                                {[1, 2, 3, 4, 5].map((i) => {
                                  if (i <= rating?.rating) {
                                    return (
                                      <img
                                        alt={""}
                                        src="/images/icons/filled-star.svg"
                                        className="text-center mx-1"
                                        width={15}
                                      />
                                    );
                                  }
                                  return (
                                    <img
                                      alt={""}
                                      src="/images/icons/blank-star.svg"
                                      className="text-center mx-1"
                                      width={15}
                                    />
                                  );
                                })}
                              </div>
                              <p className="client-feedback">{rating?.text}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <>
                        <div className="no-reviews-wrapper">
                          <img
                            alt={""}
                            src="/images/icons/bird-icon.svg"
                            className="text-center"
                          />
                        </div>
                        <span className="no-review-txt">No Reviews</span>
                      </>
                    )}
                  </div>
                </div>
              </Spin>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*// )}  */}
    </div>
  );
}

import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Slider,
  Spin,
  InputNumber, Typography,
} from "antd";
import logo1 from "../../../../Images/43.jpg";
import {
  CloseCircleOutlined,
  CheckCircleFilled,
  EditFilled,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import CaregiverUpdatedProfile from "./caregiverUpdatedProfile";
import { useDispatch, useSelector } from "react-redux";
import CaregiverProfileAction from "../../../../redux/profileDetails/profile.action";
import axios from "axios";
import { setAlert } from "../../../../redux/alert/alert.action";
import CompanionModals from "./companionModals";
import { useNavigate } from "react-router-dom";
const CaregiverProfile = ({ tabChange }) => {
  const dispatch = useDispatch();
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [isUploaded, setIsUploaded] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [fourthModal, setFourthModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [image, setImage] = useState("");
  const [form] = Form.useForm();
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState([]);
  const [secondModalVal, setSecondModalVal] = useState("");
  const [serviceData, setServiceData] = useState("");
  const [qualificationImage, setQualificationImage] = useState("");
  const [type, setType] = useState(1);
  const navigate = useNavigate();
  const caregiverProfileDetails = useSelector(
    (state) => state?.profileDetails?.getCaregiverDetails?.output
  );
  useEffect(() => {
    dispatch(CaregiverProfileAction(token));
    console.log(caregiverProfileDetails, "details of caregiver");
    // if(caregiverProfileDetails?.userType==4)
    //    setType(2);
    //    console.log(type,"type is")
    fetchServices();
  }, [caregiverProfileDetails?._id, caregiverProfileDetails?.userType]);
  const careGiverExperienceList = [
    "No Experience",
    "1-3 years",
    "4-6 years",
    "7-9 years",
    "10+ years",
  ];
  const marks = {
    18: "CAD 18",
    19: "CAD 19",
    20: "CAD 20",
    21: "CAD 21",
    22: "CAD 22",
    23: "CAD 23",
    24: "CAD 24",
    25: "CAD 25",
  };
  const months = {
    "01": "January",
    "02": "Febrauary",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
  };
  const formatter = (value) => `CAD ${value}`;
  const qualificationsLists = [
    "Occupational Therapy Certification",
    "Personal Support Worker",
    "Agency Induction Training",
    "Social Worker",
    "Registered Nurse",
    "Nursing",
    "University Degree (Health & Social Care)",
  ];
  const handleImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files, "target file is");
      let reader = new FileReader();

      reader.onload = function (e) {
        setImage(e.target.result);
        setIsUploaded(true);
      };
      reader.readAsDataURL(e.target.files[0]);

      if (e.target.files[0]) {
        setLoadingProfile(true);
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("path", "certificate");
        console.log("formData", formData);
        if (token !== "") {
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/user/upload-image`,
              formData,
              {
                headers: {
                  accessToken: token,
                  Authorization:
                    "Basic " +
                    btoa(
                      process.env.REACT_APP_USERNAME +
                        ":" +
                        process.env.REACT_APP_PASSWORD
                    ),
                },
              }
            )
            .then((response) => {
              console.log("upload-image response", response);
              if (response.data.status === 200) {
                setQualificationImage(response.data.output.imageUrl1.location);
                console.log(
                  response.data.output.imageUrl1.location,
                  "response image"
                );
                form.setFieldValue(
                  "qualificationImage",
                  response.data.output.imageUrl1.location
                );
                setLoadingProfile(false);
              }
            })
            .catch((err) => {
              setLoading(false);
              if (
                err?.response?.data?.status >= 400 &&
                err?.response?.data?.status !== 401
              ) {
                dispatch(
                  setAlert({
                    alertType: "error",
                    alertMsg: err?.response?.data?.error?.message,
                    alertOpen: true,
                  })
                );
              }
            });
        }
      }
    }
  };
  const fetchServices = async () => {
    var type = 1;
    console.log("In Fetch Service", type);
    if (caregiverProfileDetails?.userType == 4) {
      type = 2;
    }
    if (token !== "") {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/service/list-services`,
          {
            type: type,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("services", response.data);
          if (response.data.status === 200) {
            setLoading(false);
            console.log(response.data.output.result, "services");
            setServiceData(response.data.output.result);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const updatedProfileDetails = async (values) => {
    console.log("api values", values);
    const newValues = {
      ...values,
      about: {
        ...values.about,
        experience: values.experience1,
      },
    };
    delete newValues.experience1;
    setLoading(true);
    setDisabledSave(true);
    console.log(values, "in update profile details");
    if (token !== "") {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/update-profile`,
          newValues,
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("update profile", response);
          if (response.data.status === 200) {
            setLoading(false);
            dispatch(CaregiverProfileAction(token));
            dispatch(
              setAlert({
                alertType: "success",
                alertMsg: "Details Updated Successfully",
                alertOpen: true,
              })
            );
            //message.success(response.data.message)
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const onSubModel = () => {
    setFirstModal(false);
    setSecondModal(true);
  };
  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value

      // console.log("on change", value)]
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    console.log(hasErrors, "haserrors");
    setDisabledSave(hasErrors);
  };
  const handleSubmitModel2 = (values) => {
    setSecondModal(false);
    setThirdModal(true);
    setSecondModalVal({ ...values, qualificationImage: qualificationImage });
    console.log(secondModalVal, "secondModalVal");
    console.log(values, "handleSubmitModel2");
    console.log(
      caregiverProfileDetails,
      "caregiverProfileDetails in submodel2"
    );
    // updatedProfileDetails(values);
    //  updatedProfileDetails({...values,"qualificationImage":image});
  };
  const handleSubmitModel3 = (values) => {
    console.log(values, "hadlesubmit3");
    console.log(secondModalVal, "secondModalVal");
    updatedProfileDetails({
      ...secondModalVal,
      services: values.services,
      expectedRate: values.expectedHourlyRate,
      userType: 3,
    });
    // "userType": 3
    setThirdModal(false);
    setFourthModal(true);
  };

  return (
    <>
      <CaregiverUpdatedProfile
        careGiverExperienceList={careGiverExperienceList}
        qualificationsLists={qualificationsLists}
        handleImage={handleImage}
        serviceData={serviceData}
        setFirstModal={setFirstModal}
        updatedProfileDetails={updatedProfileDetails}
        setQualificationImage={setQualificationImage}
        qualificationImage={qualificationImage}
        isUploaded={isUploaded}
        setIsUploaded={setIsUploaded}
        months={months}
        loadingProfile={loadingProfile}
        setLoadingProfile={setLoadingProfile}
        tabChange={tabChange}
      />
      <CompanionModals
        thirdModal={thirdModal}
        setThirdModal={setThirdModal}
        setFourthModal={setFourthModal}
        handleSubmitModel3={handleSubmitModel3}
        token={token}
        marks={marks}
      />
      <Modal
        open={firstModal}
        onCancel={() => setFirstModal(false)}
        closable={false}
        footer={null}
        width={"440px"}
        centered
        className="upgradefirstmodal"
        // style={{ top: 150 }}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo1} width={80} height={80} alt="" />
          <br />
          <p style={{
            color:"#0F172A",
            fontSize:'18px',
            lineHeight:'33px',
            fontWeight:400
          }}>
            Once you change your role from Companion to Caregiver, it cannot be undone.
          </p>
          <br />
          <Button
            htmlType="submit"
            className="btn-black i-understand-btn"
            shape="round"
            size="large"
            onClick={onSubModel}
            sx={{
              width:{
                xs:'100%',
                sm:'235px',
                md:'235px',
                lg:'235px'
              }
            }}
          >
            I understand
          </Button>
        </div>
      </Modal>

      <Modal
        open={secondModal}
        onCancel={() => {
          setSecondModal(false);
          setThirdModal(false);
        }}
        closable={false}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"420px"}
        centered
      >
        <div className="companionmodaltwo" style={{ fontWeight: 550 }}>
          <h2 className="text-center">Upgrade to Caregiver</h2>
          <Form
            layout="vertical"
            onFieldsChange={handleFormChange}
            onFinish={handleSubmitModel2}
            form={form}
          >
            <Form.Item
              label="How many years of experience do you have as a Caregiver?"
              name={"experience"}
              // rules={[{ required: true }]}
            >
              <Select
                size="Medium"
                mode="single"
                showArrow
                showSearch
                style={{
                  width: "100% !important",
                }}
                className="upgradeselect"
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select"
                value={experience}
                onChange={(val) => {
                  console.log(val);
                  form.setFieldValue("experience", val);
                  setExperience(val);
                }}
              >
                {careGiverExperienceList.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      <div className="demo-option-label-item">{item}</div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Do you have any relevant qualifications?"
              name="qualification"
              // rules={[{ required: true }]}
            >
              <Select
                size="la"
                mode="multiple"
                showSearch
                showArrow
                className="upgradeselect"
                style={{
                  width: "100%",
                }}
                menuItemSelectedIcon={<CheckCircleFilled />}
                placeholder="Select"
                value={qualification}
                onChange={(val) => {
                  console.log(val);
                  form.setFieldValue("qualification", val);
                  setQualification(val);
                }}
              >
                {qualificationsLists.map((item, index) => {
                  return (
                    <Option value={item} key={index}>
                      <div className="demo-option-label-item">{item}</div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="qualificationImage" rules={[{ required: true }]}>
              <div className="file-upload h-auto">
                <div className="file-upload-box">
                  <Image src="/images/icons/upload-svg-img.svg" height={88} />
                  <br />
                  {!isUploaded ? (
                    <>
                      {/* <label type='file' className="upload-file-field pointer" placeholder="Upload file" */}
                      {/* style={{ marginTop: "320px", width: "80%" }}> */}
                      {/* form.setFieldValue('qualificationImage', val); */}

                      <input
                        id="file"
                        onChange={(val) => {
                          handleImage(val);
                        }}
                        className="btn-3"
                        accept="image/*"
                        type="file"
                        style={{
                          marginLeft: "120px",
                          marginTop: "40px",
                          display: "none",
                        }}
                      />
                      <label
                        for="file"
                        style={{
                          padding: "15px",
                          backgroundColor: "#364BC6",
                          borderRadius: "25px",
                          marginTop: "30px",
                          color: "white",
                          display:'inline-block',
                          width:'165px'
                        }}
                      >
                        Upload file
                      </label>

                      {/* <img src="/images/icons/upload-icon.svg" alt="" style={{ marginLeft: "250px"}} /> */}
                      {/* </label> */}
                    </>
                  ) : (
                    <>
                      <EditFilled
                        alt=""
                        onClick={() => [setIsUploaded(false), setImage(null)]}
                        className="close-icon"
                      />
                      {/* <img src="/images/icons/closeIcon.svg" alt='' onClick={() => [setIsUploaded(false), setImage(null)]} className="close-icon" /> */}
                      <img src={image} alt="" className="upload-image" />
                    </>
                  )}
                </div>
              </div>
            </Form.Item>
            <Row>
              {/*<Col span={12} className="text-center">*/}
              {/*  <Button*/}
              {/*    className="btn  mt-3 "*/}
              {/*    onClick={(e) => setSecondModal(false)}*/}
              {/*    style={{*/}
              {/*      width: "80%",*/}
              {/*      height: "70%",*/}
              {/*      backgroundColor: "#E7E8EA",*/}
              {/*      padding: "10px",*/}
              {/*      borderRadius: "30px",*/}
              {/*    }}*/}
              {/*    shape="round"*/}
              {/*    size="large"*/}
              {/*  >*/}
              {/*    Cancel*/}
              {/*  </Button>*/}
              {/*</Col>*/}
              <Col span={12} className="text-center mx-auto">
                <Button
                  htmlType="submit"
                  className="btn-black mt-3 saveButton"
                  style={{
                    width: "90%",
                    height: "70%",
                    backgroundColor: "#E7E8EA",
                    padding: "10px",
                    borderRadius: "30px",
                  }}
                  disabled={disabledSave}
                  shape="round"
                  size="large"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>

      <Modal
        open={fourthModal}
        //onCancel={() => setFourthModal(false)}
        closeIcon={<CloseCircleOutlined color="#000" />}
        footer={null}
        width={"300px"}
        height={"614px"}
        centered
        style={{ top: 150 }}
      >
        <div className="upgrade-caregiver-div w-100">
          <div className="text-center w-100">
            <img
              alt=""
              src="/images/icons/successtick.svg"
              width={80}
              height={90}
            />
            <br />
            <br />
            <p style={{ fontWeight: "bold", fontSize: "17px" }}>
              Upgrade to Caregiver Successfully!
            </p>
            <br className="d-none-mob" />
            <Button
              htmlType="submit"
              className="btn-black auth-action-btn done-btn-success"
              style={{ marginLeft: "40px" }}
              shape="round"
              size="large"
              onClick={(e) => setFourthModal(false)}
            >
              Done
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CaregiverProfile;

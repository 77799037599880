import React, { useState } from "react";
import { Menu, Modal, Button } from "antd";
import logout from "../../Images/logout.svg";
import {
  ContainerOutlined,
  DesktopOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import "./sidebar.less";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import { CLEAR_DATA } from "../../redux/auth/auth.type";
import { Logout } from "../../redux/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import LogoutModal from "../navbar/LogoutModal";
import {
  sideBarActive,
  sideBaropen,
} from "../../redux/modals/toggleModal.actions";

function Sidebar() {
  const navigate = useNavigate();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const collapsed = useSelector((state) => state.toggleModals?.toggle);
  const activeKeys = useSelector(
    (state) => state.toggleModals?.sidebarActiveKey
  );
  const unreadMsgCount = useSelector(
    (state) => state.loginData?.unreadMsgCount
  );
  console.log(unreadMsgCount, "unreadMsgCount");
  // const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();
  const [handleLogoutModal, setHandleLogoutModal] = useState(false);
  const logindata = useSelector((state) => state.loginData);
  const toggleCollapsed = (value) => {
    if (value) {
      dispatch(sideBaropen(!collapsed));

      // setCollapsed(!collapsed);
    }
  };
  const handleAppointmentsNavigation = () => {
    // setCollapsed(true)
    if (userMode == "client") {
      if (!logindata?.homeData?.isFirstAppointmentCreated) {
        navigate("/client/appointment");
      } else {
        navigate("/client/appointments-dashboard");
      }
      // navigate("/client/appointments-dashboard");
    } else {
      navigate("/caregiver/appointments-dashboard");
    }
  };

  const handleDashboardNavigation = () => {
    // setCollapsed(true)
    if (userMode == "client") {
      navigate("/client/appointments-dashboard");
    } else {
      navigate("/caregiver/dashboard");
    }
  };

  const handleMessagesNavigation = () => {
    // setCollapsed(true)
    if (userMode == "client") {
      navigate("/client/messages");
    } else {
      navigate("/caregiver/messages");
    }
  };

  const handleExpensesNavigation = () => {
    // setCollapsed(true)
    if (userMode == "client") {
      navigate("/client/expenses");
    } else {
      navigate("/caregiver/expenses");
    }
  };

  return (
    <div
      className={`sidebar sidebar-custom ${collapsed ? "collapsed-class" : ""}`}
      style={{ width: !collapsed ? "250px" : "73px", }}
    >
      <div className="sidebar-toggler" onClick={() => toggleCollapsed("i")}>
        <img
          style={{
            transform: !collapsed ? "rotate(180deg)" : "",
            position: "relative",
            top: "-2px",
          }}
          src="/images/icons/arrow-right.svg"
          alt="right arrow"
        />
      </div>
      <div className="w-100 common-div-sidebar">
        <div className="p-2 mt-2 mb-5">
        {collapsed ? (
          <img width="60" src="/images/logo-icon.svg" alt="logo" />
        ) : (
          <img
            src="/images/icons/sidebar-logo.svg"
            alt="logo"
            className="sidebar-logo"
          />
        )}
      </div>
      <div className="w-100 menu-list-div">
        <Menu
          className="ant-menu-dark"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          onClick={({ key }) => dispatch(sideBarActive([key]))}
          selectedKeys={activeKeys}
          theme="dark"
          inlineCollapsed={collapsed}
          style={{ background: "none" }}
        >
          {userMode == "caregiver" && (
            <Menu.Item
              title=""
              key="1"
              style={{ height: "3rem" }}
              onClick={handleDashboardNavigation}
            >
              <img
                className={collapsed ? "me-5" : "me-3"}
                src="/images/menu/dashboard-nav.svg"
                alt="dashboard"
              />
              <span>Dashboard</span>
            </Menu.Item>
          )}
          <Menu.Item
            title=""
            key="2"
            style={{ height: "3rem" }}
            onClick={handleAppointmentsNavigation}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/dashboard.svg"
              alt="dashboard"
            />
            <span>Appointments</span>
          </Menu.Item>
          <Menu.Item
            title=""
            key="3"
            style={{ height: "3rem" }}
            onClick={handleMessagesNavigation}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/message.svg"
              alt="messages"
            />
            <span>Messages</span>
            {unreadMsgCount > 0 && (
              <span
                style={{
                  left: "15px",
                  borderRadius: "50%",
                  display: "block",
                  width: "10px",
                  position: "relative",
                  bottom: "30px",
                  background: "red",
                  padding: "5px",
                }}
              ></span>
            )}
          </Menu.Item>
          <Menu.Item
            title=""
            key="4"
            style={{ height: "3rem" }}
            onClick={handleExpensesNavigation}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/Wallet.svg"
              alt="expenses"
            />
            <span>Expenses</span>
          </Menu.Item>
          {userMode == "caregiver" ? (
            <Menu.Item
              title=""
              key="5"
              style={{ height: "3rem" }}
              onClick={() => navigate("/caregiver/earnings")}
            >
              <img
                className={collapsed ? "me-5" : "me-3"}
                src="/images/menu/WalletIcon.svg"
                alt="expenses"
              />
              <span>Total Earnings</span>
            </Menu.Item>
          ) : null}
          <Menu.Item
            title=""
            key="6"
            style={{ height: "3rem" }}
            onClick={() => {
              if (userMode == "caregiver") {
                navigate("/caregiver/settings");
              } else {
                navigate("/client/settings");
              }
            }}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/Setting.svg"
              alt="settings"
            />
            <span>Settings</span>
          </Menu.Item>
          <Menu.Item
            title=""
            key="7"
            style={{ height: "3rem" }}
            onClick={() => {
              if (userMode == "caregiver") {
                navigate("/caregiver/view-tutorials");
              } else {
                navigate("/client/view-tutorials");
              }
            }}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/Play.svg"
              alt="play"
            />
            <span>View Tutorials</span>
          </Menu.Item>
          <Menu.Item
            key="8"
            title=""
            style={{ height: "3rem" }}
            onClick={() => {
              if (userMode == "caregiver") {
                navigate("/caregiver/contactus");
              } else {
                navigate("/client/contactus");
              }
            }}
          >
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/email.svg"
              alt="contact"
            />
            <span>Contact Us</span>
          </Menu.Item>
        </Menu>
      </div>
      </div>
      <div>
        <Menu className="ant-menu-dark"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          onClick={({ key }) => dispatch(sideBarActive([key]))}
          selectedKeys={activeKeys}
          theme="dark"
          inlineCollapsed={collapsed}
          style={{ background: "none" }}>
          <Menu.Item className={userMode == "caregiver" ? "logout-caregiver" : "logout-client"} title="" onClick={() => setHandleLogoutModal(true)}>
            <img
              className={collapsed ? "me-5" : "me-3"}
              src="/images/menu/Logout.svg"
              alt="logout"
            />
            <span>Logout</span>
          </Menu.Item>
        </Menu>
      </div>
      <LogoutModal
        handleLogoutModal={handleLogoutModal}
        setHandleLogoutModal={setHandleLogoutModal}
      />
    </div>
  );
}

export default Sidebar;

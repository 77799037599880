
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;
const ExpenseReject = (props) => {
    const { rejectpopup, handleUnsuccessModalClose, ishandleRejected, key } =
      props;
    return (
      <Modal
        className="info-modal-container"
        centered
        width={390}
        header={null}
        footer={null}
        open={rejectpopup}
        // onOk={ishandleRejected}
        onCancel={handleUnsuccessModalClose}
        closable={false}
      >
        <div className="image-container">
          <img
            src="/images/icons/payment-unsuccessful.svg"
            className="text-center"
          />
        </div>
        <h3 className="mt-3 fw-semibold expense_popup_text text-center">
          Are you sure you want to reject this expense?
        </h3>
  
        <Row gutter={24}>
          <Col span={10}>
            <Button
              // htmlType="submit"
              onClick={() => ishandleRejected(key)}
              className=" mx-auto  expense_reject_Okay"
              shape="round"
              type="primary"
              size="large"
            >
              Yes
            </Button>
          </Col>
          <Col span={14}>
            <Button
              // htmlType="submit"
              onClick={handleUnsuccessModalClose}
              className="btn-black mx-auto expense_reject_No"
              shape="round"
              type="primary"
              size="large"
            >
              No
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };

   export default ExpenseReject
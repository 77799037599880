import { GET_BANK_DETAILS, GET_CAREGIVER_PROFILE, GET_CLIENT_PROFILE, SAVE_ALL_NOTIFICATIONS } from "./profile.type"


const initialState = {
    getCaregiverDetails:[],
    getClientDetails:[],
    getBankDetails:[],
    allNotifications: []
}

 const caregiverProfileReducer = (state = initialState, action) => {
    //console.log(initialState,action)
    switch (action.type) {
        
        case GET_CAREGIVER_PROFILE:
            //console.log(action.payload)
            return {
                ...state,
                getCaregiverDetails: action.payload
            }
            case GET_CLIENT_PROFILE:
                //console.log(action.payload)
                return {
                    ...state,
                    getClientDetails: action.payload
                }
                case GET_BANK_DETAILS:
                    //console.log(action.payload)
                    return {
                        ...state,
                        getBankDetails: action.payload
                    }
        case SAVE_ALL_NOTIFICATIONS:
            return{
                ...state,
                allNotifications: action.payload
            }
        default:
                return state;
    }
}
export default caregiverProfileReducer
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import { SendOutlined } from "@ant-design/icons";
import * as io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import "./messages.less";
import moment from "moment";
import { useRef } from "react";
import Footer from "../../../components/Footer/Footer";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import axios from "axios";
import commonApi from "../../../http/commonApi";

export default function CaregiverMessage() {
  const userData = useSelector((state) => state.loginData?.user);
  const clientData = useSelector((state) => state.appointment?.paymentDetails);
  const date = new Date();
  const showTime = date.getHours() + ":" + date.getMinutes();
  const { firstName, userId } = userData;
  const [filteredList, setFilteredList] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [messagesLoader, setMessageLoader] = useState(false);
  // const [socketConnection, setSocketConnection] = useState(false);
  const [redDotVisible, setRedDotVisible] = useState(false);
  const [bottomVisible, setBottomVisible] = useState(false);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("token");
  const messageEl = useRef();
  // const socket = io(
  //   `https://mf-chat.approd.ca/?accessToken=${accessToken}`
  // );
  const isSocketConnected = useSelector(
    (state) => state.socketData?.socketConnection
  );
  const socket = useSelector((state) => state.socketData?.socket);
  const [currentConversation, setCurrentConversation] = useState(null);
  const appointmentData = useSelector((state) => state.appointment);
  console.log(appointmentData, "");
  const clientDataTwilio = useSelector(
    (state) => state?.twillioData?.twillioClient
  );
  const clientConnection = useSelector((state) => state.twillioConnection);
  const [twillioMsgList, setTwillioMessageList] = useState([]);
  const [isNewMessageAdded, setIsNewMessageAdded] = useState(false);
  const userMode = useSelector((state) => state.loginData?.userMode);
  console.log(currentConversation, "currentConversation");
  //Send Message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (typedMessage) {
      // console.log({
      //   message: typedMessage,
      //   senderId: userId,
      //   receiverId: activeUser?.receiverData?._id,
      // });
      currentConversation?.sendMessage(typedMessage);
      // socket.emit("sendMessage", {
      //   message: typedMessage,
      //   senderId: userId,
      //   receiverId: clientData?.clientId,
      // });
      // socket.on("messageFromServer", (messageFromServer) => {
      //   console.log([messageFromServer], "messageFromServer");
      // });
      // setMessages(prev => [...prev, {message: typedMessage, senderId: userId,
      //   receiverId: clientData?.clientId}])
      setTypedMessage("");
      const payload = {
        conversationId: currentConversation.sid,
        receiverId: clientData?.clientId,
      };
      const pushNotificationResp = await commonApi._sendMessageNotification(
        payload
      );
      console.log(pushNotificationResp, "pushNotificationResp");
    }
  };
  console.log(clientData, "clientData");

  useEffect(() => {
    if (twillioMsgList?.length > 0 && !isNewMessageAdded) {
      const lastMessageId = twillioMsgList[twillioMsgList.length - 1]?.sid;
      const lastMsgElem = document.getElementById(lastMessageId);
      if (lastMsgElem) {
        // setTimeout(() => {
        lastMsgElem?.scrollIntoView({
          top: lastMsgElem?.offsetTop,
          behavior: "smooth",
        });
        // }, 1000);
        setRedDotVisible(false);
      }
    }
  }, [twillioMsgList?.length]);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      clientData?.clientId
    ) {
      debugger;
      let sid;
      const url = `${process.env.REACT_APP_BASE_URL}/user/get-twilio-conversation`;
      axios
        .post(
          url,
          {
            receiverId: clientData?.clientId,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response.data, "status");
            sid = response.data.output.conversationId;
            if (sid) {
              console.log(sid, "sdfdsfsdf");
              clientDataTwilio
                ?.getConversationBySid(`${sid}`)
                .then((conversations) => {
                  debugger;
                  setCurrentConversation(conversations);
                  conversations
                    .getMessages(1000)
                    .then(async (messagesList) => {
                      setTwillioMessageList(messagesList?.items);
                      console.log(messagesList.items, "messagesListnew");
                    })
                    .catch((Err) => {
                      console.log(Err);
                    });
                });
              // conversations = resp;
            }
          }
        });

      // const conversations = clientDataTwilio?.getConversationByUniqueName(
      //   `${clientData?.clientId} - ${userId}`
      // );
      // conversations
      //   .then((Resp) => {
      //     sid = Resp.sid;
      //     setCurrentConversation(Resp);
      //     Resp.getMessages(100)
      //       .then(async (messagesList) => {
      //         setTwillioMessageList(messagesList?.items);
      //         if (messageEl) {
      //           messageEl.current?.addEventListener(
      //             "DOMNodeInserted",
      //             (event) => {
      //               const { currentTarget: target } = event;
      //               target.scroll({
      //                 top: target.scrollHeight,
      //                 behavior: "smooth",
      //               });
      //               setRedDotVisible(false);
      //             }
      //           );
      //         }
      //         await currentConversation.setAllMessagesRead();
      //         console.log(messagesList.items, "messagesListnew");
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //     console.log(Resp, "response");
      //     // if (!sid) {
      //     //   clientDataTwilio
      //     //     ?.createConversation({
      //     //       uniqueName: appointmentData?.paymentDetails?._id,
      //     //     })
      //     //     .then((resp) => {
      //     //       debugger;
      //     //       console.log("dsfdsf", resp);
      //     //       resp.join().then((respnew) => {
      //     //         resp.participants
      //     //           .create({
      //     //             identity:
      //     //               userMode == "caregiver" ? userId : clientData?.clientId,
      //     //           })
      //     //           .then((participant) => {
      //     //             console.log("Participant SID:", participant.sid);
      //     //             console.log("Participant Identity:", participant.identity);
      //     //           });
      //     //         console.log(respnew, "respnew");
      //     //       });
      //     //       setCurrentConversation(resp);
      //     //     });
      //     // }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // if (!sid) {
      //   clientDataTwilio
      //     ?.createConversation({
      //       uniqueName: `${clientData?.clientId} - ${userId}`,
      //       // `${Math.random()}`,
      //       // `${userId} - ${caregiverData?.caregiverId}4`,
      //     })
      //     .then((resp) => {
      //       resp.setAttributes({
      //         clientData: {
      //           name: clientData?.clientName,
      //           profilePic: "",
      //           userId: clientData.clientId,
      //         },
      //         caregiverData: {
      //           name: `${userData?.firstName} ${userData?.lastName || ""}`,
      //           profilePic: userData.userDetails?.image,
      //           userId: userData.userId,
      //         },
      //       });
      //       resp.join().then((respnew) => {
      //         console.log(respnew, "respnewnew");
      //         resp
      //           .add(userMode == "caregiver" ? userId : clientData?.clientId)
      //           .then((participant) => {
      //             console.log("Participant SID:", participant.sid);
      //             console.log("Participant Identity:", participant.identity);
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //           });
      //         console.log(respnew, "respnew");
      //       });
      //       setCurrentConversation(resp);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    }
  }, [clientDataTwilio, clientConnection, clientData?.clientId, messageEl]);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      currentConversation
    ) {
      currentConversation.on("messageAdded", (newMessage) => {
        // const messages = [...twillioMsgList];
        // messages.push(newMessage);
        // const twillioMsgListNew = twillioMsgList.find(
        //   (i) => i.sid == newMessage.sid
        // );
        if (newMessage.author !== userId) {
          setIsNewMessageAdded(true);
        } else {
          setIsNewMessageAdded(false);
        }
        setTwillioMessageList((prev) => {
          const foundMessage = prev.find((i) => i.sid == newMessage?.sid);
          if (!foundMessage) {
            setRedDotVisible(true);
            return [...prev, newMessage];
          }
          return prev;
        });
        console.log(newMessage, "responsedatasdff");
      });
    }
  }, [clientDataTwilio, clientConnection, currentConversation]);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      currentConversation &&
      twillioMsgList?.length > 0
    ) {
      currentConversation
        .setAllMessagesRead(twillioMsgList?.length - 1)
        .then((resp) => console.log("successss", resp));
    }
  }, [twillioMsgList, currentConversation, clientDataTwilio]);
  // useEffect(() => {
  //   if (isSocketConnected) {
  //     socket.on("messageFromServer", (messageFromServer) => {
  //       const msgData = { ...messageFromServer?.getChatData };
  //       // debugger
  //       console.log(msgData, messages, "msgData");
  //       // const newMsgData = {msgData};
  //       // if(msgData?.receiverId._id == userId) {
  //       //   // msgData['senderId'] = msgData?.receiverId;
  //       //   // msgData['receiverId'] = msgData?.senderId;
  //       //   msgData['senderData'] = msgData?.receiverId;
  //       //   msgData['receiverData'] = msgData?.senderId;
  //       //   msgData['receiverId'] = msgData?.senderId?._id
  //       //   msgData['senderId'] = msgData?.receiverId?._id
  //       // }
  //       // else {
  //       msgData["senderData"] = msgData?.senderId;
  //       msgData["receiverData"] = msgData?.receiverId;
  //       msgData["receiverId"] = msgData?.receiverId?._id;
  //       msgData["senderId"] = msgData?.senderId?._id;
  //       // }
  //       const msgList = [...messages];
  //       msgList.push(msgData);
  //       // console.log(messages, "sdsd")
  //       // console.log(messages[messages.length - 1]?._id, msgData?._id, "msgDatadata")
  //       // if(messages[messages.length - 1]?._id != undefined && messages[messages.length - 1]?._id != msgData?._id) {
  //       setMessages(msgList);
  //       // }
  //       if (msgData?.senderId != userId) {
  //         setRedDotVisible(true);
  //       }
  //       console.log(messageFromServer, "messageFromServer");
  //     });
  //   }
  // }, [socket?.connected, messages?.length]);

  // useEffect(() => {
  //   if (socket && isSocketConnected && currentChatPage == 1) {
  //     // if(!socketConnection) {
  //     //     socket.on("connect", () => {
  //     //     setSocketConnection(socket.connected);
  //     //     console.log("user connected")
  //     // });
  //     // }
  //     // else {
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: clientData?.clientId,
  //       pageNo: 1,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       console.log(data, "datadata");
  //       setTotalCount(data?.totalCount);
  //       setMessages(data?.chatHistory?.slice().reverse());
  //     });
  //     if (messageEl) {
  //       messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
  //         const { currentTarget: target } = event;
  //         target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //       });
  //     }
  //   }
  // }, [socket?.connected]);

  // useEffect(() => {
  //   if (socket?.connected && currentChatPage != 1) {
  //     setMessageLoader(true);
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: clientData?.clientId,
  //       pageNo: currentChatPage,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       // debugger
  //       console.log(data, "datadata");
  //       const newArr = data.chatHistory.slice().reverse();
  //       const updatedArr = [...newArr, ...messages];
  //       setMessages(updatedArr);
  //       setMessageLoader(false);
  //       // setTotalCount(data?.totalCount)
  //     });
  //   }
  // }, [currentChatPage]);

  const handleScroll = (e) => {
    const flag =
      e?.target.scrollHeight !=
      Math.round(e.target?.scrollTop + e.target?.clientHeight);
    setBottomVisible(flag);
    if (messageEl.current) {
      // const { scrollHeight } = messageEl.current;
      if (e.target.scrollTop == 0 && messages.length != totalCount) {
        setCurrentChatPage((prev) => prev + 1);
      }
    }
  };

  const handleScrollToBottom = (e) => {
    setCurrentChatPage(1);
    setRedDotVisible(false);
    const scroll =
      messageEl.current?.scrollHeight - messageEl.current?.clientHeight;
    messageEl.current?.scrollTo({ top: scroll, behavior: "smooth" });
  };

  console.log("careferferfr", messages);
  return (
    <div style={{ background: "#F7F9FA", minHeight: "100vH" }}>
      <div className="container-fluid appointment-dashboard message-container">
        <div className="pt-5 d-flex profile-details-wrapper">
          <Sidebar />
          <div className="mx-4 main-content w-100">
            <AppointmentNavBar />
            <div
              className="border-less message-card rounded"
              style={{ background: "#fff", padding: "35px" }}
            >
              <Row className="message-wrapper">
                <Col className="right-client-child">
                  <>
                    <div
                      className=""
                      style={{
                        padding: "20px 10px 0 10px",
                      }}
                    >
                      <div className="profile-details d-flex align-items-center">
                        {clientData?.clientImage ? (
                          <img
                            alt=""
                            src={clientData?.clientImage}
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%" }}
                            className="br-25 caregiver-img ms-2"
                          />
                        ) : (
                          <img
                            alt=""
                            src="/images/icons/Profile.png"
                            className="caregiver-img"
                            width={50}
                            height={50}
                            style={{
                              border: "1px solid #c4c2c2",
                              padding: "3px",
                              borderRadius: "25px",
                              marginLeft: "10px",
                            }}
                          />
                        )}
                        <p className="active-chat">{clientData?.clientName}</p>
                      </div>
                      <Divider />
                      <Spin spinning={messagesLoader}>
                        <div className="chat-box-wrapper">
                          <Row
                            className="chat__container2"
                            ref={messageEl}
                            onScroll={handleScroll}
                          >
                            {twillioMsgList.length == 0 ? (
                              <Col
                                md={24}
                                xs={24}
                                className="d-flex  justify-content-center align-items-center"
                              >
                                <div className="no-msg-wrapper">
                                  <img
                                    src="/images/icons/no-messages.svg"
                                    alt="no messages"
                                    width={120}
                                  />
                                  <p className="no-msg-txt">No Messages</p>
                                </div>
                              </Col>
                            ) : (
                              twillioMsgList?.map((message, index) => {
                                const currentDate =
                                  moment().format("DD-MM-YYYY");
                                const isNotSameDate =
                                  moment(message?.dateUpdated).format(
                                    "DD-MM-YYYY"
                                  ) !=
                                  moment(
                                    twillioMsgList[index - 1]?.dateUpdated
                                  ).format("DD-MM-YYYY");
                                const isSameAsToday =
                                  moment(message?.dateUpdated).format(
                                    "DD-MM-YYYY"
                                  ) == currentDate;
                                const updatedTime = moment(message?.dateUpdated)
                                  // .utc()
                                  .format("hh:mm a");
                                const isNotSameTime =
                                  updatedTime !=
                                  moment(twillioMsgList[index - 1]?.dateUpdated)
                                    // .utc()
                                    .format("hh:mm a");
                                return (
                                  <>
                                    {isNotSameDate && (
                                      <Col md={24}>
                                        <p
                                          style={{
                                            color: "black",
                                            textAlign: "center",
                                          }}
                                        >
                                          {isSameAsToday
                                            ? "Today"
                                            : moment(
                                                message?.dateUpdated
                                              ).format("DD-MM-YYYY")}
                                        </p>
                                      </Col>
                                    )}
                                    <Col
                                      md={24}
                                      className={`message__chats ${
                                        userId == message.author
                                          ? "right"
                                          : "left"
                                      }`}
                                      key={message.sid}
                                      id={message.sid}
                                    >
                                      <p>{message?.body}</p>
                                    </Col>
                                    {isNotSameTime && (
                                      <span
                                        className={`timestamp ${
                                          userId == message.author
                                            ? "right"
                                            : "left"
                                        }`}
                                      >
                                        {updatedTime}
                                      </span>
                                    )}
                                  </>
                                );
                              })
                            )}
                          </Row>
                          {bottomVisible && (
                            <div
                              className="bottom-scroll"
                              style={{ float: "right" }}
                            >
                              <Button
                                onClick={handleScrollToBottom}
                                style={{ height: "50px" }}
                              >
                                {redDotVisible ? (
                                  <img
                                    alt=""
                                    src="/images/icons/message-down-red.svg"
                                    width="30"
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    src="/images/icons/message-down.svg"
                                    width="30"
                                  />
                                )}
                              </Button>
                            </div>
                          )}
                          {/* {activeUser && ( */}
                          <div className="chat-input-message">
                            <Input
                              className="inputMessage"
                              placeholder="Type Message..."
                              value={typedMessage}
                              onChange={(e) => setTypedMessage(e.target.value)}
                              onKeyDown={(e) => {
                                console.log("called");
                                // e.preventDefault();
                                if (e.key === "Enter" && typedMessage) {
                                  handleSendMessage(e);
                                }
                              }}
                              suffix={
                                <img
                                  alt=""
                                  src="/images/icons/send-message.svg"
                                  width={40}
                                  height={40}
                                  onClick={handleSendMessage}
                                  style={
                                    typedMessage?.length == 0
                                      ? { opacity: 0.6 }
                                      : {}
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </Spin>
                    </div>
                  </>
                </Col>
              </Row>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

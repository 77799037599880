import React, { useState, useEffect } from "react";
import { Form, Button, Select, Col, Row, Input } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { connect } from "react-redux";
import languages from "../../../../common/helper/languages";
import "./workdetail.style.less";
import { updatedLanguageOptions } from "../../../../utils";

function WorkDetailsCompanion({ save, userDetails }) {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [englishLevel, setEnglishLevel] = useState("");
  const [, setAnimal] = useState("");
  const [, setLanguages] = useState([]);
  const [otherLan, setOtherLan] = useState(false);
  const [otherLanVal, setOtherLanval] = useState("");
  const [langOptions, setLangOptions] = useState([]);
  useEffect(() => {
    if (userDetails !== undefined) {
      const langArr = [...updatedLanguageOptions];
      if (userDetails.languages?.length > 0) {
        userDetails.languages.forEach((lang) => {
          const foundLang = langArr.find(
            (newLang) => newLang.toLowerCase() == lang.toLowerCase()
          );
          if (!foundLang) {
            langArr.push(lang);
          }
        });
      }
      langArr.push("Other Languages");
      setLangOptions(langArr);
      form.setFieldValue(
        "englishRate",
        userDetails?.englishRate !== "" ? userDetails?.englishRate : null
      );
      form.setFieldValue(
        "languages",
        userDetails?.languages !== "" ? userDetails?.languages : null
      );
      form.setFieldValue(
        "animals",
        userDetails?.animals !== "" ? userDetails?.animals : null
      );
      setEnglishLevel(
        userDetails?.englishRate !== "" ? null : userDetails?.englishRate
      );
      setLanguages(
        userDetails?.languages !== "" ? userDetails?.languages : null
      );
      setAnimal(userDetails?.animals !== "" ? userDetails?.animals : null);
      handleFormChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const englishRateData = [
    "Mother Tongue",
    "Fluent",
    "Proficient",
    "Conversational",
    "Basic",
  ];

  const animalData = ["All", "Cats", "Dogs", "None"];
  const handleLanguages = () => {
    const languagesVal = form.getFieldValue("languages");
    if (languagesVal.includes("Other Languages")) {
      console.log("setting language to");
      setOtherLan(true);
    } else {
      setOtherLan(false);
    }
  };
  const onFinish = (values) => {
    {
      var lang = form.getFieldValue("languages");
      const othersplit = otherLanVal.split(",");
      if (otherLanVal) {
        lang = [...lang, ...othersplit];
      }
      const finalArr = lang.filter((val) => val != "Other Languages");
      console.log(finalArr, "finalArr");
      form.setFieldValue("languages", finalArr);
      console.log(values, "final values");
      values = { ...values, languages: finalArr };
      console.log(values, "final values");
    }
    console.log("values", values);
    save(values);
  };

  return (
    <div
      className="px-3 mx-auto companion-details"
      style={{ maxWidth: "47.5vw" }}
    >
      <h1 className="color-primary fw-bolder mt-5">Work Details</h1>
      <div className="text-start work-details-container">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          // validateTrigger='onSubmit'
          onFieldsChange={handleFormChange}
          initialValues={{}}
        >
          <Row gutter={16}>
            <Col xs={24} lg={12} className="">
              <Form.Item
                name="englishRate"
                label={
                  <p className="color-secondary mb-0 fs-6">
                    1. How would you rate your spoken english?
                  </p>
                }
                rules={[{ required: true }]}
              >
                <Select
                  suffixIcon={
                    <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                  }
                  className="profileselectrole"
                  size="large"
                  value={englishLevel}
                  onChange={(val) => {
                    setEnglishLevel(val);
                    form.setFieldValue("englishRate", val);
                  }}
                  placeholder="Select your preference"
                >
                  {englishRateData?.map((op, i) => {
                    return (
                      <Select.Option value={op} index={i}>
                        {op}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="">
              <Form.Item
                name="languages"
                label={
                  <p className="color-secondary mb-0 fs-6">
                    2. What other languages do you speak? (Optional)
                  </p>
                }
                //  rules={[{ required: true }]}
              >
                <Select
                  size="large"
                  mode="multiple"
                  showArrow
                  className="profileselect"
                  style={{
                    width: "100%",
                  }}
                  suffixIcon={
                    <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                  }
                  onChange={(val) => {
                    setLanguages(val);
                    handleLanguages();
                    form.setFieldValue("languages", val);
                  }}
                  menuItemSelectedIcon={<CheckCircleFilled />}
                  placeholder="Select your preference"
                >
                  {langOptions?.map((item) => {
                    return (
                      <Option value={item}>
                        <div className="demo-option-label-item">{item}</div>
                      </Option>
                    );
                  })}
                  {/* {languages?.slice(100,199)?.map((item) => {
                  return (
                    <Option value={item.name}>
                      <div className="demo-option-label-item">{item.name}</div>
                    </Option>
                  );
                })} */}
                </Select>
              </Form.Item>
              {otherLan && (
                <Form.Item
                  name="other"
                  className="other-language-input"
                  rules={[
                    {
                      pattern: new RegExp(/^[A-Za-z]+$/),
                      message: "Add only alphabets",
                    },
                    { required: true, message: "Other Language is Required" },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Other"
                    style={{
                      width: "97%",
                      marginTop: "10px",
                      marginLeft: "10px",
                      height: "40px",
                      top: "-15px",
                    }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setOtherLanval(e.target.value);
                    }}
                  />
                </Form.Item>
              )}
            </Col>
            <Col xs={24} lg={24} className="">
              <Form.Item
                name="animals"
                label={
                  <p className="color-secondary mb-0 fs-6">
                    3. Which pets are you comfortable being around?
                  </p>
                }
                rules={[{ required: true }]}
              >
                <Select
                  suffixIcon={
                    <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                  }
                  className="profileselectrole"
                  onChange={(val) => {
                    setAnimal(val);
                    form.setFieldValue("animals", val);
                  }}
                  size="large"
                  placeholder="Select your preference"
                >
                  {animalData?.map((op, i) => {
                    return <Select.Option value={op}>{op}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="text-center mb-2">
            <Button
              className="btn-black auth-action-btn mt-3 mb-5 mx-auto"
              shape="round"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabledSave}
            >
              Next{" "}
              <img
                className="ms-2"
                src="/images/icons/right.svg"
                alt="right icon"
              />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkDetailsCompanion);

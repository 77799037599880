const pushBreadcrumb = (payload) => {
  console.log(payload, "payload");
  return {
    type: "PUSH_BREADCRUMB",
    payload,
  };
};
const popBreadcrumb = () => ({ type: "PUSH_BREADCRUMB" });

const BreadcrumbItems = {
  Appointments: [{ title: "Appointments", route: "appointments-dashboard" }],
  messages: [{ title: "Messages", route: "Messages" }],

  expenses: [{ title: "Expenses", route: "Expenses" }],
  settings: [{ title: "Settings", route: "" }],
  earnings: [{ title: "Total Earnings", route: "" }],
  dashboard: [{ title: "Dashboard", route: "dashboard" }],
  relatives: [{ title: "My Care Recipients", route: `relatives` }],

  caregivers: [
    {
      title: "My Caregivers/Companions",
      code: "dashboard/my-caregivers",
    },
  ],
  newAppoinment: [
    { title: "Appointments", route: "appointments-dashboard" },
    { title: "New Appointment", route: "" },
  ],
  Schedule: [{ title: "My Schedule", route: "" }],
};
export default { pushBreadcrumb, popBreadcrumb, BreadcrumbItems };

import { Button } from "antd";
import React, { useState } from "react";

const EmptyCard = ({ setShowCards }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleClick = () => {
    setShowCards(false);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className="container clientprofile" style={{ padding: "20px" }}>
        {/*{isMobile?<Responsiveheader title={"Payment Information"}/>:""}*/}
        <Button
          className="btn-black add-card-bank-details auth-action-btn float-end savebutton add-card-btn"
          type="primary"
          shape="round"
          size="small"
          style={{
            position: "fixed",
            width: "auto",
          }}
          onClick={handleClick}
        >
          Add Card
        </Button>
      </div>
    </>
  );
};
export default EmptyCard;

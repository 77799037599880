import axios from "axios";
import React, { useEffect, useState } from "react";
import { setAlert } from "../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import AddRelativeProfileDetails from "./relative";
import AdditionalInformation from "./relative/additional-information";
import { Button, Spin } from "antd";
import { Logout } from "../../../redux/auth/auth.action";

function AddRelatives({ next, prev, accessToken }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showRelativeProfileForm, setShowRelativeProfileForm] = useState(false);
  const [addRelative, setAddRelatives] = useState(false);
  const [relativeProfileData, setRelativeProfileData] = useState();
  const [showRelativeAdditionalForm, setShowRelativeAdditionalForm] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [relativeList, setRelativeList] = useState([]);
  const [editRelativeData, setEditRelativeData] = useState();
  const logindata = useSelector((state) => state.loginData);
  useEffect(() => {
    fetchRelatives(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const fetchRelatives = async (token) => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-relatives`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setRelativeList(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(Logout());
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          }
        });
    }
  };

  const saveRelativeFormData = (values) => {
    const obj = { ...values };
    obj.location = { lat: `${obj.location?.lat}`, lon: `${obj.location?.lon}` };
    setRelativeProfileData(obj);
    console.log("values ~", obj);
    setShowRelativeProfileForm(false);
    setShowRelativeAdditionalForm(true);
  };

  const saveRelativeAdditionalFormData = (values) => {
    const data = {
      ...relativeProfileData,
      ...values,
    };
    const editData = {
      id: editRelativeData !== undefined && editRelativeData._id,
      ...relativeProfileData,
      ...values,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/${
          editRelativeData !== undefined ? "edit-relatives" : "add-relatives"
        }`,
        editRelativeData !== undefined ? editData : data,
        {
          headers: {
            accessToken: accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        console.log("response", response);
        if (response.data.status === 200) {
          fetchRelatives(accessToken);
          setShowRelativeAdditionalForm(false);
          setShowRelativeProfileForm(false);
          setAddRelatives(false);
          setEditRelativeData(undefined);
          // next()
        } else if (response.data.status >= 400) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        } else {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (
          err?.response.data.status >= 400 &&
          err?.response.data.status != 400
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  const noRelativeAdded = (
    <div className="px-md-5 relatives-add-space text-center position-relative">
      <div className="user-circle mx-auto">
        <img
          style={{ width: "100%", height: "100%", objectFit: "scale-down" }}
          src="/images/icons/Profile.png"
          alt="dummy profile icon"
        />
        <div
          onClick={() => {
            setAddRelatives(true);
            setShowRelativeProfileForm(true);
          }}
          className="pointer"
          style={{
            position: "absolute",
            bottom: "-20px",
            right: 0,
              left:0,
              margin:'auto'

          }}
        >
          <img
            style={{ width: "45px" }}
            src="/images/icons/add-relative.svg"
            alt="dummy add relatives"
          />
        </div>
      </div>
      <p className="mt-5 color-secondary fs-6 mb-5"> Add New Recipient</p>
    </div>
  );

  const hasRelativeAdded = (
    <div style={{ maxWidth: "420px", width: "100%" }} className="mx-auto">
      {relativeList?.length > 0 &&
        relativeList?.map((rel, i) => {
          return (
            <div
              key={i}
              className="d-flex w-100 border care-recipient-card align-items-center"
            >
              <div className="text-start relative-img" style={{ width: "20%" }}>
                <img
                  className="rounded-circle"
                  src={!rel?.image ? "/images/icons/Profile.png" : rel?.image}
                  alt=""
                />
              </div>
              <div className="text-start" style={{ width: "60%" }}>
                <p className="fw-bolder fs-5 mb-0">
                  {rel?.firstName + " " + rel?.lastName}
                </p>
                <p className="color-secondary fs-6 mb-0">{rel?.address}</p>
              </div>
              <div className="text-end" style={{ width: "20%" }}>
                <img
                  onClick={() => editRelative(rel?._id)}
                  src="/images/icons/edit.svg"
                  alt="edit icon"
                />
              </div>
            </div>
          );
        })}
      <div
        onClick={() => {
          setAddRelatives(true);
          setShowRelativeProfileForm(true);
        }}
        className="pointer pointer-add-pp"
        style={{ width: "auto" }}
      >
        <img
          style={{ width: "40px" }}
          src="/images/icons/add-relative.svg"
          alt="dummy add relatives"
        />
        <p className="mt-2 color-secondary fs-6 mb-3">Add New Recipient</p>
      </div>
      <Button
        className="btn-black auth-action-btn mb-5 auth-button-space mx-auto"
        shape="round"
        type="primary"
        size="large"
        onClick={() => {
          // setShowTnc(true);
          if (!logindata?.homeData?.isFirstAppointmentCreated) {
            navigate("/client/appointment");
          } else {
            navigate("/client/appointments-dashboard");
          }
        }}
      >
        Get Started{" "}
        <img className="ms-2" src="/images/icons/right.svg" alt="right icon" />
      </Button>
    </div>
  );

  const editRelative = (id) => {
    const editRel = relativeList.filter((rel) => rel._id === id);
    console.log("editRel > ", editRel);
    setEditRelativeData(editRel[0]);
    setAddRelatives(true);
    setShowRelativeProfileForm(true);
  };

  return (
    <>
      {!addRelative && (
        <div className="px-3 px-md-0">
          <div className="text-end color-secondary fs-5 pe-4 pt-4 fw-bolder">
            {relativeList?.length > 0 ? (
              ""
            ) : (
              <span
                className="pointer"
                onClick={() => {
                  // setShowTnc(true);
                  if (!logindata?.homeData?.isFirstAppointmentCreated) {
                    navigate("/client/appointment");
                  } else {
                    navigate("/client/appointments-dashboard");
                  }
                }}
              >
                Skip
              </span>
            )}
          </div>
          <Spin spinning={loading}>
            <h1 className="welcome-header fw-bolder mt-2 mb-4" id="name">
              Add a Care Recipient
            </h1>
            {relativeList?.length == 0 && (
              <p className="welcome-subheader mb-2">
                Create a profile for the person that will be receiving home care
                services
              </p>
            )}
            <div className="col-12 text-start form-row form-row-div form-row-border">
              {relativeList?.length > 0 ? hasRelativeAdded : noRelativeAdded}
            </div>
          </Spin>
        </div>
      )}

      {showRelativeProfileForm && (
        <AddRelativeProfileDetails
          editRelData={editRelativeData}
          save={saveRelativeFormData}
        />
      )}
      {showRelativeAdditionalForm && (
        <AdditionalInformation
          editRelData={editRelativeData}
          save={saveRelativeAdditionalFormData}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRelatives);

const INITIAL_STATE = {
  identityDoc: null,
  addressDoc: null,
  stripeStatus: null,
};

const stripeReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case "IDENTITY":
      return {
        ...state,
        identityDoc: action.payload,
      };
    case "ADDRESS":
      return {
        ...state,
        addressDoc: action.payload,
      };
    case "STATUS":
      return {
        ...state,
        stripeStatus: action.payload,
      };
    default:
      return state;
  }
};

export default stripeReducer;

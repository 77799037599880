import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, Spin, Radio, Space, Button } from "antd";
import { toggleConfirmRelativeModal } from "../../redux/modals/toggleModal.actions";
import { setAlert } from "../../redux/alert/alert.action";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  bookAppointment,
  selectedCC,
} from "../../redux/appointment/appointment.action";
import { CloseCircleOutlined } from "@ant-design/icons";

function ConfirmRelatives({ confirmRelativeModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [relative, setRelative] = useState(
    JSON.parse(localStorage.getItem("relative"))
  );
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const appointmentData = useSelector((state) => state.appointment);
  const currentUrl = window.location.href;
  const token = localStorage.getItem("token");
  const handleOk = () => {
    dispatch(toggleConfirmRelativeModal(false));
  };

  const handleCancel = () => {
    dispatch(toggleConfirmRelativeModal(false));
  };

  const confirm = () => {
    localStorage.setItem("relativeId", value);
    if (currentUrl.includes("my-caregivers")) {
      // navigate('/client/appointment/pay-for-appointment');
      handleBookingAppointment();
    } else {
      navigate("/client/appointment/create-appointment/caregivers");
    }
    dispatch(toggleConfirmRelativeModal(false));
  };

  const handleBookingAppointment = () => {
    if (token !== "") {
      setLoading(true);
      const slotsArr = [];
      appointmentData?.dateTimeResp.slot.forEach((i) => {
        const foundAvailability =
          appointmentData?.caregiverAvailability?.length > 0 &&
          appointmentData?.caregiverAvailability.find(
            (a) =>
              moment(a.startTime).zone(0).format("DD-MM-YYYY") ===
              moment(i.date).format("DD-MM-YYYY")
          );
        const isOverNight =
          moment(i?.date).zone(0).format("DD") !=
          moment(foundAvailability?.endTime).zone(0).format("DD");
        const date = moment(i.date, "YYYY/MM/DD");
        const startTimeObj =
          date.format("YYYY-MM-DD") +
          "T" +
          moment(i.time?.startTime).utc(true).format("HH") +
          ":" +
          moment(i.time?.startTime).utc(true).format("mm") +
          `:00.000Z`;
        let endTimeObj =
          date.format("YYYY-MM-DD") +
          "T" +
          moment(i.time?.endTime).utc(true).format("HH") +
          ":" +
          moment(i.time?.endTime).utc(true).format("mm") +
          `:00.000Z`;
        if (i?.isOverNight) {
          endTimeObj =
            date.format("YYYY-MM") +
            "-" +
            moment(date).add(1, "days").format("DD") +
            "T" +
            moment(i.time?.endTime).utc(true).format("HH") +
            ":" +
            moment(i.time?.endTime).utc(true).format("mm") +
            `:00.000Z`;
        }
        const obj = {
          date: date.format("YYYY-MM-DD") + "T00:00:00.000Z",
          time: { startTime: startTimeObj, endTime: endTimeObj },
        };
        // const startTime = i.time?.startTime;
        // const endTime = i.time?.endTime;
        // const startTimeObj = moment(startTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
        // const endTimeObj = moment(endTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
        if (foundAvailability) {
          slotsArr.push(obj);
        }
      });
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/book-appointment`,
          {
            services: [
              ...appointmentData?.servicesResp.checkedCaregiver,
              ...appointmentData?.servicesResp.checkedCompanions,
            ],
            caregiverId: appointmentData?.selectedCC?.caregiverId
              ? appointmentData?.selectedCC?.caregiverId
              : appointmentData?.selectedCC?._id,
            slot: slotsArr,
            relativeId: JSON.parse(localStorage.getItem("relative"))?._id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then(async (response) => {
          if ((await response.data.status) === 200) {
            // setCareGiverDetails(response.data.output)
            dispatch(bookAppointment(response.data.output));
            navigate("/my-caregivers/appointment/pay-for-appointment");
            setLoading(false);
            // navigate("/client/appointment/pay-for-appointment")
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  return (
    <div>
      <Modal
        className="confirm-relative-modal"
        header={null}
        footer={null}
        centered
        open={confirmRelativeModal}
        onOk={handleOk}
        onCancel={handleCancel}
        closable
        closeIcon={<CloseCircleOutlined />}
      >
        <Spin spinning={loading}>
          <p className="fw-bold fs-3 text-center mb-2">Confirmation</p>
          <p className="color-secondary fs-5 text-center mb-2">
            Please verify the address. If incorrect, update the information now
          </p>
          <div className="border border-1 p-2 relative-container mx-auto">
            <img
              className="edit-relative"
              src="/images/icons/edit.svg"
              alt="edit icon"
              onClick={() => {
                navigate(`/client/relatives/${relative._id}`, {
                  state: {
                    relativeData: {
                      ...relative,
                      REDIRECT_FROM_APPOINTMENTS: true,
                    },
                  },
                });
                handleCancel();
              }}
            />
            <img
              style={{ width: "80px", height: "80px" }}
              className="my-2 rounded-circle"
              src={
                !relative?.image ? "/images/icons/Profile.png" : relative?.image
              }
              alt={relative?.firstName}
            />
            <p className="fw-bold fs-5">
              {relative?.firstName} {relative?.lastName}
            </p>
            <p className="color-secondary fs-6">
              {relative?.address}, {relative?.city}, {relative?.province},{" "}
              {relative?.postalCode}
            </p>
          </div>
          <Button
            htmlType="submit"
            style={{ width: "44%" }}
            onClick={confirm}
            className="btn-black mx-auto auth-action-btn mt-5 mb-3 confirm-button"
            shape="round"
            type="primary"
            size="large"
            disabled=""
          >
            Yes, I Confirm
          </Button>
        </Spin>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    confirmRelativeModal: state.toggleModals.confirmRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmRelatives);

import { Button, Col, Divider, message, Modal, Row, Spin } from "antd";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../../client/appointment/appointmentsNavBar";
import "../../client/appointment/service-grid.less";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import "./appointment.less";
// import { RatingModal } from "../../client/appointment/careGiverDetails";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { CancelModel } from "./caregiverAppointmentDetails";
import TextArea from "antd/lib/input/TextArea";
import {
  paymentDetails as newPaymentDetails,
  selectedCC,
  UpdatepaymentDetails,
} from "../../../redux/appointment/appointment.action";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { blankStarArr } from "../../../utils";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../components/Footer/Footer";
import ExpensesItems from "./ClientExpenses/ExpensesItems";
import {
  GoogleMap,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import commonApi from "../../../http/commonApi";
import { CloseCircleOutlined } from "@ant-design/icons";
import polyline from "google-polyline";
import {
  computeDestinationPoint,
  getDistance,
  getPreciseDistance,
  getRhumbLineBearing,
  isPointInPolygon,
} from "geolib";
import { ImageModal } from "../../client/appointment/careGiverDetails";

export default function AppointmentDetails(props) {
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [caregiverDetails, setCaregiverDetails] = useState(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [appointmentID, setAppointmentID] = useState(null);
  const [giveReviewsVisible, setGiveReviewsModal] = useState(false);
  const [reviewSuccessVisible, setReviewSuccessVisible] = useState(false);
  const [locationDetails, setLocationDetails] = useState(null);
  const [imagePopupVisible, setImagePopupVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const appointmentData = useSelector((state) => state.appointment);
  const socket = useSelector((state) => state.socketData?.socket);
  const userData = useSelector((state) => state.loginData?.user);
  const { paymentDetails } = appointmentData;
  const token = localStorage.getItem("token");
  var google = window.google;
  console.log(google, "globalgoogle");
  let duration = moment.duration(
    moment(paymentDetails?.startTime).diff(moment().utc(true))
  );
  let minutes = duration.asMinutes();
  console.log(minutes, "minutes");
  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.Appointments.concat({
          title: paymentDetails?.caregiverName,
          route: `appointment-details`,
        })
      )
    );
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // let duration = moment.duration(moment().diff(paymentDetails?.startTime));
      // let minutes = duration.asMinutes();
      if (
        socket?.connected &&
        (paymentDetails?.statusType == 2 || paymentDetails?.statusType == 5)
      ) {
        socket.emit("getLocation", {
          userId: userData?.userId,
          caregiverId: paymentDetails?.caregiverId,
        });
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [socket?.connected, userData?.userId, paymentDetails?.caregiverId]);

  useEffect(() => {
    // const intervalId = setInterval(() => {
    // let duration = moment.duration(moment().diff(paymentDetails?.startTime));
    // let minutes = duration.asMinutes();
    if (
      socket?.connected &&
      (paymentDetails?.statusType == 2 || paymentDetails?.statusType == 5)
    ) {
      socket.emit("getLocation", {
        userId: userData?.userId,
        caregiverId: paymentDetails?.caregiverId,
      });
    }
  }, [socket?.connected, userData?.userId, paymentDetails?.caregiverId]);

  console.log(minutes, "minutes");
  useEffect(() => {
    if (
      socket?.connected &&
      (paymentDetails?.statusType == 2 || paymentDetails?.statusType == 5)
    ) {
      socket.on("getLocationFromServer", (location) => {
        console.log(location, "getLocationFromServer");
        if (location?.locationDetails?.lat && location?.locationDetails?.lon) {
          setLocationDetails(location?.locationDetails);
        }
      });
    }
  }, [socket?.connected, userData?.userId, paymentDetails?.caregiverId]);
  console.log(paymentDetails, "paymentDetails");
  const handleReviewModalVisible = () => {
    setReviewModalVisible(true);
  };

  const handleRatingModalClose = () => {
    setReviewModalVisible(false);
  };

  const handleCancelModalVisible = (appId) => {
    setAppointmentID(appId);
    setCancelModalVisible(true);
  };

  const handleCancelModalClose = () => {
    setAppointmentID(null);
    setCancelModalVisible(false);
  };

  const handleGiveReviewsModal = () => {
    setGiveReviewsModal(true);
  };

  const handleGiveReviewsClose = () => {
    setGiveReviewsModal(false);
  };

  const handleReviewSuccessVisible = () => {
    setReviewSuccessVisible(true);
  };

  const handleReviewSuccessClose = () => {
    setReviewSuccessVisible(false);
  };

  const handleChooseRelative = () => {
    dispatch(selectedCC({ ...paymentDetails, scheduleAgain: true }));
    dispatch(toggleChooseRelativeModal(true));
  };

  const handleCancelAppointment = (cancelType, cancelReason) => {
    const type =
      paymentDetails?.statusType == 0 || paymentDetails?.statusType == 1
        ? "pending"
        : paymentDetails?.statusType == 2
        ? "upcoming"
        : "";
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/client-cancel-appointment`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type,
            cancelType,
            appointmentId: appointmentID,
            cancelReason,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            navigate("/caregiver/appointments-dashboard");
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "googlemapsapi");
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  console.log(isLoaded, "isMapLoaded");

  console.log("paymentDetails", paymentDetails);

  //  console.log(paymentDetails, "paymentDetiassss")
  const center = { lat: 48.8584, lng: 2.2945 };
  const BookmarkByid = async () => {
    setLoading(true);
    const resEntity = await commonApi._isBookmarked({
      caregiverId: paymentDetails.caregiverId,
    });
    if (resEntity.status === 200) {
      setLoading(false);
      dispatch(
        UpdatepaymentDetails({
          ...paymentDetails,
          isBookmarked: !paymentDetails.isBookmarked,
        })
      );
      if (paymentDetails?.isBookmarked) {
        message.success("Bookmarked Removed Successfully!");
      } else {
        message.success("Bookmarked Added Successfully!");
      }
      // fetchGiverDetails();
      // setLoading(false)
    } else if (resEntity.status >= 400) {
      setLoading(false);
      //  setLoading(false)
      dispatch(
        setAlert({
          alertType: "error",
          alertMsg: resEntity?.error?.message,
          alertOpen: true,
        })
      );
    } else {
      setLoading(false);
      //  setLoading(false)
      dispatch(
        setAlert({
          alertType: "error",
          alertMsg: "something went wrong! please try again.",
          alertOpen: true,
        })
      );
    }
  };

  const fetchAppointmentDetails = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-appointments-details`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          { appointmentId: id },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            const { output } = response.data;
            dispatch(
              newPaymentDetails({
                ...paymentDetails,
                ...output?.appointmentDetails,
                expenses: output?.expenses,
              })
            );
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "somthing went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getHighlightedID = () => {
    const foundOngoing = paymentDetails?.timing.find(
      (dateSlot) => dateSlot.statusType == 5
    );
    console.log(foundOngoing, "foundOngoing");
    if (foundOngoing) {
      return foundOngoing._id;
    } else {
      const foundFirstUpcoming = paymentDetails?.timing.find(
        (dateSlot) => dateSlot.statusType == 2
      );
      return foundFirstUpcoming?._id;
    }
  };

  return (
    <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>
      {/*{isMobile ? (*/}
      {/*    <div className="container-fluid appointment-dashboard">*/}
      {/*        <div className="pt-2 d-flex profile-details-wrapper">*/}
      {/*            <div className="mx-0 w-100">*/}
      {/*                <AppointmentNavBar/>*/}
      {/*                /!*<Responsiveheader title={'Appointments'} />*!/*/}
      {/*                <div style={{background: "#fff", padding: "15px 25px", marginTop: '20px', height: '140vh'}}*/}
      {/*                     className="border-less rounded">*/}
      {/*                    <div*/}
      {/*                        style={(paymentDetails?.statusType === 6 || paymentDetails?.statusType !== 4) && userMode === "client" ? {*/}
      {/*                            minHeight: '55vh',*/}
      {/*                            maxHeight: '59vh',*/}
      {/*                        } : {minHeight: '73vh', maxHeight: '90vh',}}>*/}
      {/*                        <div>*/}
      {/*                            <Spin spinning={loading} tip="loading...">*/}
      {/*                                <div className={`payment-wrapper appointment-wrapper`}*/}
      {/*                                     style={{width: "100%"}}>*/}
      {/*                                    <Row>*/}
      {/*                                        /!* <Col*/}
      {/*                xs={24}*/}
      {/*                style={{ maxHeight: "100px" }}*/}
      {/*              >*/}
      {/*                <div style={{ justifyContent: "flex-end", display: 'flex' }}>*/}
      {/*                  {paymentDetails?.statusType == 1 ? (*/}
      {/*                    <Button className="pending" style={{ width: '101px' }}>Pending</Button>*/}
      {/*                  ) : paymentDetails?.statusType == 5 ? (*/}
      {/*                    <Button className="ongoing" style={{ width: '101px' }}>Ongoing</Button>*/}
      {/*                  ) : paymentDetails?.statusType == 6 ? (*/}
      {/*                    <Button className="completed" style={{ width: '101px' }}>*/}
      {/*                      Completed*/}
      {/*                    </Button>*/}
      {/*                  ) : paymentDetails?.statusType == 4 ? (*/}
      {/*                    <Button className="cancelled">*/}
      {/*                      Cancelled*/}
      {/*                    </Button>*/}
      {/*                  ) : paymentDetails?.statusType == 3 ? (*/}
      {/*                    <Button className="declined">Declined</Button>*/}
      {/*                  ) : paymentDetails?.statusType == 2 ? (*/}
      {/*                    <Button className="upcoming">Upcoming</Button>*/}
      {/*                  ) : (*/}
      {/*                    ""*/}
      {/*                  )}*/}
      {/*                </div>*/}

      {/*              </Col> *!/*/}
      {/*                                        <Col xs={24} style={{maxHeight: "100px"}}>*/}
      {/*                                            <Row gutter={24} className="pt-3">*/}
      {/*                                                <Col xs={2}>*/}
      {/*                                                    <img alt=""*/}
      {/*                                                         style={{borderRadius: "50px"}}*/}
      {/*                                                         src={!paymentDetails?.caregiverImage ? "/images/icons/Profile.png" : paymentDetails?.caregiverImage}*/}
      {/*                                                         width={60}*/}
      {/*                                                         height={60}*/}
      {/*                                                    />*/}
      {/*                                                </Col>*/}
      {/*                                                <Col xs={2}>*/}
      {/*                                                    <div style={{position: 'relative', left: "30px"}}*/}
      {/*                                                         className="d-flex">*/}
      {/*                      <span className="fw-bold" style={{whiteSpace: 'nowrap'}}>*/}
      {/*                        {paymentDetails?.caregiverName}*/}
      {/*                      </span>*/}

      {/*                                                        <img*/}
      {/*                                                            src="/images/icons/verified.svg"*/}
      {/*                                                            alt="verification"*/}
      {/*                                                            width={15}*/}
      {/*                                                            style={{*/}
      {/*                                                                position: "relative",*/}
      {/*                                                                bottom: "1px",*/}
      {/*                                                                left: "8px",*/}
      {/*                                                            }}*/}
      {/*                                                        />*/}
      {/*                                                    </div>*/}
      {/*                                                </Col>*/}
      {/*                                                <Col xs={4} style={{display: 'flex', marginLeft: '3rem'}}>*/}
      {/*                                                    <div style={{display: 'flex', marginLeft: '4rem'}}>*/}
      {/*                                                        /!* <Button*/}
      {/*                        className="caregiver-btn"*/}
      {/*                        style={{ justifyContent: "end", display: "flex",  width:'100%'}}*/}
      {/*                      >*/}
      {/*                        {paymentDetails?.caregiverType == 3*/}
      {/*                          ? "Caregiver"*/}
      {/*                          : paymentDetails?.caregiverType == "4"*/}
      {/*                            ? "Companion"*/}
      {/*                            : ""}*/}
      {/*                      </Button> *!/*/}

      {/*                                                        {paymentDetails?.statusType == 1 ? (*/}
      {/*                                                            <Button className="pending"*/}
      {/*                                                                    style={{width: '101px'}}>Pending</Button>*/}
      {/*                                                        ) : paymentDetails?.statusType == 5 ? (*/}
      {/*                                                            <Button className="ongoing"*/}
      {/*                                                                    style={{width: '101px'}}>Ongoing</Button>*/}
      {/*                                                        ) : paymentDetails?.statusType == 6 ? (*/}
      {/*                                                            <Button className="completed"*/}
      {/*                                                                    style={{width: '101px'}}>*/}
      {/*                                                                Completed*/}
      {/*                                                            </Button>*/}
      {/*                                                        ) : paymentDetails?.statusType == 4 ? (*/}
      {/*                                                            <Button className="cancelled">*/}
      {/*                                                                Cancelled*/}
      {/*                                                            </Button>*/}
      {/*                                                        ) : paymentDetails?.statusType == 3 ? (*/}
      {/*                                                            <Button className="declined"*/}
      {/*                                                                    style={{width: '101px'}}>Declined</Button>*/}
      {/*                                                        ) : paymentDetails?.statusType == 2 ? (*/}
      {/*                                                            <Button className="upcoming"*/}
      {/*                                                                    style={{width: '101px'}}>Upcoming</Button>*/}
      {/*                                                        ) : (*/}
      {/*                                                            ""*/}
      {/*                                                        )}*/}
      {/*                                                        <img*/}
      {/*                                                            className="bookmark-button pt-2 mx-2"*/}
      {/*                                                            src={`/images/icons/${paymentDetails?.isBookmarked ? 'bookmark-filled' : 'bookmark-icon'}.svg`}*/}
      {/*                                                            width={30}*/}
      {/*                                                            height={30} onClick={BookmarkByid}*/}
      {/*                                                            alt="bookmark"*/}
      {/*                                                            // width={20}*/}
      {/*                                                            style={{*/}
      {/*                                                                cursor: 'pointer',*/}
      {/*                                                                position: "relative",*/}
      {/*                                                                bottom: "6px",*/}
      {/*                                                            }}*/}
      {/*                                                        />*/}
      {/*                                                    </div>*/}
      {/*                                                </Col>*/}
      {/*                                            </Row>*/}
      {/*                                        </Col>*/}
      {/*                                        <Col xs={24}>*/}
      {/*                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
      {/*                                                {paymentDetails?.ratingCount == 0 ?*/}
      {/*                                                    <p style={{position: "relative", color: '#9ea5ab'}}>No Ratings & Reviews</p>*/}
      {/*                                                    :*/}
      {/*                                                    <div className="d-flex"*/}
      {/*                                                        style={{position: "relative",}}>*/}
      {/*                                                        <img alt=""*/}
      {/*                                                            src="/images/icons/star-rating.svg"*/}
      {/*                                                            width={16}*/}
      {/*                                                        />*/}
      {/*                                                        <span*/}
      {/*                                                            style={{*/}
      {/*                                                                marginLeft: "8px",*/}
      {/*                                                                position: "relative",*/}
      {/*                                                                right: "2px",*/}
      {/*                                                                fontWeight: 400,*/}
      {/*                                                                fontSize: "13px",*/}
      {/*                                                            }}*/}
      {/*                                                            onClick={() => {*/}
      {/*                                                                if (paymentDetails?.ratingCount != 0) {*/}
      {/*                                                                    handleReviewModalVisible();*/}
      {/*                                                                }*/}
      {/*                                                            }}*/}
      {/*                                                        >*/}
      {/*                        {paymentDetails?.caregiverRating} (*/}
      {/*                                                            {paymentDetails?.ratingCount} reviews)*/}
      {/*                      </span>*/}
      {/*                                                    </div>*/}
      {/*                                                }*/}
      {/*                                                <div*/}
      {/*                                                    className="d-flex"*/}
      {/*                                                    style={*/}
      {/*                                                        paymentDetails?.caregiverRating == 0 ?*/}
      {/*                                                            {*/}
      {/*                                                                // left: "15vw",*/}
      {/*                                                                position: "relative",*/}
      {/*                                                                // bottom: "99px",*/}
      {/*                                                            }*/}
      {/*                                                            :*/}
      {/*                                                            {*/}
      {/*                                                                // left: "15vw",*/}
      {/*                                                                position: "relative",*/}
      {/*                                                                // bottom: "85px",*/}
      {/*                                                            }}*/}
      {/*                                                >*/}
      {/*                                                    <img alt=""*/}
      {/*                                                        src="/images/icons/location.svg"*/}
      {/*                                                        width={15}*/}
      {/*                                                    />*/}
      {/*                                                    <span*/}
      {/*                                                        style={{*/}
      {/*                                                            marginLeft: "8px",*/}
      {/*                                                            fontWeight: 400,*/}
      {/*                                                            fontSize: "13px",*/}
      {/*                                                            position: "relative",*/}
      {/*                                                            right: "2px",*/}
      {/*                                                        }}*/}
      {/*                                                    >*/}
      {/*                                                      {paymentDetails?.statusType == 2 ||*/}
      {/*                                                      paymentDetails?.statusType == 5 ||*/}
      {/*                                                      paymentDetails?.statusType == 6*/}
      {/*                                                      ? `${paymentDetails?.caregiverAddress?.city}, ${paymentDetails?.caregiverAddress?.province}`*/}
      {/*                                                      : `${paymentDetails?.caregiverAddress?.province}`}*/}
      {/*                                                    </span>*/}
      {/*                                                </div>*/}
      {/*                                            </div>*/}

      {/*                                        </Col>*/}
      {/*                                    </Row>*/}
      {/*                                    <Row gutter={24} className={"pt-2"}>*/}
      {/*                                        <Col xs={24} style={{marginBottom: "10px"}}>*/}
      {/*                                            <span className="profile-detail-title">*/}
      {/*                                              Booked For*/}
      {/*                                            </span>*/}
      {/*                                        </Col>*/}
      {/*                                        <Col xl={24} className="d-flex">*/}
      {/*                                            {*/}
      {/*                                                paymentDetails?.relativeImage ?*/}
      {/*                                                    <img alt=""*/}
      {/*                                                        style={{borderRadius: "50px"}}*/}
      {/*                                                        src={paymentDetails?.relativeImage}*/}
      {/*                                                        width={40}*/}
      {/*                                                        height={40}*/}
      {/*                                                    />*/}
      {/*                                                    :*/}
      {/*                                                    <img alt=""*/}
      {/*                                                        style={{borderRadius: "50px"}}*/}
      {/*                                                        width={40}*/}
      {/*                                                        height={40}*/}
      {/*                                                        src='/images/icons/Profile.png'*/}
      {/*                                                    />*/}
      {/*                                            }*/}
      {/*                                            <div className="mx-2">*/}
      {/*                                                <p className="fw-bold mb-1">*/}
      {/*                                                    {paymentDetails?.relativeName}*/}
      {/*                                                </p>*/}
      {/*                                            </div>*/}
      {/*                                        </Col>*/}
      {/*                                        /!* <Divider type="horizontal" style={{height: '40px'}} /> *!/*/}
      {/*                                        <Col xl={24} className="pt-4">*/}
      {/*                                            <div*/}
      {/*                                                // className="d-flex"*/}
      {/*                                                // style={{*/}
      {/*                                                //   // right: "20px",*/}
      {/*                                                //   position: "relative",*/}
      {/*                                                // }}*/}
      {/*                                            >*/}
      {/*                                                <img alt=""*/}
      {/*                                                    src="/images/icons/location.svg"*/}
      {/*                                                    width={15}*/}
      {/*                                                />*/}
      {/*                                                <span*/}
      {/*                                                    style={{*/}
      {/*                                                        marginLeft: "11px",*/}
      {/*                                                        fontWeight: 400,*/}
      {/*                                                        fontSize: "16px",*/}
      {/*                                                        lineHeight: "22px",*/}
      {/*                                                        position: "relative",*/}
      {/*                                                        right: "2px",*/}
      {/*                                                    }}*/}
      {/*                                                >*/}
      {/*                                                    {*/}
      {/*                                                        paymentDetails?.relativeAddressAddress*/}
      {/*                                                            ?.address*/}
      {/*                                                    },{" "}*/}
      {/*                                                    {*/}
      {/*                                                        paymentDetails?.relativeAddressAddress*/}
      {/*                                                            ?.city*/}
      {/*                                                    },{" "}*/}
      {/*                                                    {*/}
      {/*                                                        paymentDetails?.relativeAddressAddress*/}
      {/*                                                            ?.province*/}
      {/*                                                    },{" "}*/}
      {/*                                                    {*/}
      {/*                                                        paymentDetails?.relativeAddressAddress*/}
      {/*                                                            ?.postalCode*/}
      {/*                                                    }*/}
      {/*                                                </span>*/}
      {/*                                            </div>*/}
      {/*                                        </Col>*/}
      {/*                                        <Divider type="horizontal"/>*/}
      {/*                                        <Col xl={2} className={"pt-3 "} style={{marginLeft: '2rem'}}>*/}
      {/*                                            <div*/}
      {/*                                                className="d-flex"*/}
      {/*                                                style={{*/}
      {/*                                                    right: "20px",*/}
      {/*                                                    position: "relative",*/}
      {/*                                                    justifyContent: "center",*/}
      {/*                                                }}*/}
      {/*                                            >*/}
      {/*                                                <img alt=""*/}
      {/*                                                    src="/images/icons/time.svg"*/}
      {/*                                                    width={20}*/}
      {/*                                                />*/}
      {/*                                                <span*/}
      {/*                                                    style={{*/}
      {/*                                                        fontWeight: 400,*/}
      {/*                                                        fontSize: "16px",*/}
      {/*                                                        lineHeight: "22px",*/}
      {/*                                                        marginLeft: "11px",*/}
      {/*                                                    }}*/}
      {/*                                                >*/}
      {/*                                                    {paymentDetails?.totalHour} Hours*/}
      {/*                                                </span>*/}
      {/*                                            </div>*/}
      {/*                                        </Col>*/}
      {/*                                        /!* <Divider type="vertical" style={{ height: '40px', right: "20px", }} /> *!/*/}
      {/*                                        <Col xl={3} className={"pt-3"}>*/}
      {/*                                            <span className="profile-detail-title">*/}
      {/*                                              Hourly Rate*/}
      {/*                                            </span>*/}
      {/*                                            <p className="profile-detail-val" style={{fontWeight: "400"}}>*/}
      {/*                                                ${paymentDetails?.caregiverActualPrice}*/}
      {/*                                            </p>*/}
      {/*                                        </Col>*/}
      {/*                                        /!* <Divider type="vertical" style={{ height: '40px' }} /> *!/*/}
      {/*                                        <Col xl={2} className={"pt-3"}>*/}
      {/*                                            <span className="profile-detail-title">*/}
      {/*                                              Total*/}
      {/*                                            </span>*/}
      {/*                                            <p className="profile-detail-val" style={{fontWeight: "400"}}>*/}
      {/*                                                ${paymentDetails?.totalAmount}*/}
      {/*                                            </p>*/}
      {/*                                        </Col>*/}
      {/*                                    </Row>*/}
      {/*                                    <Row>*/}
      {/*                                        <Col xl={24} lg={12} xs={12}>*/}
      {/*                                            <Divider type="horizontal"/>*/}
      {/*                                        </Col>*/}
      {/*                                    </Row>*/}
      {/*                                    <div style={{*/}
      {/*                                        overflowY: "scroll",*/}
      {/*                                        overflowX: 'hidden',*/}
      {/*                                        maxHeight: paymentDetails?.statusType !== 4 || paymentDetails?.statusType != 6 || paymentDetails?.statusType != 5 ? "75vH" : paymentDetails?.statusType === 4 ? "75vH" : "75vH"*/}
      {/*                                    }}*/}
      {/*                                    >*/}
      {/*                                        <Row>*/}
      {/*                                            <Col xs={24} className="mb-3">*/}
      {/*                                              <span className="profile-detail-title">*/}
      {/*                                                Services*/}
      {/*                                              </span>*/}
      {/*                                                <Row className="mt-3">*/}
      {/*                                                    {paymentDetails?.serviceList?.map((i) => {*/}
      {/*                                                        return (*/}
      {/*                                                            <Col*/}
      {/*                                                                lg={6}*/}
      {/*                                                                md={8}*/}
      {/*                                                                sm={12}*/}
      {/*                                                                className="service-offer-card d-flex mx-1 mt-2"*/}
      {/*                                                            >*/}
      {/*                                                                <img alt=""*/}
      {/*                                                                    src={i.image}*/}
      {/*                                                                    width={50}*/}
      {/*                                                                    height={50}*/}
      {/*                                                                />*/}
      {/*                                                                <span className="service-offer-text">{i?.name}</span>*/}
      {/*                                                            </Col>*/}
      {/*                                                        );*/}
      {/*                                                    })}*/}
      {/*                                                </Row>*/}
      {/*                                            </Col>*/}
      {/*                                            <Col xs={24}>*/}
      {/*                                                  <span className="profile-detail-title">*/}
      {/*                                                    Date and Time*/}
      {/*                                                  </span>*/}
      {/*                                                <Row*/}
      {/*                                                    className={`mt-3 ${paymentDetails?.statusType == 6*/}
      {/*                                                        ? "completed-appointment"*/}
      {/*                                                        : ""*/}
      {/*                                                    }`}*/}
      {/*                                                >*/}
      {/*                                                    {paymentDetails?.timing?.map((i) => {*/}
      {/*                                                        return (*/}
      {/*                                                            <Col*/}
      {/*                                                                lg={3}*/}
      {/*                                                                md={6}*/}
      {/*                                                                sm={8}*/}
      {/*                                                                className={`service-offer-card d-flex mx-1 mt-2 ${i?.statusType == 4*/}
      {/*                                                                    ? "cancelled-appointment"*/}
      {/*                                                                    : ""*/}
      {/*                                                                } ${i?.statusType == 5*/}
      {/*                                                                    ? "ongoing-appointment"*/}
      {/*                                                                    : ""*/}
      {/*                                                                }`}*/}
      {/*                                                                onClick={() => fetchAppointmentDetails(i._id)}*/}
      {/*                                                            >*/}
      {/*                                                                <div className="date-card">*/}
      {/*                                                                    {moment(i.date).format("MMM")}{" "}*/}
      {/*                                                                    <span*/}
      {/*                                                                        style={{*/}
      {/*                                                                            fontWeight: 700,*/}
      {/*                                                                            marginTop: "-5px",*/}
      {/*                                                                        }}*/}
      {/*                                                                    >*/}
      {/*                                                                      {moment(i.date).format("DD")}*/}
      {/*                                                                    </span>*/}
      {/*                                                                </div>*/}
      {/*                                                                <span className="time-text">*/}
      {/*                            {moment(i.startTime)*/}
      {/*                                .zone(0)*/}
      {/*                                .format("hh:mm a")}{" "}*/}
      {/*                                                                    to{" "}*/}
      {/*                                                                    {moment(i.endTime)*/}
      {/*                                                                        .zone(0)*/}
      {/*                                                                        .format("hh:mm a")}*/}
      {/*                          </span>*/}
      {/*                                                                /!* <span className="service-offer-text">{i?.name}</span> *!/*/}
      {/*                                                            </Col>*/}
      {/*                                                        );*/}
      {/*                                                    })}*/}
      {/*                                                </Row>*/}
      {/*                                            </Col>*/}
      {/*                                            {paymentDetails?.statusType == 2 &&*/}
      {/*                                            <Col xl={24} style={{marginTop: '20px'}}>*/}
      {/*                                                <span className="profile-detail-title">*/}
      {/*                                                  Map*/}
      {/*                                                </span>*/}
      {/*                                                <Row className="mt-3 mb-3">*/}
      {/*                                                    <Col xl={24}>*/}
      {/*                                                        {isLoaded &&*/}
      {/*                                                        <ClientGoogleMap paymentDetails={paymentDetails}*/}
      {/*                                                                         isLoaded={isLoaded} google={google}*/}
      {/*                                                                         locationDetails={locationDetails}/>*/}
      {/*                                                        }*/}
      {/*                                                    </Col>*/}
      {/*                                                </Row>*/}
      {/*                                            </Col>}*/}
      {/*                                            <Col xl={24} style={{marginTop: '20px'}}>*/}
      {/*                  <span className="profile-detail-title">*/}
      {/*                    Additional Comments*/}
      {/*                  </span>*/}
      {/*                                                <Row className="mt-3 mb-3">*/}
      {/*                    <span className="profile-detail-title">*/}
      {/*                      {paymentDetails?.additionalNote*/}
      {/*                          ?.additionComments != ""*/}
      {/*                          ? paymentDetails?.additionalNote*/}
      {/*                              ?.additionComments*/}
      {/*                          : "N/A"}*/}
      {/*                    </span>*/}
      {/*                                                </Row>*/}
      {/*                                            </Col>*/}

      {/*                                            {paymentDetails?.statusType == 6 &&*/}
      {/*                                            <Col xl={24} className="mt-3">*/}
      {/*                                                <img alt="" src={paymentDetails?.doorImage} width={200}*/}
      {/*                                                     height={150}/>*/}
      {/*                                            </Col>*/}
      {/*                                            }*/}
      {/*                                            {paymentDetails?.expenses?.length > 0 &&*/}
      {/*                                            <Col xs={24} className="mt-3">*/}

      {/*                                                <p className="mt-2 mb-3">*/}
      {/*                                                    Expenses*/}
      {/*                                                </p>*/}
      {/*                                                <ExpensesItems Items={paymentDetails} isMobile={isMobile}/>*/}
      {/*                                            </Col>}*/}
      {/*                                        </Row>*/}
      {/*                                        <Row className="pt-10">*/}
      {/*                                            <Col xl={24}>*/}
      {/*                                                <div className="" style={{justifyContent: 'space-between'}}>*/}
      {/*                                                    <p className="mt-2 fw-semibold fs-3 mb-3">*/}
      {/*                                                        Booking Confirmation*/}
      {/*                                                    </p>*/}
      {/*                                                    <p*/}
      {/*                                                        className="mt-3 profile-detail-title d-flex align-items-center"*/}
      {/*                                                        style={{*/}
      {/*                                                            fontSize: "16px",*/}
      {/*                                                            // marginRight: "70px",*/}
      {/*                                                            // marginLeft:'2rem'*/}
      {/*                                                        }}*/}
      {/*                                                    >*/}
      {/*                                                        {moment(paymentDetails?.bookedAt)*/}
      {/*                                                            .zone(0)*/}
      {/*                                                            .format("MMMM DD, YYYY HH:MM a")}*/}
      {/*                                                    </p>*/}
      {/*                                                </div>*/}
      {/*                                            </Col>*/}
      {/*                                        </Row>*/}
      {/*                                        <Row>*/}
      {/*                                            <Col xl={24}>*/}
      {/*                                                <div className="amount-wrapper booking-confirm"*/}
      {/*                                                     style={{height: '280px', padding: '54px'}}>*/}
      {/*                                                    <div*/}
      {/*                                                        className="d-flex justify-content-between align-items-center"*/}
      {/*                                                        style={{width: '100%'}}>*/}
      {/*                                                        <p className="price-title">Transaction ID</p>*/}
      {/*                                                        <p className="price-value">*/}
      {/*                                                            {paymentDetails?.transactionId}*/}
      {/*                                                        </p>*/}
      {/*                                                    </div>*/}
      {/*                                                    <div*/}
      {/*                                                        className="d-flex justify-content-between align-items-center">*/}
      {/*                                                        <p className="price-title">Payment Type</p>*/}
      {/*                                                        <p className="price-value">*/}
      {/*                                                            {paymentDetails?.paymentMethod ||*/}
      {/*                                                            "Credit Card"}*/}
      {/*                                                        </p>*/}
      {/*                                                    </div>*/}
      {/*                                                    <Divider/>*/}
      {/*                                                    <div*/}
      {/*                                                        className="d-flex justify-content-between align-items-center">*/}
      {/*                                                        <p className="price-title">Caregiver Price</p>*/}
      {/*                                                        <p className="price-value">*/}
      {/*                                                            ${paymentDetails?.caregiverActualPrice}*/}
      {/*                                                        </p>*/}
      {/*                                                    </div>*/}
      {/*                                                    <div*/}
      {/*                                                        className="d-flex justify-content-between align-items-center">*/}
      {/*                                                        <p className="price-title">Additional Fee &*/}
      {/*                                                            Taxes</p>*/}
      {/*                                                        <p className="price-value">*/}
      {/*                                                            {`(${paymentDetails?.additionalTaxPercentage || 0}%)`} ${paymentDetails?.additionalTaxAmount}*/}
      {/*                                                        </p>*/}
      {/*                                                    </div>*/}
      {/*                                                    <div*/}
      {/*                                                        className="d-flex justify-content-between align-items-center total">*/}
      {/*                                                        <p className="price-title">Total Amount</p>*/}
      {/*                                                        <p className="price-value">*/}
      {/*                                                            ${paymentDetails?.totalAmount}*/}
      {/*                                                        </p>*/}
      {/*                                                    </div>*/}
      {/*                                                </div>*/}
      {/*                                            </Col>*/}
      {/*                                        </Row>*/}
      {/*                                    </div>*/}
      {/*                                </div>*/}
      {/*                            </Spin>*/}
      {/*                        </div>*/}
      {/*                        {paymentDetails?.statusType == 6 ? (*/}
      {/*                            <div className="d-flex text-right " style={{justifyContent: "right"}}>*/}
      {/*                                <Button*/}
      {/*                                    // htmlType="submit"*/}
      {/*                                    // onClick={() => handleCancelModalVisible(paymentDetails?._id)}*/}
      {/*                                    // disabled={paymentDetails?.statusType == 5}*/}
      {/*                                    style={*/}
      {/*                                        paymentDetails?.isReviewed ? {opacity: 0.6} : {}*/}
      {/*                                    }*/}
      {/*                                    disabled={paymentDetails?.isReviewed}*/}
      {/*                                    onClick={handleGiveReviewsModal}*/}
      {/*                                    className="btn-back auth-action-btn mt-3 mb-3 mx-3"*/}
      {/*                                    shape="round"*/}
      {/*                                    size="large"*/}
      {/*                                >*/}
      {/*                                    Review*/}
      {/*                                </Button>*/}
      {/*                                <Button*/}
      {/*                                    // htmlType="submit"*/}
      {/*                                    // onClick={() => navigate("/client/appointment")}*/}
      {/*                                    // disabled={!selectedCard}*/}
      {/*                                    onClick={handleChooseRelative}*/}
      {/*                                    className="btn-black auth-action-btn mt-3 mb-3"*/}
      {/*                                    shape="round"*/}
      {/*                                    type="primary"*/}
      {/*                                    size="large"*/}
      {/*                                    style={{whiteSpace: 'break-spaces'}}*/}
      {/*                                >*/}
      {/*                                    Schedule Again*/}
      {/*                                </Button>*/}
      {/*                            </div>*/}
      {/*                        ) : (*/}
      {/*                            paymentDetails?.statusType != 4 && (*/}
      {/*                                <div*/}
      {/*                                    className="d-flex text-right  mt-4"*/}
      {/*                                    style={{justifyContent: "right"}}*/}
      {/*                                >*/}
      {/*                                    <Button*/}
      {/*                                        // htmlType="submit"*/}
      {/*                                        onClick={() =>*/}
      {/*                                            handleCancelModalVisible(paymentDetails?._id)*/}
      {/*                                        }*/}
      {/*                                        style={*/}
      {/*                                            paymentDetails?.statusType == 5 ||*/}
      {/*                                            paymentDetails?.statusType == 3 ||*/}
      {/*                                            paymentDetails?.statusType == 4*/}
      {/*                                                ? {opacity: 0.5}*/}
      {/*                                                : {}*/}
      {/*                                        }*/}
      {/*                                        disabled={*/}
      {/*                                            paymentDetails?.statusType == 5 ||*/}
      {/*                                            paymentDetails?.statusType == 3 ||*/}
      {/*                                            paymentDetails?.statusType == 4*/}
      {/*                                        }*/}
      {/*                                        className="btn-back auth-action-btn mt-3 mb-3 mx-3"*/}
      {/*                                        shape="round"*/}
      {/*                                        size="large"*/}
      {/*                                    >*/}
      {/*                                        Cancel*/}
      {/*                                    </Button>*/}
      {/*                                    <Button*/}
      {/*                                        // htmlType="submit"*/}
      {/*                                        onClick={() =>*/}
      {/*                                            navigate("/client/appointment/messages")*/}
      {/*                                        }*/}
      {/*                                        disabled={paymentDetails?.statusType == 1}*/}
      {/*                                        className="btn-black auth-action-btn mt-3 mb-3"*/}
      {/*                                        shape="round"*/}
      {/*                                        type="primary"*/}
      {/*                                        size="large"*/}
      {/*                                    >*/}
      {/*                                        Message*/}
      {/*                                    </Button>*/}
      {/*                                </div>*/}
      {/*                            )*/}
      {/*                        )}*/}
      {/*                    </div>*/}
      {/*                </div>*/}
      {/*            </div>*/}

      {/*        </div>*/}
      {/*    </div>*/}
      {/*) : (*/}
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex profile-details-wrapper">
          <Sidebar />
          <div className="mx-4 w-100 main-content">
            <AppointmentNavBar />
            <div
              style={{
                background: "#fff",
                padding: "15px 25px",
                marginTop: "20px",
              }}
              className="border-less card-div caregiver-appointment-row-main mt-mob-0 rounded"
            >
              <div
                className="max-height-auto"
                style={
                  (paymentDetails?.statusType === 6 ||
                    paymentDetails?.statusType !== 4) &&
                  userMode === "client"
                    ? {
                        height: "calc(100vh - 300px)",
                        overflow: "auto",
                      }
                    : { height: "calc(100vh - 220px)", overflow: "auto" }
                }
              >
                <div>
                  {/* <div className="d-flex justify-content-between">
                                    <p className='mt-2 fw-semibold fs-4 mb-3'>Booking Confirmation</p>
                                    {/* <p className="mt-2 mb-3 profile-detail-title" style={{fontSize: '16px',}}>{moment(bookingDetails?.bookedAt).zone(0).format("MMMM DD, YYYY HH:MM a")}</p> */}
                  {/* </div> */}
                  <Spin
                    spinning={loading}
                    // tip="loading..."
                  >
                    <div
                      className={`payment-wrapper appointment-wrapper`}
                      style={{ width: "100%" }}
                    >
                      <Row>
                        <Col xl={24} lg={24}>
                          <Row>
                            <Col xl={24} md={24} lg={24} xs={24}>
                              <Row>
                                <Col
                                  className="max-height-auto appointment-details-top"
                                  xl={12}
                                  lg={12}
                                  md={16}
                                  xs={24}
                                  style={{ maxHeight: "100px" }}
                                >
                                  <img
                                    alt=""
                                    style={{ borderRadius: "50px" }}
                                    src={
                                      !paymentDetails?.caregiverImage
                                        ? "/images/icons/Profile.png"
                                        : paymentDetails?.caregiverImage
                                    }
                                    width={60}
                                    height={60}
                                    onClick={() => {
                                      if (paymentDetails?.caregiverImage) {
                                        setImagePopupVisible(true);
                                      }
                                    }}
                                  />
                                  <div className="username d-flex">
                                    <p className="fw-bold">
                                      {paymentDetails?.caregiverName}
                                    </p>
                                    {/* {caregiverDetails?.data?.isProfileUpdated && */}
                                    <img
                                      src="/images/icons/verified.svg"
                                      alt="verification"
                                      width={15}
                                      style={{
                                        position: "relative",
                                        bottom: "6px",
                                        left: "8px",
                                      }}
                                    />
                                    <Button
                                      className="caregiver-btn"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {paymentDetails?.caregiverType == 3
                                        ? "Caregiver"
                                        : paymentDetails?.caregiverType == "4"
                                        ? "Companion"
                                        : ""}
                                    </Button>

                                    <img
                                      className="bookmark-button mx-2"
                                      src={`/images/icons/${
                                        paymentDetails?.isBookmarked
                                          ? "bookmark-filled"
                                          : "bookmark-icon"
                                      }.svg`}
                                      width={20}
                                      height={20}
                                      onClick={BookmarkByid}
                                      alt="bookmark"
                                      // width={20}
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                        top: "1px",
                                      }}
                                    />
                                    {/* } */}
                                  </div>
                                  {paymentDetails?.ratingCount == 0 ? (
                                    <p
                                      className="rating-review-details-inner"
                                      style={{
                                        left: "70px",
                                        bottom: "60px",
                                        position: "relative",
                                        color: "#9ea5ab",
                                        marginTop: "5px",
                                        marginBottom: 0,
                                      }}
                                    >
                                      No Ratings & Reviews
                                    </p>
                                  ) : (
                                    <div
                                      className="responsive-data-caregiver"
                                      style={{
                                        left: "70px",
                                        bottom: "60px",
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="d-flex rating-review-details-inner">
                                        <img
                                          alt=""
                                          src="/images/icons/star-rating.svg"
                                          width={16}
                                        />
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            position: "relative",
                                            right: "2px",
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            color: "#0F172A",
                                          }}
                                          onClick={() => {
                                            if (
                                              paymentDetails?.ratingCount != 0
                                            ) {
                                              handleReviewModalVisible();
                                            }
                                          }}
                                        >
                                          {paymentDetails?.caregiverRating}{" "}
                                          &nbsp;
                                          <span style={{ fontWeight: 500 }}>
                                            {" "}
                                            ({paymentDetails?.ratingCount}{" "}
                                            reviews)
                                          </span>
                                        </span>
                                      </div>
                                      <div className="d-flex appointment-inner location-details-div pb-mob-10">
                                        <img
                                          alt=""
                                          src="/images/icons/location.svg"
                                          width={15}
                                        />
                                        <span
                                          style={{
                                            marginLeft: "8px",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#0F172A",
                                            position: "relative",
                                            right: "2px",
                                          }}
                                        >
                                          {/* {paymentDetails?.statusType == 1 ||
                                      paymentDetails?.statusType == 4 ||
                                      paymentDetails?.statusType == 3
                                        ?  */}
                                          {`${paymentDetails?.caregiverAddress?.city}, ${paymentDetails?.caregiverAddress?.province}`}
                                          {/* //   : `${paymentDetails?.caregiverAddress?.address}
                                      // , ${paymentDetails?.caregiverAddress?.city}
                                      // , ${paymentDetails?.caregiverAddress?.province}, ${paymentDetails?.caregiverAddress?.postalCode}`} */}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </Col>
                                <Col
                                  xl={12}
                                  lg={12}
                                  md={8}
                                  xs={24}
                                  className="d-flex justify-content-right max-height-auto app-confirm"
                                  style={{
                                    justifyContent: "right",
                                    maxHeight: "100px",
                                  }}
                                >
                                  {/* <img className="bookmark-btn" src="/images/icons/bookmark-icon.svg" width={30} height={30} /> */}
                                  {paymentDetails?.statusType == 1 ? (
                                    <Button className="pending">Pending</Button>
                                  ) : paymentDetails?.statusType == 5 ? (
                                    <Button className="ongoing">Ongoing</Button>
                                  ) : paymentDetails?.statusType == 6 ? (
                                    <Button className="completed">
                                      Completed
                                    </Button>
                                  ) : paymentDetails?.statusType == 4 ? (
                                    <Button className="cancelled">
                                      Cancelled
                                    </Button>
                                  ) : paymentDetails?.statusType == 3 ? (
                                    <Button className="declined">
                                      Declined
                                    </Button>
                                  ) : paymentDetails?.statusType == 2 ? (
                                    <Button className="upcoming">
                                      Upcoming
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  {/* <Button className="role-btn">{paymentDetails?.caregiverType == 3 ? 'Caregiver' : paymentDetails?.caregiverType == '4' ? 'Companion' : ''}</Button> */}
                                </Col>
                              </Row>
                              <Row className="profile-app-center">
                                <Col
                                  className="mb-mob-0"
                                  lg={24}
                                  xl={24}
                                  md={24}
                                  sm={24}
                                  xs={24}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <span className="profile-detail-title">
                                    Booked For
                                  </span>
                                </Col>
                                <Col xs={24} md={24} sm={24} lg={24} xl={4}>
                                  <Row className="mob-d-flex">
                                    <Col
                                      lg={24}
                                      xl={24}
                                      xs={24}
                                      className="d-flex align-items-center my-3-iPad mt-3-mob"
                                    >
                                      {paymentDetails?.relativeImage ? (
                                        <img
                                          alt=""
                                          style={{ borderRadius: "50px" }}
                                          src={paymentDetails?.relativeImage}
                                          width={40}
                                          height={40}
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          style={{ borderRadius: "50px" }}
                                          width={40}
                                          height={40}
                                          src="/images/icons/Profile.png"
                                        />
                                      )}
                                      <div className="mx-2">
                                        <p className="fw-bold mb-1">
                                          {paymentDetails?.relativeName}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                <Divider
                                  type="vertical"
                                  style={{ height: "40px" }}
                                />
                                <Col lg={24} xl={5} xs={24}>
                                  <div
                                    className="d-flex address-details-row"
                                    style={{
                                      position: "relative",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src="/images/icons/location.svg"
                                      width={15}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "11px",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                        position: "relative",
                                        top: "-4px",
                                      }}
                                    >
                                      {
                                        paymentDetails?.relativeAddressAddress
                                          ?.address
                                      }
                                      ,{" "}
                                      {
                                        paymentDetails?.relativeAddressAddress
                                          ?.city
                                      }
                                      ,{" "}
                                      {
                                        paymentDetails?.relativeAddressAddress
                                          ?.province
                                      }
                                      ,{" "}
                                      {
                                        paymentDetails?.relativeAddressAddress
                                          ?.postalCode
                                      }
                                    </span>
                                  </div>
                                </Col>
                                <Divider
                                  type="vertical"
                                  style={{ height: "40px" }}
                                />
                                <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                  <Row className="time-details-row">
                                    <Col xs={8} lg={7} md={7} xl={7}>
                                      <div
                                        className="d-flex right-mob-0 justify-flex-start-mob"
                                        style={{
                                          right: "20px",
                                          position: "relative",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src="/images/icons/time.svg"
                                          width={20}
                                        />
                                        <span
                                          style={{
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "22px",
                                            marginLeft: "11px",
                                          }}
                                        >
                                          {paymentDetails?.totalHour} Hours
                                        </span>
                                      </div>
                                    </Col>
                                    <Divider
                                      type="vertical"
                                      style={{ height: "40px", right: "20px" }}
                                    />
                                    <Col xs={8} lg={7} md={7} xl={7}>
                                      <span className="profile-detail-title">
                                        Hourly Rate
                                      </span>
                                      <p className="profile-detail-val">
                                        ${paymentDetails?.caregiverActualPrice}
                                      </p>
                                    </Col>
                                    <Divider
                                      type="vertical"
                                      style={{ height: "40px" }}
                                    />
                                    <Col xs={8} lg={7} md={7} xl={7}>
                                      <span className="profile-detail-title">
                                        Total
                                      </span>
                                      <p className="profile-detail-val">
                                        ${paymentDetails?.totalAmount}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Divider type="horizontal" />
                            </Col>
                          </Row>
                          <div
                            className="max-height-auto"
                            style={{
                              overflow: "auto",
                              maxHeight:
                                paymentDetails?.statusType !== 4 ||
                                paymentDetails?.statusType != 6 ||
                                paymentDetails?.statusType != 5
                                  ? "46vH"
                                  : paymentDetails?.statusType === 4
                                  ? "45vH"
                                  : "45vH",
                            }}
                          >
                            <Row>
                              <Col xl={24} lg={24} xs={24} className="mb-3">
                                <span className="profile-detail-title">
                                  Services
                                </span>
                                <Row className="mt-3 mt-mob-0 service-appointment-row">
                                  {paymentDetails?.serviceList?.map((i) => {
                                    return (
                                      <Col
                                        xl={4}
                                        lg={8}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="mt-2 service-appointment-col"
                                      >
                                        <div className="w-100 d-flex service-offer-card">
                                          <img
                                            alt=""
                                            src={i.image}
                                            width={50}
                                            height={50}
                                          />
                                          <span className="service-offer-text">
                                            {i?.name}
                                          </span>
                                        </div>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                              <Col xl={24} lg={24} xs={24}>
                                <span className="profile-detail-title">
                                  Date and Time
                                </span>
                                <Row
                                  className={`mt-3 mt-mob-0 service-appointment-row ${
                                    paymentDetails?.statusType == 6
                                      ? "completed-appointment"
                                      : ""
                                  }`}
                                >
                                  {paymentDetails?.timing?.map((i) => {
                                    return (
                                      <Col
                                        xl={4}
                                        lg={8}
                                        md={8}
                                        sm={8}
                                        xs={12}
                                        className="service-appointment-col mt-2"
                                      >
                                        <div
                                          onClick={() =>
                                            fetchAppointmentDetails(i._id)
                                          }
                                          className={`w-100 d-flex service-offer-card ${
                                            i?.statusType == 4
                                              ? "cancelled-appointment"
                                              : ""
                                          } ${
                                            i?._id == getHighlightedID()
                                              ? "ongoing-appointment"
                                              : ""
                                          } ${
                                            i?.statusType == 6
                                              ? "completed-appointment"
                                              : ""
                                          }`}
                                        >
                                          <div className="date-card">
                                            {moment(i.date).format("MMM")}{" "}
                                            <span
                                              style={{
                                                fontWeight: 700,
                                                marginTop: "-5px",
                                              }}
                                            >
                                              {moment(i.date)
                                                .utc()
                                                .format("DD")}
                                            </span>
                                          </div>
                                          <span className="time-text">
                                            {moment(i.startTime)
                                              .zone(0)
                                              .format("hh:mm a")}{" "}
                                            to{" "}
                                            {moment(i.endTime)
                                              .zone(0)
                                              .format("hh:mm a")}
                                          </span>
                                        </div>
                                        {/* <span className="service-offer-text">{i?.name}</span> */}
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Col>
                              {(paymentDetails?.statusType == 2 ||
                                paymentDetails?.statusType == 5) && (
                                <Col
                                  xl={24}
                                  lg={24}
                                  xs={24}
                                  style={{ marginTop: "20px" }}
                                >
                                  <span className="profile-detail-title">
                                    Map
                                  </span>
                                  <Row className="mt-3 mb-3">
                                    <Col xl={24} lg={24} xs={24}>
                                      {isLoaded && (
                                        <ClientGoogleMap
                                          paymentDetails={paymentDetails}
                                          isLoaded={isLoaded}
                                          google={google}
                                          locationDetails={locationDetails}
                                        />
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                              <Col
                                xl={24}
                                lg={24}
                                xs={24}
                                style={{ marginTop: "20px" }}
                              >
                                <span className="profile-detail-title">
                                  Additional Comments
                                </span>
                                <Row className="mt-3 mb-3">
                                  <span className="profile-detail-title">
                                    {paymentDetails?.additionalNote
                                      ?.additionComments != ""
                                      ? paymentDetails?.additionalNote
                                          ?.additionComments
                                      : "N/A"}
                                  </span>
                                </Row>
                              </Col>

                              {paymentDetails?.statusType == 6 && (
                                <Col
                                  xl={24}
                                  lg={24}
                                  xs={24}
                                  className="mt-3 text-left-mobile"
                                >
                                  <img
                                    alt=""
                                    src={paymentDetails?.doorImage}
                                    width={200}
                                    height={200}
                                  />
                                </Col>
                              )}
                              {paymentDetails?.expenses?.length > 0 && (
                                <Col xl={24} lg={24} xs={24} className="mt-3">
                                  <p className="mt-2 fw-semibold fs-6 mb-3">
                                    Expenses
                                  </p>
                                  <ExpensesItems Items={paymentDetails} />
                                </Col>
                              )}
                            </Row>
                            <Row>
                              <Col xl={12} lg={24} md={24} xs={24}>
                                <div className="d-flex justify-content-between booking-confirm-div">
                                  <p className="mt-2 fw-semibold fs-4 mb-3 booking-confirm-text">
                                    Booking Confirmation
                                  </p>
                                  <p
                                    className="mt-2 profile-detail-title booking-confirm-time d-flex align-items-center"
                                    style={{
                                      fontSize: "16px",
                                      marginRight: "80px",
                                    }}
                                  >
                                    {moment(paymentDetails?.bookedAt)
                                      .zone(0)
                                      .format("MMMM DD, YYYY HH:MM a")}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={12} lg={24} md={24} xs={24}>
                                <div
                                  className="amount-wrapper booking-confirm"
                                  style={{ height: "250px" }}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Transaction ID
                                    </p>
                                    <p className="price-value">
                                      {paymentDetails?.transactionId}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">Payment Type</p>
                                    <p className="price-value">
                                      {paymentDetails?.paymentMethod ||
                                        "Credit Card"}
                                    </p>
                                  </div>

                                  <Divider />
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Caregiver Rate
                                    </p>
                                    <p className="price-value">
                                      ${paymentDetails?.caregiverActualPrice}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">Total Hours</p>
                                    <p className="price-value">
                                      {paymentDetails?.totalHour || 0} Hours
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <p className="price-title">
                                      Taxes{" "}
                                      {`(${
                                        paymentDetails?.additionalTaxPercentage ||
                                        0
                                      }%)`}
                                    </p>
                                    <p className="price-value">
                                      ${paymentDetails?.additionalTaxAmount}
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center total">
                                    <p className="price-title">Total Amount</p>
                                    <p className="price-value">
                                      ${paymentDetails?.totalAmount}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </div>
              </div>
              {paymentDetails?.statusType == 6 ? (
                <div
                  className="d-flex details-footer-bottom details-footer-bottom-appointment text-right px-3"
                  style={{ justifyContent: "right" }}
                >
                  <Button
                    // htmlType="submit"
                    // onClick={() => handleCancelModalVisible(paymentDetails?._id)}
                    // disabled={paymentDetails?.statusType == 5}
                    style={paymentDetails?.isReviewed ? { opacity: 0.6 } : {}}
                    disabled={paymentDetails?.isReviewed}
                    onClick={handleGiveReviewsModal}
                    className="btn-back appointment-cancel-btn auth-action-btn mt-3 mb-3 mx-3"
                    shape="round"
                    size="large"
                  >
                    Review
                  </Button>
                  <Button
                    // htmlType="submit"
                    // onClick={() => navigate("/client/appointment")}
                    // disabled={!selectedCard}
                    onClick={handleChooseRelative}
                    className="btn-black appointment-schedule-again-btn auth-action-btn mt-3 mb-3"
                    shape="round"
                    type="primary"
                    size="large"
                  >
                    Schedule Again
                  </Button>
                </div>
              ) : (
                paymentDetails?.statusType != 4 && (
                  <div
                    className="d-flex details-footer-bottom-appointment text-right px-3 mt-4"
                    style={{ justifyContent: "right" }}
                  >
                    <Button
                      // htmlType="submit"
                      onClick={() =>
                        handleCancelModalVisible(paymentDetails?._id)
                      }
                      style={
                        paymentDetails?.statusType == 5 ||
                        paymentDetails?.statusType == 3 ||
                        paymentDetails?.statusType == 4
                          ? { opacity: 0.5 }
                          : {}
                      }
                      disabled={
                        paymentDetails?.statusType == 5 ||
                        paymentDetails?.statusType == 3 ||
                        paymentDetails?.statusType == 4
                      }
                      className="btn-back appointment-cancel-btn auth-action-btn mt-3 mb-3 mx-3"
                      shape="round"
                      size="large"
                    >
                      Cancel
                    </Button>
                    <Button
                      // htmlType="submit"
                      onClick={() => navigate("/client/appointment/messages")}
                      disabled={paymentDetails?.statusType == 1}
                      className="btn-black appointment-message-btn auth-action-btn mt-3 mb-3"
                      shape="round"
                      type="primary"
                      size="large"
                    >
                      Message
                    </Button>
                  </div>
                )
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*)}*/}
      <RatingModal
        reviewModalVisible={reviewModalVisible}
        handleRatingModalClose={handleRatingModalClose}
        caregiverDetails={paymentDetails}
      />
      <CancelModel
        paymentDetails={paymentDetails}
        cancelModalVisible={cancelModalVisible}
        handleCancelModalClose={handleCancelModalClose}
        handleCancelAppointment={handleCancelAppointment}
        loading={loading}
        userMode={userMode}
      />
      <GiveReviewsModal
        caregiverDetails={paymentDetails}
        giveReviewsVisible={giveReviewsVisible}
        handleGiveReviewsClose={handleGiveReviewsClose}
        handleReviewSuccessVisible={handleReviewSuccessVisible}
      />
      <ReviewSuccessModal
        reviewSuccessVisible={reviewSuccessVisible}
        handleReviewSuccessClose={handleReviewSuccessClose}
      />
      <ImageModal
        imagePopupVisible={imagePopupVisible}
        handleClosePopup={() => setImagePopupVisible(false)}
        caregiverImage={paymentDetails?.caregiverImage}
      />
    </div>
  );
}

const GiveReviewsModal = (props) => {
  const [ratingsArr, setRatingsArr] = useState(blankStarArr);
  const [ratingCount, setRatingCount] = useState(0);
  const [feedback, setFeedback] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const appointmentID = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    giveReviewsVisible,
    handleGiveReviewsClose,
    caregiverDetails,
    handleReviewSuccessVisible,
  } = props;

  const handleFillRatings = (id) => {
    const filledArr = ratingsArr?.map((star) => {
      if (star.id <= id) {
        return { ...star, type: "filled" };
      }
      return { ...star, type: "blank" };
    });
    setRatingCount(id);
    setRatingsArr(filledArr);
  };

  const handleReviewSubmit = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/add-review`,
          {
            text: feedback,
            rating: ratingCount,
            appointmentId: appointmentID?.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            const obj = { ...caregiverDetails, isReviewed: true };
            dispatch(newPaymentDetails(obj));
            handleGiveReviewsClose();
            handleReviewSuccessVisible();
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          //   setPayUnsuccessVisible(true);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  return (
    <Modal
      className="give-reviews"
      centered
      width={300}
      header={null}
      footer={null}
      open={giveReviewsVisible}
      onOk={handleGiveReviewsClose}
      onCancel={handleGiveReviewsClose}
    >
      <div className="give-reviews-wrapper text-center">
        <h3 className="give-reviews-wrapper-title">Write Review</h3>
        <div className="profile">
          <img
            className="profile-pic"
            src={
              !caregiverDetails?.caregiverImage
                ? "/images/icons/Profile.png"
                : caregiverDetails?.caregiverImage
            }
            alt="caregiver profile picture"
            width={65}
          />
          <h4 className="profile-name">
            {caregiverDetails?.caregiverName}{" "}
            <span>
              <img
                className="verified-icon"
                src="/images/icons/verified.svg"
                alt="verified"
                width={13}
              />
            </span>
          </h4>
        </div>
        <div className="d-flex text-center justify-content-between align-items-center star-wrapper">
          {ratingsArr?.map((i) => {
            return i.type == "blank" ? (
              <img
                src="/images/icons/blank-star.svg"
                className="text-center"
                onClick={() => handleFillRatings(i.id)}
                width={25}
              />
            ) : (
              <img
                src="/images/icons/filled-star.svg"
                className="text-center"
                onClick={() => handleFillRatings(i.id)}
                width={25}
              />
            );
          })}
        </div>
        <div>
          <TextArea
            className="give-review-input"
            placeholder="Type Here"
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <Button
            className="reviews-cancel-btn btn-back mt-3 mx-2"
            shape="round"
            size="large"
            onClick={handleGiveReviewsClose}
          >
            Cancel
          </Button>
          <Button
            className="reviews-submit-btn btn-black mt-3"
            shape="round"
            type="primary"
            size="large"
            onClick={handleReviewSubmit}
            disabled={!ratingCount}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ReviewSuccessModal = (props) => {
  const { reviewSuccessVisible, handleReviewSuccessClose } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={350}
      header={null}
      footer={null}
      open={reviewSuccessVisible}
      onOk={handleReviewSuccessClose}
      onCancel={handleReviewSuccessClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div className="image-container">
        <img src="/images/icons/success.svg" className="text-center" />
      </div>
      <h3 className="mt-3 fw-semibold fs-4 text-center">
        Review Submitted Successfully
      </h3>
      {/* <p className='text-center mt-3'>Lorem ipsum dolor sit amet, consectetur incididunt adipiscing elit</p> */}
      <Button
        // htmlType="submit"
        onClick={() => {
          handleReviewSuccessClose();
        }}
        className="btn-black mx-auto auth-action-btn mt-3"
        shape="round"
        type="primary"
        size="large"
      >
        Done
      </Button>
    </Modal>
  );
};

export const RatingModal = (props) => {
  const { reviewModalVisible, handleRatingModalClose, caregiverDetails } =
    props;
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [ratingsDetails, setRatingsDetails] = useState(null);
  const [starRating, setStarRating] = useState(blankStarArr);
  const [loading, setLoading] = useState(false);

  const fetchAllReviews = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-review`,
          {
            page: 1,
            pageLimit: 5,
            caregiverId: caregiverDetails?.caregiverId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            const checkFilledArr = starRating?.map((star) => {
              if (star.id <= response.data.output?.averageRating) {
                return { ...star, type: "filled" };
              }
              return { ...star, type: "blank" };
            });
            setRatingsDetails(response.data?.output);
            setStarRating(checkFilledArr);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAllReviews();
  }, []);
  return (
    <Modal
      className="review-modal-container"
      centered
      width={500}
      header={null}
      footer={null}
      open={reviewModalVisible}
      onOk={handleRatingModalClose}
      onCancel={handleRatingModalClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div className="no-rating-container text-center">
        <h1 className="rating-num">{caregiverDetails?.caregiverRating}</h1>
        <div className="d-flex justify-content-center align-items-center star-wrapper">
          {starRating?.map((i) => {
            return i.type == "blank" ? (
              <img
                alt={""}
                src="/images/icons/blank-star.svg"
                className="text-center mx-2"
                width={25}
              />
            ) : (
              <img
                alt={""}
                src="/images/icons/filled-star.svg"
                className="text-center mx-2"
                width={25}
              />
            );
          })}
        </div>

        <span className="review-count-msg">
          Based on {caregiverDetails?.ratingCount} reviews
        </span>
        {ratingsDetails?.list?.length > 0 ? (
          <div className="reviewsListing">
            {ratingsDetails.list.map((rating) => {
              return (
                <div className="client-wrapper">
                  <div className="client-details d-flex justify-content-between">
                    <p className="client-name">{rating?.clientName}</p>
                    <p className="rating-date">
                      {moment(rating?.reviewedAt).format("MMMM DD, YYYY")}
                    </p>
                  </div>
                  <div className="d-flex ratings-star-wrapper">
                    {[1, 2, 3, 4, 5].map((i) => {
                      if (i <= rating?.rating) {
                        return (
                          <img
                            src="/images/icons/filled-star.svg"
                            className="text-center mx-1"
                            width={15}
                          />
                        );
                      }
                      return (
                        <img
                          src="/images/icons/blank-star.svg"
                          className="text-center mx-1"
                          width={15}
                        />
                      );
                    })}
                  </div>
                  <p className="client-feedback">{rating?.text}</p>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div className="no-reviews-wrapper">
              <img src="/images/icons/no-rating.svg" className="text-center" />
            </div>
            <span className="no-review-txt">No Reviews</span>
          </>
        )}
      </div>
    </Modal>
  );
};

const ClientGoogleMap = (props) => {
  const { isLoaded, paymentDetails, google, locationDetails } = props;
  const [generatedPath, setGeneratedPath] = useState(null);
  const [GmapStatus, setGmapStatus] = useState("");
  const [coordinatesArr, setCoordinatesArr] = useState([]);
  const [updatedLocDetails, setUpdatedLocDetails] = useState(null);

  useEffect(() => {
    if (locationDetails?.lat && locationDetails?.lon) {
      const obj = {
        lat: parseFloat(locationDetails?.lat),
        lng: parseFloat(locationDetails?.lon),
      };
      setUpdatedLocDetails(obj);
    }
  }, [locationDetails?.lat, locationDetails?.lon]);

  // locationDetails?._id
  // ?
  // {
  //   lat: parseFloat(locationDetails?.lat),
  //   lng: parseFloat(locationDetails?.lon),
  // };
  // : {
  //     lat: parseFloat(paymentDetails?.caregiverAddress?.location?.lat),
  //     lng: parseFloat(paymentDetails?.caregiverAddress?.location?.lon),
  //   };
  useEffect(() => {
    if (paymentDetails && GmapStatus?.status != "OK") {
      pathGeneration();
    }
  }, [paymentDetails, google, updatedLocDetails?.lat, GmapStatus?.status]);
  console.log(locationDetails, "coordinatesArr");
  useEffect(() => {
    console.log(updatedLocDetails, coordinatesArr, "updatedLocDetails");
    const clonedArr = [...coordinatesArr];
    let shortestDistanceItem = null;
    clonedArr.forEach((item, index) => {
      let distance = getPreciseDistance(
        { latitude: item.lat, longitude: item.lng },

        {
          latitude: updatedLocDetails.lat,

          longitude: updatedLocDetails.lng,
        }
      );

      if (!shortestDistanceItem || distance < shortestDistanceItem?.distance) {
        shortestDistanceItem = {
          distance,

          latitude: item.lat,

          longitude: item.lng,

          index,
        };
      }
    });
    const modifiedArr = clonedArr.map((i) => [i.lat, i.lng]);
    let isDriverInPolyline = isPointInPolygon(
      {
        latitude: updatedLocDetails?.lat,
        longitude: updatedLocDetails?.lng,
      },
      modifiedArr
    );
    if (!isDriverInPolyline && shortestDistanceItem?.distance > 30) {
      setGmapStatus((prev) => ({ ...prev, status: "" }));
    } else {
      console.log(shortestDistanceItem, clonedArr, "shortestDistanceItem");
      clonedArr.splice(0, shortestDistanceItem?.index);
      // const filteredArr = clonedArr.filter(
      //   (coord) => updatedLocDetails?.lat >= coord.lat
      // )
      setCoordinatesArr(clonedArr);
    }
  }, [updatedLocDetails?.lat, paymentDetails]);

  const pathGeneration = async () => {
    // eslint-disable-next-line no-undef
    console.log("directionapi");
    const totalDistance = getDistance(
      { latitude: updatedLocDetails?.lat, longitude: updatedLocDetails?.lng },

      {
        latitude: parseFloat(
          paymentDetails?.relativeAddressAddress?.location?.lat
        ),
        longitude: parseFloat(
          paymentDetails?.relativeAddressAddress?.location?.lon
        ),
      }
    );
    console.log(totalDistance, "totalDistance");
    if (totalDistance > 60) {
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService?.route({
        origin: updatedLocDetails,
        optimizeWaypoints: true,
        destination: {
          lat: parseFloat(
            paymentDetails?.relativeAddressAddress?.location?.lat
          ),
          lng: parseFloat(
            paymentDetails?.relativeAddressAddress?.location?.lon
          ),
        }, // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      let polyPointsItems = [];
      let finalPolyItems = [];
      results?.routes[0]?.legs[0]?.steps?.forEach((item) => {
        let decodedPolylineItems = polyline.decode(item.polyline.points);
        decodedPolylineItems.forEach((locItem) => {
          const [lat, lng] = locItem;
          polyPointsItems.push({ lat, lng });
        });
      });

      for (let index = 0; index < polyPointsItems.length; index++) {
        const element1 = polyPointsItems[index];
        const element2 = polyPointsItems[index + 1];

        if (element1 && element2) {
          let wayPath = makeWayPoint(element1, element2);
          finalPolyItems.push(element1, ...wayPath);
        }
      }
      setCoordinatesArr(finalPolyItems);
      if (locationDetails?._id) {
        setGmapStatus(results);
      } else {
        setGmapStatus({ ...results, status: "" });
      }
    }
    // setGeneratedPath(results);
  };

  const makeWayPoint = (origin, destination) => {
    // Calculate the distance between origin and destination.
    const totalDistance = getDistance(
      { latitude: origin.lat, longitude: origin.lng },

      { latitude: destination.lat, longitude: destination.lng }
    );

    // console.log(totalDistance);

    // Define a maximum distance between waypoints (e.g., 50 meters).

    const maxWaypointDistance = 20;

    // Calculate the number of waypoints needed.

    const numWaypoints = Math.ceil(totalDistance / maxWaypointDistance);

    // Generate waypoints along the path.

    // Generate waypoints along the path.

    const waypoints = [];

    for (let i = 1; i <= numWaypoints; i++) {
      const fraction = i / (numWaypoints + 1);

      const intermediatePoint = computeDestinationPoint(
        { latitude: origin.lat, longitude: origin.lng },

        totalDistance * fraction,

        getRhumbLineBearing(
          { latitude: origin.lat, longitude: origin.lng },

          { latitude: destination.lat, longitude: destination.lng }
        )
      );

      waypoints.push({
        lat: intermediatePoint.latitude,
        lng: intermediatePoint.longitude,
      });
    }

    return waypoints;
  };

  const style = {
    maxWidth: "100%",
    height: "300px",
    overflowX: "hidden",
    overflowY: "hidden",
  };
  const containerStyle = {
    maxWidth: "100%",
    height: "300px",
  };
  console.log(coordinatesArr, "coordinatesArr");
  return isLoaded ? (
    <GoogleMap
      style={style}
      center={updatedLocDetails}
      zoom={22}
      mapContainerStyle={containerStyle}
      mapContainerClassName="gmap-wrapper"
      options={{
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        minZoom: 4,
        maxZoom: 22,
      }}
    >
      <Marker
        position={updatedLocDetails}
        icon={"/images/icons/bird-icon.svg"}
        width={20}
      />
      <Marker
        position={{
          lat: parseFloat(
            paymentDetails?.relativeAddressAddress?.location?.lat
          ),
          lng: parseFloat(
            paymentDetails?.relativeAddressAddress?.location?.lon
          ),
        }}
        icon={"/images/icons/location-icon.svg"}
      />
      <Polyline
        path={coordinatesArr}
        options={{ strokeColor: "#364bc6", strokeWeight: 10 }}
      />
      {/* {generatedPath && (
        <DirectionsRenderer
          directions={generatedPath}
          options={{
            suppressMarkers: true,
          }}
        />
      )} */}
    </GoogleMap>
  ) : (
    <div>
      <Spin spinning={isLoaded} />
    </div>
  );
};

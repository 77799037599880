import { useEffect, useMemo, useState } from "react";
import {
  Menu,
  Popover,
  Space,
  Row,
  Typography,
  Col,
  Dropdown,
  Modal,
  Button,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CloseOutlined,
  LeftOutlined,
  MenuOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { CLEAR_DATA } from "../redux/auth/auth.type";
import SubMenu from "antd/lib/menu/SubMenu";
import moment from "moment";
import { setAlert } from "../redux/alert/alert.action";
import axios from "axios";
import { saveAllNotification } from "../redux/profileDetails/profile.action";
import { EditOutlined, BankOutlined, LockOutlined } from "@ant-design/icons";
import { toggleChooseRelativeModal } from "../redux/modals/toggleModal.actions";
import { selectedCC } from "../redux/appointment/appointment.action";
import DeleteAccount from "./navbar/deleteaccount";
import LogoutModal from "./navbar/LogoutModal";
import "./Responsiveheader.less";
import commonApi from "../http/commonApi";
import { Logout } from "../redux/auth/auth.action";
import _ from "lodash";
import Confirmation from "./Modal/common/confirmation";
const { Title } = Typography;

const { Text } = Typography;
function Responsiveheader({ title }) {
  const appointmentData = useSelector((state) => state.appointment);
  const userData = useSelector((state) => state.loginData?.user);
  const { paymentDetails } = appointmentData;
  const [popupVisible, setPopupVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [handleLogoutModal, setHandleLogoutModal] = useState();
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const [handleDelete, setHandleDelete] = useState(false);
  const [current, setCurrent] = useState("1");
  const token = localStorage.getItem("token");
  const [notificationVisible, setnotificationVisible] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const [notificatonLoader, setnotificationLoader] = useState(false);
  const isUnreadNotification = useMemo(() => {
    return notificationsList.find((notification) => !notification.isRead);
  }, [notificationsList]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const handleNotificatios = (notification) => {
    setnotificationVisible(true);
    NotificationRedirection(notification);
    if (!notification.isRead) {
      setNotificationsList(
        _.map(notificationsList, (item) => {
          if (item.id === notification.id) {
            return {
              ...item,
              isRead: true,
            };
          } else {
            return item;
          }
        })
      );
      readNotification(notification);
    }
  };
  const handleLogoutClose = () => {
    setHandleLogoutModal(false);
  };
  const readNotification = async (notification) => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/read-notification`,
          {
            id: notification.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
          } else if (response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  const deleteNotification = async () => {
    setnotificationLoader(true);
    setopenModal(false);
    const res = await commonApi
      ._deleteNotification({ type: "all" })
      .catch((err) => {
        setnotificationLoader(false);
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
    if (res.status === 200) {
      setNotificationsList([]);
      setnotificationLoader(false);
    } else {
      setnotificationLoader(false);
    }
  };

  const deleteByNotifications = async (id) => {
    setopenModal(false);
    setnotificationLoader(true);
    const res = await commonApi
      ._deleteByNotification({
        type: "multiple",
        notificationIds: [id],
      })
      .catch((err) => {
        setnotificationLoader(false);
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
    if (res.status === 200) {
      setNotificationsList(
        _.reject(notificationsList, (item) => {
          return item.id === id;
        })
      );
      setnotificationLoader(false);
    } else {
      setnotificationLoader(false);
    }
  };
  useEffect(() => {
    Notifications();
  }, [page]);

  const Notifications = async () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-notification`,
          {
            page: page,
            pageLimit: 10,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setNotificationsList(response.data.output.list);
            dispatch(saveAllNotification(response.data.output.list));
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const NotificationRedirection = (notification) => {
    setnotificationVisible(false);
    switch (notification?.notificationType) {
      case "Appointment":
        {
          // navigate(`/${userMode}/appointments-dashboard/`)
          navigate(
            `/client/appointment/appointment-details/${notification.appointmentId}`
          );
        }
        break;
      case "Expense":
        {
          navigate("/client/expenses");
        }
        break;
      case "Review":
        {
          navigate("/dashboard/my-reviews");
        }
        break;
      default: {
      }
    }
  };

  const caregiverMenus = [
    {
      id: 1,
      route: "/dashboard/my-reviews",
      icon: "menu-star.svg",
      title: "Ratings & Review",
    },
    {
      id: 2,
      route: "/caregiver/edit-availability",
      icon: "clock.svg",
      title: "Availability",
    },
    {
      id: 3,
      route: "/caregiver/delete-account",
      icon: "delete-contact.svg",
      title: "Delete Account",
    },
  ];

  const clientMenus = [
    {
      id: 1,
      route: "/dashboard/my-caregivers",
      icon: "menu-star.svg",
      title: "My Caregivers",
    },
    {
      id: 2,
      route: "/client/relatives",
      icon: "clock.svg",
      title: "My Care Recipients",
    },
    // {
    //   id: 3,
    //   route: "/client/my-schedule",
    //   icon: "calendar.svg",
    //   title: "My Schedule",
    // },
    { id: 4, route: "", icon: "delete-contact.svg", title: "Delete Account" },
  ];
  const clientSettings = [
    {
      id: 1,
      route: "/client/settings/editprofile",
      icon: <EditOutlined />,
      title: "Edit Profile",
    },
    {
      id: 2,
      route: "/client/settings/changepassword",
      icon: <LockOutlined />,
      title: "Change Password",
    },
    {
      id: 3,
      route: "/client/settings/bankinformation",
      icon: <BankOutlined />,
      title: "Bank Information",
    },
  ];
  const caregiverSettings = [
    {
      id: 1,
      route: "/caregiver/settings/editprofile",
      icon: <EditOutlined />,
      title: "Edit Profile",
    },
    {
      id: 2,
      route: "/caregiver/settings/changepassword",
      icon: <LockOutlined />,
      title: "Change Password",
    },
    {
      id: 3,
      route: "/caregiver/settings/bankinformation",
      icon: <BankOutlined />,
      title: "Bank Information",
    },
  ];
  const handleAppointmentsNavigation = () => {
    if (userMode == "client") {
      navigate("/client/appointments-dashboard");
    } else {
      navigate("/caregiver/appointments-dashboard");
    }
  };

  const handleDashboardNavigation = () => {
    if (userMode == "client") {
      navigate("/client/appointments-dashboard");
    } else {
      navigate("/caregiver/dashboard");
    }
  };

  const handleMessagesNavigation = () => {
    if (userMode == "client") {
      navigate("/client/messages");
    } else {
      navigate("/caregiver/messages");
    }
  };

  const handleExpensesNavigation = () => {
    if (userMode == "client") {
      navigate("/client/expenses");
    } else {
      navigate("/caregiver/expenses");
    }
  };

  const handleSchedulesNavigation = () => {
    if (userMode == "client") {
      navigate("/client/schedule");
    } else {
      navigate("/caregiver/schedule");
    }
  };

  const handlePopupVisible = () => {
    setPopupVisible(!popupVisible);
  };

  const NotificationsContent = (
    <div className="NotificationsContent">
      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "10px" }}
      >
        <CloseOutlined onClick={() => setnotificationVisible(false)} />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4>Notifications</h4>
        </div>
        <div>
          {notificationsList.length > 0 ? (
            <h4
              onClick={() => {
                setopenModal(true);
                setnotificationVisible(false);
              }}
              style={{ color: "#364BC6", cursor: "pointer" }}
            >
              Clear{" "}
            </h4>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <Divider/>   */}
      {notificationsList && notificationsList?.length > 0 ? (
        notificationsList?.map((item, key) => {
          return (
            <>
              {item ? (
                <Row
                  className="Notifications"
                  style={{
                    background: !item.isRead && "#E7E8EA",
                    borderRadius: "3px",
                  }}
                  onClick={() => handleNotificatios(item)}
                >
                  <Col span={4}>
                    {item.notificationType === "Appointment" ||
                    item.notificationType === "Expense" ? (
                      <div style={{ paddingTop: "15px", marginLeft: "6px" }}>
                        <img
                          src="/images/icons/calender_N.png"
                          alt="user"
                          style={{ height: "35px", width: "35px" }}
                        />
                      </div>
                    ) : (
                      <>
                        <div style={{ paddingTop: "15px" }}>
                          <img
                            src="/images/icons/Review.jpeg"
                            alt="user"
                            style={{ height: "35px", width: "35px" }}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                  <Col span={18}>
                    <Row>
                      <Text className="TextNotifications">
                        {item.notificationTitle}
                      </Text>
                    </Row>
                    <div style={{ textAlign: "right", marginTop: "-7px" }}>
                      <Text className="NotificationTime">
                        {moment().isSame(item.updatedAt, "day")
                          ? moment
                              .utc(item.updatedAt)
                              .local()
                              .local()
                              .format("hh:mm A")
                          : moment
                              .utc(item.updatedAt)
                              .local()
                              .format("DD/MM/YYYY")}
                      </Text>
                    </div>
                  </Col>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CloseOutlined
                      style={{ marginLeft: "15px" }}
                      onClick={() => deleteByNotifications(item.id)}
                    />
                  </div>
                  {/* <Divider/> */}
                </Row>
              ) : (
                <div className="notification-image">
                  <img src="/images/icons/notications-img.png" alt="user" />
                  <h4>No notications</h4>
                </div>
              )}
            </>
          );
        })
      ) : notificatonLoader ? (
        <>
          <div className="notification-image">
            <Spin style={{ marginLeft: "50px" }} />
            {/* <h4>Loading ...</h4> */}
          </div>
        </>
      ) : !notificatonLoader ? (
        <div className="notification-image">
          <img src="/images/icons/notications-img.png" alt="user" />
          <h4>No Notifications</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );

  const handleLogout = () => {
    dispatch(Logout());
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  const menu = (
    <Menu
      onClick={onClick}
      mode="inline"
      forceSubMenuRender
      inlineCollapsed={false}
      style={{ width: "97.5vw" }}
    >
      <SubMenu
        style={{ width: "97.5vw", borderRadius: "0" }}
        theme="dark"
        key="2"
        title={
          <div
            onClick={() => setPopupVisible(!popupVisible)}
            className="d-flex"
          >
            {" "}
            <span
              className="d-flex"
              style={{ fontSize: "22px", paddingLeft: "22px" }}
            >
              {userData?.firstName + " " + userData?.lastName}
            </span>{" "}
            <img
              src="/images/icons/down-arrow.svg"
              style={
                popupVisible
                  ? { transform: "rotate(180deg)" }
                  : { transform: "rotate(360deg)" }
              }
            />
          </div>
        }
      >
        {userMode == "client"
          ? clientMenus.map((item) => {
              return (
                <Menu.Item
                  theme="dark"
                  style={{ width: "97.5vw", borderRadius: "0", top: "10.5rem" }}
                  key={item.title}
                  onClick={() => {
                    if (item.id === 4) {
                      setHandleDelete(true);
                    } else {
                      dispatch({ type: CLEAR_DATA });
                      navigate(item.route);
                    }
                  }}
                >
                  <img src={`/images/icons/${item.icon}`} width={20} />
                  <span className="mx-3">{item.title}</span>
                </Menu.Item>
              );
            })
          : caregiverMenus.map((item) => {
              return (
                <Menu.Item
                  theme="dark"
                  key={item.title}
                  style={{ width: "97.5vw", borderRadius: "0" }}
                  onClick={() => {
                    navigate(item.route);
                  }}
                >
                  <img src={`/images/icons/${item.icon}`} width={20} />
                  <span className="mx-3">{item.title}</span>
                </Menu.Item>
              );
            })}
      </SubMenu>

      {userMode == "caregiver" && (
        <Menu.Item onClick={handleDashboardNavigation}>
          <span>Dashboard</span>
        </Menu.Item>
      )}
      <Menu.Item onClick={handleAppointmentsNavigation}>
        <span>Appointments</span>
      </Menu.Item>

      <Menu.Item onClick={handleMessagesNavigation}>
        <span>Messages</span>
      </Menu.Item>

      <Menu.Item onClick={handleExpensesNavigation}>
        <span>Expenses</span>
      </Menu.Item>
      <Menu.Item onClick={handleSchedulesNavigation}>
        <span>My Calendar</span>
      </Menu.Item>

      {userMode == "caregiver" ? (
        <Menu.Item onClick={() => navigate("/caregiver/earnings")}>
          <span>Total Earnings</span>
        </Menu.Item>
      ) : null}

      {/* <Menu.Item
        onClick={() => {
          if (userMode == "caregiver") {
            navigate("/caregiver/settings");
          } else {
            navigate("/client/settings");
          }
        }}
      >
        <span>Settings</span>
      </Menu.Item> */}
      <SubMenu
        key="3"
        title={
          <div
            onClick={handlePopupVisible}
            style={{
              fontSize: "22px",
              width: "97.5vw",
              borderRadius: "0",
              top: "90rem",
              paddingLeft: "22px",
            }}
          >
            Settings{" "}
            <img
              src="/images/icons/down-arrow.svg"
              style={popupVisible ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        }
      >
        {userMode == "client"
          ? clientSettings.map((item) => {
              return (
                <Menu.Item
                  key={item.title}
                  style={{ width: "97.5vw", borderRadius: "0" }}
                  onClick={() => {
                    dispatch({ type: CLEAR_DATA });
                    navigate(item.route);
                  }}
                >
                  {item.icon}
                  <span className="mx-3">{item.title}</span>
                </Menu.Item>
              );
            })
          : caregiverSettings.map((item) => {
              return (
                <Menu.Item
                  key={item.title}
                  style={{ width: "97.5vw", borderRadius: "0" }}
                  onClick={() => navigate(item.route)}
                >
                  {item.icon}
                  <span className="mx-3">{item.title}</span>
                </Menu.Item>
              );
            })}
      </SubMenu>
      <Menu.Item
        onClick={() => {
          if (userMode == "caregiver") {
            navigate("/caregiver/view-tutorials");
          } else {
            navigate("/client/view-tutorials");
          }
        }}
      >
        <span>View Tutorials</span>
      </Menu.Item>
      <Menu.Item key="1" className="menu-items">
        <Popover
          placement="bottomRight"
          content={NotificationsContent}
          trigger="click"
        >
          Notifications
        </Popover>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          if (userMode == "caregiver") {
            navigate("/caregiver/contactus");
          } else {
            navigate("/client/contactus");
          }
        }}
      >
        <span>Contact Us</span>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setHandleLogoutModal(true);
          setIsOpen(false);
        }}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );
  const handleChooseRelative = () => {
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...paymentDetails, scheduleAgain: false }));
  };

  const handleOpen = (e) => {
    setIsOpen(!isOpen);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const motion = {
    motionName: "ant-dropdown-slide-down",
    onAppearStart: () => console.log("onAppearStart"),
    onAppearActive: () => console.log("onAppearActive"),
    onAppearEnd: () => console.log("onAppearEnd"),
    onEnterStart: () => console.log("onEnterStart"),
    onEnterActive: () => console.log("onEnterActive"),
    onEnterEnd: () => console.log("onEnterEnd"),
    onLeaveStart: () => console.log("onLeaveStart"),
    onLeaveActive: () => console.log("onLeaveActive"),
    onLeaveEnd: () => console.log("onLeaveEnd"),
  };

  const handleVisible = (visible) => {
    setIsOpen(visible);
  };

  return (
    <>
      <div className="appointment-nav-container">
        <div className="d-flex justify-content-between align-items-center">
          <span className="d-flex ms-0" style={{ fontWeight: "bold" }}>
            <img width="230" src="/images/full-logo.svg" alt="logo" />
          </span>
          <div className="d-flex">
            {userMode == "client" && (
              <img
                src="/images/icons/light-outline-plus.svg"
                onClick={handleChooseRelative}
              />
            )}
            <Dropdown
              motion={motion}
              className="ps-2"
              overlay={menu}
              placement="bottomRight"
              style={{ fontSize: "22px!important" }}
              trigger={["click"]}
              onVisible={(visible) => setIsOpen(visible)}
              open={isOpen}
              forceRender
            >
              <div className={`icon-container ${isOpen ? "open" : "closed"}`}>
                {isOpen ? (
                  <CloseOutlined
                    style={{ fontSize: "24px", marginLeft: "1rem" }}
                    onClick={handleClose}
                  />
                ) : (
                  <MenuOutlined
                    style={{ fontSize: "24px", marginLeft: "1rem" }}
                    onClick={handleOpen}
                  />
                )}
              </div>
            </Dropdown>
          </div>
          <Confirmation
            open={openModal}
            isDelete={deleteNotification}
            onclose={() => {
              setopenModal(false);
            }}
          />
        </div>

        <span
          className="d-flex ms-0 header-title"
          style={{
            fontWeight: "500",
            fontSize: "22px",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          {title}
        </span>
      </div>
      <DeleteAccount
        setHandleDelete={setHandleDelete}
        handleDelete={handleDelete}
        setLoading={setLoading}
      />

      <LogoutModal
        handleLogoutModal={handleLogoutModal}
        setHandleLogoutModal={setHandleLogoutModal}
      />
    </>
  );
}

export default Responsiveheader;

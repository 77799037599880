import React from "react";

import "./ScrollWrapper.less";

const ScrollWrapper = ({ height, children }) => {
  const wrapperStyle = {
    minWidth:'100%',
    height: height,
    overflow: "auto",
    scrollbarWidth: "thin",
  };

  return (
    <div style={wrapperStyle} className="scroll-wrapper">
      {children}
    </div>
  );
};

export default ScrollWrapper;

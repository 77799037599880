import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Typography,
} from "antd";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { fetchBankDetails } from "../../../../redux/profileDetails/profile.action";
import { setAlert } from "../../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
import Responsiveheader from "../../../../components/Responsiveheader";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import AppointmentNavBar from "../../../client/appointment/appointmentsNavBar";

const BankingInformation = ({ accessToken }) => {
  const token = localStorage.getItem("token");
  const NUMERIC_REGEX = /^[0-9]+$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [showTnc, setShowTnc] = useState(false);
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  // const [dataDisabledSave, setDataDisabledSave] = useState(true);
  const [getBankDetails, setGetBankDetails] = useState("");
  const [loading, setLoading] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const caregiverBankDetails = useSelector(
    (state) => state?.profileDetails?.getBankDetails?.output?.bankDetails
  );
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  useEffect(() => {
    console.log("form", form);
    console.log("elements", elements);
    dispatch(fetchBankDetails(token)).then((res) => {
      console.log(res);
      if (res.data.output.bankDetails != null) {
        // setDataDisabledSave(false);
        form.setFieldsValue(caregiverBankDetails);
        if (caregiverBankDetails) {
          form.setFieldValue("bankMandatoryCheck", true);
          setDisabledSave(false);
        }
      }
    });
  }, [caregiverBankDetails?.id]);

  const handleFormChange = (changedFields, allFields) => {
    const foundCheckField = changedFields.find((n) =>
      n.name.includes("bankMandatoryCheck")
    );
    if (!foundCheckField) {
      form.setFieldValue("bankMandatoryCheck", false);
    }
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    console.log("hasErrors", hasErrors);
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("values", values);
    generateBankToken(values);
  };

  const onFinishFailed = (error) => {
    console.log("Failed:", error);
  };

  const validation = (rule, value, callback) => {
    if (value) {
      return callback();
    }
    return callback("This field is required");
  };

  const generateBankToken = async (event) => {
    // event.preventDefault();
    if (elements == null) {
      return;
    }
    setShowTnc(true);
    try {
      console.log("account number is", form.getFieldValue("accountNum"));
      const token = await stripe?.createToken("bank_account", {
        bank_account: {
          country: "CA",
          currency: "cad",
          bank_name: form.getFieldValue("bankName"),
          account_holder_type: "individual",
          routing_number:
            String(form.getFieldValue("transitNumber")) +
            String(form.getFieldValue("institutionNumber")),
          account_number: form.getFieldValue("accountNumber"),
        },
      });
      if (token.error) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: token.error.message,
            alertOpen: true,
          })
        );
      }
      console.log("token", token);
      console.log("form.getFieldsValue()", form.getFieldsValue());
      console.log("stripe", stripe);
      const addBankPayload = {
        token: token.token.id,
        ...form.getFieldsValue(),
      };
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/add-bank-account`,
          addBankPayload,
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "success",
                alertMsg: " updated data successfully",
                alertOpen: true,
              })
            );
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    } catch (err) {
      setLoading(false);
      if (
        err?.response?.data?.status >= 400 &&
        err?.response?.data?.status !== 401
      ) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: err?.response?.data?.error?.message,
            alertOpen: true,
          })
        );
      }
    }
  };

  const handleInfoModal = () => {
    setInfoModalVisible(true);
  };

  return (
    <>
      <div className="container settings-editprofile bankdetails appointment-dashboard-cP">
        {isMobile ? <AppointmentNavBar /> : ""}
        <div className="caregiver-profile-settings-card">
          <h4
            style={{ textAlign: "start", padding: " 0px 0px 20px 0px" }}
            className="color-secondary"
          >
            Stripe requires your banking details to direct deposit your earnings
            on a weekly basis.
            {/* <img alt={''}
                             src="/images/icons/info-circle.svg"
                             width={20}
                             style={{margin: '0 0 5px 10px'}}
                            // className="info-icon-tip"
                             onClick={handleInfoModal}
                        /> */}
          </h4>
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={handleFormChange}
            form={form}
            hideRequiredMark
          >
            <Form.Item
              name={"bankName"}
              label="Bank Name"
              rules={[
                { required: true, message: "Bank Name is required" },
                {
                  pattern: new RegExp(/^[a-zA-Z\s]*$/),
                  message: "Numbers and Special Characters Not Allowed",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Bank Name"
                type={"string"}
                style={{ width: "50%" }}
                className="bankinput"
              />
            </Form.Item>
            <Form.Item
              name={"transitNumber"}
              label="Transit Number"
              rules={[
                { required: true, message: "Transit Number is required" },
              ]}
            >
              <Input
                size="large"
                maxLength={5}
                minLength={5}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Transit Number"
                style={{ width: "50%" }}
                className="bankinput"
              />
            </Form.Item>
            <Form.Item
              name={"institutionNumber"}
              label="Institution Number"
              rules={[
                { required: true, message: "Institution Number is required" },
              ]}
            >
              <Input
                size="large"
                minLength={3}
                maxLength={3}
                placeholder="Institution Number"
                style={{ width: "50%" }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className="bankinput"
              />
            </Form.Item>
            <Form.Item
              name={"accountNumber"}
              label="Account Number"
              rules={[
                { required: true, message: "Account Number is required" },
                // {
                //   // max: 12,
                //   max: 13,
                //   message: "Value should be less than 16 character",
                // },
              ]}
            >
              <Input
                size="large"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                minLength={7}
                maxLength={13}
                placeholder="Account Number"
                style={{ width: "50%" }}
                className="bankinput"
              />
            </Form.Item>
            <Form.Item
              className="bank-acknowledge"
              name={"bankMandatoryCheck"}
              rules={[{ validator: validation }]}
              valuePropName="checked"
              requiredMark={false}
              label="When you render services, taxes are not automatically deducted from the earnings you accrue over the course of the year. It is your responsibility to ensure compliance with all tax obligations imposed by your jurisdiction."
            >
              <Checkbox />
            </Form.Item>
            <Button
              className="btn-black  auth-action-btn save-btn-bank-info  float-end  "
              // onClick={generateBankToken}
              disabled={disabledSave}
              style={{
                position: "fixed",
                right: "100px",
                bottom: "105px",
              }}
              htmlType="submit"
              shape="round"
              size="large"
            >
              Save
            </Button>
          </Form>
        </div>
      </div>
      {/* <BankingInfoModal infoModalVisible={infoModalVisible}
                              handleInfoModalClose={() => setInfoModalVisible(false)}/> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  accessToken: state?.loginData?.user?.accessToken,
});

export const BankingInfoModal = (props) => {
  const { infoModalVisible, handleInfoModalClose } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      footer={null}
      open={infoModalVisible}
      onOk={handleInfoModalClose}
      onCancel={handleInfoModalClose}
      closeIcon={<CloseCircleOutlined style={{ color: "black" }} />}
    >
      <div className="image-container">
        <img src="/images/icons/info-group.svg" className="text-center" />
      </div>

      <p className="text-center mt-3" style={{ color: "#000" }}>
        This allows stripe to directly deposit your earnings into your selected
        bank account.
      </p>
      <div className="image-container">
        <img
          src="/images/banking-info.png"
          className="text-center banking-info-img"
        />
      </div>
      <Button
        onClick={() => {
          handleInfoModalClose();
        }}
        className="btn-black mx-auto auth-action-btn mt-3 Done_btn"
        shape="round"
        type="primary"
        size="large"
      >
        I understand
      </Button>
    </Modal>
  );
};

export default connect(mapStateToProps)(BankingInformation);

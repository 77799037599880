import { Button } from 'antd'
import React from 'react'
import MySchedule from "../components/mySchedule";
import "./styles/dashboard.less";

function Dashboard() {
  const Logout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  return (
    <div
      className="border border-dark w-100 ms-4 dashboard"
      style={{ background: "#fff" }}
    >
      <div className="main-container">Dashboard</div>
      <MySchedule />
    </div>
  );
}

export default Dashboard
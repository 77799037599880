import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import CardDetails from "./card-details";
import ProfileDetails from "./profile-details";
import AddRelatives from "./add-relatives";
import "./style.less";
import { connect } from "react-redux";

const Index = () => {
  const [current, setCurrent] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Account Details",
      content: <ProfileDetails next={next} prev={prev} />,
    },
    {
      title: "Card Details",
      content: <CardDetails next={next} prev={prev} />,
    },
    {
      title: "Add Care Recipient(s)",
      content: <AddRelatives next={next} prev={prev} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="container p-md-4 profilecont"
      style={{ maxWidth: "1000px" }}
    >
      <div
        className="mx-auto custom-steps-create-profile"
        style={{ maxWidth: "660px", overflowX: "hidden" }}
      >
        {isMobile ? (
          ""
        ) : (
          <Steps
            className="mb-3 pb-3"
            current={current}
            labelPlacement="vertical"
            items={items}
          />
        )}
      </div>
      <div className="steps-content profile-details">
        {steps[current].content}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.loginData.user,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

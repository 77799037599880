
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;
const OverrideMonth = (props) => {
    const { open, onClose } = props;
    return (
      <Modal
        className="info-modal-container"
        centered
        width={390}
        header={null}
        footer={null}
        open={open}
        onOk={onClose}
        onCancel={onClose}
        closable 
        closeIcon={<CloseCircleOutlined />}
      >
        {/* <div className="Close-container">
        <CloseOutlined      onClick={() => {
            onClose();
            // navigate("/client/appointment/booking-confirmation");
          }}
              
              />
        </div> */}
        <div className="image-container">
          <img src="/images/icons/info-group.svg" className="text-center" />
        </div>
      
        <p className="text-center mt-3">
        The appointments can only be scheduled for 3 months in advance
        </p>
        <Button
          // htmlType="submit"
          onClick={() => {
            onClose();
            // navigate("/client/appointment/booking-confirmation");
          }}
          className="btn-black mx-auto auth-action-btn mt-3"
          shape="round"
          type="primary"
          size="large"
        >
          I understand
        </Button>
      </Modal>
    );
  };

  export default OverrideMonth
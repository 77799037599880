import React from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";

const BookingCompletedModal = ({ bookingCompletionVisible, handleClose }) => {
  return (
    <>
      <Modal
        className="info-modal-container bookingConfirmaedModal"
        centered
        width={572}
        header={null}
        footer={null}
        open={bookingCompletionVisible}
        onOk={handleClose}
        onCancel={handleClose}
        closable
        closeIcon={<img src="/images/icons/close-icon-gray.svg" alt={""} />}
      >
        <div className="image-container">
          <img
            alt={""}
            src="/images/booking-confirm-image.svg"
            className="text-center"
          />
        </div>
        <div className="text-center booking-complete-details">
          <h2 style={{fontWeight:'700'}}>Booking Completed</h2>
          <p style={{width:'100%'}}>
            <b style={{color:'#000000'}}>Download the App</b>
            <br/>
            To be notified when the caregiver accepts your booking request and
            to use our direct messaging and live tracking features, please
            download our mobile app
          </p>
        </div>
        <div className="download-app-button">
          <Link style={{marginRight:'15px'}} to="https://apps.apple.com/us/app/mf-homecare/id6443815250">
              <img alt={""} src="/images/icons/app-store-icon.svg" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.homecare.seasons&pli=1">
              <img alt={""} src="/images/icons/play-store-icon.svg" />
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default BookingCompletedModal;

import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "./appointmentsNavBar";
import { Button, Col, Modal, Row, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/alert/alert.action";
import "./service-grid.less";
import moment from "moment";
import {
  bookAppointment,
  selectedCC,
} from "../../../redux/appointment/appointment.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function ChooseCareGivers(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [caregiverList, setCaregiverList] = useState([]);
  const [noDataModalVisible, setNoDataModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const appointmentData = useSelector((state) => state.appointment);
  const slotsArr = [];
  appointmentData?.dateTimeResp.slot.forEach((i) => {
    console.log(i, "available");
    const date = moment(i.date, "YYYY/MM/DD");
    const startTimeObj =
      date.format("YYYY-MM-DD") +
      "T" +
      moment(i.time?.startTime).utc(true).format("HH") +
      ":" +
      moment(i.time?.startTime).utc(true).format("mm") +
      `:00.000Z`;
    let endTimeObj =
      date.format("YYYY-MM-DD") +
      "T" +
      moment(i.time?.endTime).utc(true).format("HH") +
      ":" +
      moment(i.time?.endTime).utc(true).format("mm") +
      `:00.000Z`;
    if (i?.isOverNight) {
      endTimeObj =
        date.format("YYYY-MM") +
        "-" +
        moment(date).add(1, "days").format("DD") +
        "T" +
        moment(i.time?.endTime).utc(true).format("HH") +
        ":" +
        moment(i.time?.endTime).utc(true).format("mm") +
        `:00.000Z`;
    }
    const obj = {
      date: date.format("YYYY-MM-DD") + "T00:00:00.000Z",
      time: { startTime: startTimeObj, endTime: endTimeObj },
    };
    // const startTime = i.time?.startTime;
    // const endTime = i.time?.endTime;
    // const startTimeObj = moment(startTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
    // const endTimeObj = moment(endTime).set({'year': moment(date).year(), 'month': moment(date).month(),'day':moment(date).date()}).utc(true).format("YYYY-MM-DDTHH:mm:ss.000[Z]");
    slotsArr.push(obj);
  });
  const token = localStorage.getItem("token");

  console.log(appointmentData, "user");
  const fetchCaregiverCompanions = () => {
    console.log(
      appointmentData?.servicesResp.checkedCaregiver,
      appointmentData?.servicesResp.checkedCompanions,
      "checkedchekced"
    );
    const checkedCaregiver =
      appointmentData?.servicesResp.checkedCaregiver &&
      appointmentData?.servicesResp.checkedCaregiver.length > 0
        ? appointmentData?.servicesResp.checkedCaregiver
        : [];
    const checkedCompanions =
      appointmentData?.servicesResp.checkedCompanions &&
      appointmentData?.servicesResp.checkedCompanions.length > 0
        ? appointmentData?.servicesResp.checkedCompanions
        : [];

    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/available-caregiver`,
          {
            services: [...checkedCaregiver, ...checkedCompanions],
            slot: slotsArr,
            relativeId: JSON.parse(localStorage.getItem("relative"))?._id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setCaregiverList(response.data.output.list);
            if (response.data.output.list?.length == 0) {
              setNoDataModalVisible(true);
            }
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.Appointments.concat({
          title: "New Appointment",
          route: `appointment-details`,
        })
      )
    );
  }, []);
  const handleBookingAppointment = (e, caregiver) => {
    e.stopPropagation();
    console.log(caregiver, "caregiver");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/book-appointment`,
          {
            services: appointmentData?.selectedCC?.scheduleAgain
              ? []
              : [
                  ...appointmentData?.servicesResp.checkedCaregiver,
                  ...appointmentData?.servicesResp.checkedCompanions,
                ],
            caregiverId: caregiver?._id,
            slot: slotsArr,
            relativeId: JSON.parse(localStorage.getItem("relative"))?._id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            dispatch(bookAppointment(response.data.output));
            dispatch(selectedCC(caregiver));
            navigate("/client/appointment/pay-for-appointment");
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchCaregiverCompanions();
  }, []);

  //   const handleResize = () => {
  //   setIsMobile(window.innerWidth < 768);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{isMobile?<>*/}

      {/* <div className="p-3 pt-4 d-flex w-100">*/}
      {/*     <Responsiveheader title="New Appointment " />*/}
      {/*   </div>*/}
      {/*   <div className='p-3 border-less rounded mx-3' style={{background:"#fff", minHeight: "80vh"}}>*/}
      {/*    <div>*/}
      {/*     <Spin */}
      {/*     spinning={loading} */}
      {/*     tip="loading...">*/}
      {/*     <div className="caregivers-wrapper d-flex" style={{width: "100%",flexWrap: "wrap"}}>*/}
      {/*         {caregiverList.length > 0 ?*/}
      {/*         caregiverList.map(i => {*/}
      {/*             return (*/}
      {/*                 <div className="caregivers-card ms-3 mt-3 flex-row-reverse" onClick={() => navigate(`/client/appointment/create-appointment/view-caregiver/${i?._id}`)}>*/}
      {/*                     <div className="profile-wrapper text-center">*/}
      {/*                     <img className="service-img" src={i?.image} */}
      {/*                     width={90}*/}
      {/*                     height={90}*/}
      {/*                     style={{objectFit: "cover"}}*/}
      {/*                     />*/}
      {/*                     <p className="fw-bold">{i?.firstName} {i?.lastName} </p>*/}
      {/*                     <Button className="ant-btn-primary person-title">{i?.userType == 3 ? 'Caregiver' : i?.userType == '4' ? 'Companion' : ''}</Button>*/}
      {/*                     </div>*/}

      {/*                     <Row style={{justifyContent:'space-around'}}>*/}
      {/*                       <Col xl={12}>*/}
      {/*                       <div className="d-flex" style={{    display: "flex", justifyContent: "space-evenly"}}>*/}
      {/*                             <img src="/images/icons/star-rating.svg" width={20} />*/}
      {/*                             <span className="fw-bold" style={{position: "relative", right: "2px", fontWeight: 700, fontSize: "16px"}}>{i?.caregiverRating}</span>*/}
      {/*                         </div>*/}
      {/*                       </Col>*/}
      {/*                       <Col xl={12}>*/}
      {/*                       <div className="d-flex" style={{    display: "flex", justifyContent: "space-evenly"}}>*/}
      {/*                       <span className="fw-bold" style={{fontWeight: 700, fontSize: "16px"}}>${i?.expectedRate} </span>*/}
      {/*                         </div>*/}
      {/*                       </Col>*/}
      {/*                       */}
      {/*                     </Row>*/}
      {/*                     <Row style={{justifyContent:'space-around'}}>*/}
      {/*                       <Col xl={12}>*/}
      {/*                       <div className="d-flex" style={{    display: "flex", justifyContent: "space-evenly"}}>*/}
      {/*                       <span className="review-title">Hourly rate</span>*/}
      {/*                         </div>*/}
      {/*                       </Col>*/}
      {/*                       <Col xl={12}>*/}
      {/*                       <div className="d-flex" style={{    display: "flex", justifyContent: "space-evenly"}}>*/}
      {/*                       <span className="review-title">{i?.ratingCount} Reviews</span>*/}
      {/*                       /!* <span className="fw-bold" style={{fontWeight: 700, fontSize: "16px"}}>${i?.expectedRate} </span> *!/*/}
      {/*                         </div>*/}
      {/*                       </Col>*/}
      {/*                       */}
      {/*                     </Row>*/}
      {/*                     /!* <div className="d-flex justify-content-center align-items-center ratings-card mt-2">*/}
      {/*                     <div className="w-50 inner-div-1">*/}
      {/*                        */}
      {/*                         <span className="review-title">{i?.ratingCount} Reviews</span>*/}
      {/*                     </div> */}
      {/*                     <div className="w-50 inner-div-2">*/}
      {/*                       <span className="fw-bold" style={{fontWeight: 700, fontSize: "16px"}}>${i?.expectedRate} </span>*/}
      {/*                       <span className="review-title">Hourly rate</span>*/}
      {/*                     </div>*/}
      {/*                     </div> *!/*/}
      {/*                     /!* <div> *!/*/}
      {/*                     <Button */}
      {/*                     // htmlType="submit"*/}
      {/*                     onClick={(e) => handleBookingAppointment(e, i)}*/}
      {/*                     className="book-btn btn-black auth-action-btn" shape="round" type="primary" size="large">*/}
      {/*                     Book*/}
      {/*                     </Button>*/}
      {/*                     /!* </div> *!/*/}

      {/*                 </div>  */}
      {/*             )*/}
      {/*         })*/}
      {/*         : */}
      {/*         <div className="no-data-found">*/}
      {/*             <img src="/images/icons/no-caregivers.svg" alt="no-caregivers-found"  />*/}
      {/*             <h3 style={{color: '#64748B', width:'auto'}}>No Caregivers/Companion available for you</h3>*/}
      {/*         </div>*/}
      {/*       }*/}
      {/*     </div>*/}
      {/*     <div className='d-flex justify-content-between px-3 mt-4'>*/}
      {/*     <Button */}
      {/*     // htmlType="submit"*/}
      {/*     onClick={() => navigate("/client/appointment/create-appointment/dates")}*/}
      {/*     className="btn-back auth-action-btn mt-3 mb-3" shape="round" size="large">*/}
      {/*     Back*/}
      {/*     </Button>*/}
      {/*     </div>*/}
      {/*       </Spin>*/}
      {/* </div>*/}
      {/*    </div>*/}
      {/*</>:*/}

      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 w-100 message-inner-row">
            <AppointmentNavBar />
            <div
              className="p-3 border-less rounded caregivers-choose-custom"
              style={{ background: "#fff", minHeight: "80vh" }}
            >
              <div className="my-caregiver-container-mob">
                <Spin spinning={loading}>
                  <div
                    className="caregivers-wrapper caregivers-wrapper-choose d-flex"
                    style={{ width: "100%", flexWrap: "wrap" }}
                  >
                    {caregiverList.length > 0 ? (
                      caregiverList.map((i) => {
                        return (
                          <div
                            className="caregivers-card ms-3 mt-3 flex-row-reverse"
                            onClick={() =>
                              navigate(
                                `/client/appointment/create-appointment/view-caregiver/${i?._id}`,
                                {
                                  state: {
                                    unmatchedServices: i?.unMatchedServices,
                                  },
                                }
                              )
                            }
                          >
                            <div className="profile-wrapper text-center">
                              <img
                                alt={""}
                                className="service-img"
                                src={i?.image}
                                width={90}
                                height={90}
                                style={{ objectFit: "cover" }}
                              />
                              <p
                                className="fw-bold"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {i?.firstName} {i?.lastName}{" "}
                              </p>
                              <Button
                                className="ant-btn-primary person-title"
                                style={
                                  i?.userType == "4" ? { width: "90px" } : {}
                                }
                              >
                                {i?.userType == 3
                                  ? "Caregiver"
                                  : i?.userType == "4"
                                  ? "Companion"
                                  : ""}
                              </Button>
                            </div>
                            <div className="d-flex justify-content-center align-items-center ratings-card mt-2">
                              <div className="w-50 inner-div-1 inner-div-1-custom">
                                <div
                                  className="d-flex rating-review-div"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src="/images/icons/star-rating.svg"
                                    width={20}
                                  />
                                  <span
                                    className="fw-bold"
                                    style={{
                                      position: "relative",
                                      right: "2px",
                                      fontWeight: 700,
                                      fontSize: "16px",
                                    }}
                                  >
                                    {i?.caregiverRating}
                                  </span>
                                </div>
                                <span className="review-title">
                                  {i?.ratingCount} Reviews
                                </span>
                              </div>
                              <div className="w-50 inner-div-2 rating-hourly-price-div">
                                <span
                                  className="fw-bold"
                                  style={{
                                    fontWeight: 700,
                                    fontSize: "16px",
                                  }}
                                >
                                  ${i?.expectedRate}{" "}
                                </span>
                                <span className="review-title">
                                  Hourly rate
                                </span>
                              </div>
                            </div>
                            {/* <div> */}
                            <Button
                              // htmlType="submit"
                              onClick={(e) => handleBookingAppointment(e, i)}
                              className="book-btn btn-black auth-action-btn"
                              shape="round"
                              type="primary"
                              size="large"
                            >
                              Book
                            </Button>
                            {/* </div> */}
                          </div>
                        );
                      })
                    ) : (
                      <div
                        className="no-data-found"
                        style={{ position: "relative" }}
                      >
                        <div className="need-help-button">
                          <a href="#">Need help?</a> Call client support
                          1-416-262-0591
                        </div>
                        {/*<img*/}
                        {/*  src="/images/icons/no-caregivers.svg"*/}
                        {/*  alt="no-caregivers-found"*/}
                        {/*/>*/}
                        {/*<h3 style={{ color: "#64748B" }}>*/}
                        {/*  No Saved Caregivers/Companions*/}
                        {/*</h3>*/}
                      </div>
                    )}
                  </div>
                </Spin>
              </div>
              <div className="d-flex justify-content-between common-btn-row-choose-date px-3 mt-4">
                <Button
                  // htmlType="submit"
                  onClick={() =>
                    navigate("/client/appointment/create-appointment/dates")
                  }
                  className="btn-back auth-action-btn mt-3 mb-3"
                  shape="round"
                  size="large"
                >
                  Back
                </Button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <NoDataModal
        noDataModalVisible={noDataModalVisible}
        handleClosePopup={() => {
          setNoDataModalVisible(false);
          navigate("/client/appointment/create-appointment/dates");
        }}
        slotsArr={slotsArr}
      />
      {/*}*/}
    </div>
  );
}

const NoDataModal = (props) => {
  const { noDataModalVisible, handleClosePopup, slotsArr } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={400}
      header={null}
      footer={null}
      open={noDataModalVisible}
      onOk={handleClosePopup}
      onCancel={handleClosePopup}
      closable={false}
      // closeIcon={<CloseCircleOutlined />}
    >
      <div>
        <div className="image-container">
          <img src="/images/icons/info-group.svg" className="text-center" />
        </div>

        <p className="text-center mt-3" style={{ color: "#000" }}>
          {slotsArr?.length == 1
            ? `No caregiver match for the day and time you selected. Try selecting a different time and date.`
            : `No Caregiver Match. Try to book a single appointment to start. You will be able to see the caregivers' availability when viewing their profiles`}
        </p>
        <Button
          // htmlType="submit"
          onClick={handleClosePopup}
          className="btn-black mx-auto auth-action-btn mt-3"
          shape="round"
          type="primary"
          size="large"
        >
          Go Back
        </Button>
      </div>
    </Modal>
  );
};

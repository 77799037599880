import 'react-html5video/dist/styles.css'
import { useRef } from 'react';
import ReactPlayer from 'react-player';
import './videotutorial.less'
import { Modal,Button } from 'antd'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
const VideoTutorial=({item})=>{
    const [openModal, setOpenModal] = useState(false);
    const navigate=useNavigate();
    const [playing,setPlaying]=useState(true);
    const vidRef = useRef();
    const handleFunction=()=>{
        console.log(vidRef,"vidref")
        vidRef.current.pause();
        setOpenModal(false);
        
    }
    console.log(item.url)
    return (
        <div className='video-container'>

            <div className="videotitle" >
                <div className="videoimage" style={{  boderRadius: "8%" }}   >
             <img src={item.thumbnailImage} style={{ width: "100%", height: "100%", borderRadius: "10px",objectFit:"cover" }} />
                    <div className="playbtn"><img src="/images/icons/Play.png" style={{ width: "100%", height: "100%" }} onClick={() => { setOpenModal(true);setPlaying(true)}} /></div>
                </div>
                <div className="title" style={{color:"#0F172A",textAlign:"center"}}>{item.title}</div>
                

        
            </div>
          <VideoModalFuc   openModal={openModal} playing={playing}  item={item} handleFunction={handleFunction} vidRef={vidRef}/>

          
        </div>
    )

}
const VideoModalFuc=(props)=>{
    const {openModal,playing,item,handleFunction,vidRef}=props;
    console.log(openModal,"openModal")
    return(
    <Modal className="playmodal" centered header={null} footer={null} open={openModal } width={919} height={410} style={{  padding: "0" }} closable 
    closeIcon={<CloseCircleOutlined />} onCancel={handleFunction}>
    {/* <span> <button className="crossbutton"><img className="circlecross" src="/images/icons/crossiconimage.png" alt="tutorial-image" style={{ cursor: "pointer", color: "#fff",opacity:"none" }}  onClick={(e)=>{ console.log(e,"in func");handleFunction()
    }}/></button> </span> */}
     {/* <Button onClick={handleFunction} style={{float:'right'}} > */}
     {/* <img className="circlecross" src="/images/icons/crossiconimage.png" alt="tutorial-image" style={{ cursor: "pointer", color: "#fff",opacity:"none" }} onClick={handleFunction}/> */}
        {/* </Button>  */}
 <video 
              style={{borderRadius:'10px'}}
                width="100%"
                height="100%"
                ref={vidRef}
                src={item.url}
               autoPlay={true}
               controls
              ></video>
             
</Modal>
)
    }


export default VideoTutorial;
import React, { Children } from "react";
import ReactDOM from "react-dom";

// import "./styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";



// const Toolbox = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 100%;
//   max-width: calc(100vw - 60px);
//   margin-bottom: 10px;
//   button {
//     margin-left: 10px;
//     width: 2em;
//   }
// `;
 const  TransformImgWrapper=({children}) =>{
  const onClick = () => {
    alert("onClick : open popup");
  };
  const onContextMenu = e => {
    e.preventDefault();
    alert("onContextMenu : show info");
  };

  
  return (
    <TransformWrapper
      // defaultScale={1}
      // initialPositionX={200}
      // initialPositionY={100}
      initialScale={null}
      centerZoomedOut
    >
      {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
        <React.Fragment>
          {/* <Toolbox> */}
            {/* <button onClick={zoomIn}>+</button>
            <button onClick={zoomOut}>-</button>
            <button onClick={resetTransform}>x</button> */}
          {/* </Toolbox> */}
          <TransformComponent>
         {children}
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
}


export default TransformImgWrapper

import React, { useState, useEffect } from "react";
import "./style.less";
import {
  Form,
  Input,
  Button,
  Slider,
  Select,
  Col,
  Row,
  Radio,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../../redux/alert/alert.action";
const { TextArea } = Input;

function AdditionalDetails({ save, load, userDetails, userType }) {
  const dispatch = useDispatch();
  //  console.log(userDetails, "userDetails")
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [referredFrom, setReferredFrom] = useState("");
  const [expectedRate, setExpectedRate] = useState("");
  const [vaccinatedState, setVaccinatedState] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [stripeInfo, setStripeInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [isOtherVisible, setIsOtherVisible] = useState(false);
  const [otherVal, setOtherVal] = useState("");
  const [marks, setMarks] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (userDetails !== undefined) {
      const foundRefferedFrom = referredFromData.find(
        (i) => i == userDetails?.referredFrom
      );
      form.setFieldValue("describe", userDetails?.about?.describe);
      form.setFieldValue("experience", userDetails?.about?.experience);
      form.setFieldValue(
        "additionComments",
        userDetails?.about?.additionComments
      );
      if (userDetails?.referredFrom && !foundRefferedFrom) {
        form.setFieldValue(
          "referredFrom",
          userDetails?.referredFrom !== "" ? "Others" : null
        );
        form.setFieldValue("other", userDetails?.referredFrom);
        setIsOtherVisible(true);
        setOtherVal(userDetails?.referredFrom);
      } else {
        form.setFieldValue(
          "referredFrom",
          userDetails?.referredFrom !== "" ? userDetails?.referredFrom : null
        );
      }
      form.setFieldValue(
        "isVaccinated",
        userDetails?.isVaccinated !== ""
          ? userDetails?.isVaccinated?.toString()
          : ""
      );
      form.setFieldValue("expectedRate", userDetails?.expectedRate);
      setExpectedRate(userDetails?.expectedRate);
      setVaccinatedState(
        userDetails?.isVaccinated !== ""
          ? userDetails?.isVaccinated?.toString()
          : ""
      );
      setReferredFrom(userDetails?.referredFrom);
      handleFormChange();
      if (userType === 3) setMarks(marksCare);
      else setMarks(marksComp);
    }
    handleConvenience();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(userDetails, "userDetails");
  }, [userDetails]);
  const handleConvenience = () => {
    if (token !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-convenience-fee`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((res) => {
          console.log(res, "response for stripe");
          setStripeInfo(res.data.output.caregiver.value);
        })
        .catch((err) => {
          console.log("error", err);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleFormChange = () => {
    // const areTruthy = Object.values(form.getFieldsValue()).every(
    //   value => console.log(value,"value")
    // );
    const valuesArr = Object.values(
      form.getFieldsValue([
        "describe",
        "experience",
        "referredFrom",
        // "isVaccinated",
        // "expectedRate",
      ])
    );
    const filteredArr = valuesArr.filter((m) => !m);
    const isNextDisabled = filteredArr?.length > 0 ? true : false;
    setDisabledSave(isNextDisabled);
    // const hasErrors =
    // form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    // console.log(hasErrors,"hasErrors")
    // setDisabledSave(hasErrors);
  };

  const referredFromData = [
    "Existing MF HomeCare Caregiver / Companion",
    "Relative/Friend",
    "Job Center",
    "Facebook",
    // 'Twitter',
    "Internet Search",
    "Others",
  ];

  const onFinish = (values) => {
    console.log("values", values);
    const data = {
      about: {
        describe: values.describe,
        experience: values.experience,
        additionComments: values.additionComments,
      },
      referredFrom:
        values.referredFrom == "Others" ? otherVal : values.referredFrom,
      expectedRate: values.expectedRate
        ? values.expectedRate
        : userType == 3
        ? 18
        : 16,
      // isVaccinated: values.isVaccinated,
    };
    save(data);
  };

  const marksCare = {
    18: "CAD 18",
    19: "CAD 19",
    20: "CAD 20",
    21: "CAD 21",
    22: "CAD 22",
    23: "CAD 23",
    24: "CAD 24",
    25: "CAD 25",
  };

  const marksComp = {
    16: "CAD 16",
    17: "CAD 17",
    18: "CAD 18",
    19: "CAD 19",
    20: "CAD 20",
  };

  const vaccinatedData = [
    {
      name: "Yes",
      value: "true",
    },
    {
      name: "No",
      value: "false",
    },
  ];

  const formatter = (value) => `CAD ${value}`;

  // const renderDots = (index, { style, className }) => {
  //   const dotStyle = {
  //     ...style,
  //     width: "1px",
  //     height: "12px",
  //     background: "#bfbfbf",
  //     marginLeft: "-0.5px",
  //     marginRight: "-0.5px",
  //   };
  //   const dots = [];
  //   for (let i = 0; i < 5; i++) {
  //     dots.push(
  //       <span
  //         key={`${index}-${i}`}
  //         className={className}
  //         style={{ ...dotStyle, height: "8px" }}
  //       ></span>
  //     );
  //   }
  //   return dots;
  // };

  const renderDots = (index, { style, className }) => {
    const dotStyle = {
      ...style,
      width: "1px",
      height: "12px",
      background: "#bfbfbf",
      marginLeft: "-0.5px",
      marginRight: "-0.5px",
    };
    const dots = [];
    for (let i = 0; i < 5; i++) {
      dots.push(
        <span
          key={`${index}-${i}`}
          className={className}
          style={{ ...dotStyle, height: "8px" }}
        />
      );
    }
    return dots;
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(userDetails, "userDetails");
  return (
    <div
      className="px-3 mx-auto additional-detail-wrapper"
      style={{ maxWidth: "750px" }}
    >
      <h1 className="color-primary fw-bolder mt-5 mb-3">Additional Details</h1>
      <div className="text-start additionaldetails">
        <Spin spinning={loading || load}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            // validateTrigger='onSubmit'
            onFieldsChange={handleFormChange}
            //   initialValues={{
            //     describe:'',
            //     experience:'',
            //     additionComments:'',
            //     referredFrom:'',
            //     expectedRate:'',
            //     }}
          >
            <Row
              className="additional-details-row"
              style={{
                overflowY: "auto",
                height: "500px",
                overflowX: "hidden",
              }}
            >
              <Row gutter={16}>
                <Col
                  xs={24}
                  lg={12}
                  style={{ paddingRight: "0" }}
                  className="responsive-margin-bottom padding-right-15"
                >
                  <Form.Item
                    name="describe"
                    rules={[
                      { required: true, message: "Please describe yourself" },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      placeholder="How would you describe yourself? *"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} className="responsive-margin-bottom">
                  <Form.Item
                    name="experience"
                    rules={[
                      {
                        required: true,
                        message: "Please write about your experience",
                      },
                    ]}
                  >
                    <TextArea rows={4} placeholder="Experience *" />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={24} className="responsive-margin-bottom">
                  <Form.Item
                    name="additionComments"

                    // rules={[{ required: true, message: 'Please add some additional comments'  }]}
                  >
                    <TextArea
                      className="scrolltext"
                      rows={4}
                      placeholder="Additional Comments"
                      style={{
                        overflowY: "scroll !important",
                        scrollbarColor: "gray",
                        scrollbarWidth: "10px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} className="responsive-margin-bottom">
                  <Form.Item
                    name="referredFrom"
                    label={
                      <p className="color-secondary mb-0 fs-6">
                        2. How did you hear about us?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please select where did you hear about us",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={
                        <img
                          src="/images/icons/drop-icon.svg"
                          alt="arrow-icon"
                        />
                      }
                      className="profileselectrole"
                      onChange={(val) => {
                        console.log(val, "changeddd");
                        if (val === "Others") {
                          setIsOtherVisible(true);
                          setOtherVal("");
                          form.setFieldValue("other", "");
                        } else {
                          setIsOtherVisible(false);
                        }
                        setReferredFrom(val);
                        form.setFieldValue("referredFrom", val);
                      }}
                      size="large"
                      placeholder="Select your preference"
                    >
                      {referredFromData?.map((op, i) => {
                        return <Select.Option value={op}>{op}</Select.Option>;
                      })}
                    </Select>
                  </Form.Item>
                  {isOtherVisible && (
                    <Form.Item
                      className="other-language-input"
                      name="other"
                      rules={[
                        {
                          pattern: new RegExp(/^[A-Za-z]+$/),
                          message: "Add only alphabets",
                        },
                        { required: true, message: "Other field is Required" },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder="Other source"
                        style={{
                          // width: "97%",
                          marginTop: "10px",
                          // marginLeft: "10px",
                          height: "40px",
                          top: "-15px",
                        }}
                        value={otherVal}
                        onChange={(e) => {
                          setOtherVal(e.target.value);
                        }}
                      />
                    </Form.Item>
                  )}
                </Col>
                {/* <Col
                  xs={24}
                  lg={12}
                  md={24}
                  className="responsive-margin-bottom"
                >
                  <Form.Item
                    name="isVaccinated"
                    label={
                      <p className="color-secondary mb-0 fs-6">
                        3. Are you fully Vaccinated ?
                      </p>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please write about your experience",
                      },
                    ]}
                  >
                    <Radio.Group
                      size="large"
                      className={`w-100`}
                      value={vaccinatedState}
                      onChange={(e) => {
                        form.setFieldValue("isVaccinated", e.target.value);
                        setVaccinatedState(e.target.value);
                        handleFormChange();
                      }}
                    >
                      <Space
                        direction="horizontal"
                        className="custom-radio-space gender w-100 profilegender"
                      >
                        {vaccinatedData?.map((op, index) => {
                          return (
                            <Radio.Button
                              key={index}
                              className={`border-less w-100 custom-radio text-dark`}
                              value={op?.value}
                            >
                              <span>
                                {vaccinatedState === op?.value
                                  ? op?.activeIcon === undefined
                                    ? op?.icon
                                    : op?.activeIcon
                                  : op?.icon}
                                {op?.name}
                              </span>
                              <span className="ms-2">
                                {vaccinatedState === op?.value ? (
                                  <img
                                    width={16}
                                    src="/images/icons/checked.svg"
                                    alt="checked"
                                  />
                                ) : (
                                  <img
                                    width={16}
                                    src="/images/icons/unchecked.svg"
                                    alt="unchecked"
                                  />
                                )}
                              </span>
                            </Radio.Button>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col> */}

                <Col xs={24} lg={24} md={24} className="signup-slider">
                  <Form.Item
                    label={
                      <p className="color-secondary mb-5 fs-6">
                        3. Set your hourly rate?{" "}
                        <span style={{ color: "red" }}>*</span>
                      </p>
                    }
                    name="expectedRate"
                    className={
                      userType == 3
                        ? "hourlyrate caregiverhourlyrate"
                        : "hourlyrate"
                    }
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "let us know your expected hourly rate",
                    //   },
                    // ]}
                  >
                    <Slider
                      range
                      included
                      trackStyle={{ backgroundColor: "#1890ff" }}
                      dots={{ render: renderDots }}
                      value={expectedRate}
                      onChange={(val) => {
                        console.log(val, "val");
                        form.setFieldValue("expectedRate", Number(val));
                        setExpectedRate(val);
                        handleFormChange();
                      }}
                      className="mt-3"
                      style={{ padding: "10px" }}
                      tooltip={{ formatter }}
                      marks={marks}
                      min={userType == 3 ? 18 : 16}
                      max={userType == 3 ? 25 : 20}
                    />
                    <p
                      className="color-secondary mt-2 text-center"
                      style={{ fontSize: "12px" }}
                    >
                      Until you build a client list with positive reviews and
                      ratings, it is recommended to set your rate reasonably to
                      maximize appointment requests.
                    </p>
                    <h4 style={{ color: "#64748B", fontSize: "12px" }}>
                      {" "}
                      *{stripeInfo}% will be deducted from the stripe
                    </h4>
                  </Form.Item>
                </Col>
                {/* <h4 style={{color: "#64748B",fontSize:"12px"}}> *{stripeInfo}% will be deducted from the stripe</h4> */}
              </Row>
              <Col xs={24} lg={12} md={24} className="">
                <Form.Item className="text-center additional-details-btn-item">
                  <Button
                    className={
                      isMobile
                        ? "btn-black mt-3 mb-5 mx-auto"
                        : "btn-black auth-action-btn mt-3 mb-5 mx-auto additional-screen-button"
                    }
                    shape="round"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={disabledSave}
                  >
                    Next{" "}
                    <img
                      className="ms-2"
                      src="/images/icons/right.svg"
                      alt="right icon"
                    />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDetails);

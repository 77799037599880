import { Button, Space, Typography, Table, Row, Modal, Radio, Col } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpenseApprove from "../../../../components/Modal/ExpensesModal/ExpenseApprove";
import ExpenseReject from "../../../../components/Modal/ExpensesModal/ExpenseReject";
import ReceiptModal from "../../../../components/Modal/ExpensesModal/ReceiptModal";

import "../../../client/Expenses/Expenses.less";
import ExpenseApi from "../../../../http/Expenses/api";
import { paymentDetails } from "../../../../redux/appointment/appointment.action";
import { setAlert } from "../../../../redux/alert/alert.action";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { async } from "q";
import _ from "lodash";
import AddCardDetails from "../../../client/settings/addCardDetails";
const { Text } = Typography;
export default function ExpensesItems({ Items, isMobile }) {
  const userMode = useSelector((state) => state.loginData?.userMode);
  const [selectedCard, setSelectedCard] = React.useState(null);
  const dispatch = useDispatch();
  const [ReceiptEntity, setReceiptEntity] = React.useState({
    open: false,
    itemEntity: {},
  });
  const [cardDetails, setCardDetails] = React.useState([]);
  const [ModalActive, setModalActive] = React.useState({});
  const [addCardPopUp, setAddCardPopUp] = React.useState(false);
  const [showCards, setShowCards] = React.useState(false);
  const handleReceiptModalOpen = (data, id) => {
    setReceiptEntity({
      // open: true,
      itemEntity: data,
      id: data,
    });
    activeModal(id);
  };

  const activeModal = (id) => {
    setModalActive({
      // ...ModalActive,
      [id]: true,
    });
  };

  const inActiveModal = (id) => {
    setModalActive({
      // ...ModalActive,
      [id]: false,
    });
  };
  const columns = [
    {
      dataIndex: "title",
      key: "title",
      width: "15%",
      render: (_, record) => (
        <Space>
          <img
            src={record.image}
            alt="Img"
            className="Expenses_receipt_img"
            onClick={() => handleReceiptModalOpen(record, "activeReceipt")}
          />{" "}
          <Text className="expenses_client_title">{record.title}</Text>
        </Space>
      ),
    },
    {
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      render: (amount) => (
        <Text className="expenses_client_amount">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount)}
        </Text>
      ),
    },
    {
      dataIndex: "relativeName",
      key: "relativeName",
      width: "40%",
      render: (_, record) => (
        <Space>
          {/* <img
            src={record.relativeImage}
            alt="Img"
            className="expenses_client_receipt"
          />
          <Text className="expenses_client_relativeName">
            {record.relativeName}
          </Text> */}
        </Space>
      ),
    },
    {
      dataIndex: "key",
      key: "key",
      align: "right",
      render: (_, key) => (
        <>
          {key.status === 0 && userMode === "client" && (
            <Space>
              <Button
                className="expenses_reject_Button"
                onClick={() => handleRejected(key, "activeReceipt", "Reject")}
              >
                Reject
              </Button>
              <Button
                className="expenses_approve_Button"
                onClick={() => handleApproved(key, "activeReceipt", "Payment")}
                shape="round"
                type="primary"
                size="large"
              >
                Approve
              </Button>
            </Space>
          )}

          {key.status === 0 && userMode !== "client" && (
            <Button className="expenses_pending_Button">Pending</Button>
          )}

          {key.status == 1 && (
            <Button className="expenses_approved_Button">Approved</Button>
          )}
          {key.status == 2 && (
            <Button className="expenses_Rejected_Button">Rejected</Button>
          )}
        </>
      ),
    },
  ];

  const handleRejected = (item, inActiveId, ActiveId) => {
    if (item) {
      setReceiptEntity({
        // open: true,
        itemEntity: item,
        id: item,
      });
    }

    inActiveModal(inActiveId);
    activeModal(ActiveId);
  };
  const handleApproved = (item, inactiveId, activeid) => {
    inActiveModal(inactiveId);
    activeModal(activeid);

    if (item) {
      setReceiptEntity({
        // open: true,
        itemEntity: item,
        id: item,
      });
    }
  };

  const rejectExpense = async (item, id) => {
    const res = await ExpenseApi._expenseReject({
      id: ReceiptEntity.itemEntity._id,
      type: "decline",
    });
    inActiveModal("Reject");
 
     if(res.status===200){

     
    dispatch(paymentDetails({
      ...Items,
      expenses:_.map(Items.expenses, (itemlist)=>{
         if(itemlist._id===ReceiptEntity.itemEntity._id){
           return{
            ...itemlist,
            status:2
           }
           }else{
             return itemlist
           }
         
      })
    }))
  }else if(res.status >= 400){
    dispatch(
      setAlert({
        alertType: "error",
        alertMsg: res?.error?.message,
        alertOpen: true,
      })
    );
  }
    // dispatch(paymentDetails(res.output.result));
  };
  const ApproveExpense = async (id) => {
    const res = await ExpenseApi._expenseReject({
      id: ReceiptEntity.itemEntity._id,
      type: "decline",
    });
  };

  const handleCardChange = (e) => {
    setSelectedCard(e.target.value);
  }


  const handleAddCardOpen = () => {
    // console.log("it is in add card open");
    setAddCardPopUp(true);
    console.log(addCardPopUp, "addCardPopUp");
  };
  //  const handleAddCardOpen=()=>{

  //  }

    const handlePayment=async()=>{
      const response = await ExpenseApi._Pay({
        id: ReceiptEntity.itemEntity._id,
         type: "approve",
        cardId: selectedCard,
      });
       
      if (response.status === 200) {
        inActiveModal("Payment");
        activeModal("Approve")
        // fetchExpenses(token);
        // setIsPaymentScreen(false);
        // setSuccesspopup(true); 
        //  console.log(ReceiptEntity.itemEntity, ' ewdewd')

        dispatch(paymentDetails({
          ...Items,
          expenses:_.map(Items.expenses, (itemlist)=>{
             if(itemlist._id===ReceiptEntity.itemEntity._id){
               return{
                ...itemlist,
                status:1
               }
               }else{
                 return itemlist
               }
             
          })
        }))
      } else if (response.status >= 400&& response.status!==401) {
        // setLoading(false);
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: response?.error?.message,
            alertOpen: true,
          })
        );
      } else {
        // setLoading(false);
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: "something went wrong! please try again.",
            alertOpen: true,
          })
        );
      }
    
    }

    const customTable=(data)=>{
       return (
       <>
       {data.map(item=>{
return(
  <Row gutter={[8,24]} style={{overflowX:'hidden' ,}} className="pt-3">
  <Col xs={4}>
  <div>
  <img
    src={item.image}
    alt="Img"
    className="Expenses_receipt_img"
    onClick={() => handleReceiptModalOpen(item, "activeReceipt")}
  />{" "}
  <Text className="expenses_client_title">{item.title}</Text>
{/* </Space> */}
  </div>
  </Col>
  <Col xs={8} className="pt-2">
  <Text className="expenses_client_amount">
  {new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(item.amount)}
</Text>
  </Col>
  <Col xs={10}>
  {item.status === 0 && userMode === "client" && (
    <>
      <Button
        className="expenses_reject_Button"
        style={{marginTop:'3px'}}
        onClick={() => handleRejected(item, "activeReceipt", "Reject")}
      >
        Reject
      </Button>
      <Button
        className="expenses_approve_Button"
        onClick={() => handleApproved(item, "activeReceipt", "Payment")}
        shape="round"
        type="primary"
        size="large"
        style={{marginTop:'5px'}}
      >
        Approve
      </Button>
    </>
  )}

  {item.status === 0 && userMode !== "client" && (
    <Button className="expenses_pending_Button" style={{marginTop:'5px'}}>Pending</Button>
  )}

  {item.status == 1 && (
    <Button className="expenses_approved_Button"  style={{marginTop:'5px'}}>Approved</Button>
  )}
  {item.status == 2 && (
    <Button className="expenses_Rejected_Button"  style={{marginTop:'5px'}}>Rejected</Button>
  )}
  </Col>
</Row>
)
       })}
       </>
       
       )
    }
  return (
    <div className="expensesItems">
      {Items.expenses?.length > 0 &&!isMobile &&(
        <Table
          dataSource={Items.expenses}
          columns={columns}
          scroll={{ y: 200 }}
          responsive
          bordered={false}
          pagination={false} 
          showHeader={false}
        />

        
      )}
       {Items.expenses?.length > 0 &&isMobile&& (
       

        customTable(Items.expenses)
      )}

      <ReceiptModal
        receiptpopup={ModalActive?.activeReceipt}
        handleReceiptModalClose={inActiveModal}
        receiptData={ReceiptEntity.itemEntity}
        handleRejected={handleRejected}
        handleApproved={handleApproved}
      />

      <ExpenseReject
        rejectpopup={ModalActive?.Reject}
        handleUnsuccessModalClose={inActiveModal}
        ishandleRejected={rejectExpense}
        // accessToken={accessToken}
      />
      <ExpenseApprove
        successpopup={ModalActive?.Approve}
        handleSuccessModalClose={inActiveModal}
      />
      <PaymentScreen
        isPaymentScreen={ModalActive?.Payment}
        isPaymentScreenClose={inActiveModal}
        handlePayment={handlePayment}
        // cardDetails={cardDetails}
        // cardImage={cardImage}
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
        // setCardDetails={set}
        handleCardChange={handleCardChange}
        selectedCard={selectedCard}
        // showCards={showCards}
        handleAddCardOpen={handleAddCardOpen}
        // appointmentData={appointmentData}
        id={ReceiptEntity.itemEntity}
      />

<AddCardModal
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
        addCardPopUp={addCardPopUp}
        setAddCardPopUp={setAddCardPopUp}
        setShowCards={setShowCards}
        // showCards={showCards}
        isPaymentScreenOpen={activeModal}
        // isPaymentScreenClose={isPaymentScreenClose}
      />
    </div>
  );
}

const PaymentScreen = (props) => {
  const {
    isPaymentScreen,
    isPaymentScreenClose,
    handlePayment,
    // cardDetails,
    handleCardChange,
    handleAddCardOpen,
    // showCards,
    // cardImage,
    setCardDetails,
    selectedCard,
    cardDetails,
    id,
  } = props;
  const dispatch = useDispatch();
  // const [cardDetails, setCardDetails] = React.useState([]);
  const getCards = async () => {

     console.log("Update  query ,.,,....")
    const response = await ExpenseApi._fetchCard();

    if (response.status === 200) {
      setCardDetails(response.output.list);
    } else if (response.status >= 400) {
      // setLoading(false);
      dispatch(
        setAlert({
          alertType: "error",
          alertMsg: response?.error?.message,
          alertOpen: true,
        })
      );
    } else {
      // setLoading(false);
      dispatch(
        setAlert({
          alertType: "error",
          alertMsg: "something went wrong! please try again.",
          alertOpen: true,
        })
      );
    }
  };

  React.useEffect(() => {
    getCards();
  }, []);
  const cardImage = {
    Discover: "/images/icons/discover.png",
    "American Express": "/images/icons/amex.png",
    MasterCard: "/images/icons/mastercard.png",
    UnionPay: "/images/icons/unioncard.png",
    Visa: "/images/icons/visa.png",
    Diners: "/images/icons/diners.png",
    Jcb: "/images/icons/jcb.png",
  };
  return (
    <Modal
      className="info-modal-container"
      centered
      width={450}
      header={null}
      footer={null}
      open={isPaymentScreen}
      onCancel={() => isPaymentScreenClose("Payment")}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      {/* <div className="Close-container">
        <CloseOutlined
          onClick={() => {
            isPaymentScreenClose("Payment");
          }}
        />
      </div> */}
      <div className="image-container">
        <h2 className="text-center">Payment</h2>
      </div>
      <h5 className="mt-3 expense_payment_Text text-center">
        Your payment is only processed once the caregiver/companion accepts your
        request. your funds remain same in our trust account and only get
        released after each appointment is completed.
      </h5>
      <div className="expense_Payment_Details">
        <Row className="expense_payment_charges">
          <Text className="expense_Payment_Type">Cab charges</Text>
          <Text className="expense_Payment_Amount">$ {id?.amount || ""}</Text>
        </Row>
        {/* <Row className="expense_payment_charges">
          <Text className="expense_Payment_Type">Transaction Fee</Text>
          <Text className="expense_Payment_Amount">$ 00.20</Text>
        </Row> */}
      </div>
      <div className="expense_Payment_Total_Details">
        <Row className="expense_payment_Total_charges">
          <Text className="expense_payment_Total_Type">Total Amount</Text>
          <Text className="expense_Payment_Amount">$ {id?.amount || ""}</Text>
        </Row>
      </div>

      <h3 className="card-select-text">Select Card</h3>
      {cardDetails.length == 0 ? (
        <div className="no-cards-wrapper text-center p-3">
          <img src="/images/icons/no-cards.svg" />
          <Button
            className="add-card-btn btn-black auth-action-btn mt-3 mb-3"
            shape="round"
            type="primary"
            size="large"
            style={{ margin: "auto" }}
            onClick={() => {
              handleAddCardOpen();
              isPaymentScreenClose("Payment");
            }}
          >
            Add Card
          </Button>
        </div>
      ) : (
        <div className="expenses_payment_cardContainer">
          <Radio.Group
            onChange={handleCardChange}
            value={selectedCard}
            direction="vertical"
          >
            {cardDetails.map((i) => {
              return (
                <>
                  <Radio
                    value={i?.id}
                    direction="vertical"
                    className="expenses_radio_card expenses_radio_Radio"
                  >
                    <div className="expenses_radio_card">
                      <Row>
                        <span
                          className="masked-number"
                          style={{ margin: "5.5px 30px 0 0", fontSize: "24px" }}
                        >
                          **** **** ****
                        </span>
                        <span
                          style={{ margin: "5.5px 30px 0 0", fontSize: "24px" }}
                        >
                          {i?.last4}
                        </span>
                      </Row>
                      <Row
                        style={{
                          margin: "20px 0px",
                        }}
                      >
                        <div style={{ fontSize: "14px", marginRight: "12vw" }}>
                          CVV
                          <br /> <span>***</span>
                        </div>
                        <img src={cardImage[i?.brand]} width={60} />
                      </Row>
                    </div>
                  </Radio>

                  <div style={{ marginTop: "10px" }}></div>
                </>
              );
            })}
          </Radio.Group>
        </div>
      )}

      <Row gutter={24}>
        <Col span={10}>
          <Button
            // htmlType="submit"
            onClick={() => isPaymentScreenClose("Payment")}
            className=" mx-auto expense_popup_cancel"
            shape="round"
            type="primary"
            size="large"
          >
            Cancel
          </Button>
        </Col>
        <Col span={14}>
          <Button
            // htmlType="submit"
            onClick={handlePayment}
            className="btn-black mx-auto expense_popup_okay"
            shape="round"
            type="primary"
            size="large"
            disabled={!selectedCard}
          >
            Pay
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};


const AddCardModal = (props) => {
  const {
    cardDetails,
    setCardDetails,
    showCards,
    addCardPopUp,
    setAddCardPopUp,
    setShowCards,
    isPaymentScreenOpen,
  } = props;

   console.log(showCards, "showCards")
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={addCardPopUp}
    >
      <div className="expenses_new_cardContainer">
        <h3
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Add New Card
        </h3>
        <AddCardDetails
          setShowCards={setShowCards}
          setCardDetails={setCardDetails}
          setAddCardPopUp={setAddCardPopUp}
          isPaymentScreenOpen={isPaymentScreenOpen}
        />
        <Button
          className="buttoncancel mt-4"
          shape="round"
          size="large"
          onClick={() => {
            setAddCardPopUp(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
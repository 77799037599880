import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Modal, Spin, Radio, Button } from "antd";
import { toggleChooseRelativeModal } from "../../redux/modals/toggleModal.actions";
import { setAlert } from "../../redux/alert/alert.action";
import axios from "axios";
import "./chooseRelative.less";
import { useNavigate } from "react-router-dom";
import { CLEAR_DATA } from "../../redux/auth/auth.type";
import { CloseCircleOutlined } from "@ant-design/icons";

function ChooseRelatives({ chooseRelativeModal, accessToken }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [relativeList, setRelativeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [value, setValue] = useState("");
  const currentUrl = window.location.href;
  const appointmentData = useSelector((state) => state.appointment);
  const { selectedCC } = appointmentData;

  const handleOk = () => {
    dispatch(toggleChooseRelativeModal(false));
  };

  const handleCancel = () => {
    dispatch(toggleChooseRelativeModal(false));
  };

  const handleRadioChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    fetchRelatives(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const saveCaregiverRole = () => {
    localStorage.setItem("relative", JSON.stringify(value));
    if (selectedCC?.scheduleAgain) {
      navigate("/my-caregivers/services", { state: { relativeId: value } });
    } else {
      navigate("/client/appointment/create-appointment/services", {
        state: { relativeId: value },
      });
    }
    dispatch({ type: CLEAR_DATA });
    dispatch(toggleChooseRelativeModal(false));
  };

  const fetchRelatives = async (token) => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-relatives`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setRelativeList(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  return (
    <div>
      <Modal
        className="choose-relative-modal-div"
        centered
        style={{ maxWidth: "35vw", maxHeight: "71vh" }}
        header={null}
        width={476}
        footer={null}
        open={chooseRelativeModal}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: "70vh", overflow: "auto" }}
        closable
        closeIcon={
          <img src="/images/icons/close-icon-outline-black.svg" alt={""} />
        }
      >
        {/* <div className='d-flex' style={{justifyContent:"end", cursor:"pointer", position:'relative', bottom:'1rem' , }} onClick={handleCancel}><CloseCircleOutlined style={{fontSize:"27px", color:'#E7E8EA'}} /></div> */}
        <Spin spinning={loading}>
          <p className="fw-bold fs-3 text-center new-book-appoinment-heading">
            Who Are You Booking This Appointment For?
          </p>
          <Radio.Group
            size="large"
            value={value}
            onChange={handleRadioChange}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              margin: "16px -16px",
            }}
          >
            {relativeList?.map((op, index) => (
              <Radio.Button
                key={index}
                className={`custom-radio text-dark text-center p-2`}
                value={op}
                style={{
                  flexBasis: "calc(40.33% - 32px)",
                  maxWidth: "150px",
                  height: "150px",
                  margin: "6px",
                  borderRadius: "10px",
                }}
              >
                <div className="w-100">
                  {op?.image ? (
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                      className="my-2 rounded-circle"
                      src={op?.image}
                      alt={op?.firstName}
                    />
                  ) : (
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                      className="my-2 rounded-circle"
                      src={!op?.image ? "/images/icons/Profile.png" : op?.image}
                      alt={op?.firstName}
                    />
                  )}

                  <p className="mb-0 fw-bold relative-name fs-7">
                    {op?.firstName} {op?.lastName}
                  </p>
                </div>
              </Radio.Button>
            ))}
          </Radio.Group>
          <div className="text-center">
            <p
              className="color-secondary mt-3 fs-6"
              onClick={() => {
                dispatch(toggleChooseRelativeModal(false));
                navigate("/client/relatives/add-new-relative", {
                  state: { IS_APPOINTMENT: true },
                });
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="me-2"
                style={{ marginTop: "-5px" }}
                width={20}
                src="/images/icons/add-icon.svg"
                alt="add icon"
              />{" "}
              Add Care Recipients
            </p>
          </div>
          <Button
            htmlType="submit"
            onClick={saveCaregiverRole}
            className="btn-black mx-auto auth-action-btn mt-3 mb-3"
            shape="round"
            type="primary"
            size="large"
            disabled={value === ""}
          >
            Next
          </Button>
        </Spin>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseRelatives);

import React, { useEffect, useState } from "react";
import Sidebar from "../../../components/sidebar";
import "./settings.less";
import { Tabs } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import ClientProfile from "./clientProfile";
import BankDetails from "./bankDetails";
import Changepassword from "../../../components/settings/changepassword";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import { useDispatch } from "react-redux";
import Footer from "../../../components/Footer/Footer";
import StripeVerify from "../../caregiver/StripeVerify";

const breadcrumbItemId = {
  1: {
    title: "Edit Profile",
    route: "",
  },
  2: {
    title: "Change Password",
    route: "",
  },
  3: {
    title: "Payment Information",
    route: "",
  },
};

function ClientSettings() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [tabChange, setTabChange] = useState(false);
  const [tabValue, setTabValue] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(fetchBankDetails(token))
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.settings.concat({
          title: "Edit Profile",
          route: "/",
        })
      )
    );
  }, []);
  const tabClick = (key) => {
    //console.log(key,"key in tab change")
    setTabChange(!tabChange);
    setTabValue(key);
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.settings.concat(breadcrumbItemId[key])
      )
    );
  };
  console.log(tabValue, "tabValue");
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        {/*{isMobile ? (*/}
        {/*        <div className='container-fluid'>*/}
        {/*            <div className='pt-5 d-flex'>*/}
        {/*                <div className='ms-2 w-100'>*/}
        {/*                    <Responsiveheader title="Settings"/>*/}
        {/*                    <div className="clientsettings" style={{width: "100%"}}>*/}
        {/*                        <Tabs defaultActiveKey="1" tabPosition="left" size="medium" onChange={tabClick}>*/}
        {/*                            <Tabs.TabPane*/}

        {/*                                tab={*/}
        {/*                                    <span>*/}
        {/*                                /!* <span className="roundCircle">*/}
        {/*                                    <EditOutlined style={{ color: "rgb(149 149 204)", fontSize: "20px" }} />*/}
        {/*                                </span> *!/*/}
        {/*                                        <span className="imageicons">*/}
        {/*                                <img src="/images/icons/editicon.png" alt=""*/}
        {/*                                     style={{width: "14%"}}/></span>*/}
        {/*                                <span className="settingnames">   Edit Profile*/}
        {/*                                <RightCircleOutlined style={{marginLeft: "80px"}}/></span>*/}
        {/*                            </span>*/}
        {/*                                }*/}
        {/*                                key="1"*/}
        {/*                            >*/}
        {/*                                <ClientProfile/>*/}

        {/*                            </Tabs.TabPane>*/}
        {/*                            <Tabs.TabPane*/}

        {/*                                tab={*/}
        {/*                                    <span>*/}
        {/*                                /!* <LockOutlined style={{ color: "blue", fontSize: "20px" }} /> *!/*/}
        {/*                                        <span className="imageicons">*/}
        {/*                                <img src="/images/icons/passwordicon.png" alt=""*/}
        {/*                                     style={{width: "14%"}}/></span>*/}
        {/*                                <span className="settingnames">      Change Password*/}
        {/*                                <RightCircleOutlined style={{marginLeft: "38px"}}/></span>*/}
        {/*                            </span>*/}
        {/*                                }*/}
        {/*                                key="2"*/}
        {/*                            >*/}
        {/*                                <Changepassword/>*/}
        {/*                            </Tabs.TabPane>*/}
        {/*                            <Tabs.TabPane*/}

        {/*                                tab={*/}
        {/*                                    <span>*/}
        {/*                                /!* <BankOutlined style={{ color: "green", fontSize: "20px" }} /> *!/*/}
        {/*                                        <span className="imageicons">*/}
        {/*                                <img src="/images/icons/bankicon.png" alt=""*/}
        {/*                                     style={{width: "14%"}}/></span>*/}
        {/*                                <span className="settingnames"> Payment Information */}
        {/*                                <RightCircleOutlined style={{marginLeft: "41px"}}/></span>*/}
        {/*                            </span>*/}
        {/*                                }*/}
        {/*                                key="3"*/}
        {/*                            >*/}

        {/*                                {*/}
        {/*                                    <BankDetails/>*/}
        {/*                                }*/}
        {/*                            </Tabs.TabPane>*/}
        {/*                        </Tabs>*/}

        {/*                    </div>*/}
        {/*                </div>*/}

        {/*            </div>*/}
        {/*        </div>*/}
        {/*    ) : (*/}
        <div className="container-fluid appointment-dashboard">
          <div className="pt-5 d-flex client_Expenses">
            <Sidebar />
            <div className="mx-4 message-inner-row w-100">
              {/* <SettingsNavBar /> */}
              <AppointmentNavBar />
              <div className="clientsettings client-setting-custom-tab">
                <Tabs
                  defaultActiveKey="1"
                  tabPosition="left"
                  size="medium"
                  onChange={tabClick}
                  className={
                    tabValue == 1
                      ? "active1"
                      : tabValue == 2
                      ? "active2"
                      : "active3"
                  }
                >
                  <Tabs.TabPane
                    tab={
                      <span className="d-flex align-items-center justify-content-between">
                        {/* <span className="roundCircle">
                                                    <EditOutlined style={{ color: "rgb(149 149 204)", fontSize: "20px" }} />
                                                </span> */}
                        <span className="imageicons">
                          <img src="/images/icons/editicon.png" alt="" />
                        </span>
                        <span className="settingnames">
                          {" "}
                          Edit Profile
                          <RightCircleOutlined style={{ marginLeft: "80px" }} />
                        </span>
                      </span>
                    }
                    key="1"
                  >
                    <ClientProfile tabChange={tabChange} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <span className="d-flex align-items-center justify-content-between">
                        {/* <LockOutlined style={{ color: "blue", fontSize: "20px" }} /> */}
                        <span className="imageicons">
                          <img src="/images/icons/passwordicon.png" alt="" />
                        </span>
                        <span className="settingnames">
                          {" "}
                          Change Password
                          <RightCircleOutlined style={{ marginLeft: "38px" }} />
                        </span>
                      </span>
                    }
                    key="2"
                  >
                    <Changepassword tabChange={tabChange} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    // style={tabValue == 3 ? { background: "#e6f8f3" } : {}}
                    tab={
                      <span className="d-flex align-items-center justify-content-between">
                        {/* <BankOutlined style={{ color: "green", fontSize: "20px" }} /> */}
                        <span className="imageicons">
                          <img src="/images/icons/bankicon.png" alt="" />
                        </span>
                        <span className="settingnames">
                          {" "}
                          Payment Information
                          <RightCircleOutlined style={{ marginLeft: "19px" }} />
                        </span>
                      </span>
                    }
                    key="3"
                  >
                    {<BankDetails tabValue={tabValue} />}
                  </Tabs.TabPane>
                  {/* <Tabs.TabPane
                    // style={tabValue == 3 ? { background: "#e6f8f3" } : {}}
                    tab={
                      <span className="d-flex align-items-center justify-content-between w-100">
                        {/* <BankOutlined style={{ color: "green", fontSize: "20px" }} />
                        <span className="imageicons">
                          <img src="/images/icons/stripe-doc.svg" alt="" />
                        </span>
                        <span className="settingnames">
                          {" "}
                          Stripe Documents
                          <RightCircleOutlined style={{ marginLeft: "19px" }} />
                        </span>
                      </span>
                    }
                    key="4"
                  >
                    {<StripeVerify tabValue={tabValue} />}
                  </Tabs.TabPane> */}
                </Tabs>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        {/*) }*/}
      </div>
    </>
  );
}

export default ClientSettings;

import { useEffect, useRef } from "react";

export default function FooterClientTnC() {
  const scrollContainerRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      const scrollContainer = scrollContainerRef.current;
      if (!scrollContainer) return;

      if (event.key === "ArrowUp") {
        scrollContainer.scrollTop -= 100;
      } else if (event.key === "ArrowDown") {
        scrollContainer.scrollTop += 100;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <div
      ref={scrollContainerRef}
      style={{ overflowY: "auto", height: "500px" }}
    >
      {/* <p>
        <b>Last updated</b>: May 2023
      </p> */}
      <p>
        These Terms of Use set out the terms and conditions under which
        individuals may use the website or mobile applications and access the
        services offered by MF Homecare. These Terms of Use comprise a legal
        agreement between MF Homecare and you, the person accessing and using
        the website or mobile applications (“You” or “User”). MF Homecare owns,
        operates and maintains the mfhomecare.com website at{" "}
        <a href="https://mfhomecare.com">https://mfhomecare.com</a>, (referred
        to as “Website”), and the MF Homecare mobile applications (“App”),
        Website and App collectively referred to as the “Platform”. By
        downloading, accessing and/or using the Platform, you are deemed to have
        read Terms of Use and you agree to be bound by the Terms of Use which
        shall govern your access and use of the Platform and all applicable laws
        and regulations.
      </p>
      <p>
        If you do not agree to all of the terms of use set out below, you may
        not access or use the website or app, should not click to accept these
        terms and should not proceed any further.
      </p>
      <h3>Description of Services</h3>
      <p>
        MF Homecare offers online services to match individuals and families
        with various caregivers and companions (referred to as “Services”). MF
        Homecare’s Services allows individuals providing care services
        (“Caregivers”) to post information profiles on the Website to facilitate
        matching with those Users who are seeking services (“Clients”). MF
        Homecare’s Services facilitate the matching of Clients and Caregivers by
        providing search functions to allow Clients to find the appropriate
        Caregiver based on their preferences and needs. MF Homecare’s Service
        also involves providing a platform to facilitate the initial and ongoing
        communication between Clients and Caregivers (“Messaging Platform”).
        Through the Messaging Platform, Users can engage with one another
        without disclosure of their e-mail or phone number. MF Homecare reserves
        the right to re-post a User’s Service advertisement on external
        third-party websites for the purposes of ensuring exposure of the User’s
        request and/or provision of Services. Any such re-posting on an external
        third-party website will not include Personal Information (as defined
        below) of the Users. Caregivers are all independent contractors and have
        not been, are not, and never will be in an employment relationship with
        MF Homecare. MF Homecare is not a party to the relationship between the
        Client and Caregiver. MF Homecare shall not, in any way, be liable to a
        Client or Caregiver or any other third party, with respect to any matter
        or issue arising, either directly or indirectly from such employment or
        independent contractor agreement between the Client and Caregiver. MF
        Homecare does not verify, review, evaluate, interview, screen or perform
        any background checks on any of its Users or the content posted by the
        User on the Platform, including, without limiting the generality of the
        foregoing, any health, education or experiential history, other than
        requiring each Caregiver to register with and obtain from a third party
        service, a Criminal Record Verification. MF Homecare makes no
        representation or warranty whatsoever as to the veracity or results of
        such Criminal Record Verification. You assume all risks associated with
        dealing with other persons whom you come in contact with through the
        Services of MF Homecare’s Platform. Please refer to the Website
        regularly for further information about the Services provided.
      </p>
      <h3>Application Use</h3>
      <p>
        MF Homecare grants you the right to use the App for your personal use.
        You must comply with all applicable laws and third party terms of
        agreement when using the App (e.g. your wireless data service
        agreement). Your use of the App is at your own discretion and risk, and
        you are solely responsible for any damages to your hardware device(s) or
        loss of data that results from the download or use of the App. You
        acknowledge and agree that the App may not contain the same
        functionality available on the Website. We cannot guarantee the
        continuous, uninterrupted or error-free operation of the App or that the
        App will perform at a certain speed, as this depends on a number of
        factors beyond our control. You agree that downloading, accessing and
        use of the App is made available to you on an “as is” and “as available”
        basis for use at your sole risk.
      </p>
      <h3>Eligibility Criteria</h3>
      <p>
        To be eligible to use the Services offered by MF Homecare, you represent
        and warrant as follows: that you are eighteen (18) years of age or older
        on the date of creation of your User Account; if you are registering as
        Caregiver, that you are legally permitted to work in the jurisdiction
        where you are seeking employment; you do not have any health condition
        which is transmissible to Clients and which could adversely affect your
        provision of Services to Clients; neither you nor any member of your
        household have ever been the subject of a complaint, restraining order
        or any other legal action or criminal offence involving violence, abuse,
        neglect, fraud, or any other offence that involves endangering the
        safety of others, and that you are not registered or currently required
        to register as a sex offender with any government agency in any
        jurisdiction. In addition, you irrevocably and unconditionally consent
        to the use of, and participation in the use of the MF Homecare GPS
        Tracking Tool for the purpose of the provision of Services.
      </p>
      <h3>User Accounts &amp; Passwords</h3>
      <p>
        To access and use certain features of the Website, you will need to
        create a profile through the Website (“User Account”). As part of the
        process to create, register and maintain your User Account you must
        provide MF Homecare with certain current, complete, and accurate
        registration information, including your name, e-mail address, age,
        address, postal code/ZIP code, and phone number, and other data or
        information that has been requested by MF Homecare during the User
        account registration or renewal process (“Registration Data”). If you
        are enrolling as a Caregiver and/or Companion, additional information,
        including your bank account details will be collected and your funds
        will be automatically transferred to your bank account as per
        information in section 12 of these Terms of Use. In these Terms of Use,
        “Personal Information” means any information about an identifiable
        individual, such as your name, e-mail address, mailing addresses,
        gender, date of birth, any data about you that you elect to provide
        electronically through the Platform and any other information that
        identifies who you are. Registration Data and Personal Information will
        be used by MF Homecare solely in accordance with these Terms of Use and
        Company’s Privacy Policy (the “Privacy Policy”). In addition, if you are
        accessing MF Homecare’s Services through the App, you hereby grant
        permission to MF Homecare and to Clients to whom you provide Services to
        access your location through your mobile device. Access to and use of
        your location information is consistent with the terms of our Privacy
        Policy. You agree that you will: maintain and update such Registration
        Data as necessary in order to keep such information current, complete,
        and accurate; maintain the confidentiality of any passwords or other
        account identifiers which you choose or that are assigned to you as a
        result of any registration or account creation with the Platform; and be
        responsible for all activities that occur under such password or
        account. Further, you agree to immediately notify MF Homecare of any
        unauthorized use of your password or account in the event that the
        confidentiality of your password or your account is compromised. Failure
        to comply with this paragraph may result in immediate termination of
        your account.
      </p>
      <h2>User Account Fees, Term &amp; Renewal</h2>
      <h3>Caregivers &amp; Companions</h3>
      <p>
        MF Homecare charges Caregivers a fee for managing their online payments,
        as well as the fees charged by third parties, payment processors and
        direct deposits. This fee is deducted from the payment made regularly to
        the Caregivers/Companions bank accounts.
      </p>
      <h3>Caregiver &amp; Companion Tax Remittance</h3>
      <p>
        When Caregivers &amp; Companions render services, taxes are not
        automatically deducted from the earnings they accrue over the course of
        the year. As independent contractors, Caregivers &amp; Companions on the
        MF Homecare platform are solely responsible to ensure compliance for all
        federal, state/province, and local taxes in their jurisdiction. They are
        not, and have never been, employees of MF Homecare. MF Homecare is an
        aggregator platform that connects Caregivers &amp; Companions with
        customers.
      </p>
      <h3>Caregiver &amp; Companion Funds Remittance</h3>
      <p>
        All of the earnings Caregivers &amp; Companions accrue are deposited
        into their Stripe Connect Account that was automatically created for
        them upon registration. Deposits from their Stripe Connect Account to
        their personal bank account are automatically made weekly. Caregivers
        &amp; Companions that fail to add their personal banking details will
        have no means to access their funds. If a bank account is not added
        within 120 days of completing their first appointment, they will be
        unable to recover those funds for failing to add their personal banking
        details in a timely fashion.
      </p>
      <h3>Clients</h3>
      <p>
        MF Homecare adds a percentage fee to the Caregivers hourly rate, for
        managing the entire Client – Caregiver system. Payment is made when or
        subsequently to a Client making a booking for MF Homecare’s Services.
      </p>
      <p>
        Users agree with the fees charged by MF Homecare Services, which are
        considered final payments.
      </p>
      <h3>Personal Information</h3>
      <p>
        These Terms of Use set out the terms and conditions under which
        individuals may use the website or mobile applications and access the
        services offered by MF Homecare. These Terms of Use comprise a legal
        agreement between MF Homecare and you, the person accessing and using
        the website or mobile applications (“You” or “User”). MF Homecare owns,
        operates and maintains the mfhomecare.com website at{" "}
        <a href="https://mfhomecare.com">https://mfhomecare.com</a>, (referred
        to as “Website”), and the MF Homecare mobile applications (“App”),
        Website and App collectively referred to as the “Platform”. By
        downloading, accessing and/or using the Platform, you are deemed to have
        read Terms of Use and you agree to be bound by the Terms of Use which
        shall govern your access and use of the Platform and all applicable laws
        and regulations.
      </p>
      <h3>Content &amp; Use of Services</h3>
      <p>
        As a Client, Caregiver or any other User of the Services, you agree that
        the content of information and materials posted on the Website or App by
        You is accurate, current and complete. In addition, you represent and
        warrant as follows:
      </p>
      <p>
        You have the right and authority to post all information you post about
        yourself or others.
      </p>
      <p>
        You will not provide inaccurate, misleading, defamatory or false
        information to MF Homecare or to any other User of the Website or App.
      </p>
      <p>
        You will not post any content (including, but not limited to, written
        material, text, photos, or graphical content) on the Website, or
        transmit to other users, that is defamatory, inaccurate, inappropriate,
        abusive, obscene, profane, offensive, sexually oriented, threatening,
        harassing, defamatory, racially offensive, or illegal material, or any
        material that infringes or violates another party’s rights (including,
        but not limited to, intellectual property rights, and rights of privacy
        and publicity), or advocate, promote or assist any unlawful act.
      </p>
      <p>
        Your use of the Website, App and Services provided by MF Homecare are in
        accordance with any and all applicable laws and regulations.
      </p>
      <h3>Use License</h3>
      <p>
        MF Homecare hereby grants you a limited, non-exclusive, personal,
        non-transferable, non-sub-licensable and revocable right and license to
        access the Platform for the purpose of offering and/or receiving the
        Services in accordance with these Terms of Use and access, view and
        print any information and documentation made available on the Website or
        App, for your personal, non-commercial and informational use only to
        assist you in the access and use of the Website or App and the services.
      </p>
      <p>
        These Terms of Use do not grant you any other right or license, whether
        express or implied, by estoppel, or otherwise in or under any patent,
        trademark, copyright, or other intellectual property or proprietary
        right of MF Homecare or any third party. MF Homecare may terminate this
        license at any time for any reason whatsoever.
      </p>
      <h3>Caregiver Accounts</h3>
      <p>
        We provide each caregiver with their own account. We charge a small
        percentage to manage and do direct deposit into their bank account. The
        rate may vary due to changing rates and banking fees.
      </p>
      <h3>Intellectual Property</h3>
      <p>
        The Platform and all intellectual property rights in the Platform are
        owned by MF Homecare.
      </p>
      <p>
        MF Homecare reserves all intellectual property rights, including but not
        limited to, copyright, trade-marks, domain names, design rights, brand
        elements, database rights, patents, and all other intellectual property
        rights of any kind, whether registered or unregistered.
      </p>
      <h3>Trade-marks</h3>
      <p>
        All brand elements, company name, website names, logos, registered and
        unregistered trade-marks displayed on the Platform or used in connection
        with the Services are the trade-marks of MF Homecare (or its suppliers,
        partner businesses or third party licensors). Any use or misuse of any
        trade-marks or brand elements of MF Homecare or any other content
        appearing on the Platform or in connection with the Services except as
        provided in these Terms of Use or with the express written consent of MF
        Homecare is strictly prohibited. Nothing contained on the Platform shall
        be construed as an express or implied grant of any license or right to
        use any trade-mark or brand element without prior written consent of MF
        Homecare.
      </p>
      <h3>Use Restrictions</h3>
      <p>
        In addition to complying with these Terms of Use, you agree to use the
        Platform and Services for lawful purposes only and in a manner
        consistent with all applicable local, national or international laws and
        regulations, including all privacy and personal information laws.
      </p>
      <h3>Termination of Registration</h3>
      <p>
        MF Homecare reserves the right, in its sole discretion, to terminate
        your account or access to part or all of MF Homecare’s Website, App
        and/or Services in the event that MF Homecare determines you are not
        eligible to use the Services or have violated any of the provisions of
        the Terms of Use, or have misused the Website, App or Services in any
        way.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        You acknowledge that any use of or reliance on this website or the
        services is completely at your own risk. under no circumstances shall mf
        homecare be liable to you or to any other party, for any losses, costs
        or damages of any kind or nature whatsoever that are suffered or
        incurred in any connection with the use of (or the inability to use) the
        platform, services, or any mf homecare content or third party content
        (regardless of the form of action or theory of liability, including for
        breach of contract, tort, negligence, equity, strict liability, by
        statute or otherwise and regardless of the occurrence of a fundamental
        breach or failure of essential purpose). mf homecare’s services
        facilitate the matching of clients and caregivers by providing search
        functions to allow clients to find the appropriate caregiver based on
        their preferences, needs, and location. mf homecare is not a party to
        the relationship between client and caregiver, and shall not, in any
        way, be liable to a client or caregiver or any other third party,
        arising, either directly or indirectly from such employment or
        independent contractor agreement between the parties. in no event
        whatsoever shall any of mf homecare, its directors, officers, employees,
        shareholders, affiliates, agents and third-party contractors, suppliers
        and licensors (collectively the “company parties”) be liable for any
        special, exemplary, punitive, consequential, incidental or indirect
        damages of any kind or nature whatsoever that are suffered or incurred
        in any connection with the use of platform or the provision of the
        services (including in any connection with the transmission or
        downloading or storage of any data or submissions to or from the
        platform or the use of, or reliance on, any mf homecare content or other
        information or data contained on or provided through the platform, or
        services, or loss of or damage to files or data or any costs of
        recovering or reproducing any files or data or loss of use or lack of
        availability of services or any business interruption or loss of revenue
        or profit or any other economic loss whatsoever) however caused and
        regardless of the form or cause of action and whether or not
        foreseeable, even if the mf homecare parties or any of them has been
        informed in advance or ought reasonably to have known of the potential
        for such damages.
      </p>
      <h3>Indemnification</h3>
      <p>
        You agree to indemnify and hold MF Homecare, its parents, subsidiaries,
        affiliates, officers and employees, harmless from any claim, demand, or
        damage, including reasonable legal fees, asserted by any third party due
        to or arising out of the Users use of or conduct respecting the
        Services, and Platform.
      </p>
      <h3>Language</h3>
      <p>
        It is the express will of MF Homecare that these Terms of Use and all
        related documents on the Platform are in English.
      </p>
      <h3>Non-assignment</h3>
      <p>
        These Terms of Use are not assignable, transferable, or to be
        sub-licensed by you except with MF Homecare’s prior written consent. MF
        Homecare may assign, transfer, or delegate any of its rights and
        obligations hereunder without your consent.
      </p>
      <h3>Modifications of Terms of Use</h3>
      <p>
        MF Homecare may revise these Terms of Use at any time without notice. By
        using the Platform, you are agreeing to be bound by the current version
        of the Terms of Use and Privacy Policy (last updated date listed at the
        top of the Terms of Use). The content and material appearing on the
        Platform could include technical, typographical or photographic errors.
        MF Homecare does not warrant that any of the materials provided are
        accurate or current.
      </p>
      <h3>Links to External Websites</h3>
      <p>
        The links on the Platform to other websites or resources, including
        those operated by parties other than MF Homecare are provided for your
        convenience. MF Homecare is not responsible for the availability of such
        websites or resources and does not endorse or accept responsibility for
        the content of such external websites or resources and has no
        responsibility for or control over the terms of service or privacy
        policy (if any) of the operators of the external websites or resources.
        Your access and viewing of any third party websites or resources is
        conducted at your own risk.
      </p>
      <h3>Website, App &amp; Services Provided “As Is”</h3>
      <p>
        you understand and agree that the platform, the services, any content
        and other information, data and materials available on the website, app
        or provided as part of the services are provided on an “as is” and “as
        available” basis.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about the Terms of Use, or need to provide
        notice to, or communicate with, MF Homecare under the Term of Use,
        please do not hesitate to contact us by email at:{" "}
        <a href="mailto:clientsupport@mfhomecare.com">
          clientsupport@mfhomecare.com
        </a>
        .
      </p>
      <p>© 2023 MF Homecare</p>
    </div>
  );
}

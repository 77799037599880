import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import DatePicker from "../../../common/DatePicker";
import MySchedule from "../../../components/mySchedule";
import AppoinmentCard from "../../../components/mySchedule/AppoinmentCard";
import MonthlyCalneder from "../../../components/mySchedule/MonthlyCalneder";
import Sidebar from "../../../components/sidebar";
import Appointments from "./Appointments";
import "./Schedule.less";
import _ from "lodash";
import Footer from "../../../components/Footer/Footer";
import { setAlert } from "../../../redux/alert/alert.action";
import OverrideMonth from "../../../components/Modal/common/OverrideMonth";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import { paymentDetails } from "../../../redux/appointment/appointment.action";

const orientation = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

const Schedule = ({ ipad }) => {
  // debugger
  const [active, setActive] = useState(orientation.WEEKLY);
  const [appointmentList, setAppointmentList] = useState([]);
  const [datesData, setDatesData] = useState(null);
  const [selectedDayAppo, setSelectedDayAppo] = useState([]);
  const token = localStorage.getItem("token");
  const userMode = useSelector((state) => state.loginData?.userMode);
  const [Availability, setAvailability] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const [innerWidth, setinnerWidth] = React.useState("");
  const clientMode = userMode === " client";
  const dispatch = useDispatch();
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  //  console.log(window.innerWidth, "window.innerWidth" )
  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAppointments = (payload, isVerify) => {
    console.log(selectedDayAppo, "trtgrgtrtgrtgtrgtr");
    if (!token) {
      return;
    }

    const formatedURL = clientMode
      ? `${process.env.REACT_APP_BASE_URL}/appointment/list-scheduled-appointments`
      : `${process.env.REACT_APP_BASE_URL}/appointment/list-scheduled-appointments`;
    const headers = {
      accessToken: token,
      Authorization:
        "Basic " +
        btoa(
          process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
        ),
    };

    return axios
      .post(formatedURL, payload, { headers })
      .then((res) => {
        //  if(!isVerify){

        setAppointmentList(res?.data?.output);
        //       let temp=[]
        //  _.map(res?.data?.output.list,(item=>{
        //  if(item?.appointments){
        //   temp.push(...item.appointments)
        //  }
        // }))
        //  setSelectedDayAppo(temp)
        const temp = [];
        const dayByfilter = appointmentList?.list?.find((listing) => {
          const splittedDate = listing.date.split("T");
          return (
            moment().format("DD/MM/YYYY") ===
            moment(splittedDate[0]).format("DD/MM/YYYY")
          );
          // if (new Date().getDay() === new Date(listing.date).getDay()) {
          //   return (
          //     new Date().getDay() === new Date(listing.date).getDay() &&
          //     new Date().getMonth() === new Date(listing.date).getMonth() &&
          //     new Date().getDate() === new Date(listing.date).getDate()
          //   );
          // } else {
          // }
        });

        setSelectedDayAppo(dayByfilter);
        return res?.data?.output;
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  // useEffect(() => {
  //   let payload;
  //   if (active === orientation.MONTHLY) {
  //     payload = {
  //       type: userMode,
  //       startDate:
  //         moment().startOf("month").format("yyyy-MM-DDT").toString() +
  //         "00:00:00.000+0000",
  //       endDate:
  //         moment().endOf("month").format("yyyy-MM-DDT").toString() +
  //         "00:00:00.000+0000",
  //     };
  //   } else if (active === orientation.WEEKLY && datesData) {
  //     payload = {
  //       type: userMode,
  //       startDate:
  //         moment(datesData?.listingDates[0]?._d)
  //           .format("yyyy-MM-DDT")
  //           .toString() + "00:00:00.000+0000",
  //       endDate:
  //         moment(datesData?.listingDates[datesData.listingDates.length - 1]?._d)
  //           .format("yyyy-MM-DDT")
  //           .toString() + "00:00:00.000+0000",
  //     };
  //   }

  //   getAppointments(payload);
  // }, [datesData]);

  const getavailability = (payload) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/list-availability`,
        {
          ...payload,
        },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setAvailability(response.data.output.list);
        } else if (response.data.status >= 400) {
          // setLoading(false)
        } else {
          // setLoading(false)
        }
      });
  };
  useEffect(() => {
    const payload = {
      type: userMode,
      startDate:
        moment().startOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
      endDate:
        moment()
          .add(3, "months")
          .endOf("month")
          .format("yyyy-MM-DDT")
          .toString() + "00:00:00.000+0000",
    };
    getAppointments(payload);
    getavailability(payload);
    // getavailability();
  }, []);

  // const handleAppontment = (appointmentId) => {
  //   navigate(`/client/appointment/appointment-details/${appointmentId}`);
  // };

  const handleAppontment = (appointmentId) => {
    // navigate(`/client/appointment/appointment-details/${appointmentId}`);
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      // setLoading(true);
      axios
        .post(
          url,
          {
            type: "redirect",
            appointmentId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response.data, "appresp");
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointment/appointment-details/${appointmentId}`
            );
            dispatch(paymentDetails(response.data.output.result));
            // setLoading(false);
          } else if (response.data.status >= 400) {
            // setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            // setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const onSelectDatePicker = async (e) => {
    const dayAppointment = appointmentList?.list?.find((listing) => {
      const splittedDate = listing.date.split("T");
      console.log(listing, "  ");
      return (
        moment(e).format("DD/MM/YYYY") ===
        moment(splittedDate[0]).format("DD/MM/YYYY")
      );
      // if (new Date(e).getDay() === new Date(listing.date).getDay()) {
      //   return (
      //     new Date(e).getDay() === new Date(listing.date).getDay() &&
      //     new Date(e).getMonth() === new Date(listing.date).getMonth() &&
      //     new Date(e).getDate() === new Date(listing.date).getDate()
      //   );
      // } else {
      // }
    });

    setSelectedDayAppo(
      _.isEmpty(dayAppointment?.appointments) ? [] : dayAppointment.appointments
    );
  };
  // const handleArrow = (value) => {
  //     const payload = {
  //         type: userMode,
  //         startDate:
  //             moment(value).startOf("month").format("yyyy-MM-DDT").toString() +
  //             "00:00:00.000+0000",

  //         endDate:
  //             moment(value).endOf("month").format("yyyy-MM-DDT").toString() +
  //             "00:00:00.000+0000",
  //     };
  //     getAppointments(payload);
  //     getavailability(payload);
  // };

  console.log("appointmentList", selectedDayAppo);
  return (
    <div className="client-schedule">
      <Sidebar />
      <div className="schedule_container">
        {/* <div className="schedule_heading">My Schedule</div> */}
        <AppointmentNavBar />
        <div className="schedule_tab">
          <div
            className={active === orientation.WEEKLY ? "active" : "unused"}
            onClick={() => setActive(orientation.WEEKLY)}
          >
            {orientation.WEEKLY}
          </div>

          <div
            className={active === orientation.MONTHLY ? "active" : "unused"}
            onClick={() => setActive(orientation.MONTHLY)}
          >
            {orientation.MONTHLY}
          </div>
        </div>

        {active === orientation.MONTHLY ? (
          <div className="schedule_appoinments">
            <div className="schedule_calender">
              <MonthlyCalneder
                active={active}
                // handleArrow={handleArrow}
                appointmentList={appointmentList}
                onSelectDatePicker={onSelectDatePicker}
                setSelectedDayAppo={setSelectedDayAppo}
                selectedDayAppo={selectedDayAppo}
                Availability={Availability}
                userMode={userMode}
                getavailability={getavailability}
                getAppointments={getAppointments}
              />
            </div>
            {selectedDayAppo?.length === 0 || _.isEmpty(selectedDayAppo) ? (
              <div className="client_no_appointments_text ">
                <img src="/images/icons/bird-icon.svg" alt="green-circle" />
                <h4>No Appointments</h4>
              </div>
            ) : (
              <div
                className={
                  userMode === "client"
                    ? "appoinment_client_details"
                    : "appoinment_details"
                }
              >
                {selectedDayAppo?.length > 0 &&
                  selectedDayAppo?.map((appoint) => (
                    <AppoinmentCard
                      {...appoint}
                      handleAppontment={handleAppontment}
                    />
                  ))}
              </div>
            )}
          </div>
        ) : (
          <div className="weekly_schedule_appoinments">
            <DatePicker
              setDatesData={setDatesData}
              userMode={userMode}
              appointments={selectedDayAppo}
              onSelectDatePicker={onSelectDatePicker}
              getAppointments={getAppointments}
              ipad={window.innerWidth}
              // isAvailablity={true}
            />
            {/* <Appointments appointments={appointmentList?.list}  /> */}
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Schedule;

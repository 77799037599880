import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Input, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setAlert } from "../../redux/alert/alert.action";
import { handleRouting } from "../../common/helper/handleRoute";
import {
  setScreenNavigation,
  setUser,
  setUserMode,
} from "../../redux/auth/auth.action";

function Login({ user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabledSave, setDisabledSave] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(setUserMode("client"));
  }, []);
  const handleFormChange = (changedFields) => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
    if (changedFields[0]?.name.includes("email")) {
      form.setFieldValue("email", changedFields[0]?.value.toLowerCase());
    }
  };

  useEffect(() => {
    handleRouting(user, navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onFinish = (values) => {
    console.log("Success:", values);
    doLogin(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const doLogin = async (values) => {
    const newValues = {
      ...values,
      email: values.email.toLowerCase(),
    };
    setDisabledSave(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/client-login`, newValues, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((response) => {
        console.log(response, "response in then");
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/home`,
            {},
            {
              headers: {
                accessToken: response?.data?.output?.accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then(async (resp) => {
            if (resp.data.status === 200 && response.data.status === 200) {
              setDisabledSave(false);
              localStorage.setItem(
                "token",
                response?.data?.output?.accessToken
              );
              if (
                response?.data?.output?.accessToken !== "" &&
                response?.data?.output?.screen === 1
              ) {
                navigate("/client/verify", { state: response?.data?.output });
              } else if (
                response?.data?.output?.accessToken !== "" &&
                response?.data?.output?.screen === 2
              ) {
                dispatch(setUser(response?.data?.output));
                navigate("/client/profile");
              } else if (
                response?.data?.output?.accessToken !== "" &&
                response?.data?.output?.screen === 3
              ) {
                dispatch(setUser(response?.data?.output));
                const homeData = await resp.data.output;
                dispatch(setScreenNavigation(homeData));
                if (await resp.data.output?.isFirstAppointmentCreated) {
                  navigate("/client/appointments-dashboard");
                } else {
                  navigate("/client/appointment");
                }
              }
            } else if (response.data.status >= 400) {
              setDisabledSave(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: response?.data?.error?.message,
                  alertOpen: true,
                })
              );
            } else {
              setDisabledSave(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          })
          .catch((err) => {
            console.log(err, "err in catch");
            if (err?.response.data.status >= 400) {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: err?.response?.data?.error?.message,
                  alertOpen: true,
                })
              );
              if (err.response.data.status === 401) {
                localStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          });
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response.data.status >= 400) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
          if (err.response.data.status === 401) {
            localStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      });
  };

  return (
    <div className="center-container main-div-top min-vh-100">
      <div className="welcome-container justify-content-center">
        {/* <div className="left-side-poster client-login-poster" /> */}
        <div className="right-side-form">
          <div className="center-container justify-content-start h-100">
            <div className="w-100 text-center">
              <img
                className="logo-img pt-0 mb-5 logo-mobile"
                src="/images/icons/logo_with_name.svg"
                alt="logo"
              />
              <div
                className="welcome-wrapper text-left-mobile full-width"
                // style={{ maxWidth: "370px" }}
              >
                <h1 id="name" className="welcome-header mb-0">
                  Welcome Back
                </h1>
                <p className="welcome-subheader mt-2 mb-4">
                  Sign in to your account
                </p>

                <Form
                  name="signup-form"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                  // validateTrigger='onSubmit'
                  onFieldsChange={handleFormChange}
                  form={form}
                >
                  <Form.Item
                    name="email"
                    type="email"
                    className="mb-3"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid E-mail.",
                      },
                      {
                        required: true,
                        message: "Please enter your E-mail",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Email Address"
                      prefix={
                        <img
                          src="/images/icons/Message.svg"
                          alt="email icon"
                          style={{ marginRight: "10px" }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    className="mb-0"
                    rules={[
                      { required: true, message: "Please enter your Password" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                          const isContainsLowercase = /^(?=.*[a-z]).*$/;
                          const isContainsNumber = /^(?=.*[0-9]).*$/;
                          const isContainsSymbol =
                            /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                          if (value?.length < 8) {
                            return Promise.reject(
                              new Error(
                                "Password must be more than 8 character"
                              )
                            );
                          }
                          if (
                            !isContainsNumber.test(value) ||
                            !isContainsLowercase.test(value) ||
                            !isContainsSymbol.test(value) ||
                            !isContainsUppercase.test(value)
                          ) {
                            return Promise.reject(
                              new Error(
                                "Password must be a combination of uppercase, lowercase, special character, and number."
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) =>
                        visible ? (
                          <img
                            src="/images/icons/password-show.svg"
                            alt="show"
                          />
                        ) : (
                          <img
                            src="/images/icons/password-hide.svg"
                            alt="hide"
                          />
                        )
                      }
                      size="large"
                      prefix={
                        <img
                          src="/images/icons/Lock.svg"
                          alt="password icon"
                          style={{ marginRight: "10px" }}
                        />
                      }
                      placeholder="Enter Password"
                    />
                  </Form.Item>
                  <div className="text-end">
                    <p
                      onClick={() => navigate("/client/forget-password")}
                      className="welcome-subheader pointer mt-mob-0 mb-2 mt-3 fs-7"
                    >
                      Forgot your password?
                    </p>
                  </div>
                  <Form.Item className="mb-0 d-flex justify-content-center">
                    <Button
                      className="btn-black mx-auto auth-action-btn auth-action-btn-mx-auto mt-3 mb-2"
                      shape="round"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      disabled={disabledSave}
                    >
                      Sign In{" "}
                      <img
                        className="ms-2"
                        src="/images/icons/right.svg"
                        alt="right icon"
                      />
                    </Button>
                    <p className="welcome-subheader text-center-mob text-center-ipad pointer fs-6 account-confirmation">
                      Don't have an account?{" "}
                      <a className="fw-bold" href="/client/signup">
                        Sign Up
                      </a>
                    </p>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {handleErr&&<ErrorHandling err={err} setHandleErr={setHandleErr}/>} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import {
  SERVICES,
  DATE_TIME,
  BOOK_APPOINTMENT,
  SELECTED_CC,
  PAYMENT,
  MY_CAREGIVERS,
} from "./appointment.type";

const INITIAL_STATE = {
  dateTimeResp: {
    slot: [],
    range: [],
  },
  servicesResp: {
    caregiverAllChecked: false,
    companionAllChecked: false,
    checkedCompanions: [],
    checkedCaregiver: [],
  },
  selectedCC: null,
  bookingDetails: null,
  paymentDetails: null,
  caregiverAvailability: [],
};

const appointmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SERVICES:
      return {
        ...state,
        servicesResp: action.payload,
      };
    case DATE_TIME:
      return {
        ...state,
        dateTimeResp: action.payload,
      };
    case SELECTED_CC: {
      return {
        ...state,
        selectedCC: action.payload,
      };
    }
    case BOOK_APPOINTMENT: {
      return {
        ...state,
        bookingDetails: action.payload,
      };
    }
    case PAYMENT: {
      return {
        ...state,
        paymentDetails: action.payload,
      };
    }
    case MY_CAREGIVERS: {
      return {
        ...state,
        caregiverAvailability: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appointmentReducer;

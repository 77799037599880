import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Spin,
  Button,
  message,
  Col,
  Row,
  TimePicker,
  Divider,
  Typography,
  Select,
} from "antd";
import {
  toggleChooseRelativeModal,
  toggleConfirmRelativeModal,
} from "../../../redux/modals/toggleModal.actions";
import Sidebar from "../../../components/sidebar";
import { DateRangePicker } from "react-date-range";

import "./service-grid.less";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import AppointmentNavBar from "./appointmentsNavBar";
import {
  caregiverAvailability,
  dateTime,
  services,
} from "../../../redux/appointment/appointment.action";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import Footer from "../../../components/Footer/Footer";
import { Calendar } from "react-multi-date-picker";
import Responsiveheader from "../../../components/Responsiveheader";
import { CloseCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { convertToTwoDigits, getTimeSlots } from "./utils";

function MyCaregiversSelectDates({ chooseRelativeModal, accessToken }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const hourSlots = getTimeSlots();
  const dateTimeData = useSelector((state) => state.appointment.dateTimeResp);
  const selectedCCId = useSelector((state) =>
    state.appointment.selectedCC?.caregiverId
      ? state.appointment.selectedCC?.caregiverId
      : state.appointment.selectedCC?._id
  );
  const selectedCC = useSelector((state) => state.appointment.selectedCC);
  const currentDate = moment().toDate();
  const [update, setUpdate] = useState(true);
  const [availability, setAvailability] = useState([]);
  const [selectSlotsVisible, setSelectSlotsVisible] = useState(0);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const appointmentData = useSelector((state) => state.appointment);
  const [selectedAvailableSlots, setSelectedAvailableSlots] = useState(
    appointmentData?.caregiverAvailability
  );
  const [days, setDays] = useState(0);
  const mappedArr = dateTimeData?.slot.map((i) => ({ ...i, time: [i.time] }));
  const [slot, setSlot] = useState(mappedArr);
  const [occupiedArr, setOccupiedArr] = useState([]);
  const [currentSlots, setCurrentSlots] = useState([]);
  const [nextPage, setNextpage] = useState(false);
  const defaultRange =
    dateTimeData?.range?.length > 0
      ? dateTimeData?.range
      : // .map(i => {
        //   return { ...i, startDate: moment(i.startDate).toDate(), endDate: moment(i.endDate).toDate() }
        // })
        [];
  // const [range, setRange] = useState([{
  //   startDate:new Date(),
  //   endDate:new Date(),
  //   key:"selection"
  // }])
  // console.log(range, "test")
  // const dateRange = dateTimeData?.range?.length > 0 ? dateTimeData?.range : [{startDate:new Date(),
  //     endDate:new Date(),
  //     key:"selection"}]
  const [range, setRange] = useState(defaultRange);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  const storeData = useSelector((state) => state);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleSaveTiming = () => {
    // localStorage.setItem('slot', JSON.stringify(slot));
    const newArr = slot
      .filter((i) => i.time.length > 0)
      .map((obj) => {
        return { ...obj, time: obj.time[0] };
      });

    const flag = newArr.every((item) => {
      var duration = moment.duration(
        item.time?.endTime.diff(item.time?.startTime)
      );
      var hours = duration.asHours();
      return hours > 12;
    });
    // if (flag) {
    //   message.error(
    //     "An appointment could be of a maximum of 12 hours duration."
    //   );
    // }
    // else {
    const rangeSlotObj = {
      range,
      slot: newArr,
    };
    dispatch(dateTime(rangeSlotObj));
    dispatch(toggleConfirmRelativeModal(true));
    dispatch(caregiverAvailability(selectedAvailableSlots));
    // }
  };

  const fetchSlots = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/caregiver-availability`,
        {
          startDate:
            moment().format("yyyy-MM-DDT").toString() + "00:00:00.000+0000",
          endDate:
            moment().add(90, "days").format("yyyy-MM-DDT").toString() +
            "00:00:00.000+0000",
          userId: selectedCCId,
        },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
            // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MzdmNmE3Njg5NzY5ZDFhY2NlYjhmZWMiLCJ1c2VyVHlwZSI6MCwiaWF0IjoxNjY5Mjk1MDg3fQ.HmthpWH475OcT11_ibikzGb7W1ltrIOVY6IR4Hs9dv4",
          },
        }
      )
      .then((res) => {
        let dates = res.data.output.map((i) => {
          const splittedDate = i.startTime.split("T");
          return moment(splittedDate[0]).format("DD-MM-YYYY");
        });
        setOccupiedArr(dates);
        setCurrentSlots(res.data.output);
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  const handleSelect = (ranges) => {
    // const dateDiff = moment(ranges?.selection?.endDate).diff(moment(ranges?.selection?.startDate), 'days');
    // if(dateDiff > 3){
    //   message.error('end date should be within 4 days only')
    // }else{
    // console.log("ran  ges", ranges)

    // setDays(dateDiff)
    // setRange(prev => {
    //   return [...prev, {
    //   startDate:ranges?.selection?.startDate,
    //   endDate:ranges?.selection?.endDate,
    //   key:"selection"
    // }]})
    const rangeArr = [];
    ranges.forEach((range) => {
      const dateObj = {};
      // range.forEach((i, index) => {
      const date = new Date(range);
      // console.log(date, moment(new Date(i)).toDate(),"updateddate")
      // if (range.length == 1) {
      dateObj["startDate"] = moment(date).toDate();
      dateObj["endDate"] = moment(date).toDate();
      // }
      // if (index == 0) {
      //   dateObj["startDate"] = moment(date).toDate();
      // }
      // if (index == 1) {
      //   dateObj["endDate"] = moment(date).toDate();
      // }
      dateObj["key"] = "selection";
      // });
      rangeArr.push(dateObj);
    });
    setRange(ranges);
    const selected = ranges?.selection;
    // if(ranges?.selection?.endDate > ranges?.selection?.startDate){
    setSelectSlotsVisible(0);
    const filteredArr = [];
    // setTimeout(() => {
    // rangeArr.forEach((dateObj, idx) => {
    //   // if(dateObj.endDate) {
    //   handleFetchAvailability(dateObj, filteredArr);
    //   // }
    // })
    // }, 1000)
    // console.log(rangeArr, "filteredArr")
    rangeArr.forEach((n) => {
      // currentSlots.forEach((current) => {
      // debugger
      const nStartDate = moment(n.startDate);
      const nEndDate = moment(n.endDate);
      // const currentDate = moment(current.startTime);
      // console.log(
      //   moment(currentDate).isSameOrAfter(nStartDate),
      //   moment(currentDate).isSameOrBefore(nEndDate),
      //   "beforeafter"
      // );
      let start = moment(nStartDate);
      let end = moment(nEndDate);

      var list = [];

      // var dates = [];
      // var startDate = moment('2019-04-02', 'YYYY-MM-DD');
      list.push(nStartDate);
      while (start.format("DD/MM/YYYY") != end.format("DD/MM/YYYY")) {
        start = moment(start).add(1, "days");
        list.push(moment(start));
      }

      // for (var current = nStartDate; current <= end; current.add(1, "d")) {
      //   list.push(current);
      // }
      // console.log(list);
      list.forEach((current) => {
        const currentDate = moment(current);
        if (
          moment(currentDate).isBetween(nStartDate, nEndDate) ||
          moment(currentDate).isSame(nStartDate) ||
          moment(currentDate).isSame(nEndDate)
        ) {
          const filteredTimeSlots = currentSlots.filter((dte) => {
            return (
              moment(dte.startTime).zone(0).format("DD/MM/YYYY") ==
              moment(currentDate).zone(0).format("DD/MM/YYYY")
            );
          });
          if (filteredTimeSlots?.length > 0) {
            filteredArr.push({ date: currentDate, time: filteredTimeSlots });
          }
        }
        // else if(currentDate === nStartDate) {
        //   filteredArr.push(current);
        // }
      });
    });
    // handleFetchAvailability(selected);
    const newArr = filteredArr.map((i) => ({ ...i, isChecked: false }));
    setAvailability(newArr);
    setSelectedAvailableSlots([]);
    itreteSlots(rangeArr);
    // }

    // }
  };
  const itreteSlots = (ranges) => {
    let slot = [];
    ranges.forEach((dateObj) => {
      const dateDiff = moment(dateObj?.endDate).diff(
        moment(dateObj?.startDate),
        "days"
      );
      const beforeSevenDays = moment(dateObj?.endDate).subtract(
        dateDiff,
        "days"
      );
      const today = moment(dateObj?.startDate).startOf("day");
      let i = 0;
      // let slot = []
      for (
        let m = moment(beforeSevenDays);
        m.diff(today, "days") <= dateDiff;
        m.add(1, "days")
      ) {
        const foundSlot = dateTimeData?.slot?.find(
          (obj) =>
            moment(obj.date).format("DD/MM/YYYY") === m.format("DD/MM/YYYY")
        );
        slot.push({
          date: new Date(m._d),
          time: [
            {
              startTime: foundSlot ? foundSlot.time.startTime : "",
              endTime: foundSlot ? foundSlot.time.endTime : "",
            },
          ],
        });
        i += 1;
      }
    });
    setSlot(slot);
  };

  const handleStartTime = (e, date, i, availabilityIndex) => {
    let foundItem;
    let foundStartItem = hourSlots.find((item) => {
      return item.start.format("HH:mm") === e;
    });
    const foundEndItem = hourSlots.find((item) => {
      return item.end.format("HH:mm") === e;
    });
    if (foundStartItem) {
      foundItem = foundStartItem.start;
    } else {
      foundItem = foundEndItem?.end;
    }
    let tempSlot = slot;
    const selectedMonth = moment(date).format("MM");
    const selectedYear = moment(date).format("YYYY");
    const newDate = moment(foundItem).set({
      date: moment(date).format("DD"),
      month: selectedMonth - 1,
      year: selectedYear,
    });
    debugger;
    let hrs = Math.abs(
      moment
        .duration(moment(tempSlot[i].time[0].endTime).diff(newDate))
        .asHours()
    );
    const isOverNight =
      moment(tempSlot[i].time[0].endTime).format("hh:mm:ss A").includes("AM") &&
      moment(foundItem).format("hh:mm:ss A").includes("PM");
    if (hrs < 3) {
      message.error("Appointments must be a minimum duration of 3 hours.");
    } else if (!isOverNight && hrs > 12) {
      message.error("Appointments must be a maximum duration of 12 hours.");
    } else {
      tempSlot[i].time[availabilityIndex].startTime = moment(foundItem).set({
        date: moment(date).format("DD"),
        month: selectedMonth - 1,
        year: selectedYear,
      });
      setSlot(tempSlot);
      setUpdate(!update);
    }
  };

  const handleEndTime = (e, date, i, availabilityIndex) => {
    let foundItem;
    let foundStartItem = hourSlots.find((item) => {
      return item.start.format("HH:mm") === e;
    });
    const foundEndItem = hourSlots.find((item) => {
      return item.end.format("HH:mm") === e;
    });
    if (foundStartItem) {
      foundItem = foundStartItem.start;
    } else {
      foundItem = foundEndItem?.end;
    }
    let tempSlot = slot;
    const selectedMonth = moment(date).format("MM");
    const selectedYear = moment(date).format("YYYY");
    // let hrs = moment.utc(e.diff(tempSlot[i].time.startTime)).format("HH");
    const newDate = moment(foundItem).set({
      date: moment(date).format("DD"),
      month: selectedMonth - 1,
      year: selectedYear,
    });
    let hrs = Math.abs(
      moment.duration(newDate.diff(tempSlot[i].time[0].startTime)).asHours()
    );
    let time = moment(foundItem).format("HH a");
    const ct = time.split(" ");
    console.log("End time", ct);
    const isOverNight =
      moment(foundItem).format("hh:mm:ss A").includes("AM") &&
      moment(tempSlot[i].time[0].startTime).format("hh:mm:ss A").includes("PM");
    if (hrs < 3) {
      message.error("Appointments must be a minimum duration of 3 hours.");
    } else if (!isOverNight && hrs > 12) {
      message.error("Appointments must be a maximum duration of 12 hours.");
    } else if (ct[0] >= "02" && ct[0] <= "05" && ct[1] == "am") {
      message.error("End Time can not be between 2 AM to 5 AM");
    } else {
      console.log("DIFF", hrs);
      tempSlot[i].time[0].endTime = moment(foundItem).set({
        date: moment(date).format("DD"),
      });
      if (isOverNight) {
        tempSlot[i].time[0].endTime = moment(foundItem)
          .set({
            date: moment(date).format("DD"),
            month: selectedMonth - 1,
            year: selectedYear,
          })
          .add(1, "day");
        tempSlot[i].isOverNight = true;
      } else {
        tempSlot[i].isOverNight = false;
        tempSlot[i].time[0].endTime = moment(foundItem).set({
          date: moment(date).format("DD"),
          month: selectedMonth - 1,
          year: selectedYear,
        });
      }
      setSlot(tempSlot);
      setUpdate(!update);
    }
  };
  const handleClearStartEndTime = (idx, availabilityIndex) => {
    const timeSlot = [...slot];
    const isOverNight =
      timeSlot[idx].time[0]?.startTime && timeSlot[idx].time[0]?.endTime
        ? moment(timeSlot[idx].time[0]?.startTime).zone(0).format("DD") !=
          moment(timeSlot[idx].time[0]?.endTime).zone(0).format("DD")
        : false;
    timeSlot[idx].time[0].startTime = "";
    timeSlot[idx].time[0].endTime = "";
    timeSlot[idx].time[0].isOverNight = isOverNight;
    setSlot(timeSlot);
  };

  const handleInfoModal = () => {
    setInfoModalVisible(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalVisible(false);
  };
  const calculateHours = (IS_SELECTION) => {
    let hours = 0;
    slot.forEach((i) => {
      const date = moment(i.date, "YYYY/MM/DD");
      const foundAvailability = selectedAvailableSlots.find(
        (a) =>
          moment(a.startTime).zone(0).format("DD-MM-YYYY") ===
          moment(i.date).format("DD-MM-YYYY")
      );
      const foundItem = IS_SELECTION
        ? i.time?.length > 0 &&
          i.time.find((obj) => {
            return selectedAvailableSlots.some((newObj) =>
              moment(obj.startTime).isSame(newObj.startTime)
            );
          })
        : i.time[0];
      // const isOverNight = moment(foundAvailability?.startTime).zone(0).format('DD') != moment(foundAvailability?.endTime).zone(0).format('DD');
      const startTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(foundItem?.startTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      let endTimeObj =
        date.format("YYYY-MM-DD") +
        "T" +
        moment(foundItem?.endTime).utc(true).format("HH:mm") +
        `:00.000Z`;
      if (i?.isOverNight) {
        endTimeObj =
          date.format("YYYY-MM") +
          "-" +
          moment(date).add(1, "days").format("DD") +
          "T" +
          moment(foundItem?.endTime).utc(true).format("HH:mm") +
          `:00.000Z`;
      }

      const end = moment(endTimeObj);
      const start = moment(startTimeObj);
      let duration = moment.duration(end.diff(start));
      if (
        IS_SELECTION &&
        foundAvailability &&
        foundItem?.startTime &&
        foundItem?.endTime
      ) {
        hours = hours + duration.asHours();
      } else if (!IS_SELECTION && foundItem?.startTime && foundItem?.endTime) {
        hours = hours + duration.asHours();
      }
    });
    return hours;
  };

  // useEffect(() => {
  //     handleFetchAvailability()
  // }, [])

  const handleFetchAvailability = (dateObj, arr = []) => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-availability`,
          {
            userId: selectedCCId,
            startDate: dateObj
              ? moment(dateObj?.startDate).utc(true).format()
              : moment(range[0]?.startDate).utc(true).format(),
            endDate: dateObj
              ? moment(dateObj?.endDate).utc(true).format()
              : moment(range[0]?.endDate).utc(true).format(),
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then(async (response) => {
          console.log("response", response);
          if ((await response.data.status) === 200) {
            //  setCaregiverList(response.data.output.list)
            // setAvailability(prev => {
            // const availabilitySlots = [...prev];

            const availableSlots = [...response.data.output.list];
            // if(availableSlots.length > 0) {
            availableSlots.forEach((i) => {
              arr.push(i);
            });
            // }
            //   if(idx + 1 == arrLength) {
            //   const filteredItems = [];
            //   console.log(filteredItems, "filteredItems")
            //   mergedArr.forEach(n => {
            //     debugger
            //      if(filteredItems.length > 0) {
            //        filteredItems.forEach(m => {
            //           if(m.date !==n.date) {
            //             filteredItems.push(n);
            //           }
            //        })
            //      }
            //      else {
            //       filteredItems.push(n)
            //      }
            //   })
            //   return filteredItems
            // }
            // return mergedArr
            // });
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };
  useEffect(() => {
    if (selectedCC?.firstName && selectedCC?.lastName) {
      const breadCrumbArr = [...ActionCreator.BreadcrumbItems.caregivers];
      breadCrumbArr.push({
        title: `${selectedCC?.firstName} ${selectedCC?.lastName}`,
        route: `my-caregivers/view-caregiver/${selectedCCId}`,
        isCustom: true,
      });
      breadCrumbArr.push({
        title: "New Appointment",
        route: "",
      });
      dispatch(ActionCreator.pushBreadcrumb(breadCrumbArr));
    }
    fetchSlots();
  }, []);

  const handleCheckedTimeSlot = (index, childIdx) => {
    // debugger;
    const availabilitySlots = [...availability];
    const foundTimeSlots = [...availabilitySlots[index].time].map((i) => ({
      ...i,
      isChecked: false,
    }));
    foundTimeSlots[childIdx] = {
      ...foundTimeSlots[childIdx],
      isChecked: !foundTimeSlots[childIdx]?.isChecked,
    };
    let checkedCount = foundTimeSlots[childIdx]?.isChecked
      ? selectSlotsVisible + 1
      : selectSlotsVisible - 1;
    availabilitySlots[index].time = foundTimeSlots;
    const selectedSlots = [...selectedAvailableSlots];
    if (foundTimeSlots[childIdx]?.isChecked) {
      checkedCount =
        selectedAvailableSlots.filter(
          (i) =>
            moment(i.startTime).format("DD/MM/YYYY") !=
            moment(foundTimeSlots[childIdx].startTime).format("DD/MM/YYYY")
        )?.length + 1;
      setSelectedAvailableSlots((prev) => [
        ...prev.filter(
          (i) =>
            moment(i.startTime).format("DD/MM/YYYY") !=
            moment(foundTimeSlots[childIdx].startTime).format("DD/MM/YYYY")
        ),
        foundTimeSlots[childIdx],
      ]);
    } else if (!foundTimeSlots[childIdx]?.isChecked) {
      const filteredSlots = selectedSlots.filter(
        (i) => i._id != foundTimeSlots[childIdx]?._id
      );
      setSelectedAvailableSlots(filteredSlots);
    }
    const existingSlots = [...slot];
    slot.forEach((dt, index) => {
      debugger;
      const foundItem = [...selectedSlots, foundTimeSlots[childIdx]].find(
        (n) => {
          // console.log(moment(n?.startTime).format("DD/MM/YYYY"), moment(dt.date).format("DD/MM/YYYY"), "datesnew")
          return (
            moment(n?.startTime).format("DD/MM/YYYY") ===
            moment(dt.date).format("DD/MM/YYYY")
          );
        }
      );
      const filteredArr = [...selectedSlots, foundTimeSlots[childIdx]].filter(
        (n) => {
          // console.log(moment(n?.startTime).format("DD/MM/YYYY"), moment(dt.date).format("DD/MM/YYYY"), "datesnew")
          return (
            moment(n?.startTime).zone(0).format("DD/MM/YYYY") ===
            moment(dt.date).format("DD/MM/YYYY")
          );
        }
      );
      //    if(!existingSlots[index].time?.isAdded) {
      const isOverNight =
        moment(foundItem?.startTime).zone(0).format("DD") !=
        moment(foundItem?.endTime).zone(0).format("DD");
      existingSlots[index].time = filteredArr.map((timeObj) => ({
        startTime: moment(timeObj?.startTime).zone(0),
        endTime: "",
      }));
      // {
      //   startTime: moment(foundItem?.startTime).zone(0),
      //   endTime: moment(foundItem?.endTime).zone(0),
      // };
      existingSlots[index].isOverNight = isOverNight;
      //    }
    });
    setSlot(existingSlots);
    setSelectSlotsVisible(checkedCount);
    setAvailability(availabilitySlots);
  };
  const handleOverNight = (idx, availabilityIndex) => {
    const timeSlot = [...slot];
    if (timeSlot[idx].isOverNight) {
      timeSlot[idx].time[availabilityIndex] = { startTime: "", endTime: "" };
    }
    timeSlot[idx].isOverNight = !timeSlot[idx].isOverNight;
    setSlot(timeSlot);
  };
  const isNextDisabled =
    slot.length == 0 ||
    selectedAvailableSlots?.length != slot.length ||
    slot.some((i) => {
      const foundAvailability = selectedAvailableSlots.find(
        (a) =>
          moment(a.startTime).zone(0).format("DD-MM-YYYY") ==
          moment(i.date).format("DD-MM-YYYY")
      );
      if (foundAvailability) {
        return i.time[0]?.startTime == "" || i.time[0]?.endTime == "";
      }
    }) ||
    slot.some((i) => {
      const hours = Math.abs(
        moment
          .duration(moment(i.time[0]?.endTime).diff(i.time[0]?.startTime))
          .asHours()
      );
      if (!i.isOverNight) {
        return hours > 12;
      }
    });
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const flag = slot.some((i) => {
    const hours = Math.abs(
      moment
        .duration(moment(i.time[0]?.endTime).diff(i.time[0]?.startTime))
        .asHours()
    );
    if (!i.isOverNight) {
      return hours > 12;
    }
  });
  if (flag && availability.length == selectSlotsVisible) {
    message.error("Appointments must be a maximum duration of 12 hours.");
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const maxDate = moment().add(3, "months").endOf("month").toDate();
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 w-100 message-inner-row">
            <AppointmentNavBar />
            <div
              className="p-3 border-less rounded position-relative date-range-picker-wrapper date-range-picker-wrapper-custom"
              style={{ background: "#fff" }}
            >
              <div className="choose-date-div choose-date-div-updated">
                <p className="px-3 mb-2 fw-semibold px-mob-0 text-left-mobile fs-4">
                  What Dates Do You Require These Services For?
                </p>
                <Spin spinning={loading}>
                  <Row className={"p-3 px-mob-0 date_item"}>
                    <Col lg={12} xl={10} xs={24}>
                      {/* <DateRangePicker
                        ranges={range}
                        onChange={handleSelect}
                        minDate={moment().toDate()}
                        editableDateInputs={false}
                        showDateDisplay={false}
                        showMonthAndYearPickers={false}
                        showSelectionPreview={false}
                        renderStaticRangeLabel={false}
                        staticRanges={[]}
                        inputRanges={[]}
                      /> */}
                      <Calendar
                        // range
                        multiple
                        value={range}
                        onChange={(dateObject) => handleSelect(dateObject)}
                        mapDays={(obj) => {
                          const currentDate = new Date(obj.date);
                          // moment(obj?.date).format("DD-MM-YYYY");
                          const isDisabledDate = !occupiedArr.includes(
                            moment(currentDate).utc().format("DD-MM-YYYY")
                          );
                          if (isDisabledDate) {
                            return {
                              disabled: true,
                              style: { opacity: 0.5, color: "#c5c5c5" },
                            };
                          } else {
                            return {};
                          }
                          // let props = {}
                          // let isDisabledDate =
                          //   console.log(date, "object.date")
                        }}
                        minDate={moment().toDate()}
                        maxDate={maxDate}
                        weekStartDayIndex={0}
                      />
                    </Col>
                    {
                      // selectSlotsVisible > 0 &&
                      availability.length != selectSlotsVisible ? (
                        <Col
                          lg={12}
                          xl={14}
                          xs={24}
                          className="ps-4 text-start timing-container"
                        >
                          <p className="mb-0 fw-semibold fs-4">
                            Select Time Slot
                          </p>
                          <p className="fw-normal fs-7 color-secondary pb-15-mobile">
                            Please select one time slot for each date in order
                            to select a minimum 3 hour booking with our
                            caregivers/companions.
                          </p>
                          {/* <img src="/images/icons/info-circle.svg" width={20} className="info-icon-tip" onClick={handleInfoModal} /> */}
                          <Row className="p-3 timing-list">
                            <Col xs={12} lg={4} xl={4}>
                              <p className="color-secondary">Date</p>
                            </Col>
                            <Col xs={12} lg={18} xl={18}>
                              <p className="color-secondary">Time Slots</p>
                            </Col>
                            {availability?.length > 0 &&
                              availability.map((s, index) => {
                                return (
                                  <>
                                    <Col xs={12} lg={4} xl={4}>
                                      <p className="fw-bold mt-3">
                                        {moment(s?.date, "YYYY/MM/DD").format(
                                          "MMM DD"
                                        )}
                                      </p>
                                    </Col>
                                    <Col
                                      xs={12}
                                      lg={18}
                                      xl={18}
                                      className="my-caregivers-slots-wrapper"
                                    >
                                      {s.time.map((i, childIdx) => {
                                        const foundItem =
                                          selectedAvailableSlots.find(
                                            (slot) => slot._id == i._id
                                          );
                                        const isOverNight =
                                          moment(i?.startTime)
                                            .zone(0)
                                            .format("DD") !=
                                          moment(i?.endTime)
                                            .zone(0)
                                            .format("DD");
                                        return (
                                          <p
                                            className={`slots-text slots-text__${
                                              i?.isChecked ? "active" : ""
                                            }`}
                                            style={
                                              isOverNight
                                                ? { width: "128px" }
                                                : {}
                                            }
                                            onClick={() =>
                                              handleCheckedTimeSlot(
                                                index,
                                                childIdx
                                              )
                                            }
                                          >
                                            {moment(i?.startTime)
                                              .zone(0)
                                              .format("hh a")}{" "}
                                            -{" "}
                                            {moment(i?.endTime)
                                              .zone(0)
                                              .format("hh a")}
                                            {isOverNight &&
                                              (!foundItem?.isChecked ? (
                                                <span>
                                                  <img
                                                    alt={""}
                                                    src="/images/icons/bed-time.svg"
                                                    width={17}
                                                  />
                                                </span>
                                              ) : (
                                                <span>
                                                  <img
                                                    alt={""}
                                                    src="/images/icons/bed-time-white.svg"
                                                    width={15}
                                                  />
                                                </span>
                                              ))}
                                          </p>
                                        );
                                      })}
                                    </Col>
                                  </>
                                );
                              })}
                          </Row>
                          {slot?.length > 0 && (
                            <Col lg={24} xs={17} xl={17}>
                              <Divider />
                              <Typography>
                                Total Hours:{" "}
                                <strong>{calculateHours(true) || 0}</strong>
                              </Typography>
                            </Col>
                          )}
                        </Col>
                      ) : (
                        !range[0]?.notEdited && (
                          <Col
                            lg={12}
                            xl={14}
                            xs={24}
                            className="ps-4 text-start timing-container"
                          >
                            <p className="mb-0 fw-semibold fs-4">
                              Select Timing
                            </p>

                            <p className="fw-normal fs-7 color-secondary">
                              Minimum 3 hr duration is mandatory
                              <img
                                alt={""}
                                src="/images/icons/info-circle.svg"
                                width={20}
                                className="info-icon-tip info-icon-tip-custom"
                                onClick={handleInfoModal}
                              />
                            </p>
                            <Row className="p-3 timing-list">
                              <Col xs={7} lg={4} xl={4}>
                                <p className="color-secondary">Date</p>
                              </Col>
                              <Col xs={8} lg={6} xl={6}>
                                <p className="color-secondary">Start Time</p>
                              </Col>
                              <Col xs={8} lg={6} xl={6}>
                                <p className="color-secondary">End Time</p>
                              </Col>
                              <Col xs={24} lg={4} xl={4}>
                                {/* <p className='color-secondary'>Date</p> */}
                              </Col>
                              <Col xs={24} lg={4} xl={4}>
                                {/* <p className='color-secondary'>Date</p> */}
                              </Col>
                              {slot?.length > 0 &&
                                slot.map((s, i) => {
                                  debugger;
                                  const filteredSlots = s.time.filter((a) => {
                                    if (a.startTime === "") {
                                      return moment(s.date);
                                    }
                                    return (
                                      moment(a.startTime)
                                        .zone(0)
                                        .format("DD-MM-YYYY") ==
                                      moment(s.date).format("DD-MM-YYYY")
                                    );
                                    // if (a.startTime === "") {
                                    //   return a.startTime === "";
                                    // } else {
                                    //   return (
                                    //     moment(a.startTime)
                                    //       .zone(0)
                                    //       .format("DD-MM-YYYY") ==
                                    //     moment(s.date).format("DD-MM-YYYY")
                                    //   );
                                    // }
                                  });
                                  return filteredSlots.map(
                                    (foundAvailability, availabilityIndex) => {
                                      const isOverNight = s?.isOverNight
                                        ? s?.isOverNight
                                        : foundAvailability?.isOverNight
                                        ? foundAvailability?.isOverNight
                                        : foundAvailability?.startTime &&
                                          foundAvailability?.endTime
                                        ? moment(foundAvailability?.startTime)
                                            .zone(0)
                                            .format("DD") !=
                                          moment(foundAvailability?.endTime)
                                            .zone(0)
                                            .format("DD")
                                        : false;
                                      // console.log(moment(s.time?.startTime).zone(0).format('DD'), moment(s.time?.endTime).zone(0).format('DD'), isOverNight, s, "isovernight")
                                      let disabledHours = [2, 3, 4, 5];
                                      let disabledStartingHours = [];
                                      let disabledEndingHours = [];
                                      // console.log(s, "slotsarr", foundAvailability)
                                      if (s?.isOverNight) {
                                        disabledStartingHours = [
                                          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                                        ];
                                        disabledEndingHours = [
                                          2, 3, 4, 5, 12, 13, 14, 15, 16, 17,
                                          18, 19, 20, 21, 22, 23,
                                        ];
                                      }
                                      const foundAvailableSlotForHours =
                                        selectedAvailableSlots.find(
                                          (obj) =>
                                            moment(obj.startTime)
                                              .zone(0)
                                              .format("DD/MM/YYYY") ===
                                            moment(s.date)
                                              .zone(0)
                                              .format("DD/MM/YYYY")
                                        );
                                      const choosenStartTime = moment(
                                        foundAvailableSlotForHours?.startTime
                                      )
                                        .zone(0)
                                        .format("HH");
                                      const choosenEndTime = moment(
                                        foundAvailableSlotForHours?.endTime
                                      )
                                        .zone(0)
                                        .format("HH");
                                      // .hours();
                                      if (isOverNight) {
                                        for (let n = 0; n < 24; n++) {
                                          if (
                                            (choosenEndTime < n &&
                                              n < choosenStartTime) ||
                                            (choosenEndTime > n &&
                                              n > choosenStartTime)
                                          ) {
                                            disabledStartingHours.push(n);
                                            disabledEndingHours.push(n);
                                          }
                                        }
                                      } else {
                                        debugger;
                                        for (
                                          let n = 0;
                                          n < choosenStartTime;
                                          n++
                                        ) {
                                          debugger;
                                          disabledStartingHours.push(n);
                                          disabledEndingHours.push(n);
                                        }
                                        for (
                                          let n = Number(choosenEndTime) + 1;
                                          n < 24;
                                          n++
                                        ) {
                                          debugger;
                                          disabledEndingHours.push(n);
                                          disabledStartingHours.push(n);
                                        }
                                      }
                                      disabledStartingHours =
                                        disabledStartingHours.map((i) =>
                                          convertToTwoDigits(i)
                                        );
                                      disabledEndingHours =
                                        disabledEndingHours.map((i) =>
                                          convertToTwoDigits(i)
                                        );
                                      disabledHours = disabledHours.map((i) =>
                                        convertToTwoDigits(i)
                                      );
                                      if (foundAvailability) {
                                        return (
                                          <>
                                            <Row
                                              style={{ width: "97%" }}
                                              className="position-relative width-full align-items-center"
                                            >
                                              <Col
                                                xs={7}
                                                lg={4}
                                                xl={4}
                                                style={
                                                  s?.isOverNight
                                                    ? { marginTop: "33px" }
                                                    : {}
                                                }
                                              >
                                                <p className="fw-bold mt-3">
                                                  {moment(
                                                    s?.date,
                                                    "YYYY/MM/DD"
                                                  ).format("MMM DD")}
                                                </p>
                                              </Col>
                                              <Col xs={8} lg={6} xl={6}>
                                                {s?.isOverNight && (
                                                  <p>
                                                    {moment(
                                                      s?.date,
                                                      "YYYY/MM/DD"
                                                    ).format("MMM DD")}
                                                  </p>
                                                )}
                                                {/* <TimePicker
                                                  use12Hours
                                                  // format={"h a"}
                                                  format={"hh:mm a"}
                                                  minuteStep={30}
                                                  size="large"
                                                  disabledHours={() => {
                                                    if (
                                                      disabledStartingHours.length >
                                                        0 ||
                                                      s?.isOverNight
                                                    ) {
                                                      return [
                                                        ...disabledStartingHours,
                                                        ...disabledHours,
                                                      ];
                                                    }
                                                    return disabledHours;
                                                  }}
                                                  inputReadOnly
                                                  style={{
                                                    width: "127px",
                                                    height: "50px",
                                                    marginBottom: "10px",
                                                  }}
                                                  placeholder={
                                                    isMobile ? "" : "Start Time"
                                                  }
                                                  showNow={false}
                                                  allowClear={false}
                                                  // disabled={allDay}
                                                  suffixIcon={
                                                    <img
                                                      alt={""}
                                                      src="/images/icons/down-arrow.svg"
                                                      width={20}
                                                    />
                                                  }
                                                  onChange={(e, date) =>
                                                    handleStartTime(
                                                      e,
                                                      s?.date,
                                                      i,
                                                      availabilityIndex
                                                    )
                                                  }
                                                  value={
                                                    slot[i]?.time[
                                                      availabilityIndex
                                                    ]?.startTime
                                                      ? moment(
                                                          slot[i]?.time[
                                                            availabilityIndex
                                                          ]?.startTime
                                                        )
                                                      : ""
                                                  }
                                                /> */}
                                                <Select
                                                  onChange={(e) =>
                                                    handleStartTime(
                                                      e,
                                                      s?.date,
                                                      i,
                                                      availabilityIndex
                                                    )
                                                  }
                                                  // value={
                                                  //   slot[i]?.time?.startTime
                                                  //     ? moment(
                                                  //         slot[i]?.time
                                                  //           ?.startTime
                                                  //       ).format("HH:mm")
                                                  //     : ""
                                                  // }
                                                  value={
                                                    slot[i]?.time[
                                                      availabilityIndex
                                                    ]?.startTime
                                                      ? moment(
                                                          slot[i]?.time[
                                                            availabilityIndex
                                                          ]?.startTime
                                                        ).format("HH:mm")
                                                      : ""
                                                  }
                                                >
                                                  {hourSlots?.map((item) => {
                                                    const isDisabled =
                                                      moment(item.end).format(
                                                        "HH:mm"
                                                      ) ===
                                                      `${moment(
                                                        foundAvailableSlotForHours?.endTime
                                                      )
                                                        .zone(0)
                                                        .format("HH")}:30`;
                                                    return (
                                                      <>
                                                        <Select.Option
                                                          value={moment(
                                                            item.start
                                                          ).format("HH:mm")}
                                                          disabled={
                                                            disabledStartingHours.length >
                                                              0 ||
                                                            s?.isOverNight
                                                              ? disabledStartingHours.includes(
                                                                  moment(
                                                                    item.start
                                                                  ).format("HH")
                                                                )
                                                              : disabledHours.includes(
                                                                  moment(
                                                                    item.start
                                                                  ).format("HH")
                                                                )
                                                          }
                                                        >
                                                          {moment(
                                                            item.start
                                                          ).format("hh:mm a")}
                                                        </Select.Option>
                                                        <Select.Option
                                                          value={moment(
                                                            item.end
                                                          ).format("HH:mm")}
                                                          disabled={
                                                            (disabledStartingHours.length >
                                                              0 ||
                                                            s?.isOverNight
                                                              ? disabledStartingHours.includes(
                                                                  moment(
                                                                    item.end
                                                                  ).format("HH")
                                                                )
                                                              : disabledHours.includes(
                                                                  moment(
                                                                    item.end
                                                                  ).format("HH")
                                                                )) || isDisabled
                                                          }
                                                        >
                                                          {moment(
                                                            item.end
                                                          ).format("hh:mm a")}
                                                        </Select.Option>
                                                      </>
                                                    );
                                                  })}
                                                </Select>
                                              </Col>
                                              <Col xs={8} lg={6} xl={6}>
                                                {s?.isOverNight && (
                                                  <p>
                                                    {moment(
                                                      s?.date,
                                                      "YYYY/MM/DD"
                                                    )
                                                      .add(1, "days")
                                                      .format("MMM DD")}
                                                  </p>
                                                )}
                                                {/* <TimePicker
                                                  use12Hours
                                                  // format={"h a"}
                                                  format={"hh:mm a"}
                                                  minuteStep={30}
                                                  size="large"
                                                  style={{
                                                    width: "127px",
                                                    height: "50px",
                                                    marginBottom: "10px",
                                                  }}
                                                  placeholder={
                                                    isMobile ? "" : "End Time"
                                                  }
                                                  inputReadOnly
                                                  showNow={false}
                                                  disabledHours={() => {
                                                    if (
                                                      disabledStartingHours.length >
                                                        0 ||
                                                      s?.isOverNight
                                                    ) {
                                                      return [
                                                        ...disabledEndingHours,
                                                        ...disabledHours,
                                                      ];
                                                    }
                                                    return disabledHours;
                                                  }}
                                                  // disabled={allDay}
                                                  allowClear={false}
                                                  suffixIcon={
                                                    <img
                                                      alt={""}
                                                      src="/images/icons/down-arrow.svg"
                                                      width={20}
                                                    />
                                                  }
                                                  onChange={(e) =>
                                                    handleEndTime(
                                                      e,
                                                      s?.date,
                                                      i,
                                                      // s?.isOverNight,
                                                      availabilityIndex
                                                    )
                                                  }
                                                  // value={
                                                  //   editAvailabilityData
                                                  //     ? moment(editAvailabilityData.startTime).utc()
                                                  //     : startTime
                                                  // }
                                                  value={
                                                    slot[i]?.time[
                                                      availabilityIndex
                                                    ]?.endTime
                                                      ? moment(
                                                          slot[i]?.time[
                                                            availabilityIndex
                                                          ]?.endTime
                                                        )
                                                      : ""
                                                  }
                                                /> */}
                                                <Select
                                                  onChange={(e) =>
                                                    handleEndTime(
                                                      e,
                                                      s?.date,
                                                      i,
                                                      availabilityIndex
                                                    )
                                                  }
                                                  // value={
                                                  //   slot[i]?.time?.endTime
                                                  //     ? moment(
                                                  //         slot[i]?.time?.endTime
                                                  //       ).format("HH:mm")
                                                  //     : ""
                                                  // }
                                                  value={
                                                    slot[i]?.time[
                                                      availabilityIndex
                                                    ]?.endTime
                                                      ? moment(
                                                          slot[i]?.time[
                                                            availabilityIndex
                                                          ]?.endTime
                                                        ).format("HH:mm")
                                                      : ""
                                                  }
                                                >
                                                  {hourSlots?.map((item) => {
                                                    const isDisabled =
                                                      moment(item.end).format(
                                                        "HH:mm"
                                                      ) ===
                                                      `${moment(
                                                        foundAvailableSlotForHours?.endTime
                                                      )
                                                        .zone(0)
                                                        .format("HH")}:30`;
                                                    return (
                                                      <>
                                                        <Select.Option
                                                          value={moment(
                                                            item.start
                                                          ).format("HH:mm")}
                                                          disabled={
                                                            disabledEndingHours.length >
                                                              0 ||
                                                            s?.isOverNight
                                                              ? disabledEndingHours.includes(
                                                                  moment(
                                                                    item.start
                                                                  ).format("HH")
                                                                )
                                                              : disabledHours.includes(
                                                                  moment(
                                                                    item.start
                                                                  ).format("HH")
                                                                )
                                                          }
                                                        >
                                                          {moment(
                                                            item.start
                                                          ).format("hh:mm a")}
                                                        </Select.Option>
                                                        <Select.Option
                                                          value={moment(
                                                            item.end
                                                          ).format("HH:mm")}
                                                          disabled={
                                                            (disabledEndingHours.length >
                                                              0 ||
                                                            s?.isOverNight
                                                              ? disabledEndingHours.includes(
                                                                  moment(
                                                                    item.end
                                                                  ).format("HH")
                                                                )
                                                              : disabledHours.includes(
                                                                  moment(
                                                                    item.end
                                                                  ).format("HH")
                                                                )) || isDisabled
                                                          }
                                                        >
                                                          {moment(
                                                            item.end
                                                          ).format("hh:mm a")}
                                                        </Select.Option>
                                                      </>
                                                    );
                                                  })}
                                                </Select>
                                              </Col>
                                              <Col
                                                xs={24}
                                                lg={4}
                                                xl={4}
                                                className="mode-icon-div"
                                                style={
                                                  s?.isOverNight
                                                    ? { marginTop: "33px" }
                                                    : {}
                                                }
                                              >
                                                {isOverNight &&
                                                  (s?.isOverNight ? (
                                                    <img
                                                      alt={""}
                                                      src="/images/icons/bed-time.svg"
                                                      width={20}
                                                      // onClick={() =>
                                                      //   handleOverNight(
                                                      //     i,
                                                      //     availabilityIndex
                                                      //   )
                                                      // }
                                                    />
                                                  ) : (
                                                    <img
                                                      alt={""}
                                                      src="/images/bedtime.png"
                                                      width={20}
                                                      // onClick={() =>
                                                      //   handleOverNight(
                                                      //     i,
                                                      //     availabilityIndex
                                                      //   )
                                                      // }
                                                    />
                                                  ))}
                                                <img
                                                  alt={""}
                                                  src="/images/icons/clear-icon.svg"
                                                  width={20}
                                                  className="mx-2"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    handleClearStartEndTime(
                                                      i,
                                                      availabilityIndex
                                                    )
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <Col xs={24} lg={4} xl={4}>
                                              {/* <p className='color-secondary'>Date</p> */}
                                            </Col>
                                          </>
                                        );
                                      }
                                    }
                                  );
                                })}
                            </Row>
                            {slot?.length > 0 && (
                              <Col lg={24} xs={17} xl={17}>
                                <Divider />
                                <Typography>
                                  Total Hours:{" "}
                                  <strong>{calculateHours() || 0}</strong>
                                </Typography>
                              </Col>
                            )}
                          </Col>
                        )
                      )
                    }
                  </Row>
                </Spin>
              </div>
              <div className="d-flex justify-content-between common-btn-row common-btn-row-choose-date px-mob-0 px-3 mt-1 my-caregiver-select-common-btn">
                <Button
                  // htmlType="submit"
                  onClick={() => navigate("/my-caregivers/services")}
                  className="ant-btn-round ant-btn-default btn-back auth-action-btn mt-3 mb-3 text-center"
                  shape="round"
                  size="large"
                  disabled={value === ""}
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  disabled={isNextDisabled}
                  onClick={handleSaveTiming}
                  className="btn-black auth-action-btn mt-3 mb-3"
                  shape="round"
                  type="primary"
                  size="large"
                >
                  Next
                </Button>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*// }*/}
      <InfoModal
        infoModalVisible={infoModalVisible}
        handleInfoModalClose={handleInfoModalClose}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const InfoModal = (props) => {
  const { infoModalVisible, handleInfoModalClose } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={infoModalVisible}
      onOk={handleInfoModalClose}
      onCancel={handleInfoModalClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div className="image-container">
        <img
          alt={""}
          src="/images/icons/info-group.svg"
          className="text-center"
        />
      </div>
      <p className="text-center mt-3">
        For the safety of our caregivers and companions we do not allow the
        users to start or end any appointment between 2 AM - 5 AM. Thank you for
        understanding.
      </p>
      <Button
        // htmlType="submit"
        onClick={handleInfoModalClose}
        className="btn-black mx-auto auth-action-btn mt-3 mb-3"
        shape="round"
        type="primary"
        size="large"
      >
        I understand
      </Button>
    </Modal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCaregiversSelectDates);

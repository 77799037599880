import React, { Component } from "react";
import "./password-strenght-meter.less";
import zxcvbn from "zxcvbn";

class PasswordStrengthMeter extends Component {
  createPasswordLabel = (result) => {
    switch (result) {
      case result < 2:
        return "Weak";
      case 3:
        return "Medium";
      case 4:
        return "Strong";
      default:
        return "Weak";
    }
  };

  render() {
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    const checkPass = (password) => {
      const isContainsUppercase = /^(?=.*[A-Z]).*$/;
      const isContainsLowercase = /^(?=.*[a-z]).*$/;
      const isContainsNumber = /^(?=.*[0-9]).*$/;
      const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
      let testCount = 0;
      if (isContainsNumber.test(password)) {
        testCount = testCount + 1;
      }
      if (isContainsLowercase.test(password)) {
        testCount = testCount + 1;
      }
      if (isContainsUppercase.test(password)) {
        testCount = testCount + 1;
      }
      if (isContainsSymbol.test(password)) {
        testCount = testCount + 1;
      }
      return testCount;
    };

    const checkPassCount = checkPass(password);

    return (
      <div className="password-strength-meter d-flex align-items-center">
        <label
          className="password-strength-meter-label w-40"
          style={{ fontSize: "12px", marginRight: "5px" }}
        >
          <strong>Password Strength:</strong>{" "}
          <span
            className={`strength-${this.createPasswordLabel(checkPassCount)}`}
          >
            {this.createPasswordLabel(checkPassCount)}
          </span>
        </label>
        <progress
          className={`w-40 password-strength-meter-progress strength-${this.createPasswordLabel(
            checkPassCount
          )}`}
          value={checkPassCount < 4 ? checkPassCount - 1 : checkPassCount}
          max="3"
        />
      </div>
    );
  }
}

export default PasswordStrengthMeter;

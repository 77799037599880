import axios from "axios";
import {
  GET_BANK_DETAILS,
  GET_CAREGIVER_PROFILE,
  GET_CLIENT_PROFILE,
  SAVE_ALL_NOTIFICATIONS,
} from "./profile.type";
import { setUser } from "../auth/auth.action";

const CaregiverProfileAction = (token) => async (dispatch) => {
  //console.log(token)
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/get-caregiver-profile`,
      {},
      {
        headers: {
          accessToken: token,
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      }
    );
    //console.log('caregiver profile',response.data)
    if (response.data.status === 200) {
      console.log(response, "caregiverresponse");
      dispatch(
        setUser({
          userDetails: { ...response.data.output },
          accessToken: token,
        })
      );
      dispatch({ type: GET_CAREGIVER_PROFILE, payload: response.data });
    }
    return response;
  } catch (error) {
    console.log("caregiver profile", error);
  }
};
export default CaregiverProfileAction;

export const fetchClientDetails = (token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/get-client-profile`,
      {},
      {
        headers: {
          accessToken: token,
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      }
    );
    //console.log('client profile',response.data)
    if (response.data.status === 200) {
      dispatch({ type: GET_CLIENT_PROFILE, payload: response.data });
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBankDetails = (token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/user/view-bank-account`,
      {},
      {
        headers: {
          accessToken: token,
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      }
    );
    console.log("client profile", response.data);
    if (response.data.status === 200) {
      dispatch({ type: GET_BANK_DETAILS, payload: response.data });
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const saveAllNotification = (notificationsList) => (dispatch) => {
  dispatch({ type: SAVE_ALL_NOTIFICATIONS, payload: notificationsList });
};

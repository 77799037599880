import {
  Row,
  Typography,
  Space,
  Table,
  Button,
  Modal,
  Col,
  Radio,
  Input,
  Form,
  Upload,
  Popover,
  Badge,
  Divider,
  Spin,
  Tooltip,
} from "antd";
import {
  ClockCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, useMemo } from "react";
import Sidebar from "../../../components/sidebar";
//  import NotificationsContent  from '../../components/Notifications/NotificationsContent'
import { connect, useDispatch, useSelector } from "react-redux";
import "./Expenses.less";
import axios from "axios";
import AddCardDetails from "../settings/addCardDetails";
import { setAlert } from "../../../redux/alert/alert.action";
import receipt from "../../../Images/receipt.svg";
import { paymentDetails } from "../../../redux/appointment/appointment.action";
import TextArea from "antd/lib/input/TextArea";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { selectedCC } from "../../../redux/appointment/appointment.action";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import Responsiveheader from "../../../components/Responsiveheader";
import Breadcrumbs from "../../../common/Breadcrumbs";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import Footer from "../../../components/Footer/Footer";
import TransformImgWrapper from "../../../common/TransformComponent/TransformComponent";
import { useRef } from "react";

const { Text } = Typography;

const Expenses = ({ accessToken }) => {
  const dispatch = useDispatch();
  const [expensesList, setExpensesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expenseLoading, setexpencseLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [ispageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [rejectpopup, setRejectpopup] = useState(false);
  const [successpopup, setSuccesspopup] = useState(false);
  const [id, setId] = useState();
  const [isPaymentScreen, setIsPaymentScreen] = useState(false);
  // const [addedCardsList, setCardsList] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [receiptpopup, setReceiptpopup] = useState(false);
  const [addCardPopUp, setAddCardPopUp] = useState(false);
  const [receiptData, setReceiptData] = useState([]);
  const [showCards, setShowCards] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const appointmentData = useSelector((state) => state.appointment);
  const [totalCount, setTotalCount] = useState(0);
  const userMode = useSelector((state) => state.loginData?.userMode);
  const expenseRef = useRef();

  // const [loading, setloading]=useState(false)
  const notificationsList = useSelector(
    (state) => state.profileDetails.allNotifications
  );
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const isUnreadNotification = useMemo(() => {
    return notificationsList.find((notification) => !notification.isRead);
  }, [notificationsList]);

  const cardImage = {
    Discover: "/images/icons/discover.png",
    "American Express": "/images/icons/amex.png",
    MasterCard: "/images/icons/mastercard.png",
    UnionCard: "/images/icons/unioncard.png",
    Visa: "/images/icons/visa.png",
    Diners: "/images/icons/diners.png",
    Jcb: "/images/icons/jcb.png",
  };

  const handleReceiptModalClose = () => {
    setReceiptpopup(false);
  };

  const handleChooseRelative = () => {
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...paymentDetails, scheduleAgain: false }));
  };

  const handlePopupVisible = () => {
    console.log("clicled");
    setPopupVisible(true);
  };

  const handleReceiptModalOpen = (record) => {
    setReceiptpopup(true);
    console.log(record, "record");
    setReceiptData(record);
    setId(record);
  };

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.expenses)
    );
  }, []);
  const isPaymentScreenClose = () => {
    console.log("isPaymentClosed");
    setIsPaymentScreen(false);
  };

  const isPaymentScreenOpen = () => {
    setIsPaymentScreen(true);
  };

  const handleApproved = (key) => {
    setReceiptpopup(false);
    setIsPaymentScreen(true);
    setId(key);
  };

  const handleUnsuccessModalClose = () => {
    setRejectpopup(false);
  };

  const handleSuccessModalClose = () => {
    setSuccesspopup(false);
  };

  const handleAddCardOpen = () => {
    console.log("it is in add card open");
    setAddCardPopUp(true);
    console.log(addCardPopUp, "addCardPopUp");
  };

  const handleAddCardClose = () => {
    setAddCardPopUp(false);
  };

  const ishandleRejected = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/approve-reject-expenses`,
          {
            id: id.id,
            type: "decline",
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setPage(1);
            setRejectpopup(false);
            setReceiptpopup(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            setRejectpopup(false);
            setReceiptpopup(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            setRejectpopup(false);
            setReceiptpopup(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          setRejectpopup(false);
          setReceiptpopup(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleRejected = async (key) => {
    console.log(token, "accessToken", key);
    setRejectpopup(true);
    setReceiptpopup(false);
    setId(key);
  };

  const handleCardChange = (e) => {
    setSelectedCard(e.target.value);
  };

  const handlePayment = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/appointment/approve-reject-expenses`,
          {
            id: id.id,
            type: "approve",
            cardId: selectedCard,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            // fetchExpenses(token);
            setPage(1);
            setIsPaymentScreen(false);
            setSuccesspopup(true);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });

      // axios
      //   .post(
      //     `${process.env.REACT_APP_BASE_URL}/appointment/pay`,
      //     {
      //       bookingId: appointmentData?.bookingDetails?.bookingDetails?._id,
      //       cardId: selectedCard,
      //     },
      //     {
      //       headers: {
      //         accessToken: token,
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     console.log("response", response);
      //     if (response.data.status === 200) {
      //       setSuccesspopup(true);
      //       dispatch(paymentDetails(response.data.output.result));
      //       setLoading(false);
      //     } else if (response.data.status >= 400) {
      //       setLoading(false);
      //       setSuccesspopup(true);
      //       dispatch(
      //         setAlert({
      //           alertType: "error",
      //           alertMsg: response?.data?.error?.message,
      //           alertOpen: true,
      //         })
      //       );
      //     } else {
      //       setLoading(false);
      //       setSuccesspopup(true);
      //       dispatch(
      //         setAlert({
      //           alertType: "error",
      //           alertMsg: "somthing went wrong! please try again.",
      //           alertOpen: true,
      //         })
      //       );
      //     }
      //   })
      //   .catch((err) => {
      //     // console.log("err", err)
      //     setLoading(false);
      //     setSuccesspopup(true);
      //     dispatch(
      //       setAlert({
      //         alertType: "error",
      //         alertMsg: "somthing went wrong! please try again.",
      //         alertOpen: true,
      //       })
      //     );
      //   });
    }
  };
  const fetchAddedCards = () => {
    const token = localStorage.getItem("token");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-card`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setCardDetails(response.data.output.list);
            // setCardsList(response.data.output.list);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          // console.log("err", err)
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchAddedCards();
  }, []);

  useEffect(() => {
    if (token) {
      fetchExpenses(token);
    }
  }, [token, page]);
  console.log(userMode, "userMode");
  const fetchExpenses = async (token) => {
    setexpencseLoading(true);
    if (token !== "") {
      const requestURL =
        userMode === "client"
          ? `${process.env.REACT_APP_BASE_URL}/appointment/list-expenses`
          : `${process.env.REACT_APP_BASE_URL}/appointment/list-caregiver-expenses`;

      axios
        .post(
          requestURL,
          {
            page: page,
            pageLimit: ispageLimit,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            if (page == 1) {
              setExpensesList(response.data.output.list);
            } else {
              setExpensesList((prev) => [
                ...prev,
                ...response.data.output.list,
              ]);
            }
            setTotalCount(response.data.output.resultCount);
            setexpencseLoading(false);
          } else if (response.data.status >= 400) {
            setexpencseLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setexpencseLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setexpencseLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  useEffect(() => {
    const onscrollHandler = debounce(
      () => {
        if (
          window.innerHeight + document.documentElement.scrollTop ===
          document.documentElement.offsetHeight
        ) {
          fetchExpenses(token);
        }
      },
      100,
      { leading: true }
    );

    window.addEventListener("scroll", onscrollHandler);
    return () => {
      window.removeEventListener("scroll", onscrollHandler);
    };
  }, []);

  const readNotification = async (notification) => {
    console.log(notification, "readNotification");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/read-notification`,
          {
            id: notification.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
          } else if (response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const NotificationRedirection = (notification) => {
    console.log(notification, "notification");
    switch (notification?.notificationType) {
      case "Appointment":
        {
          // navigate(`/${userMode}/appointments-dashboard/`)
          navigate(
            `/client/appointment/appointment-details/${notification.appointmentId}`
          );
        }
        break;
      case "Expense":
        {
          navigate("/client/expenses");
        }
        break;
      case "Review":
        {
          navigate("/dashboard/my-reviews");
        }
        break;
      default: {
      }
    }
  };

  const handleNotifications = (notification) => {
    NotificationRedirection(notification);
    if (!notification.isRead) {
      readNotification(notification);
    }
  };

  const NotificationsContent = (
    <div className="NotificationsContent">
      {notificationsList.map((item, key) => {
        return (
          <Row
            className="Notifications"
            style={{ background: !item.isRead && "#E7E8EA" }}
            onClick={() => handleNotifications(item)}
          >
            <Col span={4}>
              {item.notificationType === "Appointment" ||
              item.notificationType === "Expense" ? (
                <div style={{ paddingTop: "15px" }}>
                  <img
                    src="/images/icons/calender_N.png"
                    alt="user"
                    style={{
                      height: "35px",
                      width: "35px",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div style={{ paddingTop: "15px" }}>
                    <img
                      src="/images/icons/Review.jpeg"
                      alt="user"
                      style={{
                        height: "35px",
                        width: "35px",
                      }}
                    />
                  </div>
                </>
              )}
            </Col>
            <Col span={20}>
              <Row>
                <Text className="TextNotifications">
                  {item.notificationTitle}
                </Text>
              </Row>
              <div style={{ textAlign: "right", marginTop: "-7px" }}>
                <Text className="NotificationTime">
                  {moment(item.updatedAt).format("hh:mm A")}
                </Text>
              </div>
            </Col>
          </Row>
        );
      })}
      {/* <div className="paginationContainer">
        <LeftOutlined onClick={() => page >= 1 && setPage(page - 1)} />{" "}
        <RightOutlined onClick={() => setPage(page + 1)} />
      </div> */}
    </div>
  );
  const options = ["Show", "Hide", "Center"];
  const [arrow, setArrow] = useState("Show");
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const Mobile_columns = [
    {
      dataIndex: "title",
      key: "title",
      // width: "10%",
      width: 40,
      render: (_, record) => (
        <Tooltip placement="top" title={"referfereff"} arrow={mergedArrow}>
          <Space>
            <img
              src={record.image}
              alt="Img"
              className="Expenses_receipt_img"
              onClick={() => handleReceiptModalOpen(record)}
            />{" "}
            <Text
              className="expenses_client_title"
              style={{
                //  maxWidth
                // fontSize: "12px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                // maxWidth: '30%',

                textOverflow: "ellipsis",
              }}
            >
              {record.title}
            </Text>
          </Space>{" "}
        </Tooltip>
      ),
    },
    {
      dataIndex: "amount",
      key: "amount",

      // width: "10%",
      width: 90,

      render: (amount, record) => (
        <>
          <Row gutter={[24]}>
            {/* <Col span={24}><Text className="expenses_client_title">{record.title}</Text> */}

            {/* </Col> */}
            <Col span={24}>
              <Text className="expenses_client_amount">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(amount)}
              </Text>
            </Col>
            <Col span={24}>
              <img
                src={
                  !record?.relativeImage
                    ? "/images/icons/Profile.png"
                    : record?.relativeImage
                }
                alt="Img"
                className="expenses_client_receipt"
              />
              <Text className="expenses_client_relativeName">
                {record.relativeName}
              </Text>
            </Col>
          </Row>
        </>
      ),
    },

    {
      dataIndex: "key",
      key: "key",
      align: "right",
      width: 50,

      render: (_, key) => (
        <>
          {key.status === 0 && userMode === "client" && (
            <Space>
              <Button
                className="expenses_reject_Button"
                onClick={() => handleRejected(key, accessToken)}
              >
                Reject
              </Button>
              <Button
                className="expenses_approve_Button"
                onClick={() => handleApproved(key, accessToken)}
                shape="round"
                // type="primary"
                size="large"
              >
                Approve
              </Button>
            </Space>
          )}

          {key.status === 0 && userMode !== "client" && (
            <Button className="expenses_pending_Button">Pending</Button>
          )}

          {key.status == 1 && (
            <Button className="expenses_approved_Button">Approved</Button>
          )}
          {key.status == 2 && (
            <Button className="expenses_Rejected_Button">Rejected</Button>
          )}
        </>
      ),
    },
  ];

  const columns = [
    {
      dataIndex: "title",
      key: "title",
      width: "25%",
      render: (_, record) => (
        <Space>
          <img
            src={record.image}
            alt="Img"
            className="Expenses_receipt_img"
            onClick={() => handleReceiptModalOpen(record)}
          />{" "}
          <Text className="expenses_client_title">{record.title}</Text>
        </Space>
      ),
    },
    {
      dataIndex: "amount",
      key: "amount",
      width: "15%",
      render: (amount) => (
        <Text className="expenses_client_amount">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount)}
        </Text>
      ),
    },
    {
      dataIndex: "relativeName",
      key: "relativeName",
      width: "30%",
      render: (_, record) => (
        <Space>
          <img
            src={
              !record?.relativeImage
                ? "/images/icons/Profile.png"
                : record?.relativeImage
            }
            alt="Img"
            className="expenses_client_receipt"
          />
          <Text className="expenses_client_relativeName">
            {record.relativeName}
          </Text>
        </Space>
      ),
    },
    {
      dataIndex: "key",
      key: "key",
      align: "right",
      render: (_, key) => (
        <>
          {key.status === 0 && userMode === "client" && (
            <Space>
              <Button
                className="expenses_reject_Button"
                onClick={() => handleRejected(key, accessToken)}
              >
                Reject
              </Button>
              <Button
                className="expenses_approve_Button"
                onClick={() => handleApproved(key, accessToken)}
                shape="round"
                // type="primary"
                size="large"
              >
                Approve
              </Button>
            </Space>
          )}

          {key.status === 0 && userMode !== "client" && (
            <Button className="expenses_pending_Button">Pending</Button>
          )}

          {key.status == 1 && (
            <Button className="expenses_approved_Button">Approved</Button>
          )}
          {key.status == 2 && (
            <Button className="expenses_Rejected_Button">Rejected</Button>
          )}
        </>
      ),
    },
  ];
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    if (expenseRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = expenseRef.current;
      console.log(
        scrollTop.toFixed(0),
        scrollHeight,
        clientHeight,
        "sfsfsdfds"
      );
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (addedScrollHeight == scrollHeight) {
        if (expensesList.length < totalCount) {
          console.log("adasd");
          setPage((prev) => prev + 1);
        }
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };
  //  console.log(isMobile, "sss")
  return (
    <>
      {/*     {isMobile ? (*/}
      {/*       <>*/}
      {/*         <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
      {/*           <div className="p-3 pt-4 d-flex w-100">*/}
      {/*             <Responsiveheader   title="Expenses" />*/}
      {/*           </div>*/}
      {/*           <div className="container-fluid">*/}
      {/*             <div className="d-flex">*/}
      {/*               <div className="w-100 client_Expenses">*/}
      {/*                 <div className="expense-container">*/}
      {/*                   {expensesList.length > 0 ? (*/}
      {/*                     <Table*/}
      {/*                       dataSource={expensesList}*/}
      {/*                       columns={Mobile_columns}*/}
      {/*                       // style={{ height: "75vh" ,overflowY:'auto'}}*/}
      {/*                       scroll={{x:200, y:500 }}*/}
      {/*                       // responsive*/}
      {/*                       // pagination={{*/}
      {/*                       //   pageSizeOptions: ["10", "20"],*/}
      {/*                       //   pageSize: ispageLimit,*/}
      {/*                       //   total: expensesList.length,*/}
      {/*                       //   showSizeChanger: true,*/}
      {/*                       //   onChange: (page, ispageLimit) => {*/}
      {/*                       //     setPage(page);*/}
      {/*                       //     setPageLimit(ispageLimit);*/}
      {/*                       //   },*/}
      {/*                       // }}*/}
      {/*                     />*/}
      {/*                   ) : (*/}
      {/*                     <div className="antd_emptyItem">.nodata</div>*/}
      {/*                   )}*/}
      {/*                 </div>*/}
      {/*               </div>*/}
      {/*             </div>*/}
      {/*           </div>*/}

      {/*           <Space size={20}>*/}

      {/*<div style={{marginTop:'2rem'}}></div>*/}
      {/*</Space>*/}
      {/*         </div>*/}
      {/*       </>*/}
      {/*     ) : (*/}
      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div className="container-fluid appointment-dashboard">
          <div className="pt-5 d-flex client_Expenses">
            <Sidebar />
            <div className="mx-4 message-inner-row w-100 ">
              {/* <Breadcrumbs  /> */}
              <AppointmentNavBar />
              {/* <Row className="nav-bar">
                  <Text className="client_header">Expenses</Text>

                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={
                      userMode == "client"
                        ? { width: "15%" }
                        : { width: "10.5%" }
                    }
                  >
                    {userMode == "client" && (
                      <img
                        src="/images/icons/light-outline-plus.svg"
                        onClick={handleChooseRelative}
                      />
                    )}

                    <Popover
                      className="notification-icon"
                      placement="bottomRight"
                      content={NotificationsContent}
                      trigger="click"
                    >
                             {/* <Badge dot={isUnreadNotification}> 
                             

                {isUnreadNotification?
                <>
                <img src="/images/icons/ReadNotification.png"    style={{height:'30px',}}/>

                </>:<><img src="/images/icons/notification.svg" />
                </>

                }
                      
                     
                      {/* </Badge> 

                    </Popover>

                    <div onClick={handlePopupVisible}>
                      <strong>John Doe</strong>{" "}
                      <img
                        src="/images/icons/down-arrow.svg"
                        style={
                          popupVisible ? { transform: "rotate(180deg)" } : {}
                        }
                      />
                    </div>
                  </div>
                </Row> */}
              <div
                className="expense-container table-responsive"
                onScroll={handleScroll}
                ref={expenseRef}
              >
                {expensesList.length > 0 ? (
                  <div className="expense-custom-table-row">
                    {expensesList.map((record) => {
                      return (
                        <div className="expense-tr-common">
                          <div className="expense-td expense-title-td">
                            <Space>
                              <img
                                src={record.image}
                                alt="Img"
                                className="Expenses_receipt_img"
                                onClick={() => handleReceiptModalOpen(record)}
                              />{" "}
                              <Text className="expenses_client_title">
                                {record.title}
                              </Text>
                            </Space>
                          </div>
                          <div className="expense-td expense-amount-td">
                            <Text className="expenses_client_amount">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                              }).format(record.amount)}
                            </Text>
                          </div>
                          <div className="expense-td expense-relative-td">
                            <Space>
                              <img
                                src={
                                  !record?.relativeImage
                                    ? "/images/icons/Profile.png"
                                    : record?.relativeImage
                                }
                                alt="Img"
                                className="expenses_client_receipt"
                              />
                              <Text className="expenses_client_relativeName">
                                {record.relativeName}
                              </Text>
                            </Space>
                          </div>
                          <div className="expense-td expense-status-td">
                            <>
                              {record.status === 0 && userMode === "client" && (
                                <Space>
                                  <Button
                                    className="expenses_reject_Button"
                                    onClick={() =>
                                      handleRejected(record, accessToken)
                                    }
                                  >
                                    Reject
                                  </Button>
                                  <Button
                                    className="expenses_approve_Button"
                                    onClick={() =>
                                      handleApproved(record, accessToken)
                                    }
                                    shape="round"
                                    // type="primary"
                                    size="large"
                                  >
                                    Approve
                                  </Button>
                                </Space>
                              )}

                              {record.status === 0 && userMode !== "client" && (
                                <Button className="expenses_pending_Button">
                                  Pending
                                </Button>
                              )}

                              {record.status == 1 && (
                                <Button className="expenses_approved_Button">
                                  Approved
                                </Button>
                              )}
                              {record.status == 2 && (
                                <Button className="expenses_Rejected_Button">
                                  Rejected
                                </Button>
                              )}
                            </>
                          </div>
                        </div>
                      );
                    })}
                    <div className="text-center">
                      <Spin spinning={expenseLoading} />
                    </div>
                  </div>
                ) : expenseLoading ? (
                  <div className="no-data-wrapper">
                    <Spin />
                    {/* <span className="no-data-text">
                      Loading  ....
                    </span> */}
                  </div>
                ) : !expenseLoading ? (
                  <div className="no-data-wrapper">
                    <img
                      width={120}
                      src="/images/icons/bird-icon.svg"
                      alt="No data"
                    />
                    <span className="no-data-text">No Expenses</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/*        <div className="expense-container table-responsive">*/}
              {/*            {expensesList.length > 0 ? (*/}
              {/*                <Table*/}
              {/*                    dataSource={expensesList}*/}
              {/*                    columns={isMobile ? Mobile_columns : columns}*/}
              {/*                    bordered={false}*/}
              {/*                    className={"table-responsive"}*/}
              {/*                    pagination={{*/}
              {/*                        pageSizeOptions: ["10", "20"],*/}
              {/*                        pageSize: ispageLimit,*/}
              {/*                        total: expensesList.length,*/}
              {/*                        showSizeChanger: true,*/}
              {/*                        onChange: (page, ispageLimit) => {*/}
              {/*                            setPage(page);*/}
              {/*                            setPageLimit(ispageLimit);*/}
              {/*                        },*/}
              {/*                    }}*/}
              {/*                />) : expenseLoading ? (*/}
              {/*                <div className="no-data-wrapper">*/}
              {/*                    <Spin/>*/}
              {/*                    /!* <span className="no-data-text">*/}
              {/*  Loading  ....*/}
              {/*</span> *!/*/}
              {/*                </div>*/}

              {/*            ) : !expenseLoading ? (*/}

              {/*                <div className="no-data-wrapper">*/}
              {/*                    <img width={120}*/}
              {/*                        src="/images/icons/bird-icon.svg"*/}
              {/*                        alt="No data"*/}
              {/*                    />*/}
              {/*                    <span className="no-data-text">*/}
              {/*  No Expenses*/}
              {/*</span>*/}
              {/*                </div>*/}
              {/*            ) : ''}*/}
              {/*        </div>*/}
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {/*)}*/}

      <PaymentUnsuccessModal
        rejectpopup={rejectpopup}
        handleUnsuccessModalClose={handleUnsuccessModalClose}
        ishandleRejected={ishandleRejected}
        accessToken={accessToken}
        isMobile={isMobile}
      />
      <PaymentSuccessModal
        successpopup={successpopup}
        handleSuccessModalClose={handleSuccessModalClose}
      />
      <PaymentScreen
        isPaymentScreen={isPaymentScreen}
        isPaymentScreenClose={isPaymentScreenClose}
        handlePayment={handlePayment}
        cardDetails={cardDetails}
        cardImage={cardImage}
        setCardDetails={setCardDetails}
        handleCardChange={handleCardChange}
        selectedCard={selectedCard}
        showCards={showCards}
        handleAddCardOpen={handleAddCardOpen}
        appointmentData={appointmentData}
        id={id}
        isMobile={isMobile}
      />
      <AddCardModal
        cardDetails={cardDetails}
        setCardDetails={setCardDetails}
        addCardPopUp={addCardPopUp}
        setAddCardPopUp={setAddCardPopUp}
        setShowCards={setShowCards}
        showCards={showCards}
        isPaymentScreenOpen={isPaymentScreenOpen}
        isPaymentScreenClose={isPaymentScreenClose}
      />

      <ReceiptModal
        receiptpopup={receiptpopup}
        handleReceiptModalClose={handleReceiptModalClose}
        receiptData={receiptData}
        handleRejected={handleRejected}
        handleApproved={handleApproved}
        isMobile={isMobile}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    chooseRelativeModal: state.toggleModals.chooseRelativeModal,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);

const PaymentUnsuccessModal = (props) => {
  const {
    rejectpopup,
    handleUnsuccessModalClose,
    ishandleRejected,
    key,
    isMobile,
  } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={rejectpopup}
      // onOk={ishandleRejected}
      onCancel={handleUnsuccessModalClose}
     closable={false}
    >
      <div className="image-container">
        <img
          alt=""
          src="/images/icons/payment-unsuccessful.svg"
          className="text-center"
        />
      </div>
      <h3 className="mt-3 fw-semibold expense_popup_text text-center">
        Are you sure you want to reject this expense?
      </h3>

      <Row
        gutter={[12, 24]}
        // className="Close_modal"
      >
        <Col span={isMobile ? 12 : 10}>
          <Button
            // htmlType="submit"
            onClick={() => ishandleRejected(key)}
            className=" mx-auto  expense_reject_Okay"
            shape="round"
            type="primary"
            size="large"
          >
            Yes
          </Button>
        </Col>
        <Col span={isMobile ? 12 : 14}>
          <Button
            // htmlType="submit"
            onClick={handleUnsuccessModalClose}
            className="btn-black mx-auto expense_reject_No"
            shape="round"
            type="primary"
            size="large"
          >
            No
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const PaymentSuccessModal = (props) => {
  const { successpopup, handleSuccessModalClose } = props;
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={successpopup}
      onOk={handleSuccessModalClose}
      onCancel={handleSuccessModalClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      {/* <div className="Close-container">
                <CloseOutlined onClick={() => {
                    handleSuccessModalClose();
                    // navigate("/client/appointment/booking-confirmation");
                }}

                />
            </div> */}
      <div className="image-container">
        <img
          alt={""}
          src="/images/icons/info-group.svg"
          className="text-center"
        />
      </div>

      <h3 className="mt-3 fw-semibold fs-4 text-center">Payment Successful</h3>
      <p className="text-center mt-3">
        We have Successfully put a hold on your credit card
      </p>
      <Button
        // htmlType="submit"
        onClick={() => {
          handleSuccessModalClose();
          // navigate("/client/appointment/booking-confirmation");
        }}
        className="btn-black mx-auto auth-action-btn mt-3 Done_btn"
        shape="round"
        type="primary"
        size="large"
      >
        Done
      </Button>
    </Modal>
  );
};

const PaymentScreen = (props) => {
  const {
    isPaymentScreen,
    isPaymentScreenClose,
    handlePayment,
    cardDetails,
    handleCardChange,
    handleAddCardOpen,
    showCards,
    cardImage,
    selectedCard,
    id,
    isMobile,
  } = props;
  return (
    <Modal
      className="info-modal-container  "
      centered
      width={isMobile ? 1500 : 510}
      header={null}
      footer={null}
      open={isPaymentScreen}
      onCancel={isPaymentScreenClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      <div className="payment_modal">
        {/* <div className="Close-container ">
                    <CloseOutlined onClick={() => {

                        isPaymentScreenClose();
                    }}/>
                </div> */}
        <div className="image-container">
          <h2 className="text-center">Payment</h2>
        </div>
        <h5 className="mt-3 expense_payment_Text text-center">
          Your payment is only processed once the caregiver/companion accepts
          your request. your funds remain same in our trust account and only get
          released after each appointment is completed.
        </h5>
        <div className="expense_Payment_Details">
          <Row className="expense_payment_charges">
            <Text className="expense_Payment_Type">Cab charges</Text>
            <Text className="expense_Payment_Amount">$ {id?.amount || ""}</Text>
          </Row>
          {/* <Row className="expense_payment_charges">
          <Text className="expense_Payment_Type">Transaction Fee</Text>
          <Text className="expense_Payment_Amount">$ 00.20</Text>
        </Row> */}
        </div>
        <div className="expense_Payment_Total_Details">
          <Row className="expense_payment_Total_charges">
            <Text className="expense_payment_Total_Type">Total Amount</Text>
            <Text className="expense_Payment_Amount">$ {id?.amount || ""}</Text>
          </Row>
        </div>

        <h3 className="card-select-text">Select Card</h3>
        {cardDetails.length == 0 ? (
          <div className="no-cards-wrapper text-center p-3">
            <img alt="" src="/images/icons/no-cards.svg" />
            <Button
              className="add-card-btn btn-black auth-action-btn mt-3 mb-3"
              shape="round"
              type="primary"
              size="large"
              style={{ margin: "auto" }}
              onClick={() => {
                handleAddCardOpen();
                isPaymentScreenClose();
              }}
            >
              Add Card
            </Button>
          </div>
        ) : (
          <div className="expenses_payment_cardContainer">
            <Radio.Group
              onChange={handleCardChange}
              value={selectedCard}
              direction="vertical"
            >
              {cardDetails.map((i) => {
                return (
                  <>
                    <Radio
                      value={i?.id}
                      direction="vertical"
                      className="expenses_radio_card expenses_radio_Radio"
                    >
                      <div className="expenses_radio_card">
                        <Row>
                          <span
                            className="masked-number"
                            style={{
                              margin: "5.5px 30px 0 0",
                              fontSize: "24px",
                            }}
                          >
                            **** **** ****
                          </span>
                          <span
                            style={{
                              margin: "5.5px 30px 0 0",
                              fontSize: "24px",
                            }}
                          >
                            {i?.last4}
                          </span>
                        </Row>
                        <Row
                          style={{ margin: "20px 0px" }}
                          // className={"Card_margin"}
                        >
                          <div
                            style={{ fontSize: "14px", marginRight: "12vw" }}
                          >
                            CVV
                            <br /> <span>***</span>
                          </div>
                          <img alt={""} src={cardImage[i?.brand]} width={60} />
                        </Row>
                      </div>
                    </Radio>

                    <div style={{ marginTop: "10px" }} />
                  </>
                );
              })}
            </Radio.Group>
          </div>
        )}

        <Row gutter={[12, 24]}>
          <Col span={isMobile ? 12 : 10}>
            <Button
              // htmlType="submit"
              onClick={isPaymentScreenClose}
              className=" mx-auto expense_reject_Okay expense_cancel_Okay"
              shape="round"
              type="primary"
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col span={isMobile ? 12 : 14}>
            <Button
              // htmlType="submit"
              onClick={handlePayment}
              className="btn-black mx-auto expense_pay_popup_okay"
              shape="round"
              type="primary"
              size="large"
              disabled={!selectedCard}
            >
              Pay
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const AddCardModal = (props) => {
  const {
    cardDetails,
    setCardDetails,
    showCards,
    addCardPopUp,
    setAddCardPopUp,
    setShowCards,
    isPaymentScreenOpen,
  } = props;

  console.log(showCards, "showCards");
  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={addCardPopUp}
    >
      <div className="expenses_new_cardContainer" style={{ height: "57vh" }}>
        <h3
          style={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Add New Card
        </h3>
        <AddCardDetails
          setShowCards={setShowCards}
          setCardDetails={setCardDetails}
          setAddCardPopUp={setAddCardPopUp}
          isPaymentScreenOpen={isPaymentScreenOpen}
        />
        <Button
          className="buttoncancel mt-4"
          shape="round"
          size="large"
          onClick={() => {
            setAddCardPopUp(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const ReceiptModal = (props) => {
  const userMode = useSelector((state) => state.loginData?.userMode);
  const {
    receiptpopup,
    handleReceiptModalClose,
    receiptData,
    handleRejected,
    handleApproved,
    isMobile,
  } = props;
  return (
    <Modal
      className="info-modal-container "
      centered
      width={390}
      header={null}
      footer={null}
      open={receiptpopup}
      onOk={handleReceiptModalClose}
      onCancel={handleReceiptModalClose}
      closable
      closeIcon={<CloseCircleOutlined />}
    >
      {/* <div className="Close-container Receipt_Modal">
                <CloseCircleOutlined style={{fontSize: '30px'}} onClick={() => {

                    handleReceiptModalClose("activeReceipt");
                }}/>
            </div> */}

      <div className="Expense_receipt">
        <div className="text-center">
          <Text className="expenseText">Expense</Text>
          <div className="ExpenseImg ExpenseImg-custom">
            {/* <img
              src={receiptData.image}
              className="ExpenseImg_image"
              alt="image"
            /> */}

            <TransformImgWrapper>
              <div style={{ cursor: "pointer" }}>
                <img
                  src={receiptData.image}
                  className="ExpenseImg_image"
                  alt="image"
                />
              </div>
            </TransformImgWrapper>
          </div>
        </div>
        {receiptData.status == 0 && userMode === "client" && (
          <Row gutter={24}>
            <Col span={10} xs={10}>
              <Button
                // htmlType="submit"
                onClick={() =>
                  handleRejected(receiptData, "activeReceipt", "Reject")
                }
                className=" mx-auto expenseReceipt_reject"
                shape="round"
                type="primary"
                size="large"
              >
                Reject
              </Button>
            </Col>
            <Col span={14} xs={14}>
              <Button
                // htmlType="submit"
                // onClick={handleUnsuccessModalClose}
                className="btn-black mx-auto expenseReceipt_approve"
                shape="round"
                type="primary"
                size="large"
                onClick={() =>
                  handleApproved(receiptData, "activeReceipt", "Approve")
                }
              >
                Approve
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

import { toExpression } from "@babel/types";
import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;

const tokenValidate = (config) => {
  const token = localStorage.getItem("token");

  config.headers.accessToken = token;
  config.headers.Authorization = 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD);

  return config;
};
const HttpService = axios.create({ baseURL: URL });

HttpService.interceptors.request.use(tokenValidate);

export  { HttpService };

import "../style.less";
const StripeInformation = () => {
  return (
    <div className="stripeinfo">
      <h2>Stripe Services Agreement — Canada</h2>
      <p>Last updated: May, 2023</p>

      <h3>Welcome to Stripe.</h3>
      <p>
        This Stripe Services Agreement (“Agreement”) is a legal agreement
        between Stripe Payments Canada, Ltd. (“Stripe”, “us”, or “we”) and the
        entity or person (“you”, “your”, or “user”) who registered on the Stripe
        Account page to receive certain payment, analytics and benchmarking, and
        other business services that may be offered by Stripe and its affiliates
        (each, a “Service”). This Agreement describes the terms and conditions
        that apply to your use of the Services.
      </p>
      <p>
        If you do not understand any of the terms of this Agreement, please{" "}
        <a href="https://stripe.com/en-ca/contact">contact us</a> before using
        the Services.
      </p>
      <p>
        You may not access or use any Services unless you agree to abide by all
        of the terms and conditions in this Agreement.
      </p>

      <h3>Required Regulatory Disclosures</h3>
      <p>
        We are required by law to provide to you at the beginning of our
        agreement with boxes that contain a summary of certain information from
        the agreement and disclosure of our fees. This information is described
        in more detail in the body of the agreement and our fees are also set
        out in the{" "}
        <a href="https://stripe.com/en-ca/ca/pricing">Fee Schedule</a>.
      </p>
      <h3>Information Summary Box</h3>
      <table className="t1">
        <tbody>
          <tr>
            <td>Date of Contract</td>
            <td>
              The Agreement is effective upon the date you agree to it (by
              electronically indicating acceptance) and continues so long as you
              use the Service or until terminated by Stripe.
            </td>
          </tr>
          <tr>
            <td>Acquirer</td>
            <td>
              Stripe Payments Canada, Ltd.{" "}
              <a href="mailto:info@stripe.com">info@stripe.com</a>{" "}
              <a href="https://support.stripe.com/email/login">
                https://support.stripe.com/email/login
              </a>
            </td>
          </tr>
          <tr>
            <td>Cancellation of contract and any applicable penalties</td>
            <td>
              You may terminate this Agreement by closing your Stripe account at
              any time by following the instructions on our website in your
              Account Profile. We may terminate this Agreement and close your
              Stripe account at any time for any reason effective upon providing
              you notice in accordance with this Agreement. There are no
              penalties for termination. However, termination does not alter
              your liability for processed payments or related chargebacks.
            </td>
          </tr>
          <tr>
            <td>Complaint handling procedures</td>
            <td>
              Merchant complaints should be submitted to Stripe Support. Our
              complaint handling process is accessible at{" "}
              <a href="https://support.stripe.com.">
                https://support.stripe.com.
              </a>
            </td>
          </tr>
          <tr>
            <td>Information about Payment Terminal</td>
            <td>
              This disclosure only applies to users who purchase Stripe Terminal
              Products as defined in the{" "}
              <a href="https://stripe.com/en-ca/terminal-purchase/legal">
                Stripe Terminal Purchase Terms{" "}
              </a>
              . <br />
              Purchase Pricing: Point-of-sale equipment may be purchased at
              then-current pricing specified at the time of your purchase.{" "}
              <br /> Fees: Fees associated with use of point-of-sale equipment
              are set forth in the Other Fee Disclosure Box below. <br />{" "}
              Termination: You may terminate the agreement for services related
              to point-of-sale equipment if you notify Stripe and stop using
              those services (which you may do at any time), if you provide
              Stripe with 60 days’ notice (for any or no reason) or if Stripe
              breaches the agreement and does not cure the breach within 30 days
              after your notice to Stripe. Stripe may terminate the agreement
              for services related to point-of-sale equipment immediately by
              notice to you if one or more of your other agreements with Stripe
              is terminated, if Stripe provides you with 60 days’ notice (for
              any or no reason) or if you breach the agreement and do not cure
              the breach within 30 days after our notice to you. There are no
              penalties for termination. However, termination will not affect
              any provisions that give rise to an ongoing obligation or
              limitations.
            </td>
          </tr>
          <tr>
            <td>Transaction return policy</td>
            <td>
              To learn more about how to issue refunds and the terms and
              conditions associated with refunds, please visit:{" "}
              <a href="https://support.stripe.com/questions/how-do-i-issue-refunds">
                https://support.stripe.com/questions/how-do-i-issue-refunds
              </a>
            </td>
          </tr>
          <tr>
            <td>Code of Conduct</td>
            <td>
              The Code of Conduct for the Credit and Debit Card Industry is
              available at the{" "}
              <a href="https://www.canada.ca/en/financial-consumer-agency.html">
                FCAC's website
              </a>
              .
            </td>
          </tr>
          <tr>
            <td>Statements</td>
            <td>
              Your transaction history will be available to you on your
              dashboard when you login to your Stripe account on our{" "}
              <a href="https://dashboard.stripe.com/login?redirect=https%3A%2F%2Fsupport.stripe.com%2F">
                website
              </a>
              .
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <h3>Fee Disclosure Box</h3>
      <p>
        “Card-Not-Present” means that the card was not electronically read (e.g.
        online payment).
      </p>
      <table>
        <tbody>
          <tr>
            <th>Payment card type</th>
            <th>Processing method: card-not-present</th>
            <th>Processing method: card-present</th>
          </tr>
          <tr>
            <td>Visa Classic</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Visa Infinite</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Visa Infinite Privilege</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Visa Business</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Visa Prepaid</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Visa Debit</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard Classic</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard World</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard World Elite</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard Business</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard Debit</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard Prepaid</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>American Express</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Discover Consumer</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Discover Commercial</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Diners Club International (Domestic)</td>
            <td>2.9% + CA$0.30</td>
            <td>2.7% + CA$0.05</td>
          </tr>
          <tr>
            <td>Successful charge presented in USD</td>
            <td>2.9% + US$0.30</td>
            <td>n/a</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <h3>Other Fees Disclosure Box</h3>
      <table>
        <tbody>
          <tr>
            <td>Visa International Cards presented in CAD</td>
            <td>3.5% + CA$0.30</td>
            <td>3.3% + CA$0.05</td>
          </tr>
          <tr>
            <td>MasterCard International Cards presented in CAD</td>
            <td>3.5% + CA$0.30</td>
            <td>3.3% + CA$0.05</td>
          </tr>
          <tr>
            <td>Discover International Cards presented in CAD</td>
            <td>3.5% + CA$0.30</td>
            <td>3.3% + CA$0.05</td>
          </tr>
          <tr>
            <td>
              Diners Club International (Non-Domestic) Cards presented in CAD
            </td>
            <td>3.5% + CA$0.30</td>
            <td>3.3% + CA$0.05</td>
          </tr>
          <tr>
            <td>International cards presented in CAD</td>
            <td>3.5% + CA$0.30</td>
            <td>3.3% + CA$0.05</td>
          </tr>
          <tr>
            <td>International cards presented in USD</td>
            <td>3.5% + US$0.30</td>
            <td>n/a</td>
          </tr>
          <tr>
            <td>Currency Conversion Fee</td>
            <td>
              2% above the daily mid market rate. <br />
              <a href="https://stripe.com/en-ca/docs/currencies/conversions">
                Learn more
              </a>{" "}
            </td>
            <td>
              2% above the daily mid market rate. <br />{" "}
              <a href="https://stripe.com/en-ca/docs/currencies/conversions">
                Learn more
              </a>
            </td>
          </tr>
          <tr>
            <td>Dispute Fee</td>
            <td>
              CA$15.00 or CA$0 Disputed payments, such as chargebacks, incur a
              fee. If the customer’s bank resolves the dispute in your favor,
              the fee is fully refunded. <br />
              <a href="https://stripe.com/en-ca/docs/disputes">Learn more</a>
            </td>
            <td>
              CA$15.00 or CA$0 Disputed payments, such as chargebacks, incur a
              fee. If the customer’s bank resolves the dispute in your favor,
              the fee is fully refunded. <br />{" "}
              <a href="https://stripe.com/en-ca/docs/disputes">Learn more</a>
            </td>
          </tr>
          <tr>
            <td>Terminal Services Fees</td>
            <td>n/a</td>
            <td>
              Any service fees associated with Stripe Terminal are included in
              the processing fees (2.7% + CA$0.05) or as you and Stripe agree.
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://support.stripe.com/questions/price-change-for-manually-entered-card-payments">
                Manually Entered Card
              </a>{" "}
              Fee
            </td>
            <td>
              0.5% in addition to the applicable processing fee. Fee to go into
              effect on May, 2023.
            </td>
            <td>n/a</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <h3>Section A: General Terms</h3>
      <h4>1. Overview of this Agreement</h4>
      <p>
        This Agreement provides a general description of the Services Stripe may
        provide to you, including those allowing you to accept payments from
        purchasers of your goods or services or donors to your organization
        (your “Customers”). We provide you with a more detailed description of
        the Services through published software libraries and application
        programming interfaces that may be used to access the Services (the
        “APIs”) and additional resources we make available to you on our
        website.
      </p>
      <p>
        Before using the Services, you must register with Stripe and create an
        account (a “Stripe Account”).
      </p>
      <p>
        Section A describes the process of registering for and using your Stripe
        Account.
      </p>
      <p>Section B describes your use of the APIs and the Services.</p>
      <p>
        Section C describes the Services you may use to accept payments and
        perform other financial transactions, manage subscriptions, and perform
        transaction reporting (the “Payment Services”).
      </p>
      <p>
        Section D describes proper handling, management, and use of data,
        generated during your use of the Services, including your Customers’
        data.
      </p>
      <p>
        Finally, Section E describes your liability to Stripe for all losses
        connected with your Stripe Account, your agreement to resolve all
        disputes with Stripe by arbitration and not in a lawsuit, and other
        legal terms that apply to you.
      </p>
      <br />
      <h4>2. Your Stripe Account</h4>
      <p>
        a. Registration and Permitted Activities: Only businesses (including
        sole proprietors), bona fide charitable organizations, and other
        entities or persons located in Canada are eligible to apply for a Stripe
        Account to use the Services described in this Agreement. Stripe and its
        affiliates may provide Services to you or your affiliates in other
        countries or regions under separate agreements.
      </p>
      <p>
        To register for a Stripe Account, you or the person or people submitting
        the application (your “Representative”) must provide us with your
        business or trade name, physical address, email, phone number, business
        identification number, URL, the nature of your business or activities,
        and certain other information about you that we require. We may also
        collect personal information (including name, birthdate, and
        government-issued identification number) about your beneficial owners,
        principals, and your Stripe Account administrator. Until you have
        submitted, and we have reviewed and approved all required information,
        your Stripe Account will be available to you on a preliminary basis
        only, and we may terminate it at any time and for any reason.
      </p>
      <p>
        If you use Payment Services, your name (or the name used to identify
        you) and URL may appear on your Customers’ bank or other statements. To
        minimize confusion and avoid potential disputes, these descriptors must
        be recognizable to your Customers and must accurately describe your
        business or activities. You may only use Payment Services to facilitate
        Transactions (as defined below) with your Customers. You may not use
        Payment Services to send money to others, to conduct any personal or
        noncommercial transactions, or for any other purposes prohibited by this
        Agreement.
      </p>
      <p>
        b. Business Representative: You and your Representative individually
        affirm to Stripe that your Representative is authorized to provide the
        information described in this Section A.2 on your behalf and to bind you
        to this Agreement. We may require you or your Representative to provide
        additional information or documentation demonstrating your
        Representative’s authority. Without the express written consent of
        Stripe, neither you nor your Representative may register or attempt to
        register for a Stripe Account on behalf of a user Stripe previously
        terminated from use of the Services.
      </p>
      <p>
        If you are a sole proprietor, you and your Representative also affirm
        that your Representative is personally responsible and liable for your
        use of the Services and your obligations to Customers, including payment
        of any amounts owed under this Agreement.
      </p>
      <p>
        The following special requirements apply to you if you are not at least
        18 years old. If you are a legal entity owned, directly or indirectly,
        by an individual who is not at least 18 years old, your Representative
        must either obtain the consent of your board or of an authorized
        officer; and if you are an individual or sole proprietor, and if you are
        not at least 18 years old, your Representative must be your parent or
        legal guardian. Any such approving board, authorized officer, parent, or
        legal guardian is responsible to Stripe and is legally bound to this
        Agreement as if it had agreed to the terms of this Agreement itself.
      </p>
      <p>
        c. Validation and Underwriting: At any time during the term of this
        Agreement and your use of the Services, we may require additional
        information from you to verify beneficial ownership or control of the
        business, validate information you provided, verify you or your
        Representative’s identity, and assess the risk associated with your
        business. This additional information may include business invoices,
        copies of government-issued identification, business licences, or other
        information related to your business, its beneficial owners or
        principals. If you use Payment Services, we may also request that you
        provide copies of financial statements or records pertaining to your
        compliance with this Agreement, or require you to provide a personal or
        company guarantee. Your failure to provide this information or material
        may result in suspension or termination of your Stripe Account.
      </p>
      <p>
        You authorize us to retrieve information about you from our service
        providers and other third parties, including credit reporting agencies
        and information bureaus, and you authorize and direct those third
        parties to compile and provide to us the information we request. You
        acknowledge that this may include your name, addresses, credit history,
        and other data about you or your Representative. You acknowledge that we
        may use your information to verify any other information you provide to
        us, and that any information we collect may affect our assessment of
        your overall risk to our business. You acknowledge that in some cases,
        such information may lead to suspension or termination of your Stripe
        Account. Stripe may periodically update this information as part of our
        underwriting criteria and risk analysis procedures.
      </p>
      <p>
        d. Changes to Your Business, Keeping your Stripe Account Current: You
        agree to keep the information in your Stripe Account current. You must
        promptly update your Stripe Account with any changes affecting you, the
        nature of your business activities, your Representatives, beneficial
        owners, principals, or any other pertinent information. We may suspend
        your Stripe Account or terminate this Agreement if you fail to keep this
        information current.
      </p>
      <p>
        You also agree to promptly notify us in writing no more than three days
        after any of the following occur: you are the subject of any voluntary
        or involuntary bankruptcy or insolvency application, petition or
        proceeding, receivership, bankruptcy, or similar action (any of the
        foregoing, a “Bankruptcy Proceeding”); there is an adverse change in
        your financial condition; there is a planned or anticipated liquidation
        or substantial change in the basic nature of your business; you transfer
        or sell 25% or more of your total assets, or there is any change in the
        control or ownership of your business or parent entity; or you receive a
        judgment, writ or warrant of attachment or execution, or levy against
        25% or more of your total assets.
      </p>
      <p>
        You will include us on the list and matrix of creditors as filed with
        any bankruptcy, commercial or civil court in connection with any
        Bankruptcy Proceeding, whether or not a claim may exist at the time of
        filing. Failure to do so will be cause for immediate termination of this
        Agreement and shall allow the pursuit of any other action available to
        us under applicable Network Rules or law. You agree that this Agreement
        is a contract for the advance of credit to you within the meaning of
        Section 11.01(b) of the Companies’ Creditors Arrangement Act (Canada)
        and within the meaning of Section 65.1(4)(b) of the Bankruptcy and
        Insolvency Act (Canada) and cannot be assigned by you in the event of a
        Bankruptcy Proceeding relating to you. You hereby acknowledge that, but
        for the agreement in the immediately preceding sentence, we would not
        have entered into this Agreement.
      </p>
      <br />
      <h4>3. Your Relationship with Your Customers</h4>
      <p>
        You may only use the Services for legitimate Transactions with your
        Customers. You know your Customers better than we do, and you are
        responsible for your relationship with them. Stripe is not responsible
        for the products or services you publicize or sell, or that your
        Customers purchase using the Services; or if you accept donations, for
        your communication to your Customers of the intended use of such
        donations. You affirm that you are solely responsible for the nature and
        quality of the products or services you provide, and for delivery,
        support, refunds, returns, and for any other ancillary services you
        provide to your Customers.
      </p>
      <p>
        Stripe provides Services to you but we have no way of knowing if any
        particular purchase, sale, donation, order, or other transaction (each a
        “Transaction”) is accurate or correct, or typical for your business. You
        are responsible for knowing whether a Transaction initiated by your
        Customer is erroneous (such as a Customer purchasing one item when they
        meant to order another) or suspicious (such as unusual or large
        purchases, or a request for delivery to a foreign country where this
        typically does not occur). If you are unsure if a Transaction is
        erroneous or suspicious, you agree to research the Transaction and, if
        necessary, contact your Customer before fulfilling or completing the
        Transaction. You are solely responsible for any losses you incur due to
        erroneous or fraudulent Transactions in connection with your use of the
        Services.
      </p>
      <h4>4. Fees and Fines</h4>
      <p>
        Stripe will provide the Services to you at the rates and for the fees
        (“Fees”) described in the{" "}
        <a href="https://stripe.com/en-ca/ca/pricing">Fee Schedule</a>, linked
        here and incorporated into this Agreement. The Fees include charges for
        Transactions (such as processing a payment) and for other events
        connected with your Stripe Account (such as handling a disputed charge).
      </p>
      <p>
        We reserve the right to revise our Fees at any time, provided that, with
        respect to increases in or the introduction of new Fees related to
        credit card and debit card transactions, such increase in or new Fees
        will be subject to a ninety (90) day notice period to you. For greater
        certainty, notification is not required for fee changes made in
        accordance with pre-determined fee schedules.
      </p>
      <p>
        We may charge additional Fees for cross-border transactions or foreign
        exchange services. In addition to the Fees, you are also responsible for
        any penalties or fines imposed on you or Stripe by any bank, money
        services business, payment network, financial institution, or other
        financial intermediary (each a “Financial Services Provider”) resulting
        from your use of Payment Services in a manner not permitted by this
        Agreement or a Financial Services Provider’s rules and regulations.
      </p>
      <p>
        You are also obligated to pay all taxes, fees and other charges
        (“Taxes”) imposed by any governmental authority, including without
        limitation any value added tax, goods and services tax, provincial sales
        tax and/or harmonized sales tax on the Services provided under this
        Agreement. If you are tax-exempt, you will provide us with an
        appropriate certificate or other evidence of tax exemption that is
        satisfactory to us.
      </p>
      <h4>5. Services and Stripe Account Support</h4>
      <p>
        We will provide you with support to resolve general issues relating to
        your Stripe Account and your use of the Services. This support includes
        resources and documentation that we make available to you through the
        current versions of Stripe’s{" "}
        <a href="https://support.stripe.com/">support pages</a>,{" "}
        <a href="https://stripe.com/en-ca/docs">API documentation</a>, and other
        pages on our <a href="https://stripe.com/en-ca/ca">website</a>{" "}
        (collectively, “Documentation”). The most efficient way to get answers
        to your questions is to review our Documentation. If you still have
        questions after reviewing the Documentation, please{" "}
        <a href="https://stripe.com/en-ca/contact">contact us</a>.
      </p>
      <p>
        You are solely responsible for providing support to Customers regarding
        Transaction receipts, product or service delivery, support, returns,
        refunds, and any other issues related to your products and services and
        business activities. We are not responsible for providing support for
        the Services to your Customers unless we agree to do so in a separate
        agreement with you or one of your Customers.
      </p>
      <h4>6. Taxes and Other Expenses</h4>
      <p>
        You are solely responsible (i) for determining what, if any, Taxes apply
        to your Transactions; and (ii) assessing, collecting, reporting, or
        remitting Taxes to the appropriate tax authority. We are not obligated
        to nor will we determine the applicability of any Taxes, or calculate,
        collect, report, or remit any Taxes to any tax authority arising from
        any Transaction, but we may withhold any amounts that we deem
        appropriate to cover such Taxes if we cannot validate any tax-related
        identification information you provide us. We may send documents to you
        and tax authorities for Transactions processed using the Services. You
        agree that we may send you any tax-related information electronically.
      </p>
      <h4>
        7. Service Limitations, Prohibited Activities, and Security Controls
      </h4>
      <p>
        a. Compliance with Applicable Laws: You must use the Services in a
        lawful manner, and must obey all laws, rules, regulations and codes of
        conduct (“Laws”) applicable to your use of the Services and to
        Transactions. As applicable, this may include compliance with domestic
        and international Laws related to the use or provision of financial
        services, notification and consumer protection, unfair competition,
        privacy, and false advertising, and any other Laws relevant to
        Transactions.
      </p>
      <p>
        b. Restricted Businesses and Activities: You may not use the Services
        for your benefit or the benefit of another, for any activities Stripe
        has identified as a{" "}
        <a href="https://stripe.com/en-ca/ca/restricted-businesses">
          restricted business or activity
        </a>{" "}
        (collectively, “Restricted Businesses”). Restricted Businesses include
        use of the Services in or for the benefit of a country, organization,
        entity, or person embargoed or blocked by any government, including
        those on sanctions lists identified by Canada.
      </p>
      <p>
        Please review the list of{" "}
        <a href="https://stripe.com/en-ca/ca/restricted-businesses">
          Restricted Businesses
        </a>
        thoroughly before registering for and opening a Stripe Account. If you
        are uncertain whether a category of business or activity is restricted
        or have questions about how these restrictions apply to you, please{" "}
        <a href="https://stripe.com/en-ca/contact">contact us</a>. We may add to
        or update the Restricted Business List at any time.
      </p>
      <p>
        c. Other Restricted Activities: You may not use the Services to
        facilitate illegal Transactions or to permit others to use the Services
        for noncommercial, personal, family or household purposes. In addition,
        you may not allow, and may not allow others to: (i) access or attempt to
        access non-public Stripe systems, programs, data, or services; (ii)
        copy, reproduce, republish, upload, post, transmit, resell, or
        distribute in any way, any data, content, or any part of the Services,
        Documentation, or our website except as expressly permitted by
        applicable Laws; (iii) act as service bureau or pass-through agent for
        the Services with no added value to Customers; (iv) transfer any rights
        granted to you under this Agreement; (v) work around any of the
        technical limitations of the Services or enable functionality that is
        disabled or prohibited; (vi) reverse engineer or attempt to reverse
        engineer the Services except as expressly permitted by Laws; (vii)
        perform or attempt to perform any actions that would interfere with the
        normal operation of the Services or affect use of the Services by our
        other users; or (ix) impose an unreasonable or disproportionately large
        load on the Service.
      </p>
      <p>
        d. Security Controls: We may provide or suggest security procedures and
        controls intended to reduce the risk to you of fraud (“Security
        Controls”). You agree to review the Security Controls and the
        Documentation that we provide to you, and to select the Security
        Controls that meet your business requirements. If you believe that the
        Security Controls we provide are insufficient, then you agree to
        separately implement additional controls that meet your requirements.
        You may review some of the details of our Security Controls{" "}
        <a href="https://stripe.com/en-ca/ca/security">on our website</a>.
      </p>
      <h4>8. Suspicion of Unauthorised or Illegal Use</h4>
      <p>
        We may refuse, condition, or suspend any Transactions that we believe
        may violate this Agreement or other agreements you may have with Stripe;
        or that expose you, Stripe, or others to risks unacceptable to Stripe.
        If we suspect or know that you are using or have used the Services for
        unauthorized, fraudulent, or illegal purposes, we may share any
        information related to such activity with the appropriate financial
        institution, regulatory authority, or law enforcement agency consistent
        with our legal obligations. This information may include information
        about you, your Stripe Account, your Customers, and Transactions made
        through your use of the Services.
      </p>
      <h4>9. Disclosures and Notices; Electronic Signature Consent</h4>
      <p>
        a. Consent to Electronic Disclosures and Notices: By registering for a
        Stripe Account, you agree that such registration constitutes your
        electronic signature, and you consent to electronic provision of all
        disclosures and notices from Stripe (“Notices”), including those
        required by Law. You also agree that your electronic consent will have
        the same legal effect as a physical signature.
      </p>
      <p>
        b. Methods of Delivery: You agree that Stripe can provide Notices
        regarding the Services to you through our website or through the
        Dashboard (as defined below), or by mailing Notices to the email or
        physical addresses identified in your Stripe Account. Notices may
        include notifications about your Stripe Account, changes to the
        Services, or other information we are required to provide to you. You
        also agree that electronic delivery of a Notice has the same legal
        effect as if we provided you with a physical copy. We will consider a
        Notice to have been received by you within 24 hours of the time a Notice
        is either posted to our website or emailed to you.
      </p>
      <p>
        c. SMS and Text Messages: You authorize us to provide Notices to you via
        text message to allow us to verify your or your Representative’s control
        over your Stripe Account (such as through two-step verification), and to
        provide you with other critical information about your Stripe Account.
        Standard text or data charges may apply to such Notices. Where offered,
        you may disable text message notifications in the Dashboard by
        responding to any such message with “STOP”, or by following instructions
        provided in the message. However, by disabling text messaging, you may
        be disabling important security procedures and controls intended to
        reduce the risk of fraud to you and your Customers (“Security Controls”)
        on your Stripe Account and may increase the risk of loss to your
        business.
      </p>
      <p>
        d. Requirements for Delivery: It should come as no surprise to you that
        you will need a computer or mobile device, Internet connectivity, and an
        updated browser to access your Dashboard and review the Notices provided
        to you. If you are having problems viewing or accessing any Notices,
        please contact us and we can find another means of delivery.
      </p>
      <p>
        e. Withdrawing Consent: Due to the nature of the Services, you will not
        be able to begin using the Services without agreeing to electronic
        delivery of Notices. However, you may choose to withdraw your consent to
        receive Notices electronically by terminating your Stripe Account.
      </p>
      <h4>10. Termination</h4>
      <p>
        a. Term and Termination: This Agreement is effective upon the date you
        first access or use the Services and continues until terminated by you
        or Stripe. You may terminate this Agreement by closing your Stripe
        Account at any time by opening{" "}
        <a href="https://dashboard.stripe.com/login?redirect=%2Faccount">
          the account information tab in your account settings
        </a>
        , selecting “close my account” and ceasing to use the Service. If you
        use the Services again or register for another Stripe Account, you are
        consenting to this Agreement. We may terminate this Agreement or close
        your Stripe Account at any time for any reason by providing you Notice.
        We may suspend your Stripe Account and your ability to access funds in
        your Stripe Account, or terminate this Agreement, if (i) we determine in
        our sole discretion that you are ineligible for the Services because of
        significant fraud or credit risk, or any other risks associated with
        your Stripe Account; (ii) you use the Services in a prohibited manner or
        otherwise do not comply with any of the provisions of this Agreement; or
        (iii) any Law or Financial Services Provider requires us to do so.
      </p>
      <p>
        b. Effects of Termination: Termination does not immediately relieve you
        of obligations incurred by you under this Agreement. Upon termination,
        you agree to (i) complete all pending Transactions, (ii) stop accepting
        new Transactions through the Service, and (iii) immediately remove all
        Stripe or payment network logos from your website (unless permitted
        under a separate licence with the payment network). Your continued or
        renewed use of the Services after all pending Transactions have been
        processed serves to renew your consent to the terms of this Agreement.
        If you terminate this Agreement, we will pay out any remaining funds
        owed to you in accordance with Section C.
      </p>
      <p>
        In addition, upon termination you understand and agree that (i) all
        licences granted to you by Stripe under this Agreement will end; (ii) we
        reserve the right (but have no obligation) to delete all of your
        information and account data stored on our servers within 10 days of
        termination; (iii) we will not be liable to you for compensation,
        reimbursement, or damages related to your use of the Services, or any
        termination or suspension of the Services or deletion of your
        information or account data; and (iv) you are still liable to us for any
        Fees or fines, or other financial obligation incurred by you or through
        your use of the Services prior to termination.
      </p>
      <br />
      <br />
      <h3>Section B: Stripe Technology</h3>
      <h4>1. APIs and Dashboard</h4>
      <p>
        Stripe has developed and provides access to the APIs that may be used to
        access the Services. You may use the APIs solely as described in the
        Documentation to process Transactions or use the Services on websites
        and through the applications identified in your Stripe Account. You may
        manage your Stripe Account, connect with other service providers, and
        enable additional features through the Stripe{" "}
        <a href="https://dashboard.stripe.com/">management dashboard</a>{" "}
        (“Dashboard”).
      </p>
      <p>
        You may use the APIs to utilize the Services on your website or inside
        your mobile applications. You may not use the API for any purpose,
        function, or feature not described in the Documentation or otherwise
        communicated to you by us. Due to the nature of the Services, we will
        update the APIs and Documentation from time to time, and may add or
        remove functionality. We will provide you Notice if we change,
        deprecate, or remove functionality from the API so that you may continue
        using the Services with minimal interruption.
      </p>
      <p>
        We will give you both publishable and secret API keys for live and test
        Transactions available through the Dashboard. Publishable keys identify
        Transactions with your Customers, and secret keys permit any API call to
        your Stripe Account. You are responsible for securing your secret keys —
        do not publish or share them with any unauthorized persons. Failure to
        secure your secret keys will increase the likelihood of fraud on your
        Stripe Account and potential losses to you or your Customers. You will
        contact us immediately if you become aware of any unauthorized use of
        your secret key or any other breach of security regarding the Services.
        We provide more details on proper use of publishable and secret API keys
        in the Documentation. Information on securing your Stripe Account is
        available in Section D.
      </p>
      <br />
      <h4>2. Ownership of Stripe IP</h4>
      <p>
        Stripe or its licensors exclusively own all rights, title, and interest
        in the patents, copyrights (including rights in derivative works), moral
        rights, rights of publicity, trademarks or service marks, logos and
        designs, trade secrets, and other intellectual property embodied by, or
        contained in the APIs, Services, Dashboard, and Documentation
        (collectively, “Stripe IP”) or any copies thereof. Stripe IP is
        protected by copyright, trade secret, patent, and other intellectual
        property Laws, and Stripe reserves all rights in Stripe IP not expressly
        granted to you in this Agreement.
      </p>
      <p>
        You may choose to or we may invite you to submit comments or ideas about
        improvements to the Service, our APIs, our platform, or any other
        component of our products or services (“Ideas”). If you submit an Idea
        to us, we will presume that your submission was voluntary, unsolicited
        by us, and delivered to us without any restrictions on our use of the
        Idea. You also agree that Stripe has no fiduciary or any other
        obligation to you in connection with any Idea you submit to us, and that
        we are free to use your Ideas without any attribution or compensation to
        you.
      </p>
      <br />
      <h4>3. Licence</h4>
      <p>
        Stripe grants you a nonexclusive and nontransferable licence to
        electronically access and use the Stripe IP only in the manner described
        in this Agreement. Stripe does not sell to you, and you do not have the
        right to sublicence the Stripe IP. We may make updates to the Stripe IP
        or new Services available to you automatically as electronically
        published by Stripe, but we may require action on your part before you
        may use the Stripe IP or new Services (including activation through the
        Dashboard, or acceptance of new or additional terms). Stripe may revoke
        or terminate this licence at any time if you use Stripe IP in a manner
        prohibited by this Agreement.
      </p>
      <p>
        You may not: (i) claim or register ownership of Stripe IP on your behalf
        or on behalf of others; (ii) sublicence any rights in Stripe IP granted
        by us; (iii) import or export any Stripe IP to a person or country in
        violation of any country’s export control Laws; (iv) use Stripe IP in a
        manner that violates this Agreement or Laws; or (v) attempt to do any of
        the foregoing.
      </p>
      <br />
      <h4>4. Stripe Marks; References to Our Relationship</h4>
      <p>
        We may make certain Stripe logos or marks (“Stripe Marks”) available for
        use by you and other users to allow you to identify Stripe as a service
        provider. To use Stripe Marks, you must first agree to the{" "}
        <a href="https://stripe.com/en-ca/ca/marks/terms">
          Stripe Marks Usage Agreement
        </a>
        . Stripe may limit or revoke your ability to use Stripe Marks at any
        time. You may never use any Stripe Marks or Stripe IP consisting of
        trademarks or service marks without our express permission, or in a
        manner that may lead people to confuse the origin of your products or
        services with ours.
      </p>
      <p>
        During the term of this Agreement, you may publicly identify us as the
        provider of the Services to you and we may publicly identify you as a
        Stripe user. If you do not want us to identify you as a user, please
        contact us. Neither you nor we will imply any untrue sponsorship,
        endorsement, or affiliation between you and Stripe. Upon termination of
        your Stripe Account, both you and Stripe will remove any public
        references to our relationship from our respective websites.
      </p>
      <h4>5. Content</h4>
      <p>
        You may use the Services to upload or publish text, images, and other
        content (collectively, “Content”) to your Stripe Account and to
        third-party sites or applications but only if you agree to obtain the
        appropriate permissions and, if required, licences to upload or publish
        any such Content using the Services. You agree to fully reimburse Stripe
        for all fees, fines, losses, claims, and any other costs we may incur
        that arise from publishing illegal Content through the Services, or
        claims that Content you published infringes the intellectual property,
        privacy, or other proprietary rights of others.
      </p>
      <h4>6. Use of Stripe Connect and Stripe Relay</h4>
      <p>
        a. Stripe Connect: The terms in this section only apply if you choose to
        connect your Stripe Account to a platform provider (each a “Platform”)
        using the Stripe Connect service.
      </p>
      <p>
        Stripe Connect allows Platforms to help you administer your Stripe
        Account and provide additional services directly to you or your
        Customers. You can connect your Stripe Account to Platforms when you
        register for your Stripe Account, or through the Dashboard. To use
        Stripe Connect, you must also agree to the{" "}
        <a href="https://stripe.com/en-ca/ca/connect-account/legal">
          Stripe Connected Account Agreement
        </a>
        . When you connect your Stripe Account to a Platform, you authorise
        Stripe to permit the Platform to: (i) access your Stripe Account and any
        Data (as defined in Section C) contained in your Stripe Account; (ii)
        create and manage Transactions with your Customers; and (iii) deduct
        amounts (for example, fees for use of the Platform) from funds payable
        to you from Transactions occurring in connection with the Platform
        (“Platform Fees”). You must separately agree with the Platform to pay
        any Platform Fees, and any Platform Fees will be in addition to Fees.
      </p>
      <p>
        Once you have authorised a Platform to connect to your Stripe Account,
        the Platform will continue to have access to your Stripe Account and
        will be authorised to perform the functions described in the Stripe
        Connected Account Agreement until you specifically withdraw your
        authorisation by changing the Stripe Connect settings in the Dashboard.
      </p>
      <p>
        b. Stripe Relay: The terms in this section only apply if you choose to
        publish or accept Transactions through your Stripe Account using Stripe
        Relay.
      </p>
      <p>
        You may publish product and service descriptions, SKUs, prices,
        inventory, images and other information describing your products or
        services and their variations (collectively, “Product Data”) to online,
        mobile, off-site or affiliate channels (“Apps”) using Stripe Relay.
        Stripe Relay also allows you to accept Transactions from your Customers
        through the Apps, and enables you to manage inventory and purchase
        information for such Transactions. As used in this section, “Order Data”
        means the Data (as defined in Section C) transmitted using your systems
        or systems you control, for the purpose of initiating or completing a
        Transaction, and which includes Product Data.
      </p>
      <p>
        We may provide Product Data through the Dashboard to the Apps connected
        to your Stripe Account, and the connected Apps may publish Product Data
        publicly as you provided it to us through Stripe Relay. You are solely
        responsible for obtaining the appropriate rights to publish Product Data
        through the Apps, and for the accuracy or inaccuracy of any pricing,
        inventory information, facts, or statements — even those made
        erroneously —contained in Product Data. You grant Stripe a worldwide,
        royalty-free, nontransferable licence to: (i) publish Product Data
        through the Apps you have connected your Stripe Account to; (ii) receive
        and transmit Order Data to you; (iii) use any trademarks, service marks,
        or Personal Data (as defined in Section C) contained in Product Data or
        Order Data to the extent required to perform either (i) or (ii); and
        (iv) authorize Stripe to sublicence such rights to Apps to facilitate
        your use of Stripe Relay. You agree to fully reimburse Stripe for all
        fees, fines, losses, claims, and any other costs we may incur that
        result from your use of Order Data or publication of Product Data
        through the Apps.
      </p>
      <p>
        You affirm that you will not knowingly publish any false or misleading
        Product Data, or use Stripe Relay to sell or attempt to sell any illegal
        products or services through the Apps, and that you are solely
        responsible for production, fulfilment, shipping, and provision of any
        ancillary services related to Transactions you receive through your use
        of Stripe Relay.
      </p>
      <p>
        c. Additional Services: From time to time we may offer you additional
        features or services that may be subject to additional or different
        terms of service. You may not use these additional services unless you
        agree to the applicable agreement or terms for those services.
      </p>
      <p>
        We may also provide you access to services identified as “beta” or
        pre-release services. You understand that these services are still in
        development, may contain bugs or errors, may be feature incomplete, may
        materially change prior to a full commercial launch, or may never be
        released commercially. We provide beta services AS IS, and without
        warranty of any kind, and your use of, or reliance on beta services is
        at your own risk.
      </p>
      <br />
      <br />
      <h3>Section C: Payment Services</h3>
      <br />
      <h4>1. Payment Services Overview</h4>
      <p>
        Stripe provides you Payment Services through various Financial Services
        Providers. Payment Services allow you to accept payment from your
        Customers for Transactions. We may limit or refuse to process Charges
        (as defined below) for any Restricted Businesses, or for Charges
        submitted in violation of this Agreement. To use the Payment Services,
        you must also accept separate agreements with the Financial Services
        Providers as described in Section 5.
      </p>
      <p>
        <b>
          The following terms used in this section relate to your use of Payment
          Services:
        </b>
      </p>
      <p>
        “Charge” means a credit or debit instruction to capture funds from an
        account that a Customer maintains with a bank or other financial
        institution in connection with a Transaction.
      </p>
      <p>
        “Refund” means an instruction initiated by you to return funds to a
        Customer for an existing Charge.
      </p>
      <p>
        “Dispute” means an instruction initiated by a Customer with a Financial
        Services Provider or us to return funds for an existing Charge
        (including a chargeback on a payment card network; or disputes on a
        debit network).
      </p>
      <p>
        “Reversal” means an instruction initiated by a Financial Services
        Provider or us to return funds for an existing Charge. Reversals may
        result from (i) invalidation of a charge by a Financial Services
        Provider; (ii) funds settled to you in error or without authorization;
        and (iii) submission of a Charge in violation of Network Rules, or where
        submission of the Charge or your use of Payment Services violates this
        Agreement.
      </p>
      <p>
        “Return” means an instruction initiated by you, a Customer, or a
        Financial Services Provider to return funds unrelated to an existing
        Charge.
      </p>
      <p>
        “Transfer” means an instruction to credit funds to or debit funds from
        an account you designate with a Financial Services Provider.
      </p>
      <p>
        “Fine” means any fines, levies, or other charges imposed by us or a
        Financial Services Provider caused by your violation of Laws or this
        Agreement, or as permitted by Network Rules.
      </p>
      <p>
        “Network Rules” means the guidelines, bylaws, rules, and regulations
        imposed by the Financial Services Providers that operate payment
        networks supported by Stripe (including the payment card network
        operating rules for the Visa, MasterCard, American Express, and Discover
        networks, and the rules of Payments Canada for Interac).
      </p>
      <h4>2. Registering for Use of Payment Services</h4>
      <p>
        When you register for a Stripe Account, you may be asked for financial
        information, or information we use to identify you, your
        Representatives, principals, beneficial owners, and other individuals
        associated with your Stripe Account. Throughout the term of this
        Agreement, we may share information about your Stripe Account with
        Financial Services Providers in order to verify your eligibility to use
        the Payment Services, establish any necessary accounts or credit with
        Financial Services Providers, monitor Charges and other activity, and
        conduct risk management and compliance reviews. We will review and may
        conduct further intermittent reviews of your Stripe Account information
        to determine that you are eligible to use the Payment Services. Stripe’s
        use of the information you provide to us under this Agreement is
        described in more detail in&nbsp;Section D.
      </p>
      <p>
        Stripe is not a bank and we do not provide loans or extend credit. If
        you accept payment for products or services (including events such as
        concerts or other performances) not immediately deliverable to the
        Customer (a “Preorder”), we may, in our sole discretion, initiate
        Reversals or hold&nbsp;Reserves&nbsp;for all or a portion of the Charges
        processed by us for a Preorder. If you would like to receive payment for
        a Preorder, please&nbsp;
        <a href="https://stripe.com/en-ca/contact">contact us</a>&nbsp;before
        doing so.
      </p>
      <h4>3. Processing Transactions, Disputes</h4>
      <p>
        You may only submit Charges through the Payment Services that are
        authorized by your Customers. To enable us to process Transactions for
        you, you authorize and direct Financial Services Providers to receive
        and pay any funds owed to you through the Payment Services, and you will
        identify Stripe as your agent for purposes of providing the Payment
        Services to you. You must not, other than as required by the Financial
        Services Terms or Payment Terms (each as defined below), grant or assign
        to any third party any lien on or interest in funds that may be owed to
        you under this Agreement until the funds are deposited into your Payout
        Account (as defined below).
      </p>
      <p>
        Except where Stripe and a Customer have otherwise agreed, you maintain
        the direct relationship with your Customers and are responsible for: (i)
        acquiring appropriate consent to submit Charges through the Payment
        Services on their behalf; (ii) providing confirmation or receipts to
        Customers for each Charge; (iii) verifying Customers’ identities; and
        (iv) determining a Customer’s eligibility and authority to complete
        Transactions. However, even authorized Transactions may be subject to a
        Dispute. Stripe is not responsible for or liable to you for authorized
        and completed Charges that are later the subject of a Dispute, Refund,
        or Reversal, are submitted without authorization or in error, or violate
        any Laws.
      </p>
      <p>
        You are immediately responsible to us for all Disputes, Refunds,
        Reversals, Returns, or Fines regardless of the reason or timing. In many
        but not all cases, you may have the ability to challenge a Dispute by
        submitting evidence through the API or the Dashboard. We may request
        additional information to provide to Financial Services Providers to
        assist you in contesting the Dispute, but we cannot guarantee that your
        challenge will be successful. Financial Services Providers may deny your
        challenge for any reason they deem appropriate. Where a challenge is
        entirely or partially successful, a Financial Services Provider may
        credit funds associated with the Charge that is the subject of the
        Dispute (or a portion thereof) to your Stripe Account.
      </p>
      <p>
        Please keep in mind that you are liable for all losses you incur when
        lost or stolen payment credentials or accounts are used to purchase
        products or services from you. Stripe does not and will not insure you
        against losses caused by fraud under any circumstances. For example, if
        someone pretends to be a legitimate buyer but is a fraudster, you will
        be responsible for any resulting costs, including Disputes, even if you
        do not recover the fraudulently purchased product. Even if we work with
        you to assist you or law enforcement in recovering lost funds, Stripe is
        not liable to you, or responsible for your financial losses or any other
        consequences of such fraud. We provide the Security Controls described
        in Section D.3 to help you mitigate the risk of fraud losses on your
        Stripe Account, and we strongly encourage you to review and use the
        Security Controls appropriate for your business.
      </p>
      <p>
        A Financial Services Provider or we may issue a Reversal for any Charge
        where a Charge is made without the account owner’s authorization, in
        connection with a Restricted Business, that violates the Network Rules,
        or for many other reasons. If a Financial Services Provider or we issue
        a Reversal, we will provide you Notice and a description of the cause of
        the Reversal.
      </p>
      <h4>4. Responsibilities and Disclosures to Your Customers</h4>
      <p>
        It is very important to us that your Customers understand the purpose,
        amount, and conditions of Charges you submit to us. With that in mind,
        when using the Payment Services you agree to: (i) accurately
        communicate, and not misrepresent, the nature of the Transaction, and
        the amount of the Charge in the appropriate currency prior to submitting
        it to the API; (ii) provide a receipt that accurately describes each
        Transaction to Customers; (iii) provide Customers a meaningful way to
        contact you in the event that the product or service is not provided as
        described; (iv) not use Services to sell products or services in a
        manner that is unfair or deceptive, exposes Customers to unreasonable
        risks, or does not disclose material terms of a purchase in advance; and
        (v) inform Customers that Stripe and its global affiliates process
        Transactions (including payment Transactions) for you. You also agree to
        maintain a fair return, refund, cancellation, or adjustment policy, and
        clearly explain the process by which Customers can receive a Refund.
      </p>
      <p>
        You may use some Payment Services to receive recurring or subscription
        payments from your Customers. If you use the Payment Services to submit
        these recurring or subscription Charges, you agree to comply with
        applicable Laws, including clearly informing Customers in advance of
        submitting the initial Charge that they will be charged on an ongoing
        basis and explaining the method for unsubscribing or cancelling their
        recurring billing or subscription.
      </p>
      <p>
        If you engage in Transactions with Customers who are individuals (i.e.
        consumers), you specifically agree to provide consumers disclosures
        required by Law, and to not engage in unfair or deceptive acts or
        practices.
      </p>
      <h4>5. Financial Services Providers and Payment Method Providers</h4>
      <p>
        Your use of the Payment Services is subject to additional terms that
        apply between you and one or more of Stripe, a Stripe affiliate, or a
        Financial Services Provider (“Financial Services Terms”). In addition,
        unique terms and conditions may also apply to specific payment methods
        or networks (“Payment Terms”). By using the Payment Services, you agree
        to the Financial Services Terms and applicable Payment Terms as set out
        on our&nbsp;<a href="https://stripe.com/en-ca/legal">legal page</a>
        &nbsp;(including those that separately bind you with Financial Services
        Providers or payment method providers). Additionally, a payment method
        provider may enforce the terms of this Agreement directly against you
        with respect to that payment method provider’s payment method(s).
      </p>
      <p>
        We may add or remove Financial Services Providers or payment methods at
        any time. The Financial Service Terms and Payment Terms may also be
        amended from time to time. Your continuing use of the Payment Services
        constitutes your consent and agreement to such additions, removals and
        amendments.
      </p>
      <p>
        You authorize Financial Services Providers to hold, receive, and
        disburse funds on your behalf; and to instruct such Financial Services
        Providers as to how and when funds are transferred to you. You also
        authorize us to designate which Financial Services Providers may hold
        settlement funds, on deposit and in trust, pending transfer of funds to
        you in accordance with the terms of this Agreement. A clearing account
        through which funds are settled will be maintained by a Financial
        Services Provider, and transfers to you from this account will be a full
        and final payment by the Financial Services Provider to you.
      </p>
      <h4>6. Specific Payment Methods</h4>
      <p>
        When accepting payment card payments, you must comply with all Network
        Rules applicable to merchants, including the Network Rules provided
        by&nbsp;
        <a href="http://usa.visa.com/merchants/operations/op_regulations.html">
          Visa
        </a>
        ,&nbsp;
        <a href="http://www.mastercard.com/us/merchant/support/rules.html">
          MasterCard
        </a>
        , and&nbsp;
        <a href="https://icm.aexp-static.com/content/dam/gms/en_ca/optblue/canada-mog.pdf">
          American Express
        </a>
        , the Network Rules of Discover (please&nbsp;
        <a href="https://www.discover.com/credit-cards/help-center/contact-us/">
          contact Discover
        </a>
        &nbsp;for a copy), and the Network Rules of Interac.
      </p>
      <p>
        These Network Rules state that you may only accept payment using payment
        cards for bona fide legal commercial transactions between you and your
        Customers for goods or services that are free of liens, claims, and
        encumbrances (other than ordinary sales taxes). You, may only use
        payment network trademarks or service marks consistent with the Network
        Rules, and the Network Rules also limit your ability to discriminate by
        card type or charge surcharges for acceptance of payment cards. You can
        choose to accept only credit or debit payments from a payment card
        network, without having to use the same payment network for both credit
        and debit payments.
      </p>
      <p>
        The payment card networks may amend the Network Rules at any time
        without notice to you, and Stripe reserves the right to change the
        Payment Services for payment card processing at any time to comply with
        the Network Rules. We may share the information you provide to us that
        we use to identify the nature of the products or services with Financial
        Services Providers, including assigning your business activities to a
        particular payment network merchant category code (MCC).
      </p>
      <p>
        Customers typically raise payment card network Disputes (i.e.
        chargebacks) when a merchant fails to provide the product or service to
        the Customer, or where the payment card account holder did not authorize
        the Charge. High chargeback rates (typically those exceeding 1%) may
        result in your inability to use the Payment Services. Failure to timely
        and effectively manage Disputes with your Customers may ultimately
        result in your inability to accept payment cards for your business.
      </p>
      <p>
        When you accept payment card Transactions, Network Rules specifically
        prohibit you from (i) providing cash refunds for a Charge on a payment
        card, unless required by Laws, (ii) accepting cash, its equivalent, or
        any other item of value for a Refund, (iii) acting as a payment
        intermediary or aggregator, or otherwise reselling Payment Services on
        behalf of others, (iv) submitting what you believe or know to be a
        fraudulent Charge, or (v) using Payment Services in a manner that is an
        abuse of Financial Services Providers’ networks or a violation of
        Network Rules.
      </p>
      <p>
        If you misuse the Payment Services for payment card transactions or
        engage in activity the payment card networks identify as damaging to
        their brand, or if we are required to do so by Network Rules, we may
        submit information about you, Representatives, Principals, beneficial
        owners and other individuals associated with your Stripe Account to the
        MATCH terminated merchant listing maintained by MasterCard and accessed
        and updated by Visa and American Express, or to the Consortium Merchant
        Negative File maintained by Discover. Addition to one of these lists may
        result in your inability to accept payments from payment cards. You
        understand and consent to our sharing this information and to the
        listing itself, and you will fully reimburse us for any losses we incur
        from third-party claims, and you waive your rights to bring any direct
        claims against us that result from such reporting.
      </p>
      <p>
        If your American Express Transaction volume exceeds the applicable
        threshold amount set by American Express, American Express may convert
        you to a direct American Express merchant. As a direct American Express
        merchant, you and American Express will enter into a definitive
        “American Express Card Acceptance Agreement,” which will govern your
        acceptance of American Express payment cards, and you and American
        Express must directly agree to your pricing and other fees payable for
        American Express Transaction processing. Stripe will continue to process
        your American Express Transactions in accordance with this Agreement,
        except that Stripe will incorporate the new pricing and fees into the
        applicable Stripe fees.
      </p>
      <h4>7. Transfers and Payout Schedule</h4>
      <p>
        a. Transfers For Your Payout Account:&nbsp;Transfers may be initiated by
        you to or from your Stripe Account to or from the account with a
        Financial Services Provider that you designate in the Dashboard (the
        “Payout Account”). A Positive balance in your Stripe Account will result
        in a Transfer to your Payout Account and negative balance in your Stripe
        Account will result in a Transfer from your Payout Account. All
        Transfers to your Payout Account will exclude Fees, Fines, and amounts
        owed to us for any reason. You affirm that you are authorized to
        initiate Transfers to and from the Payout Account.
      </p>
      <p>
        We may offer you the ability to have funds settled to your Payout
        Account in a currency different from the one in which you accepted
        payment from a customer (“Multi-Currency Processing”). To use this
        service, you must provide us with a valid Payout Account for each
        currency for which you request settlement, based on our list of
        available settlement currencies. We may add or remove currencies from
        our list of available settlement currencies at any time. If you use
        Multi-Currency Processing, we will identify at the time of charge (for
        example, through the API)), the conversion rate that will apply to the
        charge. If you refund a charge, the conversion rate that will apply will
        be the rate in effect at the time of the refund, not the charge. By
        submitting a charge or refund for processing you will be deemed to have
        accepted the rate. You may choose not to use the Multi-Currency
        Processing service at any time. You may also change the Payout Account
        information or other settings associated with your use of Multi-Currency
        Processing, but any such changes will only affect subsequent charges.
      </p>
      <p>
        The term “Payout Schedule” refers to the time it takes for us to
        initiate a Transfer Payout Account. Your Payout Schedule is specified in
        the Dashboard. Stripe may require a holding period before making an
        initial Transfer to the Payout Account. After the initial Transfer, we
        will credit funds to the Payout Account according to the Payout
        Schedule; however, please be aware that Financial Services Providers,
        including the institution holding the Payout Account, may delay
        Transfers for any reason. We are not responsible for any action taken by
        the institution holding the Payout Account to not credit the Payout
        Account or to otherwise not make funds available to you as you expected.
      </p>
      <p>
        We reserve the right to change the Payout Schedule or to suspend
        Transfers to you. Examples of situations where we may do so are: (i)
        where there are pending, anticipated, or excessive Disputes, Refunds, or
        Reversals; (ii) in the event that we suspect or become aware of
        suspicious activity; or (iii) where we are required by Law or court
        order. We have the right to withhold Transfers to your Payout Account
        upon termination of this Agreement if we reasonably determine that we
        may incur losses resulting from credit, fraud, or other legal risks
        associated with your Stripe Account. If we exercise our right to
        withhold a Payout for any reason, we will communicate the general reason
        for withholding the Payout and give you a timeline for releasing the
        funds.
      </p>
      <p>
        b. Transfers For Recipients:&nbsp;We may offer Payment Services that
        allow you to send Transfers to others (“Recipient Account”). You agree
        to appoint Stripe (or a Financial Services Provider designated by
        Stripe) as your agent to send Transfers to Recipient Accounts on your
        behalf. You understand and agree that: (i) we will only pay Recipient
        Accounts with funds that are available for Transfer; (ii) we may
        condition any Transfers to Recipient Accounts on verification of the
        owner’s identity, verification that an owner may legally receive a
        Transfer, or in any manner or for any other purpose; (iii) you are
        solely responsible for determining the accuracy and completeness of any
        instructions you provide us for a Transfer to a Recipient Account; and
        (iv) unless otherwise agreed upon between you and us, you are solely
        responsible for any obligations that you contractually or legally owe to
        an owner of a Recipient Account, including providing payment or forms
        related to taxes owed by you or a third party. You affirm that: (i) you
        will not make any Transfers to or on behalf of Restricted Businesses;
        and (ii) any information or authorization you provide to us about each
        Recipient Account is complete and accurate. You agree to not make any
        claims against us, and to fully reimburse us for any losses we incur
        that result from your use, or failure to properly use the Payment
        Services to make Transfers to Recipient Accounts.
      </p>
      <p>
        c. Additional Terms:&nbsp;The information required for a Transfer will
        depend on the Financial Services Provider holding the Payout Account or
        Recipient Account. Please make sure that any information about the
        Payout Accounts or Recipient Accounts that you provide to us is correct
        and accurate. If you provide us with incorrect information (i) you
        understand that Transfers may be sent to the wrong account and we may
        not be able to recover the funds from such incorrect Transfers and (ii)
        you agree that you are solely responsible for any losses you or third
        parties incur, you will not make any claims against us related to such
        erroneous Transfers, and you will fully reimburse us for any losses we
        incur.
      </p>
      <h4>8. Clearing Funds and Reserves</h4>
      <p>
        All funds resulting from Charges are held in pooled clearing accounts
        (the “Clearing Accounts”). We will make Transfers to and from the
        Clearing Accounts in the manner described in this Agreement; however,
        you have no rights to the Clearing Accounts or to any funds held in the
        Clearing Accounts, you are not entitled to draw funds from the Clearing
        Accounts, and you will not receive interest from funds maintained in the
        Clearing Accounts.
      </p>
      <p>
        In certain circumstances, we may require you to place funds in reserve
        or to impose conditions on the release of funds (each a “Reserve”). We
        may impose a Reserve on you for any reason if we determine that the risk
        of loss to Stripe, Customers, or others associated with your Stripe
        Account is higher than normal. For example, we may hold a Reserve if:
        (i) your or your Customers’ activities increase the risk of loss to us
        or to your Customers, (ii) you have violated or are likely to violate
        this Agreement, or (iii) your Stripe Account has an elevated or
        abnormally high number of Disputes. If we impose a Reserve, we will
        establish the terms of the Reserve and provide you Notice of the amount,
        timing, and conditions upon which the funds in the Reserve will be
        released to you. In many cases, the Reserve amount will be the entire
        amount of Charges processed using the Payment Services. We may change or
        condition the terms of the Reserve based on our continuous assessment
        and understanding of the risks associated with your Stripe Account, if
        required to do so by Financial Services Providers, or for any other
        reason. We may fund the Reserve with funds processed through your use of
        Payment Services, by debiting the Payout Account or another bank account
        associated with your Stripe Account, or by requesting funds directly
        from you.
      </p>
      <p>
        To the extent possible, we prefer to identify the necessity for a
        Reserve in advance of establishing one. If you are concerned that we
        will impose a Reserve on you due to the nature of your business
        activities, please&nbsp;
        <a href="https://stripe.com/en-ca/contact">contact us</a>&nbsp;before
        using the Services.
      </p>
      <h4>9. Security Interests, Collection, and Set-Off Rights</h4>
      <p>
        a. Security Interests:&nbsp;You grant us a lien and security interest in
        (and in Quebec, a hypothec on) all funds processed and deposited into
        all Payout Accounts or any other bank account associated with your
        Stripe Account, and in any funds processed using the Payment Services.
        This means that if you have not paid funds that you owe to us, your
        Customers, or to any of our affiliates, we have a right superior to the
        rights of any of your other creditors to seize or withhold funds owed to
        you for Transactions that we process through the Services, and to debit
        or withdraw funds from any bank account associated with your Stripe
        Account. The hypothec created pursuant to this Section is granted for
        the sum of fifty million dollars with interest at the rate of twenty
        five percent per annum. This amount and this rate are a formal amount
        and formal rate to satisfy requirements of law in Quebec and do not
        affect the amount of the actual obligations you owe to us. Upon our
        request, you will execute and deliver any documents and pay any
        associated fees we consider necessary to create, perfect, and maintain a
        security interest in (and a hypothec on) such funds.
      </p>
      <p>
        b. Collection and Set-Off Rights:&nbsp;You agree to pay all amounts owed
        to us and to our affiliates on demand. Where possible, we will first
        attempt to collect or set-off balances in your Stripe Accounts from your
        use of the Payment Services or from funds that we hold in Reserve.
        However, we may collect any obligations you owe us under this Agreement
        from any Payout Account associated with your Stripe Account or any
        commonly-owned Stripe Account by deducting or setting-off the
        corresponding amounts from the funds owed to you through your use of the
        Payment Services, or through a direct debit from any Payout Account
        identified in your Stripe Account or a commonly-owned Stripe Account.
        Your failure to pay amounts owed to us or to our affiliates under this
        Agreement is a breach and you will be liable for any costs we incur
        during collection in addition to the amount you owe. Collection costs
        may include, legals fees and expenses, costs of any arbitration or court
        proceeding, collection agency fees, any applicable interest, and any
        other related cost.
      </p>
      <p>
        In certain circumstances, we may require a personal, parent or other
        guarantee (a “Guarantee”) from a user’s principal, owner, or other
        guarantor. A Guarantee consists of a legally binding promise by an
        individual or an entity to pay any amounts the user owes in the event
        that the user is unable to pay. If we require you to provide us with a
        Guarantee, we will specifically inform you of the amount of, and the
        reasons for the Guarantee. If you are unable to provide such a Guarantee
        when required, you will not be permitted to use the Services.
      </p>
      <p>
        c. Pre-Authorized Debits (“PADs”):&nbsp;You authorize us and our
        respective vendors and agents to initiate debit and credit entries to
        the Payout Account, or any other account maintained by you at any
        institution that is a member of the Canadian Payments Association, doing
        business as Payments Canada, all in accordance with this Agreement. You
        agree that any withdrawal by us and our vendors and agents in accordance
        with this Agreement are PADs for business purposes, as defined under
        Rule H1 of Payments Canada.&nbsp;You hereby waive the right to receive
        advance notice from us and our vendors and agents of any and all such
        debits.&nbsp;This authorization will remain in effect after termination
        of this Agreement and until all of your obligations to us have been paid
        in full. If you change the Payout Account, this PAD authorization will
        apply to the new account and you shall provide us in writing such
        information regarding the new account as we deem necessary. Such new
        account shall thereafter be and become the “Payout Account” for the
        purpose of this Agreement. It may take us up to ten (10) business days
        after receipt of a written notice from you to reflect in our system any
        change to the Payout Account. If you change the Payout Account, you
        agree that you are responsible for all costs incurred by us in
        connection with your decision to change the Payout Account. You may
        revoke this PAD authorization upon thirty (30) days’ prior written
        notice to us, but any such revocation shall constitute a material breach
        of this Agreement. You may obtain a sample cancellation form, as well as
        further information on your right to cancel a PAD authorization by
        contacting your financial institution or by visiting the&nbsp;
        <a href="http://www.payments.ca/">Payments Canada website</a>. You have
        certain recourse rights if any debit does not comply with this PAD
        Agreement. For example, you have the right to receive reimbursement for
        any debit that is not authorized or is not consistent with this PAD
        Agreement. To obtain more information on your recourse rights, you may
        contact your financial institution or&nbsp;
        <a href="https://www.payments.ca/">Payments Canada</a>.
      </p>
      <h4>10. Reconciliation and Error Notification</h4>
      <p>
        The Dashboard contains details of Charges, Charge history, and other
        activity on your Stripe Account. Except as required by Law, you are
        solely responsible for reconciling the information in the Dashboard
        generated by your use of Payment Services with your records of Customer
        Transactions, and for identifying any Transaction errors. You agree to
        review your Stripe Account and immediately notify us of any errors. We
        will investigate any reported errors, including any errors made by
        Stripe or our Financial Services Providers, and attempt to rectify them
        by crediting or debiting the Payout Account identified in the Dashboard.
        Your chance of recovering funds you have lost due to a Transaction error
        will be very limited or even impossible if we did not cause the error,
        or if funds are no longer available in any Payout Account or Recipient
        Account. We will work with you and our Financial Services Providers to
        correct a Transaction error in accordance with Network Rules; however,
        if you fail to communicate a Transaction error to us for our review
        within 60 days after you discovered it and flagged it in the Dashboard,
        you waive your right to make any claim against us or our Financial
        Services Providers for any amounts associated with the Transaction
        error.
      </p>
      <h4>11. Dormant Accounts</h4>
      <p>
        If you leave any funds dormant in a Stripe Account and you do not give
        us instructions where to send them, we may be required by Law to deem
        the funds to be abandoned by you, and to deliver them to various
        government agencies. To the extent required by Law, we will attempt to
        provide you Notice if we hold funds payable to you in an account beyond
        the applicable dormancy period for abandoned property. If we are unable
        to contact you, we will treat the funds in your Stripe Account to be
        abandoned, and will deliver them to the appropriate government
        authority.
      </p>
      <br />
      <br />
      <h3>Section D: Data Usage, Privacy, and Security</h3>
      <h4>1. Data Usage Overview</h4>
      <p>
        Protecting, securing, and maintaining the information processed and
        handled through the Services is one of our top priorities, and it should
        be yours too. This section describes our respective obligations when
        handling and storing information connected with the Services. The
        following terms used in this section relate to data provided to Stripe
        by you or your Customers, or received or accessed by you through your
        use of the Services:
      </p>
      <p>
        “Personal Data” means information that identifies a specific living
        person (not a company, legal entity, or machine) and is transmitted to
        or accessible through the Services.
      </p>
      <p>
        “User Data” means information that describes your business and its
        operations, your products or services, and orders placed by Customers.
      </p>
      <p>
        “Payment Data” means payment account details, information communicated
        to or by Financial Services Providers, financial information
        specifically regulated by Laws and Network Rules, and any other
        information used with the Payment Services to complete a Transaction.
      </p>
      <p>
        “Stripe Data” means details of the API transactions over Stripe
        infrastructure, information used in fraud detection and analysis,
        aggregated or anonymized information generated from Data, and any other
        information created by or originating from Stripe or the Services.
      </p>
      <p>
        The term “Data” used without a modifier means all Personal Data, User
        Data, Payment Data, and Stripe Data.
      </p>
      <p>
        Stripe processes, analyses, and manages Data to: (a) provide Services to
        you, other Stripe users, and Customers; (b) mitigate fraud, financial
        loss, or other harm to users, Customers and Stripe; and (c) analyze,
        develop and improve our products, systems, and tools. Stripe provides
        Data to third-party service providers, including Financial Services
        Providers and their affiliates, as well as Stripe’s global affiliates,
        to allow us to provide Services to you and other users. We do not
        provide Personal Data to unaffiliated parties for marketing their
        products to you. You understand and consent to Stripe’s use of Data for
        the purposes and in a manner consistent with this&nbsp;Section D.
      </p>
      <h4>2. Data Protection and Privacy</h4>
      <p>
        a. Confidentiality:&nbsp;Stripe will only use User Data as permitted by
        this Agreement, by other agreements between you and us, or as otherwise
        directed by you. You will protect all Data you receive through the
        Services, and you may not disclose or distribute any such Data, and you
        will only use such Data in conjunction with the Services and as
        permitted by this Agreement or by other agreements between you and us.
        Neither party may use any Personal Data to market to Customers unless it
        has received the express consent from a specific Customer to do so. You
        may not disclose Payment Data to others except in connection with
        processing Transactions requested by Customers and consistent with
        applicable Laws and Network Rules.
      </p>
      <p>
        b. Privacy:&nbsp;Protection of Personal Data is very important to us.
        Our&nbsp;
        <a href="https://stripe.com/en-ca/ca/privacy">Privacy Policy</a>
        &nbsp;explains how and for what purposes we collect, use, retain,
        disclose, and safeguard the Personal Data you provide to us. You agree
        to review the terms of our Privacy Policy, which we update from time to
        time.
      </p>
      <p>
        You affirm that you are now and will continue to be compliant with all
        applicable Laws governing privacy and your use of Data that you provide
        to us or access through your use of the Services. You also affirm that
        you have obtained all necessary rights and consents under applicable
        Laws to disclose to Stripe — or allow Stripe to collect, use, retain,
        and disclose — any Personal Data that you provide to us or authorize us
        to collect, including Data that we may collect directly from Customers
        using cookies or other similar means. As may be required by Law and in
        connection with this Agreement, you are solely responsible for
        disclosing to Customers that Stripe processes Transactions (including
        payment Transactions) for you and may receive Personal Data from you.
        Additionally, where required by Law or Network Rules, we may delete or
        disconnect a Customer’s Personal Data from your Stripe Account when
        requested to do so by the Customer.
      </p>
      <p>
        Stripe will provide some or all of the Service from systems located
        within the United States or other countries outside of Canada. As such,
        it is your obligation to disclose to your customers that Payment Data
        may be transferred, processed and stored outside of Canada and, as set
        forth in our Privacy Policy, may be subject to disclosure as required by
        applicable Laws.
      </p>
      <p>
        If we become aware of an unauthorized acquisition, disclosure or loss of
        Customer Personal Data on our systems, we will notify you consistent
        with our obligations under applicable Law. We will also notify you and
        provide you sufficient information regarding the unauthorized
        acquisition, disclosure or loss to help you mitigate any negative impact
        on the Customer.
      </p>
      <p>
        c. PCI Compliance:&nbsp;If you use Payment Services to accept payment
        card Transactions, you must comply with the Payment Card Industry Data
        Security Standards (“PCI-DSS”) and, if applicable to your business, the
        Payment Application Data Security Standards (PA-DSS) (collectively, the
        “PCI Standards”). Stripe provides tools to simplify your compliance with
        the PCI Standards, but you must ensure that your business is compliant.
        The specific steps you will need to take to comply with the PCI
        Standards will depend on your implementation of the Payment Services.
        You can find more information about implementing Stripe in a manner
        compliant with the PCI Standards in our&nbsp;
        <a href="https://support.stripe.com/questions/do-i-need-to-be-pci-compliant-what-do-i-have-to-do">
          Documentation
        </a>
        . You will promptly provide us with documentation demonstrating your
        compliance with the PCI Standards upon our request. If you elect to
        store, hold and maintain “Account Data”, as defined by the PCI Standards
        (including Customer card account number or expiration date), you further
        agree that you will either maintain a PCI-compliant system or use a
        compliant service provider to store or transmit such Account Data;
        further, you agree to never store any “Sensitive Authentication Data”,
        as defined by the PCI Standards (including CVC or CVV2), data at any
        time. You can find information about the PCI Standards on the&nbsp;
        <a href="https://www.pcisecuritystandards.org/">
          PCI Council’s website
        </a>
        .
      </p>
      <h4>3. Security and Fraud Controls</h4>
      <p>
        a. Stripe’s Security:&nbsp;Stripe is responsible for protecting the
        security of Data in our possession. We will maintain commercially
        reasonable administrative, technical, and physical procedures to protect
        User Data and Personal Data stored in our servers from unauthorized
        access, accidental loss, modification, or breach, and we will comply
        with applicable Laws and Network Rules when we handle User and Personal
        Data. However, no security system is impenetrable and we cannot
        guarantee that unauthorized parties will never be able to defeat our
        security measures or misuse any Data in our possession. You provide User
        Data and Personal Data to Stripe with the understanding that any
        security measures we provide may not be appropriate or adequate for your
        business, and you agree to implement the Security Controls and any
        additional controls that meet your specific requirements. In our sole
        discretion, we may take any action, including suspension of your Stripe
        Account, to maintain the integrity and security of the Services or Data,
        or to prevent harm to you, us, Customers, or others. You waive any right
        to make a claim against us for losses you incur that may result from our
        actions.
      </p>
      <p>
        b. Your Security:&nbsp;You are solely responsible for the security of
        any Data on your website, your servers, in your possession, or that you
        are otherwise authorized to access or handle. You will comply with
        applicable Laws and Network Rules when handling or maintaining User Data
        and Personal Data, and will provide evidence of your compliance to us
        upon our request. If you do not provide evidence of such compliance to
        our satisfaction, we may suspend transactions on your account or
        terminate this Agreement.
      </p>
      <p>
        c. Security and Fraud Controls:&nbsp;We may provide or suggest Security
        Controls to you, but we cannot guarantee that you or Customers will
        never become victims of fraud. Any Security Controls we provide or
        suggest may include processes or applications developed by Stripe, its
        affiliates, or other companies. You agree to review all the Security
        Controls we suggest and choose those that are appropriate for your
        business to protect against unauthorized Transactions and, if
        appropriate for your business, independently implement other security
        procedures and controls not provided by us. If you disable or fail to
        properly use Security Controls, you will increase the likelihood of
        unauthorized Transactions, Disputes, fraud, losses, and other similar
        occurrences. Keep in mind that you are solely responsible for losses you
        incur from the use of lost or stolen payment credentials or accounts by
        fraudsters who engage in fraudulent Transactions with you, and your
        failure to implement Security Controls will only increase the risk of
        fraud. We may assist you with recovering lost funds, but you are solely
        responsible for losses due to lost or stolen credentials or accounts,
        compromise of your username or password, changes to your Payout Account,
        and any other unauthorized use or modification of your Stripe Account.
        Stripe is not liable or responsible to you and you waive any right to
        bring a claim against us for any losses that result from the use of lost
        or stolen credentials or accounts to engage in fraudulent Transactions,
        unless such losses result from Stripe’s willful or intentional actions.
        Further, you will fully reimburse us for any losses we incur that result
        from the use of lost or stolen credentials or accounts.
      </p>
      <p>
        We may also provide you with subjective Data regarding the possibility
        or likelihood that a Transaction may be fraudulent that require action
        or review by you. We may incorporate action or inaction by you into any
        such subjective scoring when identifying future potential fraud. You
        understand that we provide this Data to you for your consideration, but
        that you are ultimately responsible for any actions you choose to take
        or not take in relation to such Data, and for providing inaccurate or
        incorrect information to us. You are solely responsible for any action
        or inaction you take based on such Data.
      </p>
      <h4>4. Your Use of Data with Stripe Connect and Stripe Relay</h4>
      <p>
        When using Stripe Connect or Stripe Relay, you will have the ability to
        connect your Stripe Account with a Platform or App. Connected Platforms
        and Apps may take certain actions on your behalf and access Data
        available through your Stripe Account, including some User Data. By
        using Stripe Connect or Stripe Relay, you authorize Stripe to share Data
        with any Platform or App that you connect with your Stripe Account
        through the Dashboard or the API. You also understand that at any point
        you may disallow any such sharing by&nbsp;
        <a href="https://dashboard.stripe.com/account/applications">
          removing the Platform or App
        </a>
        &nbsp;from your Stripe Account. You waive your right to bring any claims
        against Stripe for losses you incur that arise from any actions or use
        of Data by any Platform or App connected to your Stripe Account, and you
        will fully reimburse us for any losses we incur that result from your
        actions or use of such Data by any Platform or App.
      </p>
      <h4>5. Transfer of Payment Data upon Termination</h4>
      <p>
        For 30 days after termination of your Stripe Account, you may request in
        writing that we transfer Payment Data regarding transactions between you
        and Customers that you are entitled to receive (“Exportable Data”) to an
        alternative payment services provider consistent with applicable Laws.
        For payment card transactions, Stripe will only transfer Exportable Data
        to another PCI-DSS Level 1-certified payment services provider. For
        other payment methods, Stripe may require you to provide us with
        evidence that the alternative payment services provider you select has
        appropriate systems and security controls before we migrate any
        Exportable Data. We will use commercially reasonable efforts to transfer
        Exportable Data within 10 business days after we receive your written
        request. We may delay or refuse any transfer request if we believe the
        payment services provider you have identified does not have systems or
        security controls in place that are sufficient to protect Exportable
        Data, that the integrity of Exportable Data may be compromised, or if
        Laws or Network Rules prohibit us from transferring it.
      </p>
      <br />
      <br />
      <h3>Section E: Additional Legal Terms</h3>
      <h4>1. Right to Amend</h4>
      <p>
        We have the right to change or add to the terms of this Agreement at any
        time, and to change, delete, discontinue, or impose conditions on use of
        the Services by posting such changes on our&nbsp;
        <a href="https://stripe.com/en-ca/ca">website</a>&nbsp;or any other
        website we maintain or own. We may provide you with Notice of any
        changes through the Dashboard, via email, or through other means. Your
        use of the Services, APIs, or Data after we publish any such changes on
        our website, constitutes your acceptance of the terms of the modified
        Agreement.
      </p>
      <h4>2. Assignment</h4>
      <p>
        You may not assign this Agreement, any rights or licences granted in
        this Agreement, or operation of your Stripe Account to others without
        our prior written consent. If you wish to make such an assignment,
        please&nbsp;<a href="https://stripe.com/en-ca/contact">contact us</a>.
        If we consent to the assignment, the assignee agrees to assume all of
        your rights and obligations owed by you related to the assignment, and
        must agree to comply with the terms of this Agreement. Stripe may assign
        this Agreement without your consent or any other restriction. If we make
        an assignment, we will provide reasonable Notice to you.
      </p>
      <h4>3. Right to Audit</h4>
      <p>
        If we believe that a security breach, leak, loss, or compromise of Data
        has occurred on your systems, website, or app affecting your compliance
        with this Agreement, we may require you to permit a third-party auditor
        approved by us to conduct a security audit of your systems and
        facilities. The auditor will issue a report to us and our Financial
        Services Providers.
      </p>
      <h4>4. No Agency; Third-Party Services</h4>
      <p>
        Nothing in this Agreement serves to establish a partnership, joint
        venture, or other agency relationship between you and us, or with any
        Financial Services Provider. Each party to this Agreement, and each
        Financial Services Provider, is an independent contractor. Unless a
        Financial Services Provider expressly agrees, neither you nor we have
        the ability to bind a Financial Services Provider to any contract or
        obligation, and neither party will represent that you or we have such an
        ability.
      </p>
      <p>
        We may reference or provide access to third-party services, products,
        and promotions that utilize, integrate, or provide ancillary services to
        the Services (“Third-Party Services”). These Third-Party Services are
        provided for your convenience only and do not constitute our approval,
        endorsement, or recommendation of any such Third-Party Services for you.
        You access and use any Third-Party Service based on your own evaluation
        and at your own risk. You understand that your use of any Third-Party
        Service is not governed by this Agreement. If you decide to use a
        Third-Party Service, you will be responsible for reviewing,
        understanding and accepting the terms and conditions associated with its
        use. We expressly disclaim all responsibility and liability for your use
        of any Third-Party Service. Please also remember that when you use a
        Third-Party Service, our Privacy Policy is no longer in effect. Your use
        of a Third-Party Service, including those that have a link on our
        website, is subject to that Third-Party Service’s own terms of use and
        privacy policies.
      </p>
      <h4>5. Force Majeure</h4>
      <p>
        Neither party will be liable for any delays in processing or other
        nonperformance caused by telecommunications, utility, failures, or
        equipment failures; labor strife, riots, war, or terrorist attacks;
        nonperformance of our vendors or suppliers, fires or acts of nature; or
        any other event over which the respective party has no reasonable
        control. However, nothing in this section will affect or excuse your
        liabilities or your obligation to pay Fees, Fines, Disputes, Reversals,
        or Returns under this Agreement.
      </p>
      <h4>6. Your Liability For Third-Party Claims Against Us</h4>
      <p>
        You agree to defend Stripe, our affiliates, and their respective
        employees, agents, and service providers (each a “Stripe Entity”)
        against any claim, suit, demand, loss, liability, damage, action, or
        proceeding (each, a “Claim”) brought by a third party against a Stripe
        Entity, and you agree to fully reimburse the Stripe Entities for any
        Claims that result from: (i) your breach of any provision of this
        Agreement; (ii) any Fees, Fines, Disputes, Reversals, Returns, or any
        other liability we incur that results from your use of Payment Services;
        (iii) negligent or willful misconduct of your employees, contractors, or
        agents; or (iv) contractual or other relationships between you and
        Customers.
      </p>
      <p>
        Important Note for Individual Traders:&nbsp;If you are using Services as
        a sole proprietor, please keep in mind that the Law and the terms of
        this Agreement consider you and your business to be legally one and the
        same. You are personally responsible and liable for your use of the
        Services, payment of Fees, Reversals, Fines, losses based on Disputes or
        fraud, or for any other amounts you owe under this Agreement for your
        failure to use Security Controls, and for all other obligations to us
        and to your Customers. You risk personal financial loss if you fail to
        pay any amounts owed. Please take the time to read our Documentation and
        take any measures appropriate to protect against such losses.
      </p>
      <h4>7. Representations and Warranties</h4>
      <p>
        By accepting the terms of this Agreement, you represent and warrant
        that: (a) you are eligible to register and use the Services and have the
        authority to execute and perform the obligations required by this
        Agreement; (b) any information you provide us about your business,
        products, or services is accurate and complete; (c) any Charges
        represent a Transaction for permitted products, services, or donations,
        and any related information accurately describes the Transaction; (d)
        you will fulfil all of your obligations to Customers and will resolve
        all Disputes with them; (e) you will comply with all Laws applicable to
        your business and use of the Services; (f) you will not use Payment
        Services for household purposes or peer-to-peer money transmission, or
        (except in the normal course of business) intercompany Transactions; and
        (g) you will not use the Service, directly or indirectly, for any
        fraudulent or illegal undertaking, or to interfere in any manner with
        the normal operation of the Service.
      </p>
      <h4>8. No Warranties</h4>
      <p>
        WE PROVIDE THE SERVICES AND STRIPE IP “AS IS” AND “AS AVAILABLE”,
        WITHOUT ANY EXPRESS, IMPLIED, OR STATUTORY WARRANTIES OF TITLE,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT, OR
        ANY OTHER TYPE OF WARRANTY OR GUARANTEE. NO DATA, DOCUMENTATION OR ANY
        OTHER INFORMATION PROVIDED BY STRIPE OR OBTAINED BY YOU FROM OR THROUGH
        THE SERVICES — WHETHER FROM STRIPE OR ANOTHER STRIPE ENTITY, AND WHETHER
        OR ORAL OR WRITTEN — CREATES OR IMPLIES ANY WARRANTY FROM A STRIPE
        ENTITY TO YOU.
      </p>
      <p>
        YOU AFFIRM THAT NO STRIPE ENTITY CONTROLS THE PRODUCTS OR SERVICES THAT
        YOU OFFER OR SELL OR THAT YOUR CUSTOMERS PURCHASE USING THE PAYMENT
        SERVICES. YOU UNDERSTAND THAT WE CANNOT GUARANTEE AND WE DISCLAIM ANY
        KNOWLEDGE THAT YOUR CUSTOMERS POSSESS THE AUTHORITY TO, OR WILL COMPLETE
        ANY TRANSACTION.
      </p>
      <p>
        THE STRIPE ENTITIES DISCLAIM ANY KNOWLEDGE OF, AND DO NOT GUARANTEE: (a)
        THE ACCURACY, RELIABILITY, OR CORRECTNESS OF ANY DATA PROVIDED THROUGH
        THE SERVICES; (b) THAT THE SERVICES WILL MEET YOUR SPECIFIC BUSINESS
        NEEDS OR REQUIREMENTS; (c) THAT THE SERVICES WILL BE AVAILABLE AT ANY
        PARTICULAR TIME OR LOCATION, OR WILL FUNCTION IN AN UNINTERRUPTED MANNER
        OR BE SECURE; (d) THAT STRIPE WILL CORRECT ANY DEFECTS OR ERRORS IN THE
        SERVICE, APIS, DOCUMENTATION, OR DATA; OR (e) THAT THE SERVICES ARE FREE
        OF VIRUSES OR OTHER HARMFUL CODE. USE OF DATA YOU ACCESS OR DOWNLOAD
        THROUGH THE SERVICES IS DONE AT YOUR OWN RISK — YOU ARE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY, LOSS OF DATA, OR ANY OTHER
        LOSS THAT RESULTS FROM SUCH ACCESS OR DOWNLOAD. YOU UNDERSTAND THAT THE
        STRIPE ENTITIES MAKE NO GUARANTEES TO YOU REGARDING TRANSACTION
        PROCESSING TIMES OR PAYOUT SCHEDULES.
      </p>
      <p>
        NOTHING IN THIS AGREEMENT OPERATES TO EXCLUDE, RESTRICT OR MODIFY THE
        APPLICATION OF ANY IMPLIED CONDITION, WARRANTY OR GUARANTEE, OR THE
        EXERCISE OF ANY RIGHT OR REMEDY, OR THE IMPOSITION OF ANY LIABILITY
        UNDER LAW WHERE TO DO SO WOULD: (A) CONTRAVENE THAT LAW; OR (B) CAUSE
        ANY TERM OF THIS AGREEMENT TO BE VOID.
      </p>
      <h4>9. Limitation of Liability</h4>
      <p>
        Under no circumstances will any Stripe Entity be responsible or liable
        to you for any indirect, punitive, incidental, special, consequential,
        or exemplary damages resulting from your use or inability to use the
        Services or for the unavailability of the Services, for lost profits,
        personal injury, or property damage, or for any other damages arising
        out of, in connection with, or relating to this Agreement or your use of
        the Services, even if such damages are foreseeable, and whether or not
        you or the Stripe Entities have been advised of the possibility of such
        damages. The Stripe Entities are not liable, and deny responsibility for
        any damages, harm, or losses to you arising from or relating to hacking,
        tampering, or other unauthorized access or use of the Services, your
        Stripe Account, or Data, or your failure to use or implement anti-fraud
        measures, the Security Controls, or any other data security measure. The
        Stripe Entities further deny responsibility for all liability and
        damages to you or others caused by (a) your access or use of the
        Services inconsistent with the Documentation; (b) any unauthorized
        access of servers, infrastructure, or Data used in connection with the
        Services; (c) interruptions or cessation of the Services; (d) any bugs,
        viruses, or other harmful code that may be transmitted to or through the
        Services; (e) any errors, inaccuracies, omissions, or losses in or to
        any Data provided to us; (f) third-party content provided by you; or (g)
        the defamatory, offensive, or illegal conduct of others.
      </p>
      <p>
        You agree to limit any additional liability not disclaimed or denied by
        the Stripe Entities under this Agreement to your direct and documented
        damages; and you further agree that under no circumstances will any such
        liability exceed in the aggregate the amount of Fees paid by you to
        Stripe during the three-month period immediately preceding the event
        that gave rise to your claim for damages.
      </p>
      <p>
        These limitations on our liability to you will apply regardless of the
        legal theory on which your claim is based, including contract, tort
        (including negligence), strict liability, or any other theory or basis.
      </p>
      <h4>10. Responding to Legal Process</h4>
      <p>
        Stripe may respond to and comply with any writ of attachment, lien,
        levy, subpoena, warrant, or other legal order (“Legal Process”) that we
        believe to be valid. We or any Financial Services Provider may deliver
        or hold any funds or, subject to the terms of our Privacy Policy, any
        Data as required under such Legal Process, even if you are receiving
        funds or Data on behalf of other parties. Where permitted by Law, we
        will make reasonable efforts to provide you Notice of such Legal Process
        by sending a copy to the email address we have on file for you. Stripe
        is not responsible for any losses, whether direct or indirect, that you
        may incur as a result of our response or compliance with a Legal
        Process.
      </p>
      <h4>11. Dispute Resolution; Agreement to Arbitrate</h4>
      <p>
        a. Binding Arbitration:&nbsp;Any dispute, controversy or claim arising
        out of or relating to this Agreement, or in respect of any legal
        relationship associated with or derived from this Agreement, including
        without limitation the formation, interpretation, breach or termination
        thereof, including whether the claims asserted are arbitrable, will be
        referred to and finally determined by arbitration administered by ICDR
        Canada in accordance with its Canadian Arbitration Rules. The number of
        arbitrators shall be one. The place of the arbitration shall be
        Vancouver, British Columbia, Canada. The language of the arbitration
        shall be English. Judgment upon the award rendered by the arbitrator may
        be entered by any court having jurisdiction thereof.
      </p>
      <p>
        This Agreement shall be governed by the laws of the Province Of British
        Columbia and the federal laws of Canada applicable in that Province.
        Nothing in this paragraph will preclude the parties from seeking
        provisional remedies in aid of arbitration from a court of appropriate
        jurisdiction.
      </p>
      <p>
        Either party may commence arbitration by providing to ICDR Canada and
        the other party to the dispute a written demand for arbitration, setting
        forth the subject of the dispute and the relief requested (“Arbitration
        Demand”).
      </p>
      <p>
        b. Service of Process:&nbsp;Each party hereby irrevocably and
        unconditionally consents to service of process through personal service
        at their corporate headquarters, registered address, or primary address
        (for individuals or sole proprietors). Nothing in this Agreement will
        affect the right of any party to serve process in any other manner
        permitted by Law.
      </p>
      <p>
        c. Class Waiver:&nbsp;To the fullest extent permitted by Law, each of
        the parties agrees that any dispute arising out of or in connection with
        this Agreement will be conducted only on an individual basis and not in
        a class, consolidated or representative action. If for any reason a
        claim or dispute proceeds in court rather than through arbitration, each
        party knowingly and irrevocably waives any right to trial by jury in any
        action, proceeding or counterclaim arising out of or relating to this
        Agreement or any of the transactions contemplated between the parties.
      </p>
      <p>
        d. Provision of an Award:&nbsp;Subject to the limitations of liability
        identified in this Agreement, the appointed arbitrator may award
        monetary damages and any other remedies allowed by Law. In making a
        determination, the arbitrator will not have the authority to modify any
        term or provision of this Agreement. The arbitrator will deliver a
        reasoned written decision with respect to the dispute (the “Award”) to
        each party, who will promptly act in accordance with the Award. Any
        Award (including interim or final remedies) may be confirmed in or
        enforced by any court having jurisdiction over either party or its
        assets. The decision of the arbitrator will be final and binding on the
        parties, and will not be subject to appeal or review.
      </p>
      <p>
        e. Fees:&nbsp;Each party will advance one-half of the fees and expenses
        of the arbitrator, the costs of the attendance of the arbitration
        reporter at the arbitration hearing, and the costs of the arbitration
        facility. In any arbitration arising out of or related to this
        Agreement, the arbitrator will award to the prevailing party, if any,
        the costs and legal fees reasonably incurred by the prevailing party in
        connection with those aspects of its claims or defenses on which it
        prevails, and any opposing awards of costs and legal fees awards will be
        offset.
      </p>
      <p>
        f. Confidentiality:&nbsp;The parties will maintain the confidential
        nature of the arbitration proceeding, the hearing and the Award, except
        as may be necessary to prepare for or conduct the arbitration hearing on
        the merits, or except as may be necessary in connection with a court
        application as contemplated above for a preliminary remedy, or
        confirmation of an Award or its enforcement, or unless otherwise
        required by applicable Laws. The parties, witnesses, and arbitrator will
        treat as confidential and will not disclose to any third person (other
        than witnesses or experts) any documentary or other evidence produced in
        any arbitration hereunder, except as required by Law or except if such
        evidence was obtained from the public domain or was otherwise obtained
        independently from the arbitration.
      </p>
      <p>
        g. Conflict of Rules:&nbsp;In the case of a conflict between the
        provisions of this Section E.11.g and the rules governing arbitration
        identified in Section E.11.a, the provisions of this Section E.11.g will
        prevail. If any provision of this Agreement to arbitrate is held invalid
        or unenforceable, it will be so held to the minimum extent required by
        Law and all the other provisions will remain valid and enforceable.
      </p>
      <h4>12. Entire Agreement</h4>
      <p>
        This Agreement and all policies and procedures that are incorporated by
        reference constitute the entire agreement between you and Stripe for
        provision and use of the Services. Except where expressly stated
        otherwise in a writing executed between you and Stripe, this Agreement
        will prevail over any conflicting policy or agreement for the provision
        or use of the Services. This Agreement sets forth your exclusive
        remedies with respect to the Services. If any provision or portion of
        this Agreement is held to be invalid or unenforceable under Law, then it
        will be reformed and interpreted to accomplish the objectives of such
        provision to the greatest extent possible, and all remaining provisions
        will continue in full force and effect.
      </p>
      <h4>13. Survival</h4>
      <p>
        All provisions of this Agreement that give rise to a party’s ongoing
        obligation will survive termination of this Agreement, including but not
        limited to Sections A.3 (“Your Relationship with Your Customers”), A.6
        (“Taxes and Other Expenses”), A.7 (“Service Limitations, Prohibited
        Activities, and Security Controls”), A.8 (“Suspicion of Unauthorised or
        Illegal Use”), A.9 (“Disclosures and Notices; Electronic Signature
        Consent”), A.10.b (“Effects of Termination”), B.2 (“Ownership of Stripe
        IP”), C.6 (“Specific Payment Methods”), C.7 (“Transfers and Payout
        Schedule”), C.8 (“Clearing Funds and Reserves”), C.9 (“Security
        Interests, Collection, and Set-Off Rights”), C.10 (“Reconciliation and
        Error Notification”), C.11 (“Dormant Accounts”), D.3 (“Security and
        Fraud Controls”), D.4 (“Your Use of Data with Stripe Connect and Stripe
        Relay”), D.5 (“Transfer of Payment Data upon Termination”), E.4 (“No
        Agency; Third-Party Services”), E.5 (“Force Majeure”), E.6 (“Your
        Liability for Third-Party Claims Against Us”), E.7 (“Representations and
        Warranties”), E.8 (“No Warranties”), E.9 (“Limitation of Liability”),
        E.10 (“Responding to Legal Process”), E.11 (“Dispute Resolution;
        Agreement to Arbitrate”), E.12 (“Entire Agreement”), and E.13
        (“Survival”); and any related terms in the Agreement.
      </p>
      <h4>14. Language</h4>
      <p>
        The parties hereby acknowledge that they have required this Agreement
        and all related documents to be drawn up in the English language. Les
        parties reconnaissent avoir demandé que le présent contrat ainsi que
        tous les documents qui s’y rattachent soient rédigés en langue anglaise.
      </p>
      <h4>15. Code of Conduct, Element 13 Disclosure</h4>
      <p>
        The Financial Consumer Agency of Canada (the “FCAC”) has established a
        Code of Conduct for the Credit and Debit Card Industry (the “Code”), a
        copy of which is available at the&nbsp;
        <a href="http://www.fcac-acfc.gc.ca/Eng/forIndustry/publications/lawsReg/Pages/CodeofCo-Codedeco.aspx">
          FCAC’s website
        </a>
        .
      </p>
      <p>
        Stripe has established a process for managing potential Code compliance
        violations. If you have a complaint about a particular element of the
        Code, please submit your complaint to&nbsp;
        <a href="https://support.stripe.com/email/login">Stripe Support</a>
        &nbsp;and include all of the following information:
      </p>
      <ul>
        <li>
          <p>Your name</p>
        </li>
        <li>
          <p>Business name on your Stripe account</p>
        </li>
        <li>
          <p>Business address with territory specified</p>
        </li>
        <li>
          <p>Name and email address of administrator on your Stripe account</p>
        </li>
        <li>
          <p>
            General summary of your complaint and, if you have any supporting
            documents, please provide them upon response from our Support team.
          </p>
        </li>
        <li>
          <p>
            Identify one or more of the following elements of Code that the
            complaint pertains to:
          </p>
        </li>
      </ul>
      <p>
        Element 1: Transparency and Disclosure Element; Element 2: Notice of fee
        increase or new fee Element; Element 3: Contract Cancellation; Element
        4: No Obligation Acceptance; Element 5: Payment Method Discount Element;
        10: Negative Option Acceptance; Element 11: Contactless Payments; 12:
        Renewal and Cancellation Disclosure; Element 13: Complaint Handling
        Process
      </p>
      <p>
        Please ensure that all information requested is provided so that we may
        appropriately investigate the matter. Per the Code, we will acknowledge
        receipt of your email within 5 days of receipt. We will either resolve
        the complaint within 90 days or provide you information as to why it has
        not been resolved within such period.
      </p>
      <p>
        If your complaint has not been resolved after following the above
        process, you may contact the Financial Consumer Agency of Canada by
        calling <a href="tel:1-866-461-3222">1-866-461-3222</a> or emailing
        <a href="mailto:info@fcac-acfc.gc.ca">info@fcac-acfc.gc.ca</a>.
      </p>
      <p>
        Except where otherwise agreed upon in writing between you and Stripe,
        you are bound by this Agreement on the date your account was opened with
        Stripe.You may close your account and terminate this Agreement at
        anytime, and there is no fee to do so. Pricing can be found on our&nbsp;
        <a href="https://stripe.com/en-ca/ca/pricing">pricing webpage</a>.
      </p>
    </div>
  );
};
export default StripeInformation;

export const blankStarArr = [
  { id: 1, type: "blank" },
  { id: 2, type: "blank" },
  { id: 3, type: "blank" },
  { id: 4, type: "blank" },
  { id: 5, type: "blank" },
];

export const updatedLanguageOptions = [
  "Chinese",
  "Tagalog",
  "French",
  "German",
  "Greek",
  "Hindi",
  "Italian",
  "Mandarin",
  "Spanish",
];

// export const getDistanceBetweenTwoPoints = (cord1, cord2) => {
//   console.log(cord1, cord2, "coordinateshk");
//   if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
//     return 0;
//   }

//   const radlat1 = (Math.PI * cord1.lat) / 180;
//   const radlat2 = (Math.PI * cord2.lat) / 180;

//   const theta = cord1.lon - cord2.lon;
//   const radtheta = (Math.PI * theta) / 180;

//   let dist =
//     Math.sin(radlat1) * Math.sin(radlat2) +
//     Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

//   if (dist > 1) {
//     dist = 1;
//   }

//   dist = Math.acos(dist);
//   dist = (dist * 180) / Math.PI;
//   dist = dist * 60 * 1.1515;
//   dist = dist * 1.609344; //convert miles to km
//   dist = dist * 1000; //convert km to m
//   return Math.round(dist);
// };

export const getDistanceBetweenTwoPoints = (cord1, cord2) => {
  const lat1 = cord1?.lat;
  const lon1 = cord1?.lon;
  const lat2 = cord2?.lat;
  const lon2 = cord2?.lon;
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
};

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

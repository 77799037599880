import { Button, Col, Modal, Row, Spin } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import Sidebar from "../../../components/sidebar";
import { setAlert } from "../../../redux/alert/alert.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import "./relative.less";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function RelativesList(props) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [relativeList, setRelativeList] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [loading, setLoading] = useState(false);
  const [selectedRelative, setSelectedRelative] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmation] = useState(false);

  const fetchRelatives = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-relatives`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setRelativeList(response.data.output);
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    fetchRelatives();
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb([
        {
          title: "My Care Recipients",
          route: ``,
        },
      ])
    );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log(relative, "relative")

  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/* {isMobile ? (*/}
      {/*    <div className='container      relative-list-container-mobile'>*/}
      {/*    <div className='pt-2 d-flex'>*/}
      {/*        <div className='mx-1 w-100'>*/}
      {/*            <Responsiveheader title="Relative lists" />*/}
      {/*            <div className="d-flex justify-content-center">*/}
      {/*              <Button className="add-relative-btn" onClick={() => navigate("/client/relatives/add-new-relative")}>+ Add Relative</Button>*/}
      {/*            </div>*/}
      {/*            <Spin spinning={loading} tip="loading...">*/}
      {/*                <div className="relative-list">*/}
      {/*                    {*/}
      {/*                        relativeList.map(relative => {*/}
      {/*                            return (*/}
      {/*                                <Row gutter={[24, 12]} className="relative-wrapper" onClick={() => navigate(`/client/relatives/view-relative/${relative._id}`, {state: {relativeData: relative}})}>*/}
      {/*                                  <Col xs={12}>*/}
      {/*                                    <div className="d-flex ">*/}
      {/*                                      <div className="relative-profile d-flex align-items-center">*/}
      {/*                                      {relative?.image ?*/}
      {/*                                      <img src={relative?.image} alt="profile-pic" width={50} height={50}/>*/}
      {/*                                      : */}
      {/*                                      <img src="/images/icons/Profile.png" width={50} height={50} style={{border: '1px solid gray', padding: '3px'}}/>*/}
      {/*                                      }*/}
      {/*                                      <h4 className="relative-name">{relative?.firstName} {relative?.lastName}</h4>*/}
      {/*                                      </div>*/}
      {/*                                      /!* <div className="address-wrapper">*/}
      {/*                                       <p className="relative-address">{relative?.address}, {relative?.city}, {relative?.province} {relative?.postalCode}</p>*/}
      {/*                                      </div> *!/*/}
      {/*                                    </div>*/}
      {/*                                  </Col>*/}
      {/*                                  */}
      {/*                                  <Col xs={12} className="relative-actions">*/}
      {/*                                      <div className="btn-wrapper d-flex">*/}
      {/*                                         <img src="/images/icons/delete.svg" width={20} onClick={e => {*/}
      {/*                                          e.stopPropagation();*/}
      {/*                                          setDeleteConfirmation(true);*/}
      {/*                                          setSelectedRelative(relative);*/}
      {/*                                         }}/>*/}
      {/*                                         <img src="/images/icons/edit-relative.svg" width={20} onClick={(e) => {*/}
      {/*                                          e.stopPropagation();*/}
      {/*                                          navigate(`/client/relatives/${relative._id}`, {state: {relativeData: relative}})*/}
      {/*                                         }}/>*/}
      {/*                                      </div>*/}
      {/*                                  </Col>    */}
      {/*                                  <Col xs={24}>*/}
      {/*                                    <div className="address-wrapper">*/}
      {/*                                       <p>{relative?.address},{relative?.city},{relative?.province}{relative?.postalCode}</p>*/}
      {/*                                      </div>*/}
      {/*                                  </Col>       */}
      {/*                                </Row>*/}
      {/*                                */}
      {/*                            )*/}
      {/*                        })*/}
      {/*                    }*/}
      {/*                </div>*/}
      {/*                <DeleteConfirmationModal */}
      {/*                deleteConfirmationVisible={deleteConfirmationVisible}*/}
      {/*                handleDeleteModalClose={() => setDeleteConfirmation(false)}*/}
      {/*                selectedRelative={selectedRelative}*/}
      {/*                fetchRelatives={fetchRelatives}*/}
      {/*                LISTING_PAGE*/}
      {/*                />*/}
      {/*            </Spin>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*  ) : (*/}
      <div className="container-fluid appointment-dashboard relative-list-container-web">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 message-inner-row w-100">
            <AppointmentNavBar />
            <div className="d-flex justify-content-end">
              <Button
                className="add-relative-btn"
                onClick={() => navigate("/client/relatives/add-new-relative")}
              >
                + Add Care Recipient
              </Button>
            </div>
            <Spin spinning={loading}>
              <div className="relative-list">
                {relativeList.map((relative) => {
                  return (
                    <Row
                      className="relative-wrapper"
                      onClick={() =>
                        navigate(
                          `/client/relatives/view-relative/${relative._id}`,
                          { state: { relativeData: relative } }
                        )
                      }
                    >
                      <Col md={20} sm={20} lg={16} xl={16} xs={20}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="relative-profile d-flex align-items-center">
                            {relative?.image ? (
                              <img
                                src={relative?.image}
                                alt="profile-pic"
                                width={50}
                                height={50}
                              />
                            ) : (
                              <img
                                alt={""}
                                src="/images/icons/Profile.png"
                                width={50}
                                height={50}
                                style={{
                                  border: "1px solid gray",
                                  padding: "3px",
                                }}
                              />
                            )}
                            <h3 className="relative-name">
                              {relative?.firstName} {relative?.lastName}
                            </h3>
                          </div>
                          <div className="address-wrapper">
                            <p className="relative-address">
                              {relative?.address}, {relative?.city},{" "}
                              {relative?.province} {relative?.postalCode}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        md={4}
                        sm={4}
                        lg={8}
                        xl={8}
                        xs={4}
                        className="relative-actions"
                      >
                        <div className="btn-wrapper d-flex justify-content-between">
                          <img
                            src="/images/icons/delete.svg"
                            width={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteConfirmation(true);
                              setSelectedRelative(relative);
                            }}
                          />
                          <img
                            className="ms-4"
                            src="/images/icons/edit-relative.svg"
                            width={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`/client/relatives/${relative._id}`, {
                                state: { relativeData: relative },
                              });
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
              <DeleteConfirmationModal
                deleteConfirmationVisible={deleteConfirmationVisible}
                handleDeleteModalClose={() => setDeleteConfirmation(false)}
                selectedRelative={selectedRelative}
                fetchRelatives={fetchRelatives}
                LISTING_PAGE
              />
            </Spin>
            <Footer />
          </div>
        </div>
      </div>
      {/*)}*/}
    </div>
  );
}

export const DeleteConfirmationModal = (props) => {
  const navigate = useNavigate();
  const {
    LISTING_PAGE,
    deleteConfirmationVisible,
    handleDeleteModalClose,
    selectedRelative,
    fetchRelatives,
  } = props;
  const [saveloading, setSaveLoading] = useState(false);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const handleDeleteRelative = () => {
    if (token !== "") {
      setSaveLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/delete-relatives`,
          { id: selectedRelative?._id },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            handleDeleteModalClose(false);
            if (!LISTING_PAGE) {
              navigate("/client/relatives");
            } else {
              fetchRelatives();
            }
          } else if (response.data.status >= 400) {
            setSaveLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setSaveLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  return (
    <Modal
      className="info-modal-container"
      centered
      width={390}
      header={null}
      footer={null}
      open={deleteConfirmationVisible}
      onOk={handleDeleteModalClose}
      onCancel={handleDeleteModalClose}
      closable={false}
    >
      <div className="image-container">
        <img
          alt={""}
          src="/images/icons/delete-relative.svg"
          className="text-center"
        />
      </div>
      <h3 className="mt-3 fw-semibold fs-4 text-center">
        Are you sure you want to delete {selectedRelative?.firstName || ""}’s
        Profile?
      </h3>
      <div className="common-button-yes-no mt-3">
        <Button onClick={() => {handleDeleteRelative();}} loading={saveloading} className="yes-button">Yes</Button>
        <Button htmlType="submit" onClick={handleDeleteModalClose} className="no-button">No</Button>
      </div>
    </Modal>
  );
};

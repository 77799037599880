import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleChooseRelativeModal,
  toggleRedirectAppModal,
} from "../../../redux/modals/toggleModal.actions";
import { Button } from "antd";
import { selectedCC } from "../../../redux/appointment/appointment.action";

export default function NoAppointment() {
  const dispatch = useDispatch();
  const appointmentData = useSelector((state) => state.appointment);
  const appointmentDetails = appointmentData.paymentDetails;

  const handleChooseRelative = () => {
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...appointmentDetails, scheduleAgain: false }));
    // dispatch(toggleRedirectAppModal(true));
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="text-center">
        <img
          width={150}
          src="/images/icons/bird-icon.svg"
          alt="create appointment"
        />
        <h5 className="add-first-appointment">Add Your First Appointment</h5>
        <p className="small-descripation">
          Book a new appointment by clicking the + button
        </p>
        <Button className="plus-icon-custom" onClick={handleChooseRelative}>
          <img
            className="cursor-pointer"
            alt="light outline plus icon"
            src="/images/icons/plus-icon-white.svg"
          />
        </Button>
      </div>
    </div>
  );
}

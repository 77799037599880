import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/sidebar";
import ActionCreator from "../../redux/Breadcrumb/ActionCreator";
import AppointmentNavBar from "../client/appointment/appointmentsNavBar";
import Availability from "./Availability";
import Responsiveheader from "../../components/Responsiveheader";
import {Row, Col} from "antd";
import './editAvalability.less'

export default function EditAvailablity(props) {
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 930);
    useEffect(() => {
        dispatch(
            ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.dashboard.concat({
                title: 'Availability',
                route: `appointment-details`,
            },))
        );

    }, []);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <div className="main-content" style={{background: "#F7F9FA", minHeight: "100vH"}}>
            <div className='container-fluid appointment-dashboard'>
                {/*{isMobile ? (<Row className="edit-availability-content">*/}
                {/*      <Col xs={24} md={24}>*/}
                {/*      <div className='pt-3 d-flex profile-details-wrapper'>            */}
                {/*      <div className='w-100'>*/}
                {/*        <Responsiveheader title="Availability" />*/}
                {/*          <div className='border-less rounded' style={{ background: "#fff", padding: '15px 25px',height:"78vh" }}>*/}
                {/*              <Availability isEdit/>*/}
                {/*          </div>*/}
                {/*      </div>*/}
                {/*  </div>*/}
                {/*      </Col>*/}
                {/*      </Row>) : (*/}
                <div className='pt-5 d-flex profile-details-wrapper'>
                    <Sidebar/>
                    <div className='ms-4 w-100 message-inner-row'>
                        <AppointmentNavBar/>
                        <div className='border-less rounded availability-main-div'
                             style={{background: "#fff", padding: '15px 25px', height: "78vh"}}>
                            <Availability isEdit/>
                        </div>
                        <Footer/>
                    </div>
                </div>
                {/*)}*/}
            </div>
        </div>
    )
}



import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;
const Confirmation = (props) => {
    const { open,onclose, isDelete, key } =
      props;
    return (
      <Modal
        className="info-modal-container"
        centered
        width={390}
        header={null}
        footer={null}
        open={open}
        // onOk={ishandleRejected}
        onCancel={onclose}
        closable={false}
      >
        <div className="image-container">
          <img
            src="/images/icons/payment-unsuccessful.svg"
            className="text-center"
          />
        </div>
        <h3 className="mt-3 fw-semibold expense_popup_text text-center">
          Are you sure you want to clear  this  Notifications?
        </h3>
  
        <Row gutter={24}>
          <Col  span={24} className="common-button-yes-no mt-3">
            <Button onClick={() => isDelete(key)} className="yes-button">Yes</Button>
             <Button onClick={onclose} className="no-button">No</Button>
          </Col>
        </Row>
      </Modal>
    );
  };

   export default Confirmation
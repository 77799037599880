import { Button, Checkbox, Col, Form, Image, Input, Modal, Row, Select, Slider, Spin, DatePicker, List } from "antd";
import { useState } from "react";
import { Option } from "antd/lib/mentions";
import { CloseCircleOutlined, CheckCircleFilled } from "@ant-design/icons"
import { useEffect } from "react";
import axios from "axios";
import { setAlert } from "../../../../redux/alert/alert.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const CompanionModals = ({ thirdModal, handleSubmitModel3, setThirdModal, marks, token,setFourthModal,caregiverProfileDetails}) => {
    const formatter = (value) => `CAD ${value}`;
    const [disabledSave, setDisabledSave] = useState(true);
    const [expectedRate, setExpectedRate] = useState("");
    const [caregiverServices, setCaregiverServices] = useState("");
    const [loading,setLoading]=useState(false)
    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const fetchCareGiverServices = async (type) => {
        //console.log(token)
        console.log("In Fetch Service",type)
        
        if (token !== '') {
          setLoading(true)
          await axios.post(`${process.env.REACT_APP_BASE_URL}/service/list-services`, {
            "type": type
          }, {
            headers: {
              accessToken: token,
              'Authorization': 'Basic ' + btoa(process.env.REACT_APP_USERNAME + ':' + process.env.REACT_APP_PASSWORD),
            }
          })
            .then((response) => {
              console.log("services", response.data)
              if (response.data.status === 200) {
                setLoading(false)
                console.log(response.data.output.result,"services")
                setCaregiverServices(response.data.output.result)
    
              } else if (response.data.status >= 400) {
                setLoading(false)
                dispatch(setAlert({
                  'alertType': "error",
                  'alertMsg': response?.data?.error?.message,
                  'alertOpen': true
                }))
              } else {
                setLoading(false)
                dispatch(setAlert({
                  'alertType': "error",
                  'alertMsg': 'something went wrong! please try again.',
                  'alertOpen': true
                }))
              }
            })
            .catch((err) => {
              setLoading(false)
              if(err?.response?.data?.status >= 400&&err?.response?.data?.status !== 401) {
                dispatch(
                  setAlert({
                    alertType: "error",
                    alertMsg: err?.response?.data?.error?.message,
                    alertOpen: true,
                  })
                );
    }
            })
        }
      }
    useEffect(()=>{
      
        fetchCareGiverServices(1);
        
    },[caregiverProfileDetails?.id])
    const handleFormChange = () => {
        const areTruthy = Object.values(form.getFieldsValue()).every(
            (value) => value
        );

        const hasErrors =
            !areTruthy ||
            form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
        console.log(hasErrors, "haserrors")
        setDisabledSave(hasErrors);
    };
    return (
        <Modal
            open={thirdModal}
            onCancel={() => setThirdModal(false)}
            closeIcon={<CloseCircleOutlined color="#000" />}
            footer={null}
            width={"350px"}
           
            position={"fixed"}
            centered
            // style={{ top: 150 }}
            className="modelthree"
        >
            <div className="companionmodelthree">
                <h2 className="text-center">Upgrade to Caregiver</h2>
                <Form layout="vertical" onFinish={handleSubmitModel3} onFieldsChange={handleFormChange}
                    form={form}   >
                    <Form.Item
                        label={<h3 className="mb-0">Expected hourly rate?</h3>}
                        name="expectedHourlyRate"
                      //  rules={[{ required: true, message: 'let us know your expected hourly rate' }]}
                    >
                        <br className="d-none-mob" />
                        <br />
                        <Slider
                        range
                        included
                            value={expectedRate}
                            onChange={(val) => {
                                console.log(val)
                                form.setFieldValue('expectedHourlyRate', val)
                                setExpectedRate(val)
                                handleFormChange()
                            }}
                            tooltip={{ formatter }} marks={marks} step={null} min={18} max={25} />
                        <p className='color-secondary mt-2' style={{ fontSize: "12px" }}>You are more likely to get more care jobs if rates are set reasonably, until you  build a client base and positive reviews.</p>
                    </Form.Item>
                    <h3 >Update to Caregiver Servies</h3>
                    <Form.Item
                        //label="Select Your Service"
                        name="services"
                        //rules={[{ required: true }]}
                    >
                        <Select
                            size="large"
                            mode="multiple"
                            showSearch
                            showArrow
                            style={{
                                width: "100%",
                            }}
                            className="upgradeselect"
                            menuItemSelectedIcon={<CheckCircleFilled />}
                            placeholder="Select Your Service"
                            value={caregiverServices}
                            onChange={(val) => {
                                console.log(val)
                                form.setFieldValue('selectedServices', [])
                             //   setCaregiverServices([])
                                handleFormChange()
                            }}
                        >
                            {caregiverServices?.length > 0 && caregiverServices?.map((item,index) => {
                                return (
                                    <Option value={item?._id} key={index}  >
                                        <div className="demo-option-label-item">{item?.name}</div>
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Row>
                        <Col span={12} className="text-center">
                            <Button  className="mt-3 " onClick={(e) => {setThirdModal(false);setFourthModal(false)}}
                                style={{ width: '80%', height: "70%", backgroundColor: "#E7E8EA",padding:"10px",borderRadius:"30px" }}
                                shape="round" size="large" >Cancel</Button>
                        </Col>
                        <Col span={12} className="">
                            <Button htmlType="submit" className="btn-black mt-3 " style={{  width: '90%', height: "70%", backgroundColor: "#E7E8EA",padding:"10px",borderRadius:"30px" }}
                                shape="round" size="large" disabled={disabledSave}
                            >Save</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}
export default CompanionModals;
import { HttpService } from "./axios";

// import {HttpService} from '../axios'
const _getBannerStatus = async () => {
  const res = await HttpService.post("/user/home", {});

  return res.data;
};
const _deleteNotification = async (payload) => {
  const res = await HttpService.post("/user/delete-notification", payload);

  return res.data;
};

const _deleteByNotification = async (payload) => {
  const res = await HttpService.post("/user/delete-notification", payload);

  return res.data;
};

const _isBookmarked = async (payload) => {
  const res = await HttpService.post("/user/add-bookmark", payload);

  return res.data;
};

const _getTwillioAccessToken = async () => {
  const res = await HttpService.get("/user/get-twilio-token");
  return res.data;
};

const _getCheckAvailableToMsg = async (payload) => {
  const res = await HttpService.post("/user/check-available-message", payload);
  return res.data;
};

const _sendMessageNotification = async (payload) => {
  const res = await HttpService.post(
    "/user/send-message-notification",
    payload
  );
  return res.data;
};

export default {
  _getBannerStatus,
  _deleteNotification,
  _deleteByNotification,
  _isBookmarked,
  _getTwillioAccessToken,
  _getCheckAvailableToMsg,
  _sendMessageNotification,
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../components/sidebar";
import AppointmentNavBar from "../pages/client/appointment/appointmentsNavBar";
import ActionCreator from "../redux/Breadcrumb/ActionCreator";

import "./styles/contactus.less";
import Footer from "../components/Footer/Footer";
import { Link } from "react-router-dom";
import { Button, message } from "antd";

function ContactUs() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const dispatch = useDispatch();
  const userMode = useSelector((state) => state.loginData.userMode);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 840);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb([
        {
          title: "Contact Us",
          route: `appointment-details`,
        },
      ])
    );
  }, []);

  const handleCallUs = () => {
    navigator.clipboard.writeText("+1 416-626-0591");
    message.success("Copied to clipboard.");
  };
  return (
    <>
      <div
        className="message-main contact-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div
          className="m-mob-0 appointment-dashboard px-3-mob"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            padding: "0 0.75rem",
          }}
        >
          {/*{isMobile ? (*/}
          {/*    <div className='pt-3 d-flex'>*/}
          {/*        <div className='w-100'>*/}
          {/*            <Responsiveheader title="Contact Us"/>*/}
          {/*            <div className="clientsettings">*/}
          {/*                <div style={{*/}
          {/*                    flexDirection: "column",*/}
          {/*                    display: "flex",*/}
          {/*                    justifyContent: "center",*/}
          {/*                    alignItems: "center",*/}
          {/*                    width: '100%',*/}
          {/*                    maxHeight: '70vh'*/}
          {/*                }}>*/}

          {/*                    <img style={{width: "45%"}} src="/images/icons/emailimage.svg"></img>*/}
          {/*                    <h3 style={{marginTop: "10px"}}>{userMode === "client" ? "clientsupport@mfhomecare.com" : "caregiversupport@mfhomecare.com"}</h3>*/}
          {/*                    <div className="sendmailbtn">< a className="emailref"*/}
          {/*                                                     href={userMode === "client" ? "mailto:clientsupport@mfhomecare.com" : "mailto:caregiversupport@mfhomecare.com"}*/}
          {/*                                                     style={{*/}
          {/*                                                         color: "white",*/}
          {/*                                                         fontWeight: "normal !important",*/}
          {/*                                                         textAlign: "center",*/}
          {/*                                                         fontSize: "14px"*/}
          {/*                                                     }}>Send Email</a>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*) : (*/}
          <div className="pt-5 d-flex client_Expenses">
            <Sidebar />
            <div className="m-mob-0 w-100 ipad-width contact-us-main">
              {/* <SettingsNavBar /> */}
              <AppointmentNavBar />
              <div className="clientsettings contact-client-row">
                <div className="contactinfo">
                  <img
                    alt=""
                    className="contactimage"
                    src="/images/posters/contact-us-illustrate-icon.svg"
                  />
                  <>
                    {/*<img*/}
                    {/*  className="email-icon-img"*/}
                    {/*  alt=""*/}
                    {/*  style={{ width: "150px" }}*/}
                    {/*  src="/images/icons/bird-icon.svg"*/}
                    {/*/>*/}
                    <h3 className="contact-support-main">
                      <Link
                        to={
                          userMode === "client"
                            ? "mailto:clientsupport@mfhomecare.com"
                            : "mailto:caregiversupport@mfhomecare.com"
                        }
                      >
                        {userMode === "client"
                          ? "clientsupport@mfhomecare.com"
                          : "caregiversupport@mfhomecare.com"}
                      </Link>
                    </h3>
                    {/*<p*/}
                    {/*    className="support-contact-no text-center"*/}
                    {/*    style={{*/}
                    {/*        fontSize: "16px",*/}
                    {/*        cursor: "pointer",*/}
                    {/*    }}*/}
                    {/*    onClick={handleCallUs}*/}
                    {/*>*/}
                    {/*    +1 416-626-0591*/}
                    {/*</p>*/}
                    <div className="">
                      {/* <Button*/}
                      {/*    // htmlType="submit"*/}
                      {/*    onClick={handleCallUs}*/}
                      {/*    className="btn-black mx-auto auth-action-btn call-btn"*/}
                      {/*    shape="round"*/}
                      {/*    type="primary"*/}
                      {/*    size="large"*/}
                      {/*>*/}
                      {/*    Call Us*/}
                      {/*</Button> */}
                      <a
                        className="emailref"
                        href={
                          userMode === "client"
                            ? "mailto:clientsupport@mfhomecare.com"
                            : "mailto:caregiversupport@mfhomecare.com"
                        }
                        style={{
                          color: "white",
                          fontWeight: "400 !important",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                      >
                        <div className="sendmailbtn">Send Email</div>
                      </a>
                      {userMode == "client" && isMobile && (
                        <a
                          className="emailref"
                          href={"tel:+14166260591"}
                          style={{
                            display: "block",
                            marginTop: "20px",
                            color: "white",
                            fontWeight: "400 !important",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          <div className="sendmailbtn">Call Us</div>
                        </a>
                      )}
                    </div>
                  </>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          {/*)}*/}
        </div>
      </div>
    </>
  );
}

export default ContactUs;

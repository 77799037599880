import React, { useState, useEffect } from "react";
import { Button, Modal, message } from "antd";
import RadioBox from "../components/forms/v-radio-box";
import "./styles/Welcome.less";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setUserMode } from "../redux/auth/auth.action";
import { handleRouting } from "./helper/handleRoute";
import { setAlert } from "../redux/alert/alert.action";
import { CloseCircleOutlined } from "@ant-design/icons";

function ContactRoleSelection({ accessToken, user }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  const [customer, setCustomer] = useState("");
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const handleRadioChange = (e) => {
    setCustomer(e.target.value);
  };

  const handleNext = () => {
    if (customer === "client") {
      setInfoModalOpen(true);
    } else {
      window.location.href = "https://mfhomecare.com/#contact-section";
    }
  };

  const typeOfCustomers = [
    {
      icon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/client.svg"
          alt="client icon"
        />
      ),
      activeIcon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/client_active.svg"
          alt="client icon"
        />
      ),
      name: "I am a Client",
      helperText: "Looking for care",
      value: "client",
    },
    {
      icon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/caregiver.svg"
          alt="caregiver icon"
        />
      ),
      activeIcon: (
        <img
          className="me-3"
          width={45}
          src="/images/icons/caregiver_bold.svg"
          alt="caregiver icon"
        />
      ),
      name: "I am a Caregiver/Jobs",
      helperText: "Looking for Jobs",
      value: "caregiver",
    },
  ];
  return (
    <div className="center-container main-div-top min-vh-100">
      <div className="welcome-container">
        <div className="left-side-poster welcome-poster" />
        <div className="right-side-form">
          <div className="center-container justify-content-start h-100">
            <div className="w-100">
              <img
                className="logo-img pt-0 mb-4 main-logo"
                src="./images/icons/logo_with_name.svg"
                alt="logo"
              />
              <h1
                className="welcome-header mb-2"
                style={isMobile ? { color: "#364bc6", fontSize: "25px" } : {}}
              >
                Welcome to {!isMobile && <span id="name">MF Homecare.</span>}
              </h1>
              {isMobile && (
                <div className="d-flex justify-content-center">
                  <img
                    className="logo-img pt-0 mb-4"
                    src="./images/icons/logo_with_name.svg"
                    alt="logo"
                    width={200}
                  />
                </div>
              )}
              <div className="full-width" style={{ maxWidth: "370px" }}>
                <p className="welcome-subheader mb-3">
                  Please let us know which type of support you need
                </p>
                <div className="mb-3 mt-5 radio-box-div">
                  <RadioBox
                    btnClass="p-3 mb-3 h-100 fs-5 fw-bold"
                    sufixStyle={{ width: 22 }}
                    options={typeOfCustomers}
                    value={customer}
                    defaultValue={"client"}
                    dir="vertical"
                    onChange={handleRadioChange}
                    isSupport
                  />
                </div>
                <Button
                  className="btn-black ps-5 pe-5 home-button common-theme-btn"
                  onClick={() => handleNext()}
                  shape="round"
                  type="primary"
                  size="large"
                  disabled={customer === "" ? true : false}
                >
                  Confirm{" "}
                  <img
                    className="ms-2"
                    src="/images/icons/right.svg"
                    alt="right icon"
                  />
                </Button>
              </div>
            </div>
            <InfoModal
              infoModalOpen={infoModalOpen}
              handleInfoModalClose={() => setInfoModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData?.user?.userType,
    screen: state.loginData?.user?.screen,
    accessToken: state.loginData?.user?.accessToken,
    user: state.loginData?.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const InfoModal = (props) => {
  const { infoModalOpen, handleInfoModalClose } = props;
  const handleCallUs = () => {
    navigator.clipboard.writeText("+1 416-626-0591");
    message.success("Copied to clipboard.");
    handleInfoModalClose();
  };
  return (
    <div>
      <Modal
        className="support-role-container"
        centered
        width={365}
        style={{ maxWidth: "75vw !important" }}
        header={null}
        footer={null}
        open={infoModalOpen}
        onOk={handleInfoModalClose}
        onCancel={handleInfoModalClose}
        closable
        closeIcon={<CloseCircleOutlined />}
      >
        <div className="image-container d-flex justify-content-center">
          <img
            className="logo-img pt-0 mb-4 main-logo"
            src="./images/icons/logo_with_name.svg"
            alt="logo"
            width={200}
            style={{ display: "flex", justifyContent: "center" }}
          />
        </div>
        <div className="image-container d-flex justify-content-center">
          <img
            alt=""
            src="/images/icons/support-role.svg"
            className="text-center"
          />
        </div>
        <p className="text-center mt-3 info-text">
          Please call us <br />
          if you have any questions or would like to learn more
        </p>
        <p className="support-contact-no my-3 text-center">+1 416-626-0591</p>
        <p className="support-email my-3 text-center">
          clientsupport@mfhomecare.com
        </p>
        <Button
          // htmlType="submit"
          onClick={handleCallUs}
          className="btn-black mx-auto auth-action-btn mt-3 mb-3"
          shape="round"
          type="primary"
          size="large"
        >
          Call Us
        </Button>
      </Modal>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactRoleSelection);

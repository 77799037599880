import { Button, Col, Divider, Row, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import ScrollWrapper from "../../../components/ScrollWrapper/ScrollWrapper";
import Sidebar from "../../../components/sidebar";
import { setAlert } from "../../../redux/alert/alert.action";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import { DeleteConfirmationModal } from "./relativesList";

const { Title } = Typography;

export default function RelativeDetails(props) {
  const { state = {} } = useLocation();
  const { relativeData } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const param = useParams();
  const [deleteConfirmationVisible, setDeleteConfirmation] = useState(false);
  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(
        ActionCreator.BreadcrumbItems.relatives.concat({
          title: relativeData?.firstName,
          route: `relatives/view-relative/${param.id}`,
          state: { relativeData: relativeData },
        })
      )
    );

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  //    console.log(appointment, ";")
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  console.log(relativeData, "relativeData");

  const formatPhoneNumber = () => {
    if (!relativeData?.phone) return "";
    const countryCode = relativeData?.countryCode; // +12
    const phone = relativeData?.phone;
    return `+${countryCode} ${phone.slice(0, 3)}-${phone.slice(
      3,
      6
    )}-${phone.slice(6, phone?.length)}`;
  };
  return (
    <>
      {/*{isMobile?<>*/}
      {/*    <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
      {/*    <div className='container     relative-details-container'>*/}
      {/*          <div className='pt-4 d-flex'>*/}
      {/*              <div className='mx-1 w-100'>*/}
      {/*                  <Responsiveheader title="Relative Details" />*/}
      {/*                  */}
      {/*                  <div className="relative-details" style={{ background: "#fff", padding: "20px 20px 0 20px", borderRadius: "25px", height: "90vh", overflow: "auto" }}>*/}
      {/*                            <div className="profile-wrapper d-flex align-items-center">*/}
      {/*                                {relativeData?.image ?*/}
      {/*                                <img src={relativeData?.image} alt="relative-img" width="50" height="50" />*/}
      {/*                                : */}
      {/*                                <img src="/images/icons/Profile.png" width={50} height={50} style={{border: '1px solid gray', padding: '3px'}}/>*/}
      {/*                                }*/}
      {/*                                <h3 className="relative-name">{relativeData?.firstName} {relativeData?.lastName}</h3>*/}
      {/*                            </div>*/}
      {/*                            <Row gutter={[24, 8]}>*/}
      {/*                                <Col xl={4}>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Date of birth*/}
      {/*                                    </p>*/}
      {/*                                    <p className="values">*/}
      {/*                                        {relativeData?.dob}*/}
      {/*                                    </p>*/}
      {/*                                </Col>*/}
      {/*                                <Col xl={4}>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Phone Number*/}
      {/*                                    </p>*/}
      {/*                                    {relativeData?.phone ? */}
      {/*                                        <p className="values">*/}
      {/*                                        +{relativeData?.phone}*/}
      {/*                                    </p>*/}
      {/*                                    : */}
      {/*                                    <p className="values">*/}
      {/*                                   N/A*/}
      {/*                                </p>*/}
      {/*                                    }*/}
      {/*                                   */}
      {/*                                </Col>*/}
      {/*                               */}
      {/*                                <Col  xl={4}>*/}
      {/*                                    <div className="d-flex">*/}
      {/*                                        <img src="/images/icons/location.svg" width={15} />*/}
      {/*                                        <span style={{*/}
      {/*                                            marginLeft: "8px", fontWeight: 400,*/}
      {/*                                            fontSize: "16px",*/}
      {/*                                            lineHeight: '23px'*/}
      {/*                                        }}>*/}
      {/*                                            {`${relativeData?.address}, ${relativeData?.city}, ${relativeData?.province}`} {relativeData?.postalCode}*/}
      {/*                                        </span>*/}
      {/*                                    </div>*/}
      {/*                                </Col>*/}
      {/*                                <Divider />*/}
      {/*                                <div style={{ height: "calc(65vh - 200px)", overflow:"scroll" }}>*/}

      {/*                                <Col lg={24} xl={24} style={{width:"100%"}}>*/}
      {/*                                <Title className="color-secondary" level={3}>Additional Information</Title>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Does your relative have any of these conditions?*/}
      {/*                                    </p>*/}
      {/*                                    <p className="values">*/}
      {/*                                        {relativeData?.diseaseList.length > 0 ?*/}
      {/*                                            relativeData.diseaseList.map((i, idx) => i.map(n => `${n.name}${relativeData.diseaseList.length != idx + 1 ? ', ' : ''}`))*/}
      {/*                                            :*/}
      {/*                                            "NA"*/}
      {/*                                        }*/}
      {/*                                    </p>*/}
      {/*                                </Col>*/}
      {/*                                <Col lg={24} xl={24}>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Does your relative have a preference about the caregiver's gender?*/}
      {/*                                    </p>*/}
      {/*                                    <p className="values">*/}
      {/*                                        {relativeData?.gender == 1 ? 'Male' : relativeData?.gender == 2 ? 'Female' : 'Prefer Not to Say'}*/}
      {/*                                    </p>*/}
      {/*                                </Col>*/}
      {/*                                <Col lg={24} xl={24}>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Does your relative have any pets?*/}
      {/*                                    </p>*/}
      {/*                                    <p className="values">*/}
      {/*                                        {relativeData?.pets?.length > 0 ?*/}
      {/*                                            relativeData?.pets.map(pet => pet)*/}
      {/*                                            :*/}
      {/*                                            "NA"*/}
      {/*                                        }*/}
      {/*                                    </p>*/}
      {/*                                </Col>*/}
      {/*                                <Col lg={24} xl={24}>*/}
      {/*                                    <p className="label">*/}
      {/*                                        Any additional information or instructions not covered yet and deemed important for the caregivers to be aware of before the appointment?*/}
      {/*                                    </p>*/}
      {/*                                    <p className="values">*/}
      {/*                                        {relativeData?.additionalInfo?? "N/A"}*/}
      {/*                                    </p>*/}
      {/*                                </Col>*/}
      {/*                                </div>*/}
      {/*                            </Row>*/}

      {/*                            <Row  gutter={[24, 12]}>*/}
      {/*                        <Col lg={24} xl={24}>*/}
      {/*                                    <div className='d-flex text-right px-3 mt-5 mb-4' style={{ justifyContent: 'right', bottom:"6rem" }}>*/}
      {/*                                        <Button*/}
      {/*                                            onClick={() => setDeleteConfirmation(true)}*/}
      {/*                                            className="btn-back auth-action-btn mt-3 mx-3" shape="round" size="large">*/}
      {/*                                            Delete*/}
      {/*                                        </Button>*/}
      {/*                                        <Button*/}
      {/*                                            htmlType="submit"*/}
      {/*                                            // onClick={() => navigate("/client/appointment")}*/}
      {/*                                            onClick={() => navigate(`/client/relatives/${relativeData._id}`, { state: { relativeData: relativeData } })}*/}
      {/*                                            className="btn-black auth-action-btn mt-3" shape="round" type="primary" size="large">*/}
      {/*                                            Edit*/}
      {/*                                        </Button>*/}
      {/*                                    </div>*/}
      {/*                        </Col>*/}
      {/*                        </Row>*/}
      {/*                        </div>*/}

      {/*                     */}

      {/*                  </div>*/}

      {/*                  </div>*/}
      {/*                  </div>*/}
      {/*    </div>*/}

      {/*    <DeleteConfirmationModal */}
      {/*                        deleteConfirmationVisible={deleteConfirmationVisible}*/}
      {/*                        handleDeleteModalClose={() => setDeleteConfirmation(false)}*/}
      {/*                        selectedRelative={relativeData}*/}
      {/*                        />*/}
      {/*</>:*/}

      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div className="container-fluid appointment-dashboard relative-details-container">
          <div className="pt-5 d-flex client_Expenses">
            <Sidebar />
            <div className="mx-4 w-100 message-inner-row">
              <AppointmentNavBar />
              <div
                className="relative-details"
                style={{
                  background: "#fff",
                  padding: "20px 20px 0 20px",
                  borderRadius: "25px",
                  height: "80vh",
                  overflow: "auto",
                }}
              >
                <div className="profile-wrapper d-flex align-items-center">
                  {relativeData?.image ? (
                    <img
                      src={relativeData?.image}
                      alt="relative-img"
                      width="50"
                      height="50"
                    />
                  ) : (
                    <img
                      src="/images/icons/Profile.png"
                      width={50}
                      height={50}
                      style={{ border: "1px solid gray", padding: "3px" }}
                    />
                  )}
                  <h3 className="relative-name">
                    {relativeData?.firstName} {relativeData?.lastName}
                  </h3>
                </div>
                <Row className="text-left-mobile">
                  <Col lg={4} xl={4} xs={12}>
                    <p className="label">Date of Birth</p>
                    <p className="values">{relativeData?.dob}</p>
                  </Col>
                  <Col lg={4} xl={4} xs={12}>
                    <p className="label">Phone Number</p>
                    {relativeData?.phone ? (
                      <p className="values">
                        {formatPhoneNumber()}
                        {/* {relativeData?.phone
                          ? `+${relativeData?.phone?.[0]} `
                          : "+1"}
                        {[
                          relativeData?.phone?.slice(1, 4),
                          " ",
                          relativeData?.phone?.slice(4, 7),
                          " ",
                          relativeData?.phone?.slice(7),
                        ].join("")} */}
                      </p>
                    ) : (
                      <p className="values">N/A</p>
                    )}
                  </Col>

                  <Col lg={6} xl={4}>
                    <div className="d-flex">
                      <img alt="" src="/images/icons/location.svg" width={15} />
                      <span
                        style={{
                          marginLeft: "8px",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "23px",
                        }}
                      >
                        {`${relativeData?.address}, ${relativeData?.city}, ${relativeData?.province}`}{" "}
                        {relativeData?.postalCode}
                      </span>
                    </div>
                  </Col>
                  <Divider />
                  <div
                    style={{ height: "calc(67vh - 200px)", overflow: "scroll" }}
                  >
                    <Col lg={24} xl={24} style={{ width: "100%" }}>
                      <Title className="color-secondary" level={3}>
                        Additional Information
                      </Title>
                      <p className="label">
                        Does the care recipient have any of these conditions?
                      </p>
                      <p className="values">
                        {relativeData?.diseaseList.length > 0 ? (
                          <>
                            {relativeData.diseaseList.map((i, idx) =>
                              i.map(
                                (n) =>
                                  `${n.name}${
                                    relativeData.diseaseList.length !=
                                      idx + 1 || relativeData?.otherDisease
                                      ? ", "
                                      : ""
                                  }`
                              )
                            )}
                            {relativeData?.otherDisease
                              ? relativeData?.otherDisease
                              : ""}
                          </>
                        ) : (
                          "NA"
                        )}
                      </p>
                    </Col>
                    <Col lg={24} xl={24}>
                      <p className="label">
                        Does the care recipient have a preference regarding the
                        caregiver's sex?
                      </p>
                      <p className="values">
                        {relativeData?.gender == 1
                          ? "Male"
                          : relativeData?.gender == 2
                          ? "Female"
                          : "No Preference"}
                      </p>
                    </Col>
                    <Col lg={24} xl={24}>
                      <p className="label">
                        Does the care recipient have any pets?
                      </p>
                      <p className="values">
                        {relativeData?.pets?.length > 0
                          ? relativeData?.pets.map((pet) => pet)
                          : "NA"}
                      </p>
                    </Col>
                    <Col lg={24} xl={24}>
                      <p className="label">
                        Any additional information or instructions not covered
                        yet and deemed important for the caregivers to be aware
                        of before the appointment?
                      </p>
                      <p className="values">
                        {relativeData?.additionalInfo ?? "N/A"}
                      </p>
                    </Col>
                  </div>
                </Row>
                <Row className="view-relative-details-bottom-row">
                  <Col lg={24} xl={24} xs={24}>
                    <div
                      className="d-flex view-relative-details-inner text-right px-3"
                      style={{ justifyContent: "right"}}
                    >
                      <Button
                        onClick={() => setDeleteConfirmation(true)}
                        className="btn-back auth-action-btn mt-3 mx-3"
                        shape="round"
                        size="large"
                      >
                        Delete
                      </Button>
                      <Button
                        htmlType="submit"
                        // onClick={() => navigate("/client/appointment")}
                        onClick={() =>
                          navigate(`/client/relatives/${relativeData._id}`, {
                            state: { relativeData: relativeData },
                          })
                        }
                        className="btn-black auth-action-btn mt-3"
                        shape="round"
                        type="primary"
                        size="large"
                      >
                        Edit
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <DeleteConfirmationModal
          deleteConfirmationVisible={deleteConfirmationVisible}
          handleDeleteModalClose={() => setDeleteConfirmation(false)}
          selectedRelative={relativeData}
        />
      </div>
      {/*}*/}
    </>
  );
}

import { Button } from 'antd';
import React from 'react'
import Sidebar from '../sidebar';
import MainPage from '../../pages/dashboard'
import Navbar from '../navbar';

function Dashboard() {
    const Logout = () => {
        localStorage.clear();
        window.location.replace('/');
      }
    return (
      <div style={{ background: "#F7F9FA", minHeight: "100vH" }}>
        <div className="container-fluid">
          <Navbar />
          <div className="d-flex">
            <Sidebar />
            <MainPage />
          </div>
        </div>
      </div>
    );
}

export default Dashboard
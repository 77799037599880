import React from "react";
import { Checkbox, Button } from "antd";

function CheckBoxComponent({ values, items, onChange, isRelativeModule }) {
  // const optionsAvailable = items?.map(i => {
  //   return i._id
  // })
  console.log(isRelativeModule, "optionsAvailable", items, values);
  return (
    <>
      <Checkbox.Group
        value={values}
        style={{ width: "100%" }}
        onChange={onChange}
      >
          <ul className="list-inline mb-0">
        {items?.map((op, index) => {
          if (!isRelativeModule) {
            return (
                <li className="list-inline-item">
                    <Checkbox
                      className="checkbox-custom-additional"
                      value={op?._id}
                      checked={values?.includes(op?._id)}
                    >
                       <div
                            className={`text-body custom-checkbox-button ${
                              values?.includes(op?._id) ? "checked" : ""
                            }`}
                            key={index}
                          >
                            {op?.icon} {op?.name}
                       </div>
                    </Checkbox>
                </li>
            );
          } else {
            return (
                <li className="list-inline-item">
              <Checkbox
                className="d-flex checkbox-custom-additional"
                value={op?._id}
                style={{ flexDirection: "row-reverse" }}
                checked={values?.includes(op?._id)}
              >
                <div
                  className={`text-body pets-section-button ${
                    values?.includes(op?._id) ? "checked" : ""
                  }`}
                  key={index}
                >
                  {op?.icon} {op?.name}
                </div>
              </Checkbox>
                </li>
            );
          }
        })}
              </ul>
      </Checkbox.Group>
    </>
  );
}

export default CheckBoxComponent;

import { SOCKET_CONNECTION, TWILIO_CONNECTION } from "./socket.types";

export const handleSocketConnection = (payload) => {
  console.log(payload, "payload");
  return {
    type: SOCKET_CONNECTION,
    payload,
  };
};

export const handleTwilioConnection = (payload) => {
  console.log(payload, "chkhfdkspayload");
  return {
    type: TWILIO_CONNECTION,
    payload,
  };
};

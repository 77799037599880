import Card from '../../../components/settings/card';
import React, {useState} from 'react';
import {Button} from 'antd';
import Responsiveheader from '../../../components/Responsiveheader';

const CardDetails = ({cardDetails, setShowCards, deleteSingleCardDetails, fetchCardDetails, setCardDetails}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const handleClick = () => {
        setShowCards(false);
    }

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])
    return (
        <>
            <div className="container settings-client-editprofile clientprofile">
                {/*{isMobile ? <div><Responsiveheader title={"Banking Details"}/>*/}
                {/*        <div className="carddetailsinfo mx-2">*/}
                {/*            <div className="cards">*/}
                {/*                {*/}

                {/*                    cardDetails.map((item) => {*/}
                {/*                        return (*/}
                {/*                            <div className="card" key={item.id}>*/}
                {/*                                <Card item={item} key={item.id}*/}
                {/*                                      deleteSingleCardDetails={deleteSingleCardDetails}*/}
                {/*                                      fetchCardDetails={fetchCardDetails} setCardDetails={setCardDetails}/>*/}
                {/*                            </div>*/}
                {/*                        )*/}
                {/*                    })*/}
                {/*                }*/}

                {/*            </div>*/}
                {/*            <div className="cardbutton mx-2" style={{marginTop: "0px"}}>*/}
                {/*                <Button className="btn-black  auth-action-btn mt-5   "*/}
                {/*                    // type="primary" shape="round" size="small"*/}
                {/*                        style={{*/}
                {/*                            //    bottom:"6.5rem",*/}
                {/*                            //    position:"fixed",*/}
                {/*                            width: "100%",*/}
                {/*                        }}*/}
                {/*                        onClick={handleClick}>Add New Card</Button>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    :*/}
                    <div className="carddetailsinfo">
                        <div className="cards">
                            {

                                cardDetails.map((item) => {
                                    return (
                                        <div className="card" key={item.id}>
                                            <Card item={item} key={item.id}
                                                  deleteSingleCardDetails={deleteSingleCardDetails}
                                                  fetchCardDetails={fetchCardDetails} setCardDetails={setCardDetails}/>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="cardbutton mt-0 client-bottom-btn client-bottom-btn-update add-new-card-btn addCard">
                            <Button className="btn-black auth-action-btn float-end" onClick={handleClick}>Add New Card</Button>
                        </div>
                    </div>
                {/*}*/}
            </div>
        </>
    )

}
export default CardDetails;

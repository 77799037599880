import {
  TOGGLE_CHHOSE_RELATIVE_MODAL,
  TOGGLE_CONFIRM_RELATIVE_MODAL,
  TOGGLE_REDIRECT_APP_MODAL,
} from "./toggleModal.types";

export const toggleChooseRelativeModal = (payload) => {
  return {
    type: TOGGLE_CHHOSE_RELATIVE_MODAL,
    payload,
  };
};

export const toggleRedirectAppModal = (payload) => {
  return {
    type: TOGGLE_REDIRECT_APP_MODAL,
    payload,
  };
};

export const toggleConfirmRelativeModal = (payload) => {
  return {
    type: TOGGLE_CONFIRM_RELATIVE_MODAL,
    payload,
  };
};
export const sideBaropen = (payload) => {
  return {
    type: "OPEN",
    payload,
  };
};
export const sideBarClose = (payload) => {
  return {
    type: "CLOSE",
    payload,
  };
};

export const sideBarActive = (payload) => {
  return {
    type: "SIDEBAR",
    payload,
  };
};

export const handleRouting = (user, navigate) => {
  if (user?.userType === 2) {
    if (user?.accessToken !== "" && user?.screen === 1) {
      navigate("/client/verify");
    } else if (user?.accessToken !== "" && user?.screen === 2) {
      navigate("/client/profile");
    } else if (user?.accessToken !== "" && user?.screen === 3) {
      navigate("/client/appointment");
    }
  } else if (
    user?.userType === 0 ||
    user?.userType === 3 ||
    user?.userType === 4
  ) {
    if (
      user?.accessToken !== "" &&
      user?.isVerified === false &&
      user?.isProfileUpdated === false
    ) {
      navigate("/caregiver/details");
    } else if (
      user?.accessToken !== "" &&
      user?.isVerified === true &&
      user?.isProfileUpdated === false
    ) {
      navigate("/caregiver/details");
    } else if (
      user?.accessToken !== "" &&
      user?.isVerified === true &&
      user?.isProfileUpdated === true
    ) {
      navigate("/caregiver/dashboard");
    }
  }
};

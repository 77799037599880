import {
    Elements
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useState} from 'react';
import StripeCardForm from './StripeCardForm';
import Responsiveheader from '../../../components/Responsiveheader';

const AddCardDetails = ({
                            addSingleCard,
                            setShowCards,
                            fetchCardDetails,
                            setCardDetails,
                            isPaymentScreenOpen,
                            tabValue,
                            setAddCardPopUp,
                            removeHeader = true,
                            onCancel
                        }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);
    return (
        <>
            <div className="container clientprofile" style={{padding: "20px"}}>
                {/*{isMobile&&removeHeader ?*/}
                {/*<>*/}
                {/*<Responsiveheader title={"Banking Details"}/>*/}

                {/*</> */}

                {/*:""}*/}
                <div className="cardform">
                    <Elements stripe={stripePromise}>
                        <StripeCardForm addSingleCard={addSingleCard} setShowCards={setShowCards}
                                        setCardDetails={setCardDetails} fetchCardDetails={fetchCardDetails}
                                        isPaymentScreenOpen={isPaymentScreenOpen} tabValue={tabValue}
                                        setAddCardPopUp={setAddCardPopUp} isMobile={isMobile}
                                        onCancel={onCancel}
                        />
                    </Elements>
                </div>
            </div>
        </>

    )
}
export default AddCardDetails;

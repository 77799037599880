import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import { SendOutlined } from "@ant-design/icons";
import * as io from "socket.io-client";
import { useSelector } from "react-redux";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import "./messages.less";
import moment from "moment";
import { useRef } from "react";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import { Client } from "@twilio/conversations";
import axios from "axios";
import commonApi from "../../../http/commonApi";
// import { socket } from "../../../redux/socket/socket.reducer";

export default function ClientMessage() {
  const userData = useSelector((state) => state.loginData?.user);
  const caregiverData = useSelector(
    (state) => state.appointment?.paymentDetails
  );
  const date = new Date();
  const showTime = date.getHours() + ":" + date.getMinutes();
  const { firstName, userId } = userData;
  const [filteredList, setFilteredList] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  // const [socketConnection, setSocketConnection] = useState(false);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [messagesLoader, setMessageLoader] = useState(false);
  const [redDotVisible, setRedDotVisible] = useState(false);
  const [bottomVisible, setBottomVisible] = useState(false);
  const accessToken = localStorage.getItem("token");
  const messageEl = useRef();
  // const socket = io(
  //   `https://mf-chat.approd.ca/?accessToken=${accessToken}`
  // );
  const isSocketConnected = useSelector(
    (state) => state.socketData?.socketConnection
  );
  const socket = useSelector((state) => state.socketData?.socket);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const clientData = useSelector((state) => state.socketData);

  const clientConnection = useSelector((state) => state.twillioConnection);
  const twilioData = useSelector((state) => state.twillioClient);
  const [currentConversation, setCurrentConversation] = useState(null);
  const appointmentData = useSelector((state) => state.appointment);
  const clientDataTwilio = useSelector(
    (state) => state?.twillioData?.twillioClient
  );

  const [twillioMsgList, setTwillioMessageList] = useState([]);
  const [isNewMessageAdded, setIsNewMessageAdded] = useState(false);
  console.log(userData, "appointmentData");
  const userMode = useSelector((state) => state.loginData?.userMode);
  console.log(currentConversation, "currentConversation");
  const filterBySearch = (event) => {
    // const query = event.target.value;
    // var updatedList = [...filteredList];
    // updatedList = updatedList.filter((item) => {
    //   return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    // });
    // setFilteredList(updatedList);
  };

  //Send Message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    // console.log({
    //   message: typedMessage,
    //   senderId: userId,
    //   receiverId: activeUser?.receiverData?._id,
    // });
    currentConversation?.sendMessage(typedMessage);
    setTypedMessage("");
    const payload = {
      conversationId: currentConversation.sid,
      receiverId: caregiverData?.caregiverId,
    };
    const pushNotificationResp = await commonApi._sendMessageNotification(
      payload
    );
    console.log(pushNotificationResp, "pushNotificationResp");
    // if (typedMessage) {
    //   socket.emit("sendMessage", {
    //     message: typedMessage,
    //     senderId: userId,
    //     receiverId: caregiverData?.caregiverId,
    //   });
    //   socket.on("messageFromServer", (messageFromServer) => {
    //     console.log([messageFromServer], "messageFromServer");
    //   });
    //   // setMessages(prev => [...prev, {message: typedMessage, senderId: userId,
    //   //   receiverId: caregiverData?.caregiverId}])
    //   setTypedMessage("");
    // }
  };

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      caregiverData?.caregiverId
    ) {
      let sid;
      const url = `${process.env.REACT_APP_BASE_URL}/user/get-twilio-conversation`;
      axios
        .post(
          url,
          {
            receiverId: caregiverData?.caregiverId,
          },
          {
            headers: {
              accessToken: accessToken,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            console.log(response.data, "status");
            sid = response.data.output.conversationId;
            if (sid) {
              console.log(sid, "sdfdsfsdf");
              clientDataTwilio
                ?.getConversationBySid(`${sid}`)
                .then((conversations) => {
                  setCurrentConversation(conversations);
                  conversations
                    .getMessages(1000)
                    .then(async (messagesList) => {
                      setTwillioMessageList(messagesList?.items);
                      // await currentConversation.setAllMessagesRead();
                      console.log(messagesList.items, "messagesListnew");
                    })
                    .catch((Err) => {
                      console.log(Err);
                    });
                });
              // conversations = resp;
            }
          }
        });

      // conversations
      //   .then((Resp) => {
      //     sid = Resp.sid;
      //     console.log(Resp, "responsedata");
      //     setCurrentConversation(Resp);
      //     Resp.getMessages(100)
      //       .then(async (messagesList) => {
      //         setTwillioMessageList(messagesList?.items);
      //         if (messageEl) {
      //           messageEl.current?.addEventListener(
      //             "DOMNodeInserted",
      //             (event) => {
      //               const { currentTarget: target } = event;
      //               target.scroll({
      //                 top: target.scrollHeight,
      //                 behavior: "smooth",
      //               });
      //               setRedDotVisible(false);
      //             }
      //           );
      //         }
      //         await currentConversation.setAllMessagesRead();
      //         console.log(messagesList.items, "messagesListnew");
      //       })
      //       .catch((Err) => {
      //         console.log(Err);
      //       });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // if (!sid) {

      //   // clientDataTwilio
      //   //   ?.createConversation({
      //   //     uniqueName: `${userId} - ${caregiverData?.caregiverId}`,
      //   //     // `${Math.random()}`,
      //   //     // `${userId} - ${caregiverData?.caregiverId}4`,
      //   //   })
      //   //   .then((resp) => {
      //   //     resp.setAttributes({
      //   //       clientData: {
      //   //         name: `${userData?.firstName} ${userData?.lastName || ""}`,
      //   //         profilePic: "",
      //   //         userId: userData.userId,
      //   //       },
      //   //       caregiverData: {
      //   //         name: caregiverData?.caregiverName,
      //   //         profilePic: caregiverData?.caregiverImage,
      //   //         userId: caregiverData?.caregiverId,
      //   //       },
      //   //     });
      //   //     console.log("dsfdsf", resp);
      //       resp.join().then((respnew) => {
      //         console.log(respnew, "respnewnew");
      //         resp
      //           .add(userMode == "client" ? caregiverData?.caregiverId : userId)
      //           .then((participant) => {
      //             console.log("Participant SID:", participant.sid);
      //             console.log("Participant Identity:", participant.identity);
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //           });
      //         console.log(respnew, "respnew");
      //       });
      //       setCurrentConversation(resp);
      //     // });
      // }
      // else {

      // }
    }
  }, [
    clientDataTwilio,
    clientConnection,
    caregiverData?.caregiverId,
    messageEl,
  ]);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      currentConversation
    ) {
      currentConversation.on("messageAdded", (newMessage) => {
        // const messages = [...twillioMsgList];
        // messages.push(newMessage);
        // const foundMessage = twillioMsgList.find(
        //   (i) => i.sid == newMessage?.sid
        // );
        if (newMessage.author !== userId) {
          setIsNewMessageAdded(true);
        } else {
          setIsNewMessageAdded(false);
        }
        setTwillioMessageList((prev) => {
          const foundMessage = prev.find((i) => i.sid == newMessage?.sid);
          if (!foundMessage) {
            setRedDotVisible(true);
            return [...prev, newMessage];
          }
          return prev;
        });
        console.log(newMessage, "responsedatasdff");
      });
    }
  }, [clientDataTwilio, clientConnection, currentConversation]);

  useEffect(() => {
    if (twillioMsgList?.length > 0 && !isNewMessageAdded) {
      const lastMessageId = twillioMsgList[twillioMsgList.length - 1]?.sid;
      const lastMsgElem = document.getElementById(lastMessageId);
      if (lastMsgElem) {
        // setTimeout(() => {
        lastMsgElem?.scrollIntoView({
          top: lastMsgElem?.offsetTop,
          behavior: "smooth",
        });
        // }, 1000);
        setRedDotVisible(false);
      }
    }
  }, [twillioMsgList?.length]);
  //Read message
  // useEffect(() => {
  //   socket.emit("readMessage", {
  //     messageId: activeUser?.messageId,
  //   });
  // }, []);

  //Read chat-list
  //   useEffect(() => {
  //     if(!socketConnection) {
  //     socket.on("connect", () => {
  //        setSocketConnection(socket.connected);
  //       console.log("user connected")
  //     });
  //     }
  //     socket.on("chatListFromServer", (data) => {
  //       setUsers(data?.chatList);
  //       if(!activeUser?.senderData?._id) {
  //       setActiveUser(data?.chatList[0])
  //       }
  //     //   const firstNames = data?.chatList?.map((item) => item);
  //       setFilteredList(data?.chatList);
  //     });

  //     socket.emit("chatList", {
  //       senderId: userId,
  //       pageNo: 1,
  //       limit: 10,
  //     });
  //   }, [userId,messages?.length]);

  //Read Chat-history
  // useEffect(() => {
  //   // if(!socketConnection) {
  //   //     socket.on("connect", () => {
  //   //     setSocketConnection(socket.connected);
  //   //     console.log("user connected")
  //   // });
  //   // }
  //   // else {
  //   if (isSocketConnected && currentChatPage == 1) {
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: caregiverData?.caregiverId,
  //       pageNo: 1,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       // console.log(data, "datadata")
  //       const newArr = data?.chatHistory?.slice().reverse();
  //       setMessages(newArr);
  //       setTotalCount(data?.totalCount);
  //     });
  //   }
  //   if (messageEl) {
  //     messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // }, [socket?.connected]);

  // useEffect(() => {
  //   if (socket?.connected && currentChatPage != 1) {
  //     console.log(currentChatPage, "currentChatPage");
  //     setMessageLoader(true);
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: caregiverData?.caregiverId,
  //       pageNo: currentChatPage,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       // debugger
  //       console.log(data, "datadata");
  //       const newArr = data.chatHistory.slice().reverse();
  //       const updatedArr = [...newArr, ...messages];
  //       setMessages(updatedArr);
  //       setMessageLoader(false);
  //       // setTotalCount(data?.totalCount)
  //     });
  //   }
  // }, [currentChatPage]);

  const handleScroll = (e) => {
    const flag =
      e?.target.scrollHeight !=
      Math.round(e.target?.scrollTop + e.target?.clientHeight);
    setBottomVisible(flag);
    if (messageEl.current) {
      // const { scrollHeight } = messageEl.current;
      if (e.target.scrollTop == 0 && messages.length != totalCount) {
        setCurrentChatPage((prev) => prev + 1);
      }
    }
  };

  const handleScrollToBottom = (e) => {
    setCurrentChatPage(1);
    setRedDotVisible(false);
    const scroll =
      messageEl.current?.scrollHeight - messageEl.current?.clientHeight;
    messageEl.current?.scrollTo({ top: scroll, behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (socket?.connected) {
  //     socket.on("messageFromServer", (messageFromServer) => {
  //       const msgData = { ...messageFromServer?.getChatData };
  //       // debugger
  //       // console.log(msgData,messages, "msgData")
  //       // const newMsgData = {msgData};
  //       // if(msgData?.receiverId._id == userId) {
  //       //   // msgData['senderId'] = msgData?.receiverId;
  //       //   // msgData['receiverId'] = msgData?.senderId;
  //       //   msgData['senderData'] = msgData?.receiverId;
  //       //   msgData['receiverData'] = msgData?.senderId;
  //       //   msgData['receiverId'] = msgData?.senderId?._id
  //       //   msgData['senderId'] = msgData?.receiverId?._id
  //       // }
  //       // else {
  //       msgData["senderData"] = msgData?.senderId;
  //       msgData["receiverData"] = msgData?.receiverId;
  //       msgData["receiverId"] = msgData?.receiverId?._id;
  //       msgData["senderId"] = msgData?.senderId?._id;
  //       // }
  //       const msgList = [...messages];
  //       msgList.push(msgData);
  //       // console.log(messages, "sdsd")
  //       // console.log(messages[messages.length - 1]?._id, msgData?._id, "msgDatadata")
  //       // if(messages[messages.length - 1]?._id != undefined && messages[messages.length - 1]?._id != msgData?._id) {
  //       setMessages(msgList);
  //       if (msgData?.senderId != userId) {
  //         setRedDotVisible(true);
  //       }
  //       // }
  //       // console.log(messageFromServer, "messageFromServer");
  //     });
  //   }
  // }, [socket?.connected, messages?.length]);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log(twillioMsgList, "twillioMsgList");

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      currentConversation &&
      twillioMsgList?.length > 0
    ) {
      currentConversation
        .setAllMessagesRead(twillioMsgList?.length - 1)
        .then((resp) => console.log("successss", resp));
    }
  }, [twillioMsgList, currentConversation, clientDataTwilio]);
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      {/*{ isMobile ? (*/}
      {/*  <>*/}
      {/*  <div className="p-3 pt-4 d-flex w-100 message-container" >*/}
      {/*    <Responsiveheader   title="Messages" />*/}
      {/*  </div>*/}

      {/*  <div className='container-fluid appointment-dashboard message-container'>*/}

      {/*  <div className='border-less rounded' style={{ background: "#fff", padding: '35px' }}>*/}
      {/*            <Row className="message-wrapper" >*/}
      {/*              <Col className="right-client-child" md={24}>*/}
      {/*                    <>*/}
      {/*                      <div*/}
      {/*                        className=""*/}
      {/*                        style={{*/}
      {/*                          padding:"20px 10px 0 10px",*/}
      {/*                        }}*/}
      {/*                      >*/}
      {/*                        <div className="profile-details d-flex align-items-center">*/}
      {/*                        {caregiverData?.caregiverImage ?*/}
      {/*                        <img*/}
      {/*                          src={!caregiverData?.caregiverImage?"/images/icons/Profile.png":caregiverData?.caregiverImage}*/}
      {/*                          width={50}*/}
      {/*                          height={50}*/}
      {/*                          style={{ borderRadius: "50%" }}*/}
      {/*                          className="br-25 caregiver-img ms-2"*/}
      {/*                        />*/}
      {/*                        :*/}
      {/*                        <img src="/images/icons/Profile.png" className="caregiver-img" width={50} height={50} style={{border: '1px solid #c4c2c2', padding: '3px', borderRadius: '25px', marginLeft: '10px'}}/>*/}
      {/*                        }*/}
      {/*                        <p className="active-chat">*/}
      {/*                          {caregiverData?.caregiverName} */}
      {/*                        </p>*/}
      {/*                        </div>*/}
      {/*                        <Divider />*/}
      {/*                        <Spin spinning={messagesLoader}>*/}
      {/*                         <div className="chat-box-wrapper">*/}
      {/*                      <Row className="chat__container2" ref={messageEl} onScroll={handleScroll}>*/}
      {/*                      {messages.length == 0 ?*/}
      {/*                          <Col md={24} className="d-flex justify-content-center align-items-center">*/}
      {/*                          <div className="no-msg-wrapper">*/}
      {/*                            <img src="/images/icons/no-messages.svg" alt="no messages" width={120}/>*/}
      {/*                            <p className="no-msg-txt">No Messages</p>*/}
      {/*                          </div>*/}
      {/*                        </Col>*/}
      {/*                        :*/}
      {/*                        messages?.map((message, index) => {*/}
      {/*                             const currentDate = moment().format('DD-MM-YYYY');*/}
      {/*                             const isNotSameDate = moment(message?.updatedAt).format('DD-MM-YYYY') != moment(messages[index - 1]?.updatedAt).format("DD-MM-YYYY");*/}
      {/*                             const isSameAsToday = moment(message?.updatedAt).format('DD-MM-YYYY') == currentDate;*/}
      {/*                             const updatedTime = moment(message?.updatedAt).format("hh:mm a");*/}
      {/*                             const isNotSameTime = updatedTime != moment(messages[index - 1]?.updatedAt).format("hh:mm a");*/}
      {/*                            return (*/}
      {/*                              <>*/}
      {/*                              {isNotSameDate &&*/}
      {/*                            <Col span={24}>*/}
      {/*                            <p style={{color: "black", textAlign: "center"}}>{isSameAsToday ? "Today" : moment(message?.updatedAt).format('DD-MM-YYYY')}</p>*/}
      {/*                            </Col>*/}
      {/*                            }*/}
      {/*                              {isNotSameTime &&*/}
      {/*                            <span className={`timestamp ${*/}
      {/*                                userId != message.receiverId*/}
      {/*                                  ? "right"*/}
      {/*                                  : "left"*/}
      {/*                              }`}>{updatedTime}</span>*/}
      {/*                            }*/}
      {/*                              <Col span={24}*/}
      {/*                                className={`message__chats ${userId != message.receiverId ? 'right' : 'left'}`}*/}
      {/*                                key={message.id}*/}
      {/*                              >*/}
      {/*                                /!* <span className="sender-time">*/}
      {/*                                  <span*/}
      {/*                                    style={{*/}
      {/*                                      opacity: "70%",*/}
      {/*                                      paddingRight: "3px",*/}
      {/*                                    }}*/}
      {/*                                  >*/}
      {/*                                    {showTime}*/}
      {/*                                  </span>*/}
      {/*                                  <b style={{ marginRight: "5px" }}>.You</b>{" "}*/}
      {/*                                </span> *!/*/}
      {/*                                  <p>{message?.message}</p>*/}
      {/*                              </Col>*/}
      {/*                              </>*/}
      {/*                            )})}*/}
      {/*                      </Row>*/}
      {/*                      {bottomVisible &&*/}
      {/*                          <div className="bottom-scroll" style={{float: 'right'}}>*/}
      {/*                           <Button onClick={handleScrollToBottom} style={{height: '50px'}}>*/}
      {/*                            {redDotVisible ?*/}
      {/*                            <img src="/images/icons/message-down-red.svg" width="30" />*/}
      {/*                            :*/}
      {/*                             <img src="/images/icons/message-down.svg" width="30" />*/}
      {/*                            }*/}
      {/*                           </Button>*/}
      {/*                          </div>*/}
      {/*                          }*/}
      {/*                      /!* {activeUser && ( *!/*/}
      {/*                        <div className="chat-input-message">*/}
      {/*                          <Input*/}
      {/*                            className="inputMessage"*/}
      {/*                            placeholder="Type Message..."*/}
      {/*                            value={typedMessage}*/}
      {/*                            onChange={(e) =>*/}
      {/*                              setTypedMessage(e.target.value)*/}
      {/*                            }*/}
      {/*                            onKeyDown={(e) => {*/}
      {/*                              console.log("called")*/}
      {/*                              // e.preventDefault();*/}
      {/*                              if (e.key === "Enter" && typedMessage) {*/}
      {/*                                  handleSendMessage(e)*/}
      {/*                              }*/}
      {/*                            }}*/}
      {/*                            suffix={*/}
      {/*                                <img src="/images/icons/send-message.svg" width={40} height={40} onClick={handleSendMessage}/> */}
      {/*                            }*/}
      {/*                          />*/}
      {/*                        </div>*/}
      {/*                      /!* )} *!/*/}
      {/*                    </div>*/}
      {/*                    </Spin>*/}
      {/*                      </div>*/}
      {/*                    </>*/}
      {/*                </Col>*/}
      {/*              </Row>*/}
      {/*      </div>*/}
      {/*      </div>*/}
      {/*  </>):*/}
      <div className="container-fluid appointment-dashboard message-container">
        <div className="pt-5 d-flex profile-details-wrapper">
          <Sidebar />
          <div className="mx-4 w-100 message-inner-row">
            <AppointmentNavBar />
            <div
              className="border-less rounded card-div message-card-div"
              style={{ background: "#fff", padding: "35px" }}
            >
              <Row className="message-wrapper">
                <Col className="right-client-child" md={24} xs={24}>
                  <>
                    <div
                      className=""
                      style={{
                        padding: "20px 10px 0 10px",
                      }}
                    >
                      <div className="profile-details d-flex align-items-center">
                        {caregiverData?.caregiverImage ? (
                          <img
                            alt=""
                            src={
                              !caregiverData?.caregiverImage
                                ? "/images/icons/Profile.png"
                                : caregiverData?.caregiverImage
                            }
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%" }}
                            className="br-25 caregiver-img ms-2"
                          />
                        ) : (
                          <img
                            alt=""
                            src="/images/icons/Profile.png"
                            className="caregiver-img"
                            width={50}
                            height={50}
                            style={{
                              border: "1px solid #c4c2c2",
                              padding: "3px",
                              borderRadius: "25px",
                              marginLeft: "10px",
                            }}
                          />
                        )}
                        <p className="active-chat">
                          {caregiverData?.caregiverName}
                        </p>
                      </div>
                      <Divider />
                      <Spin spinning={messagesLoader}>
                        <div className="chat-box-wrapper">
                          <Row
                            className="chat__container2"
                            ref={messageEl}
                            onScroll={handleScroll}
                          >
                            {twillioMsgList.length == 0 ? (
                              <Col
                                md={24}
                                xs={24}
                                className="d-flex no-message-center justify-content-center align-items-center"
                              >
                                <div className="no-msg-wrapper">
                                  <img
                                    src="/images/icons/no-messages.svg"
                                    alt="no messages"
                                    width={120}
                                  />
                                  <p className="no-msg-txt">No Messages</p>
                                </div>
                              </Col>
                            ) : (
                              twillioMsgList?.map((message, index) => {
                                const currentDate =
                                  moment().format("DD-MM-YYYY");
                                const isNotSameDate =
                                  moment(message?.dateCreated).format(
                                    "DD-MM-YYYY"
                                  ) !=
                                  moment(
                                    twillioMsgList[index - 1]?.dateCreated
                                  ).format("DD-MM-YYYY");
                                const isSameAsToday =
                                  moment(message?.dateCreated).format(
                                    "DD-MM-YYYY"
                                  ) == currentDate;
                                const updatedTime = moment(message?.dateCreated)
                                  // .utc()
                                  .format("hh:mm a");
                                const isNotSameTime =
                                  updatedTime !=
                                  moment(twillioMsgList[index - 1]?.dateCreated)
                                    // .utc()
                                    .format("hh:mm a");
                                console.log(
                                  message?.dateCreated,
                                  "datecreatednew"
                                );
                                return (
                                  <>
                                    {isNotSameDate && (
                                      <Col md={24} xs={24}>
                                        <p
                                          style={{
                                            color: "black",
                                            textAlign: "center",
                                          }}
                                        >
                                          {isSameAsToday
                                            ? "Today"
                                            : moment(
                                                message?.dateCreated
                                              ).format("DD-MM-YYYY")}
                                        </p>
                                      </Col>
                                    )}
                                    <Col
                                      md={24}
                                      xs={24}
                                      className={`message__chats ${
                                        userId == message.author
                                          ? "right"
                                          : "left"
                                      }`}
                                      key={message.sid}
                                      id={message.sid}
                                    >
                                      {/* <span className="sender-time">
                                      <span
                                        style={{
                                          opacity: "70%",
                                          paddingRight: "3px",
                                        }}
                                      >
                                        {showTime}
                                      </span>
                                      <b style={{ marginRight: "5px" }}>.You</b>{" "}
                                    </span> */}
                                      <p>{message?.body}</p>
                                    </Col>
                                    {isNotSameTime && (
                                      <span
                                        className={`timestamp ${
                                          userId == message.author
                                            ? "right"
                                            : "left"
                                        }`}
                                      >
                                        {updatedTime}
                                      </span>
                                    )}
                                  </>
                                );
                              })
                            )}
                          </Row>
                          {bottomVisible && (
                            <div
                              className="bottom-scroll"
                              style={{ float: "right" }}
                            >
                              <Button
                                onClick={handleScrollToBottom}
                                style={{ height: "50px" }}
                              >
                                {redDotVisible ? (
                                  <img
                                    alt={""}
                                    src="/images/icons/message-down-red.svg"
                                    width="30"
                                  />
                                ) : (
                                  <img
                                    alt={""}
                                    src="/images/icons/message-down.svg"
                                    width="30"
                                  />
                                )}
                              </Button>
                            </div>
                          )}
                          {/* {activeUser && ( */}
                          <div className="chat-input-message">
                            <Input
                              className="inputMessage"
                              placeholder="Type Message..."
                              value={typedMessage}
                              onChange={(e) => setTypedMessage(e.target.value)}
                              onKeyDown={(e) => {
                                console.log("called");
                                // e.preventDefault();
                                if (e.key === "Enter" && typedMessage) {
                                  handleSendMessage(e);
                                }
                              }}
                              suffix={
                                <img
                                  alt={""}
                                  src="/images/icons/send-message.svg"
                                  width={40}
                                  height={40}
                                  onClick={handleSendMessage}
                                  style={
                                    typedMessage?.length == 0
                                      ? { opacity: 0.6 }
                                      : {}
                                  }
                                />
                              }
                            />
                          </div>
                          {/* )} */}
                        </div>
                      </Spin>
                    </div>
                  </>
                </Col>
              </Row>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {/*}*/}
    </div>
  );
}

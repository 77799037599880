import { useSelector } from "react-redux";
import ClientTnC from "../common/clientTnC";
import CaregiverTnC from "../common/caregiverTnC";
import FooterClientTnC from "../common/footerClientTnC";
import FooterCaregiverTnC from "../common/footerCaregiverTnC";

const TermsAndConditions = (props) => {
  const userMode = useSelector((state) => state.loginData?.userMode);
  return !props.termView ? (
    <div className="common-condition-details">
      {userMode == "client" ? ClientTnC() : CaregiverTnC()}
    </div>
  ) : (
    <div className="common-condition-details">
      {userMode == "client" ? FooterClientTnC() : FooterCaregiverTnC()}
    </div>
  );
};
export default TermsAndConditions;

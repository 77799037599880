import React, { useState } from "react";
import { Button, Input, Form, Row, Col, List } from "antd";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { connect, useDispatch, useSelector } from "react-redux";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../common/helper";
import { useNavigate } from "react-router-dom";
import { Logout, setUser } from "../../../redux/auth/auth.action";

function ProfileDetails({ user, next }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.loginData.user);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      componentRestrictions: { country: "CA" },
    },
  });
  const [value, setValue] = useState("");
  const [showList, SetShowList] = useState(false);

  const getDetails = (place_id) => {
    SetShowList(false);
    placesService?.getDetails(
      {
        placeId: place_id,
      },
      (placeDetails) => {
        console.log(
          "placeDetails",
          placeDetails,
          placeDetails.geometry.location.lat(),
          placeDetails.geometry.location.lng()
        );
        const address = getAddressObject(placeDetails?.address_components);
        // setValue(placeDetails?.name);
        setValue(address?.home + " " + address?.street);
        form.setFieldValue("address", address?.home + address?.street);
        form.setFieldValue("city", address?.city);
        form.setFieldValue("province", address?.region);
        form.setFieldValue("postalCode", address?.postal_code);
        handleFormChange();
        form.validateFields(["postalCode"]);
      }
    );
  };

  const [disabledSave, setDisabledSave] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Success:", values);
    updateProfile(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormChange = (changedFields) => {
    console.log(changedFields, "changedFields");
    if (
      changedFields?.length > 0 &&
      changedFields[0]?.name[0]?.includes("postalCode")
    ) {
      let val = changedFields[0]?.value;
      val = val.replace(/[^\w\s]/g, "");
      if (val[3] && val[3] != " ") {
        val = val?.replace(/(.{3})/, "$1 ");
      }
      form.setFieldValue("postalCode", val?.toUpperCase());
    }
    if (
      changedFields?.length > 0 &&
      (changedFields[0]?.name[0]?.includes("firstName") ||
        changedFields[0]?.name[0]?.includes("lastName"))
    ) {
      const name = changedFields[0]?.name[0];
      let val = changedFields[0]?.value;
      val = val.charAt(0).toUpperCase() + val.slice(1);
      form.setFieldValue(name, val);
    }
    const valuesObj = form.getFieldsValue();
    delete valuesObj?.apartmentNumber;
    const areTruthy = Object.values(valuesObj).every((value) => value);
    console.log(form.getFieldsValue(), "values");
    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
    console.log("hasErrors", form.getFieldsError());
  };

  const updateProfile = async (values) => {
    if (user?.accessToken !== "") {
      setDisabledSave(true);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/user/update-profile`, values, {
          headers: {
            accessToken: user?.accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            setDisabledSave(false);
            dispatch(
              setUser({
                ...userDetails,
                firstName: values.firstName,
                lastName: values.lastName,
              })
            );
            next();
            // navigate('/client/card-details')
          } else if (response.data.status >= 400) {
            setDisabledSave(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setDisabledSave(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (err?.response.data.status >= 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(Logout());
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  return (
    <div className="px-3 px-md-0 profile-details">
      <h1 className="welcome-header fw-bolder mt-4" id="name">
        Account Details
      </h1>
      <p className="welcome-subheader mb-5">
        Enter your details as the account holder. You will be able to add
        profiles for family members receiving care in the next step
      </p>
      <div className="text-start form-row form-row-div">
        <Form
          name="signup-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          // validateTrigger='onSubmit'
          onFieldsChange={handleFormChange}
          form={form}
          requiredMark={false}
          className="mb-0"
        >
          <Row gutter={16}>
            <Col xs={24} lg={12}>
              <Form.Item
                name="firstName"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    First Name <span style={{ color: "red" }}>*</span>
                  </p>
                }
                pattern="[A-Za-z]"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="First Name"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="lastName"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z]+$/),
                    message:
                      "Field does not accept numbers or special characters.",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Last Name"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="address"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    Address <span style={{ color: "red" }}>*</span>
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter your address!",
                  },
                ]}
              >
                <Input
                  size="large"
                  style={{ color: "black", height: "60px" }}
                  value={value}
                  onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    SetShowList(true);
                    setValue(evt.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event?.keyCode === 8 || event?.keyCode === 46) {
                      form.setFieldValue("city", "");
                      form.setFieldValue("province", "");
                      form.setFieldValue("postalCode", "");
                      handleFormChange();
                    }
                  }}
                  loading={isPlacePredictionsLoading}
                  placeholder="Address"
                />
                {!isPlacePredictionsLoading && value !== "" && showList && (
                  <div className="google-autosuggestion-list">
                    <>
                      <List
                        dataSource={placePredictions}
                        renderItem={(item) => {
                          return (
                            <List.Item
                              onClick={(e) => {
                                getDetails(item?.place_id);
                              }}
                            >
                              <List.Item.Meta title={item.description} />
                            </List.Item>
                          );
                        }}
                      />
                    </>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name={"apartmentNumber"}
                label={
                  <p className="color-secondary mb-0">Unit/Apartment Number</p>
                }
              >
                <Input
                  placeholder="Unit/Apartment Number"
                  size="large"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="city"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    City <span style={{ color: "red" }}>*</span>
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter your city!",
                  },
                ]}
              >
                <Input
                  disabled
                  size="large"
                  placeholder="City"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="province"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    Province <span style={{ color: "red" }}>*</span>
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter your Province!",
                  },
                ]}
              >
                <Input
                  disabled
                  size="large"
                  placeholder="Province"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="postalCode"
                type="text"
                className="mb-4"
                label={
                  <p className="color-secondary mb-0">
                    Postal Code <span style={{ color: "red" }}>*</span>
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter your Postal Code!",
                  },
                  // {
                  //   pattern: new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i),
                  //   message: "Please enter valid postal code"
                  //  }
                  {
                    max: 7,
                    message: "Maximum 7 digits can be entered",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z0-9 ]*$/),
                    // message: "Please enter valid postal code",
                  },
                ]}
              >
                <Input
                  // disabled
                  size="large"
                  placeholder="Postal Code"
                  style={{ height: "60px" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item className="text-center mobile-btn-item mb-0">
            <Button
              className="btn-black auth-action-btn mt-3 mb-mob-0 mb-5 mx-auto"
              shape="round"
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabledSave}
            >
              Next{" "}
              <img
                className="ms-2"
                src="/images/icons/right.svg"
                alt="right icon"
              />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.loginData.user,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);

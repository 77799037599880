import { Breadcrumb } from "antd";
import BreadcrumbItem from "antd/lib/breadcrumb/BreadcrumbItem";
import { useDispatch } from "react-redux";
import "../../../common/styles/navbar.less";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { Typography } from "antd";
import Breadcrumbs from "../../../common/Breadcrumbs";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import { useEffect } from "react";

const { Title } = Typography;

const SettingsNavBar = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const handleChooseRelative = () => {
    dispatch(toggleChooseRelativeModal(true));
  };
  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.settings)
    );
  }, []);
  return (
    <>
  

      <div className="d-flex justify-content-between appointment-nav-container">
        <div>
          {/* <Breadcrumb>
            <BreadcrumbItem style={{ fontSize: "25px", color: "gray" }}>
              <Title level={1} style={{ fontWeight: "700", fontSize: "36px" }}>
                Settings
              </Title>
            </BreadcrumbItem>
          </Breadcrumb> */}
          <Breadcrumbs title={"Settings"} />
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "15%" }}
        >
          <img
            src="/images/icons/light-outline-plus.svg"
            onClick={handleChooseRelative}
          />
          <img src="/images/icons/notification.svg" />
          <div>
            <strong>John Doe</strong> <img src="/images/icons/down-arrow.svg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsNavBar;

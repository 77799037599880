import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleChooseRelativeModal,
  toggleRedirectAppModal,
} from "../../redux/modals/toggleModal.actions";
import "./RedirectModal.less";
import { Link, useNavigate } from "react-router-dom";
const { Text } = Typography;
export default function RedirectModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectAppModal = useSelector(
    (state) => state.toggleModals?.redirectAppModal
  );

  const handleCancel = () => {
    dispatch(toggleRedirectAppModal(false));
  };
  const handleOk = () => {
    dispatch(toggleRedirectAppModal(false));
    dispatch(toggleChooseRelativeModal(true));
  };
  return (
    <Modal
      className="redirect-modal"
      centered
      // style={{ maxWidth: "35vw", maxHeight: "70vh" }}
      header={null}
      footer={null}
      open={redirectAppModal}
      onOk={handleCancel}
      onCancel={handleCancel}
      bodyStyle={{ height: "70vh", overflow: "auto" }}
      closable
      closeIcon={<CloseCircleOutlined width={30} />}
    >
      <p className="redirect-modal__text title mb-3">
        Thank you for initiating your appointment!
      </p>
      <p className="redirect-modal__text">
        For a better and more secure user experience, we recommend downloading
        our mobile app for enhanced features such as Direct Messaging, Tracking
        and time sensitive notifications.
      </p>
      <div className="d-flex text-center mb-3">
        {/* <Button> */}
        <Link to="https://apps.apple.com/us/app/mf-homecare/id6443815250">
          <img
            src="/images/icons/download-ios.svg"
            alt="ios-btn"
            className="redirect-modal__img"
          />
          <img
            className="redirect-modal__img"
            src="/images/ios-qr.png"
            alt="QR-Code"
          />
        </Link>
        <Link to="https://play.google.com/store/apps/details?id=com.homecare.seasons&pli=1">
          <img
            className="redirect-modal__img"
            src="/images/icons/download-and.svg"
            alt="ios-btn"
          />
          <img
            src="/images/andr-qr.png"
            alt="QR-Code"
            className="redirect-modal__img"
          />
        </Link>
        {/* </Button> */}
      </div>
      <div className="text-center mt-3">
        <p
          className="proceed-btn"
          onClick={handleOk}
        >{`Proceed booking on the web >>> (not recommended)`}</p>
      </div>
    </Modal>
  );
}

import React from 'react'
import MySchedule from '../../../components/mySchedule'
import Responsiveheader from '../../../components/Responsiveheader'
import Schedule from './Schedule';

export default function MobileSchedule() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])
   console.log(isMobile, "isMobile")
  return (
    <>
    {isMobile?
    <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>
    <div className="p-3 pt-4 d-flex w-100">
      <Responsiveheader   title="My Schedule" />
    </div>
    <div >
    {/* <div className="right-panel"> */}
                      <MySchedule />
                    {/* </div> */}
    </div>
  </div>:<Schedule
  
   ipad={true}
  />}
    
    </>
  )
}

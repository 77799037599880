import React from "react";
import Navbar from "../../../components/navbar";
import Sidebar from "../../../components/sidebar";
import NoAppointment from "./no-appointment";
import AppointmentNavBar from "./appointmentsNavBar";
import Footer from "../../../components/Footer/Footer";

function Appointment() {
  return (
    <div
      className="message-main"
      style={{ background: "#F7F9FA", minHeight: "100vH" }}
    >
      <div className="container-fluid appointment-dashboard">
        <div className="pt-5 d-flex client_Expenses">
          <Sidebar />
          <div className="mx-4 message-inner-row w-100">
            {/* <Navbar /> */}
            <AppointmentNavBar />
            <div className="w-100 d-flex justify-content-center no-appointment-div align-items-center h-75">
              <NoAppointment />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appointment;

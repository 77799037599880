import logout from "../../Images/logout.svg";
import { Button, Modal } from "antd";
import { Logout } from "../../redux/auth/auth.action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({ handleLogoutModal, setHandleLogoutModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    setHandleLogoutModal(false);
    dispatch(Logout());
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const handleLogoutClose = () => {
    setHandleLogoutModal(false);
  };

  return (
    <>
      <Modal
        centered
        width={350}
        header={null}
        footer={null}
        open={handleLogoutModal}
        onCancel={handleLogoutClose}
        className="logoutmodal"
        closable={false}
      >
        <div className="crossImage">
          <img alt=""
            src={logout}
            className="text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          />
        </div>

        <h2
          style={{
            textAlign: "center",
            fontSize: "20px",
            color: "#f172a",
            fontWeight: 700,
            marginBottom: "20px",
          }}
        >
          Are you sure you want to logout?
        </h2>

        <div className="confirmButtons common-button-yes-no">
          <Button className="yes-button" onClick={handleLogout}>Yes</Button>
          <Button className="no-button" onClick={handleLogoutClose}>No</Button>
        </div>
      </Modal>
    </>
  );
};
export default LogoutModal;

import {
  BOOK_APPOINTMENT,
  DATE_TIME,
  MY_CAREGIVERS,
  PAYMENT,
  SELECTED_CC,
  SERVICES,
} from "./appointment.type";
export const services = (payload) => async (dispatch) => {
  dispatch({
    type: SERVICES,
    payload: payload,
  });
};

export const dateTime = (payload) => async (dispatch) => {
  dispatch({
    type: DATE_TIME,
    payload: payload,
  });
};

export const selectedCC = (payload) => async (dispatch) => {
  dispatch({
    type: SELECTED_CC,
    payload: payload,
  });
};

export const bookAppointment = (payload) => async (dispatch) => {
  dispatch({
    type: BOOK_APPOINTMENT,
    payload: payload,
  });
};

export const paymentDetails = (payload) => async (dispatch) => {
  dispatch({
    type: PAYMENT,
    payload: payload,
  });
};
export const UpdatepaymentDetails = (payload) => async (dispatch) => {
  console.log(payload, "paylod.......")
 dispatch({
   type: PAYMENT,
   payload: payload,
 });
};
export const caregiverAvailability = (payload) => async (dispatch) => {
  dispatch({
    type: MY_CAREGIVERS,
    payload: payload,
  });
};

import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BankingInformation from "./BankingInformation";
// import BankDetails from '../../../client/settings/bankDetails';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const BankDetailsUpdate = ({ stripe }) => {
  return (
    <div>
      <Elements stripe={stripePromise}>
        <BankingInformation />
      </Elements>
    </div>
  );
};

export default BankDetailsUpdate;

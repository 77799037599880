import {
  Menu,
  Popover,
  Row,
  Badge,
  Typography,
  Col,
  Dropdown,
  Spin,
  Button,
  Collapse,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OutsideAlerter from "../../../common/ClickOutside";
import "../../../common/styles/navbar.less";
import {
  paymentDetails,
  selectedCC,
} from "../../../redux/appointment/appointment.action";
import { CLEAR_DATA } from "../../../redux/auth/auth.type";
import { toggleChooseRelativeModal } from "../../../redux/modals/toggleModal.actions";
import { setAlert } from "../../../redux/alert/alert.action";
import axios from "axios";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { saveAllNotification } from "../../../redux/profileDetails/profile.action";
import DeleteAccount from "../../../components/navbar/deleteaccount";
import Breadcrumbs from "../../../common/Breadcrumbs";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import commonApi from "../../../http/commonApi";
import _ from "lodash";
import Confirmation from "../../../components/Modal/common/confirmation";
import { CLEAR_ALLDATA } from "../../../redux/auth/auth.type";
import LogoutModal from "../../../components/navbar/LogoutModal";

const { Panel } = Collapse;

const { Text } = Typography;
const AppointmentNavBar = (props) => {
  const caregiverProfileDetails = useSelector(
    (state) => state?.profileDetails?.getCaregiverDetails?.output
  );
  const userData = useSelector((state) => state.loginData?.user);
  console.log(userData, "userDatahk");
  const [popupVisible, setPopupVisible] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const appointmentData = useSelector((state) => state.appointment);
  const appointmentDetails = appointmentData.paymentDetails;
  const [handleDelete, setHandleDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const caregiverData = useSelector((state) => state.loginData);
  console.log(caregiverData, "caregiverData");
  const token = localStorage.getItem("token");
  const [notificationVisible, setnotificationVisible] = useState(false);
  const [isMobileNotificationVisible, setIsMobileNotificationVisible] =
    useState(false);
  const [openModal, setopenModal] = useState(false);
  const [notificationLoader, setnotificationLoader] = useState(false);
  const [handleLogoutModal, setHandleLogoutModal] = useState(false);
  const [navMenuVisible, setNavMenuVisible] = useState(false);
  const [notificationTotalCount, setNotificationTotalCount] = useState(0);
  const [unreadData, setUnreadData] = useState(null);
  const [averageRating, setAverageRating] = useState(0);
  const notificationRef = useRef(null);
  const [dynamicNavMenus, setDynamicMenus] = useState([]);
  const clientDataTwilio = useSelector(
    (state) => state?.twillioData?.twillioClient
  );
  const homeData = useSelector((state) => state.loginData?.homeData);
  // const isUnreadNotification = useMemo(() => {
  //   return notificationsList.find((notification) => !notification.isRead);
  // }, [notificationsList]);

  const handleChooseRelative = () => {
    // dispatch(toggleRedirectAppModal(true));
    dispatch(toggleChooseRelativeModal(true));
    dispatch(selectedCC({ ...appointmentDetails, scheduleAgain: false }));
  };

  const handlePopupVisible = () => {
    setPopupVisible(true);
  };
  const clientItems = [
    // {
    //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //   label: <Link to="/client/appointments-dashboard">Appointments</Link>,
    //   key: "0",
    // },
    // {
    //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //   label: <Link to="/client/messages">Messages</Link>,
    //   key: "1",
    // },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/client/relatives">My Care Recipients</Link>,
      key: "6",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/client/my-schedule">My Calendar</Link>,
      key: "3",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/client/expenses">Expenses</Link>,
      key: "2",
    },
    // {
    //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //   label: <Link to="/dashboard/my-caregivers">My Caregivers</Link>,
    //   key: "5",
    // },
    // {
    //   // eslint-disable-next-line jsx-a11y/anchor-is-valid
    //   label: (
    //     <Collapse className="custom-collapse-link">
    //       <Panel header="Settings" key="1">
    //         <ul className="list-inline mb-0">
    //           <li>
    //             <Link to={"/client/settings"}>Edit Profile</Link>
    //           </li>
    //           <li>
    //             <Link to={"/client/settings"}>Change Password</Link>
    //           </li>
    //           <li>
    //             <Link to={"/client/settings"}>Bank Information</Link>
    //           </li>
    //           <li>
    //             <Link onClick={() => setHandleDelete(true)}>
    //               Delete Account
    //             </Link>
    //           </li>
    //         </ul>
    //       </Panel>
    //     </Collapse>
    //   ),
    //   key: "4",
    // },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/client/view-tutorials">View Tutorials</Link>,
      key: "7",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/client/contactus">Contact Us</Link>,
      key: "8",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link onClick={() => setHandleLogoutModal(true)}>Logout</Link>,
      key: "9",
    },
  ];

  const caregiverItems = [
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/dashboard">Dashboard</Link>,
      key: "0",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/appointments-dashboard">Appointments</Link>,
      key: "1",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/messages">Messages</Link>,
      key: "2",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/expenses">Expenses</Link>,
      key: "3",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/earnings">Total Earnings</Link>,
      key: "4",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: (
        <Collapse className="custom-collapse-link">
          <Panel header="Settings" key="1">
            <ul className="list-inline mb-0">
              <li>
                <Link to={"/caregiver/settings/editprofile"}>Edit Profile</Link>
              </li>
              <li>
                <Link to={"/caregiver/settings/changepassword"}>
                  Change Password
                </Link>
              </li>
              <li>
                <Link to={"/caregiver/settings/bankinformation"}>
                  Bank Information
                </Link>
              </li>
              <li>
                <Link onClick={() => setHandleDelete(true)}>
                  Delete Account
                </Link>
              </li>
            </ul>
          </Panel>
        </Collapse>
      ),
      key: "5",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/edit-availability">Availability</Link>,
      key: "6",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/dashboard/my-reviews">Ratings & Reviews</Link>,
      key: "7",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/view-tutorials">View Tutorials</Link>,
      key: "8",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link to="/caregiver/contactus">Contact Us</Link>,
      key: "9",
    },
    {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      label: <Link onClick={() => setHandleLogoutModal(true)}>Logout</Link>,
      key: "10",
    },
  ];
  useEffect(() => {
    if (userMode == "client") {
      setDynamicMenus(clientItems);
    } else if (userMode == "caregiver") {
      setDynamicMenus(caregiverItems);
    }
  }, [userMode]);
  // const dynamicNavMenus = userMode == "client" ? clientItems : caregiverItems;

  const caregiverMenus = [
    {
      id: 1,
      route: "/dashboard/my-reviews",
      icon: "menu-star.svg",
      title: "Ratings & Review",
    },
    {
      id: 2,
      route: "/caregiver/edit-availability",
      icon: "clock.svg",
      title: "Availability",
    },
    // {
    //   id: 2,
    //   route: "/caregiver/my-schedule",
    //   icon: "calendar.svg",
    //   title: "My Schedule",
    // },
    { id: 3, route: "", icon: "delete-contact.svg", title: "Delete Account" },
  ];
  const clientMenus = [
    {
      id: 1,
      route: "/dashboard/my-caregivers",
      icon: "bookmark-icon.svg",
      title: "My Caregivers",
    },
    {
      id: 2,
      route: "/client/relatives",
      icon: "my-relatives.svg",
      title: "My Care Recipients",
    },
    {
      id: 3,
      route: "/client/my-schedule",
      icon: "calendar.svg",
      title: "My Calendar",
    },
    { id: 4, route: "", icon: "delete-contact.svg", title: "Delete Account" },
  ];
  useEffect(() => {
    Notifications();
  }, [page]);
  const Notifications = async () => {
    if (token !== "") {
      setnotificationLoader(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-notification`,
          {
            page: page,
            pageLimit: 10,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("Notificstions response", response.data.output.list);
          if (response.data.status === 200) {
            if (page == 1) {
              setNotificationsList(response.data.output.list);
            } else {
              setNotificationsList((prev) => [
                ...prev,
                ...response.data.output.list,
              ]);
            }
            setNotificationTotalCount(response.data.output?.resultCount);
            dispatch(saveAllNotification(response.data.output.list));
            setnotificationLoader(false);
          } else if (response.data.status == 400) {
            setnotificationLoader(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
          // else {
          //   setLoading(false);
          //   dispatch(
          //     setAlert({
          //       alertType: "error",
          //       alertMsg: "somthing went wrong! please try again.",
          //       alertOpen: true,
          //     })
          //   );
          // }
        })
        .catch((err) => {
          console.log(err, "errorObj");
          // if(err.error)
          setnotificationLoader(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
          if (
            err?.response?.data?.status == 401 ||
            err?.response?.data?.status == 403
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            setTimeout(() => {
              dispatch({ type: CLEAR_ALLDATA });
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }, 3000);
          }
        });
    }
  };
  const readNotification = async (notification) => {
    console.log(notification, "readNotification");
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/read-notification`,
          {
            id: notification.id,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setPage(1);
            getUnreadNotificationsCount();
          } else if (response.data.status == 400) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const deleteNotification = async () => {
    setnotificationLoader(true);
    setopenModal(false);
    const res = await commonApi
      ._deleteNotification({ type: "all" })
      .catch((err) => {
        setnotificationLoader(false);
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
    if (res.status === 200) {
      setNotificationsList([]);
      setnotificationLoader(false);
      getUnreadNotificationsCount();
    } else {
      setnotificationLoader(false);
    }
  };

  const deleteByNotifications = async (id) => {
    setopenModal(false);
    setnotificationLoader(true);
    const res = await commonApi
      ._deleteByNotification({
        type: "multiple",
        notificationIds: [id],
      })
      .catch((err) => {
        setnotificationLoader(false);
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
    if (res.status === 200) {
      setNotificationsList(
        _.reject(notificationsList, (item) => {
          return item.id === id;
        })
      );
      setnotificationLoader(false);
    } else {
      setnotificationLoader(false);
    }
  };
  const fetchAppointmentDetails = (appointmentId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: "redirect",
            appointmentId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response", response);
          if (response.data.status === 200) {
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointment/appointment-details/${appointmentId}`
            );
            dispatch(paymentDetails(response.data.output.result));
            setLoading(false);
          } else if (response.data.status == 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
            navigate("/client/appointments-dashboard");
          } else {
            setLoading(false);
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointments-dashboard`
            );
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const NotificationRedirection = (notification) => {
    console.log(notification, "notification");
    const type = userMode == "client" ? "client" : "caregiver";
    console.log(notification, "notificationredirection");
    switch (notification?.notificationType) {
      case "Appointment":
        {
          // navigate(`/${userMode}/appointments-dashboard/`)
          fetchAppointmentDetails(notification.appointmentId);
          // navigate(
          //   `/${type}/appointment/appointment-details/${notification.appointmentId}`
          // );
        }
        break;
      case "Message":
        {
          navigate(`/${type}/messages`);
        }
        break;
      case "Expense":
        {
          navigate(`/${type}/expenses`);
        }
        break;
      case "Review":
        {
          navigate("/dashboard/my-reviews");
        }
        break;
      default: {
      }
    }
  };
  const handleNotificatios = (notification) => {
    console.log(notification, "notification");
    NotificationRedirection(notification);
    if (!notification.isRead) {
      console.log("isRead");
      readNotification(notification);
    }
    setIsMobileNotificationVisible(false);
    setnotificationVisible(false);
  };

  useEffect(() => {
    if (clientDataTwilio && clientDataTwilio?.connectionState == "connected") {
      clientDataTwilio.on("messageAdded", () => {
        getUnreadNotificationsCount();
      });
    }
  }, [clientDataTwilio]);
  const getUnreadNotificationsCount = async () => {
    const res = await commonApi._getBannerStatus();
    if (res.status === 200) {
      const { isUnReadNotification, unReadNotificationCount } = res?.output;
      setUnreadData({ isUnReadNotification, unReadNotificationCount });
    }
  };

  const paginateOnScroll = () => {
    if (notificationRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = notificationRef.current;
      console.log(
        scrollTop.toFixed(0),
        scrollHeight,
        clientHeight,
        "sfsfsdfds"
      );
      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);
      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (
          notificationsList.length <= notificationTotalCount &&
          !notificationLoader
        ) {
          console.log("adasd");
          setPage((prev) => prev + 1);
        }
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };

  const fetchAllReviews = () => {
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/list-review`,
          {
            page: 1,
            pageLimit: 5,
            caregiverId: caregiverData?.userId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            setLoading(false);
            setAverageRating(response.data.output?.averageRating);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (err?.response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const NotificationsContent = (
    <div
      className="NotificationsContent"
      ref={notificationRef}
      onScroll={paginateOnScroll}
    >
      {/* <div style={{ display:'flex' ,justifyContent:"flex-end"}} >
app
       
<CloseOutlined  onClick={()=>setnotificationVisible(false)}/>
</div>  */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h4>Notifications</h4>
        </div>
        <div>
          {notificationsList.length > 0 ? (
            <h4
              onClick={() => setopenModal(true)}
              style={{ color: "#364BC6", cursor: "pointer" }}
            >
              Clear{" "}
            </h4>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <Divider/>   */}
      {notificationsList?.length > 0 ? (
        <div>
          {notificationsList?.map((item, key) => {
            return (
              <>
                {item ? (
                  <Row
                    className="Notifications"
                    style={{
                      background: !item.isRead && "#E7E8EA",
                      borderRadius: "3px",
                    }}
                    onClick={() => handleNotificatios(item)}
                  >
                    <Col span={4}>
                      {item.notificationType === "Appointment" ||
                      item.notificationType === "Expense" ? (
                        <div style={{ paddingTop: "15px", marginLeft: "6px" }}>
                          <img
                            src="/images/icons/calender_N.png"
                            alt="user"
                            style={{ height: "35px", width: "35px" }}
                          />
                        </div>
                      ) : item.notificationType
                          .toLowerCase()
                          .includes("message") ? (
                        <div
                          style={{ paddingTop: "15px", paddingLeft: "5.5px" }}
                        >
                          <img
                            src="/images/icons/notification-msg.svg"
                            alt="user"
                            style={{ height: "35px", width: "35px" }}
                          />
                        </div>
                      ) : (
                        <>
                          <div
                            style={{ paddingTop: "15px", paddingLeft: "5.5px" }}
                          >
                            <img
                              src="/images/icons/notification-review.svg"
                              alt="user"
                              style={{ height: "35px", width: "35px" }}
                            />
                          </div>
                        </>
                      )}
                    </Col>
                    <Col span={18}>
                      <Row>
                        <Text className="TextNotifications">
                          {item.notificationTitle}
                        </Text>
                      </Row>
                      <div style={{ textAlign: "right", marginTop: "-7px" }}>
                        <Text className="NotificationTime">
                          {moment().isSame(item.updatedAt, "day")
                            ? moment
                                .utc(item.updatedAt)
                                .local()
                                .local()
                                .format("hh:mm A")
                            : moment
                                .utc(item.updatedAt)
                                .local()
                                .format("DD/MM/YYYY")}
                        </Text>
                      </div>
                    </Col>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CloseOutlined
                        style={{ marginLeft: "15px" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteByNotifications(item.id);
                        }}
                      />
                    </div>
                    {/* <Divider/> */}
                  </Row>
                ) : (
                  <div className="notification-image">
                    <img src="/images/icons/notications-img.png" alt="user" />
                    <h4>No notications</h4>
                  </div>
                )}
              </>
            );
          })}
          {notificationLoader && <h4>Loading ...</h4>}
        </div>
      ) : notificationLoader ? (
        <>
          <div className="notification-image">
            <Spin style={{ marginLeft: "50px" }} />
            <h4>Loading ...</h4>
          </div>
        </>
      ) : !notificationLoader ? (
        <div className="notification-image text-center">
          <img src="/images/icons/bird-icon.svg" alt="user" />
          <h4>No Notifications</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    getUnreadNotificationsCount();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (props.BreadcrumbsView) {
      dispatch(
        ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.expenses)
      );
    } else if (props.dashboardTitile) {
      dispatch(
        ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.dashboard)
      );
    } else if (props.Schedule) {
      dispatch(
        ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.Schedule)
      );
    } else {
      dispatch(
        ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.Appointments)
      );
    }
    if (userMode == "caregiver") {
      fetchAllReviews();
    }
  }, []);

  console.log(averageRating, "averageRating");

  return (
    <div className="main-content-nav" style={{ marginBottom: "20px" }}>
      <div className="d-flex justify-content-between appointment-nav-container">
        {props.BreadcrumbsView ? (
          <div style={{ marginLeft: "20px" }}>
            <Breadcrumbs />
          </div>
        ) : (
          <div className="header-breadcrumb">
            <img
              className="d-block d-sm-block d-xml-none d-lg-none d-md-none mobile-time-logo"
              alt=""
              src="/images/icons/logo_with_name.svg"
            />
            <div style={isMobile ? { display: "none" } : { display: "block" }}>
              <Breadcrumbs />
            </div>
          </div>
        )}

        <div className="d-flex align-items-center right-side-data d-block d-sm-block d-md-none d-lg-none d-xl-none">
          {userMode == "client" && (
            // window.location.href.includes("dashboard")) && (
            <img
              className="plus-icon-img cursor-pointer plus-add-img plus-icon"
              alt=""
              src="/images/icons/add-first.svg"
              onClick={handleChooseRelative}
            />
          )}
          <img
            className="cursor-pointer chat-icon-mobile"
            alt="light outline message icon"
            src="/images/icons/message-chat-icon.svg"
            width={20}
            height={20}
            onClick={() => navigate("/client/messages")}
          />
          <Popover
            overlayClassName="header-popover-custom"
            placement="bottomRight"
            content={NotificationsContent}
            open={isMobileNotificationVisible}
          >
            {/* <Button onClick={() => setnotificationVisible(prev => !prev)} className="border-0 notification-icon-btn shadow-none bg-transparent rounded-0">
                        {/* <BellOutlined /> */}
            {/* </Button> */}
            {unreadData?.isUnReadNotification ? (
              <>
                <Badge count={unreadData?.unReadNotificationCount}>
                  <img
                    className="notification-icon-img"
                    alt=""
                    src="/images/icons/notification.svg"
                    onClick={() =>
                      setIsMobileNotificationVisible((prev) => !prev)
                    }
                  />
                </Badge>
              </>
            ) : (
              <>
                <img
                  className="notification-icon-img"
                  alt=""
                  src="/images/icons/notification.svg"
                  onClick={() =>
                    setIsMobileNotificationVisible((prev) => !prev)
                  }
                />
              </>
            )}
          </Popover>
          <Dropdown
            overlayClassName="header-dropdown-custom"
            menu={{
              items: dynamicNavMenus,
            }}
            trigger={["click"]}
            open={navMenuVisible}
            onOpenChange={() => setNavMenuVisible((prev) => !prev)}
          >
            <Button
              onClick={(e) => e.preventDefault()}
              className="menu-hamburger-btn"
            >
              <img
                className="img-fluid"
                alt=""
                src="/images/icons/menu-bar.png"
              />
            </Button>
          </Dropdown>
        </div>

        <div
          className="d-flex d-none d-sm-none details-header-right d-md-flex d-md-flex d-md-flex align-items-center"
          style={userMode == "client" ? { width: "auto" } : { width: "auto" }}
        >
          {userMode == "client" && (
            <img
              className="cursor-pointer me-3 plus-add-img plus-icon"
              alt="light outline plus icon"
              src="/images/icons/add-first.svg"
              onClick={handleChooseRelative}
              width={45}
            />
          )}
          <Popover
            placement="bottomRight"
            content={
              <OutsideAlerter
                handleOutSideClick={() => setnotificationVisible(false)}
              >
                {NotificationsContent}
              </OutsideAlerter>
            }
            open={notificationVisible}
            trigger={"click"}
            className="cursor-pointer"
          >
            {unreadData?.isUnReadNotification ? (
              <>
                <Badge count={unreadData?.unReadNotificationCount}>
                  <img
                    alt=""
                    src="/images/icons/notification.svg"
                    onClick={() => setnotificationVisible(true)}
                  />
                </Badge>
              </>
            ) : (
              <img
                alt=""
                src="/images/icons/notification.svg"
                onClick={() => setnotificationVisible(true)}
              />
            )}
          </Popover>
          <div
            onClick={handlePopupVisible}
            className="d-flex cursor-pointer ms-3"
          >
            {userMode == "caregiver" ? (
              <img
                alt=""
                src={
                  !userData?.userDetails?.image
                    ? "/images/icons/Profile.png"
                    : userData?.userDetails?.image
                }
                className="rounded-circle"
                style={{ height: "45px", width: "45px", objectFit: "cover" }}
              />
            ) : (
              <strong className="d-flex profile-name">
                {userData?.firstName + " " + userData?.lastName}
              </strong>
            )}

            <img
              alt=""
              className="ms-1"
              src="/images/icons/down-arrow.svg"
              style={popupVisible ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        </div>
        {/* {popupVisible && */}
        {/* // <OutsideAlerter handleOutSideClick={() => setPopupVisible(false)} className="nav-menu"> */}
        {/* <Menu> */}
        {/* {userMode == "client" ?
                  clientMenus.map(item => {
                    return <Menu.Item onClick={() => {
                      dispatch({ type: CLEAR_DATA })
                      navigate(item.route)
                    } */}
        {/* </div> */}
        {popupVisible && (
          <OutsideAlerter
            handleOutSideClick={() => setPopupVisible(false)}
            className={userMode == "client" ? "nav-menu" : "nav-menu caregiver"}
          >
            <Menu onChange={() => {}}>
              {userMode == "client"
                ? clientMenus.map((item) => {
                    return (
                      <Menu.Item
                        onClick={() => {
                          if (item.id === 4) {
                            setHandleDelete(true);
                          } else {
                            dispatch({ type: CLEAR_DATA });
                            navigate(item.route);
                          }
                        }}
                      >
                        <img
                          alt=""
                          src={`/images/icons/${item.icon}`}
                          width={
                            item.icon === "bookmark-icon.svg" ||
                            item.icon === "my-relatives.svg"
                              ? 23
                              : 20
                          }
                        />
                        <span className="mx-3 menu-title">{item.title}</span>
                      </Menu.Item>
                    );
                  })
                : caregiverMenus.map((item) => {
                    return (
                      <Menu.Item
                        onClick={() => {
                          if (item.id === 3) {
                            setHandleDelete(true);
                          } else if (item.id == 1 && averageRating != 0) {
                            navigate(item.route);
                          } else if (item.id != 1) {
                            navigate(item.route);
                          }
                        }}
                      >
                        <img
                          alt=""
                          src={`/images/icons/${item.icon}`}
                          width={20}
                        />
                        <span className="mx-3 menu-title">{item.title}</span>
                      </Menu.Item>
                    );
                  })}
            </Menu>
          </OutsideAlerter>
        )}
        {handleDelete && (
          <DeleteAccount
            setHandleDelete={setHandleDelete}
            handleDelete={handleDelete}
            setLoading={setLoading}
          />
        )}
      </div>
      <Confirmation
        open={openModal}
        isDelete={deleteNotification}
        onclose={() => {
          setopenModal(false);
        }}
      />
      <LogoutModal
        handleLogoutModal={handleLogoutModal}
        setHandleLogoutModal={setHandleLogoutModal}
      />
      {userData?.userType == 2 && (
        <div className="tab-button-custom-row">
          <button
            className={`${
              window.location.href.includes("/client/appointments-dashboard") ||
              window.location.href.includes("/client/appointment")
                ? "tab-active"
                : ""
            } tab-button-top-mobile`}
            onClick={() => {
              if (caregiverData?.homeData?.isFirstAppointmentCreated) {
                navigate("/client/appointments-dashboard");
              } else {
                navigate("/client/appointment");
              }
            }}
          >
            Appointments
          </button>
          <button
            className={`${
              window.location.href.includes("/dashboard/my-caregivers")
                ? "tab-active"
                : ""
            } tab-button-top-mobile`}
            onClick={() => navigate("/dashboard/my-caregivers")}
          >
            My Caregivers
          </button>
          <button
            className={`${
              window.location.href.includes("/client/settings")
                ? "tab-active"
                : ""
            } tab-button-top-mobile`}
            onClick={() => navigate("/client/settings")}
          >
            My Account
          </button>
        </div>
      )}
    </div>
  );
};

export default AppointmentNavBar;

import React, { useMemo, useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Input, Spin } from "antd";
import {
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { setAlert } from "../../../redux/alert/alert.action";
import { useNavigate } from "react-router-dom";
const SplitForm = ({
  setShowCards,
  setCardDetails,
  isPaymentScreenOpen,
  tabValue,
  setAddCardPopUp,
  isMobile,
  onCancel,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const formRef = useRef();
  const [cardHoldersName, setCardHoldersName] = useState("");
  const [stripeElements, setStripeElements] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(false);
  const navigate = useNavigate;
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  console.log("token in splitcard form is", token);
  const [form] = Form.useForm();
  useEffect(() => {
    // if(tabValue!=3)
  }, [tabValue]);
  const cardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        "::placeholder": {
          color: "rgb(192, 192, 192)",
        },
      },
      invalid: {},
    },
    hidePostalCode: true,
    disableLink: true,
  };
  const handleSubmit = async () => {
    setSaveDisabled(true);
    // const cardElement = elements.getElement("card");
    const cardElement = elements.getElement(CardNumberElement);
    console.log(cardElement, "cardELement");
    if (!stripe || !elements) {
      return;
    }
    await stripe
      .createToken(cardElement, { name: cardHoldersName })
      .then((res) => {
        if (res.error) {
          console.log(res.error.message, "in response error");
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: res.error.message,
              alertOpen: true,
            })
          );
        } else {
          console.log(res, "res adding card token");
          addSingleCard(token, res)
            .then((res) => {
              console.log(res, "in add card");
              if (res.data.status === 200) {
                setShowCards(true);
                if (isPaymentScreenOpen) {
                  onCancel();
                  setAddCardPopUp(false);
                  isPaymentScreenOpen("Payment");
                }
                onCancel();
                fetchCardDetails(token).then((res) => {
                  setCardDetails(res);
                });
                dispatch(
                  setAlert({
                    alertType: "success",
                    alertMsg: res.data.message,
                    alertOpen: true,
                  })
                );
              } else if (res.data.status >= 400) {
                dispatch(
                  setAlert({
                    alertType: "error",
                    alertMsg: res?.data?.error?.message,
                    alertOpen: true,
                  })
                );
              } else {
                dispatch(
                  setAlert({
                    alertType: "error",
                    alertMsg: "something went wrong! please try again.",
                    alertOpen: true,
                  })
                );
              }
            })
            .catch((err) => {
              if (
                err?.response?.data?.status >= 400 &&
                err?.response?.data?.status !== 401
              ) {
                dispatch(
                  setAlert({
                    alertType: "error",
                    alertMsg: err?.response?.data?.error?.message,
                    alertOpen: true,
                  })
                );
              }
            });
        }
      });
  };

  // useEffect(() => {
  //   checkDisabled();
  // }, [stripeElements, cardHoldersName]);

  const checkDisabled = () => {
    if (
      !stripeElements?.cardCvc?.complete ||
      !stripeElements?.cardExpiry?.complete ||
      !stripeElements?.cardNumber?.complete ||
      stripeElements?.cardCvc?.error?.code ||
      stripeElements?.cardExpiry?.error?.code ||
      stripeElements?.cardNumber?.error?.code ||
      !cardHoldersName ||
      saveDisabled
    ) {
      return true;
    }
    return false;
  };
  console.log(stripeElements, "stripeElements");
  const handleChange = async (event) => {
    const e = await event;
    console.log("com", e);
    setStripeElements((prev) => ({ ...prev, [e.elementType]: e }));
    // if (e?.complete === true) {
    //   // enable payment button
    //   setSaveDisabled(false);
    //   console.log("com", e);
    // } else if (e?.error) {
    //   // show validation to customer
    //   setSaveDisabled(true);
    // } else if (e?.empty === false && e?.error === undefined) {
    //   setSaveDisabled(true);
    // }
  };
  const addSingleCard = async (token, res) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/add-card`,
        { token: res.token.id },
        {
          headers: {
            accessToken: token,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      );
      console.log("single card details", response.data);
      if (response.data.status === 200) {
        fetchCardDetails(token).then((res) => setCardDetails(res));
      }
      return response;
    } catch (err) {
      if (
        err?.response?.data?.status >= 400 &&
        err?.response?.data?.status !== 401
      ) {
        dispatch(
          setAlert({
            alertType: "error",
            alertMsg: err?.response?.data?.error?.message,
            alertOpen: true,
          })
        );
      }
    }
  };
  const fetchCardDetails = async (token) => {
    if (token !== "") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/list-card`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        );
        // console.log(response.data.output.list,"fetch api response");
        return response.data.output.list;
      } catch (err) {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      }
    }
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <>
      {isMobile ? (
        <div className="stripe">
          <Form
            onFinish={handleSubmit}
            ref={formRef}
            initialValues={{
              remember: true,
            }}
          >
            {/* onFieldsChange={}> */}
            <Form.Item
              name="name"
              style={{ width: "50%", marginBottom: "15px" }}
              type="text"
              className="mb-1"
              rules={[
                {
                  required: true,
                  message: "Please enter cardholder name",
                },
                {
                  pattern: new RegExp(/^[A-Za-z ]+$/),
                  message:
                    "Field does not accept numbers or special characters.",
                },
              ]}
            >
              <Input
                style={{
                  height: "47px",
                  marginTop: "5px",
                  color: "black",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
                onChange={(e) => {
                  setCardHoldersName(e.target.value);
                }}
                placeholder="CardHolder's Name"
              />
            </Form.Item>
            {/* <Form.Item style={{ width: "50%", marginLeft: "10px" }}>
              <CardElement
                options={cardElementOptions}
                onChange={handleChange}
              />
            </Form.Item> */}
            <Form.Item className="mb-1 element-custom" style={{ width: "50%" }}>
              <CardNumberElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item className="mb-1 element-custom" style={{ width: "50%" }}>
              <CardCvcElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item className="mb-1" style={{ width: "50%" }}>
              <CardExpiryElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  className="btn-black  auth-action-btn"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  size="large"
                  disabled={checkDisabled()}
                >
                  Add Card
                </Button>
                <Button
                  style={{
                    marginLeft: "10px",
                    height: "55px",
                    width: "185px",
                    borderRadius: "50px",
                  }}
                  onClick={() => setAddCardPopUp(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <>
          <Form
            onFinish={handleSubmit}
            ref={formRef}
            initialValues={{
              remember: true,
            }}
          >
            {/* onFieldsChange={}> */}
            <Form.Item
              name="name"
              style={{ width: "50%", marginBottom: "15px" }}
              type="text"
              className="mb-1"
              rules={[
                {
                  required: true,
                  message: "Please enter cardholder name",
                },
                {
                  pattern: new RegExp(/^[A-Za-z ]+$/),
                  message:
                    "Field does not accept numbers or special characters.",
                },
              ]}
            >
              <Input
                style={{
                  height: "47px",
                  marginTop: "5px",
                  color: "black",
                  fontSize: "15px",
                  fontWeight: 500,
                }}
                onChange={(e) => {
                  setCardHoldersName(e.target.value);
                }}
                placeholder="Cardholder's Name"
              />
            </Form.Item>
            {/* <Form.Item style={{ width: "50%", marginLeft: "10px" }}>
              <CardElement
                options={cardElementOptions}
                onChange={handleChange}
              />
            </Form.Item> */}
            <Form.Item className="mb-1 element-custom" style={{ width: "50%" }}>
              <CardNumberElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item className="mb-1 element-custom" style={{ width: "50%" }}>
              <CardCvcElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item className="mb-1 element-custom" style={{ width: "50%" }}>
              <CardExpiryElement
                onChange={handleChange}
                options={cardElementOptions}
              />
            </Form.Item>
            <Form.Item>
              <div className="stripeaddcardbtn stripeaddcardbtn-update stripeaddcardbtn-div d-flex align-items-center w-auto">
                <Button
                  className="btn-black  auth-action-btn"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  size="large"
                  disabled={checkDisabled()}
                >
                  Add Card
                </Button>
                <Button
                  style={{
                    marginLeft: "10px",
                    height: "55px",
                    width: "150px",
                    borderRadius: "50px",
                  }}
                  onClick={() => setAddCardPopUp(false)}
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

export default SplitForm;

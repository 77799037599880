import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import axios from "axios";
import { connect, useDispatch } from "react-redux";
import { setAlert } from "../../redux/alert/alert.action";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../common/styles/otp.less";
import { setUser } from "../../redux/auth/auth.action";

function Verify({ accessToken }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  console.log("state", state);
  const [, setResendCode] = useState(false);
  const [otp, setOTP] = useState(null);
  const [contryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState(0);
  const [sentOTP, setSentOTP] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [disableNumber, setDisableNumber] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [isIncorrectOTP, setIsIncorrectOTP] = useState(false);

  const handleResendCode = async () => {
    setDisabledBtn(true);
    setIsIncorrectOTP(false);
    setOTP(null);
    setResendCode(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/send-verification-code`,
        {
          countryCode: contryCode,
          phoneNumber: mobile,
        },
        {
          headers: {
            accessToken:
              state?.accessToken !== undefined
                ? state?.accessToken
                : accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        if (response?.data?.status === 200) {
          setDisabledBtn(false);
          setResendCount(resendCount + 1);
          dispatch(
            setAlert({
              alertType: "success",
              alertMsg: response?.data?.message,
              alertOpen: true,
            })
          );
          setSentOTP(true);
        } else if (response?.data?.status >= 400) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (err?.response.data.status >= 400) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
          if (err.response.data.status === 401) {
            localStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      });
    // console.log("response", response)
  };

  const renderButton = (buttonProps) => {
    console.log("buttonProps", buttonProps);
    return (
      <div style={{ fontSize: "0.875rem" }}>
        Didn’t receive a code?{" "}
        {resendCount >= 2 ? (
          <Button disabled className="resend-otp-btn p-0 fw-bold" type="link">
            {" "}
            Resend code
          </Button>
        ) : (
          <Button
            className="resend-otp-btn"
            type="link"
            style={{
              textTransform: "capitalize",
              fontWeight: 600,
              paddingLeft: "0px",
            }}
            disabled={buttonProps?.disabled}
            onClick={buttonProps?.onClick}
          >
            Resend code
          </Button>
        )}{" "}
      </div>
    );
  };

  const renderTime = (remainingTime) => {
    return remainingTime ? (
      <span
        style={{
          textTransform: "capitalize",
          fontWeight: 600,
          paddingLeft: "0px",
        }}
      >
        00:{remainingTime < 10 ? 0 : ""}
        {remainingTime}
      </span>
    ) : (
      ""
    );
  };

  const handleSendOTP = () => {
    const body = {
      countryCode: contryCode,
      phoneNumber: mobile,
    };
    sendOTP(body);
  };

  const sendOTP = async (body) => {
    setDisabledBtn(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/send-verification-code`,
        body,
        {
          headers: {
            accessToken:
              state?.accessToken !== undefined
                ? state?.accessToken
                : accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 200) {
          setDisabledBtn(false);
          setDisableNumber(true);
          dispatch(
            setAlert({
              alertType: "success",
              alertMsg: response?.data?.message,
              alertOpen: true,
            })
          );
          setSentOTP(true);
        } else if (response?.data?.status >= 400) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status >= 400) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        } else {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      });
  };

  const handleVerify = async (body) => {
    setDisabledBtn(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/verify-code`,
        {
          code: otp,
        },
        {
          headers: {
            accessToken:
              state?.accessToken !== undefined
                ? state?.accessToken
                : accessToken,
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        }
      )
      .then((response) => {
        if (response?.data?.status === 200) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "success",
              alertMsg: response?.data?.message,
              alertOpen: true,
            })
          );
          dispatch(setUser(state));
          navigate("/client/profile");
        } else if (response?.data?.status >= 400) {
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err, "err in verify code");
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          setIsIncorrectOTP(true);
          setDisabledBtn(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  useEffect(() => {
    if (isIncorrectOTP) {
      setIsIncorrectOTP(false);
    }
  }, [otp?.length]);

  console.log(isIncorrectOTP, "isIncorrectOTP");
  return (
    <div className="center-container main-div-top min-vh-100">
      <div className="welcome-container justify-content-center">
        {/* <div className="left-side-poster verify-poster" /> */}
        <div className="right-side-form">
          <div className="center-container verify-container h-100 text-center">
            <div>
              <h1 id="name" className="welcome-header verify-header mb-0">
                Verify Your Phone Number
              </h1>
              {!sentOTP && (
                <p
                  className="welcome-subheader mt-2 mb-4"
                  style={{ maxWidth: "75%", margin: "auto" }}
                >
                  Enter your mobile phone number, a verification code will be
                  sent
                </p>
              )}
              <div
                className="full-width verify-main-div"
                style={{ maxWidth: "340px", margin: "auto" }}
              >
                <div className="mb-3 mt-5 phone-number-custom">
                  <PhoneInput
                    inputExtraProps={{
                      placeholder: "Enter phone number",
                      searchPlaceholder: "Enter phone number",
                    }}
                    placeholder="Enter phone number"
                    searchPlaceholder="Enter phone number"
                    country="ca"
                    enableAreaCodes={false}
                    copyNumbersOnly={false}
                    inputClass="ant-input ant-input-lg"
                    containerClass="ant-input-affix-wrapper ant-input-affix-wrapper-lg"
                    dropdownClass="ant-select ant-select-single"
                    buttonStyle={{ background: "none", border: "none" }}
                    inputStyle={{ width: "100%", paddingLeft: "60px" }}
                    disabled={disableNumber}
                    // countryCodeEditable={false}
                    onChange={(value, country, e, formattedValue) => {
                      setCountryCode(country?.dialCode);
                      setMobile(value.slice(country?.dialCode?.length));
                    }}
                  />
                  {console.log("mobile", contryCode, mobile)}
                </div>

                {sentOTP && (
                  <div>
                    <p className="welcome-subheader mb-3">
                      Please type the verification code sent to
                      {" +"}
                      {contryCode} {mobile}
                    </p>
                    <OTPInput
                      className="otp-input-container my-4"
                      inputClassName={`otp-input ${
                        isIncorrectOTP ? "incorrect" : ""
                      }`}
                      value={otp}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure={false}
                    />
                    {isIncorrectOTP && (
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#FF0000",
                          lineHeight: "18px",
                          textAlign: "left",
                        }}
                      >
                        Incorrect Verification Code
                      </p>
                    )}
                    <ResendOTP
                      renderButton={renderButton}
                      renderTime={renderTime}
                      className=" mb-3 align-items-center"
                      maxTime={15}
                      onResendClick={() => handleResendCode()}
                    />
                  </div>
                )}
                <Button
                  onClick={() => {
                    sentOTP ? handleVerify() : handleSendOTP();
                  }}
                  className="btn-black auth-action-btn mb-2"
                  style={{ margin: "auto" }}
                  shape="round"
                  type="primary"
                  size="large"
                  disabled={
                    disabledBtn ||
                    (sentOTP
                      ? otp === null || otp === "" || otp?.length < 4
                        ? true
                        : false
                      : mobile === 0 || mobile === "" || mobile?.length < 10
                      ? true
                      : false)
                  }
                >
                  Verify{" "}
                  <img
                    className="ms-2"
                    src="/images/icons/right.svg"
                    alt="right icon"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    userType: state.loginData.user.userType,
    screen: state.loginData.user.screen,
    accessToken: state.loginData.user.accessToken,
    user: state.loginData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);

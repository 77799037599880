import React from "react";
import { Button, Input, Form } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setAlert } from "../../redux/alert/alert.action";
import { useState } from "react";
import { useDispatch } from "react-redux";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [disabledSave, setDisabledSave] = useState(true);

  const [form] = Form.useForm();

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    resetPass(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const resetPass = async (values) => {
    setDisabledSave(true);
    const data = {
      email: values.email,
      userType: [2],
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/forgot-password`, data, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((response) => {
        // console.log("response", response)
        if (response.data.status === 200) {
          setDisabledSave(false);
          navigate("/client/reset-password/success", { state: data });
        } else if (response.data.status >= 400) {
          setDisabledSave(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        } else {
          setDisabledSave(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      });
  };

  return (
    <div className="center-container min-vh-100">
      <div className="welcome-container justify-content-center">
        {/* <div className="left-side-poster client-login-poster" /> */}
        <div className="right-side-form">
          <div className="center-container justify-content-start h-100">
            <div className="w-100">
              <div
                className="full-width reset-password-main text-center"
                // style={{ maxWidth: "480px" }}
              >
                <h1 id="name" className="welcome-header mb-0">
                  Reset Your Password
                </h1>
                <p
                  className="welcome-subheader mt-2 mb-4"
                  style={{ maxWidth: "75%", margin: "auto" }}
                >
                 Enter the email address associated with your account and we will send you the new password
                </p>
                <div
                  className="full-width reset-password-div"
                  // style={{ maxWidth: "380px" }}
                >
                  <Form
                    name="signup-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    // validateTrigger='onSubmit'
                    onFieldsChange={handleFormChange}
                    form={form}
                  >
                    <Form.Item
                      name="email"
                      type="email"
                      className="mb-4"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid E-mail.",
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Email Address"
                        prefix={
                          <img
                            alt=""
                            src="/images/icons/Message.svg"
                            style={{ marginRight: "10px" }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item className="d-flex justify-content-center">
                      <Button
                        htmlType="submit"
                        className="btn-black auth-action-btn mb-3"
                        shape="round"
                        type="primary"
                        size="large"
                        disabled={disabledSave}
                      >
                        Reset Password{" "}
                        <img
                          className="ms-2"
                          src="/images/icons/right.svg"
                          alt="Reset Password Icon"
                        />
                      </Button>
                      <p className="ps-3 welcome-subheader back-sign-in pointer fs-6">
                        Back to{" "}
                        <a className="fw-bold" href="/client/signin">
                          Sign In
                        </a>
                      </p>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

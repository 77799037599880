import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import toggleModal from "./modals/toggleModal.reducer";
import Loginreducer from "./auth/auth.reducer";
import alertReducer from "./alert/alert.reducer";
import appointmentReducer from "./appointment/appointment.reducer";
import caregiverProfileReducer from "./profileDetails/profile.reducer";
import { CLEAR_DATA } from "./auth/auth.type";
import socketReducer, { twilioReducer } from "./socket/socket.reducer";
import breadcumbsReducer from "./Breadcrumb/BreadcrumbRoute";
import stripeReducer from "./stripe/stripe.reducer";
import { CLEAR_ALLDATA } from "./auth/auth.type";

const appReducer = combineReducers({
  state: (state = {}) => state,
  toggleModals: toggleModal,
  loginData: Loginreducer,
  session: sessionReducer,
  alert: alertReducer,
  appointment: appointmentReducer,
  profileDetails: caregiverProfileReducer,
  socketData: socketReducer,
  twillioData: twilioReducer,
  breadcumbsReducer: breadcumbsReducer,
  stripeDocs: stripeReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === CLEAR_DATA)
    state = {
      loginData: { ...state.loginData },
      session: { ...state.session },
      toggleModals: { ...state.toggleModals },
      appointment: {
        paymentDetails: { ...state.appointment.paymentDetails },
        dateTimeResp: {
          slot: [],
          range: [],
        },
        servicesResp: {
          caregiverAllChecked: false,
          companionAllChecked: false,
          checkedCompanions: [],
          checkedCaregiver: [],
        },
        selectedCC: { ...state.appointment.selectedCC },
        bookingDetails: null,
        caregiverAvailability: [],
      },
      breadcumbsReducer: [...state.breadcumbsReducer],
    };
  if (action.type === CLEAR_ALLDATA) {
    state = {};
  }
  return appReducer(state, action);
};
export default rootReducer;

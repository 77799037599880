import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, Col, Divider, Input, Row, Spin } from "antd";
import { Typography } from "antd";
import { ArrowLeftOutlined, SendOutlined } from "@ant-design/icons";
import * as io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../../components/sidebar";
import AppointmentNavBar from "../appointment/appointmentsNavBar";
import "./messages.less";
import moment from "moment";
import { useRef } from "react";
import ActionCreator from "../../../redux/Breadcrumb/ActionCreator";
import { handleSocketConnection } from "../../../redux/socket/socket.action";
import Footer from "../../../components/Footer/Footer";
import Responsiveheader from "../../../components/Responsiveheader";
import { Client } from "@twilio/conversations";
import { setUnreadMsgCount } from "../../../redux/auth/auth.action";
import commonApi from "../../../http/commonApi";

// import { socket } from "../../../redux/socket/socket.reducer";

function Message() {
  const userData = useSelector((state) => state.loginData?.user);
  const dispatch = useDispatch();
  const date = new Date();
  const showTime = date.getHours() + ":" + date.getMinutes();
  const { firstName, userId } = userData;
  const [filteredList, setFilteredList] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const [typedMessage, setTypedMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [bottomVisible, setBottomVisible] = useState(false);
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [messagesLoader, setMessageLoader] = useState(false);
  const [conversationsLoader, setConversationsLoader] = useState(false);
  const [redDotVisible, setRedDotVisible] = useState(false);
  // const [socketConnection, setSocketConnection] = useState(false);
  const accessToken = localStorage.getItem("token");
  const [isClientConnected, setIsClientConnected] = useState(false);
  // const socket = io(`https://mf-chat.approd.ca/?accessToken=${accessToken}`);
  const isSocketConnected = useSelector(
    (state) => state.socketData?.socketConnection
  );
  const [isAvailable, setIsAvailable] = useState(false);
  const socket = useSelector((state) => state.socketData?.socket);
  const messageEl = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const clientConnection = useSelector((state) => state.twillioConnection);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [conversationsList, setConversationsList] = useState([]);
  const clientDataTwilio = useSelector(
    (state) => state?.twillioData?.twillioClient
  );
  const [twillioMsgList, setTwillioMessageList] = useState([]);
  const [activeUserData, setActiveUserData] = useState(null);
  const [isNewMessageAdded, setIsNewMessageAdded] = useState(false);
  const [isAvailableToMessage, setIsAvailableToMessage] = useState(false);

  //Send Message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    // console.log({
    //   message: typedMessage,
    //   senderId: userId,
    //   receiverId: activeUser?.receiverId,
    // });
    // if (currentConversation) {
    //   debugger;
    //   const resp = await currentConversation.sendMessage(typedMessage);
    //   const newMsgResp = await currentConversation.getMessages(100);
    //   console.log(newMsgResp, "newMsgResp");
    //   console.log(resp, "sendmsgresp");
    // }
    if (typedMessage) {
      activeUser?.sendMessage(typedMessage);
      setTypedMessage("");
      const participants = await activeUser.getParticipants();
      console.log(participants, "participants");
      if (participants?.length == 2) {
        const oppoParticipant = participants?.find(
          (n) => n.state.identity != userId
        );
        const payload = {
          conversationId: activeUser.sid,
          receiverId: oppoParticipant.state.identity,
        };
        const pushNotificationResp = await commonApi._sendMessageNotification(
          payload
        );
        console.log(pushNotificationResp, "pushNotificationResp");
      }
      // socket.emit("sendMessage", {
      //   message: typedMessage,
      //   senderId: userId,
      //   receiverId: activeUser?._id,
      // });
      // socket.on("messageFromServer", (messageFromServer) => {
      //   console.log([messageFromServer], "messageFromServer");
      // });
      // setMessages((prev) => [
      //   ...prev,
      //   {
      //     message: typedMessage,
      //     senderId: userId,
      //     receiverId: activeUser?._id,
      //   },
      // ]);
      // setCurrentChatPage(1);
      // setTypedMessage("");
    }
  };

  // useEffect(() => {
  //   console.log(userData?.twilioConversationToken, "userDatanew");
  //   // if (userData?.twilioConversationToken) {
  //   // }
  // }, [userData?.twilioConversationToken]);

  useEffect(() => {
    // debugger;
    if (clientDataTwilio && clientDataTwilio?.connectionState == "connected") {
      let sid;
      setConversationsLoader(true);
      const conversations = clientDataTwilio.getSubscribedConversations();
      conversations
        .then(async (Resp) => {
          const conversationsArr = [];
          console.log(Resp.items, "respitems");
          for (const elem of Resp.items) {
            const unreadCount = await elem.getUnreadMessagesCount();
            const lastMessage = await elem.getMessages(1);
            const participants = await elem.getParticipants();
            if (participants?.length == 2) {
              const oppoParticipant = participants?.find(
                (n) => n.state.identity != userId
              );
              const userDataNew = await clientDataTwilio.getUser(
                oppoParticipant.state.identity
              );
              console.log(userDataNew, "userDataNew");
              const obj = {
                name: userDataNew.state?.friendlyName,
                profilePic: userDataNew.state?.attributes?.image,
                userId: userDataNew.state.identity,
              };
              // let usersData;
              const elemObj = { elem, unreadCount, lastMessage, userData: obj };
              conversationsArr.push(elemObj);
            }
          }
          // if (conversationsArr?.length == Resp.items?.length) {
          setConversationsList(conversationsArr);
          // }
          // sid = Resp.sid;
          // console.log(Resp, "responsedata");
          // setCurrentConversation(Resp);
          // Resp.getMessages(100)
          //   .then((messagesList) => {
          //     setTwillioMessageList(messagesList?.items);
          //     console.log(messagesList.items, "messagesListnew");
          //   })
          //   .catch((Err) => {
          //     console.log(Err);
          //   });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setConversationsLoader(false);
        });
      // if (!sid) {
      //   clientDataTwilio
      //     ?.createConversation({
      //       uniqueName: `${userId} - ${caregiverData?.caregiverId}`,
      //       // `${Math.random()}`,
      //       // `${userId} - ${caregiverData?.caregiverId}4`,
      //     })
      //     .then((resp) => {
      //       debugger;
      //       console.log("dsfdsf", resp);
      //       resp.join().then((respnew) => {
      //         console.log(respnew, "respnewnew");
      //         resp
      //           .add(userMode == "client" ? caregiverData?.caregiverId : userId)
      //           .then((participant) => {
      //             console.log("Participant SID:", participant.sid);
      //             console.log("Participant Identity:", participant.identity);
      //           })
      //           .catch((err) => {
      //             console.log(err);
      //           });
      //         console.log(respnew, "respnew");
      //       });
      //       setCurrentConversation(resp);
      //     });
      // }
    }
  }, [clientDataTwilio, clientConnection]);

  useEffect(() => {
    if (clientDataTwilio?.connectionState == "connected" && activeUser) {
      activeUser
        .getMessages(1000)
        .then(async (messagesList) => {
          setTwillioMessageList(messagesList?.items);
          console.log(messagesList.items, "messagesListnew");
        })
        .catch((Err) => {
          console.log(Err);
        });
    }
  }, [clientDataTwilio, clientConnection, activeUser, messageEl]);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      activeUser &&
      twillioMsgList?.length > 0
    ) {
      activeUser
        .setAllMessagesRead(twillioMsgList?.length - 1)
        .then((resp) => console.log("successss", resp));
    }
  }, [twillioMsgList, activeUser, clientDataTwilio]);
  //Read message
  // useEffect(() => {
  //   socket.emit("readMessage", {
  //     messageId: activeUser?.messageId,
  //   });
  // }, []);

  //Read chat-list
  // useEffect(() => {
  // if (socket?.connected) {
  //   setTimeout(() => {
  //     socket.on("chatListFromServer", (data) => {
  //       // console.log("chatList", data?.chatList)
  //       setFilteredList(data?.chatList);
  //     });
  //     socket.emit("chatList", {
  //       senderId: userId,
  //       pageNo: 1,
  //       limit: 10,
  //     });
  //   }, 2);
  //   socket.on("messageFromServer", (messageFromServer) => {
  //     const msgData = { ...messageFromServer?.getChatData };
  //     // debugger
  //     console.log(msgData, "msgData");
  //     // const newMsgData = {msgData};
  //     // if(msgData?.receiverId._id == userId) {
  //     //   // msgData['senderId'] = msgData?.receiverId;
  //     //   // msgData['receiverId'] = msgData?.senderId;
  //     //   msgData['senderData'] = msgData?.receiverId;
  //     //   msgData['receiverData'] = msgData?.senderId;
  //     //   msgData['receiverId'] = msgData?.senderId?._id
  //     //   msgData['senderId'] = msgData?.receiverId?._id
  //     // }
  //     // else {
  //     msgData["senderData"] = msgData?.senderId;
  //     msgData["receiverData"] = msgData?.receiverId;
  //     msgData["receiverId"] = msgData?.receiverId?._id;
  //     msgData["senderId"] = msgData?.senderId?._id;
  //     // }
  //     if (msgData?.senderId != userId) {
  //       setRedDotVisible(true);
  //     }
  //     const msgList = [...messages];
  //     msgList.push(msgData);
  //     // console.log(messages, "sdsd")
  //     // console.log(messages[messages.length - 1]?._id, msgData?._id, "msgDatadata")
  //     // if(messages[messages.length - 1]?._id != undefined && messages[messages.length - 1]?._id != msgData?._id) {
  //     setMessages(msgList);
  //     // }
  //     console.log(messageFromServer, "messageFromServer");
  //   });
  // }
  // }, [userId, messages?.length, socket?.connected]);

  //Read Chat-history

  useEffect(() => {
    dispatch(
      ActionCreator.pushBreadcrumb(ActionCreator.BreadcrumbItems.messages)
    );
  }, []);
  // useEffect(() => {
  //   if (socket?.connected && currentChatPage == 1) {
  //     setMessageLoader(true);
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: activeUser?._id,
  //       pageNo: 1,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       console.log(data, "datadata");
  //       const newArr = data.chatHistory.slice()?.reverse();
  //       setMessages(newArr);
  //       setTotalCount(data?.totalCount);
  //       setMessageLoader(false);
  //       setIsAvailable(data?.isAvailableToMessage);
  //     });
  //     if (messageEl) {
  //       messageEl.current?.addEventListener("DOMNodeInserted", (event) => {
  //         const { currentTarget: target } = event;
  //         target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //         setRedDotVisible(false);
  //       });
  //     }
  //   }
  // }, [activeUser?._id, socket?.connected]);

  // useEffect(() => {
  //   if (socket?.connected && currentChatPage != 1) {
  //     setMessageLoader(true);
  //     socket.emit("chatHistory", {
  //       senderId: userId,
  //       receiverId: activeUser?._id,
  //       pageNo: currentChatPage,
  //       limit: 50,
  //     });
  //     socket.on("chatHistoryFromServer", (data) => {
  //       const newArr = data.chatHistory.slice().reverse();
  //       const updatedArr = [...newArr, ...messages];
  //       setMessages(updatedArr);
  //       setMessageLoader(false);
  //       // setTotalCount(data?.totalCount)
  //     });
  //   }
  // }, [currentChatPage]);
  // console.log("messages",messages)
  const handleScroll = (e) => {
    console.log(
      "called",
      e.target.scrollHeight,
      e.target.scrollTop + e.target?.clientHeight
    );
    let flag =
      e?.target.scrollHeight !=
      Math.round(e.target?.scrollTop + e.target?.clientHeight);
    if (flag) {
      flag =
        e?.target.scrollHeight - 1 !=
        Math.round(e.target?.scrollTop + e.target?.clientHeight);
    }
    setBottomVisible(flag);
    if (messageEl.current) {
      // const { scrollHeight } = messageEl.current;
      if (e.target.scrollTop == 0 && messages.length != totalCount) {
        setCurrentChatPage((prev) => prev + 1);
      }
    }
  };

  const handleScrollToBottom = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    // messageEl.current?.addEventListener('', event => {
    // const { currentTarget: target } = e;
    // target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    // });
    // debugger
    // messageEl.current?.scrollIntoView({ block: "nearest",behavior: 'smooth', });
    setCurrentChatPage(1);
    setRedDotVisible(false);
    // setBottomVisible(false);
    const scroll =
      messageEl.current?.scrollHeight - messageEl.current?.clientHeight;
    messageEl.current?.scrollTo({ top: scroll, behavior: "smooth" });
    // messageEl?.current?.scrollIntoView({behavior: 'smooth'});
    // messageEl.current?.addEventListener('click', event => {
    //   const { currentTarget: target } = event;
    //   target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    // });
  };

  //     const onScroll = () => {
  //     console.log("called", "")

  // };

  // console.log(socket, "socketdata")
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  console.log("defrefe", isMobile);

  useEffect(() => {
    if (
      clientDataTwilio &&
      clientDataTwilio?.connectionState == "connected" &&
      activeUser
    ) {
      activeUser.on("messageAdded", async (newMessage) => {
        console.log(newMessage, activeUser, "newMessage");
        // const messages = [...twillioMsgList];
        // messages.push(newMessage);
        const conversationsArr = [...conversationsList];
        const foundIndex = conversationsArr.findIndex(
          (curr) => curr.elem.sid === newMessage.conversation.sid
        );
        conversationsArr[foundIndex].lastMessage = { items: [newMessage] };

        // if (!foundMessage) {
        //   debugger;
        // if(newMessage.conversation.sid === activeUser.sid)
        if (activeUser.sid === newMessage.conversation.sid) {
          if (newMessage.author !== userId) {
            setIsNewMessageAdded(true);
          } else {
            setIsNewMessageAdded(false);
          }
          setTwillioMessageList((prev) => {
            const foundMessage = prev.find((i) => i.sid == newMessage?.sid);
            if (!foundMessage) {
              setRedDotVisible(true);
              return [...prev, newMessage];
            }
            return prev;
          });
        } else {
          conversationsArr[foundIndex].unreadCount = await conversationsArr[
            foundIndex
          ].getUnreadMessagesCount();
        }

        setConversationsList(conversationsArr);

        // }
      });
    }
  }, [clientDataTwilio, clientConnection, activeUser]);
  useEffect(() => {
    if (activeUser && conversationsList?.length > 0) {
      let unreadCount = 0;
      conversationsList.forEach((i) => {
        unreadCount = unreadCount + i.unreadCount;
      });
      dispatch(setUnreadMsgCount(unreadCount));
    }
  }, [activeUser, conversationsList?.length]);

  useEffect(() => {
    if (twillioMsgList?.length > 0 && !isNewMessageAdded) {
      const lastMessageId = twillioMsgList[twillioMsgList.length - 1]?.sid;
      const lastMsgElem = document.getElementById(lastMessageId);
      if (lastMsgElem) {
        // setTimeout(() => {
        lastMsgElem?.scrollIntoView({
          top: lastMsgElem?.offsetTop,
          behavior: "smooth",
        });
        // }, 1000);
        setRedDotVisible(false);
      }
    }
  }, [twillioMsgList?.length]);

  useEffect(() => {
    if (activeUser && userId) {
      getAvailableToMsg();
    }
  }, [activeUser, userId]);

  const getAvailableToMsg = useCallback(async () => {
    if (activeUser) {
      const participants = await activeUser?.getParticipants();
      if (participants?.length > 0) {
        const foundSender = participants.find(
          (n) => n.state.identity == userId
        );
        const foundReceiver = participants.find(
          (n) => n.state.identity != userId
        );
        const payload = {
          receiverId: foundReceiver.state.identity,
          senderId: foundSender.state.identity,
        };
        const resp = await commonApi._getCheckAvailableToMsg(payload);
        setIsAvailableToMessage(resp.output.isAvailableToMessage);
        console.log(resp, "checkavailable");
      }
    }
  }, [activeUser]);

  return (
    <>
      {/*{ isMobile ? (*/}
      {/*  <div style={{ background: "#E4E4E4", minHeight: "100vH" }}>*/}
      {/*  */}
      {/*    <div className="p-3 pt-4 d-flex w-100 message-container" >*/}
      {/*      <Responsiveheader   title="Messages" />*/}
      {/*    </div>*/}
      {/*    <div className="container-fluid appointment-dashboard message-container">*/}
      {/*    <div*/}
      {/*          className="border-less rounded"*/}
      {/*          style={{ background: "#fff", padding: "35px", minHeight: "80vh"}}*/}
      {/*        >*/}
      {/*    <Row className="message-wrapper">*/}
      {/*            {filteredList?.length == 0 ?*/}
      {/*            <Col span={24} className="d-flex justify-content-center align-items-center">*/}
      {/*              <div className="no-msg-wrapper">*/}
      {/*                <img src="/images/icons/no-messages.svg" alt="no messages" width={120}/>*/}
      {/*                <p className="no-msg-txt">No Messages</p>*/}
      {/*              </div>*/}
      {/*            </Col>*/}
      {/*            :*/}
      {/*            <>*/}
      {/*          <Col className="left-child"  span={24}>*/}
      {/*              <Input*/}
      {/*                className="search-chat"*/}
      {/*                placeholder="Search messages"*/}
      {/*                onChange={filterBySearch}*/}
      {/*              />*/}
      {/*             {!activeUser&&   <div className="friend-list mt-3">*/}
      {/*                {filteredList?.map((item) => {*/}
      {/*                  const itemTobeShown = item?.receiverId == userId ? {...item?.senderData, isAvailable: item?.isAvailableToMessage} : {...item?.receiverData, isAvailable: item?.isAvailableToMessage};*/}
      {/*                  console.log(itemTobeShown, "itemTobeShown")*/}
      {/*                  if(itemTobeShown?._id != userId) {*/}
      {/*                  return (*/}
      {/*                    <>*/}
      {/*                      <div*/}
      {/*                        className={`chat-wrapper d-flex align-items-center ${*/}
      {/*                          (activeUser?._id == item?.receiverId || activeUser?._id == item?.senderId)*/}
      {/*                            ? "current-chat"*/}
      {/*                            : ""*/}
      {/*                        }`}*/}
      {/*                        onClick={() => {*/}
      {/*                          // console.log(item, "selected");*/}
      {/*                          setActiveUser(itemTobeShown);*/}
      {/*                        }}*/}
      {/*                      >*/}
      {/*                        {itemTobeShown?.profilePic ? (*/}
      {/*                          <img*/}
      {/*                            src={itemTobeShown?.profilePic}*/}
      {/*                            width={40}*/}
      {/*                            style={{*/}
      {/*                              borderRadius: "50%",*/}
      {/*                              marginRight: "10px",*/}
      {/*                            }}*/}
      {/*                            height={40}*/}
      {/*                            className="br-25 caregiver-img"*/}
      {/*                          />*/}
      {/*                        ) : (*/}
      {/*                          <img*/}
      {/*                            src="/images/icons/Profile.png"*/}
      {/*                            className="caregiver-img"*/}
      {/*                            width={35}*/}
      {/*                            height={35}*/}
      {/*                            style={{*/}
      {/*                              border: "1px solid #c4c2c2",*/}
      {/*                              padding: "3px",*/}
      {/*                              borderRadius: "25px",*/}
      {/*                              marginRight: "10px",*/}
      {/*                            }}*/}
      {/*                          />*/}
      {/*                        )}*/}
      {/*                        <div className="list-item">*/}
      {/*                          <p className="list-person-name">*/}
      {/*                            {itemTobeShown?.firstName*/}
      {/*                              ?.charAt(0)*/}
      {/*                              .toUpperCase() +*/}
      {/*                              itemTobeShown?.firstName.slice(1)}*/}

      {/*                            {!item?.isRead && item?.unreadCount > 0 ? (*/}
      {/*                              <span className="unread-count">*/}
      {/*                                {item?.unreadCount}*/}
      {/*                              </span>*/}
      {/*                            ) : (*/}
      {/*                              ""*/}
      {/*                            )}*/}
      {/*                          </p>*/}

      {/*                          <div  style={{justifyContent:'flex-end' , display:'flex'}}>*/}
      {/*                          <p*/}
      {/*                            className="latest-message"*/}
      {/*                            style={{*/}
      {/*                              fontSize: "12px",*/}
      {/*                              overflow: 'hidden',*/}
      {/*                              whiteSpace: 'nowrap',*/}
      {/*                              width: '60%',*/}
      {/*                              fontSize: '12px',*/}
      {/*                              textOverflow: 'ellipsis'                              */}
      {/*                            }}*/}
      {/*                          >*/}
      {/*                            {item?.message}*/}
      {/*                         */}
      {/*                           */}
      {/*                          </p>*/}
      {/*                          <p style={{width:"100%" }}>*/}
      {/*                              {moment(item?.updatedAt).format("hh:mm a")}*/}
      {/*                            </p>*/}
      {/*                          </div>*/}
      {/*                         */}
      {/*                        </div>*/}
      {/*                      </div>*/}
      {/*                      {activeUser?._id !=*/}
      {/*                        item?.receiverId && (*/}
      {/*                        <hr*/}
      {/*                          className="heading-line mx-4 "*/}
      {/*                          style={{*/}
      {/*                            opacity: "30%",*/}
      {/*                            display: "flex",*/}
      {/*                            justifyItems: "center",*/}
      {/*                            marginTop: "1px",*/}
      {/*                          }}*/}
      {/*                        />*/}
      {/*                      )}*/}
      {/*                    </>*/}
      {/*                  );*/}
      {/*                  }*/}
      {/*                })}*/}
      {/*              </div>}*/}
      {/*            </Col>*/}
      {/*            <Col className="right-child" md={17}>*/}
      {/*              {activeUser && (*/}
      {/*                <>*/}
      {/*                  <div*/}
      {/*                    className=""*/}
      {/*                    style={{*/}
      {/*                      padding: "20px 10px 0 10px",*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    <div className="profile-details d-flex align-items-center">*/}
      {/*                    <ArrowLeftOutlined  onClick={()=>  setActiveUser('')}/>*/}
      {/*                      {activeUser?.profilePic ? (*/}
      {/*                        <img*/}
      {/*                          src={activeUser?.profilePic}*/}
      {/*                          width={50}*/}
      {/*                          height={50}*/}
      {/*                          style={{ borderRadius: "50%" }}*/}
      {/*                          className="br-25 caregiver-img ms-2"*/}
      {/*                        />*/}
      {/*                      ) : (*/}
      {/*                        <img*/}
      {/*                          src="/images/icons/Profile.png"*/}
      {/*                          className="caregiver-img"*/}
      {/*                          width={50}*/}
      {/*                          height={50}*/}
      {/*                          style={{*/}
      {/*                            border: "1px solid #c4c2c2",*/}
      {/*                            padding: "3px",*/}
      {/*                            borderRadius: "25px",*/}
      {/*                            marginLeft: "10px",*/}
      {/*                          }}*/}
      {/*                        />*/}
      {/*                      )}*/}
      {/*                      <p className="active-chat">*/}
      {/*                        {activeUser?.firstName}{" "}*/}
      {/*                        {activeUser?.lastName || ""}*/}
      {/*                      </p>*/}
      {/*                    </div>*/}
      {/*                    <Divider />*/}
      {/*                    <Spin spinning={messagesLoader}>*/}
      {/*                    <div className="chat-box-wrapper">*/}
      {/*                      <Row className="chat__container" ref={messageEl} onScroll={handleScroll}>*/}
      {/*                        {messages.length == 0 ?*/}
      {/*                          <Col md={24} className="d-flex justify-content-center align-items-center">*/}
      {/*                          <div className="no-msg-wrapper">*/}
      {/*                            <img src="/images/icons/no-messages.svg" alt="no messages" width={120}/>*/}
      {/*                            <p className="no-msg-txt">No Messages</p>*/}
      {/*                          </div>*/}
      {/*                        </Col>*/}
      {/*                        :*/}
      {/*                        messages?.map((message, index) => {*/}
      {/*                           const currentDate = moment().format('DD-MM-YYYY');*/}
      {/*                           const isNotSameDate = moment(message?.updatedAt).format('DD-MM-YYYY') != moment(messages[index - 1]?.updatedAt).format("DD-MM-YYYY");*/}
      {/*                           const isSameAsToday = moment(message?.updatedAt).format('DD-MM-YYYY') == currentDate;*/}
      {/*                           const updatedTime = moment(message?.updatedAt).format("hh:mm a");*/}
      {/*                           const isNotSameTime = updatedTime != moment(messages[index - 1]?.updatedAt).format("hh:mm a");*/}
      {/*                           console.log(moment(message?.updatedAt).format('DD-MM-YYYY'), moment(messages[index + 1]?.updatedAt).format("DD-MM-YYYY"), "formatterrr")*/}
      {/*                          return (*/}
      {/*                            <>*/}
      {/*                            {isNotSameDate &&*/}
      {/*                            <Col span={24}>*/}
      {/*                            <p style={{color: "black", textAlign: "center"}}>{isSameAsToday ? "Today" : moment(message?.updatedAt).format('DD-MM-YYYY')}</p>*/}
      {/*                            </Col>*/}
      {/*                            }*/}
      {/*                            {isNotSameTime &&*/}
      {/*                            <span className={`timestamp ${*/}
      {/*                                userId != message.receiverId*/}
      {/*                                  ? "right"*/}
      {/*                                  : "left"*/}
      {/*                              }`}>{updatedTime}</span>*/}
      {/*                            }*/}
      {/*                            <Col*/}
      {/*                              span={24}*/}
      {/*                              className={`message__chats ${*/}
      {/*                                userId != message.receiverId*/}
      {/*                                  ? "right"*/}
      {/*                                  : "left"*/}
      {/*                              }`}*/}
      {/*                              key={message.id}*/}
      {/*                            >*/}
      {/*                              /!* <span className="sender-time">*/}
      {/*                                  <span*/}
      {/*                                    style={{*/}
      {/*                                      opacity: "70%",*/}
      {/*                                      paddingRight: "3px",*/}
      {/*                                    }}*/}
      {/*                                  >*/}
      {/*                                    {showTime}*/}
      {/*                                  </span>*/}
      {/*                                  <b style={{ marginRight: "5px" }}>.You</b>{" "}*/}
      {/*                                </span> *!/*/}
      {/*                              <p >{message?.message}</p>*/}
      {/*                            </Col>*/}
      {/*                            </>*/}
      {/*                          );*/}
      {/*                        })}*/}
      {/*                      </Row>*/}
      {/*                      {bottomVisible &&*/}
      {/*                          <div className="bottom-scroll" style={{float: 'right'}}>*/}
      {/*                           <Button onClick={handleScrollToBottom} style={{height: '50px'}}>*/}
      {/*                            {redDotVisible ?*/}
      {/*                            <img src="/images/icons/message-down-red.svg" width="30" />*/}
      {/*                            :*/}
      {/*                             <img src="/images/icons/message-down.svg" width="30" />*/}
      {/*                            }*/}
      {/*                           </Button>*/}
      {/*                          </div>*/}
      {/*                          }*/}
      {/*                      {activeUser && (*/}
      {/*                        <div className="chat-input-message">*/}
      {/*                          {activeUser?.isAvailable ?*/}
      {/*                          <Input*/}
      {/*                            className="inputMessage"*/}
      {/*                            placeholder="Type Message..."*/}
      {/*                            value={typedMessage}*/}
      {/*                            onChange={(e) =>*/}
      {/*                              setTypedMessage(e.target.value)*/}
      {/*                            }*/}
      {/*                            onKeyDown={(e) => {*/}
      {/*                              console.log("called")*/}
      {/*                              // e.preventDefault();*/}
      {/*                              if (e.key === "Enter" && typedMessage) {*/}
      {/*                                  handleSendMessage(e)*/}
      {/*                              }*/}
      {/*                            }}*/}
      {/*                            suffix={*/}
      {/*                              <img*/}
      {/*                                src="/images/icons/send-message.svg"*/}
      {/*                                width={40}*/}
      {/*                                height={40}*/}
      {/*                                onClick={handleSendMessage}*/}
      {/*                              />*/}
      {/*                            }*/}
      {/*                          />*/}
      {/*                          :*/}
      {/*                          <p className="not-available-txt">You can't message, as appointment is completed, Thank you.</p>*/}
      {/*                          }*/}
      {/*                        </div>*/}
      {/*                      )}*/}
      {/*                    </div>*/}
      {/*                    </Spin>*/}
      {/*                  </div>*/}
      {/*                </>*/}
      {/*              )}*/}
      {/*            </Col>*/}
      {/*            </>*/}
      {/*            }*/}
      {/*          </Row>*/}
      {/*          </div>*/}
      {/*          </div>*/}
      {/*    </div>):( */}
      <div
        className="message-main"
        style={{ background: "#F7F9FA", minHeight: "100vH" }}
      >
        <div className="container-fluid appointment-dashboard message-container">
          <div className="pt-5 d-flex profile-details-wrapper">
            <Sidebar />
            <div className="mx-4 w-100 message-inner-row">
              <AppointmentNavBar />
              <div
                className={`border-less rounded card-div message-card-div ${
                  activeUser?.firstName ? "user-active" : ""
                }`}
                style={{
                  background: "#fff",
                  padding: "35px",
                  minHeight: "75vh",
                }}
              >
                <Spin spinning={conversationsLoader}>
                  <Row className="message-wrapper">
                    {conversationsList?.length == 0 ? (
                      <Col
                        md={24}
                        className="d-flex justify-content-center no-message-center align-items-center"
                      >
                        <div className="no-msg-wrapper">
                          <img
                            src="/images/icons/bird-icon.svg"
                            alt="no messages"
                            width={120}
                          />
                          <p className="no-msg-txt">No Messages</p>
                        </div>
                      </Col>
                    ) : (
                      <>
                        <Col
                          className="left-child"
                          md={24}
                          xl={6}
                          lg={24}
                          xs={24}
                          style={
                            isMobile && activeUser
                              ? { display: "none" }
                              : { display: "block" }
                          }
                        >
                          {/* <Input
                                                    className="search-chat"
                                                    placeholder="Search messages"
                                                    onChange={filterBySearch}
                                                /> */}
                          <div className="friend-list">
                            {conversationsList?.length > 0 &&
                              conversationsList?.map((obj, index) => {
                                const item = obj.elem;
                                const { unreadCount, lastMessage, userData } =
                                  obj;
                                console.log(obj?.userData, "unreadCount");
                                // const itemTobeShown =
                                //   item?.receiverId == userId
                                //     ? {
                                //         ...item?.senderData,
                                //         isAvailable: item?.isAvailableToMessage,
                                //       }
                                //     : {
                                //         ...item?.receiverData,
                                //         isAvailable: item?.isAvailableToMessage,
                                //       };
                                console.log(item, "attributes");
                                // const { caregiverData } = item.attributes;
                                // console.log(itemTobeShown, "itemTobeShown");
                                // if (caregiverData?.userId != userId) {
                                return (
                                  <>
                                    <div
                                      className={`chat-wrapper d-flex align-items-center ${
                                        activeUserData?.userId ==
                                        userData?.userId
                                          ? // ||
                                            // activeUser?._id == item?.senderId
                                            "current-chat"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setActiveUser(item);
                                        setActiveUserData(userData);
                                        setIsNewMessageAdded(false);
                                        const clonedConversations = [
                                          ...conversationsList,
                                        ];
                                        clonedConversations[
                                          index
                                        ].unreadCount = 0;
                                        setConversationsList(
                                          clonedConversations
                                        );
                                      }}
                                    >
                                      {userData?.profilePic ? (
                                        <img
                                          alt=""
                                          src={userData?.profilePic}
                                          width={40}
                                          style={{
                                            borderRadius: "50%",
                                            marginRight: "10px",
                                          }}
                                          height={40}
                                          className="br-25 caregiver-img"
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src="/images/icons/Profile.png"
                                          className="caregiver-img"
                                          width={35}
                                          height={35}
                                          style={{
                                            border: "1px solid #c4c2c2",
                                            padding: "3px",
                                            borderRadius: "25px",
                                            marginRight: "10px",
                                          }}
                                        />
                                      )}
                                      <div className="list-item-wrapper d-flex justify-content-between">
                                        <div className="list-item">
                                          <p className="list-person-name position-relative">
                                            {/* {itemTobeShown?.firstName
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            itemTobeShown?.firstName.slice(
                                              1
                                            )}{" "} */}
                                            {userData?.name}
                                            {unreadCount > 0 ? (
                                              <span className="unread-count unread-count-custom">
                                                {unreadCount}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </p>
                                          <p
                                            className="latest-message"
                                            style={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              width: "150px",
                                              fontSize: "12px",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {lastMessage?.items?.length > 0 &&
                                              lastMessage?.items[0]?.body}
                                            {/* {item?.message.length > 0
                                  ? item?.message.slice(
                                      0,
                                      Math.floor(item?.message.length / 2)
                                    ) + "..."
                                  : ""} */}
                                          </p>
                                        </div>
                                        <span
                                          className="updated-time d-flex justify-content-end"
                                          style={{ width: "100%" }}
                                        >
                                          {moment(item?.dateUpdated)
                                            // .utc()
                                            .format("hh:mm a")}
                                        </span>
                                      </div>
                                    </div>
                                    {/* {activeUser?.caregiverData?.userId !=
                                    item?.caregiverData?.userId && ( */}
                                    <hr
                                      className="heading-line mx-4 "
                                      style={{
                                        opacity: "30%",
                                        display: "flex",
                                        justifyItems: "center",
                                        marginTop: "1px",
                                      }}
                                    />
                                    {/* )} */}
                                  </>
                                );
                                // }
                              })}
                          </div>
                        </Col>
                        <Col
                          className="right-child message-change-mobile-only right-child-mb-0"
                          md={24}
                          lg={24}
                          xl={17}
                          xs={24}
                          style={
                            !isMobile && activeUser ? { marginTop: "-30px" } : {marginTop:0}
                          }
                        >
                          {activeUser ? (
                            <>
                              <div
                                className="message-main-box"
                                style={{
                                  padding: "20px 10px 0 10px",
                                  position: "relative",
                                }}
                              >
                                {isMobile && (
                                  <Button
                                    onClick={() => {
                                      setActiveUser(null);
                                    }}
                                    className="back-btn-message position-absolute"
                                  >
                                    <ArrowLeftOutlined />
                                  </Button>
                                )}
                                <div className="profile-details d-flex align-items-center">
                                  {activeUserData.profilePic ? (
                                    <img
                                      alt=""
                                      src={activeUserData.profilePic}
                                      width={50}
                                      height={50}
                                      style={{ borderRadius: "50%" }}
                                      className="br-25 caregiver-img ms-2"
                                    />
                                  ) : (
                                    <img
                                      alt=""
                                      src="/images/icons/Profile.png"
                                      className="caregiver-img"
                                      width={50}
                                      height={50}
                                      style={{
                                        border: "1px solid #c4c2c2",
                                        padding: "3px",
                                        borderRadius: "25px",
                                        marginLeft: "10px",
                                      }}
                                    />
                                  )}
                                  <p className="active-chat">
                                    {activeUserData.name}
                                    {/* {activeUser?.firstName}{" "}
                                  {activeUser?.lastName || ""} */}
                                  </p>
                                </div>
                                <Divider />
                                <Spin spinning={messagesLoader}>
                                  <div className="chat-box-wrapper">
                                    <Row
                                      className="chat__container"
                                      ref={messageEl}
                                      onScroll={handleScroll}
                                    >
                                      {twillioMsgList.length == 0 ? (
                                        <Col
                                          md={24}
                                          xs={24}
                                          className="d-flex justify-content-center align-items-center"
                                        >
                                          <div className="no-msg-wrapper">
                                            <img
                                              src="/images/icons/bird-icon.svg"
                                              alt="no messages"
                                              width={120}
                                            />
                                            <p className="no-msg-txt">
                                              No Messages
                                            </p>
                                          </div>
                                        </Col>
                                      ) : (
                                        twillioMsgList?.map(
                                          (message, index) => {
                                            const currentDate =
                                              moment().format("DD-MM-YYYY");
                                            const isNotSameDate =
                                              moment(
                                                message?.dateCreated
                                              ).format("DD-MM-YYYY") !=
                                              moment(
                                                twillioMsgList[index - 1]
                                                  ?.dateCreated
                                              ).format("DD-MM-YYYY");
                                            const isSameAsToday =
                                              moment(
                                                message?.dateCreated
                                              ).format("DD-MM-YYYY") ==
                                              currentDate;
                                            const updatedTime = moment(
                                              message?.dateCreated
                                            )
                                              // .utc()
                                              .format("hh:mm a");
                                            const isNotSameTime =
                                              updatedTime !=
                                              moment(
                                                twillioMsgList[index - 1]
                                                  ?.dateCreated
                                              )
                                                // .utc()
                                                .format("hh:mm a");
                                            return (
                                              <>
                                                {isNotSameDate && (
                                                  <Col md={24} xs={24}>
                                                    <p
                                                      style={{
                                                        color: "black",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {isSameAsToday
                                                        ? "Today"
                                                        : moment(
                                                            message?.updatedAt
                                                          ).format(
                                                            "DD-MM-YYYY"
                                                          )}
                                                    </p>
                                                  </Col>
                                                )}
                                                <Col
                                                  md={24}
                                                  xs={24}
                                                  className={`message__chats ${
                                                    userId == message.author
                                                      ? "right"
                                                      : "left"
                                                  }`}
                                                  key={message.sid}
                                                  id={message.sid}
                                                >
                                                  {/* <span className="sender-time">
                                      <span
                                        style={{
                                          opacity: "70%",
                                          paddingRight: "3px",
                                        }}
                                      >
                                        {showTime}
                                      </span>
                                      <b style={{ marginRight: "5px" }}>.You</b>{" "}
                                    </span> */}
                                                  <p>{message?.body}</p>
                                                </Col>
                                                {isNotSameTime && (
                                                  <span
                                                    className={`timestamp ${
                                                      userId == message.author
                                                        ? "right"
                                                        : "left"
                                                    }`}
                                                  >
                                                    {updatedTime}
                                                  </span>
                                                )}
                                              </>
                                            );
                                          }
                                        )
                                      )}
                                    </Row>
                                    {bottomVisible && (
                                      <div
                                        className="bottom-scroll"
                                        style={{ float: "right" }}
                                      >
                                        <Button
                                          onClick={handleScrollToBottom}
                                          style={{ height: "50px" }}
                                        >
                                          {redDotVisible ? (
                                            <img
                                              alt=""
                                              src="/images/icons/message-down-red.svg"
                                              width="30"
                                            />
                                          ) : (
                                            <img
                                              alt=""
                                              src="/images/icons/message-down.svg"
                                              width="30"
                                            />
                                          )}
                                        </Button>
                                      </div>
                                    )}
                                    {activeUser && (
                                      <div className="chat-input-message">
                                        {isAvailableToMessage ? (
                                          <Input
                                            className="inputMessage"
                                            placeholder="Type Message..."
                                            value={typedMessage}
                                            onChange={(e) =>
                                              setTypedMessage(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                              console.log("called");
                                              // e.preventDefault();
                                              if (
                                                e.key === "Enter" &&
                                                typedMessage
                                              ) {
                                                handleSendMessage(e);
                                              }
                                            }}
                                            suffix={
                                              <img
                                                alt=""
                                                src="/images/icons/send-message.svg"
                                                width={40}
                                                height={40}
                                                onClick={handleSendMessage}
                                                style={
                                                  typedMessage?.length == 0
                                                    ? { opacity: 0.6 }
                                                    : {}
                                                }
                                              />
                                            }
                                          />
                                        ) : (
                                          <p className="not-available-txt">
                                            Messaging is only available for
                                            active appointments.
                                          </p>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </Spin>
                              </div>
                            </>
                          ) : !isMobile &&(
                            <div
                              className="d-flex no-user-selected-div justify-content-center align-items-center"
                              style={{ minHeight: "100%" }}
                            >
                              No User Selected
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                </Spin>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
      {/*)}*/}
    </>
  );
}

export default Message;

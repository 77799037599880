import React from "react";
import { Button } from "antd";
import "./styles/EmailSuccessPopup.less";
import { useNavigate } from "react-router-dom";

const EmailSuccessPopup = () => {
  const navigate = useNavigate();
  return (
    <div className="email-container">
      <img src="/images/icons/mail_logo.svg" alt="email card" />
      <div className="reqgenaratedMsg">
        <h1>
          Your request has been <span>generated.</span>
        </h1>
        <p className="mt-4 color-secondary fs-6">
          CERTN will notify you of a successful background check via email,{" "}
          <br />
          at which point you can complete your registration on the app.
          {/* We will notify you after a successful background <br></br> check. Look for an email from CERTN. */}
        </p>
      </div>
      <div>
        <Button
          className="mt-3 btn-black auth-action-btn mb-2"
          onClick={() => navigate("/caregiver/signin")}
        >
          <img src="/images/icons/done_btn_with_arrow.svg" alt="done button" />
        </Button>
      </div>
    </div>
  );
};

export default EmailSuccessPopup;

import React, { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import OverrideMonth from "../Modal/common/OverrideMonth";
import "./MySchedule.less";
const orientation = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

const MonthlyCalneder = ({
  active,
  appointmentList,
  setSelectedDayAppo,
  selectedDayAppo,
  userMode,
  onSelectDatePicker,
  // handleArrow,
  Availability,
  getavailability,
  getAppointments,
}) => {
  console.log(appointmentList, "appointmentList");
  const [value, setValue] = useState(null);
  const [modalActive, setmodalActive] = React.useState(false);
  const [listItems, setListItems] = useState([]);
  console.log(Availability, "Availability");
  const occupiedArr = Availability.map((i) => {
    console.log(i, "availability");
    const splittedDate = i.date.split("T");
    return moment(splittedDate[0]).format("DD-MM-YYYY");
  });
  const onChange = (e) => {
    setValue(e);
    const dayAppointment = appointmentList?.list?.find((listing) => {
      console.log(listing, "listinglistinglisting");
      const splittedDate = listing.date.split("T");
      return (
        moment(e).format("DD/MM/YYYY") ===
        moment(splittedDate[0]).format("DD/MM/YYYY")
      );
      // if (e.getDay() === new Date(listing.date).getDay()) {
      //   return (
      //     e.getDay() === new Date(listing.date).getDay() &&
      //     e.getMonth() === new Date(listing.date).getMonth() &&
      //     e.getDate() === new Date(listing.date).getDate()
      //   );
      // } else {
      // }
    });
    // dayAppointment && setSelectedDayAppo([...dayAppointment?.appointments]);

    console.log(dayAppointment, "ererer");
    onSelectDatePicker(e);
  };

  const tileContent = ({ date, view }) => {
    const dayAppointment = appointmentList?.list?.find((listing) => {
      const splittedDate = listing.date?.split("T");
      console.log(
        moment(splittedDate[0]).isSameOrAfter(moment()),
        "datelisting",
        splittedDate[0],
        moment(new Date()).format("DD/MM/YYYY")
      );
      if (
        moment(splittedDate[0]).isSameOrAfter(
          moment(new Date()).format("YYYY-MM-DD")
        )
      ) {
        // if(moment().format("DD/MM/YYYY"))
        return (
          moment(date).format("DD/MM/YYYY") ===
          moment(splittedDate[0]).format("DD/MM/YYYY")
        );
        // date.getDay() === new Date(listing.date).getDay() &&
        // date.getMonth() === new Date(listing.date).getMonth() &&
        // date.getDate() === new Date(listing.date).getDate()
        // );
      }
    });
    console.log(dayAppointment, "dayAppointment");
    //  console.log(userMode,"userMode")
    return (
      <div className="day-appointments">
        {dayAppointment?.appointments?.map(() => (
          <div className="greenCircle"></div>
        ))}
      </div>
    );
  };

  // const handleButtonsAction = ({ action, activeStartDate, value, view }) => {

  //    console.log(action, "activeeee")
  //   if (action === "next") {
  //     const current = new Date(value.getFullYear(), value.getMonth() + 1, 1);
  //     setValue(current);
  //   } else if (action === "prev2") {
  //     const current = new Date(value.getFullYear(), value.getMonth() - 1, 1);
  //     setValue(current);
  //   }
  // };

  const isTileDisabled = ({ activeStartDate, date, view }) => {
    const checkAppointment = listItems?.find((listing) => {
      return (
        date.getDay() === new Date(listing.date).getDay() &&
        date.getMonth() === new Date(listing.date).getMonth() &&
        date.getDate() === new Date(listing.date).getDate()
      );
    });
    if (checkAppointment) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (appointmentList?.list?.length > 0) {
      setListItems((listItems) => [...listItems, ...appointmentList.list]);
    }
    if (Availability?.length > 0) {
      setListItems((listItems) => [...listItems, ...Availability]);
    }
  }, [Availability, appointmentList.list]);

  React.useEffect(() => {
    const payload = {
      type: userMode,
      startDate:
        moment().startOf("month").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
      endDate:
        moment()
          .add(3, "months")
          .endOf("month")
          .format("yyyy-MM-DDT")
          .toString() + "00:00:00.000+0000",
    };
    getavailability(payload);
    getAppointments(payload);
  }, []);

  React.useEffect(() => {
    if (
      value &&
      value.getMonth() === new Date(Date.now()).getMonth() + 3 &&
      userMode === "client"
    ) {
      setmodalActive(true);
    }
  }, [value]);

  const calendarStyle = () => {
    return {
      style: {
        backgroundColor: "red", //this works
        color: "green", //but why doesn't this work?
      },
    };
  };

  console.log(value, "Valuessss");
  const maxDate = moment().add(3, "months").endOf("month").toDate();
  return (
    <div className="calender">
      {active === orientation.WEEKLY ? (
        <div>date Schedule</div>
      ) : (
        <Calendar
          // hover={new Date()}
          locale="en-US"
          className="calender"
          onChange={onChange}
          // activeStartDate={value}
          value={value}
          // defaultValue={value}
          tileClassName={({ date, view }) => {
            // date will return every date visible on calendar and view will view type (eg. month)
            if (new Date(date).getDay() === new Date().getDay()) {
              return calendarStyle;
            }
          }}
          minDate={moment().toDate()}
          maxDate={maxDate}
          tileContent={(props) => tileContent(props)}
          // prevLabel={
          //  null
          // }

          // prev2Label={
          //   // new Date(Date.now()).getMonth() === value.getMonth() ? null : (
          //     <LeftOutlined
          //     // onClick={() => handleArrow( new Date(value.getFullYear(), value.getMonth() - 1, 1))}
          //      />
          //   // )
          // }
          // next2Label={null}
          // nextLabel={
          //   // value.getMonth() === new Date(Date.now()).getMonth() + 3? null : (
          //     <RightOutlined
          //     // onClick={() =>{
          //     //   handleArrow( new Date(value.getFullYear(), value.getMonth() +1, 1))
          //     //   // if(){
          //     //   //   setmodalActive(true)

          //     //   // }

          //     // }}
          //     />
          //   // )
          // }
          // onActiveStartDateChange={handleButtonsAction}
          tileDisabled={({ date, view }) => {
            if (userMode == "client") {
              return false;
            }
            if (userMode == "caregiver") {
              const checkAppointment = listItems?.find((listing) => {
                const splittedDate = listing.date?.split("T");
                return (
                  moment(date).format("DD/MM/YYYY") ===
                  moment(splittedDate[0]).format("DD/MM/YYYY")
                  // date.getDay() === new Date(listing.date).getDay() &&
                  // date.getMonth() === new Date(listing.date).getMonth() &&
                  // date.getDate() === new Date(listing.date).getDate()
                );
              });
              if (checkAppointment) {
                return false;
              }
              return true;
            }
            console.log(occupiedArr, "occupiedArr");
            const currentDate = moment(date).format("DD-MM-YYYY");
            // console.log(Availability, currentDate,"currentdate")
            return !occupiedArr.includes(currentDate);
          }}
          // view={"month"}
          minDetail={"month"}
        />
      )}

      <OverrideMonth open={modalActive} onClose={() => setmodalActive(false)} />
    </div>
  );
};

export default MonthlyCalneder;

import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import TransformImgWrapper from "../../../common/TransformComponent/TransformComponent";
const { Text } = Typography;
const ReceiptModal = (props) => {
    const userMode = useSelector((state) => state.loginData?.userMode);
    const {
      receiptpopup,
      handleReceiptModalClose,
      receiptData,
      handleRejected,
      handleApproved,
    } = props;
    return (
      <Modal
        className="info-modal-container "
        centered
        width={390}
        header={null}
        footer={null}
        open={receiptpopup}
        onOk={handleReceiptModalClose}
        onCancel={handleReceiptModalClose}
      >
  
  <div className="Close-container">
  <CloseCircleOutlined   style={{fontSize:'30px'}}      onClick={() => {
                
                handleReceiptModalClose('activeReceipt');
              }}/>
        </div>
  
        <div className="Expense_receipt">
          <div className="text-center ">
            <Text className="expenseText">Expense</Text>
            <div className="ExpenseImg">
          <TransformImgWrapper>
          <div style={{ cursor: 'pointer'}}>
              <img
                src={receiptData.image}
                className="ExpenseImg_image"
                alt="image"
              />
              </div>
              </TransformImgWrapper>
            </div>
            
          </div>
          {receiptData.status == 0 && userMode === "client" && (
            <Row gutter={24}>
              <Col span={10}>
              <Button
                  // htmlType="submit"
                  onClick={() => handleRejected(receiptData,"activeReceipt","Reject")}
                  className=" mx-auto expenseReceipt_reject"
                  shape="round"
                  type="primary"
                  size="large"
                >
                  Reject
                </Button>
              </Col>
              <Col span={14}>
              
  
                <Button
                  // htmlType="submit"
                  // onClick={handleUnsuccessModalClose}
                  className="btn-black mx-auto expenseReceipt_approve"
                  shape="round"
                  type="primary"
                  size="large"
                  onClick={() => handleApproved(receiptData,"activeReceipt","Payment")}
                >
                  Approve
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    );
  };
  
   export default ReceiptModal
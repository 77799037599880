import moment from "moment";
// Define the start and end times

export const getTimeSlots = () => {
  const startTime = moment().startOf("day").utc(true); // Start at the beginning of the current day
  const endTime = moment().endOf("day").utc(true); // End at the end of the current day

  // Array to store the intervals
  let intervals = [];

  // Loop through each hour of the day
  let currentTime = startTime.clone();
  while (currentTime.isBefore(endTime)) {
    // Generate intervals within the current hour
    let intervalStart = currentTime.clone();
    let intervalEnd = currentTime.clone().add(30, "minutes");

    // Format the intervals as needed (e.g., "HH:mm" for 24-hour format)
    intervals.push({
      start: intervalStart,
      end: intervalEnd,
    });

    // Move to the next hour
    currentTime.add(1, "hour");
  }
  return intervals;
  // Output the intervals
  // console.log(intervals);
};

export function convertToTwoDigits(num) {
  // Convert the number to a string and pad with leading zeros if necessary
  return num.toString().padStart(2, "0");
}

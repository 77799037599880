import { useEffect, useState } from "react";
import "./settings.less";
import CardDetails from "./cardDetails";
import EmptyCard from "./cardEmpty";
import AddCardDetails from "./addCardDetails";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAlert } from "../../../redux/alert/alert.action";
import Responsiveheader from "../../../components/Responsiveheader";
import axios from "axios";
const BankDetails = ({ tabValue }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  var token;
  token = localStorage.getItem("token");
  if (!token) {
    var data = localStorage.getItem("persist:root");
    if (data) {
      let data1 = JSON.parse(data);
      let data2 = JSON.parse(data1.loginData);
      token = data2.user.accessToken;
    }
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState([]);
  const [showCards, setShowCards] = useState(true);
  const [addCardPopUp, setAddCardPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCardDetails(token).then((res) => setCardDetails(res));
  }, []);
  const fetchCardDetails = async (token) => {
    if (token !== "") {
      try {
        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/list-card`,
          {},
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        );
        // console.log(response.data.output.list,"fetch api response");
        return response.data.output.list;
      } catch (err) {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      } finally {
        setLoading(false);
      }
    }
  };
  const deleteSingleCardDetails = async (token, _id) => {
    if (token !== "") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/delete-card`,
          { id: _id },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        );
        return response;
      } catch (err) {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      }
    }
  };
  const addSingleCard = async (token, res) => {
    if (token !== "") {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/user/add-card`,
          { token: res.token.id },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        );
        console.log("single card details", response.data);
        if (response.data.status === 200) {
          fetchCardDetails(token).then((res) => setCardDetails(res));
        }
        return response;
      } catch (err) {
        if (
          err?.response?.data?.status >= 400 &&
          err?.response?.data?.status !== 401
        ) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
        }
      }
    }
  };

  console.log(showCards, "", cardDetails);
  if (!loading) {
    return cardDetails &&
      cardDetails.length == 0 &&
      showCards &&
      !addCardPopUp ? (
      <EmptyCard setShowCards={setShowCards} />
    ) : showCards && cardDetails?.length > 0 && !addCardPopUp ? (
      <CardDetails
        deleteSingleCardDetails={deleteSingleCardDetails}
        setShowCards={setShowCards}
        fetchCardDetails={fetchCardDetails}
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
      />
    ) : (
      <AddCardDetails
        addSingleCard={addSingleCard}
        setShowCards={setShowCards}
        fetchCardDetails={fetchCardDetails}
        setCardDetails={setCardDetails}
        tabValue={tabValue}
        setAddCardPopUp={() => {
          setShowCards(true);
          setAddCardPopUp(false);
        }}
      />
    );
  }
};
export default BankDetails;

import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const AppoinmentCard = ({
  fullName,
  startTime,
  endTime,
  appointmentId,
  handleAppontment,
  weekStyles,
}) => {
  const userMode = useSelector((state) => state.loginData?.userMode);
  const start = moment(startTime).zone(0).format("hh:mm a");
  const end = moment(endTime).zone(0).format("hh:mm a");

  //  console.log(convertUTCDateToLocalDate(startTime), convertUTCDateToLocalDate(endTime),

  //   "convert data......"
  //  )
  return (
    <div
      className={
        weekStyles
          ? "appoinment_card"
          : userMode === "client"
          ? "appoinment_card_border-month"
          : "appoinment_card-month"
      }
      onClick={() => {
        console.log(appointmentId, "appointmentId");
        handleAppontment(appointmentId);
      }}
    >
      <div className="timing">
        <img src="/images/icons/green-circle.svg" alt="green-circle" />
        <span className="timing_date">{`${start} - ${end}`}</span>
      </div>
      <div className="contact_details">
        <span>{`Appoinment with `}</span>
        <span className="contact_person">{fullName}</span>
      </div>
    </div>
  );
};

export default AppoinmentCard;

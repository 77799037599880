import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/alert/alert.action";
import axios from "axios";
import closeicon from "../../Images/crossicon.svg";
import { useNavigate } from "react-router-dom";
import { CLEAR_DATA, CLEAR_ALLDATA } from "../../redux/auth/auth.type";
import "./deleteaccount.less";
import { CloseCircleOutlined } from "@ant-design/icons";
function DeleteAccount({ setHandleDelete, handleDelete, setLoading }) {
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disableYes, setDisableYes] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const handleFormChange = () => {
    const areTruthy = Object.values(form.getFieldsValue()).every(
      (value) => value
    );

    const hasErrors =
      !areTruthy ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    console.log(hasErrors, "haserrors");
    setDisableYes(hasErrors);
  };
  const deleteAcc = async () => {
    console.log("deleteAcc");
    setLoading(true);
    setHandleDelete(false);
    if (token !== "") {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/user/delete-account`,
          {
            password: password,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          console.log("response in deleteAcc", response);
          if (response.data.status === 200) {
            dispatch(
              setAlert({
                alertType: "success",
                alertMsg: response?.data?.message,
                alertOpen: true,
              })
            );
            console.log(response, "success response");
            setInterval(() => {
              dispatch({ type: CLEAR_ALLDATA });
              localStorage.clear();

              navigate("/");
              window.location.reload();
            }, 2000);
          } else if (response.data.status >= 400) {
            console.log(response, "response in response");
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          if (err?.response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
            if (err.response.data.status === 401) {
              dispatch(CLEAR_ALLDATA);
              localStorage.clear();
              navigate("/");
              window.location.reload();
            }
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        });
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      centered
      style={{ minWidth: isMobile ? "90%" : "550px" }}
      header={null}
      footer={null}
      open={handleDelete}
      onCancel={() => setHandleDelete(false)}
      className="deletemodal"
      closable={false}
    >
      <div className="crossImage" style={{ marginBottom: "20px" }}>
        <img alt=""
          src={closeicon}
          className="text-center"
          style={{ display: "flex", justifyContent: "center" }}
        />
      </div>

      <h2
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "30px",
          marginLeft: "38px",
          fontWeight: "bold",
        }}
        className="deleteheading"
      >
        Are you sure you want to delete this account?
      </h2>
      <Form onFieldsChange={handleFormChange}>
        <Form.Item name="password" style={{ Height: "10%" }}>
          <Input.Password
            placeholder="Enter password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item name="confirmpassword">
          <Input.Password
            placeholder="Confirm password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      <div className="confirmButtons common-button-yes-no">
        <Button className="yes-button" disabled={!(!disableYes && confirmPassword === password)} onClick={() => deleteAcc()}>
          Yes
        </Button>
        <Button className="no-button" onClick={() => {setHandleDelete(false);}}>
          No
        </Button>
      </div>
    </Modal>
  );
}
export default DeleteAccount;

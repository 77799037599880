
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;
const ExpenseApprove = (props) => {
    const { successpopup, handleSuccessModalClose } = props;
    return (
      <Modal
        className="info-modal-container"
        centered
        width={390}
        header={null}
        footer={null}
        open={successpopup}
        onOk={handleSuccessModalClose}
        onCancel={handleSuccessModalClose}
        closable 
        closeIcon={<CloseCircleOutlined />}
      >
        {/* <div className="Close-container">
        <CloseOutlined      onClick={() => {
            handleSuccessModalClose();
            // navigate("/client/appointment/booking-confirmation");
          }}
              
              />
        </div> */}
        <div className="image-container">
          <img src="/images/icons/info-group.svg" className="text-center" />
        </div>
        
        <h3 className="mt-3 fw-semibold fs-4 text-center">Payment Successful</h3>
        <p className="text-center mt-3">
        We have Successfully put a hold on your credit card
        </p>
        <Button
          // htmlType="submit"
          onClick={() => {
            handleSuccessModalClose();
            // navigate("/client/appointment/booking-confirmation");
          }}
          className="btn-black mx-auto auth-action-btn mt-3"
          shape="round"
          type="primary"
          size="large"
        >
          Done
        </Button>
      </Modal>
    );
  };

  export default ExpenseApprove
import {
  TOGGLE_CHHOSE_RELATIVE_MODAL,
  TOGGLE_CONFIRM_RELATIVE_MODAL,
  TOGGLE_REDIRECT_APP_MODAL,
} from "./toggleModal.types";

const INITIAL_STATE = {
  chooseRelativeModal: false,
  confirmRelativeModal: false,
  redirectAppModal: false,
  toggle: false,
  sidebarActiveKey: ["1"],
};

const reducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case TOGGLE_CHHOSE_RELATIVE_MODAL:
      return {
        ...state,
        chooseRelativeModal: action.payload,
      };
    case TOGGLE_CONFIRM_RELATIVE_MODAL:
      return {
        ...state,
        confirmRelativeModal: action.payload,
      };
    case TOGGLE_REDIRECT_APP_MODAL:
      return {
        ...state,
        redirectAppModal: action.payload,
      };
    case "OPEN":
      return {
        ...state,
        toggle: action.payload,
      };
    case "CLOSE":
      return {
        ...state,
        toggle: action.payload,
      };
    case "SIDEBAR":
      return {
        ...state,
        sidebarActiveKey: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

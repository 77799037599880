import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import { setAlert } from "../../../../redux/alert/alert.action";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import alllanguages from "../../../../common/helper/languages";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../redux/auth/auth.action";
import "./workdetail.style.less";
import { updatedLanguageOptions } from "../../../../utils";

function WorkDetails({ accessToken, save, userDetails }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [fileName, setFileName] = useState("");
  const [englishLevel, setEnglishLevel] = useState("");
  // const [, setExperienceLevel] = useState('')
  // const [, setAnimal] = useState('')
  // const [, setQualification] = useState([])
  // const [, setQualificationImage] = useState('')
  // const [, setLanguages] = useState([])
  const [otherLan, setOtherLan] = useState(false);
  const [otherLanVal, setOtherLanval] = useState("");
  const [loading, setLoading] = useState(false);
  const [languageOptions, setLangOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails !== undefined) {
      const langArr = [...updatedLanguageOptions];
      if (userDetails.languages?.length > 0) {
        userDetails.languages.forEach((lang) => {
          const foundLang = langArr.find(
            (newLang) => newLang.toLowerCase() == lang.toLowerCase()
          );
          if (!foundLang) {
            langArr.push(lang);
          }
        });
      }
      langArr.push("Other Languages");
      setLangOptions(langArr);
      form.setFieldValue(
        "englishRate",
        userDetails?.englishRate !== "" ? userDetails?.englishRate : null
      );
      form.setFieldValue(
        "languages",
        userDetails?.languages !== "" ? userDetails?.languages : null
      );
      form.setFieldValue(
        "experience",
        userDetails?.experience !== "" ? userDetails?.experience : null
      );
      form.setFieldValue(
        "animals",
        userDetails?.animals !== "" ? userDetails?.animals : null
      );
      form.setFieldValue(
        "qualification",
        userDetails?.qualification !== "" ? userDetails?.qualification : null
      );
      form.setFieldValue(
        "qualificationImage",
        userDetails?.qualificationImage !== ""
          ? userDetails?.qualificationImage
          : null
      );
      setEnglishLevel(
        userDetails?.englishRate !== "" ? null : userDetails?.englishRate
      );
      // setLanguages(userDetails?.languages !== '' ? userDetails?.languages : null)
      // setExperienceLevel(userDetails?.experience !== '' ? userDetails?.experience : null)
      // setAnimal(userDetails?.animals !== '' ? userDetails?.animals : null)
      // setQualification(userDetails?.qualification !== '' ? userDetails?.qualification : null)
      // setQualificationImage(userDetails?.qualificationImage !== '' ? userDetails?.qualificationImage : null)
      const name = userDetails?.qualificationImage?.split("/");
      if (name?.length > 0 && name[4]) {
        setFileName(name[4]?.slice(name[4]?.length - 15));
      }
      handleFormChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleFormChange = () => {
    const valuesArr = Object.values(
      form.getFieldsValue(["englishRate", "experience", "animals"])
    );
    const filteredArr = valuesArr.filter((m) => !m);
    const isNextDisabled = filteredArr?.length > 0 ? true : false;
    setDisabledSave(isNextDisabled);
  };
  const handleLanguages = () => {
    const languagesVal = form.getFieldValue("languages");
    if (languagesVal.includes("Other Languages")) {
      console.log("setting language to");
      setOtherLan(true);
    } else {
      setOtherLan(false);
    }
  };

  const englishRateData = [
    "Mother Tongue",
    "Fluent",
    "Proficient",
    "Conversational",
    "Basic",
  ];

  const animalData = ["All", "Cats", "Dogs", "None"];

  const experienceData = [
    "No experience",
    "1-3 years",
    "4-6 years",
    "7-9 years",
    "10+ years",
  ];
  const qualificationData = [
    "Occupational Therapy Certification",
    "Personal Support Worker",
    "Agency Induction Training",
    "Social Worker",
    "Registered Nurse",
    "Nursing",
    "University Degree (Health & Social Care)",
  ];

  const onFinish = (values) => {
    if (otherLanVal != "") {
      var lang = form.getFieldValue("languages");
      const othersplit = otherLanVal.split(",");
      lang = [...lang, ...othersplit];
      const finalArr = lang.filter((val) => val != "Other Languages");
      console.log(finalArr, "finalArr");
      form.setFieldValue("languages", finalArr);
      console.log(values, "final values");
      values = { ...values, languages: finalArr };
      console.log(values, "final values");
    }
    save(values);
  };

  const handleImageUpload = (e) => {
    setLoading(true);
    const [file] = e.target.files;
    if (file) {
      // setFileName(file?.name.slice(file?.name?.length - 25))
      setFileName(file?.name);
      let formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("path", "qualification");
      console.log("formData", formData);
      if (accessToken != "") {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/user/upload-image`,
            formData,
            {
              headers: {
                accessToken: accessToken,
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then((response) => {
            console.log("response", response);
            // console.log("response", response)
            if (response.data.status === 200) {
              setLoading(false);
              // setQualificationImage(response.data.output.imageUrl1.location)
              form.setFieldValue(
                "qualificationImage",
                response.data.output.imageUrl1.location
              );
              handleFormChange();
            } else if (response.data.status >= 400) {
              // setDisabledSave(false)
              setLoading(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: response?.data?.error?.message,
                  alertOpen: true,
                })
              );
            } else {
              // setDisabledSave(false)
              setLoading(false);
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          })
          .catch((err) => {
            if (err?.response.data.status >= 400) {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: err?.response?.data?.error?.message,
                  alertOpen: true,
                })
              );
              if (err.response.data.status === 401) {
                dispatch(Logout());
                localStorage.clear();
                navigate("/");
                window.location.reload();
              }
            } else {
              dispatch(
                setAlert({
                  alertType: "error",
                  alertMsg: "something went wrong! please try again.",
                  alertOpen: true,
                })
              );
            }
          });
      }
    }
  };
  console.log(
    form.getFieldsValue(["englishRate", "experience", "animals"]),
    "errorsform"
  );
  // const isNextDisabled = Object.values(form.getFieldsValue(['englishRate', 'experience', 'animals'])).some(m => !m);
  // console.log(isNextDisabled, "isNextDisabled")
  return (
    <div
      className="px-3 mx-auto work-details-container work-details-container-ipad"
      style={{ maxWidth: "inherit",width:'auto' }}
    >
      <h1 className="color-primary fw-bolder mt-5">Work Details</h1>
      <Spin spinning={loading}>
        <div className="text-start work-details-form-row">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            onFieldsChange={handleFormChange}
            // validateTrigger='onFocus'
            initialValues={{}}
          >
            <Row gutter={16}>
              <Col xs={24} lg={12} className="responsive-bottom-margin">
                <Form.Item
                  name="englishRate"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      1. How would you rate your spoken english? <span style={{color:'red'}}>*</span>
                    </p>
                  }
                  rules={[
                    { required: true, message: "English rate is required" },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                    }
                    size="large"
                    className="profileselectrole"
                    value={englishLevel}
                    onChange={(val) => {
                      setEnglishLevel(val);
                      form.setFieldValue("englishRate", val);
                    }}
                    placeholder="Select your preference"
                  >
                    {englishRateData?.map((op, i) => {
                      return (
                        <Select.Option value={op} index={i}>
                          {op}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="languages"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      2. What other languages do you speak? (Optional) <span style={{color:'red'}}>*</span>
                    </p>
                  }
                  // rules={[{ required: true,  message:"Please select your preferred languages"}]}
                >
                  <Select
                    suffixIcon={
                      <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                    }
                    size="large"
                    mode="multiple"
                    showArrow
                    style={{
                      width: "100%",
                    }}
                    className="profileselectlanguage"
                    onChange={(val) => {
                      // setLanguages(val);
                      handleLanguages();
                      form.setFieldValue("languages", val);
                    }}
                    menuItemSelectedIcon={<CheckCircleFilled />}
                    placeholder="Select your preference"
                  >
                    {languageOptions?.map((item) => {
                      return (
                        <Option value={item}>
                          <div className="demo-option-label-item">{item}</div>
                        </Option>
                      );
                    })}
                    {/* {languages?.slice(100,199)?.map((item) => {
                  return (
                    <Select.Option value={item.name}>
                      <div className="demo-option-label-item">{item.name}</div>
                    </Select.Option>
                  );
                })} */}
                  </Select>
                </Form.Item>
                {otherLan && (
                  <Form.Item
                    className="other-language-input"
                    name="other"
                    rules={[
                      {
                        pattern: new RegExp(/^[A-Za-z]+$/),
                        message: "Add only alphabets",
                      },
                      { required: true, message: "Other Language is Required" },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter language"
                      style={{
                        width: "97%",
                        marginTop: "10px",
                        marginLeft: "10px",
                        height: "40px",
                        top: "-15px",
                      }}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setOtherLanval(e.target.value);
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} lg={12} className="responsive-bottom-margin">
                <Form.Item
                  name="experience"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      3. How many years of experience do you have as a
                      Caregiver? <span style={{color:'red'}}>*</span>
                    </p>
                  }
                  rules={[
                    { required: true, message: "Experience is required" },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                    }
                    className="profileselectrole"
                    onChange={(val) => {
                      // setExperienceLevel(val);
                      form.setFieldValue("experience", val);
                    }}
                    size="large"
                    placeholder="Select your preference"
                  >
                    {experienceData?.map((op, i) => {
                      return <Select.Option value={op}>{op}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                lg={12}
                className="responsive-bottom-margin responsive-top-margin"
              >
                <Form.Item
                  name="animals"
                  label={
                    <p className="color-secondary animals-label fs-6 mb-0">
                      4. Which pets are you comfortable being around? <span style={{color:'red'}}>*</span>
                    </p>
                  }
                  rules={[{ required: true, message: "Animals is required" }]}
                >
                  <Select
                    suffixIcon={
                      <img src="/images/icons/drop-icon.svg" alt="arrow-icon" />
                    }
                    className="profileselectrole"
                    onChange={(val) => {
                      // setAnimal(val);
                      form.setFieldValue("animals", val);
                    }}
                    size="large"
                    placeholder="Select your preference"
                  >
                    {animalData?.map((op, i) => {
                      return <Select.Option value={op}>{op}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={24}
                lg={12}
                className="responsive-bottom-margin responsive-top-margin"
              >
                <Form.Item
                  className="mb-mob-0"
                  label={
                    <p className="color-secondary mb-0 fs-6">
                      5. Do you have any relevent qualifications? (Optional)
                    </p>
                  }
                  name="qualification"
                  //  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    className="profileselectlanguage qualification"
                    showArrow
                    menuItemSelectedIcon={<CheckCircleFilled />}
                    suffixIcon={
                      <img
                        src="/images/icons/drop-icon.svg"
                        alt="arrow-icon"
                        style={{ color: "black" }}
                      />
                    }
                    onChange={(val) => {
                      // setQualification(val);
                      form.setFieldValue("qualification", val);
                    }}
                    size="large"
                    placeholder="Select qualification"
                  >
                    {qualificationData?.map((op, i) => {
                      return <Select.Option value={op}>{op}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={24}
                lg={12}
                className="responsive-bottom-margin responsive-top-margin responsive-top-margin-ipad"
                style={{ marginTop: "35px" }}
              >
                <Form.Item
                  label={false}
                  name="qualificationImage"
                  //   rules={[{ required: true }]}
                >
                  <label
                    type="file"
                    className="upload-file-field pointer"
                    style={{
                      height: "57px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      for="file"
                      className="btn-3"
                      onChange={handleImageUpload}
                      accept="image/*"
                      type="file"
                    />
                    <div className="p-2">
                      {fileName}
                      {fileName === "" && (
                        <p className="mb-0 fs-7" style={{ opacity: "0.3" }}>
                          Upload Qualification *
                        </p>
                      )}
                    </div>
                    <img src="/images/icons/upload-icon.svg" alt="" />
                  </label>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="text-center mb-2">
              <Button
                className="btn-black auth-action-btn mt-3 mb-5 mx-auto work-next-button"
                shape="round"
                type="primary"
                size="large"
                htmlType="submit"
                disabled={disabledSave}
                //     disabled={Object.values(form.getFieldsValue(['englishRate', 'experience', 'animals'])).every(m => !m)}
                // || form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
              >
                Next{" "}
                <img
                  className="ms-2"
                  src="/images/icons/right.svg"
                  alt="right icon"
                />
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
  userDetails: state?.loginData?.user?.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkDetails);

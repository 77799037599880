import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./Datepicker.style.less";
import Appointments from "../pages/client/Schedule/Appointments";
function DatePicker({
  setDatesData,
  weekly,
  userMode,
  appointments,
  isVisibleWeek,
  getAppointments = () => {},
  onSelectDatePicker = () => {},
  isAvailablity,
  ipad,
}) {
  const [allDates, setAllDates] = useState(null);
  const [showingDates, setShowingDates] = useState([]);
  const [monthName, setMonthName] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [lastMonthName, setLastMonthName] = useState(null);

  const threeMonths = 90;
  const forteenDays = weekly ? 7 : ipad === 786 || ipad <= 900 ? 7 : 7;

  useEffect(() => {
    const payload = {
      type: userMode,
      startDate:
        moment(new Date()).format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
      endDate:
        moment().add(7, "d").format("yyyy-MM-DDT").toString() +
        "00:00:00.000+0000",
    };
    getAppointments(payload);
  }, []);

  useEffect(() => {
    if (userMode !== "client" && isVisibleWeek) {
      var d = new Date();
      var str = [];
      var _days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      var _months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      for (let i = 1; i <= 7; i++) {
        let first = d.getDate() - d.getDay() + i;
        let dt = new Date(d.setDate(first));
        var _day = _days[dt.getDay()];
        var _month = _months[dt.getMonth()];
        var _date = dt.getDate();
        if (_date < 10) {
          _date = "0" + _date;
        }
        var _year = dt.getFullYear();
        var fulldate = _day + " " + _month + " " + _date + " " + _year + " ";
        str.push(new Date(fulldate));
      }
      // setShowingDates(str)
      // setSelectedDate(str[0]);
      setLastMonthName(moment().add(89, "days").format("MMMM YYYY"));
      setLastDate(moment().add(89, "days"));
      setAllDates(str);
      setMonthName(
        moment(str[0]).set({ year: moment().year() }).format("MMMM YYYY")
      );
      setShowingDates(str.slice(0, forteenDays));
      setDatesData({ listingDates: str?.slice(0, 4) });
      // setSelectedDate(str[0]);
    } else {
      let dates = [];
      const currentYear = moment().year();
      // const currentMonth = moment().month();
      for (let i = 0; i < threeMonths; i++) {
        dates.push(moment().add(i, "days"));
      }
      setLastMonthName(moment().add(89, "days").format("MMMM YYYY"));
      setLastDate(moment().add(89, "days"));
      setAllDates(dates);
      setMonthName(
        moment(dates[0]).set({ year: currentYear }).format("MMMM YYYY")
      );
      setShowingDates(dates.slice(0, forteenDays));
      setDatesData({ listingDates: dates?.slice(0, 4) });
      // setSelectedDate(dates[0]);
    }
  }, []);

  const handlePreviousDates = () => {
    const datesToBeShown =
      allDates.indexOf(showingDates[0]) < forteenDays
        ? allDates.slice(0, forteenDays)
        : allDates.slice(
            allDates.indexOf(showingDates[0]) - forteenDays,
            allDates.indexOf(showingDates[0])
          );
    let currentMonth = null;
    if (datesToBeShown.includes(selectedDate)) {
      currentMonth = moment(selectedDate).format("MMMM YYYY");
    } else {
      currentMonth = moment(datesToBeShown[0]).format("MMMM YYYY");
    }
    setMonthName(currentMonth);
    setShowingDates(datesToBeShown);
  };

  const handleNextDates = () => {
    for (let i = 0; i < showingDates.length; i++) {
      if (showingDates[i].format("DD") === "01") {
        setMonthName(moment(showingDates[i]).format("MMMM YYYY"));
      }
    }

    setShowingDates(
      allDates.indexOf(showingDates[showingDates.length - 1]) > 76
        ? allDates.slice(78, threeMonths)
        : allDates.slice(
            allDates.indexOf(showingDates[showingDates.length - 1]),
            allDates.indexOf(showingDates[showingDates.length - 1]) +
              forteenDays
          )
    );
  };

  const handlePreviousMonth = () => {
    const year = moment().year();
    let monthNames = [...Array(12).keys()]
      // .fill(moment())
      .map((month, index) =>
        moment(month).add(index, "months").set({ year }).format("MMMM YYYY")
      );
    let currentMonth = monthNames.indexOf(monthName);
    let previousMonth = currentMonth - 1;
    if (currentMonth === 0) {
      let showingMonthDates = allDates.filter((item) => {
        return item.format("MMMM YYYY") === monthNames[11];
      });
      if (showingMonthDates.length > 0) {
        setMonthName(monthNames[11]);
        setShowingDates(showingMonthDates.slice(0, forteenDays));
      }
    } else {
      let showingMonthDates = allDates.filter((item) => {
        return item.format("MMMM YYYY") === monthNames[previousMonth];
      });

      // const lastDayInAllDates = allDates.indexOf(
      //   showingMonthDates[showingMonthDates.length - 1]
      // );

      if (showingMonthDates.length > 0) {
        setMonthName(monthNames[previousMonth]);
        setShowingDates(showingMonthDates.slice(0, forteenDays));
      }
      if (
        showingMonthDates.length > 0 &&
        showingMonthDates.length < forteenDays
      ) {
        setMonthName(monthNames[previousMonth]);
        setShowingDates([
          ...showingMonthDates,
          ...allDates.slice(2, forteenDays),
        ]);
      }
    }
  };

  const handleNextMonth = () => {
    const year = moment().year();
    let monthNames = [...Array(12).keys()]
      // .fill(moment())
      .map((month, index) =>
        moment(month).add(index, "months").set({ year }).format("MMMM YYYY")
      );
    let currentMonth = monthNames.indexOf(monthName);
    let nextMonth = currentMonth + 1;
    if (currentMonth < 11) {
      let showingMonthDates = allDates.filter((item) => {
        return item.format("MMMM YYYY") === monthNames[nextMonth];
      });
      if (showingMonthDates.length > 0) {
        setMonthName(monthNames[nextMonth]);
        setShowingDates(showingMonthDates.slice(0, forteenDays));
      }
    } else {
      let showingMonthDates = allDates.filter((item) => {
        return item.format("MMMM YYYY") === monthNames[0];
      });
      if (showingMonthDates.length > 0) {
        setMonthName(monthNames[0]);
        setShowingDates(showingMonthDates.slice(0, forteenDays));
      }
    }
  };

  const handleSelectDate = (date) => {
    // const selectedMonth = moment(date).month();
    setMonthName(moment(date).format("MMMM YYYY"));
    setSelectedDate(date);
    let listingDates;
    if (!showingDates) return;
    if (showingDates.length - 1 === showingDates.indexOf(date)) {
      listingDates = showingDates.slice(
        showingDates.indexOf(date) - 3,
        showingDates.indexOf(date) + 1
      );
    } else if (showingDates.indexOf(date) === 0) {
      listingDates = showingDates.slice(
        showingDates.indexOf(date),
        showingDates.indexOf(date) + 4
      );
    } else {
      if (showingDates.length - 1 - showingDates.indexOf(date) === 1) {
        listingDates = showingDates.slice(
          showingDates.indexOf(date) - 2,
          showingDates.indexOf(date) + 2
        );
      } else {
        listingDates = showingDates.slice(
          showingDates.indexOf(date) - 1,
          showingDates.indexOf(date) + 3
        );
      }
    }
    let selectedDate =
      moment(date).format("yyyy-MM-DDTHH:mm:ss").toString() + ".000+0000";
    setDatesData({
      selectedMomentDate: date,
      selectedDate,
      showingDates,
      selectedDateInShowingDates: showingDates.indexOf(date),
      listingDates,
    });
    onSelectDatePicker(date);
  };

  const style = weekly
    ? {
        backgroundColor: "white",
        width: "109%",
        transform: "translateX(-50%)",
        marginLeft: "50%",
        height: "58px",
        marginTop: "10px",
      }
    : {};
  return (
    <div>
      <div
        className="App date-picker"
        style={{
          padding: userMode === "client" ? "" : 5,
          borderRadius: "10px",
          backgroundColor: "#E7E8EA",
        }}
      >
        {!weekly ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "-1rem",
            }}
          >
            <Button
              onClick={handlePreviousMonth}
              icon={<LeftOutlined />}
              size={"small"}
              style={{
                visibility:
                  monthName === moment().format("MMMM YYYY")
                    ? "hidden"
                    : "visible",
                backgroundColor: "#E7E8EA",
                color: "#64748B",
                border: "none",
              }}
            />
            <h4 className="w-20" style={{ marginTop: "2px" }}>
              {monthName}
            </h4>
            {(isAvailablity || userMode === "client") && (
              <Button
                onClick={handleNextMonth}
                icon={<RightOutlined />}
                size={"small"}
                style={{
                  visibility:
                    monthName === lastMonthName ? "hidden" : "visible",
                  backgroundColor: "#E7E8EA",
                  color: "#64748B",
                  border: "none",
                }}
              />
            )}
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            ...style,
          }}
        >
          {(isAvailablity || userMode == "client") && (
            <Button
              onClick={handlePreviousDates}
              icon={
                isAvailablity || userMode == "client" ? <LeftOutlined /> : ""
              }
              size={"small"}
              style={{
                visibility:
                  showingDates &&
                  showingDates[0] &&
                  moment(showingDates[0]).format("MM DD YYYY") ===
                    moment().format("MM DD YYYY")
                    ? isAvailablity || userMode === "client"
                      ? "visible"
                      : "hidden"
                    : "visible",
                backgroundColor: "#E7E8EA",
                color: "#64748B",
                border: "none",
              }}
            />
          )}
          <div style={{ display: "flex" }}>
            {showingDates?.map((item, index) => (
              <div key={index}>
                {selectedDate === item ? (
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "4px",
                      cursor: "pointer",
                      backgroundColor: "#364BC6",
                      color: "#fff",
                      height: weekly ? "47px" : "50px",
                      width: weekly ? "47px" : "50px",
                      borderRadius: "50%",
                      padding: "10px",
                      paddingTop: "10px",
                      margin: weekly ? "3px" : "5px",
                    }}
                  >
                    <p>{moment(item).format("ddd")}</p>
                    <p>{moment(item).format("DD")}</p>
                  </div>
                ) : // new Date().getDate()===new Date(item).getDate()&&new Date().getMonth()===new Date(item).getMonth()?(
                moment(item).format("DD/MM/YYYY") ===
                  moment().format("DD/MM/YYYY") ? (
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "4px",
                      cursor: "pointer",
                      backgroundColor: "#64748B ",
                      color: "#fff",
                      height: weekly ? "47px" : "50px",
                      width: weekly ? "47px" : "50px",
                      borderRadius: "50%",
                      padding: "10px",
                      paddingTop: "10px",
                      margin: weekly ? "3px" : "5px",
                    }}
                    onClick={() => handleSelectDate(item)}
                    key={index}
                  >
                    <p>{moment(item).format("ddd")}</p>
                    <p>{moment(item).format("DD")}</p>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "15px",
                      lineHeight: "4px",
                      backgroundColor: weekly ? "none" : "#E7E8EA",
                      color: moment(item).isBefore(moment())
                        ? "#b8b8b8"
                        : "#64748B",
                      border: weekly ? "none" : "1px solid #E7E8EA",
                      height: weekly ? "47px" : "50px",
                      width: weekly ? "47px" : "50px",
                      borderRadius: "50%",
                      padding: "10px",
                      paddingTop: "15px",
                      margin: weekly ? "0px" : "5px",
                      cursor: moment(item).isBefore(moment())
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (!moment(item).isBefore(moment())) {
                        handleSelectDate(item);
                      }
                    }}
                    key={index}
                  >
                    <p>{moment(item).format("ddd")}</p>
                    <p>{moment(item).format("DD")}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* {isAvailablity  &&
          <Button
            onClick={handleNextDates}
            icon={ isAvailablity? <RightOutlined />:''}
            size={"small"}
            style={{
              visibility: moment(showingDates[showingDates.length - 1]).isSame(
                lastDate
              )
                ? "hidden"
                : "visible",
              backgroundColor: "#E7E8EA",
              color: "#64748B",
              border: "none",
            }}
          />} */}
          {(isAvailablity || userMode === "client") && (
            <Button
              onClick={handleNextDates}
              icon={<RightOutlined />}
              size={"small"}
              style={{
                visibility: moment(
                  showingDates[showingDates.length - 1]
                ).isSame(lastDate)
                  ? "hidden"
                  : "visible",
                backgroundColor: "#E7E8EA",
                color: "#64748B",
                border: "none",
              }}
            />
          )}
        </div>
      </div>

      {userMode === "client" && <Appointments appointments={appointments} />}
    </div>
  );
}

export default DatePicker;

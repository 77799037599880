import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ProfileGeolocation from "./profileGeolocation";
import ProfileDetails from "./profile-details";

function Index({
  next,
  prev,
  save,
  accessToken,
  userType,
  setUserType,
  userDetails,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [profileInformation, setProfileInformation] = useState();
  const [geolocationInformation, setGeolocationInformation] = useState();
  const [showProfileDetails, setShowProfileDetails] = useState(true);
  const [showProfileGeolocation, setShowProfileGeolocation] = useState(false);
  // const [userType,setUserType]=useState();
  const [location, setLocation] = useState(null);
  const saveProfileDetails = async (values) => {
    const CLG = () => {
      console.log("aa");
    };
    setShowProfileDetails(false);
    console.log(userDetails, location, "sdfsfdsdf");
    save(
      {
        ...values,
        location: {
          lat: String(userDetails?.location?.lat || location?.lat),
          lon: String(userDetails?.location?.lon || location?.lon),
          radius: userDetails?.location?.radius || 15,
        },
      },
      CLG
    );
    setShowProfileGeolocation(true);
  };

  const saveGeolocationDetails = (values) => {
    console.log("values", values);
    save(values, next);
  };

  const shareLocation = (val) => {
    setLocation(val);
    console.log("val ", val);
  };

  return (
    <>
      {showProfileDetails && (
        <ProfileDetails
          sharePoints={shareLocation}
          save={saveProfileDetails}
          userType={userType}
          setUserType={setUserType}
        />
      )}
      {console.log("loc", location)}
      {showProfileGeolocation && (
        <ProfileGeolocation location={location} save={saveGeolocationDetails} />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  accessToken: state.loginData.user.accessToken,
  userDetails: state.loginData.user.userDetails,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);

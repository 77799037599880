import { Breadcrumb } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles/BreadCrumbs.less";

function Breadcrumbs({ title }) {
  const navigate = useNavigate();
  const breadCrums = useSelector((state) => state.breadcumbsReducer);
  const userMode = useSelector((state) => state.loginData?.userMode);
  console.log(breadCrums, "breadCrums");
  const handleNavigetor = (data) => {
    if (data?.isCustom) {
      let url = `/${data.route}`;
      navigate(url, { state: { relativeData: data?.state?.relativeData } });
    } else {
      if (data.route) {
        let url = `/${userMode}/${data.route}`;
        navigate(url, { state: { relativeData: data?.state?.relativeData } });
      }
      if (data?.code) {
        let url = `/${data.code}`;
        navigate(url, { state: { relativeData: data?.state?.relativeData } });
      }
    }
  };

  console.log(breadCrums);
  return (
    <Breadcrumb separator=">">
      {breadCrums &&
        breadCrums?.map((item, index) => {
          if (index + 1 != breadCrums?.length) {
            return (
              <Breadcrumb.Item
                onClick={() => handleNavigetor(item)}
                style={{ color: "#64748b" }}
              >
                {item?.title}
              </Breadcrumb.Item>
            );
          }
          return <Breadcrumb.Item>{item?.title}</Breadcrumb.Item>;
        })}
    </Breadcrumb>
  );
}

export default Breadcrumbs;

import React from "react";
import { Button, message } from "antd";
import "../../common/styles/EmailSuccessPopup.less";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { setAlert } from "../../redux/alert/alert.action";
import { useDispatch } from "react-redux";
import axios from "axios";

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const resetPass = async () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/forgot-password`, state, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
      .then((response) => {
        // console.log("response", response)
        if (response.data.status === 200) {
          setLoading(false);
          message.success(
            "Please check your email for newly resetted password."
          );
          // navigate('/caregiver/reset-password/success',{state: state})
        } else if (response.data.status >= 400) {
          setLoading(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: response?.data?.error?.message,
              alertOpen: true,
            })
          );
        } else {
          setLoading(false);
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      })
      .catch((err) => {
        if (err?.response.data.status >= 400) {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: err?.response?.data?.error?.message,
              alertOpen: true,
            })
          );
          if (err.response.data.status === 401) {
            localStorage.clear();
            navigate("/");
            window.location.reload();
          }
        } else {
          dispatch(
            setAlert({
              alertType: "error",
              alertMsg: "something went wrong! please try again.",
              alertOpen: true,
            })
          );
        }
      });
  };

  return (
    <div className="email-container">
      <img src="/images/icons/mail_logo.svg" alt="email card" />
      <div className="reqgenaratedMsg">
        <h1>Email has been sent</h1>
        <h4>
          Please check your inbox. We have sent a new password to your email
          address.
        </h4>
      </div>
      <p className="p-resend">
        Didn’t receive password?{" "}
        <Button  style={{fontWeight:'bold'}} type="link" onClick={resetPass}>
          Resend
        </Button>
      </p>
      <div>
        <Button
          htmlType="submit"
          onClick={() => navigate("/client/signin")}
          className="btn-black auth-action-btn mb-2"
          shape="round"
          type="primary"
          size="large"
          disabled={loading}
        >
          Sign In{" "}
          <img
            className="ms-2"
            src="/images/icons/right.svg"
            alt="right icon"
          />
        </Button>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;

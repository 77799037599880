import React, { useEffect, useState } from "react";
import { TimePicker, Checkbox, Button, Typography } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import moment from "moment/moment";

export function ModalBody(props) {
  const { Title, Text } = Typography;
  const {
    editAvailabilityData,
    selectedDate,
    handleDayClick,
    overnightAvailability,
    allDay,
    startTimeDisabledHrs,
    handleStartTime,
    startTime,
    endTimeDisabledHrs,
    handleEndTime,
    endTime,
    handleOvernightAvailability,
    minDuration,
    handleSaveOfTime,
    daysActive,
    weekDays,
    isLoading,
  } = props;
  console.log(editAvailabilityData, "editAvailabilityData");
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="m-4">
      {editAvailabilityData ? (
        <Title level={5}>
          For {moment(editAvailabilityData?.date).format("dddd MMMM DD")}
        </Title>
      ) : (
        selectedDate && (
          <Title level={5} strong>
            For {moment(selectedDate?.date).format("dddd MMMM DD")}
            {/* {moment(selectedDate).format("DD MMMM YYYY")} */}
          </Title>
        )
      )}
      {!editAvailabilityData && (
        <div style={{ marginBottom: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              onChange={(e) => {
                handleDayClick(selectedDate?.date);
                setIsChecked(e.target.checked);
              }}
              value={isChecked}
              // style={{ marginTop: "2px" }}
            />
            <Title
              level={5}
              // className="TextLevelSmallLabel"
              style={{
                fontSize: "16px",
                marginLeft: "10px",
                // paddingBottom: "5px",
                marginTop: "4.5px",
              }}
            >
              Repeat every {moment(selectedDate?.date).format("dddd")} For 3
              Months
            </Title>
          </div>
          <Text
            style={{
              fontSize: "12px",
              color: "#808080",
            }}
          >
            Set these your designated availability hours every{" "}
            {moment(selectedDate?.date).format("dddd")} for the next 3 months.
            Specific dates you have auto-scheduled can be edited later in your
            dashboard.
          </Text>
        </div>
      )}
      {/* {!editAvailabilityData && (
        <div className="mt-4 mb-3">
          <Text
          className="TextLevelSmallLabel"
            style={{
              fontSize: "16px",
            }}
          >
            Repeat Every Week for 3 months
          </Text>
        </div>
      )}
      {!editAvailabilityData && (
        <div className="d-flex justify-content-between mb-4">
          {weekDays.map((day, index) => (
            <div key={index}>
              {daysActive.some((item) => {
                return moment(item).format("dd") === moment(day).format("dd");
              }) ? (
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: "#364BC6",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleDayClick(day)}
                >
                  <Text
                    style={{
                      color: "#fff",
                    }}
                  >
                    {moment(day).format("dd").slice(0, 1)}
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor: "#E7E8EA",
                    color: "#fff",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleDayClick(day)}
                >
                  <Text
                    style={{
                      color: "#64748B",
                    }}
                  >
                    {moment(day).format("dd").slice(0, 1)}
                  </Text>
                </div>
              )}
            </div>
          ))}
        </div>
      )} */}
      <div>
        {overnightAvailability ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "180px",
              }}
            >
              {editAvailabilityData
                ? moment(editAvailabilityData?.startTime).format("MMM DD")
                : moment(selectedDate?.date).format("MMM DD")}
            </div>
            <div
              style={{
                width: "180px",
                paddingLeft: "33px",
              }}
            >
              {editAvailabilityData
                ? moment(editAvailabilityData?.startTime)
                    .add(1, "day")
                    .format("MMM DD")
                : moment(selectedDate?.date).add(1, "day").format("MMM DD")}
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TimePicker
            use12Hours
            format={"h a"}
            style={{
              width: "148px",
              height: "70px",
            }}
            inputReadOnly
            placeholder="Start Time"
            showNow={false}
            disabled={allDay}
            disabledHours={() => {
              if (overnightAvailability) {
                const disabledStartingHours = [
                  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                ];
                return disabledStartingHours;
              }
              return startTimeDisabledHrs;
            }}
            onChange={handleStartTime}
            value={
              editAvailabilityData
                ? moment(editAvailabilityData.startTime).utc()
                : startTime
            }
          />
          <TimePicker
            use12Hours
            format={"h a"}
            style={{
              width: "148px",
              height: "70px",
            }}
            inputReadOnly
            placeholder="End Time"
            disabledHours={() => {
              if (overnightAvailability) {
                const disabledEndingHours = [
                  2, 3, 4, 5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
                ];
                return disabledEndingHours;
              }
              return [...endTimeDisabledHrs, 2, 3, 4, 5];
            }}
            showNow={false}
            disabled={allDay}
            onChange={handleEndTime}
            value={
              editAvailabilityData
                ? moment(editAvailabilityData.endTime).utc()
                : endTime
            }
          />
        </div>
        <div className="mt-3">
          <div className="d-flex">
            <Checkbox
              style={{
                borderRadius: "20px",
              }}
              onChange={handleOvernightAvailability}
              checked={overnightAvailability}
            >
              Overnight Availability
            </Checkbox>
            <img src="/images/icons/bed-time.svg" width={20} />
          </div>
          <div className="mt-2">
            <Text
              style={{
                fontSize: "12px",
              }}
            >
              Considering the safety of our caregiver/companions we do not allow
              the appointments to end between 2 AM - 5 AM, please select your
              availability accordingly.
            </Text>
          </div>
        </div>
        <div className="mt-4 text-center">
          <Button
            type="primary"
            shape="round"
            className="ButtonText"
            style={{
              width: "260px",
              height: "70px",
              fontStyle: "bold",
              fontSize: "20px",
              backgroundColor:
                (startTime && endTime) || allDay || editAvailabilityData?.edited
                  ? "#0F172A"
                  : "#E7E8EA",
              color: "#fff",
            }}
            disabled={
              editAvailabilityData?.edited
                ? false
                : (!startTime || !endTime) && !allDay
            }
            onClick={handleSaveOfTime}
            loading={isLoading}
          >
            Save
            <ArrowRightOutlined type="Right" color={"#fff"} />
          </Button>
        </div>
      </div>
    </div>
  );
}

import axios from "axios";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AppoinmentCard from "../../../components/mySchedule/AppoinmentCard";
import { setAlert } from "../../../redux/alert/alert.action";
import { paymentDetails } from "../../../redux/appointment/appointment.action";

const Appointments = ({ appointments }) => {
  const navigate = useNavigate();
  const userMode = useSelector((state) => state.loginData?.userMode);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const handleAppontment = (appointmentId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/appointment/view-${
      userMode == "client" ? "client" : "caregiver"
    }-appointments`;
    if (token !== "") {
      setLoading(true);
      axios
        .post(
          url,
          {
            type: "redirect",
            appointmentId,
          },
          {
            headers: {
              accessToken: token,
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then((response) => {
          if (response.data.status === 200) {
            navigate(
              `/${
                userMode == "client" ? "client" : "caregiver"
              }/appointment/appointment-details/${appointmentId}`
            );
            dispatch(paymentDetails(response.data.output.result));
            setLoading(false);
          } else if (response.data.status >= 400) {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: response?.data?.error?.message,
                alertOpen: true,
              })
            );
          } else {
            setLoading(false);
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: "something went wrong! please try again.",
                alertOpen: true,
              })
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (
            err?.response?.data?.status >= 400 &&
            err?.response?.data?.status !== 401
          ) {
            dispatch(
              setAlert({
                alertType: "error",
                alertMsg: err?.response?.data?.error?.message,
                alertOpen: true,
              })
            );
          }
        });
    }
    // navigate(`/client/appointment/appointment-details/${appointmentId}`);
  };

  return (
    <div
      className={
        userMode === "client"
          ? "schedule_appointments_details"
          : "schedule_appointments_care_details"
      }
    >
      {appointments?.length === 0 ? (
        <div className="client_no_appointments_text weekly_calender">
          <img src="/images/icons/bird-icon.svg" alt="green-circle" />
          <h4>No Appointments</h4>
        </div>
      ) : (
        <>
          {appointments?.length > 0 &&
            appointments?.map((items) => {
              if (items?.appointments?.length > 0) {
                return (
                  <div className="appointments_date">
                    <div
                      className="
          "
                    >
                      {items?.appointments?.map((appoint) => (
                        <AppoinmentCard
                          {...appoint}
                          handleAppontment={handleAppontment}
                          weekStyles={true}
                        />
                      ))}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="appointments_date">
                    <div
                      className="
          "
                    >
                      <AppoinmentCard
                        {...items}
                        handleAppontment={handleAppontment}
                        weekStyles={true}
                      />
                    </div>
                  </div>
                );
              }
            })}
        </>
      )}
    </div>
  );
};

export default Appointments;

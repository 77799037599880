import { SOCKET_CONNECTION, TWILIO_CONNECTION } from "./socket.types";
// import * as io from "socket.io-client";

const token = localStorage.getItem("token");
// export const socket = io(`https://mf-chat.approd.ca/?accessToken=${token}`);

const INITIAL_STATE = {
  socketConnection: false,
  socket: null,
  twillioClient: null,
  twillioConnection: false,
};

const socketReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SOCKET_CONNECTION:
      return {
        ...state,
        socketConnection: action.payload.socketConnection,
        socket: action.payload.socket,
      };
    default:
      return state;
  }
};

export const twilioReducer = (state = INITIAL_STATE, action = {}) => {
  console.log(action.payload, "pauyldsfsd");
  switch (action.type) {
    case TWILIO_CONNECTION:
      return {
        ...state,
        twillioClient: action.payload.twillioClient,
        twillioConnection: action.payload.twillioConnection,
      };
    default:
      return state;
  }
};

export default socketReducer;

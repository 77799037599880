import React,{useState} from 'react'
import { Input, Select,InputNumber } from 'antd';
import {  CheckCircleFilled } from '@ant-design/icons';
import CountryCode from '../../common/helper/CountryCodes.json'
import RadioBox from './v-radio-box';

const { Option } = Select;

function InputBox() {
 
  const [value, setValue] = useState('female');
  const handleRadioChange = (e) => {
    setValue(e.target.value);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  console.log("code > ", CountryCode)

  

  const genders = [
    {
      icon:<img className='me-3' width={25} src='/images/icons/male.svg' alt='male icon' />,
      activeIcon:<img className='me-3' width={25} src='/images/icons/female.svg' alt='active male icon' />,
      name:"Male",
      value:"male"
    },
    {
      icon:<img className='me-3' width={25} src='/images/icons/female.svg' alt='female icon' />,
      name:"Female",
      value:"female"
    },
    {
      icon:<img className='me-3' width={25} src='/images/icons/others.svg' alt='others icon' />,
      name:"Others",
      value:"other"
    }
  ]


  const selectBefore = (
    <Select   suffixIcon={<img src="/images/icons/drop-icon.svg" alt="arrow-icon" />}
    size='large' defaultValue="+1">
      {CountryCode?.slice(0,99).map((cn, index) => {
        return  <Option key={index} value={cn.dial_code}>{cn.dial_code}</Option>
      })}
       {CountryCode?.slice(100,199).map((cn, index) => {
        return  <Option key={index} value={cn.dial_code}>{cn.dial_code}</Option>
      })}
      {CountryCode?.slice(200,299).map((cn, index) => {
        return  <Option key={index} value={cn.dial_code}>{cn.dial_code}</Option>
      })}
    </Select>
  );


  return (
    <>
            <Input size="large" placeholder="large size" prefix={<img src='/images/icons/Message.svg' style={{marginRight:"10px"}} alt="msg icon" />} />
            <br />
            <br />
            
            <Input.Password size="large" prefix={<img src='/images/icons/Lock.svg' 
            style={{marginRight:"10px"}}  
            alt="lock icon"/>} 
            placeholder="input password"
            iconRender={(visible) => (visible ? <img src="/images/icons/password-show.svg" alt="show" /> :  <img src="/images/icons/password-hide.svg" alt="hide" />)}
             />
            <br />
            <br />
            <Select
            suffixIcon={<img src="/images/icons/drop-icon.svg" alt="arrow-icon" />}
            size="large"
          mode="multiple"
          showArrow
          style={{
            width: '100%',
          }}
          menuItemSelectedIcon={<CheckCircleFilled />}
          placeholder="select one country"
          defaultValue={['china']}
          onChange={handleChange}
          optionLabelProp="label"
        >
    <Option value="china" label="China">
      <div className="demo-option-label-item">
        <span role="img" aria-label="China">
          🇨🇳
        </span>
        China (中国)
      </div>
    </Option>
    <Option value="usa" label="USA">
      <div className="demo-option-label-item">
        <span role="img" aria-label="USA">
          🇺🇸
        </span>
        USA (美国)
      </div>
    </Option>
    <Option value="japan" label="Japan">
      <div className="demo-option-label-item">
        <span role="img" aria-label="Japan">
          🇯🇵
        </span>
        Japan (日本)
      </div>
    </Option>
    <Option value="korea" label="Korea">
      <div className="demo-option-label-item">
        <span role="img" aria-label="Korea">
          🇰🇷
        </span>
        Korea (韩国)
      </div>
    </Option>
            </Select>
           
    <br />
    <br />

   <RadioBox
   options={genders}
   value={value}
   defaultValue={"female"}
   dir="vertical"
   onChange={handleRadioChange}
   />
<br>
</br>
<br>
</br>
<RadioBox
   options={genders}
   value={value}
   defaultValue={"female"}
   dir="horizontal"
   onChange={handleRadioChange}
   />
<br>
</br>
<br>
</br>
<InputNumber  style={{
            width: '100%',
          }} controls={false} size='large' addonBefore={selectBefore} defaultValue={100}  />

<br>
</br>
<br>
</br>
    </>
  )
}

export default InputBox